import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function SaveButton({ onSave, buttonText, withIcon = true, ...props }) {
  const { t } = useTranslation("common");
  return (
    <Button type="submit" size="lg" variant="first" onClick={onSave} {...props}>
      {withIcon && <i className="fe-folder" />}
      {` ${buttonText || t("actions.save")}`}
    </Button>
  );
}

export { SaveButton };
