import React from "react";
import { useVerificationDispatch } from "hooks/useVerification";
import { PermCodeRequired } from "components/perms";
import { codesForVerifications } from "components/perms/PermCodes";
import UserMenu from "./UserMenu";
import CompanySwitcher from "./CompanySwitcher";
import FinancialYearNavBarPicker from "./FinancialYearNavBarPicker";
import NavBarButton from "./NavBarButton";

function CreateVerificationButton() {
  const { open: openVerificationModal } = useVerificationDispatch();
  return <NavBarButton className="nav-plus-btn" icon="fe-plus" text="Ver." onClick={openVerificationModal} />;
}

function NavBarRightSide({ company, forDashboard }) {
  return (
    <ul className="list-unstyled topnav-menu float-right mb-0">
      {company && forDashboard && (
        <li>
          <FinancialYearNavBarPicker />
        </li>
      )}
      {/* <TimeTrackerLink /> */}
      {company && (
        <>
          {forDashboard && !company.ttOnly && (
            <PermCodeRequired code={codesForVerifications.manage}>
              <li>
                <CreateVerificationButton />
              </li>
            </PermCodeRequired>
          )}
          <li>
            <CompanySwitcher company={company} />
          </li>
        </>
      )}
      <li className="dropdown ml-2">
        <UserMenu />
      </li>
    </ul>
  );
}

export default NavBarRightSide;
