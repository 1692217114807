import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function FilterButton({ onClick, active = false, count = 0, size = "md", ...props }) {
  const { t } = useTranslation("common");
  return (
    <Button className="btn-filter" variant="" size={size} onClick={onClick} {...props}>
      {t("actions.filter")} <i className={active ? "fa fa-toggle-on" : "fa fa-toggle-off"} />{" "}
      <span className="filter-counter">{count}</span>
    </Button>
  );
}

export default FilterButton;
