import React from "react";
import axios from "axios";

function useInitialAsync(dataSource, initial, deps = []) {
  const [data, setData] = React.useState({
    loading: true,
    error: false,
    item: initial || {},
  });
  React.useEffect(() => {
    const signal = axios.CancelToken.source();
    setData({ ...data, loading: true });
    dataSource({ cancelToken: signal.token })
      .then((response) => {
        setData({
          loading: false,
          error: false,
          item: response.data ? response.data : response,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setData({ loading: false, error: true, item: initial || {} });
        }
      });
    return () => {
      signal.cancel("aborted");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    loading: data.loading,
    item: data.item,
    error: data.error,
    set: (newData) => setData({ loading: false, error: false, item: newData }),
  };
}

export default useInitialAsync;
