import React from "react";
import { useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import BankConnectModal from "pages/companies/BankConnections/BankConnectModal";

function NewConnectionBox({ companyId, onCloseModal }) {
  const { t } = useTranslation("company");
  const connectModal = useModal();

  const handleClose = () => {
    onCloseModal();
    connectModal.close();
  };

  return (
    <div className="new-connection-box__container">
      <div className="new-connection-box" onClick={connectModal.open}>
        <i className="fe-plus" />
        <span>{t("psd.newAccount")}</span>
      </div>
      {connectModal.show && <BankConnectModal companyId={companyId} handleClose={handleClose} />}
    </div>
  );
}

export default NewConnectionBox;
