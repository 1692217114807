import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import PendingNavTabs from "./PendingNavTabs";

function PendingLayout() {
  const company = useOutletContext();
  return (
    <div className="tab-card">
      <PendingNavTabs />
      <Outlet context={company} />
    </div>
  );
}

export default PendingLayout;
