import React, { useState } from "react";
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";

import { ConfirmButton, RemoveButton, SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup, TableGroup } from "components/formik";
import * as consultAPI from "api/consult";
import * as options from "api/options";
import * as selectAPI from "api/select";
import { BSelect, BSelectAsync } from "components/formik/pickers";
import { confirmExecute } from "components/modals/ConfirmModal";

function AddPermBox({ userRoles, onAddPerm }) {
  const [office, setOffice] = useState(null);
  const [role, setRole] = useState(userRoles[0]);
  const { t } = useTranslation("common");

  const handleAdd = () => {
    onAddPerm({
      office: office.value,
      office_name: office.label,
      role,
      draft: true,
    });
    setRole(userRoles[0]);
    setOffice(null);
  };

  return (
    <tr>
      <td className="select">
        <BSelectAsync
          loadOptions={selectAPI.getOffices}
          className="table-select"
          value={office}
          onChange={(selected) => setOffice(selected)}
          classNamePrefix="table-select"
        />
      </td>
      <td className="select">
        <BSelect
          options={userRoles}
          className="table-select"
          value={role}
          onChange={(selected) => setRole(selected)}
          classNamePrefix="table-select"
        />
      </td>
      <td width={100} className="text-center">
        <Button variant="primary" size="sm" onClick={handleAdd} disabled={!office || !role}>
          <i className="fas fa-plus" /> {t("common:actions.add")}
        </Button>
      </td>
    </tr>
  );
}

function UserGlobalForm({ user, successCallback, deleteCallback, formName }) {
  const { t } = useTranslation("common");
  const userRoles = options.userRoles.asList();

  const formikProps = {
    initialValues: {
      ...user,
      is_active: user.is_active || false,
      offices: user.offices.map((office) => ({
        ...office,
        role: options.userRoles.getOption(office.role),
      })),
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      personal_number: yup
        .string()
        .nullable()
        .trim()
        .required()
        .test("isNum", t("onlyDigitsAllowed"), (val) => !Number.isNaN(+val))
        .test("len", t("requireDigits", { number: 12 }), (val) => val.toString().length === 12),
      email: yup.string().email(t("common:errors.invalid.email")).required(),
    }),
    onSubmit: async (values, { setErrors, resetForm, setFieldValue, ...props }) => {
      await consultAPI
        .saveUser({
          id: user.id,
          is_active: values.is_active,
          email: values.email.toLowerCase(),
          first_name: values.first_name,
          last_name: values.last_name,
          personal_number: values.personal_number,
          can_use_standard_sign_in: values.can_use_standard_sign_in,
          offices: values.offices.map((office) => ({
            office: office.office,
            role: office.role.value,
          })),
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (!user.id) {
            resetForm();
            setFieldValue("offices", []);
          }
          if (successCallback) {
            successCallback({
              ...response.data,
              name: `${response.data.first_name} ${response.data.last_name}`,
            });
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onDelete = async () => {
    return consultAPI.deleteUser(user.id).then(() => {
      toast.success(t("msg:deleted"));
      if (deleteCallback) {
        deleteCallback();
      }
    });
  };

  const startResetPassword = async () => {
    return consultAPI
      .startUserResetPassword(user.id, user.email)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch(() => {
        toast.error(t("msg:canNotExecuteAction"));
      });
  };

  function onAddPerm(perm, exitedOffices, push) {
    const found = exitedOffices.find((office) => office.office === perm.office);
    if (!found) {
      push(perm);
    }
  }

  async function onRemoveOffice(officeUser, index, remove) {
    if (officeUser.draft) {
      remove(index);
      return;
    }
    const answer = await confirmExecute(
      t("others:confirm.disconnectAgency", {
        agencyName: officeUser.office_name,
      })
    );
    if (!answer) {
      return;
    }
    consultAPI
      .removeOfficeUser(officeUser.office, user.id)
      .then(() => {
        toast.success(t("msg:updated"));
        remove(index);
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 10000 });
        }
      });
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        return (
          <Form noValidate>
            <Row>
              <Col lg={4}>
                <FormGroup.Input
                  label={t("common:contact.personalNumber")}
                  name="personal_number"
                  placeholder="YYYYMMDDXXXX"
                  required
                />
              </Col>
              <Col lg={4}>
                <FormGroup.Input label={t("common:contact.emailLong")} name="email" required />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <FormGroup.Input label={t("common:contact.firstName")} name="first_name" required />
              </Col>
              <Col lg={4}>
                <FormGroup.Input label={t("common:contact.lastName")} name="last_name" required />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <FormGroup.Checkbox
                  formName={formName}
                  wrapperClass="mb-3"
                  label={t("others:canUseStandardSignIn")}
                  name="can_use_standard_sign_in"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={8}>
                <FieldArray
                  name="offices"
                  render={({ remove, push }) => (
                    <Table striped={false} bordered className="global-user-perm-table">
                      <thead>
                        <tr>
                          <th>{t("common:agency")}</th>
                          <th>{t("common:role")}</th>
                          <th style={{ width: 120 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {!values.offices.length && "id" in values ? (
                          <tr>
                            <td colSpan={3}>{t("common:noResultsFound")}</td>
                          </tr>
                        ) : (
                          values.offices.map((officeUser, idx) => (
                            <tr key={officeUser.office}>
                              <td className="office">{officeUser.office_name}</td>
                              <TableGroup.SimpleSelect options={userRoles} name={`offices[${idx}].role`} />
                              <td width={100} className="text-center">
                                <Button
                                  variant="danger"
                                  size="sm"
                                  disabled={isSubmitting}
                                  onClick={() => onRemoveOffice(officeUser, idx, remove)}
                                >
                                  <i className="fas fa-trash" />
                                </Button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                      <tfoot>
                        <AddPermBox userRoles={userRoles} onAddPerm={(perm) => onAddPerm(perm, values.offices, push)} />
                      </tfoot>
                    </Table>
                  )}
                />
              </Col>
            </Row>
            <AllError errors={errors} />
            {!user.is_active && (
              <>
                <Alert variant="warning">{t("others:warnUserInactive")}</Alert>
                <FormGroup.Checkbox name="is_active" label={t("common:statuses.active")} />
              </>
            )}
            <hr />
            <SubmitButton isSubmitting={isSubmitting} />
            {user.id && user.is_active && (
              <>
                <RemoveButton
                  label={t("actions.deactivate")}
                  variant="danger"
                  confirmMessage={t("others:confirm.removeUser", {
                    name: user.name,
                  })}
                  disabled={isSubmitting}
                  className="float-right"
                  onClick={onDelete}
                />
                <ConfirmButton
                  variant="second"
                  confirmMessage={t("others:confirm.userResetPwd", {
                    email: user.email,
                  })}
                  label={t("others:userSendResetPwd")}
                  className="ml-2"
                  icon="fas fa-key"
                  onClick={startResetPassword}
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default UserGlobalForm;
