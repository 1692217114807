import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { CustomerInvoiceTable } from "components/tables";
import * as tableAPI from "api/table";
import { useOutletContext, useSearchParams } from "react-router-dom";

function CustomerInvoiceListPage() {
  const [searchParams] = useSearchParams();
  const company = useOutletContext();
  const initialFilters = {
    mode: searchParams.get("mode") || "",
    ordering: "status2,-invoice_no2",
  };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId="customerInvoices"
          dataSource={(filters, config) => tableAPI.companyCustomerInvoices(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <CustomerInvoiceTable selectable companyId={company.id} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default CustomerInvoiceListPage;
