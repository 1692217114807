import { Options } from "api/options";

export const sniCodes = Options({
  "01110": "01110 - Odling av spannmål (utom ris)",
  "01120": "01120 - Odling av ris",
  "01131": "01131 - Potatisodling",
  "01132": "01132 - Sockerbetsodling",
  "01133": "01133 - Odling av grönsaker (köksväxter) på friland",
  "01134": "01134 - Odling av grönsaker (köksväxter) i växthus",
  "01135": "01135 - Svampodling m.m.",
  "01140": "01140 - Odling av sockerrör",
  "01150": "01150 - Odling av tobak",
  "01160": "01160 - Odling av fiberväxter",
  "01191": "01191 - Odling av prydnadsväxter i växthus",
  "01199": "01199 - Odling av övriga ett- och tvååriga växter",
  "01210": "01210 - Odling av druvor",
  "01220": "01220 - Odling av tropiska och subtropiska frukter",
  "01230": "01230 - Odling av citrusfrukter",
  "01240": "01240 - Odling av kärnfrukter och stenfrukter",
  "01250": "01250 - Odling av andra frukter och bär samt nötter",
  "01260": "01260 - Odling av oljehaltiga frukter",
  "01270": "01270 - Odling av växter för dryckesframställning",
  "01280": "01280 - Odling av kryddväxter, drog- och medicinalväxter",
  "01290": "01290 - Odling av andra fleråriga växter",
  "01301": "01301 - Odling av plantskoleväxter i växthus",
  "01302": "01302 - Odling av plantskoleväxter m.m. på friland",
  "01410": "01410 - Mjölkproduktion och uppfödning av nötkreatur av mjölkras",
  "01420": "01420 - Uppfödning av andra nötkreatur och bufflar",
  "01430": "01430 - Uppfödning av hästar och andra hästdjur",
  "01440": "01440 - Uppfödning av kameler och kameldjur",
  "01450": "01450 - Uppfödning av får och getter",
  "01461": "01461 - Uppfödning av smågrisar",
  "01462": "01462 - Uppfödning av slaktsvin",
  "01471": "01471 - Äggproduktion (för försäljning)",
  "01472": "01472 - Uppfödning av fjäderfä, ej äggproduktion",
  "01491": "01491 - Renskötsel",
  "01492": "01492 - Uppfödning av sällskapsdjur",
  "01499": "01499 - Övrig uppfödning av andra djur",
  "01500": "01500 - Blandat jordbruk",
  "01610": "01610 - Service till växtodling",
  "01620": "01620 - Service till husdjursskötsel",
  "01630": "01630 - Bearbetning av skördade växter",
  "01640": "01640 - Bearbetning av utsäde",
  "01700": "01700 - Jakt och service i anslutning härtill",
  "02101": "02101 - Skogsförvaltning",
  "02102": "02102 - Skogsskötsel",
  "02109": "02109 - Övrig skoglig verksamhet",
  "02200": "02200 - Drivning",
  "02300": "02300 - Insamling av annat vilt växande skogsmaterial än trä",
  "02401": "02401 - Virkesmätning",
  "02409": "02409 - Övrig service till skogsbruk",
  "03111": "03111 - Trålfiske i saltvatten",
  "03119": "03119 - Övrigt saltvattensfiske",
  "03120": "03120 - Sötvattensfiske",
  "03210": "03210 - Fiskodling i saltvatten",
  "03220": "03220 - Fiskodling i sötvatten",
  "05100": "05100 - Stenkolsutvinning",
  "05200": "05200 - Brunkolsutvinning",
  "06100": "06100 - Utvinning av råpetroleum",
  "06200": "06200 - Utvinning av naturgas",
  "07100": "07100 - Järnmalmsutvinning",
  "07210": "07210 - Utvinning av uran- och toriummalm",
  "07290": "07290 - Utvinning av annan malm",
  "08110": "08110 - Brytning av natursten, kalk- och gipssten, krita och skiffer",
  "08120": "08120 - Utvinning av sand, grus och berg; utvinning av lera och kaolin",
  "08910": "08910 - Brytning av kemiska mineral",
  "08920": "08920 - Torvutvinning",
  "08930": "08930 - Saltutvinning",
  "08990": "08990 - Diverse övrig utvinning av mineral",
  "09100": "09100 - Stödtjänster till råpetroleum- och naturgasutvinning",
  "09900": "09900 - Stödtjänster till annan utvinning",
  10111: "10111 - Kreatursslakt",
  10112: "10112 - Styckning av kött",
  10120: "10120 - Beredning och hållbarhetsbehandling av fjäderfäkött",
  10130: "10130 - Charkuteri- och annan köttvarutillverkning",
  10200: "10200 - Beredning och hållbarhetsbehandling av fisk samt skal- och blötdjur",
  10310: "10310 - Beredning och hållbarhetsbehandling av potatis",
  10320: "10320 - Juice- och safttillverkning",
  10390: "10390 - Annan beredning och hållbarhetsbehandling av frukt, bär och grönsaker",
  10410: "10410 - Framställning av oljor och fetter",
  10420: "10420 - Matfettstillverkning",
  10511: "10511 - Osttillverkning",
  10519: "10519 - Annan mejerivarutillverkning",
  10520: "10520 - Glasstillverkning",
  10611: "10611 - Mjöltillverkning",
  10612: "10612 - Tillverkning av frukostflingor, mixer och andra livsmedelsberedningar av kvarnprodukter",
  10620: "10620 - Stärkelsetillverkning",
  10710: "10710 - Tillverkning av mjukt matbröd och färska bakverk",
  10721: "10721 - Knäckebrödstillverkning",
  10722: "10722 - Tillverkning av kex och konserverade bakverk",
  10730: "10730 - Tillverkning av pastaprodukter",
  10810: "10810 - Sockertillverkning",
  10821: "10821 - Tillverkning av sockerkonfektyrer",
  10822: "10822 - Tillverkning av choklad och chokladkonfektyrer",
  10830: "10830 - Framställning av te och kaffe",
  10840: "10840 - Tillverkning av senap, ketchup, kryddor och andra smaksättningsmedel",
  10850: "10850 - Tillverkning av lagad mat och färdigrätter",
  10860: "10860 - Tillverkning av homogeniserade livsmedelspreparat inklusive dietmat",
  10890: "10890 - Framställning av andra livsmedel",
  10910: "10910 - Framställning av beredda fodermedel",
  10920: "10920 - Framställning av mat till sällskapsdjur",
  11010: "11010 - Destillering, rening och tillblandning av spritdrycker",
  11020: "11020 - Framställning av vin från druvor",
  11030: "11030 - Framställning av cider och andra fruktviner",
  11040: "11040 - Framställning av andra icke-destillerade jästa drycker",
  11050: "11050 - Framställning av öl",
  11060: "11060 - Framställning av malt",
  11070: "11070 - Framställning av läskedrycker, mineralvatten och annat vatten på flaska",
  12000: "12000 - Tobaksvarutillverkning",
  13100: "13100 - Garntillverkning",
  13200: "13200 - Vävnadstillverkning",
  13300: "13300 - Blekning, färgning och annan textilberedning",
  13910: "13910 - Tillverkning av trikåväv",
  13921: "13921 - Sömnad av gardiner, sängkläder och linnevaror",
  13922: "13922 - Tillverkning av presenningar, tält, segel o.d.",
  13930: "13930 - Tillverkning av mattor",
  13940: "13940 - Tågvirkes- och bindgarnstillverkning",
  13950: "13950 - Tillverkning av bondad duk",
  13960: "13960 - Tillverkning av andra tekniska textilier och industritextilier",
  13990: "13990 - Övrig textilietillverkning",
  14110: "14110 - Tillverkning av läder- och skinnkläder",
  14120: "14120 - Tillverkning av arbets-, skydds- och överdragskläder",
  14130: "14130 - Tillverkning av andra gång- och ytterkläder",
  14140: "14140 - Tillverkning av underkläder, skjortor och blusar",
  14190: "14190 - Tillverkning av andra beklädnadsvaror och tillbehör",
  14200: "14200 - Tillverkning av pälsvaror",
  14310: "14310 - Tillverkning av strumpor",
  14390: "14390 - Tillverkning av andra trikåvaror",
  15110: "15110 - Garvning och annan läderberedning; pälsberedning",
  15120: "15120 - Tillverkning av reseffekter, handväskor, sadel- och seldon m. m.",
  15200: "15200 - Tillverkning av skodon",
  16101: "16101 - Sågning av trä",
  16102: "16102 - Hyvling av trä",
  16103: "16103 - Träimpregnering",
  16210: "16210 - Tillverkning av fanér och träbaserade skivor",
  16220: "16220 - Tillverkning av sammansatta parkettgolv",
  16231: "16231 - Tillverkning av monteringsfärdiga trähus",
  16232: "16232 - Tillverkning av dörrar av trä",
  16233: "16233 - Tillverkning av fönster av trä",
  16239: "16239 - Tillverkning av övriga byggnads- och inredningssnickerier",
  16240: "16240 - Träförpackningstillverkning",
  16291: "16291 - Tillverkning av förädlade trädbränslen",
  16292: "16292 - Övrig trävarutillverkning",
  16293: "16293 - Tillverkning av varor av kork, halm, rotting o.d.",
  17111: "17111 - Tillverkning av mekanisk eller halvkemisk massa",
  17112: "17112 - Sulfatmassatillverkning",
  17113: "17113 - Sulfitmassatillverkning",
  17121: "17121 - Tillverkning av tidnings- och journalpapper",
  17122: "17122 - Tryckpapperstillverkning, ej tidnings- och journalpapper",
  17123: "17123 - Tillverkning av kraftpapper och kraftpapp",
  17129: "17129 - Övrig tillverkning av papper och papp",
  17211: "17211 - Tillverkning av wellpapp och wellpappförpackningar",
  17219: "17219 - Övrig tillverkning av pappers- och pappförpackningar",
  17220: "17220 - Tillverkning av hushålls- och hygienartiklar av papper",
  17230: "17230 - Tillverkning av skrivpapper, kuvert o.d.",
  17240: "17240 - Tapettillverkning",
  17290: "17290 - Tillverkning av andra pappers- och pappvaror",
  18110: "18110 - Tryckning av dagstidningar",
  18121: "18121 - Tryckning av tidskrifter",
  18122: "18122 - Tryckning av böcker och övriga trycksaker",
  18130: "18130 - Grafiska tjänster före tryckning (prepress/premedia)",
  18140: "18140 - Bokbindning och andra tjänster i samband med tryckning",
  18200: "18200 - Reproduktion av inspelningar",
  19100: "19100 - Tillverkning av stenkolsprodukter",
  19200: "19200 - Petroleumraffinering",
  20110: "20110 - Industrigasframställning",
  20120: "20120 - Tillverkning av färgämnen",
  20130: "20130 - Tillverkning av andra oorganiska baskemikalier",
  20140: "20140 - Tillverkning av andra organiska baskemikalier",
  20150: "20150 - Tillverkning av gödselmedel och kväveprodukter",
  20160: "20160 - Basplastframställning",
  20170: "20170 - Tillverkning av syntetiskt basgummi",
  20200: "20200 - Tillverkning av bekämpningsmedel och andra lantbrukskemiska produkter",
  20300: "20300 - Tillverkning av färg, lack, tryckfärg m.m.",
  20410: "20410 - Tillverkning av tvål, såpa, tvättmedel och polermedel",
  20420: "20420 - Tillverkning av parfymer och toalettartiklar",
  20510: "20510 - Sprängämnestillverkning",
  20520: "20520 - Tillverkning av lim",
  20530: "20530 - Tillverkning av eteriska oljor",
  20590: "20590 - Tillverkning av övriga kemiska produkter",
  20600: "20600 - Konstfibertillverkning",
  21100: "21100 - Tillverkning av farmaceutiska basprodukter",
  21200: "21200 - Tillverkning av läkemedel",
  22110: "22110 - Tillverkning av däck och slangar; regummering",
  22190: "22190 - Annan gummivarutillverkning",
  22210: "22210 - Tillverkning av plasthalvfabrikat",
  22220: "22220 - Plastförpackningstillverkning",
  22230: "22230 - Byggplastvarutillverkning",
  22290: "22290 - Annan plastvarutillverkning",
  23110: "23110 - Framställning av planglas",
  23120: "23120 - Bearbetning av planglas",
  23130: "23130 - Tillverkning av buteljer, glasförpackningar och husgeråd av glas",
  23140: "23140 - Tillverkning av glasfiber",
  23190: "23190 - Tillverkning av andra glasvaror inklusive tekniska glasvaror",
  23200: "23200 - Tillverkning av eldfasta produkter",
  23310: "23310 - Tillverkning av keramiska golv- och väggplattor",
  23320: "23320 - Tillverkning av murtegel, takpannor och andra byggvaror av tegel",
  23410: "23410 - Tillverkning av keramiska hushålls- och prydnadsartiklar",
  23420: "23420 - Tillverkning av keramiska sanitetsartiklar",
  23430: "23430 - Tillverkning av keramiska isolatorer o.d.",
  23440: "23440 - Tillverkning av andra tekniska keramiska produkter",
  23490: "23490 - Tillverkning av andra keramiska produkter",
  23510: "23510 - Tillverkning av cement",
  23520: "23520 - Tillverkning av kalk och gips",
  23610: "23610 - Tillverkning av betongvaror för byggändamål",
  23620: "23620 - Tillverkning av gipsvaror för byggändamål",
  23630: "23630 - Tillverkning av fabriksblandad betong",
  23640: "23640 - Tillverkning av murbruk",
  23650: "23650 - Tillverkning av fibercementvaror",
  23690: "23690 - Tillverkning av andra varor av betong, cement och gips",
  23701: "23701 - Huggning, formning och slutlig bearbetning av sten för byggnadsändamål",
  23709: "23709 - Huggning, formning och slutlig bearbetning av sten för prydnadsändamål",
  23910: "23910 - Slipmedelstillverkning",
  23991: "23991 - Tillverkning av varor av sten- och mineralull",
  23999: "23999 - Diverse övrig tillverkning av icke-metalliska mineraliska produkter",
  24100: "24100 - Framställning av järn och stål samt ferrolegeringar",
  24200: "24200 - Tillverkning av rör, ledningar, ihåliga profiler och tillbehör av stål",
  24310: "24310 - Tillverkning av kalldragen stålstång",
  24320: "24320 - Tillverkning av kallvalsade stålband",
  24330: "24330 - Tillverkning av andra kallformade produkter av stål",
  24340: "24340 - Tillverkning av kalldragen ståltråd",
  24410: "24410 - Framställning av ädla metaller",
  24420: "24420 - Framställning av aluminium",
  24430: "24430 - Framställning av bly, zink och tenn",
  24440: "24440 - Framställning av koppar",
  24450: "24450 - Framställning av andra metaller",
  24460: "24460 - Tillverkning av kärnbränsle",
  24510: "24510 - Gjutning av järn",
  24520: "24520 - Gjutning av stål",
  24530: "24530 - Gjutning av lättmetall",
  24540: "24540 - Gjutning av andra metaller",
  25110: "25110 - Tillverkning av metallstommar och delar därav",
  25120: "25120 - Tillverkning av dörrar och fönster av metall",
  25210: "25210 - Tillverkning av radiatorer och pannor för centraluppvärmning",
  25290: "25290 - Tillverkning av andra cisterner, tankar, kar och andra behållare av metall",
  25300: "25300 - Tillverkning av ånggeneratorer utom varmvattenpannor för centraluppvärmning",
  25400: "25400 - Tillverkning av vapen och ammunition",
  25500: "25500 - Smidning, pressning, prägling och valsning av metall; pulvermetallurgi",
  25610: "25610 - Beläggning och överdragning av metall",
  25620: "25620 - Metallegoarbeten",
  25710: "25710 - Tillverkning av bestick",
  25720: "25720 - Tillverkning av lås och gångjärn",
  25730: "25730 - Tillverkning av verktyg och redskap",
  25910: "25910 - Tillverkning av stålfat o.d. behållare",
  25920: "25920 - Tillverkning av lättmetallförpackningar",
  25930: "25930 - Tillverkning av metalltrådvaror, kedjor och fjädrar",
  25940: "25940 - Tillverkning av nitar och skruvar",
  25991: "25991 - Tillverkning av diskbänkar, sanitetsgods m. m. av metall för byggändamål",
  25999: "25999 - Diverse övrig metallvarutillverkning",
  26110: "26110 - Tillverkning av elektroniska komponenter",
  26120: "26120 - Tillverkning av kretskort",
  26200: "26200 - Tillverkning av datorer och kringutrustning",
  26300: "26300 - Tillverkning av kommunikationsutrustning",
  26400: "26400 - Tillverkning av hemelektronik",
  26510: "26510 - Tillverkning av instrument och apparater för mätning, provning och navigering",
  26520: "26520 - Urtillverkning",
  26600: "26600 - Tillverkning av strålningsutrustning samt elektromedicinsk och elektroterapeutisk utrustning",
  26700: "26700 - Tillverkning av optiska instrument och fotoutrustning",
  26800: "26800 - Tillverkning av magnetiska och optiska medier",
  27110: "27110 - Tillverkning av elmotorer, generatorer och transformatorer",
  27120: "27120 - Tillverkning av eldistributions- och elkontrollapparater",
  27200: "27200 - Batteri- och ackumulatortillverkning",
  27310: "27310 - Tillverkning av optiska fiberkablar",
  27320: "27320 - Tillverkning av andra elektroniska och elektriska ledningar och kablar",
  27330: "27330 - Tillverkning av kabeltillbehör",
  27400: "27400 - Tillverkning av belysningsarmatur",
  27510: "27510 - Tillverkning av elektriska hushållsmaskiner och hushållsapparater",
  27520: "27520 - Tillverkning av icke-elektriska hushållsmaskiner och hushållsapparater",
  27900: "27900 - Tillverkning av annan elapparatur",
  28110: "28110 - Tillverkning av motorer och turbiner utom för luftfartyg och fordon",
  28120: "28120 - Tillverkning av fluidteknisk utrustning",
  28130: "28130 - Tillverkning av andra pumpar och kompressorer",
  28140: "28140 - Tillverkning av andra kranar och ventiler",
  28150: "28150 - Tillverkning av lager, kugghjul och andra delar för kraftöverföring",
  28210: "28210 - Tillverkning av ugnar och brännare",
  28220: "28220 - Tillverkning av lyft- och godshanteringsanordningar",
  28230: "28230 - Tillverkning av kontorsmaskiner och kontorsutrustning (utom datorer och kringutrustning)",
  28240: "28240 - Tillverkning av motordrivna handverktyg",
  28250: "28250 - Tillverkning av maskiner och apparater för kyla och ventilation utom för hushåll",
  28290: "28290 - Övrig tillverkning av maskiner för allmänt ändamål",
  28300: "28300 - Tillverkning av jord- och skogsbruksmaskiner",
  28410: "28410 - Tillverkning av verktygsmaskiner för metallbearbetning",
  28490: "28490 - Tillverkning av övriga verktygsmaskiner",
  28910: "28910 - Tillverkning av maskiner för metallurgi",
  28920: "28920 - Tillverkning av gruv-, bergbrytnings- och byggmaskiner",
  28930: "28930 - Tillverkning av maskiner för framställning av livsmedel, drycker och tobaksvaror",
  28940: "28940 - Tillverkning av maskiner för produktion av textil-, beklädnads- och lädervaror",
  28950: "28950 - Tillverkning av maskiner för produktion av massa, papper och papp",
  28960: "28960 - Tillverkning av maskiner för gummi och plast",
  28990: "28990 - Tillverkning av övriga specialmaskiner",
  29101: "29101 - Tillverkning av personbilar och andra lätta motorfordon",
  29102: "29102 - Tillverkning av lastbilar och andra tunga motorfordon",
  29200: "29200 - Tillverkning av karosserier för motorfordon; tillverkning av släpfordon och påhängsvagnar",
  29310: "29310 - Tillverkning av elektrisk och elektronisk utrustning för motorfordon",
  29320: "29320 - Tillverkning av andra delar och tillbehör till motorfordon",
  30110: "30110 - Byggande av fartyg och flytande materiel",
  30120: "30120 - Byggande av fritidsbåtar",
  30200: "30200 - Tillverkning av rälsfordon",
  30300: "30300 - Tillverkning av luftfartyg, rymdfarkoster o.d.",
  30400: "30400 - Tillverkning av militära stridsfordon",
  30910: "30910 - Tillverkning av motorcyklar",
  30920: "30920 - Tillverkning av cyklar och invalidfordon",
  30990: "30990 - Diverse övrig transportmedelstillverkning",
  31011: "31011 - Tillverkning av kontors- och butiksmöbler",
  31012: "31012 - Tillverkning av kontors- och butiksinredningar",
  31021: "31021 - Tillverkning av köksmöbler",
  31022: "31022 - Tillverkning av köksinredningar",
  31030: "31030 - Tillverkning av madrasser",
  31090: "31090 - Tillverkning av andra möbler",
  32110: "32110 - Prägling av mynt",
  32120: "32120 - Tillverkning av smycken, guld- och silversmedsvaror",
  32130: "32130 - Tillverkning av bijouterier o.d.",
  32200: "32200 - Tillverkning av musikinstrument",
  32300: "32300 - Tillverkning av sportartiklar",
  32400: "32400 - Tillverkning av spel och leksaker",
  32501: "32501 - Tillverkning av medicinska och dentala instrument och tillbehör",
  32502: "32502 - Tillverkning av tandproteser",
  32910: "32910 - Tillverkning av borstbinderiarbeten",
  32990: "32990 - Diverse övrig tillverkning",
  33110: "33110 - Reparation av metallvaror",
  33120: "33120 - Reparation av maskiner",
  33130: "33130 - Reparation av elektronisk och optisk utrustning",
  33140: "33140 - Reparation av elapparatur",
  33150: "33150 - Reparation och underhåll av fartyg och båtar",
  33160: "33160 - Reparation och underhåll av luftfartyg och rymdfarkoster",
  33170: "33170 - Reparation och underhåll av andra transportmedel",
  33190: "33190 - Reparation av annan utrustning",
  33200: "33200 - Installation av industrimaskiner och -utrustning",
  35110: "35110 - Generering av elektricitet",
  35120: "35120 - Överföring av elektricitet",
  35130: "35130 - Distribution av elektricitet",
  35140: "35140 - Handel med elektricitet",
  35210: "35210 - Framställning av gas",
  35220: "35220 - Distribution av gasformiga bränslen via rörnät",
  35230: "35230 - Handel med gas via rörnät",
  35300: "35300 - Försörjning av värme och kyla",
  36001: "36001 - Grundvattenförsörjning",
  36002: "36002 - Ytvattenförsörjning",
  37000: "37000 - Avloppsrening",
  38110: "38110 - Insamling av icke-farligt avfall",
  38120: "38120 - Insamling av farligt avfall",
  38210: "38210 - Behandling och bortskaffande av icke-farligt avfall",
  38220: "38220 - Behandling och bortskaffande av farligt avfall",
  38311: "38311 - Demontering av uttjänta fordon",
  38312: "38312 - Demontering av elektrisk och elektronisk utrustning",
  38319: "38319 - Demontering av övrig kasserad utrustning",
  38320: "38320 - Återvinning av källsorterat material",
  39000: "39000 - Sanering, efterbehandling av jord och vatten samt annan verksamhet för föroreningsbekämpning",
  41100: "41100 - Utformning av byggprojekt",
  41200: "41200 - Byggande av bostadshus och andra byggnader",
  42110: "42110 - Anläggning av vägar och motorvägar",
  42120: "42120 - Anläggning av järnvägar och tunnelbanor",
  42130: "42130 - Anläggning av broar och tunnlar",
  42210: "42210 - Allmännyttiga anläggningsarbeten för värme, vatten och avlopp",
  42220: "42220 - Anläggningsarbeten för el och telekommunikation",
  42910: "42910 - Vattenbyggnad",
  42990: "42990 - Övriga anläggningsarbeten",
  43110: "43110 - Rivning av hus och byggnader",
  43120: "43120 - Mark- och grundarbeten",
  43130: "43130 - Markundersökning",
  43210: "43210 - Elinstallationer",
  43221: "43221 - Värme- och sanitetsarbeten",
  43222: "43222 - Ventilationsarbeten",
  43223: "43223 - Kyl- och frysinstallationsarbeten",
  43229: "43229 - Övriga VVS-arbeten",
  43290: "43290 - Andra bygginstallationer",
  43310: "43310 - Puts-, fasad- och stuckatörsarbeten",
  43320: "43320 - Byggnadssnickeriarbeten",
  43330: "43330 - Golv- och väggbeläggningsarbeten",
  43341: "43341 - Måleriarbeten",
  43342: "43342 - Glasmästeriarbeten",
  43390: "43390 - Annan slutbehandling av byggnader",
  43911: "43911 - Takarbeten av plåt",
  43912: "43912 - Takarbeten av andra material än plåt",
  43991: "43991 - Uthyrning av bygg- och anläggningsmaskiner med förare",
  43999: "43999 - Diverse övrig specialiserad bygg- och anläggningsverksamhet",
  45110: "45110 - Handel med personbilar och lätta motorfordon",
  45191: "45191 - Handel med lastbilar, bussar och specialfordon",
  45192: "45192 - Handel med husvagnar, husbilar, släpfordon och påhängsvagnar",
  45201: "45201 - Allmän service och reparation av motorfordon utom motorcyklar",
  45202: "45202 - Plåt-, lack- och glasreparationer på motorfordon utom motorcyklar",
  45203: "45203 - Installationer och reparationer av elsystem till motorfordon utom motorcyklar",
  45204: "45204 - Däckservice",
  45310: "45310 - Parti- och provisionshandel med reservdelar och tillbehör till motorfordon utom motorcyklar",
  45320: "45320 - Detaljhandel med reservdelar och tillbehör till motorfordon utom motorcyklar",
  45400: "45400 - Handel med och service av motorcyklar inklusive reservdelar och tillbehör",
  46110: "46110 - Provisionshandel med jordbruksråvaror, levande djur, textilråvaror och textilhalvfabrikat",
  46120: "46120 - Provisionshandel med bränsle, malm, metaller och industrikemikalier",
  46130: "46130 - Provisionshandel med virke och byggmaterial",
  46141:
    "46141 - Provisionshandel med maskiner, industriell utrustning, fartyg och luftfartyg utom kontorsutrustning och datorer",
  46142: "46142 - Provisionshandel med kontorsutrustning och datorer",
  46150: "46150 - Provisionshandel med möbler, hushålls- och järnhandelsvaror",
  46160: "46160 - Provisionshandel med textilier, kläder, skodon och lädervaror",
  46170: "46170 - Provisionshandel med livsmedel, drycker och tobak",
  46180: "46180 - Provisionshandel med annat specialsortiment",
  46190: "46190 - Provisionshandel med blandat sortiment",
  46210: "46210 - Partihandel med spannmål, råtobak, utsäde och djurfoder",
  46220: "46220 - Partihandel med blommor och växter",
  46230: "46230 - Partihandel med levande djur",
  46240: "46240 - Partihandel med hudar, skinn och läder",
  46310: "46310 - Partihandel med frukt och grönsaker",
  46320: "46320 - Partihandel med kött- och köttvaror",
  46330: "46330 - Partihandel med mejeriprodukter, ägg, matolja och matfett",
  46340: "46340 - Partihandel med drycker",
  46350: "46350 - Partihandel med tobak",
  46360: "46360 - Partihandel med socker, choklad och sockerkonfektyrer",
  46370: "46370 - Partihandel med kaffe, te, kakao och kryddor",
  46380: "46380 - Partihandel med andra livsmedel, bl.a. fisk samt skal- och blötdjur",
  46390: "46390 - Icke specialiserad partihandel med livsmedel, drycker och tobak",
  46410: "46410 - Partihandel med textilier",
  46420: "46420 - Partihandel med kläder och skodon",
  46431: "46431 - Partihandel med elektriska hushållsmaskiner och -apparater",
  46432: "46432 - Partihandel med ljud- och bildanläggningar samt videoutrustning",
  46433: "46433 - Partihandel med inspelade band och skivor för musik och bild",
  46434: "46434 - Partihandel med elartiklar",
  46435: "46435 - Partihandel med fotografiska och optiska produkter",
  46440: "46440 - Partihandel med glas och porslin, rengöringsmedel",
  46450: "46450 - Partihandel med parfym och kosmetika",
  46460: "46460 - Partihandel med medicinsk utrustning och apoteksvaror",
  46470: "46470 - Partihandel med möbler, mattor och belysningsartiklar",
  46480: "46480 - Partihandel med ur och guldsmedsvaror",
  46491: "46491 - Partihandel med sport- och fritidsartiklar",
  46492: "46492 - Partihandel med kontorsförbrukningsvaror",
  46499: "46499 - Partihandel med övriga hushållsvaror",
  46510: "46510 - Partihandel med datorer och kringutrustning samt programvara",
  46521: "46521 - Partihandel med elektronikkomponenter",
  46522: "46522 - Partihandel med teleprodukter",
  46610: "46610 - Partihandel med jordbruksmaskiner och -utrustning",
  46620: "46620 - Partihandel med verktygsmaskiner",
  46630: "46630 - Partihandel med gruv-, bygg- och anläggningsmaskiner",
  46640: "46640 - Partihandel med textil-, sy- och stickmaskiner",
  46650: "46650 - Partihandel med kontorsmöbler",
  46660: "46660 - Partihandel med andra kontorsmaskiner och kontorsutrustning",
  46691: "46691 - Partihandel med mät- och precisionsinstrument",
  46692: "46692 - Partihandel med datoriserad materialhanteringsutrustning",
  46699: "46699 - Partihandel med diverse övriga maskiner och utrustning",
  46710: "46710 - Partihandel med bränslen",
  46720: "46720 - Partihandel med metaller och metallmalmer",
  46731: "46731 - Partihandel med virke och andra byggmaterial",
  46732: "46732 - Partihandel med sanitetsgods",
  46741: "46741 - Partihandel med järnhandelsvaror",
  46742: "46742 - Partihandel med VVS-varor",
  46750: "46750 - Partihandel med kemiska produkter",
  46761: "46761 - Partihandel med industriförnödenheter",
  46762: "46762 - Partihandel med emballage",
  46769: "46769 - Partihandel med övriga insatsvaror",
  46771: "46771 - Partihandel med uttjänta fordon",
  46772: "46772 - Partihandel med metallavfall och metallskrot",
  46773: "46773 - Partihandel med avfall och skrot av icke-metall",
  46900: "46900 - Övrig partihandel",
  47111: "47111 - Varuhus- och stormarknadshandel, mest livsmedel, drycker och tobak",
  47112: "47112 - Livsmedelshandel med brett sortiment, ej varuhus eller stormarknad",
  47191: "47191 - Annan varuhus- och stormarknadshandel",
  47199: "47199 - Övrig detaljhandel med brett sortiment",
  47210: "47210 - Specialiserad butikshandel med frukt och grönsaker",
  47220: "47220 - Specialiserad butikshandel med kött och charkuterier",
  47230: "47230 - Specialiserad butikshandel med fisk, skal- och blötdjur",
  47241: "47241 - Specialiserad butikshandel med bröd och konditorivaror",
  47242: "47242 - Specialiserad butikshandel med konfektyrer",
  47250: "47250 - Specialiserad butikshandel med alkoholhaltiga och andra drycker",
  47260: "47260 - Specialiserad butikshandel med tobaksvaror",
  47291: "47291 - Specialiserad butikshandel med hälsokost",
  47299: "47299 - Övrig specialiserad butikshandel med livsmedel",
  47300: "47300 - Specialiserad detaljhandel med drivmedel",
  47410: "47410 - Specialiserad butikshandel med datorer, programvara, data- och tv-spel",
  47420: "47420 - Specialiserad butikshandel med telekommunikationsutrustning",
  47430: "47430 - Specialiserad butikshandel med ljud- och bildanläggningar samt videoutrustning",
  47510: "47510 - Specialiserad butikshandel med textilier",
  47521: "47521 - Specialiserad butikshandel med virke och byggvaror",
  47522: "47522 - Specialiserad butikshandel med järn- och VVS-varor",
  47523: "47523 - Specialiserad butikshandel med färger, fernissor och lacker",
  47531: "47531 - Specialiserad butikshandel med mattor och annan vägg- och golvbeklädnad",
  47532: "47532 - Specialiserad butikshandel med inredningstextilier",
  47540: "47540 - Specialiserad butikshandel med elektriska hushållsmaskiner och hushållsapparater",
  47591: "47591 - Specialiserad butikshandel med möbler för hemmet",
  47592: "47592 - Specialiserad butikshandel med kontorsmöbler",
  47593: "47593 - Specialiserad butikshandel med glas, porslin och andra bosättningsvaror",
  47594: "47594 - Specialiserad butikshandel med belysningsartiklar",
  47595: "47595 - Specialiserad butikshandel med musikinstrument och noter",
  47610: "47610 - Specialiserad butikshandel med böcker",
  47621: "47621 - Specialiserad butikshandel med tidningar",
  47622: "47622 - Specialiserad butikshandel med kontorsförbrukningsvaror",
  47630: "47630 - Specialiserad butikshandel med inspelade och oinspelade band och skivor för musik och bild",
  47641: "47641 - Specialiserad butikshandel med sport- och fritidsartiklar utom cyklar och båtar",
  47642: "47642 - Specialiserad butikshandel med cyklar",
  47643: "47643 - Specialiserad butikshandel med båtar",
  47650: "47650 - Specialiserad butikshandel med spel och leksaker",
  47711: "47711 - Specialiserad butikshandel med herr-, dam- och barnkläder, blandat",
  47712: "47712 - Specialiserad butikshandel med herrkläder",
  47713: "47713 - Specialiserad butikshandel med damkläder",
  47714: "47714 - Specialiserad butikshandel med barnkläder",
  47715: "47715 - Specialiserad butikshandel med pälsar",
  47721: "47721 - Specialiserad butikshandel med skodon",
  47722: "47722 - Specialiserad butikshandel med väskor, reseffekter och lädervaror",
  47730: "47730 - Apotekshandel",
  47740: "47740 - Specialiserad butikshandel med sjukvårdsartiklar",
  47750: "47750 - Specialiserad butikshandel med kosmetika och hygienartiklar",
  47761: "47761 - Specialiserad butikshandel med blommor och andra växter, frön och gödselmedel",
  47762: "47762 - Specialiserad butikshandel med små sällskapsdjur",
  47771: "47771 - Specialiserad butikshandel med ur",
  47772: "47772 - Specialiserad butikshandel med guldsmedsvaror och smycken",
  47781: "47781 - Specialiserad butikshandel med glasögon och andra optiska artiklar utom fotoutrustning",
  47782: "47782 - Specialiserad butikshandel med fotoutrustning",
  47783: "47783 - Specialiserad butikshandel med konst samt galleriverksamhet",
  47784: "47784 - Specialiserad butikshandel med mynt och frimärken",
  47789: "47789 - Övrig specialiserad butikshandel",
  47791: "47791 - Specialiserad butikshandel med antikviteter och begagnade böcker",
  47792: "47792 - Specialiserad butikshandel med övriga begagnade varor",
  47793: "47793 - Auktioner i butik",
  47810: "47810 - Torg- och marknadshandel med livsmedel, drycker och tobak",
  47820: "47820 - Torg- och marknadshandel med textilier, kläder och skodon",
  47890: "47890 - Torg- och marknadshandel med övriga varor",
  47911: "47911 - Postorderhandel och detaljhandel på Internet med brett sortiment",
  47912: "47912 - Postorderhandel och detaljhandel på Internet med beklädnadsvaror",
  47913: "47913 - Postorderhandel och detaljhandel på Internet med böcker och andra mediavaror",
  47914: "47914 - Postorderhandel och detaljhandel på Internet med datorer och annan elektronisk utrustning",
  47915: "47915 - Postorderhandel och detaljhandel på Internet med sport- och fritidsutrustning",
  47916: "47916 - Postorderhandel och detaljhandel på Internet med bosättningsvaror",
  47917: "47917 - Auktioner på Internet",
  47919: "47919 - Postorderhandel och detaljhandel på Internet med övriga varor",
  47991: "47991 - Provisionsdetaljhandel (ej auktioner)",
  47992: "47992 - Ambulerande och tillfällig handel med livsmedel",
  47993: "47993 - Ambulerande och tillfällig handel med övriga varor",
  47994: "47994 - Auktioner ej i butik eller på Internet",
  47999: "47999 - Övrig detaljhandel ej i butik",
  49100: "49100 - Järnvägstransport, passagerartrafik",
  49200: "49200 - Järnvägstransport, godstrafik",
  49311: "49311 - Linjebussverksamhet",
  49319: "49319 - Övrig kollektivtrafik",
  49320: "49320 - Taxitrafik",
  49390: "49390 - Annan landtransport av passagerare",
  49410: "49410 - Vägtransport, godstrafik",
  49420: "49420 - Flyttjänster",
  49500: "49500 - Transport i rörsystem",
  50101: "50101 - Reguljär sjötrafik över hav och kust av passagerare",
  50102: "50102 - Icke reguljär sjötrafik över hav och kust av passagerare",
  50201: "50201 - Reguljär sjötrafik över hav och kust av gods",
  50202: "50202 - Icke reguljär sjötrafik över hav och kust av gods",
  50301: "50301 - Reguljär sjötrafik på inre vattenvägar av passagerare",
  50302: "50302 - Icke reguljär sjötrafik på inre vattenvägar av passagerare",
  50401: "50401 - Reguljär sjötrafik på inre vattenvägar av gods",
  50402: "50402 - Icke reguljär sjötrafik på inre vattenvägar av gods",
  51101: "51101 - Reguljär lufttransport av passagerare",
  51102: "51102 - Icke reguljär lufttransport av passagerare",
  51211: "51211 - Reguljär lufttransport av gods",
  51212: "51212 - Icke reguljär lufttransport av gods",
  51220: "51220 - Rymdfart",
  52100: "52100 - Magasinering och varulagring",
  52211: "52211 - Bärgning för landtransport",
  52219: "52219 - Övriga stödtjänster till landtransport",
  52220: "52220 - Stödtjänster till sjötransport",
  52230: "52230 - Stödtjänster till lufttransport",
  52241: "52241 - Hamngodshantering",
  52249: "52249 - Övrig godshantering",
  52290: "52290 - Övriga stödtjänster till transport",
  53100: "53100 - Postbefordran via nationella posten",
  53201: "53201 - Annan postbefordran",
  53202: "53202 - Bud- och kurirverksamhet",
  53203: "53203 - Tidningsdistribution",
  55101: "55101 - Hotellverksamhet med restaurangrörelse",
  55102: "55102 - Drift av konferensanläggningar",
  55103: "55103 - Hotellverksamhet utan restaurangrörelse",
  55201: "55201 - Vandrarhemsverksamhet",
  55202: "55202 - Stugbyverksamhet m.m.",
  55300: "55300 - Campingplatsverksamhet",
  55900: "55900 - Annan logiverksamhet",
  56100: "56100 - Restaurangverksamhet",
  56210: "56210 - Cateringverksamhet vid enskilda evenemang",
  56291: "56291 - Drift av personalmatsalar",
  56292: "56292 - Centralköksverksamhet för sjukhus",
  56293: "56293 - Centralköksverksamhet för skolor, omsorgs- och andra institutioner",
  56294: "56294 - Cateringverksamhet för transportsektorn",
  56299: "56299 - Övrig cateringverksamhet",
  56300: "56300 - Barverksamhet",
  58110: "58110 - Bokutgivning",
  58120: "58120 - Publicering av kataloger och sändlistor",
  58131: "58131 - Dagstidningsutgivning",
  58132: "58132 - Annonstidningsutgivning",
  58140: "58140 - Utgivning av tidskrifter",
  58190: "58190 - Annan förlagsverksamhet",
  58210: "58210 - Utgivning av dataspel",
  58290: "58290 - Utgivning av annan programvara",
  59110: "59110 - Produktion av film, video och TV-program",
  59120: "59120 - Efterproduktion av film, video och TV-program",
  59130: "59130 - Film-, video- och TV-programdistribution",
  59140: "59140 - Filmvisning",
  59200: "59200 - Ljudinspelning och fonogramutgivning",
  60100: "60100 - Sändning av radioprogram",
  60200: "60200 - Planering av TV-program och sändningsverksamhet",
  61100: "61100 - Trådbunden telekommunikation",
  61200: "61200 - Trådlös telekommunikation",
  61300: "61300 - Telekommunikation via satellit",
  61900: "61900 - Annan telekommunikation",
  62010: "62010 - Dataprogrammering",
  62020: "62020 - Datakonsultverksamhet",
  62030: "62030 - Datordrifttjänster",
  62090: "62090 - Andra IT- och datatjänster",
  63110: "63110 - Databehandling, hosting o.d.",
  63120: "63120 - Webbportaler",
  63910: "63910 - Nyhetsservice",
  63990: "63990 - Övriga informationstjänster",
  64110: "64110 - Centralbanksverksamhet",
  64190: "64190 - Annan monetär finansförmedling",
  64201: "64201 - Holdingverksamhet i finansiella koncerner",
  64202: "64202 - Holdingverksamhet i icke-finansiella koncerner",
  64301: "64301 - Investeringsfonder",
  64309: "64309 - Andra fonder och liknande finansiella enheter",
  64910: "64910 - Finansiell leasing",
  64920: "64920 - Annan kreditgivning",
  64991: "64991 - Investment- och riskkapitalbolagsverksamhet",
  64992: "64992 - Handel med och förvaltning av värdepapper, för egen räkning",
  64993: "64993 - Förvaltning av och handel med värdepapper för en begränsad och sluten krets av ägare",
  64999: "64999 - Diverse övrig finansförmedling",
  65111: "65111 - Fondanknuten livförsäkring",
  65119: "65119 - Övrig livförsäkring",
  65120: "65120 - Skadeförsäkring",
  65200: "65200 - Återförsäkring",
  65300: "65300 - Pensionsfondsverksamhet",
  66110: "66110 - Administrativa tjänster till finansiella marknader",
  66120: "66120 - Verksamhet utförd av värdepappers- och varumäklare",
  66190: "66190 - Andra stödtjänster till finansiella tjänster utom försäkring och pensionsfondsverksamhet",
  66210: "66210 - Risk- och skadebedömning",
  66220: "66220 - Verksamhet utförd av försäkringsombud och försäkringsmäklare",
  66290: "66290 - Andra stödtjänster till försäkring och pensionsfondsverksamhet",
  66301: "66301 - Förvaltning av investeringsfonder",
  66309: "66309 - Annan fondförvaltning",
  68100: "68100 - Handel med egna fastigheter",
  68201: "68201 - Uthyrning och förvaltning av egna eller arrenderade bostäder",
  68202: "68202 - Uthyrning och förvaltning av egna eller arrenderade industrilokaler",
  68203: "68203 - Uthyrning och förvaltning av egna eller arrenderade, andra lokaler",
  68204: "68204 - Förvaltning i bostadsrättsföreningar",
  68209: "68209 - Övrig förvaltning av egna eller arrenderade fastigheter",
  68310: "68310 - Fastighetsförmedling",
  68320: "68320 - Fastighetsförvaltning på uppdrag",
  69101: "69101 - Advokatbyråverksamhet",
  69102: "69102 - Juridiska byråers verksamhet m.m.",
  69103: "69103 - Patentbyråverksamhet m.m.",
  69201: "69201 - Redovisning och bokföring",
  69202: "69202 - Revision",
  69203: "69203 - Skatterådgivning",
  70100: "70100 - Verksamheter som utövas av huvudkontor",
  70210: "70210 - PR och kommunikation",
  70220: "70220 - Konsultverksamhet avseende företags organisation",
  71110: "71110 - Arkitektverksamhet",
  71121: "71121 - Teknisk konsultverksamhet inom bygg- och anläggningsteknik",
  71122: "71122 - Teknisk konsultverksamhet inom industriteknik",
  71123: "71123 - Teknisk konsultverksamhet inom elteknik",
  71124: "71124 - Teknisk konsultverksamhet inom energi-, miljö- och VVS-teknik",
  71129: "71129 - Övrig teknisk konsultverksamhet",
  71200: "71200 - Teknisk provning och analys",
  72110: "72110 - Bioteknisk forskning och utveckling",
  72190: "72190 - Annan naturvetenskaplig och teknisk forskning och utveckling",
  72200: "72200 - Samhällsvetenskaplig och humanistisk forskning och utveckling",
  73111: "73111 - Reklambyråverksamhet",
  73112: "73112 - Direktreklamverksamhet",
  73119: "73119 - Övrig reklamverksamhet",
  73120: "73120 - Mediebyråverksamhet och annonsförsäljning",
  73200: "73200 - Marknads- och opinionsundersökning",
  74101: "74101 - Industri- och produktdesignverksamhet",
  74102: "74102 - Grafisk designverksamhet",
  74103: "74103 - Inredningsarkitektverksamhet",
  74201: "74201 - Porträttfotoverksamhet",
  74202: "74202 - Reklamfotoverksamhet",
  74203: "74203 - Press- och övrig fotografverksamhet",
  74204: "74204 - Fotolaboratorieverksamhet",
  74300: "74300 - Översättning och tolkning",
  74900: "74900 - Övrig verksamhet inom juridik, ekonomi, vetenskap och teknik",
  75000: "75000 - Veterinärverksamhet",
  77110: "77110 - Uthyrning och leasing av personbilar och lätta motorfordon",
  77120: "77120 - Uthyrning och leasing av lastbilar och andra tunga motorfordon",
  77210: "77210 - Uthyrning och leasing av fritids- och sportutrustning",
  77220: "77220 - Uthyrning av videokassetter och dvd-skivor",
  77290: "77290 - Uthyrning och leasing av andra hushållsartiklar och varor för personligt bruk",
  77310: "77310 - Uthyrning och leasing av jordbruksmaskiner och jordbruksredskap",
  77320: "77320 - Uthyrning och leasing av bygg- och anläggningsmaskiner",
  77330: "77330 - Uthyrning och leasing av kontorsmaskiner och kontorsutrustning (inklusive datorer)",
  77340: "77340 - Uthyrning och leasing av fartyg och båtar",
  77350: "77350 - Uthyrning och leasing av flygplan",
  77390: "77390 - Uthyrning och leasing av övrig utrustning och övriga maskiner och materiella tillgångar",
  77400: "77400 - Leasing av immateriell egendom och liknande produkter, med undantag för upphovsrättsskyddade verk",
  78100: "78100 - Arbetsförmedling och rekrytering",
  78200: "78200 - Personaluthyrning",
  78300: "78300 - Övrigt tillhandahållande av personalfunktioner",
  79110: "79110 - Resebyråverksamhet",
  79120: "79120 - Researrangemang",
  79900: "79900 - Turist- och bokningsservice",
  80100: "80100 - Säkerhetsverksamhet",
  80200: "80200 - Säkerhetssystemtjänster",
  80300: "80300 - Spanings- och detektivverksamhet",
  81100: "81100 - Fastighetsrelaterade stödtjänster",
  81210: "81210 - Lokalvård",
  81221: "81221 - Rengöring av byggnader",
  81222: "81222 - Skorstensfejarverksamhet",
  81290: "81290 - Annan rengöring",
  81300: "81300 - Skötsel och underhåll av grönytor",
  82110: "82110 - Kombinerade kontorstjänster",
  82190: "82190 - Kopiering, dokumentsammanställning och andra specialiserade kontorstjänster",
  82200: "82200 - Callcenterverksamhet",
  82300: "82300 - Arrangemang av kongresser och mässor",
  82910: "82910 - Inkassoföretags och kreditupplysningsföretags verksamhet",
  82920: "82920 - Förpackningsverksamhet",
  82990: "82990 - Övriga företagstjänster",
  84111: "84111 - Stats- och kommunledning, lagstiftning och övergripande planering",
  84112: "84112 - Inspektion, kontroll, tillståndsgivning",
  84113: "84113 - Skatteförvaltning, indrivning",
  84114: "84114 - Samhällelig informationsförsörjning",
  84115: "84115 - Personalförvaltning och andra allmänna stödtjänster",
  84121: "84121 - Administration av grundskole- och gymnasieskoleutbildning",
  84122: "84122 - Administration av universitets- och högskoleutbildning samt forskning",
  84123: "84123 - Administration av hälso- och sjukvård",
  84124: "84124 - Administration av omsorg och socialtjänst",
  84125: "84125 - Administration av program för kultur, miljö, boende m.m.",
  84131: "84131 - Administration av infrastrukturprogram",
  84132: "84132 - Administration av program för jordbruk, skogsbruk, jakt och fiske",
  84133: "84133 - Administration av arbetsmarknadsprogram",
  84139: "84139 - Administration av andra näringslivsprogram",
  84210: "84210 - Utrikesförvaltning",
  84221: "84221 - Militärt försvar",
  84222: "84222 - Gemensam verksamhet för totalförsvaret",
  84223: "84223 - Civilt försvar och frivilligförsvar",
  84231: "84231 - Åklagarverksamhet",
  84232: "84232 - Domstolsverksamhet",
  84233: "84233 - Kriminalvård",
  84240: "84240 - Polisverksamhet",
  84250: "84250 - Brand- och räddningsverksamhet",
  84300: "84300 - Obligatorisk socialförsäkring",
  85100: "85100 - Förskoleutbildning",
  85201: "85201 - Grundskoleutbildning och förskoleklass",
  85202: "85202 - Utbildning inom grundsärskola",
  85311: "85311 - Studieförberedande gymnasial utbildning",
  85312: "85312 - Kommunal vuxenutbildning o.d.",
  85321: "85321 - Gymnasial yrkesutbildning",
  85322: "85322 - Utbildning inom gymnasiesärskola",
  85323: "85323 - Annan gymnasial utbildning",
  85324: "85324 - Yrkesförarutbildning m.m.",
  85410: "85410 - Eftergymnasial utbildning vid annat än universitet och högskola",
  85420: "85420 - Universitets- eller högskoleutbildning",
  85510: "85510 - Sport- och fritidsutbildning",
  85521: "85521 - Kommunala kulturskolans utbildning",
  85522: "85522 - Övrig musik-, dans- och kulturell utbildning",
  85530: "85530 - Trafikskoleverksamhet",
  85591: "85591 - Arbetsmarknadsutbildning",
  85592: "85592 - Folkhögskoleutbildning",
  85593: "85593 - Studieförbundens och frivilligorganisationernas utbildning",
  85594: "85594 - Personalutbildning",
  85599: "85599 - Diverse övrig utbildning",
  85600: "85600 - Stödverksamhet för utbildningsväsendet",
  86101: "86101 - Sluten primärvård",
  86102: "86102 - Specialiserad sluten somatisk hälso- och sjukvård på sjukhus",
  86103: "86103 - Specialiserad sluten psykiatrisk hälso- och sjukvård på sjukhus",
  86211: "86211 - Primärvårdsmottagningar med läkare m.m.",
  86212: "86212 - Annan allmän öppen hälso- och sjukvård, ej primärvård",
  86221: "86221 - Specialistläkarverksamhet inom öppenvård, på sjukhus",
  86222: "86222 - Specialistläkarverksamhet inom öppenvård, ej på sjukhus",
  86230: "86230 - Tandläkarverksamhet",
  86901: "86901 - Medicinsk laboratorieverksamhet m.m.",
  86902: "86902 - Ambulanstransporter och ambulanssjukvård",
  86903: "86903 - Primärvård, ej läkare",
  86904: "86904 - Tandhygienistverksamhet",
  86905: "86905 - Fysioterapeutisk verksamhet o.d.",
  86909: "86909 - Annan öppen hälso- och sjukvård, utan läkare",
  87100: "87100 - Boende med sjuksköterskevård",
  87201: "87201 - Boende med särskild service för personer med utvecklingsstörning eller psykiska funktionshinder",
  87202: "87202 - Boende med särskild service för barn och ungdomar med missbruksproblem",
  87203: "87203 - Boende med särskild service för vuxna med missbruksproblem",
  87301: "87301 - Vård och omsorg i särskilda boendeformer för äldre personer",
  87302: "87302 - Vård och omsorg i särskilda boendeformer för personer med funktionshinder",
  87901: "87901 - Heldygnsvård med boende för barn och ungdomar med sociala problem",
  87902: "87902 - Omsorg och sociala insatser i övriga boendeformer för vuxna",
  88101: "88101 - Öppna sociala insatser för äldre personer",
  88102: "88102 - Öppna sociala insatser för personer med funktionshinder",
  88910: "88910 - Dagbarnvård",
  88991: "88991 - Öppna sociala insatser för barn och ungdomar med sociala problem",
  88992: "88992 - Öppna sociala insatser för vuxna med missbruksproblem",
  88993: "88993 - Övriga öppna sociala insatser för vuxna",
  88994: "88994 - Humanitära insatser",
  88995: "88995 - Drift av flyktingförläggning",
  90010: "90010 - Artistisk verksamhet",
  90020: "90020 - Stödtjänster till artistisk verksamhet",
  90030: "90030 - Litterärt och konstnärligt skapande",
  90040: "90040 - Drift av teatrar, konserthus o.d.",
  91011: "91011 - Biblioteksverksamhet",
  91012: "91012 - Arkivverksamhet",
  91020: "91020 - Museiverksamhet",
  91030: "91030 - Vård av historiska minnesmärken och byggnader och liknande sevärdheter",
  91040: "91040 - Drift av botaniska trädgårdar, djurparker och naturreservat",
  92000: "92000 - Spel- och vadhållningsverksamhet",
  93111: "93111 - Drift av skidsportanläggningar",
  93112: "93112 - Drift av golfbanor",
  93113: "93113 - Drift av motorbanor",
  93114: "93114 - Drift av trav- och galoppbanor",
  93119: "93119 - Drift av sporthallar, idrottsplatser och andra sportanläggningar",
  93120: "93120 - Sportklubbars och idrottsföreningars verksamhet",
  93130: "93130 - Drift av gymanläggningar",
  93191: "93191 - Tävling med hästar",
  93199: "93199 - Övrig sportverksamhet",
  93210: "93210 - Nöjes- och temaparksverksamhet",
  93290: "93290 - Övrig fritids- och nöjesverksamhet",
  94111: "94111 - Intressebevakning inom branschorganisationer",
  94112: "94112 - Intressebevakning inom arbetsgivarorganisationer",
  94120: "94120 - Intressebevakning inom yrkesorganisationer",
  94200: "94200 - Intressebevakning inom arbetstagarorganisationer",
  94910: "94910 - Verksamhet i religiösa samfund",
  94920: "94920 - Verksamhet i politiska organisationer",
  94990: "94990 - Verksamhet i andra intresseorganisationer",
  95110: "95110 - Reparation av datorer och kringutrustning",
  95120: "95120 - Reparation av kommunikationsutrustning",
  95210: "95210 - Reparation av hemelektronik",
  95220: "95220 - Reparation av hushållsapparater samt av utrustning för hem och trädgård",
  95230: "95230 - Lagning av skodon och lädervaror",
  95240: "95240 - Reparation av möbler och heminredning",
  95250: "95250 - Reparation av ur och guldsmedsvaror",
  95290: "95290 - Reparation av andra hushållsartiklar och personliga artiklar",
  96011: "96011 - Industri- och institutionstvätt",
  96012: "96012 - Konsumenttvätt",
  96021: "96021 - Hårvård",
  96022: "96022 - Skönhetsvård",
  96030: "96030 - Begravningsverksamhet",
  96040: "96040 - Kroppsvård",
  96090: "96090 - Övriga konsumenttjänster",
  97000: "97000 - Förvärvsarbete i hushåll",
  98100: "98100 - Hushållens produktion av diverse varor för eget bruk",
  98200: "98200 - Hushållens produktion av diverse tjänster för eget bruk",
  99000: "99000 - Verksamhet vid internationella organisationer, utländska ambassader o.d.",
});
