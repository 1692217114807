import React from "react";

import * as verificationAPI from "api2/verification";
import useTable from "components/tables/btable/useTable";
import BaseCommentsModal from "./BaseCommentsModal";

function VerificationCommentsModal({ handleClose, companyId, verification }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const onClose = (comments) => {
    handleClose();
    document.body.dispatchEvent(new Event("comments/changed"));
    updateRow({ ...verification, comments });
  };

  return (
    <BaseCommentsModal
      handleClose={onClose}
      companyId={companyId}
      itemId={verification.id}
      apiCreate={verificationAPI.comment.create}
      apiList={verificationAPI.comment.list}
      apiRemove={verificationAPI.comment.remove}
    />
  );
}

export default VerificationCommentsModal;
