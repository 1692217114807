import React from "react";

import * as companyAPI from "api/company";
import useTable from "components/tables/btable/useTable";
import { withInitialAsync } from "hooks/useAsync";
import FinancialYearForm from "./FinancialYearForm";

function FinancialYearEditWidget({ data: item, companyId, id, userPermCodes }) {
  const {
    dataActions: { reload, updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };

  return (
    <FinancialYearForm
      companyId={companyId}
      financialYear={item}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
      userPermCodes={userPermCodes}
    />
  );
}

const EnhancedFinancialYearEditWidget = withInitialAsync(
  FinancialYearEditWidget,
  ({ companyId, id }) =>
    React.useCallback(
      (cancelToken) => companyAPI.getCompanyFinancialYear(companyId, id, { cancelToken }),
      [companyId, id]
    ),
  {},
  false
);

export default EnhancedFinancialYearEditWidget;
