import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { accountBalance } from "api2/reports";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";

const BalanceStateContext = React.createContext({});

const initialState = {};

function BalanceProvider({ initialAccounts, companyId, children }) {
  const {
    financialYear: { id: financialYearSelected },
  } = useContext(FinancialYearStateContext);
  const [balances, setBalances] = useState(initialState);
  const [serverBalances, setServerBalances] = useState(initialState);
  useEffect(() => {
    if (initialAccounts.length) {
      accountBalance(companyId, {
        for_accounts: initialAccounts,
        financial_year: financialYearSelected,
      }).then((response) => {
        const _balances = Object.assign({}, ...response.data.map((acc) => ({ [acc.number]: acc.balance })));
        setServerBalances(_balances);
        setBalances(_balances);
      });
    }
  }, [initialAccounts, companyId, financialYearSelected]);

  const fetchBalance = useCallback(
    (accountNumber) => {
      if (serverBalances[accountNumber] === undefined) {
        accountBalance(companyId, {
          for_accounts: [accountNumber],
          financial_year: financialYearSelected,
        }).then((response) => {
          setServerBalances((state) => ({
            ...state,
            [accountNumber]: response.data.length ? response.data[0].balance || 0 : 0,
          }));
          setBalances((state) => ({
            ...state,
            [accountNumber]: response.data.length ? response.data[0].balance || 0 : 0,
          }));
        });
      }
    },
    [serverBalances, companyId, financialYearSelected]
  );

  const updateBalances = useCallback(
    (transactions) => {
      const _balances = { ...serverBalances };
      let amount;
      transactions
        .filter((t) => !!t.account)
        .forEach((t) => {
          if (t.id && !t.is_deleted) {
            amount = 0;
          } else {
            amount = (t.debit || 0) - (t.credit || 0);
          }
          _balances[t.account.number] += amount || 0;
        });
      setBalances(_balances);
    },
    [serverBalances]
  );

  const state = useMemo(
    () => ({
      balances,
      fetchBalance,
      updateBalances,
    }),
    [balances, fetchBalance, updateBalances]
  );

  return <BalanceStateContext.Provider value={state}>{children}</BalanceStateContext.Provider>;
}

export { BalanceStateContext };

export default BalanceProvider;
