import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BTable from "components/tables/btable";
import Filters from "./EventFilters";
import Actions from "./EventActions";

function EventTable() {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "description",
        label: t("common:description"),
      },
      {
        field: "event_type",
        label: t("common:eventType"),
        render: (values) => (values.event_type ? t(`options:${values.event_type}`) : ""),
      },
      {
        field: "period",
        label: t("common:dates.period"),
        canSort: false,
        render(values) {
          if (values.event_type === "vat_yearly_se" || values.event_type === "vat_yearly_eu") {
            return `${t("others:fYearEnds")} ${values.date_end}`;
          }
          return `${values.date_start} - ${values.date_end}`;
        },
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
    ],
    [t]
  );

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.total")}:</strong>
      {responseHeaders["x-pagination-count"]}
    </div>
  );

  return (
    <BTable
      headers={headers}
      selectable={false}
      FiltersComponent={<Filters />}
      renderActions={(row) => <Actions row={row} />}
      renderTotals={renderTotals}
    />
  );
}

export default EventTable;
