import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import React, { useCallback, useContext } from "react";

import { withTranslation } from "react-i18next";
import { SelectionContext } from "state/providers/SelectionProvider";
import BPagination from "./BPagination";
import useTable from "./useTable";
import Headers from "./Headers";
import TableLoader from "./TableLoader";

function BTable({
  t,
  headers,
  FiltersComponent,
  renderActions,
  renderTotals,
  renderSelect,
  getRowProps,
  selectable,
  tableResponsive = true,
  tableClassName = "",
  ExtraHeaderComponent = null,
}) {
  const { data, pagination, loading, responseHeaders, setOrdering, tableId, filters } = useTable();
  const { selection, toggleSelection, toggleAll } = useContext(SelectionContext);

  // eslint-disable-next-line react/no-unstable-nested-components
  function SelectTD({ item }) {
    const result = renderSelect(item, filters);
    if (result === undefined) {
      return (
        <td
          className="selectable"
          onClick={() => {
            toggleSelection(tableId, item);
          }}
        >
          <div className="checkbox custom-control checkbox-primary">
            <input type="checkbox" className="d-none" checked={!!(selection[tableId] || {})[item.id]} readOnly />
            <label className="custom-control-label" />
          </div>
        </td>
      );
    }
    return <td className="selectable">{result}</td>;
  }

  const onSelectAll = useCallback(() => {
    if (!selectable) {
      return;
    }

    toggleAll(tableId, data, filters);
  }, [tableId, data, toggleAll, selectable, filters]);

  return (
    <div className="b-table">
      {loading && <TableLoader />}
      <div className="table-filters-group">{FiltersComponent}</div>
      <Table responsive={tableResponsive} bordered striped hover className={tableClassName}>
        <thead>
          <Headers
            initialHeaders={headers}
            onSort={setOrdering}
            actionColumn={false}
            selectColumn={selectable}
            tableId={tableId}
            onSelectAll={onSelectAll}
          />
          {ExtraHeaderComponent}
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={`ctr.${item.id}`} {...getRowProps(item)}>
              {selectable && <SelectTD item={item} />}
              {headers.map((header, i) => (
                <td
                  key={`ctd${header.field}`}
                  className={header.getRowClass ? header.getRowClass(item) : header.className}
                  {...header.extraTdProps}
                >
                  {header.render ? header.render(item) : item[header.field] || "-"}
                  {i === headers.length - 1 && !!renderActions && (
                    <div className="div-actions">{renderActions(item)}</div>
                  )}
                </td>
              ))}
            </tr>
          ))}
          {!data.length && (
            <tr>
              <td colSpan={headers.length + (selectable ? 2 : 1)}>{t("common:noResultsFound")}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="table-footer">
        <div className="totals">{!!renderTotals && renderTotals(responseHeaders)}</div>
        {pagination.count > 1 && <BPagination pagination={pagination} />}
      </div>
    </div>
  );
}

BTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      render: PropTypes.func,
      canSort: PropTypes.bool,
    })
  ),
  getRowProps: PropTypes.func,
  renderSelect: PropTypes.func,
};

BTable.defaultProps = {
  getRowProps: () => {},
  renderSelect: () => undefined,
};

export default withTranslation("common")(BTable);
