import { useAuthState } from "hooks/useAuth";
import React, { Fragment, useState, useMemo, useCallback } from "react";

import { PERMISSION_MODULES } from "utils/permissions/permissions";

import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import ChildCheckboxes from "./ChildCheckboxes";

import ParentCheckBoxes from "./ParentCheckboxes";
import GlobalCheckboxes from "./GlobalCheckboxes";

function EmployeePermissionsTable({ permsChecked, setPermsChecked }) {
  const { user } = useAuthState();

  const [activeParentRow, setActiveParentRow] = useState(0);

  // If user is not consultant, hide their permissions
  const permissionModules = useMemo(() => {
    if (!user.is_consult) {
      return PERMISSION_MODULES.map((permission) => {
        return {
          ...permission,
          children: permission?.children?.filter((child) => {
            return !child.forConsult;
          }),
        };
      });
    }

    return PERMISSION_MODULES;
  }, [user.is_consult]);

  const expandParentRow = useCallback(
    (rowIndex) => {
      if (rowIndex === activeParentRow) {
        setActiveParentRow(-1);
      } else {
        setActiveParentRow(rowIndex);
      }
    },
    [activeParentRow]
  );

  return (
    <>
      <section className="headers">
        <table>
          <thead>
            <tr>
              <th className="empty" />
              <GlobalCheckboxes
                permissionModules={permissionModules}
                permsChecked={permsChecked}
                setPermsChecked={setPermsChecked}
              />
            </tr>
          </thead>
        </table>
      </section>
      <Scrollbars className="body" style={{ height: 415 }}>
        <table>
          <tbody>
            {permissionModules.map((permissionModule, rowIndex) => {
              const withExpandClass = rowIndex === activeParentRow ? "expand" : "";

              return (
                <Fragment key={permissionModule.id}>
                  <ParentCheckBoxes
                    setPermsChecked={setPermsChecked}
                    permsChecked={permsChecked}
                    permissionModule={permissionModule}
                    withExpandClass={withExpandClass}
                    expandParentRow={() => expandParentRow(rowIndex)}
                  />
                  {!!withExpandClass &&
                    permissionModule.children.map((child) => {
                      return (
                        <ChildCheckboxes
                          key={child.code}
                          setPermsChecked={setPermsChecked}
                          child={child}
                          permsChecked={permsChecked}
                          withExpandClass={withExpandClass}
                          permissionModule={permissionModule}
                        />
                      );
                    })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </Scrollbars>
    </>
  );
}

EmployeePermissionsTable.propTypes = {
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
};

export default EmployeePermissionsTable;
