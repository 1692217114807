import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import EmployeeSchedulesForm from "components/forms/company-settings-forms/EmployeeSchedulesForm";

function EmployeeScheduleExtendedTable({ companyId }) {
  const { t } = useTranslation("company");
  const headers = useMemo(
    () => [
      {
        field: "user_name",
        label: t("common:name"),
        sortField: "user__first_name",
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          renderExpandedRow={(row) => <EmployeeSchedulesForm companyId={companyId} employeeId={row.id} />}
        />
      </Col>
    </Row>
  );
}

export default EmployeeScheduleExtendedTable;
