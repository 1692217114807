import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();
  const setLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
  };

  return (
    <div className="language-switcher">
      {i18n.languages.map((lang) => (
        <button
          key={lang}
          type="button"
          className={cx("btn btn-link", { active: i18n.language === lang })}
          onClick={() => setLanguage(lang)}
        >
          {t(lang)}
        </button>
      ))}
    </div>
  );
}

export default LanguageSwitcher;
