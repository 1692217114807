import React from "react";
import { useNavigate } from "react-router-dom";

import EventForm from "components/forms/EventForm";

function EventAddPage() {
  const navigate = useNavigate();

  const onCreate = (newItem) => {
    navigate(-1);
  };

  const event = {
    event_type: "vat_monthly",
    due_date: null,
    date_start: null,
    date_end: null,
  };
  return <EventForm event={event} onSave={onCreate} />;
}

export default EventAddPage;
