import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import Filters from "./TimeReportFilters";
import Actions from "./TimeReportActions";

function TimeReportTable({ companyId, selectable = false }) {
  const { t } = useTranslation("common");
  const headers = useMemo(
    () => [
      {
        field: "project_name",
        label: t("common:project"),
        canSort: false,
      },
      {
        field: "user_name",
        label: t("common:employee"),
        canSort: true,
      },
      {
        field: "at_date",
        label: t("common:dates.date"),
        canSort: true,
      },
      {
        field: "text",
        label: t("common:description"),
        className: "w-300px",
        canSort: false,
        render: (values) => `${values.text.substring(0, 103)}...`,
      },
      {
        field: "time_spent",
        label: t("common:time"),
        canSort: true,
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return {
      className: cx(row.status === 1 ? "paid" : "draft", {
        "has-message": !!row.comments.length,
      }),
    };
  };

  const renderTotals = (responseHeaders) => (
    <>
      <div>
        <strong>{t("common:money.quantity")}:</strong>
        {responseHeaders["x-pagination-count"]}
      </div>
      <div>
        <strong>{t("common:timeSpent")}:</strong>
        {responseHeaders["x-pagination-balance"]}
      </div>
    </>
  );

  const renderSelect = (item) => {
    return item.status === 1 ? "" : undefined;
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
      renderSelect={renderSelect}
      getRowProps={getRowProps}
    />
  );
}

export default TimeReportTable;
