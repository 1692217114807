import { CHECKING, LOGGED_IN, LOGGED_OUT, UNAUTHORIZED, UPDATE_USER_PROPS } from "state/types";

const initialState = {
  user: null,
  authenticated: false,
  loading: true,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case CHECKING:
      return { user: null, authenticated: false, loading: true };
    case LOGGED_IN:
      return { user: action.payload.user, authenticated: true, loading: false };
    case LOGGED_OUT:
      return { user: null, authenticated: false, loading: false };
    case UNAUTHORIZED:
      return { user: null, authenticated: false, loading: false };
    case UPDATE_USER_PROPS:
      return { user: action.payload.user, authenticated: true, loading: false };
    default:
      throw new Error(`Unhandled type: ${action.type}`);
  }
}

export default userReducer;
