import { formatDate, isBefore, parseDate } from "utils/date";
import client from "./client";

export function save(taskData) {
  if (taskData.id) {
    return client.put(`/time-tracker/tasks/${taskData.id}/`, taskData);
  }
  return client.post(`/time-tracker/tasks/`, taskData);
}

export function remove(taskId) {
  return client.delete(`/time-tracker/tasks/${taskId}/`);
}

export function details(taskId) {
  return client.get(`/time-tracker/tasks/${taskId}/`).then((response) => {
    response.data.deadline_date = parseDate(response.data.deadline_date);
    response.data.overdue = isBefore(response.data.deadline_date, new Date());
    return response;
  });
}

export function startTimer(taskId) {
  return client.patch(`/time-tracker/tasks/${taskId}/start/`);
}

export function stopTimer(taskId) {
  return client.patch(`/time-tracker/tasks/${taskId}/stop/`);
}

export function finish(taskId, data) {
  return client.patch(`/time-tracker/tasks/${taskId}/finish/`, data);
}

export function revertFinish(taskId) {
  return client.patch(`/time-tracker/tasks/${taskId}/not_finish/`);
}

export function getActiveTask() {
  return client.get(`/time-tracker/tasks/running_task/`).catch((error) => null);
}

export function updateTimeLog(logId, data) {
  return client.patch(`/time-tracker/time-logs/${logId}/`, data);
}

export function removeTimeLog(logId) {
  return client.delete(`/time-tracker/time-logs/${logId}/`);
}

export function createAbsenceTimeLogs(officeId, absence) {
  return client.post("/time-tracker/time-logs/create_absence_time_logs/", {
    date_from: formatDate(absence.date_from),
    date_to: formatDate(absence.date_to),
    hours_per_day: parseFloat(absence.hours_per_day),
    comment: `${absence.comment}`,
    reason: absence.reason.value,
    office: officeId,
  });
}

export const workTime = {
  isPlaying: (officeId) =>
    client.get(`/time-tracker/work-time/is_playing/`, {
      params: {
        office: officeId,
      },
    }),
  play: (officeId) =>
    client.get("/time-tracker/work-time/play/", {
      params: {
        office: officeId,
      },
    }),
  stop: (officeId) =>
    client.get("/time-tracker/work-time/stop/", {
      params: {
        office: officeId,
      },
    }),
};

export const timeGrid = {
  getLogs: (officeId, dateFrom, dateTo) =>
    client.get(`/time-tracker/time-grid/`, {
      params: {
        office: officeId,
        date_from: formatDate(dateFrom),
        date_to: formatDate(dateTo),
      },
    }),
  getMonthlyLockDates: (officeId, dateFrom, dateTo) =>
    client.get(`/time-tracker/lock-dates/${officeId}/month/`, {
      params: {
        date_from: formatDate(dateFrom),
        date_to: formatDate(dateTo),
      },
    }),
  getDisputedAbsenceReports: (officeId, config) =>
    client.get(`/time-tracker/lock-dates/${officeId}/latest_disputed_absence_report/`, { ...config }),
  resendAbsenceReportApproval: (officeId, reportId) =>
    client.post(`/time-tracker/lock-dates/${officeId}/send_to_approval/`, {
      report: reportId,
    }),
  lockDate: (officeId, day) =>
    client.post(`/time-tracker/lock-dates/${officeId}/lock/`, {
      lock_date: formatDate(day),
    }),
  unlockDate: (officeId, day) =>
    client.post(`/time-tracker/lock-dates/${officeId}/unlock/`, {
      lock_date: formatDate(day),
    }),
};
