import { useEffect, useState } from "react";

export const useModalOnPage = () => {
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const isModalOnPage = document.querySelector(".modal-dialog");
    setIsModal(!!isModalOnPage);
  }, []);

  return isModal;
};

export default useModalOnPage;
