import { OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";

function CompanyIndicators({ reko }) {
  const { t } = useTranslation("company");

  return (
    <span className="indicator-container">
      <OverlayTrigger
        trigger={reko ? ["hover", "click"] : []}
        placement="bottom"
        overlay={
          <Tooltip id={String(_.uniqueId("tooltip_"))} className="list-indicators-tooltip">
            <p>
              <i className={cx("fe-alert-triangle", "status-icon", reko.tabs.color)} />
              {reko.tabs.data}
            </p>
            <p>
              <i className={cx("fe-alert-circle", "status-icon", reko.kyc.color)} />
              <b>KYC:</b> {reko.kyc.data}
            </p>
            <p>
              <i className={cx("fe-shield", "status-icon", reko.sa.color)} />
              <b>{t("SA")}:</b> {reko.sa.data}
            </p>
            <p>
              <i className={cx("fe-check", "status-icon", reko.bd.color)} />
              <b>{t("basicDocuments")}:</b> {reko.bd.data}
            </p>
          </Tooltip>
        }
      >
        <div>
          <span className={cx("status-dot", reko.tabs.color)} />
          <span className={cx("status-dot", reko.kyc.color)} />
          <span className={cx("status-dot", reko.sa.color)} />
          <span className={cx("status-dot", reko.bd.color)} />
        </div>
      </OverlayTrigger>
    </span>
  );
}

export { CompanyIndicators };
