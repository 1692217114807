import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FieldArray, Form, Formik } from "formik";

import { confirmExecute } from "components/modals/ConfirmModal";
import "./AccountMappingModal.scss";
import * as companyAPI from "api/company";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { AllError, TableGroup } from "components/formik";
import { formatMoney } from "../../../utils/money";

function AccountMappingModal({ bflowAccounts, bankAccounts, companyId, consentId, error, handleClose }) {
  const { t } = useTranslation("company");
  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      accounts: bankAccounts.map((bAccount) => ({
        ...bAccount,
        bflow_account: bAccount.bflow_account ? bflowAccounts.find((a) => a.value === bAccount.bflow_account) : null,
      })),
    },
    onSubmit: (values, { setErrors }) => {
      return companyAPI.psd2.accounts
        .update(
          companyId,
          consentId,
          values.accounts.map((account) => ({
            id: account.id,
            bflow_account: account.bflow_account ? account.bflow_account.value : null,
          }))
        )
        .then(async (response) => {
          document.body.dispatchEvent(new Event("psd/reload"));
          const transInSystem = response.data.transactions_in_system;
          let wipeTransactions = false;
          if (transInSystem) {
            wipeTransactions = await confirmExecute(t("confirm.wipeFetchBankTrans"));
          }
          if (wipeTransactions) {
            companyAPI.psd2.accounts.refetchBankStatement(companyId, consentId).catch(() => {});
          }
          handleClose();
        })
        .catch((response) => {
          if (response.data.error) {
            setErrors({ __all__: response.data.error });
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    },
  };

  return (
    <Modal
      show
      onHide={handleClose}
      size="lg"
      className="bank-mapping-modal"
      scrollable
      keyboard={false}
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header>
        <Modal.Title>{t("psd.mapAccountsTitle")}</Modal.Title>
      </Modal.Header>
      <Formik {...formikProps}>
        {({ values, errors, isSubmitting }) => {
          return (
            <Form noValidate>
              <Modal.Body>
                <Table>
                  <thead>
                    <tr>
                      <th>IBAN</th>
                      <th>BBAN</th>
                      <th>{t("common:money.currency")}</th>
                      <th>{t("common:money.balance")}</th>
                      <th>{t("common:accountF")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray
                      name="accounts"
                      render={() =>
                        values.accounts.map((account, idx) => (
                          <tr key={account.id}>
                            <td>{account.iban}</td>
                            <td>{account.bban || "-"}</td>
                            <td>{account.currency}</td>
                            <td>{formatMoney(account.psd_balance)}</td>
                            <TableGroup.SimpleSelect
                              isClearable
                              required={false}
                              name={`accounts[${idx}].bflow_account`}
                              options={bflowAccounts}
                            />
                          </tr>
                        ))
                      }
                    />
                  </tbody>
                </Table>
                <AllError errors={errors} />
              </Modal.Body>
              <Modal.Body className="modal-actions">
                <Button type="button" variant="link" onClick={handleClose} disabled={isSubmitting}>
                  {t("common:actions.goBack")}
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  {t("common:actions.save")}
                </Button>
              </Modal.Body>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

function AccountMappingModalWrapper({ companyId, consentId, handleClose }) {
  const [data, setData] = useState({
    bankAccounts: [],
    bflowAccounts: [],
    error: "",
  });

  useEffect(() => {
    companyAPI.psd2.accounts
      .list(companyId, consentId)
      .then(async (response) => {
        const bflowAccounts = await companyAPI.psd2.bflowAccounts(companyId);
        setData({ bankAccounts: response.data, bflowAccounts, error: "" });
      })
      .catch((error) => {
        setData({
          bankAccounts: [],
          bflowAccounts: [],
          error: error.data.error,
        });
      });
  }, [companyId, consentId]);

  return (
    <AccountMappingModal
      bankAccounts={data.bankAccounts}
      bflowAccounts={data.bflowAccounts}
      companyId={companyId}
      consentId={consentId}
      handleClose={handleClose}
    />
  );
}

export default AccountMappingModalWrapper;
