import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  TooltipActionButton,
  VerificationBookingButton,
  VerificationCommentsButton,
  VerificationHistoryButton,
} from "components/ui/buttons";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as dpAPI from "api/direct-payments";
import { SelectionContext } from "state/providers/SelectionProvider";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { useCompanyState } from "hooks/useCompany";
import { PermCodeRequired } from "components/perms";
import { codesForDirectPayments } from "components/perms/PermCodes";
import useModal from "hooks/useModal";
import { DPBookPaymentModal } from "components/modals";

function DirectPaymentActions({ row, companyId }) {
  const { t } = useTranslation("others");
  const bookPayModal = useModal();
  const {
    tableId,
    dataActions: { reload },
  } = useTable();
  const {
    company: { payment_provider: paymentProvider },
  } = useCompanyState();
  const { removeSelection } = useContext(SelectionContext);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const show = {
    bookBtn: _.includes(["unpaid"], row.sub_status) || (paymentProvider === "pis" && row.sub_status === "sent_bank"),
    bookingBtn: row.status === 1,
    deleteBtn: _.includes([""], row.sub_status),
    cancelPayBtn: row.sub_status === "sent_bank",
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeDP", { employee: row.receiver }));
    if (answer) {
      dpAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const onCancelPayment = async () => {
    const answer = await confirmExecute(t("confirm.cancelPaymentDP", { employee: row.receiver }));
    if (answer) {
      dpAPI
        .cancelPayment(companyId, row.id)
        .then(() => {
          toast.success(t("msg:updated"));
          reload();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onBookPayModalClose = (saved) => {
    bookPayModal.close();
    if (saved) {
      if (removeSelection) {
        removeSelection(tableId, row);
      }
      reload();
      reloadMenuNumbers();
    }
  };

  return (
    <>
      <VerificationHistoryButton verificationId={row.id} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      <PermCodeRequired code={codesForDirectPayments.bookPayment}>
        {show.bookBtn && (
          <TooltipActionButton
            text={t("common:actions.bookPayment")}
            onClick={bookPayModal.open}
            icon="fas fa-dollar-sign"
          />
        )}
      </PermCodeRequired>
      {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      {show.deleteBtn && (
        <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      )}
      {show.cancelPayBtn && (
        <TooltipActionButton
          variant="danger"
          text={t("common:actions.cancelPayment")}
          onClick={onCancelPayment}
          icon="fas fa-ban"
        />
      )}
      {bookPayModal.show && <DPBookPaymentModal companyId={companyId} dpData={row} handleClose={onBookPayModalClose} />}
    </>
  );
}

export default DirectPaymentActions;
