import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";

function EmployeeExtendedFilters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");

  return (
    <section className="table-filters-left">
      <ButtonGroup aria-label="Status filter" className="status-filter">
        <Button
          variant="outline-secondary"
          active={!filters.mode || filters.mode === "all"}
          onClick={() => setFilters({ mode: "" })}
        >
          {t("common:statuses.all")}
        </Button>
        <Button
          variant="outline-success"
          active={filters.mode === "active"}
          onClick={() => setFilters({ mode: "active" })}
        >
          {t("common:statuses.active")}
        </Button>
        <Button
          variant="outline-warning"
          active={filters.mode === "inactive"}
          onClick={() => setFilters({ mode: "inactive" })}
        >
          {t("common:statuses.inactive")}
        </Button>
      </ButtonGroup>
    </section>
  );
}

export default EmployeeExtendedFilters;
