import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { SearchFilter } from "components/filters";
import { PermCodeRequired } from "components/perms";
import { codesForFileArchive } from "components/perms/PermCodes";

function FileArchiveFilters({ companyId, forStatus }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
      </section>
      <PermCodeRequired code={codesForFileArchive.manage}>
        <section className="table-filters-right">
          <Link to="/archive/file-archive/form" className="btn btn-first">
            <i className="fas fa-plus" /> {t("common:actions.upload")}
          </Link>
        </section>
      </PermCodeRequired>
    </>
  );
}

export default FileArchiveFilters;
