import React from "react";
import * as ReactDOM from "react-dom";

import { PayBankIDModal } from "components/modals";
import ConfirmModal from "./ConfirmModal";
import ConfirmModalWithAction from "./ConfirmModalWithAction";
import RemoveConfirmModal from "./RemoveConfirmModal";
import ConfirmInfoModal from "./ConfirmInfoModal";

const confirmRoot = document.createElement("div");
const body = document.querySelector("body");
body.appendChild(confirmRoot);

const customConfirm = (DialogContent) =>
  new Promise((res) => {
    const giveAnswer = (answer) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      res(answer);
    };

    ReactDOM.render(<DialogContent giveAnswer={giveAnswer} />, confirmRoot);
  });

const confirmRemove = (description) =>
  customConfirm(({ giveAnswer }) => <RemoveConfirmModal description={description} giveAnswer={giveAnswer} />);

const confirmExecute = (description, btnTitle, cancelBtnTitle, isSafeAction) =>
  customConfirm(({ giveAnswer }) => (
    <ConfirmModal
      description={description}
      btnTitle={btnTitle}
      cancelBtnTitle={cancelBtnTitle}
      giveAnswer={giveAnswer}
      isSafeAction={isSafeAction}
    />
  ));

const confirmExecuteWithAction = (description, onConfirmCallback) =>
  customConfirm(({ giveAnswer }) => (
    <ConfirmModalWithAction description={description} giveAnswer={giveAnswer} onConfirmCallback={onConfirmCallback} />
  ));

const confirmBankID = (bankIDMsg) => {
  return new Promise((resolve, reject) => {
    const onConfirm = (token) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      resolve(token);
    };
    const onCancel = () => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      resolve(null);
    };
    ReactDOM.render(
      <PayBankIDModal handleSuccess={onConfirm} handleClose={onCancel} bankIDMsg={bankIDMsg} />,
      confirmRoot
    );
  });
};

const confirmInfo = (description) =>
  customConfirm(({ giveAnswer }) => <ConfirmInfoModal description={description} giveAnswer={giveAnswer} />);

export { confirmRemove, confirmExecute, confirmExecuteWithAction, confirmBankID, confirmInfo };

export default customConfirm;
