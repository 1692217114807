import React, { useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import * as ciAPI from "api/customer-invoices";
import { formatDate } from "utils/date";
import { roundMoney } from "utils/money";
import { useCompanyState } from "hooks/useCompany";
import useModal from "hooks/useModal";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { CIUseCreditModal } from "../UseCreditModal";

function UseCreditButton({ companyId, invoiceData, handleParentClose }) {
  const { t } = useTranslation("common");
  const useCreditModal = useModal();

  const onOpen = () => {
    document.getElementById("bookCIForm").remove();
    useCreditModal.open();
  };

  const onClose = (creditedInvoice) => {
    handleParentClose(creditedInvoice);
    useCreditModal.close();
  };

  return (
    <>
      {invoiceData.amount_sek > 0 && <Button onClick={onOpen}>{t("common:actions.useCredit")}</Button>}
      {useCreditModal.show && (
        <CIUseCreditModal companyId={companyId} invoiceData={invoiceData} handleClose={onClose} />
      )}
    </>
  );
}

function CIBookPaymentModal({ handleClose, companyId, invoiceData }) {
  const { t } = useTranslation("common");

  const onSave = (values) => {
    return ciAPI
      .manual_pay(companyId, invoiceData.id, {
        ...values,
        exchange_rate: roundMoney(values.exchange_rate, 6),
      })
      .then((response) => {
        toast.success(t("msg:saved"));
        handleClose(true);
      });
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog} id="ciBookModal">
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("common:registerPaymentCI")} ({invoiceData.invoice_no})
        </Modal.Title>
      </Modal.Header>
      <BookForm t={t} onSave={onSave} invoiceData={invoiceData} companyId={companyId} handleClose={handleClose} />
    </Modal>
  );
}

function BookForm({ invoiceData, onSave, t, companyId, handleClose }) {
  const foreignCurrency = invoiceData.amount_currency !== "SEK";
  const [sending, setSending] = useState(false);
  const {
    paymentAccounts: { byId: paymentAccountsById, asOptions: paymentAccountsOptions },
  } = useCompanyState();
  const formikProps = {
    initialValues: {
      payment_date: new Date(),
      amount: roundMoney(invoiceData.balance / invoiceData.exchange_rate) || 0,
      amount_sek: invoiceData.balance,
      exchange_rate: invoiceData.amount_currency === "SEK" ? 1 : invoiceData.exchange_rate,
      billecta_registration: true,
      contra_account: paymentAccountsById[1930],
      before: {
        amount: invoiceData.balance / invoiceData.exchange_rate || 0,
        currency: invoiceData.amount_currency,
        balance: invoiceData.balance,
        exchange_rate: invoiceData.exchange_rate,
      },
    },
    validationSchema: yup.object().shape({
      payment_date: yup.date().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      setSending(true);
      await onSave({
        payment_date: formatDate(values.payment_date),
        amount: values.amount,
        amount_currency: invoiceData.amount_currency,
        exchange_rate: values.exchange_rate,
        account_id: values.contra_account.value,
        billecta_registration: values.billecta_registration,
        book_excess_as_reminder_fee: values.book_excess_as_reminder_fee,
      })
        .catch((error) => {
          setErrors(error.data);
        })
        .finally(() => {
          setSending(false);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, errors, setFieldValue, setTouched, isValid, isSubmitting }) => {
        return (
          <Form id="bookCIForm">
            <Modal.Body>
              <Row>
                <Col lg={6}>
                  <FormGroup.BookingDatePicker
                    name="payment_date"
                    label={t("common:dates.paymentDate")}
                    popperClassName="popper-in-modal"
                    required
                  />
                </Col>
              </Row>
              <fieldset>
                <legend>{t("common:amountReceivedAccBank")}</legend>
                <Row>
                  <Col lg={3}>
                    <FormGroup.MoneyInput
                      name="amount"
                      label={t("common:money.amount")}
                      onChange={(val) => {
                        if (foreignCurrency && val && values.amount_sek) {
                          setFieldValue("exchange_rate", values.amount_sek / val);
                        }
                      }}
                      required
                    />
                  </Col>
                  {foreignCurrency && (
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="exchange_rate"
                        label={t("common:money.exchangeRate")}
                        disabled
                        decimalPlaces={6}
                      />
                    </Col>
                  )}
                  {foreignCurrency && (
                    <Col lg={3}>
                      <FormGroup.MoneyInput
                        name="amount_sek"
                        label={`${t("common:money.amount")} SEK`}
                        onChange={(val) => {
                          if (foreignCurrency && val && values.amount) {
                            setFieldValue("exchange_rate", val / values.amount);
                          }
                        }}
                        required
                      />
                    </Col>
                  )}
                </Row>
                {!!invoiceData.reminder_fee && (
                  <Row>
                    <Col lg={6}>
                      <p>
                        {t("ci:reminderInfoOnBooking", {
                          reminder_fee_amount: invoiceData.reminder_fee,
                          reminder_fee_currency: invoiceData.reminder_fee_currency,
                        })}
                      </p>
                    </Col>
                  </Row>
                )}
              </fieldset>
              <fieldset>
                <legend>{t("balanceBeforeTrans")}</legend>
                <Row>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="before.amount" label={t("common:money.amount")} disabled />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.Input name="before.currency" label={t("common:money.currency")} disabled />
                  </Col>
                  {values.exchange_rate !== 1 && (
                    <>
                      <Col lg={3}>
                        <FormGroup.MoneyInput
                          name="before.balance"
                          label={`${t("common:money.balance")} SEK`}
                          disabled
                        />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.MoneyInput
                          decimalPlaces={6}
                          name="before.exchange_rate"
                          label={t("common:money.exchangeRate")}
                          disabled
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup.SimpleSelect
                      name="contra_account"
                      label={t("common:paymentAccount")}
                      options={paymentAccountsOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <FormGroup.Checkbox
                      title={t("common:options")}
                      label={t("common:billectaRegister")}
                      name="billecta_registration"
                    />
                  </Col>
                </Row>
              </fieldset>

              {errors && errors.__all__ && (
                <Alert className="mt-2 mb-0" variant="danger">
                  {errors.__all__}
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              <UseCreditButton invoiceData={invoiceData} companyId={companyId} handleParentClose={handleClose} />
              <SubmitButton disabled={!isValid || sending} />
            </Modal.Footer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CIBookPaymentModal;
