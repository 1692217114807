import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Button } from "react-bootstrap";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import { useVerificationDispatch } from "hooks/useVerification";
import Filters from "./VerificationPickerFilters";
import Actions from "./VerificationPickerActions";

function VerificationPickerTable({ companyId, selectable = false, onModalClose }) {
  const { t } = useTranslation("common");
  const { open: openVerificationModal } = useVerificationDispatch();
  const headers = useMemo(
    () => [
      {
        field: "series_number",
        label: t("ver:verNumber"),
        render: (values) => (
          <Button variant="link" onClick={() => openVerificationModal(companyId, values)}>
            {values.verification_number}
          </Button>
        ),
      },
      {
        field: "booking_date",
        label: t("dates.bookingDate"),
      },
      {
        field: "title",
        label: t("description"),
      },
      {
        field: "amount_sek",
        label: t("money.amount"),
        className: "text-right",
        render: (values) => formatMoney(values.amount_sek),
      },
    ],
    [t, companyId, openVerificationModal]
  );

  const getRowProps = (row) => {
    return {
      className: cx({ removed: row.status === 2 }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} onModalClose={onModalClose} />}
      getRowProps={getRowProps}
    />
  );
}

export default VerificationPickerTable;
