import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import UploadNavTabs from "./UploadNavTabs";

function UploadLayout() {
  const company = useOutletContext();
  return (
    <div className="tab-card">
      <UploadNavTabs />
      <Outlet context={company} />
    </div>
  );
}

export default UploadLayout;
