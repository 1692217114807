import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import * as taxAPI from "api/taxes";
import useInitialAsync from "hooks/useInitialAsync";
import { formatMoney } from "utils/money";

function Box({ title, children }) {
  return (
    <Card className="text-center mb-1" style={{ height: 120, borderTop: "1px solid #e4e7ea" }}>
      <Card.Header className="pb-0">{title}</Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

function TaxSpecification({ company }) {
  const { loading, item: data } = useInitialAsync(() => taxAPI.taxAuthority.latestUnpaid(company.id));
  const { t } = useTranslation("tax");
  if (loading) {
    return null;
  }
  const toPayFuture = data.balance1630_future < 0 ? -1 * data.balance1630_future : 0;
  return (
    <Row className="mt-2">
      <Col xl={3} lg={4}>
        <Box title={t("common:money.toBePaid")}>
          <h3 className="m-0 p-0">{formatMoney(toPayFuture)} SEK</h3>
          <h7 className="m-0 p-0" style={{ color: "#ADADAD" }}>
            ({t("common:dates.dueDate")} {data.tax ? data.tax.payment_date : new Date().toISOString().slice(0, 10)})
          </h7>
        </Box>
        <p>
          <span style={{ color: "#ADADAD" }}>
            <b>{t("currentTaxBalance")}: </b>
          </span>
          <span style={{ color: "#6E747C" }}>
            <b>{formatMoney(data.balance1630_till_today)} SEK</b>
          </span>
        </p>
      </Col>
      <Col xl={3} lg={4}>
        <Box title={t("taxSpecification")}>
          {data.tax ? (
            <Link to={`/pay/taxes/${data.tax.id}/preview`}>
              <i className="fas fa-search fa-3x" style={{ marginTop: -5 }} />
            </Link>
          ) : (
            <h3 className="m-0 p-0">-</h3>
          )}
        </Box>
      </Col>
    </Row>
  );
}

export default TaxSpecification;
