import React from "react";

import * as officeSupportApi from "api/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportRolesAdditionalForm from "./OfficeSupportRolesAdditionalForm";

function OfficeSupportRolesAdditionalWidget({ data: item, companyId, changeEditedForm }) {
  return (
    <OfficeSupportRolesAdditionalForm companyId={companyId} changeEditedForm={changeEditedForm} initialData={item} />
  );
}

const EnhancedOfficeSupportRolesAdditionalWidget = withInitialAsync(
  OfficeSupportRolesAdditionalWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => officeSupportApi.rolesAdditional.get(companyId, { cancelToken }), [companyId]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportRolesAdditionalWidget;
