import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";
import { NewDocuments, SavedDocuments } from "components/ui/documents/verification-documents";
import { COMPANY_ARCHIVE_ALLOWED_FILE_TYPES } from "components/ui/documents/DropZoneInput";
import * as documentAPI from "api/document";
import { AllError, FormGroup } from "components/formik";
import * as options from "api/options";
import * as archiveAPI from "api/archive";
import { formatDate, parseDate } from "../../../utils/date";
import UnsavedWarn from "../../ui/UnsavedWarn";

function ConsultArchiveForm({ companyId, archive, onSave }) {
  const { t } = useTranslation("common");
  const consultArchiveCategories = options.consultArchiveCategories.asList();
  const [newFiles, setNewFiles] = useState(!archive.id && [{ key: _.uniqueId("nd.") }]);

  const isMonthRequired = (category) => {
    return ["service_agreement_incl_attachments", "kyc"].includes(category);
  };
  const [editMode, setEditMode] = useState(false);

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      setEditMode(dirty || newFiles.length > 1);
    }, [dirty]);
    return null;
  }

  const formikProps = {
    initialValues: {
      ...archive,
      category: options.consultArchiveCategories.getOption(archive.category) || consultArchiveCategories[0],
      month: archive.month ? parseDate(archive.month) : null,
    },
    validationSchema: yup.object().shape({
      title: newFiles.length >= 3 ? yup.string().notRequired() : yup.string().required(),
      category: yup.object().nullable().required(),
      month: yup
        .date()
        .nullable()
        .when("category", (category, schema) => {
          return category && isMonthRequired(category.value) ? schema.required() : schema;
        }),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      let _newFiles = [];
      if (!archive.id) {
        _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
        if (!_newFiles.length && values.category.value !== "meeting_notes") {
          toast.error(t("msg:fixErrors"));
          setErrors({ __all__: t("file.noFile") });
          return false;
        }
      }
      return archiveAPI.consultDocument
        .save(companyId, _newFiles, {
          ...values,
          object_type: "con_doc",
          title: values.title,
          category: values.category.value,
          month: values.month ? formatDate(values.month) : null,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            resetForm({ values });
            setNewFiles([{ key: _.uniqueId("nd.") }]);
            onSave(response.data);
          }
        })
        .catch((errors) => {
          setErrors(errors.data);
          toast.error(t("msg:fixErrors"));
        });
    },
  };

  function onFileSelected(file, index, setFieldValue) {
    const maxCount = 10;
    if (file) {
      const fileCount = file.length + newFiles.length - 1;
      if (fileCount > maxCount) {
        toast.error(t("msg:fileLengthLimit", { max: maxCount }));
        return;
      }
    }
    documentAPI.onNewFileChange({
      file,
      index,
      allFiles: newFiles,
      setFile: setNewFiles,
      max: maxCount,
    });
    if (newFiles.length >= 2 || file?.length >= 2) {
      setFieldValue("title", "");
    } else if (file && file[0]) {
      setFieldValue("title", file[0].name);
    }
  }

  const onCancel = (resetForm) => {
    resetForm();
    setNewFiles([{ key: _.uniqueId("nd.") }]);
  };
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, values, errors, setFieldValue, dirty, resetForm }) => {
        return (
          <Form id="archiveForm">
            <Card>
              <FormUpdateChecker />
              <UnsavedWarn isUnsaved={editMode} />
              <Card.Body>
                <Row>
                  <Col xl={6}>
                    <Row>
                      <Col>
                        <FormGroup.Input
                          label={t("common:name")}
                          name="title"
                          required={newFiles.length - 1 <= 1}
                          disabled={newFiles.length - 1 >= 2}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <FormGroup.SimpleSelect
                          options={consultArchiveCategories}
                          name="category"
                          label={t("common:category")}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup.Input as="textarea" label={t("common:description")} name="description" rows={3} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup.DateMonthPicker
                          isClearable
                          name="month"
                          label={t("common:dates.month")}
                          required={isMonthRequired(values.category?.value)}
                        />
                      </Col>
                    </Row>
                    <AllError errors={errors} />
                    <Row>
                      <Col className="text-right">
                        {dirty && (
                          <Button variant="link" size="lg" onClick={() => onCancel(resetForm)} className="cancel-btn">
                            {t("common:actions.cancel")}
                          </Button>
                        )}
                        <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} className="verification-files">
                    {archive.id && archive.has_file && (
                      <SavedDocuments
                        companyId={companyId}
                        documents={[archive.id]}
                        partUrl="consultant-documents"
                        removable={false}
                      />
                    )}
                    <div>
                      <i className="fas fa-info-circle mr-1" />
                      {t("infoAboutMultiUpload")}
                    </div>
                    <NewDocuments
                      fileSize={1024 * 1024 * 10}
                      fileTypes={COMPANY_ARCHIVE_ALLOWED_FILE_TYPES}
                      documents={newFiles}
                      onChange={({ file, index }) => onFileSelected(file, index, setFieldValue)}
                      multiple
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ConsultArchiveForm;
