import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as contractAPI from "api/contracts";
import { RocketLoader } from "components/ui/loaders";
import ContractTemplateForm from "components/forms/ContractTemplateForm";

function ContractTemplateEditPage() {
  const company = useOutletContext();
  const { id: contractId } = useParams();
  const navigate = useNavigate();
  const { loading, item } = useInitialAsync(() => {
    return contractAPI.templates.details(company.id, contractId);
  });

  const onUpdate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <ContractTemplateForm invoice={item} company={company} onSave={onUpdate} />;
}

export default ContractTemplateEditPage;
