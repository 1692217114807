import React, { useContext } from "react";

import { BSelect } from "components/formik/pickers";
import { FinancialYearDispatchContext, FinancialYearStateContext } from "state/providers/FinancialYearProvider";

function FinancialYearNavBarPicker() {
  const { financialYear, financialYears } = useContext(FinancialYearStateContext);
  const { updateFinancialYear } = useContext(FinancialYearDispatchContext);

  const onSelect = (selected) => {
    updateFinancialYear(selected);
  };

  return (
    <BSelect
      defaultValue={financialYear}
      options={financialYears}
      onChange={onSelect}
      isClearable={false}
      isSearchable={false}
      className="fyear-react-select"
      classNamePrefix="select"
    />
  );
}

export default FinancialYearNavBarPicker;
