import client from "./client";

export function balanceAccount(companyId, account) {
  return client.get(`/select/accounts-balances/`, {
    params: {
      companyId,
      accountNumber: account,
    },
  });
}
//
// export function balanceReport(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/balance_report/`, {
//     params,
//     ...config,
//   });
// }
//
// export function profitAndLoss(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/profit_loss_report/`, {
//     params,
//     ...config,
//   });
// }
//
// export function profitAndLoss12(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/profit_loss_12_months_report/`, {
//     params,
//     ...config,
//   });
// }
//
// export function budgetProfitAndLoss(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/budget_profit_loss_report/`, {
//     params,
//     ...config,
//   });
// }
//
// export function ledgerReport(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/ledger_report/`, {
//     params,
//     ...config,
//   });
// }
//
// export function ledgerCSVReport(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/ledger_csv_report/`, {
//     params,
//     ...config,
//   });
// }
//
// export function basicBalanceReport(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/balance_basic_report/`, {
//     params,
//     paramsSerializer: (args) => {
//       return qs.stringify(args, { indices: false });
//     },
//     ...config,
//   });
// }

// export function fetchSpecification(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/fetch_specification/`, {
//     params,
//     ...config,
//   });
// }

// export function fetchAllSpecifications(companyId, financialYearId, params, config) {
//   return client.get(`/companies/${companyId}/reports/${financialYearId}/fetch_all_specifications/`, {
//     params,
//     ...config,
//   });
// }

export const budget = {
  list: (companyId, financialYearId, params, config) => {
    return client.get(`/companies/${companyId}/budget/${financialYearId}/`, {
      params,
      ...config,
    });
  },
  save: (companyId, financialYearId, data) => {
    return client.post(`/companies/${companyId}/budget/${financialYearId}/save/`, data);
  },
  setMargin: (companyId, margin) => {
    return client.patch(`/companies/${companyId}/set_budget_margin/`, { budget_margin: margin });
  },
};
