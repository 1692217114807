import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { VATReportTable } from "components/tables";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import * as tableAPI from "api/table";

function VATReportListPage({ initialMode, selectable = false, tableId = "vatReport" }) {
  const company = useOutletContext();
  const { mode } = useParams();
  const { financialYear } = useContext(FinancialYearStateContext);
  const initialFilters = {
    mode: initialMode || mode || "",
    ordering: "due_date",
  };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) =>
            tableAPI.companyVatReport(
              company.id,
              {
                ...filters,
                financial_year_id: financialYear && financialYear.id,
              },
              config
            )
          }
          initialFilters={initialFilters}
        >
          <VATReportTable companyId={company.id} selectable={selectable} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default VATReportListPage;
