import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import AccountMatchingForm from "./AccountMatchingForm";
import SIEUploadForm from "./SIEUploadForm";

function SIEFileForm({ companyId }) {
  const { t } = useTranslation("company");
  const [data, setData] = useState({
    step: 1,
    mapping: [],
    companyAccounts: { byId: {}, asOptions: [] },
  });

  const onUploadSuccess = (data2) => {
    const accounts = data2.accounts.map((account) => ({
      ...account,
      value: account.number,
      label: `${account.number} - ${account.name}`,
    }));
    const byId = _.keyBy(accounts, (account) => account.number);
    setData({
      step: 2,
      mapping: data2.partially_mapped_accounts.map((item) => ({
        ...item,
        bflow_account: item.bflow_account_number ? byId[item.bflow_account_number] : null,
      })),
      companyAccounts: {
        byId,
        asOptions: accounts,
      },
    });
  };

  const onCancel = () => {
    setData({
      step: 1,
      mapping: [],
      companyAccounts: { byId: {}, asOptions: [] },
    });
  };

  const { step, mapping, companyAccounts } = data;

  return (
    <Card>
      {step === 1 && <SIEUploadForm companyId={companyId} onUploadSuccess={onUploadSuccess} />}
      {step === 2 && (
        <AccountMatchingForm
          companyId={companyId}
          mapping={mapping}
          companyAccounts={companyAccounts}
          onCancel={onCancel}
          t={t}
        />
      )}
    </Card>
  );
}

export default SIEFileForm;
