import React from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { AllError, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { formatDate, parseDate } from "utils/date";

function PreviewPartialUpdateForm({ invoice, onSubmit }) {
  const { t } = useTranslation("si");

  const formikProps = {
    initialValues: {
      id: invoice.id,
      ocr: invoice.ocr,
      invoice_no: invoice.invoice_no,
      due_date: parseDate(invoice.due_date),
    },
    validationSchema: yup.object().shape({
      ocr: yup.string(),
      invoice_no: yup.string().required(),
      due_date: yup.date().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      onSubmit({ ...values, due_date: formatDate(values.due_date) }).catch((response) => {
        setErrors(response.data);
      });
    },
  };

  return (
    <fieldset className="p-1 border-left-content border-warning">
      <Formik {...formikProps}>
        {({ values, isSubmitting, errors, touched }) => {
          return (
            <Form noValidate>
              <Row>
                <Col xl={4}>
                  <FormGroup.Input label={t("common:invoiceNo")} name="invoice_no" required />
                </Col>
                <Col xl={4}>
                  <FormGroup.Input label="OCR" name="ocr" />
                </Col>
                <Col xl={4}>
                  <FormGroup.DatePicker label={t("common:dates.dueDate")} name="due_date" required />
                </Col>
              </Row>
              <AllError errors={errors} />
              <SubmitButton variant="outline-warning" size="sm" isSubmitting={isSubmitting} title="actions.save" />
            </Form>
          );
        }}
      </Formik>
    </fieldset>
  );
}

export default PreviewPartialUpdateForm;
