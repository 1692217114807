import React, { useState } from "react";
import { NewDocuments } from "components/ui/documents/verification-documents";
import * as documentAPI from "api/document";
import { SubmitButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Alert, Card, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import * as companyAPI from "api/company";

function ImportCompaniesCSVPage() {
  const { t } = useTranslation("msg");
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [result, setResult] = useState({ error: "", companies: [] });

  function replacer(key, value) {
    if (value === null) {
      return "";
    }
    return value;
  }
  const generateCSV = (errors) => {
    const headers = errors.length > 0 ? Object.keys(errors[0]) : [];
    const csvRows = [
      headers.join(";"),
      ...errors.map((row) => headers.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(";")),
    ];

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const timestamp = new Date().toISOString().slice(0, 19).replace(/[:T]/g, "-");
    const filename = `${timestamp}-errors.csv`;

    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const onSubmit = () => {
    const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);

    if (!_newFiles.length) {
      toast.error(t("msg:fileMinOneRequired"));
      return;
    }
    setSubmitting(true);
    companyAPI
      .importCompaniesCSV(_newFiles[0])
      .then((response) => {
        setResult({ error: "", companies: response.data.companies });
        if (response.data.errors && response.data.errors.length > 0) {
          generateCSV(response.data.errors);
        }
        setNewFiles([{ key: _.uniqueId("nd.") }]);
      })
      .catch((error) => {
        if (error.data.__all__) {
          setResult({ error: error.data.__all__, companies: [] });
          setNewFiles([{ key: _.uniqueId("nd.") }]);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Card id="importCompaniesCSV">
      <Card.Body>
        <NewDocuments
          fileTypes={["text/csv", "application/vnd.ms-excel"]}
          documents={newFiles}
          multiple={false}
          onChange={({ file, index }) =>
            documentAPI.onNewFileChange({
              file,
              index,
              allFiles: newFiles,
              setFile: setNewFiles,
              max: 1,
            })
          }
        />
        {result.error && <Alert variant="danger">{result.error}</Alert>}
        {result.companies.length ? <CompaniesImportedTable companies={result.companies} /> : null}
        <SubmitButton title="actions.import" onClick={onSubmit} isSubmitting={isSubmitting} />
      </Card.Body>
    </Card>
  );
}

function CompaniesImportedTable({ companies }) {
  const { t } = useTranslation(["common", "others"]);
  return (
    <Table bordered>
      <thead>
        <tr>
          <th>#</th>
          <th>{t("common:companyName")}</th>
          <th>{t("common:financialYear")}</th>
        </tr>
      </thead>
      <tbody>
        {companies.map((company) => (
          <tr key={company.id}>
            <td>{company.id}</td>
            <td>{company.name}</td>
            <td>{company.financial_year}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={3}>{t("others:importedCompaniesCSVInfo", { count: companies.length })}</th>
        </tr>
      </tfoot>
    </Table>
  );
}

export default ImportCompaniesCSVPage;
