import _ from "lodash";
import { roundMoney } from "./money";

export const emptyTransaction = {
  credit: "",
  debit: "",
  amount: 0,
  account: null,
  title: "",
  is_deleted: false,
  periodisation_config: null,
};

export const appendAutoAccounts = (transaction, tIndex, allTransactions, accountById) => {
  const newTransactions = [...allTransactions];
  if (transaction.autoAdded) {
    _.remove(newTransactions, (item) => item.uid === transaction.autoAdded[0] || item.uid === transaction.autoAdded[1]);
  }
  let t1Acc;
  let t2Acc;
  let tPercent;
  if (!transaction.account) {
    t1Acc = undefined;
    t2Acc = undefined;
    tPercent = undefined;
  } else {
    switch (transaction.account.number) {
      case 4415:
        t1Acc = 2647;
        t2Acc = 2617;
        tPercent = 0.25;
        break;
      case 4416:
        t1Acc = 2647;
        t2Acc = 2627;
        tPercent = 0.12;
        break;
      case 4417:
        t1Acc = 2647;
        t2Acc = 2637;
        tPercent = 0.06;
        break;
      case 4425:
        t1Acc = 2647;
        t2Acc = 2617;
        tPercent = 0.25;
        break;
      case 4426:
        t1Acc = 2647;
        t2Acc = 2627;
        tPercent = 0.12;
        break;
      case 4427:
        t1Acc = 2647;
        t2Acc = 2637;
        tPercent = 0.06;
        break;
      case 4515:
        t1Acc = 2645;
        t2Acc = 2614;
        tPercent = 0.25;
        break;
      case 4516:
        t1Acc = 2645;
        t2Acc = 2624;
        tPercent = 0.12;
        break;
      case 4517:
        t1Acc = 2645;
        t2Acc = 2634;
        tPercent = 0.06;
        break;
      case 4531:
      case 4535:
        t1Acc = 2645;
        t2Acc = 2614;
        tPercent = 0.25;
        break;
      case 4545:
        t1Acc = 2645;
        t2Acc = 2615;
        tPercent = 0.25;
        break;
      case 4546:
        t1Acc = 2645;
        t2Acc = 2625;
        tPercent = 0.12;
        break;
      case 4547:
        t1Acc = 2645;
        t2Acc = 2635;
        tPercent = 0.06;
        break;
      case 5421:
        t1Acc = 2645;
        t2Acc = 2614;
        tPercent = 0.25;
        break;
      case 7400:
        t1Acc = 7530;
        t2Acc = 2514;
        tPercent = 0.2426;
        break;
      default:
        t1Acc = undefined;
        t2Acc = undefined;
        tPercent = undefined;
    }
  }
  if (t1Acc && t2Acc && tPercent) {
    const t1 = {
      ...emptyTransaction,
      uid: _.uniqueId("tran"),
      account: accountById[t1Acc],
      debit: transaction.debit ? roundMoney(transaction.debit * tPercent, 2) : undefined,
      credit: transaction.credit ? roundMoney(transaction.credit * tPercent, 2) : undefined,
      cost_center: transaction.cost_center,
      project: transaction.project,
      autoParent: transaction.uid,
    };
    const t2 = {
      ...emptyTransaction,
      uid: _.uniqueId("tran"),
      account: accountById[t2Acc],
      debit: transaction.credit ? roundMoney(transaction.credit * tPercent, 2) : undefined,
      credit: transaction.debit ? roundMoney(transaction.debit * tPercent, 2) : undefined,
      cost_center: transaction.cost_center,
      project: transaction.project,
      autoParent: transaction.uid,
    };
    if (t1.debit > 0.01 || t1.credit > 0.01) {
      newTransactions.splice(tIndex + 1, 0, t1);
      newTransactions.splice(tIndex + 2, 0, t2);
      newTransactions[tIndex].autoAdded = [t1.uid, t2.uid];
    }
  }
  return newTransactions;
};
