import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function CompareBudgetButton({ onChange }) {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation("reports");
  const onToggle = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <div className="mt-2">
      <Button variant="outline-primary" active={checked} onClick={onToggle}>
        {t("compareToBudget")} {checked ? <i className="fe-check-square" /> : <i className="fe-square" />}
      </Button>
    </div>
  );
}

export default CompareBudgetButton;
