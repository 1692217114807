import React from "react";

function CheckSVG() {
  return (
    <div className="checkmark">
      <svg viewBox="0 0 161.2 161.2">
        <path
          className="path"
          fill="none"
          stroke="#4bd396"
          strokeMiterlimit={10}
          d="M425.9 52.1h0c-2.2-2.6-6-2.6-8.3-.1l-42.7 46.2-14.3-16.4c-2.3-2.7-6.2-2.7-8.6-.1-1.9 2.1-2 5.6-.1 7.7l17.6 20.3.6.9c1.8 2 4.4 2.5 6.6 1.4.7-.3 1.4-.8 2-1.5.3-.3.5-.6.7-.9l46.3-50.1c2-2 2-5.3.2-7.4z"
        />
        <circle
          className="path"
          fill="none"
          stroke="#4bd396"
          strokeWidth={4}
          strokeMiterlimit={10}
          cx={80.6}
          cy={80.6}
          r={62.1}
        />
        <path
          className="path"
          fill="none"
          stroke="#4bd396"
          strokeWidth={6}
          strokeLinecap="round"
          strokeMiterlimit={10}
          d="M113 52.8l-38.9 55.6-25.9-22"
        />
        <circle
          className="spin"
          fill="none"
          stroke="#4bd396"
          strokeWidth={4}
          strokeMiterlimit={10}
          strokeDasharray="12.2175,12.2175"
          cx={80.6}
          cy={80.6}
          r={73.9}
        />
      </svg>
    </div>
  );
}

export default CheckSVG;
