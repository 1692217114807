import _ from "lodash";
import { toast } from "react-toastify";
import i18next from "i18next";
import client from "./client";

function ServerDisabled(error) {
  this.data = "Server is not enabled. Please try again for few minutes.";
  this.error = error;
  this.status = null;
  this.name = "ServerDisabled";
  toast.error(this.data);
  console.warn(this.data);
}

function ServerError(error) {
  this.data = "Error - code 99";
  this.error = error;
  this.status = error.response.status;
  this.name = "ServerError";
  toast.error(this.data);
  console.warn(this.data);
}

function PermissionError(error) {
  this.data = i18next.t("msg:permissionError");
  this.error = error;
  this.status = error.response.status;
  this.name = "PermissionError";
  toast.error(this.data);
  console.warn(this.data);
}

function ValidationError(error) {
  this.data = _.mapValues(error.response.data, (msgs) => {
    return _.isArray(msgs) ? msgs[0] : msgs;
  });
  this.status = error.response.status;
  this.name = "ValidationError";
}

function handleError(error) {
  if (error.message && error.message === "aborted") {
    throw error;
  }
  if (!error.response) {
    throw new ServerDisabled(error);
  }
  if (error.response.status === 400) {
    if (error.response.request.responseType === "blob") {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(new ValidationError(error)));
        };
        reader.onerror = () => {
          reject(error);
        };
        reader.readAsText(error.response.data);
      });
    }
    throw new ValidationError(error);
  } else if (error.response.status === 401) {
    const token = localStorage.getItem("token");
    if (token) {
      localStorage.removeItem("token");
      client.defaults.headers.common.Authorization = "";
      window.location = "/auth/login";
    }
  } else if (error.response.status === 403) {
    throw new PermissionError(error);
  } else if (error.response.status === 500) {
    throw new ServerError(error);
  }
  throw error;
}

function handleFormErrors(error, setErrors) {
  if (error.data && error.data.__all__) {
    toast.error(error.data.__all__, { autoClose: 5000 });
  }
  if (error.status === 400) {
    toast.error(i18next.t("msg:fixErrors"));
    setErrors(error.data);
  }
}

function handleActionErrors(error) {
  if (error.data && error.data.__all__) {
    toast.error(error.data.__all__, { autoClose: 5000 });
  } else if (error.status !== 403) {
    toast.error(i18next.t("msg:canNotExecuteAction"));
  }
}

export { handleError, handleFormErrors, handleActionErrors };
