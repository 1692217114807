import { Col, Modal, Row } from "react-bootstrap";
import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import * as selectAPI from "api/select";
import * as companyAPI from "api/company";
import { SubmitButton } from "components/ui/buttons";
import { AllError, ConsultPicker, FormGroup } from "components/formik";
import { StaffPermRequired } from "components/perms";
import * as options from "api/options";
import { formatDate } from "utils/date";
import { isLastDayOfMonth } from "date-fns";
import { DraggableModalDialog } from "../DraggableModalDialog";

function RegisterCompanyModal({ handleClose }) {
  const { t } = useTranslation("company");
  const companyTypeOptions = options.companyTypes.asList();
  const formikProps = {
    initialValues: {
      name: "",
      org_no: "",
      company_type: "limited",
      office: null,
      create_office: false,
      financial_year_date_start: null,
      financial_year_date_end: null,
      consult: null,
      client_no: null,
    },
    validationSchema: yup.object().shape({
      org_no: yup.string().required(),
      name: yup.string().required(),
      company_type: yup.object().nullable().required(),
      office: yup
        .object()
        .nullable()
        .when("create_office", (value, schema) => {
          return value ? schema : schema.required();
        }),
      financial_year_date_end: yup
        .date()
        .nullable()
        .test("lastDayMonth", function (dateEnd) {
          if (dateEnd && !isLastDayOfMonth(dateEnd)) {
            return this.createError({
              path: this.path,
              message: t("msg:lastDayOfMonth"),
            });
          }
          return true;
        })
        .when("financial_year_date_start", (startDate, schema) => {
          return startDate ? schema.min(startDate, t("common:errors.mustLaterStartDate")) : schema;
        }),
      consult: yup
        .object()
        .nullable()
        .when("create_office", (value, schema) => {
          return !value ? schema.required() : schema;
        }),
    }),
    onSubmit: async (values, { setErrors }) => {
      await companyAPI
        .registerCompany({
          ...values,
          office: values.create_office ? null : values.office.value,
          company_type: values.company_type.value,
          consult: !values.create_office && values.consult ? values.consult.value : null,
          financial_year_date_start: values.financial_year_date_start && formatDate(values.financial_year_date_start),
          financial_year_date_end: values.financial_year_date_end && formatDate(values.financial_year_date_end),
        })
        .then((response) => {
          handleClose({ name: values.name, id: response.data.id }, values.enable_tt);
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };
  async function onOfficeChange(office, setFieldValue) {
    setFieldValue("office", office);
    setFieldValue("consult", null);
  }

  return (
    <Modal
      show
      size="lg"
      className="register-company-modal btn-outline-secondary-blue"
      onHide={handleClose}
      animation={false}
      dialogAs={DraggableModalDialog}
      scrollable
    >
      <Formik {...formikProps}>
        {({ values, isSubmitting, errors, setFieldValue }) => {
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title as="h4">{t("navigation:registerCompany")}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ height: 550 }}>
                <Row>
                  <Col>
                    <FormGroup.Input label={t("common:companyName")} name="name" required />
                  </Col>
                  <Col>
                    <FormGroup.Input label={t("common:orgNo")} name="org_no" required />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup.SimpleSelect
                      label={t("company:companyType")}
                      name="company_type"
                      options={companyTypeOptions}
                      required
                    />
                  </Col>
                  <Col>
                    <FormGroup.Input type="number" label={t("common:customerNo")} name="client_no" min={1} />
                  </Col>
                </Row>
                <StaffPermRequired>
                  <FormGroup.YesNoToggle label={t("createAgencyCompany")} name="create_office" />
                </StaffPermRequired>
                {!values.create_office && (
                  <FormGroup.AsyncSelect
                    label={t("common:agency")}
                    name="office"
                    cacheOptions
                    minSearchLength={0}
                    required={!values.create_office}
                    loadOptions={selectAPI.getOffices}
                    onChange={(selected) => onOfficeChange(selected, setFieldValue)}
                    maxMenuHeight={150}
                  />
                )}
                <>
                  <Row lg={2}>
                    <Col>
                      <ConsultPicker
                        key={values.office?.value}
                        name="consult"
                        officeId={values.office?.value}
                        label={t("common:customerManager")}
                        isDisabled={values.create_office || !values.office}
                        required={!values.create_office}
                        maxMenuHeight={150}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <FormGroup.DatePicker
                            label={`${t("common:financialYear")} ${t("common:starts")}`}
                            name="financial_year_date_start"
                            popperClassName="popper-in-modal"
                            required
                          />
                        </Col>
                        <Col>
                          <FormGroup.DatePicker
                            label={t("common:ends")}
                            popperClassName="popper-in-modal"
                            name="financial_year_date_end"
                            required
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
                <AllError errors={errors} />
              </Modal.Body>
              <Modal.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RegisterCompanyModal;
