import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import DirectPaymentListPage from "./DirectPaymentListPage";

function PayDirectPaymentListPage() {
  const canPay = HasPermCode(codesFor.directPayments.pay);
  return <DirectPaymentListPage tableId="unpaidDirectPayments" initialMode="unpaid" selectable={canPay} />;
}

export default PayDirectPaymentListPage;
