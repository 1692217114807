import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as verAPI from "api2/verification";
import { VerificationPickerTable } from "components/tables";
import TableProvider from "components/tables/btable/provider";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import { DraggableModalDialog } from "../DraggableModalDialog";

function VerificationPickerModal({ companyId, handleClose }) {
  const { t } = useTranslation("common");
  const {
    financialYear: { id: fYearId },
  } = useContext(FinancialYearStateContext);

  const onModalClose = (selected) => {
    handleClose(selected);
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      size="xl"
      onHide={handleClose}
      dialogClassName=""
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("actions.select")} {t("verification")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableProvider
          tableId="verificationsPicker"
          initialFilters={{ financial_year: fYearId }}
          dataSource={(filters, config) => verAPI.companyVerifications(companyId, filters, config)}
        >
          <VerificationPickerTable companyId={companyId} onModalClose={onModalClose} />
        </TableProvider>
      </Modal.Body>
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={handleClose}>
          {t("actions.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default VerificationPickerModal;
