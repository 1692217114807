import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ProductForm from "components/forms/ProductForm";
import { DraggableModalDialog } from "../DraggableModalDialog";

function NewProductModal({ companyId, onSuccess, onCancel }) {
  const { t } = useTranslation("ci");
  const product = {
    Description: "",
    ProductType: "Service",
    IsActive: true,
    UnitPrice: null,
    Units: "",
    VAT: 25,
    ArticleNumber: "",
  };

  const onSave = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onCancel}
      dialogClassName="product-modal"
      size="lg"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.addProduct")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductForm companyId={companyId} product={product} successCallback={onSave} />
      </Modal.Body>
    </Modal>
  );
}

export default NewProductModal;
