import React from "react";

import welcomeSrc from "assets/images/welcome.gif";

import "./WelcomeAnimation.scss";

function WelcomeAnimation() {
  return (
    <div className="row welcomeAnimation">
      <img src={welcomeSrc} alt="" height="500" />
    </div>
  );
}

export default WelcomeAnimation;
