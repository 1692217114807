import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

function SearchInput(props) {
  return (
    <div className="search-input-wrapper">
      <input name="search" type="text" className="form-control search-input" autoComplete="off" disabled {...props} />
      <i className="fas fa-search" />
    </div>
  );
}

function SearchFilter({ onFilter, width = "auto", disabled = false, ...props }) {
  const { t } = useTranslation("common");
  const { hiddenLabel, defaultValue, label } = props;
  const search = _.debounce((event) => {
    onFilter({ term: event.target.value.trim() });
  }, 500);

  if (hiddenLabel) {
    return (
      <div>
        <label className="d-block">&nbsp;</label>
        <SearchInput
          placeholder={`${t("search")}...`}
          style={{ width }}
          onChange={search}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
    );
  }
  if (label) {
    return (
      <div>
        <label className="d-block">{label}</label>
        <SearchInput
          placeholder={`${t("search")}...`}
          style={{ width }}
          onChange={search}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
    );
  }
  return (
    <SearchInput
      placeholder={`${t("search")}...`}
      style={{ width }}
      onChange={search}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
}

export default SearchFilter;
