import React from "react";
import { Col, Row } from "react-bootstrap";

import { PreviewInput } from "components/formik/PreviewInput";
import { ReInvoicedAlert } from "components/ui/alerts";
import { VerificationInlineComments } from "components/modals/CommentsModal";

function MileagePreviewContent({ company, outlay, t, children }) {
  return (
    <>
      <Row>
        <Col lg={4}>
          <PreviewInput label={t("whenTrip")} value={outlay.booking_date} />
        </Col>
        <Col lg={4}>
          <PreviewInput label={t("whereTrip")} value={outlay.trip_from} />
        </Col>
        <Col lg={4}>
          <PreviewInput label={t("common:ends")} value={outlay.trip_to} />
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <PreviewInput label={t("howTrip")} value={outlay.ownership} />
        </Col>
        <Col lg={4}>
          <PreviewInput label={t("fuelType")} value={outlay.fuel_type} />
        </Col>
        <Col lg={4}>
          <PreviewInput label={t("longTrip")} value={`${outlay.distance} km`} />
        </Col>
      </Row>
      <Row>
        <Col>
          <PreviewInput label={t("tripPurpose")} value={outlay.trip_purpose} />
        </Col>
      </Row>
      <Row>
        <Col>
          <PreviewInput label={t("common:money.amount")} value={outlay.amount} />
        </Col>
      </Row>
      <VerificationInlineComments companyId={company.id} itemId={outlay.id} />
      <ReInvoicedAlert invoiceId={outlay.reinvoice_to} />
      <div className="mt-3">
        <hr />
        {children}
      </div>
    </>
  );
}

export default MileagePreviewContent;
