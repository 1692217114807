import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import cx from "classnames";

function CheckboxFilter({ label, title, name, defaultChecked, onFilter, wrapperClass, ...props }) {
  const Component = useCallback(() => {
    return (
      <div className={cx("checkbox checkbox-primary", wrapperClass)}>
        <input
          type="checkbox"
          name={name}
          defaultChecked={defaultChecked}
          id={`id_${name}`}
          onChange={(e) => onFilter({ [name]: e.target.checked })}
          {...props}
        />
        <label htmlFor={`id_${name}`}>{label}</label>
      </div>
    );
  }, [defaultChecked, label, name, onFilter, props, wrapperClass]);

  if (title) {
    return (
      <Form.Group>
        <Form.Label htmlFor={`id_${name}`}>{title}</Form.Label>
        <Component />
      </Form.Group>
    );
  }
  return <Component />;
}

export default CheckboxFilter;
