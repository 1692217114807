import React, { useCallback, useEffect } from "react";

import useModal from "hooks/useModal";
import { DocumentsModal, DocumentsModalRefactored } from "components/modals";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate, useResolvedPath } from "react-router-dom";
import { FileArchiveDocumentModal } from "components/modals/DocumentsModal";
import { TooltipActionButton } from "./TooltipButton";

function DocumentsButton({ companyId, documentsIds, partUrl, text, removable = false }) {
  const { t } = useTranslation("common");
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(documentsIds);
  };

  if (!documentsIds.length) {
    return null;
  }

  return (
    <>
      <TooltipActionButton text={text || t("documents")} onClick={openModal} icon="fas fa-images" />
      {fileModal.show && (
        <DocumentsModal
          partUrl={partUrl}
          companyId={companyId}
          documentIds={fileModal.data}
          handleClose={fileModal.close}
          removable={removable}
        />
      )}
    </>
  );
}

function DocumentsButtonRefactored({ companyId, documentsIds, partUrl, text, removable = false }) {
  const { t } = useTranslation("common");
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(documentsIds);
  };

  if (!documentsIds.length) {
    return null;
  }

  return (
    <>
      <TooltipActionButton text={text || t("documents")} onClick={openModal} icon="fas fa-images" />
      {fileModal.show && (
        <DocumentsModalRefactored
          partUrl={partUrl}
          companyId={companyId}
          documentIds={fileModal.data}
          handleClose={fileModal.close}
          removable={removable}
        />
      )}
    </>
  );
}

function VerificationDocumentsButton({ companyId, verificationId, documentsIds, text }) {
  return (
    <DocumentsButtonRefactored
      text={text}
      companyId={companyId}
      documentsIds={documentsIds}
      partUrl={`/verifications/${verificationId}`}
    />
  );
}

function AssetsDocumentsButton({ companyId, documentsIds }) {
  return <DocumentsButton companyId={companyId} documentsIds={documentsIds} partUrl="asset-documents" removable />;
}

function DocumentDocumentsButton({ companyId, documentsIds, partUrl = "company-documents" }) {
  return <DocumentsButton companyId={companyId} documentsIds={documentsIds} partUrl={partUrl} />;
}

function DocumentsLink({ companyId, documentsIds, partUrl, text }) {
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(documentsIds);
  };

  if (!documentsIds.length) {
    return text;
  }

  return (
    <>
      <Button onClick={openModal} variant="link">
        {text}
      </Button>
      {fileModal.show && (
        <DocumentsModal
          partUrl={partUrl}
          companyId={companyId}
          documentIds={fileModal.data}
          handleClose={fileModal.close}
        />
      )}
    </>
  );
}

function SalaryDocumentsLink({ companyId, documentsIds, text }) {
  return <DocumentsLink companyId={companyId} documentsIds={documentsIds} text={text} partUrl="salary-documents" />;
}

function ConsultDocumentsLink({ companyId, documentsIds, text }) {
  return <DocumentsLink companyId={companyId} documentsIds={documentsIds} text={text} partUrl="consultant-documents" />;
}

function DocumentDocumentsLink({ companyId, documentsIds, text }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { pathname } = useResolvedPath("");
  const partUrl = "company-documents";

  const fileModal = useModal();

  const openModal = useCallback(() => {
    fileModal.open(documentsIds);
  }, [documentsIds, fileModal]);

  const closeModal = () => {
    fileModal.close();
  };

  const saveDocumentIdToState = useCallback(() => {
    navigate(pathname, {
      state: {
        ...state,
        documentId: documentsIds[0],
      },
    });
  }, [documentsIds, navigate, state, pathname]);

  const removeDocumentIdFromState = useCallback(() => {
    navigate(pathname, {
      state: {
        ...state,
        documentId: "",
      },
    });
  }, [navigate, state, pathname]);

  useEffect(() => {
    if (state?.documentId === documentsIds[0]) {
      openModal();
    }
  }, [documentsIds, openModal, state?.documentId]);

  if (!documentsIds.length) {
    return text;
  }

  return (
    <>
      <Button
        onClick={() => {
          saveDocumentIdToState();
          openModal();
        }}
        variant="link"
      >
        {text}
      </Button>
      {fileModal.show && (
        <DocumentsModal
          partUrl={partUrl}
          companyId={companyId}
          documentIds={fileModal.data}
          handleClose={() => {
            removeDocumentIdFromState();
            closeModal();
          }}
        />
      )}
    </>
  );
}

function FileArchiveDocumentsLink({ companyId, archiveId, text, filters }) {
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(archiveId);
  };

  if (!archiveId) {
    return null;
  }
  return (
    <>
      <Button onClick={openModal} variant="link">
        {text}
      </Button>
      {fileModal.show && (
        <FileArchiveDocumentModal
          companyId={companyId}
          archiveId={fileModal.data}
          handleClose={fileModal.close}
          handleOpen={fileModal.open}
          filters={filters}
        />
      )}
    </>
  );
}

function FileArchiveDocumentsButton({ companyId, archiveId, filters }) {
  const { t } = useTranslation("common");
  const fileModal = useModal();

  const openModal = () => {
    fileModal.open(archiveId);
  };

  if (!archiveId) {
    return null;
  }
  return (
    <>
      <TooltipActionButton text={t("documents")} onClick={openModal} icon="fas fa-images" />
      {fileModal.show && (
        <FileArchiveDocumentModal
          companyId={companyId}
          archiveId={fileModal.data}
          handleClose={fileModal.close}
          handleOpen={fileModal.open}
          filters={filters}
        />
      )}
    </>
  );
}

export {
  VerificationDocumentsButton,
  FileArchiveDocumentsButton,
  DocumentDocumentsButton,
  DocumentDocumentsLink,
  FileArchiveDocumentsLink,
  SalaryDocumentsLink,
  ConsultDocumentsLink,
  AssetsDocumentsButton,
};
