import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link, useOutletContext, useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { TaxDirectPaymentTable } from "components/tables";
import * as tableAPI from "api/table";
import TaxSpecification from "components/tables/TaxDirectPaymentTable/TaxSpecification";
import { useTranslation } from "react-i18next";

function TaxDirectPaymentListPage({ initialMode, allowedModes, selectable = false, tableId = "unpaidTaxDP" }) {
  const company = useOutletContext();
  const { t } = useTranslation("tax");
  const { mode } = useParams();
  const initialFilters = {
    mode: initialMode || mode || "unpaid",
  };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => tableAPI.companyTaxDP(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <TaxDirectPaymentTable
            companyId={company.id}
            taxOCR={company.ocr_tax}
            forStatus={initialFilters.mode}
            allowedModes={allowedModes}
            selectable={selectable}
          />
        </TableProvider>
        {initialMode === "unpaid" && (
          <Row>
            <Col className="text-right">
              <Link to="/pay/taxes/form" className="btn btn-outline-primary">
                <i className="fas fa-plus" /> {t("common:actions.addPartialPayment")}
              </Link>
            </Col>
          </Row>
        )}
        {initialMode !== "paid" && <TaxSpecification company={company} />}
      </Card.Body>
    </Card>
  );
}

export default TaxDirectPaymentListPage;
