import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import * as options from "api/options";
import SupplierForm from "./SupplierForm";

function SupplierAddWidget({ companyId }) {
  const supplierPaymentMethods = options.supplierPaymentMethods.asDict();
  const {
    dataActions: { reload },
  } = useTable();
  const supplier = {
    supplier: null,
    payment_method: supplierPaymentMethods.BankGiro,
    contra_account: null,
    bankgiro_number: "",
    plusgiro_number: "",
    clearing_number: "",
    bank_account_number: "",
    contact_email: "",
    payment_terms: 30,
    bic: "",
    iban: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    bank_name: "",
    bank_code: "",
  };

  const { t } = useTranslation("si");
  return (
    <Row>
      <Col>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.connectSupplier")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <SupplierForm companyId={companyId} supplier={supplier} successCallback={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default SupplierAddWidget;
