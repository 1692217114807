import { NavLink } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import Pill from "components/ui/pills/Pill";
import { useMenuNumbersState } from "hooks/useMenuNumbers";
import { PermCodeRequired, codesFor } from "components/perms";

function PayNavTabs() {
  const { t } = useTranslation("navigation");
  const { sent_bank: sentBank } = useMenuNumbersState();
  const badgeClass = "badge-sentBank";
  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      <PermCodeRequired code={codesFor.supplierInvoices.enabled}>
        <li className="nav-item">
          <NavLink to="/payments/supplier-invoices" className="nav-link">
            {t("supplierInvoices")} <Pill number={sentBank.supplier_invoices} badgeClass={badgeClass} />
          </NavLink>
        </li>
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.outlays.enabled}>
        <li className="nav-item">
          <NavLink to="/payments/outlays" className="nav-link">
            {t("outlays")} <Pill number={sentBank.outlays} badgeClass={badgeClass} />
          </NavLink>
        </li>
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.directPayments.enabled}>
        <li className="nav-item">
          <NavLink to="/payments/direct-payments" className="nav-link">
            {t("directPayments")} <Pill number={sentBank.direct_payments} badgeClass={badgeClass} />
          </NavLink>
        </li>
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.salaries.enabled}>
        <li className="nav-item">
          <NavLink to="/payments/salaries" className="nav-link">
            {t("salaries")} <Pill number={sentBank.salaries} badgeClass={badgeClass} />
          </NavLink>
        </li>
      </PermCodeRequired>
      <PermCodeRequired code={codesFor.taxes.enabled}>
        <li className="nav-item">
          <NavLink to="/payments/taxes" className="nav-link">
            {t("taxes")} <Pill number={sentBank.taxes} badgeClass={badgeClass} />
          </NavLink>
        </li>
      </PermCodeRequired>
    </ul>
  );
}

export default PayNavTabs;
