import React from "react";
import verificationReducer, { initialState } from "state/reducers/verificationReducer";

const VerificationStateContext = React.createContext();
const VerificationDispatchContext = React.createContext();

function VerificationProvider({ children }) {
  const [state, dispatch] = React.useReducer(verificationReducer, initialState, undefined);

  return (
    <VerificationStateContext.Provider value={state}>
      <VerificationDispatchContext.Provider value={dispatch}>{children}</VerificationDispatchContext.Provider>
    </VerificationStateContext.Provider>
  );
}

export { VerificationStateContext, VerificationDispatchContext };

export default VerificationProvider;
