import React, { useCallback, useContext, useState } from "react";
import { Alert, Card } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInitialAsync from "hooks/useInitialAsync";
import * as archiveAPI from "api/archive";
import { RocketLoader } from "components/ui/loaders";
import CompanyArchiveCard from "./CompanyArchiveCard";
import { FiltersContext } from "../../../../state/providers/FiltersProvider";
import { useAuthState } from "../../../../hooks/useAuth";

function CompanyArchiveBasicPage({ companyId, data, reloadData, filters }) {
  const { t } = useTranslation("company");
  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  const haveItems =
    Object.values(data).reduce((sum, nextItem) => {
      return sum + nextItem.length;
    }, 0) !== 0;

  return (
    <Card id="company-archive">
      <Card.Body className="grid-1">
        <p className="mb-0">{t("basicArchiveCategoryNote")}.</p>
        {!haveItems && (
          <Alert variant="info" className="mt-1">
            {t("common:NoDocumentsUploaded")}
          </Alert>
        )}
        <div>
          {Object.keys(data)
            .filter((cat) => (isConsult && !filters.projection ? true : data[cat].length))
            .map((cat) => (
              <CompanyArchiveCard
                key={cat}
                companyId={companyId}
                category={cat}
                projection={filters.projection}
                isConsult={isConsult}
                items={data[cat]}
                reloadData={reloadData}
              />
            ))}
        </div>
      </Card.Body>
    </Card>
  );
}

function CompanyArchiveBasicWrapper() {
  const company = useOutletContext();
  const [counter, setCounter] = useState(1); // to refresh
  const { filters } = useContext(FiltersContext);
  const { loading, item } = useInitialAsync(
    () => {
      return archiveAPI.companyDocument.basicList(company.id, { projection: filters.projection });
    },
    {},
    [counter, filters.projection]
  );
  const reloadData = useCallback(() => {
    setCounter((c) => c + 1);
  }, []);

  if (loading) {
    return <RocketLoader />;
  }

  return <CompanyArchiveBasicPage companyId={company.id} data={item} reloadData={reloadData} filters={filters} />;
}

export default CompanyArchiveBasicWrapper;
