import { addMonths, addWeeks, differenceInCalendarDays, getISOWeek, setDate, startOfISOWeek } from "date-fns";
import _ from "lodash";

import { formatMonth, isAfter, isBefore, parseDate } from "utils/date";

function getNextMonths(numberOfMonths = 3) {
  const output = [];
  let today = setDate(new Date(), 1);

  for (let i = 0; i < numberOfMonths; i++) {
    output.push({ date: today, name: formatMonth(today) });
    today = addMonths(today, 1);
  }
  return output;
}

function getWeeks(numberOfWeeks = 15) {
  let currentWeek = startOfISOWeek(new Date());
  const weeks = [];
  for (let i = 0; i < numberOfWeeks; i++) {
    weeks.push({
      date: currentWeek,
      weekNumber: getISOWeek(currentWeek),
    });
    currentWeek = addWeeks(currentWeek, 1);
  }
  return weeks;
}

function getWeeksByDayNumber(weeksByNo, dayNumber) {
  const indexes = [];
  let start = setDate(new Date(), dayNumber);
  if (isBefore(start, new Date())) {
    start = addMonths(start, 1);
  }
  let currentWeek = getISOWeek(start);
  while (weeksByNo[currentWeek]) {
    indexes.push(weeksByNo[currentWeek]);
    start = addMonths(start, 1);
    currentWeek = getISOWeek(start);
  }
  return indexes;
}

function fillByDayNumber(weeksByNo, dayNumber, amount, initialArray) {
  const numberOfWeeks = Object.values(weeksByNo).length;
  let start = setDate(new Date(), dayNumber);
  let currentWeek = getISOWeek(start);
  let output = initialArray;
  if (!initialArray) {
    output = _.fill(Array(numberOfWeeks), 0);
  }
  while (weeksByNo[currentWeek]) {
    output[weeksByNo[currentWeek].index] = amount;
    start = addMonths(start, 1);
    currentWeek = getISOWeek(start);
  }
  return output;
}

function fillSocSec(weeksByNo, socSec) {
  const numberOfWeeks = Object.keys(weeksByNo).length;
  const output = _.fill(Array(numberOfWeeks), 0);
  const day12 = setDate(new Date(), 12);
  const isAfter12 = isAfter(new Date(), day12);
  const day12weeks = getWeeksByDayNumber(weeksByNo, 12);
  day12weeks.forEach((week) => {
    if (isBefore(week.date, day12)) {
      if (isAfter12) {
        // after 12th consider as paid
        output[week.index] = 0;
      } else {
        output[week.index] = socSec.last_month / 1000; // to Ksek
      }
    } else {
      output[week.index] = socSec.this_month / 1000; // to Ksek
    }
  });
  return output;
}

function fillVAT(weeksByNo, vatEvents) {
  const output = _.fill(Array(Object.keys(weeksByNo).length), 0);
  let currentWeek;
  vatEvents.forEach((event) => {
    currentWeek = getISOWeek(parseDate(event.due_date));
    if (weeksByNo[currentWeek]) {
      output[weeksByNo[currentWeek].index] += event.amount / -1000; // to Ksek
    }
  });
  return output;
}

function fillPersonalTax(weeksByNo, personalTax) {
  const numberOfWeeks = Object.keys(weeksByNo).length;
  const output = _.fill(Array(numberOfWeeks), 0);
  const day12 = setDate(new Date(), 12);
  const isAfter12 = isAfter(new Date(), day12);
  const day12weeks = getWeeksByDayNumber(weeksByNo, 12);
  day12weeks.forEach((week) => {
    if (personalTax.this_month) {
      if (week.index !== 0 || (week.index === 0 && isAfter12)) {
        output[week.index] = personalTax.this_month / 1000; // to Ksek
      }
    } else {
      output[week.index] = personalTax.last_month / 1000; // to Ksek
    }
  });
  return output;
}

function fillCompanyTax(weeksByNo, companyTax) {
  const numberOfWeeks = Object.keys(weeksByNo).length;
  const output = _.fill(Array(numberOfWeeks), 0);
  if (!companyTax) {
    return output;
  }
  const day12 = setDate(new Date(), 12);
  const isAfter12 = isAfter(new Date(), day12);
  const day12weeks = getWeeksByDayNumber(weeksByNo, 12);

  day12weeks.forEach((week) => {
    if (week.index !== 0 || (week.index === 0 && !isAfter12)) {
      output[week.index] = companyTax / 1000; // to Ksek
    }
  });
  return output;
}

function groupCI(invoices, numberOfWeeks, weeksByNo, simulation) {
  const today = new Date();
  const grouped = {
    due0: { weeks: _.fill(Array(numberOfWeeks), 0), items: [], sum: 0 },
    due30: { weeks: _.fill(Array(numberOfWeeks), 0), items: [], sum: 0 },
    due90: { weeks: _.fill(Array(numberOfWeeks), 0), items: [], sum: 0 },
    due90p: { weeks: _.fill(Array(numberOfWeeks), 0), items: [], sum: 0 },
    weeks: _.fill(Array(numberOfWeeks), 0),
  };
  invoices.forEach((invoice) => {
    invoice.amount = invoice.amount_sek / 1000; // in Ksek
    const dueDate = parseDate(invoice.due_date);
    const diff = differenceInCalendarDays(today, dueDate);
    if (diff > 90) {
      grouped.due90p.items.push({
        item: invoice,
        weeks: _.fill(Array(numberOfWeeks), 0),
      });
      grouped.due90p.sum += invoice.amount;
    } else if (diff > 30) {
      grouped.due90.items.push({
        item: invoice,
        weeks: _.fill(Array(numberOfWeeks), 0),
      });
      grouped.due90.sum += invoice.amount;
    } else if (diff > 0) {
      grouped.due30.items.push({
        item: invoice,
        weeks: _.fill(Array(numberOfWeeks), 0),
      });
      grouped.due30.sum += invoice.amount;
    } else {
      grouped.due0.items.push({
        item: invoice,
        weeks: _.fill(Array(numberOfWeeks), 0),
      });
      grouped.due0.sum += invoice.amount;
    }
  });
  return grouped;
}

function _getNetWeeks(weeksByNo, nets) {
  const numberOfWeeks = Object.keys(weeksByNo).length;
  const output = _.fill(Array(numberOfWeeks), 0);
  const weeks = getWeeksByDayNumber(weeksByNo, 25);
  for (let i = 0; i < weeks.length; i++) {
    output[weeks[i].index] = -nets[i];
  }
  return output;
}

function _getPersonalWeeks(weeksByNo, personal) {
  const numberOfWeeks = Object.keys(weeksByNo).length;
  const output = _.fill(Array(numberOfWeeks), 0);
  const weeks = getWeeksByDayNumber(weeksByNo, 12);
  for (let i = 0; i < weeks.length; i++) {
    if (weeks[i].date.getMonth() === 0 || weeks[i].date.getMonth() === 7) {
      // In August and January the 12th = 18th
      const newDate = setDate(weeks[i].date, 18);
      const newWeek = getISOWeek(newDate);
      if (newWeek) {
        weeks[i] = weeksByNo[newWeek];
      }
    }
    if (weeks[i]) {
      output[weeks[i].index] = -personal[i] || 0;
    }
  }
  return output;
}

function _getSocSecWeeks(weeksByNo, socSec) {
  return _getPersonalWeeks(weeksByNo, socSec);
}

function sumArrays(...arrays) {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((item, i) => arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0));
}

function simulateSalaries(salaries, weeksByNo) {
  const o = {
    gross: [
      salaries.reduce((sum, salary) => sum + salary.amount1, 0),
      salaries.reduce((sum, salary) => sum + salary.amount2, 0),
      salaries.reduce((sum, salary) => sum + salary.amount3, 0),
    ],
    net: [0, 0, 0],
    personal: [0, 0, 0],
    socSec: [0, 0, 0],
    total: [0, 0, 0],
    items: salaries,
    weeks: [],
    weeksNet: [],
    weeksPersonal: [],
    weeksSocSec: [],
  };
  o.net = [o.gross[0] * 0.7, o.gross[1] * 0.7, o.gross[2] * 0.7];
  o.personal = [o.gross[0] * 0.3, o.gross[1] * 0.3, o.gross[2] * 0.3];
  o.socSec = [o.gross[0] * 0.3142, o.gross[1] * 0.3142, o.gross[2] * 0.3142];
  o.total = [
    o.net[0] + o.personal[0] + o.socSec[0],
    o.net[1] + o.personal[1] + o.socSec[1],
    o.net[2] + o.personal[2] + o.socSec[2],
  ];

  o.weeksNet = _getNetWeeks(weeksByNo, o.net);
  o.weeksPersonal = _getPersonalWeeks(weeksByNo, o.personal);
  o.weeksSocSec = _getSocSecWeeks(weeksByNo, o.socSec);
  o.weeks = sumArrays(o.weeksNet, o.weeksPersonal, o.weeksSocSec);

  return o;
}

function simulateAddons(addons, weeksByNo) {
  const today = new Date();
  const numberOfWeeks = Object.keys(weeksByNo).length;
  const output = {
    weeks: _.fill(Array(numberOfWeeks), 0),
    items: addons.map((addon) => ({
      weeks: _.fill(Array(numberOfWeeks), 0),
      item: addon,
    })),
  };
  output.items.forEach((data) => {
    const dueDate = parseDate(data.item.due_date);
    if (isBefore(dueDate, today) && data.item.repeat === "0") {
      // data.weeks[0] = data.item.amount;
      return;
    }
    let start = dueDate;
    let startWeek = getISOWeek(start);
    switch (data.item.repeat) {
      case "0": {
        const onDue = getISOWeek(dueDate);
        if (weeksByNo[onDue]) {
          data.weeks[weeksByNo[onDue].index] = data.item.amount;
          output.weeks[weeksByNo[onDue].index] += data.item.amount;
        }
        break;
      }
      case "1":
        while (isBefore(start, today)) {
          start = addWeeks(start, 1);
        }
        startWeek = getISOWeek(start);
        while (weeksByNo[startWeek]) {
          data.weeks[weeksByNo[startWeek].index] = data.item.amount;
          output.weeks[weeksByNo[startWeek].index] += data.item.amount;
          start = addWeeks(start, 1);
          startWeek = getISOWeek(start);
        }
        break;
      case "2":
        while (isBefore(start, today)) {
          start = addMonths(start, 1);
        }
        startWeek = getISOWeek(start);
        while (weeksByNo[startWeek]) {
          data.weeks[weeksByNo[startWeek].index] = data.item.amount;
          output.weeks[weeksByNo[startWeek].index] += data.item.amount;
          start = addMonths(start, 1);
          startWeek = getISOWeek(start);
        }
        break;
      case "3":
        while (isBefore(start, today)) {
          start = addMonths(start, 3);
        }
        startWeek = getISOWeek(start);
        while (weeksByNo[startWeek]) {
          data.weeks[weeksByNo[startWeek].index] = data.item.amount;
          output.weeks[weeksByNo[startWeek].index] += data.item.amount;
          start = addMonths(start, 3);
          startWeek = getISOWeek(start);
        }
        break;
      default:
        break;
    }
  });

  return output;
}

function _getRate(part1, invoice) {
  if (part1 === "100") {
    return [invoice.amount];
  }
  if (part1 === "0") {
    return [0];
  }
  const rate = parseInt(part1, 10) / 100;
  return [invoice.amount * rate, invoice.amount - invoice.amount * rate];
}

function simulateCI(groupedCI, simulation, weeksByNo) {
  const output = _.cloneDeep(groupedCI);
  const numberOfWeeks = Object.keys(weeksByNo).length;
  output.weeks = _.fill(Array(numberOfWeeks), 0);
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, section] of Object.entries(output)) {
    if (key === "weeks") {
      // eslint-disable-next-line no-continue
      continue;
    }
    section.weeks = _.fill(Array(numberOfWeeks), 0);
    section.items.forEach((data) => {
      data.weeks = _.fill(Array(numberOfWeeks), 0);
      data.sum += data.item.amount;
      const amounts = _getRate(simulation[key].part1, data.item);
      let weekNumber1;
      let weekNumber2;
      if (simulation[key].part2 === "0") {
        const dueDate = parseDate(data.item.due_date);
        const week = weeksByNo[getISOWeek(dueDate)];
        if (week) {
          weekNumber1 = week.index;
        }
        if (simulation[key].part1 !== "100") {
          weekNumber2 = parseInt(simulation[key].part3, 10) - 1;
        }
      } else {
        weekNumber1 = parseInt(simulation[key].part2, 10) - 1;
        weekNumber2 = parseInt(simulation[key].part3, 10) - 1;
      }
      if (weekNumber1 !== undefined) {
        // eslint-disable-next-line prefer-destructuring
        data.weeks[weekNumber1] = amounts[0];
        section.weeks[weekNumber1] += amounts[0];
        output.weeks[weekNumber1] += amounts[0];
      }
      if (weekNumber2 !== undefined && amounts.length === 2) {
        data.weeks[weekNumber2] += amounts[1];
        section.weeks[weekNumber2] += amounts[1];
        output.weeks[weekNumber2] += amounts[1];
      }
    });
  }

  return output;
}

function fillSI(invoice, weeksByNo) {
  const weeks = Object.values(weeksByNo);
  const numberOfWeeks = weeks[weeks.length - 1].weekNumber;
  const output = _.fill(Array(weeks.length), 0);
  let start = parseDate(invoice.due_date);

  const isPast = isBefore(start, new Date());
  // if(isBefore(start, new Date())){
  //   if(invoice.delay !== '0'){
  //     start = addWeeks(new Date(), invoice.delay);
  //   } else {
  //     start = addWeeks(start, invoice.delay);
  //   }
  // }
  // let start = addWeeks(parseDate(invoice['due_date']), invoice.delay);
  let currentWeek = getISOWeek(start) + parseInt(invoice.delay, 10);

  if (isPast) {
    output[invoice.delay] = invoice.amount;
  }
  if (invoice.repeat === "0") {
    if (weeksByNo[currentWeek]) {
      output[weeksByNo[currentWeek].index] = invoice.amount;
    }
  } else if (invoice.repeat === "1") {
    while (isBefore(start, weeksByNo[numberOfWeeks].date)) {
      if (weeksByNo[currentWeek]) {
        output[weeksByNo[currentWeek].index] += invoice.amount;
      }
      start = addWeeks(start, 1);
      currentWeek = getISOWeek(start);
    }
  } else if (invoice.repeat === "2" || invoice.repeat === "3") {
    while (weeksByNo[numberOfWeeks] && isBefore(start, weeksByNo[numberOfWeeks].date)) {
      if (weeksByNo[currentWeek]) {
        output[weeksByNo[currentWeek].index] += invoice.amount;
      }
      start = addMonths(start, invoice.repeat === "2" ? 1 : 3);
      currentWeek = getISOWeek(start);
    }
  }
  return output;
}

export function groupSI(initialSI, weeksByNo, simulation) {
  const numberOfWeeks = Object.keys(weeksByNo).length;
  const simulationIds = simulation.map((item) => {
    return item.id;
  });
  let untreatedWeeks = _.fill(Array(numberOfWeeks), 0);
  let simulationWeeks = _.fill(Array(numberOfWeeks), 0);
  const output = {
    untreated: [],
    simulation: simulation.map((invoice) => {
      const _weeks = fillSI(invoice, weeksByNo);
      simulationWeeks = sumArrays(simulationWeeks, _weeks);
      return {
        ...invoice,
        weeks: _weeks,
      };
    }),
    weeks: [],
  };

  initialSI.forEach((invoice) => {
    if (!simulationIds.includes(invoice.id)) {
      invoice.delay = "0";
      invoice.repeat = "0";
      invoice.weeks = fillSI(invoice, weeksByNo);
      output.untreated.push({ ...invoice });
      untreatedWeeks = sumArrays(untreatedWeeks, invoice.weeks);
    }
  });
  output.weeks = sumArrays(untreatedWeeks, simulationWeeks);
  return output;
}

export {
  getNextMonths,
  getWeeksByDayNumber,
  getWeeks,
  groupCI,
  simulateAddons,
  simulateCI,
  simulateSalaries,
  sumArrays,
  fillSocSec,
  fillByDayNumber,
  fillPersonalTax,
  fillCompanyTax,
  fillSI,
  fillVAT,
};
