import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { EventTable } from "components/tables";
import * as tableAPI from "api/table";

function EventListPage({ tableId = "events" }) {
  const initialFilters = null;

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => tableAPI.events(filters, config)}
          initialFilters={initialFilters}
        >
          <EventTable />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default EventListPage;
