import React, { useCallback, useMemo, useState } from "react";
import * as companyAPI from "api/company";
import { formatDate } from "utils/date";

const BasketContext = React.createContext(undefined);

const STAGE_INITIAL = "initial";
const STAGE_DECOUPLED = "decoupled";
const STAGE_SUMMARY = "summary";
const STAGE_SINGLE = "single";

function BasketProvider({ companyId, initialBasketId, children }) {
  const [basketId, setBasketId] = useState(initialBasketId || "");
  const [singlePayments, setSinglePayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [stage, setStage] = useState(initialBasketId ? STAGE_SUMMARY : STAGE_INITIAL);
  const reset = useCallback(() => {
    setBasketId("");
    setError("");
    setStage(STAGE_INITIAL);
  }, []);

  const getInitialList = useCallback(
    (verificationIds) => {
      setLoading(true);
      return companyAPI.signingBasket.initialList(companyId, verificationIds).finally(() => {
        setLoading(false);
      });
    },
    [companyId]
  );

  const startSinglePayments = useCallback((payments) => {
    setStage(STAGE_SINGLE);
    setSinglePayments(payments);
  }, []);

  const createBasket = useCallback(
    (paymentsData) => {
      setLoading(true);
      setError("");
      return companyAPI.signingBasket
        .create(
          companyId,
          paymentsData.map((item) => ({ verification: item.id, payment_date: formatDate(item.payment_date) }))
        )
        .then((response) => {
          if (response.data.error) {
            setBasketId(response.data.basket_id);
            setError(response.data.error);
            setStage(STAGE_SUMMARY);
            return null;
          }
          const redirectLink = response.data.redirect_link;
          if (redirectLink) {
            window.location = redirectLink;
          } else {
            setBasketId(response.data.basket_id);
            setStage(STAGE_DECOUPLED);
            setLoading(false);
          }
          return redirectLink;
        })
        .catch((response) => {
          if (response.data.__all__) {
            setError(response.data.__all__);
          } else {
            setError("");
          }
          setLoading(false);
          throw response;
        });
    },
    [companyId]
  );

  const checkSigning = useCallback(() => {
    return companyAPI.signingBasket
      .checkSignStatus(companyId, basketId)
      .then((response) => {
        if (response.data.status === 1) {
          setStage("summary");
        } else if (response.data.status === -1) {
          setError(response.data.error);
          setStage(STAGE_SUMMARY);
        }
        return response;
      })
      .catch((response) => {
        setError(response.data.error);
        setStage(STAGE_INITIAL);
        throw response;
      });
  }, [companyId, basketId]);

  const checkBasket = useCallback(() => {
    setLoading(true);
    return companyAPI.signingBasket
      .checkBasket(companyId, basketId)
      .finally(() => {
        setLoading(false);
      })
      .catch((response) => {
        setError(response.data.__all__);
      });
  }, [companyId, basketId]);

  const stateValue = useMemo(
    () => ({
      companyId,
      stage,
      loading,
      error,
      basketId,
      singlePayments,
      actions: {
        createBasket,
        getInitialList,
        checkSigning,
        checkBasket,
        startSinglePayments,
        reset,
        setLoading,
      },
    }),
    [
      companyId,
      stage,
      loading,
      error,
      basketId,
      singlePayments,
      createBasket,
      getInitialList,
      checkSigning,
      checkBasket,
      startSinglePayments,
      reset,
      setLoading,
    ]
  );
  return <BasketContext.Provider value={stateValue}>{children}</BasketContext.Provider>;
}

export { BasketContext };

export default BasketProvider;
