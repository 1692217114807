import React, { useEffect, useMemo, useState } from "react";
import { companyFileArchivesSwitcher } from "api/table";
import { useTranslation } from "react-i18next";
import "./FileArchiveSwitcher.scss";
import { useQuery } from "utils/others";
import { useLocation, useNavigate } from "react-router-dom";

function FileArchiveSwitcher({ companyId, archiveId, customOpen, passedFilters }) {
  const { t } = useTranslation("common");
  const queryParams = useQuery();
  const navigate = useNavigate();
  const loc = useLocation();
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const term = passedFilters ? passedFilters.term : queryParams.get("term");
  const ordering = passedFilters ? passedFilters.ordering : queryParams.get("ordering");
  const filters = useMemo(
    () => ({
      term,
      ordering,
      status: "new",
    }),
    [term, ordering]
  );
  useEffect(() => {
    companyFileArchivesSwitcher(companyId, archiveId, filters)
      .then((response) => {
        setNext(response.data.next);
        setPrev(response.data.prev);
      })
      .catch(() => {
        setNext(null);
        setPrev(null);
      });
  }, [archiveId, companyId, filters]);

  const goToPrev = () => {
    if (prev) {
      if (customOpen) {
        customOpen(prev);
      } else {
        navigate(`${loc.pathname.replace(archiveId, prev)}${loc.search}`, { replace: true });
      }
    }
  };

  const goToNext = () => {
    if (next) {
      if (customOpen) {
        customOpen(next);
      } else {
        navigate(`${loc.pathname.replace(archiveId, next)}${loc.search}`, { replace: true });
      }
    }
  };

  return (
    <div className="archive-switcher">
      <div>
        {prev && (
          <div className="control" onClick={goToPrev}>
            <i className="fe-chevron-left" /> {t("common:actions.previous")}
          </div>
        )}
      </div>
      <div>
        {next && (
          <div className="control" onClick={goToNext}>
            {t("common:actions.next")} <i className="fe-chevron-right" />
          </div>
        )}
      </div>
    </div>
  );
}

export default FileArchiveSwitcher;
