import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { SalaryDocumentsLink } from "components/ui/buttons/DocumentsButton";
import * as options from "api/options";
import { truncateText } from "utils/text";
import { formatMonth } from "utils/date";
import Actions from "./SalaryArchiveActions";
import Filters from "./SalaryArchiveFilters";

function SalaryArchiveTable({ companyId, selectable = false }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "title",
        label: t("common:name"),
        canSort: false,
        render: (values) =>
          values.type === "document" ? (
            <SalaryDocumentsLink companyId={companyId} documentsIds={[values.id]} text={values.title || "-"} />
          ) : (
            values.title
          ),
      },
      {
        field: "category",
        label: t("common:category"),
        canSort: true,
        render: (values) => options.salaryArchiveCategories.getLabel(values.category),
      },
      {
        field: "description",
        label: t("common:descriptionOrNote"),
        className: "w-300px",
        canSort: false,
        render: (values) => truncateText(values.description, 103),
      },
      {
        field: "month",
        label: t("common:dates.month"),
        canSort: true,
        render: (values) => (values.month ? formatMonth(values.month) : "-"),
      },
    ],
    [t, companyId]
  );

  const getRowProps = (row) => {
    return {
      className: cx({ "has-message": !!row.comments.length }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default SalaryArchiveTable;
