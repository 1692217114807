import client from "./client";

export function save(companyId, financialYearId, data) {
  return client.post(`/companies/${companyId}/financial-years/${financialYearId}/vat-reports/`, data);
}

export function remove(companyId, financialYearId, vatReportId) {
  return client.delete(`/companies/${companyId}/financial-years/${financialYearId}/vat-reports/${vatReportId}/`);
}

export function downloadReportXML(companyId, financialYearId, eventId) {
  return client.get(`/companies/${companyId}/reports/${financialYearId}/events/${eventId}/vat_xml_report/`, {
    responseType: "blob",
  });
}

export function canLockPeriod(companyId, financialYearId, data) {
  return client
    .post(`/companies/${companyId}/financial-years/${financialYearId}/vat-reports/lock_period_check/`, data)
    .then((response) => {
      return response.data.can_lock;
    });
}

export function showWarning(companyId, financialYearId, eventId) {
  return client
    .get(`/companies/${companyId}/reports/${financialYearId}/events/${eventId}/vat-warning/`)
    .then((response) => {
      return response.data.show_warning;
    });
}
