import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useCompanyDispatch } from "hooks/useCompany";
import { useTranslation } from "react-i18next";
import OnboardingWizardLayout from "components/layouts/OnboardingWizardLayout";
import OnboardingProvider, { OnboardingStateContext } from "./provider/OnboardingProvider";
import WelcomeAnimation from "./WelcomeAnimation";
import {
  CompanyInformationStep,
  CompanySettingsStep,
  ConsultSettingsStep,
  EmployeesStep,
  FinishedStep,
  GeneralInformationStep,
  ImportAccountingStep,
  ImportCSVCustomersStep,
  ImportFortnoxStep,
  PreviousSystemStep,
  SaveLedgersStep,
  TTConfigurationStep,
} from "./steps";
import StepsProgress from "./steps/StepsProgress/StepsProgress";
import AccountMappingStep from "./steps/AccountMappingStep/AccountMappingStep";

function OnboardingWizardPage() {
  const { activeStep, companyId, companyName } = useContext(OnboardingStateContext);
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const navigate = useNavigate();

  const renderStep = useMemo(() => {
    switch (activeStep && activeStep.id) {
      case "company_info":
        return <CompanyInformationStep companyId={companyId} />;
      case "tt_config":
        return <TTConfigurationStep />;
      case "consultant_settings":
        return <ConsultSettingsStep />;
      case "previous_system":
        return <PreviousSystemStep companyId={companyId} />;
      case "general_info":
        return <GeneralInformationStep companyId={companyId} />;
      case "company_settings":
        return <CompanySettingsStep companyId={companyId} />;
      case "employees":
        return <EmployeesStep companyId={companyId} />;
      case "import_accounting":
        return <ImportAccountingStep companyId={companyId} />;
      case "import_customers":
        return <ImportCSVCustomersStep companyId={companyId} />;
      case "import_fortnox":
        return <ImportFortnoxStep companyId={companyId} />;
      case "save_general_info":
        return <GeneralInformationStep />;
      case "save_accounting":
        return <AccountMappingStep companyId={companyId} />;
      case "save_ledgers":
        return <SaveLedgersStep companyId={companyId} />;
      case "finished":
        return <FinishedStep companyId={companyId} />;
      default:
        return <div>NOT IMPLEMENTED</div>;
    }
  }, [activeStep, companyId]);

  if (activeStep?.welcome) {
    setTimeout(() => selectCompanyAction(companyId).then(() => navigate("/")), 4000);
    return (
      <OnboardingWizardLayout companyId={companyId} companyName={companyName}>
        <WelcomeAnimation />
      </OnboardingWizardLayout>
    );
  }

  return (
    <OnboardingWizardLayout companyId={companyId} companyName={companyName}>
      {activeStep && (
        <>
          <Card>
            <Card.Body className="p-0 pt-3">
              <StepsProgress />
            </Card.Body>
          </Card>
          {renderStep}
        </>
      )}
    </OnboardingWizardLayout>
  );
}

function OnboardingWizardPageWrapper() {
  const { companyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("company");
  const fortnoxError = searchParams.get("fortnox_error");
  const initialStep = searchParams.get("step");

  useEffect(() => {
    if (fortnoxError) {
      toast.error(t(`onboarding.${fortnoxError}`), { autoClose: 5000 });
      setSearchParams({});
    }
  }, [fortnoxError, t, setSearchParams]);

  return (
    <OnboardingProvider companyId={companyId} initialStep={initialStep}>
      <OnboardingWizardPage />
    </OnboardingProvider>
  );
}

export default OnboardingWizardPageWrapper;
