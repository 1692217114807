import React, { useCallback, useMemo } from "react";

import { getOffice } from "api/office";
import useAsync from "hooks/useAsync";

const OfficeSettingStateContext = React.createContext(undefined);

function OfficeSettingProvider({ officeId, children }) {
  const dataSource = useCallback((cancelToken) => getOffice(officeId, { cancelToken }), [officeId]);
  const [{ data: item, loading }, { updateData }] = useAsync(dataSource, {});

  const stateValue = useMemo(
    () => ({
      info: item,
      loading,
      updateInfo: updateData,
    }),
    [item, loading, updateData]
  );

  return <OfficeSettingStateContext.Provider value={stateValue}>{children}</OfficeSettingStateContext.Provider>;
}

export { OfficeSettingStateContext };

export default OfficeSettingProvider;
