import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as officeSupportApi from "api/office-support";
import useAsync from "hooks/useAsync";
import { Loader } from "components/ui/loaders";
import { codesForOffice } from "components/perms/PermCodes";
import { HasPermCode } from "components/perms";
import { DraggableModalDialog } from "../DraggableModalDialog";

function BflowAccessModal({ company, handleClose }) {
  const { t } = useTranslation(["common", "msg", "company"]);
  const getUsersPerms = useCallback(
    (cancelToken) =>
      officeSupportApi.bflowAccess.get(company.id).then((response) => {
        return response.data;
      }),
    [company]
  );
  const hasEditConsultPerms = HasPermCode(codesForOffice.editConsultPerms);

  const [{ data: users, loading }] = useAsync(getUsersPerms, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <Modal
      scrollable
      show
      onHide={handleClose}
      size="lg"
      className="company-access-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("company:bflowAccess")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {hasEditConsultPerms && (
          <Row>
            <Col>
              <Link
                to={`/offices/${company.office_id}/settings/consults`}
                target="_blank"
                className="btn btn-second blue"
              >
                {t("company:officePermissions")}
              </Link>
            </Col>
          </Row>
        )}
        <Row className="mt-2">
          <Col>
            <Table size="sm" className="table-bordered-cells">
              <thead>
                <tr className="text-center">
                  <th style={{ width: 200 }} className="text-left">
                    {t("company:title.consultant")}
                  </th>
                  <th style={{ width: 150 }}>{t("standardAccess")}</th>
                  <th style={{ width: 150 }}>{t("salaryAccess")}</th>
                  <th style={{ width: 150 }}>{t("officeSupport")}</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.user_id} className="text-center">
                    <th className="text-left">{user.user_name}</th>
                    <td>
                      <input
                        defaultChecked={user.has_access}
                        type="checkbox"
                        disabled
                        ref={(input) => {
                          if (input) {
                            input.indeterminate = typeof user.has_access !== "boolean";
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        defaultChecked={user.has_salary_access}
                        type="checkbox"
                        disabled
                        ref={(input) => {
                          if (input) {
                            input.indeterminate = typeof user.has_salary_access !== "boolean";
                          }
                        }}
                      />
                    </td>
                    <td>
                      <input
                        defaultChecked={user.has_office_support_access}
                        type="checkbox"
                        disabled
                        ref={(input) => {
                          if (input) {
                            input.indeterminate = typeof user.has_office_support_access !== "boolean";
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default BflowAccessModal;
