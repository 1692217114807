import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuthState } from "hooks/useAuth";
import BTable from "components/tables/btable";
import { codesFor } from "components/perms";
import { Button } from "react-bootstrap";
import Filters from "./OfficeSupportCompanyFilters";
import Actions from "./OfficeSupportCompanyActions";
import { truncateText } from "../../../utils/text";
import { CompanyIndicators } from "./OfficeSupportCompanyIndicators";

function OfficeSupportCompanyTable({ forStaff, selectCompany, openCompanyArchive }) {
  const { t } = useTranslation("company");
  const {
    user: { id: userId, show_offices_filter: showOfficesFilter },
  } = useAuthState();

  const headers = useMemo(() => {
    const _headers = [
      {
        field: "client_no",
        label: t("common:customerNo"),
        width: 170,
      },
      {
        field: "name",
        label: t("common:name"),
        className: "text-left",
        render: (row) =>
          row.permission_codes.includes(codesFor.officeSupport.enabled) ? (
            <Button
              variant="link"
              onClick={() => selectCompany(row.id, `/office-support-companies/client-details`)}
              title={row.name}
            >
              {truncateText(row.name, 19)}
            </Button>
          ) : (
            <span> {row.name}</span>
          ),
      },
      {
        field: "org_no",
        label: t("common:orgNo"),
      },
      {
        field: "consult_name",
        label: t("common:customerManager"),
        sortField: "consult__first_name",
      },
      {
        field: "reko",
        label: t("REKO"),
        render: (row) => <CompanyIndicators reko={row.reko} />,
      },
      {
        field: "company_archive",
        label: t("navigation:companyArchive"),
        className: "text-left",
        width: 200,
        render: (row) =>
          row.permission_codes.includes(codesFor.companyArchive.enabled) ? (
            <Button variant="link" onClick={() => openCompanyArchive(row.id)}>
              <i onClick={() => openCompanyArchive(row.id)} className="fas fa-external-link-alt" />
            </Button>
          ) : (
            "-"
          ),
      },
    ];
    return _headers;
  }, [t, selectCompany, openCompanyArchive]);

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      FiltersComponent={<Filters forStaff={forStaff} showOfficesFilter={showOfficesFilter} />}
      renderActions={(row) => <Actions row={row} forStaff={forStaff} userId={userId} />}
    />
  );
}

export default OfficeSupportCompanyTable;
