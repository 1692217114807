import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";

function PageTitle({ title, subtitle, rightComponent }) {
  return (
    <Row>
      <Col>
        <div className="page-title-box">
          <div className="page-title-right">{rightComponent}</div>
          <h4 className="page-title">{title}</h4>
          <p className="page-title-sub-header">{subtitle}</p>
        </div>
      </Col>
    </Row>
  );
}

function SetDocTitle({ title }) {
  useEffect(() => {
    document.title = title;
    window.name = title;
    return () => {
      document.title = "Bflow";
      window.name = "Bflow";
    };
  }, [title]);
  return null;
}

function AppendDocTitle({ appendText }) {
  useEffect(() => {
    const chatUnreadCount = localStorage.getItem("chat-count") || 0;
    let newTitle = "";
    if (chatUnreadCount > 0) {
      newTitle = `(${chatUnreadCount}) ${window.name} - ${appendText}`;
    } else {
      newTitle = `${window.name} - ${appendText}`;
    }
    document.title = newTitle;
    return () => {
      document.title = "Bflow";
    };
  }, [appendText]);
  return null;
}

export default PageTitle;
export { SetDocTitle, AppendDocTitle };
