import React from "react";
import { Card } from "react-bootstrap";
import { Area, Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useTranslation } from "react-i18next";

import { config as chartConfig } from "components/charts/helpers";
import { formatMoney } from "utils/money";

function CashflowChart({ weeks, cashIn, cashOut, result }) {
  const { t } = useTranslation("reports");
  const chartData = weeks.map((week, i) => ({
    name: `${t("common:dates.week")} ${week.weekNumber}`,
    cashIn: cashIn[i],
    cashOut: cashOut[i],
    result: result[i],
  }));
  return (
    <Card>
      <Card.Body className="m-0 p-0 pr-1">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={chartData}
            margin={{
              top: 20,
              right: 0,
              bottom: 20,
              left: 0,
            }}
          >
            <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
            <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" />
            <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
            <Tooltip formatter={(value) => `${formatMoney(value, 1, 1)} Ksek`} />
            <Legend />
            <Bar dataKey="cashIn" name={t("cash.cashIn")} barSize={10} fill={chartConfig.blue} />
            <Bar dataKey="cashOut" name={t("cash.cashOut")} barSize={10} fill={chartConfig.purple} />
            <Area
              dataKey="result"
              name={t("cash.outgoingBalance")}
              type="monotone"
              fill={chartConfig.orange}
              opacity={0.8}
              dot={{ stroke: chartConfig.orange, strokeWidth: 1 }}
              stroke={chartConfig.orange}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
}

export default CashflowChart;
