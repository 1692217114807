import React from "react";
import { PERMISSION_ROLES } from "utils/permissions/permissions";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { PERMISSION_MODULE_CHILD_TYPE, PERMISSION_MODULE_TYPE } from "./models";

function ChildCheckboxes({ child, permsChecked, setPermsChecked, withExpandClass, permissionModule }) {
  const { code, label, roles } = child;

  const {
    i18n: { language },
  } = useTranslation();

  const {
    enabled: { code: enabledCode },
  } = permissionModule;

  const handleCheckboxChange = (role) => {
    setPermsChecked((p) => {
      if (p.includes(code)) {
        return p.filter((backendCode) => backendCode !== code);
      }

      if (!p.includes(enabledCode) && role !== "read") {
        return [...p, code, enabledCode];
      }

      return [...p, code];
    });
  };
  return (
    <tr className={cx("child", withExpandClass)} key={code}>
      <td>
        <span className="dot">•</span>
        <span>{label[language]}</span>
      </td>
      {PERMISSION_ROLES.map((role) => {
        const isAllowedToCheck = role === roles.employee;
        const isChecked = permsChecked.includes(code) && isAllowedToCheck;

        const forChecked = isChecked ? "checked checkbox" : "";
        const forAllowed = isAllowedToCheck ? "allowed checkbox" : "checkbox";

        return (
          <td key={role}>
            <div>
              <button
                onClick={() => isAllowedToCheck && handleCheckboxChange(role)}
                type="button"
                className={forChecked || forAllowed}
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
}

ChildCheckboxes.propTypes = {
  child: PERMISSION_MODULE_CHILD_TYPE,
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
  withExpandClass: PropTypes.string.isRequired,
  permissionModule: PERMISSION_MODULE_TYPE,
};

export default ChildCheckboxes;
