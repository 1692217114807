import React, { useState } from "react";
import { withInitialAsync } from "hooks/useAsync";
import * as trackerAPI from "api/time-tracker";
import { formatMonth } from "utils/date";
import { ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AbsenceCommentsButton, ConfirmButton } from "components/ui/buttons";
import { toast } from "react-toastify";

import "./DisputedAbsenceReportBox.scss";

function DisputedAbsenceReportBox({ data: initialReports, officeId }) {
  const { t } = useTranslation(["common", "tt", "msg"]);
  const [reports, setReports] = useState(initialReports);
  const resendAbsenceReport = async (reportId, idx) => {
    try {
      await trackerAPI.timeGrid.resendAbsenceReportApproval(officeId, reportId);
      setReports([...reports.slice(0, idx), ...reports.slice(idx + 1)]);
      toast.success(t("msg:sent"));
    } catch (error) {
      if (error.data.__all__) {
        toast.error(error.data.__all__);
      }
    }
  };

  if (!reports) {
    return null;
  }
  return (
    <div className="absence-box">
      {reports.map((report, idx) => (
        <div className="box-item" key={report.id}>
          {formatMonth(report.month_holder)} - {t("tt:reportedDisputedComment")}:
          <pre>{report.comments[0] || "n/a"}</pre>
          <ButtonGroup>
            <ConfirmButton
              label={t("common:actions.resend")}
              confirmMessage={t("tt:confirm.sendAbsenceReport")}
              size="sm"
              variant="danger"
              onClick={() => resendAbsenceReport(report.id, idx)}
            />
            <AbsenceCommentsButton companyId={report.company} report={report} />
          </ButtonGroup>
          <small>{t("tt:reportedDisputedWarn")}</small>
        </div>
      ))}
    </div>
  );
}

const EnhancedDisputedAbsenceReportBox = withInitialAsync(
  DisputedAbsenceReportBox,
  ({ officeId }) =>
    React.useCallback(
      (cancelToken) => trackerAPI.timeGrid.getDisputedAbsenceReports(officeId, { cancelToken }),
      [officeId]
    ),
  [],
  false
);

export default EnhancedDisputedAbsenceReportBox;
