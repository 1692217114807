import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { CustomerExtendedTable } from "components/tables";
import { CustomerAddWidget } from "components/forms/CustomerForm";
import * as tableAPI from "api/table";
import { PermCodeRequired } from "components/perms";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { useOutletContext } from "react-router-dom";

function CustomerListPage({ tableId = "customers" }) {
  const company = useOutletContext();
  const initialFilters = {
    mode: "",
  };
  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => tableAPI.companyCustomers(company.id, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <CustomerExtendedTable companyId={company.id} />
        </Card.Body>
      </Card>
      <PermCodeRequired code={codesForCustomerInvoices.manage}>
        <CustomerAddWidget companyId={company.id} />
      </PermCodeRequired>
    </TableProvider>
  );
}

export default CustomerListPage;
