import React from "react";
import { Modal } from "react-bootstrap";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import useInitialAsync from "hooks/useInitialAsync";
import { useTranslation } from "react-i18next";
import Loader from "components/ui/loaders/Loader";
import { useAuthState } from "hooks/useAuth";
import { DraggableModalDialog } from "../DraggableModalDialog";
import CommentBox from "./CommentsBox";

function BaseCommentsModal({ handleClose, companyId, itemId, apiList, apiRemove, apiCreate }) {
  const { t } = useTranslation("common");
  const { loading, item: comments, set: setComments } = useInitialAsync(() => apiList(companyId, itemId), []);
  const {
    user: { id: userId },
  } = useAuthState();
  const ref = React.useRef();

  const onRemove = async (index, comment) => {
    await apiRemove(companyId, itemId, comment.id).then(() => {
      setComments([...comments.slice(0, index), ...comments.slice(index + 1)]);
    });
  };

  const newMessage = () => {
    const text = ref.current.value.trim();
    if (!text) {
      toast.error(t("errors.messageRequired"), { autoClose: 3000 });
      return;
    }
    apiCreate(companyId, itemId, text)
      .then((res) => {
        ref.current.value = "";
        setComments([res.data, ...comments]);
        toast.success(t("msg:saved"), { autoClose: 3000 });
        handleClose([res.data, ...comments]);
      })
      .catch(() => {
        toast.error(t("errors.sthWentWrong"), { autoClose: 3000 });
      });
  };

  const onClose = () => {
    return handleClose(comments);
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onClose}
      dialogClassName="verification-comments-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("comments")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 150 }}>
        {loading ? <Loader /> : <CommentBox comments={comments} userId={userId} onRemove={onRemove} />}
      </Modal.Body>
      <Modal.Footer>
        <textarea className="form-control" placeholder={t("yourMsg")} autoFocus ref={ref} />
        <SubmitButton onClick={newMessage} />
      </Modal.Footer>
    </Modal>
  );
}

export default BaseCommentsModal;
