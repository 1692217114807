import client from "./client";

export const companyDocument = {
  save(companyId, files, data) {
    if (data.id) {
      return client.patch(`/companies/${companyId}/company-documents/${data.id}/`, data);
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    files.forEach((file, i) => {
      if (file) {
        formData.append("files", file, file.name);
      }
    });
    return client.post(`/companies/${companyId}/company-documents/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  details(companyId, documentId) {
    return client.get(`/companies/${companyId}/company-documents/${documentId}/`).then((response) => ({
      ...response.data,
    }));
  },
  remove(companyId, documentId) {
    return client.delete(`/companies/${companyId}/company-documents/${documentId}/`);
  },
  duplicate(companyId, documentId) {
    return client.post(`/companies/${companyId}/company-documents/${documentId}/duplicate/`);
  },
  changeShareAuditor(companyId, documentId) {
    return client.patch(`/companies/${companyId}/company-documents/${documentId}/change_share_auditor/`);
  },
  changeShareCustomer(companyId, documentId, notifyCustomer) {
    return client.patch(`/companies/${companyId}/company-documents/${documentId}/change_share_customer/`, {
      notify_customer: notifyCustomer,
    });
  },
  auditorList(companyId, filters) {
    return client.get(`/companies/${companyId}/auditor-company-documents/`, {
      params: filters,
    });
  },
  yearlyList(companyId, filters) {
    return client.get(`/companies/${companyId}/yearly-company-documents/`, {
      params: filters,
    });
  },
  basicList(companyId, filters) {
    return client.get(`/companies/${companyId}/basic-company-documents/`, {
      params: filters,
    });
  },
};

export const archiveDocument = {
  save: function saveFileArchive(companyId, file, data) {
    if (data.id) {
      return client.patch(`/companies/${companyId}/archive-documents/${data.id}/`, data);
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    formData.append("file", file, file.name);
    return client.post(`/companies/${companyId}/archive-documents/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  details: function fileArchiveDetails(companyId, documentId) {
    return client.get(`/companies/${companyId}/archive-documents/${documentId}/`).then((response) => ({
      ...response.data,
    }));
  },
  remove(companyId, documentId) {
    return client.delete(`/companies/${companyId}/archive-documents/${documentId}/`);
  },
  duplicate(companyId, documentId) {
    return client.post(`/companies/${companyId}/archive-documents/${documentId}/duplicate/`);
  },
  appendToVer(companyId, documentId, verificationId) {
    return client.patch(`/companies/${companyId}/archive-documents/${documentId}/append_to_verification/`, {
      verification_id: verificationId,
    });
  },
  toCompanyArchive(companyId, documentId, category, title, financialYearId) {
    return client.post(`/companies/${companyId}/archive-documents/${documentId}/to_company_archive/`, {
      category,
      title,
      financial_year_id: financialYearId,
    });
  },
  toSupplierInvoice(companyId, documentId) {
    return client.post(`/companies/${companyId}/archive-documents/${documentId}/to_supplier_invoice/`);
  },
};

export const trashDocument = {
  remove(documentId) {
    return client.delete(`/trash/${documentId}/`);
  },
};

export const salaryDocument = {
  save(companyId, file, data) {
    if (data.id) {
      return client.patch(`/companies/${companyId}/salary-documents/${data.id}/`, data);
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    if (data.type === "document") {
      formData.append("file", file, file.name);
    }
    return client.post(`/companies/${companyId}/salary-documents/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  details(companyId, documentId) {
    return client.get(`/companies/${companyId}/salary-documents/${documentId}/`).then((response) => ({
      ...response.data,
    }));
  },
  remove(companyId, documentId) {
    return client.delete(`/companies/${companyId}/salary-documents/${documentId}/`);
  },
  duplicate(companyId, documentId) {
    return client.post(`/companies/${companyId}/salary-documents/${documentId}/duplicate/`);
  },
};

export const consultDocument = {
  save(companyId, files, data) {
    if (data.id) {
      return client.patch(`/companies/${companyId}/consultant-documents/${data.id}/`, data);
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    files.forEach((file, i) => {
      if (file) {
        formData.append("files", file, file.name);
      }
    });
    return client.post(`/companies/${companyId}/consultant-documents/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  details(companyId, documentId) {
    return client.get(`/companies/${companyId}/consultant-documents/${documentId}/`).then((response) => ({
      ...response.data,
    }));
  },
  remove(companyId, documentId) {
    return client.delete(`/companies/${companyId}/consultant-documents/${documentId}/`);
  },
  duplicate(companyId, documentId) {
    return client.post(`/companies/${companyId}/consultant-documents/${documentId}/duplicate/`);
  },
  quickList(companyId) {
    return client.get(`/companies/${companyId}/consultant-documents/quick_categories/`, {});
  },
};

export const comment = {
  list(companyId, documentId) {
    return client.get(`/companies/${companyId}/documents/${documentId}/comments/`, { params: { page_size: 100 } });
  },
  remove(companyId, documentId, commentId) {
    return client.delete(`/companies/${companyId}/documents/${documentId}/comments/${commentId}/`);
  },
  create(companyId, documentId, text) {
    return client.post(`/companies/${companyId}/documents/${documentId}/comments/`, { text });
  },
};
