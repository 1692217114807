import React from "react";
import { TooltipActionButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import EmployeeModal from "components/modals/EmployeeModal";

function EmployeeActions({ companyId, row, forOnboarding }) {
  const { t } = useTranslation("common");
  const modal = useModal();

  return (
    <>
      <TooltipActionButton text={t("common:actions.change")} onClick={modal.open} icon="fas fa-pen" />
      {modal.show && (
        <EmployeeModal
          handleClose={modal.close}
          companyId={companyId}
          employeeId={row.id}
          forOnboarding={forOnboarding}
        />
      )}
    </>
  );
}

export default EmployeeActions;
