import React from "react";

import { MenuNumberDispatchContext, MenuNumberStateContext } from "state/providers/MenuNumberProvider";

function useMenuNumbersState() {
  const context = React.useContext(MenuNumberStateContext);
  if (context === undefined) {
    throw new Error("useMenuNumbersState must be used within a MenuNumberProvider");
  }
  return context;
}

function useMenuNumbersDispatch() {
  const dispatch = React.useContext(MenuNumberDispatchContext);
  if (dispatch === undefined) {
    throw new Error("useMenuNumbersDispatch must be used within a MenuNumberProvider");
  }

  return {
    reload: dispatch.reload,
  };
}

export { useMenuNumbersState, useMenuNumbersDispatch };
