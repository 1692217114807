import React, { useContext, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BankConnectionContext } from "state/providers/BankConnectionProvider";

function DecoupledQRFlow() {
  const { t } = useTranslation("company");
  const { reset, qrcode, checkSCAStatus } = useContext(BankConnectionContext);
  const checkingStatus = useRef(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!checkingStatus.current) {
        checkingStatus.current = true;
        checkSCAStatus().finally(() => {
          checkingStatus.current = false;
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [checkSCAStatus]);

  const onExpired = () => {
    toast.error("QR code expired. Try again");
    reset();
  };

  return (
    <div className="decoupled-flow">
      {qrcode ? (
        <>
          <p className="subtitle">{t("psd.qrNote")}</p>
          <img src={qrcode} alt="QR Code" />
        </>
      ) : (
        <p className="subtitle h4 mb-4">{t("psd.lsConsentNote")}</p>
      )}
      <TimeCounter onExpired={onExpired} />
    </div>
  );
}

function TimeCounter({ onExpired }) {
  const [time, setTime] = useState(30);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((ti) => {
        return ti >= 1 ? ti - 1 : 0;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (time === 0) {
    onExpired();
    return null;
  }
  const percentage = Math.floor(time / 0.3);
  return (
    <>
      <ProgressBar percentage={percentage} />
      <p className="counter-text">
        <Trans i18nKey="company:psd.authTimer" time={time}>
          You have <strong>{{ time }}</strong> to finish authorization
        </Trans>
      </p>
    </>
  );
}

function ProgressBar({ percentage }) {
  return (
    <div className="progress">
      <div
        className="progress-bar bg-soft-green"
        style={{ width: `${percentage}%` }}
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  );
}

export default DecoupledQRFlow;
