import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import TableProvider from "components/tables/btable/provider";
import * as tableAPI from "api/table";
import { BankRulesExtendedTable } from "components/tables";
import { BankRuleAddWidget } from "components/forms/company-settings-forms/BankRuleForm";
import client from "api/client";
import { withInitialAsync } from "hooks/useAsync";

function BankMatchingRulesPage({ data: companyAccounts }) {
  const { companyId } = useParams();

  const initialFilters = {};

  return (
    <TableProvider
      tableId="bankRulesTable"
      dataSource={(filters, config) => tableAPI.companyBankRules(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <BankRulesExtendedTable companyId={companyId} companyAccounts={companyAccounts} />
        </Card.Body>
      </Card>
      <BankRuleAddWidget companyId={companyId} companyAccounts={companyAccounts} />
    </TableProvider>
  );
}

const EnhancedBankMatchingRulesPage = withInitialAsync(
  BankMatchingRulesPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback(
      (cancelToken) =>
        client
          .get("/select/company-accounts/", {
            params: { companyId, forBankMatching: true },
            cancelToken,
          })
          .then((response) => {
            response.data = response.data.map((item) => ({
              value: item.number,
              label: `${item.number} - ${item.name}`,
              isDisabled: !item.is_active,
            }));
            return response;
          }),
      [companyId]
    );
  },
  [],
  false
);

export default EnhancedBankMatchingRulesPage;
