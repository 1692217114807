import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import AgaPdfReductionForm from "components/forms/AgaPdfReductionForm";

function AgaPdfReductionAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const item = {
    booking_date: new Date(),
    reduction_soc_sec: 0,
    project: null,
    cost_center: null,
  };

  const onCreate = () => {
    navigate(-1);
  };

  return <AgaPdfReductionForm companyId={company.id} item={item} onSave={onCreate} />;
}

export default AgaPdfReductionAddPage;
