import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import React from "react";

function BGCNotConnectedErrorPage() {
  const { t } = useTranslation("common");
  return (
    <Card>
      <Card.Body className="text-danger">{t("bgcDisabled")}</Card.Body>
    </Card>
  );
}

export default BGCNotConnectedErrorPage;
