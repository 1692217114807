import React, { useEffect, useState } from "react";
import { useCompanyState } from "hooks/useCompany";
import ActionResultsModal from "../ActionResultsModal";

function ApproveResultsModal({ selectedItems, handleClose }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  const [data, setData] = useState([...selectedItems]);

  useEffect(() => {
    const promises = [];
    selectedItems.forEach((item) => {
      const promise = item
        .apiRequest(companyId, item.id)
        .then((response) => {
          setData((_data) => _data.map((i) => (i.id === item.id ? { ...i, progress: 1 } : i)));
          return response;
        })
        .catch((error) => {
          let errorMsg = "";
          if (error.status === 403) {
            errorMsg = error.data;
          } else {
            errorMsg = "__all__" in error.data ? error.data.__all__ : error.data;
          }
          setData((d) => d.map((i) => (i.id === item.id ? { ...i, progress: -1, error: errorMsg } : i)));
          return error;
        });
      promises.push(promise);
    });

    Promise.all(promises).then((responses) => {
      if (responses.findIndex((response) => response.status >= 400) === -1) {
        handleClose(true);
      }
    });
  }, [handleClose, companyId, selectedItems]);

  return <ActionResultsModal data={data} handleClose={handleClose} />;
}

export default ApproveResultsModal;
