import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import _ from "lodash";

function HintButton({ hint, placement = "top", ...props }) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Popover id={String(_.uniqueId("hint_"))} className="popover-hint" {...props}>
          <Popover.Content>{hint}</Popover.Content>
        </Popover>
      }
    >
      <i className="fas fa-question-circle ml-1" />
    </OverlayTrigger>
  );
}

export default HintButton;
