import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import "../ImportFortnoxStep/ImportFortnoxStep.scss";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";
import { OnboardingDispatchContext } from "../../provider/OnboardingProvider";

function SaveLedgersStep({ data: initialData, companyId }) {
  const [data, setData] = useState(initialData);
  const { t } = useTranslation("company");
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const fetchChecklist = useCallback(() => {
    companyAPI.onboarding.fortnoxSaveLedgerStep.get(companyId).then((response) => {
      setData(response.data);
      if (response.data.saved_status !== "finished" || response.data.saved_status !== "error") {
        fetchDetails();
      }
    });
  }, [companyId, fetchDetails]);
  const onStart = () => {
    companyAPI.onboarding.fortnoxSaveLedgerStep.start(companyId).then(() => {
      // fetch steps details
      setData((_data) => ({
        checklist: _data.checklist.map((item) => ({ ...item, error: "", saved_status: "in_progress" })),
        saved_status: "in_progress",
      }));
      fetchDetails();
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchChecklist();
    }, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [fetchChecklist]);

  const showStartBtn = ["not_started", "error"].includes(data.saved_status);
  return (
    <Card className="import-fortnox-step">
      <Card.Body>
        <StepHeader />
      </Card.Body>
      <Card.Body>
        <div className="fortnox-wrapper">
          <small>{t("onboarding.fortnox.importInfo")}</small>
          <div className="fortnox-control">
            {showStartBtn && (
              <Row>
                <Col xl={1}>
                  <Button
                    type="submit"
                    className="fortnox-control__start"
                    onClick={onStart}
                    disabled={data.saved_status === "in_progress"}
                  >
                    <i className="fe-download-cloud" /> {t("onboarding.fortnox.start")}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
          <LedgerProgress data={data} />
        </div>
      </Card.Body>
    </Card>
  );
}

function LedgerProgress({ data }) {
  const { t } = useTranslation("company");
  return (
    <div className="fortnox-results">
      {data.checklist.map((checkItem) => (
        <div key={checkItem.name} className={cx("fortnox-results__item", checkItem.saved_status)}>
          <i
            className={cx({
              "fas fa-circle-notch fa-spin": checkItem.saved_status === "in_progress",
              "fas fa-check": checkItem.saved_status === "finished" || checkItem.saved_status === "not_started",
              "fas fa-times": checkItem.saved_status === "error",
            })}
          />
          {t("onboarding.fortnox.save")} {t(`onboarding.fortnox.${checkItem.name}`)}
          {checkItem.error && <small className="d-block">{checkItem.error}</small>}
        </div>
      ))}
    </div>
  );
}

const EnhancedSaveLedgersStep = withInitialAsync(
  SaveLedgersStep,
  ({ companyId }) => {
    return useCallback(
      (cancelToken) => companyAPI.onboarding.fortnoxSaveLedgerStep.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedSaveLedgersStep;
