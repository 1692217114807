import React from "react";
import MetisMenu from "metismenujs";

function Menu({ children, extraDeps }) {
  const menuEl = React.useRef(null);
  React.useEffect(() => {
    const MenuJS = new MetisMenu(menuEl.current);
    return function cleanup() {
      MenuJS.dispose();
    };
  }, [extraDeps]);
  return (
    <div id="sidebar-menu" className="custom mm-wrapper">
      <ul id="side-menu" className="metismenu" ref={menuEl}>
        {children}
      </ul>
    </div>
  );
}

export default Menu;
