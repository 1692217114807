import { useEffect, useState } from "react";

export const useModalScroll = () => {
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    const getHeight = () => {
      const dialog = document.querySelector(".modal-dialog");
      if (!dialog) return;

      const windowHeight = window.innerHeight;
      const { marginBottom, marginTop, height } = window.getComputedStyle(dialog);
      const fullHeight = parseFloat(marginBottom) + parseFloat(marginTop) + parseFloat(height);
      const isScrollNeeded = fullHeight >= windowHeight;

      setShouldScroll(isScrollNeeded);
    };

    getHeight();
    window.addEventListener("resize", getHeight);

    return () => window.removeEventListener("resize", getHeight);
  }, []);

  return { shouldScroll };
};
