import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import _ from "lodash";

import * as options from "api/options";
import * as selectAPI from "api/select";
import * as companyAPI from "api/company";
import { ConfirmButton, RemoveButton, SubmitButton } from "components/ui/buttons";
import { FormGroup } from "components/formik";
import { useCompanyState } from "hooks/useCompany";
import {
  isAddressRequired,
  isBankCodeRequired,
  isClearingNumberRequired,
  isIBANSupported,
  isStateRequired,
} from "utils/countries";
import { AllError } from "../../formik";

function mapSupplier(supplier) {
  if (supplier.supplier) {
    if (_.isObject(supplier.supplier)) {
      return { value: supplier.supplier.value, label: supplier.supplier.label };
    }
    return {
      value: supplier.supplier,
      label: supplier.supplier,
    };
  }
  return "";
}

function SupplierForm({
  companyId,
  supplier,
  successCallback,
  deleteCallback,
  shouldResetForm = true,
  allowDelete = true,
  allowDeactivate = true,
}) {
  const { t } = useTranslation("si");
  const supplierPaymentMethods = options.supplierPaymentMethods.asList();
  const {
    accounts: { byId: accountById },
    costCenters: { byId: centerById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
  } = useCompanyState();

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      ...supplier,
      supplier: mapSupplier(supplier),
      payment_method: options.supplierPaymentMethods.getOption(supplier.payment_method) || supplierPaymentMethods[0],
      contra_account: supplier.contra_account ? accountById[supplier.contra_account] : null,
      project: supplier.project ? projectById[supplier.project] : null,
      cost_center: supplier.cost_center ? centerById[supplier.cost_center] : null,
    },
    validationSchema: yup.object().shape({
      contra_account: yup.object().nullable().required(),
      payment_method: yup.object().required(),
      supplier: yup.object().nullable().required(),
      payment_terms: yup.string().required(),
      bankgiro_number: yup.string().when("payment_method", (method, schema) => {
        return method && method.value === "BankGiro" ? schema.required() : schema;
      }),
      plusgiro_number: yup.string().when("payment_method", (method, schema) => {
        return method && method.value === "PlusGiro" ? schema.required() : schema;
      }),
      clearing_number: yup.string().when("payment_method", (method, schema) => {
        return method && method.value === "BankAccount" ? schema.required() : schema;
      }),
      bank_account_number: yup.string().when("payment_method", (method, schema, { parent }) => {
        if (method && method.value === "BankAccount") {
          return schema.required();
        }
        if (method && method.value === "ForeignAccount") {
          const ibanSupported = isIBANSupported(parent.country);
          if (!ibanSupported) {
            return schema.required();
          }
        }
        return schema;
      }),
      bic: yup.string().when("payment_method", (method, schema) => {
        return method && method.value === "ForeignAccount" ? schema.required() : schema;
      }),
      bank_name: yup.string().when("payment_method", (method, schema) => {
        return method && method.value === "ForeignAccount" ? schema.required() : schema;
      }),
      bank_code: yup.string().when("payment_method", (method, schema, { parent }) => {
        if (method && method.value === "ForeignAccount") {
          if (isBankCodeRequired(parent.country)) {
            return schema.required();
          }
        }
        return schema;
      }),
      iban: yup.string().when("payment_method", (method, schema, { parent }) => {
        if (method && method.value === "ForeignAccount") {
          const ibanSupported = isIBANSupported(parent.country);
          if (ibanSupported) {
            return schema.required();
          }
        }
        return schema;
      }),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const ibanSupported = isIBANSupported(values.country);
      let bankAccountNumber = values.bank_account_number;
      if (values.payment_method === "ForeignAccount" && ibanSupported) {
        bankAccountNumber = "";
      }
      const data = {
        id: supplier.id,
        ...values,
        supplier: values.supplier ? values.supplier.value : null,
        contra_account: values.contra_account.value,
        payment_method: values.payment_method.value,
        project: values.project ? values.project.value : null,
        cost_center: values.cost_center ? values.cost_center.value : null,
        iban: ibanSupported ? values.iban.split(" ").join("") : "",
        bank_account_number: bankAccountNumber,
      };
      // if (data.id) {
      //   delete values.supplier;
      // }
      await companyAPI
        .saveCompanySupplier(companyId, data)
        .then((response) => {
          toast.success(t("msg:saved"));
          if (successCallback) {
            successCallback(response.data);
            if (!data.id && shouldResetForm) {
              resetForm();
            }
          }
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };

  function onSupplierSelected(values, newSupplier, setValues) {
    const paymentMethod = newSupplier.payment_method;
    if (paymentMethod) {
      setValues(
        {
          ...values,
          payment_method: options.supplierPaymentMethods.getOption(newSupplier.payment_method),
          contra_account: newSupplier.contra_account ? accountById[newSupplier.contra_account] : null,
          bankgiro_number: newSupplier.bankgiro_number,
          plusgiro_number: newSupplier.plusgiro_number,
          clearing_number: newSupplier.clearing_number,
          bank_account_number: newSupplier.bank_account_number,
          bic: newSupplier.bic,
          iban: newSupplier.iban,
          bank_name: newSupplier.bank_name,
          bank_code: newSupplier.bank_code,
          street: newSupplier.street,
          city: newSupplier.city,
          zip_code: newSupplier.zip_code,
          state: newSupplier.state,
          country: newSupplier.country,
        },
        false
      );
    }
  }

  const onDelete = async () => {
    companyAPI
      .deleteCompanySupplier(companyId, supplier.id)
      .then(() => {
        toast.success(t("msg:deleted"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          toast.error(
            t("si:canNotRemoveCompanySupplier", {
              count: error.data.booked_invoices || 1,
            }),
            { autoClose: 5000 }
          );
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };

  async function activeToggle() {
    if (supplier.is_active) {
      await companyAPI.deactiveCompanySupplier(companyId, supplier.id).then((response) => {
        toast.success(t("msg:deactivated"), { autoClose: 2000 });
        successCallback();
      });
    } else {
      await companyAPI.activeCompanySupplier(companyId, supplier.id).then((response) => {
        toast.success(t("msg:activated"), { autoClose: 2000 });
        successCallback();
      });
    }
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setValues }) => {
        const paymentMethod = values.payment_method;
        const ibanSupported = isIBANSupported(values.country);
        const bankCodeRequired = isBankCodeRequired(values.country);
        const addressRequired = isAddressRequired(values.country);
        const stateRequired = isStateRequired(values.country);
        const clearingNoRequired = isClearingNumberRequired(values.country);
        return (
          <Form id="local-supplier-form">
            {!supplier.id && (
              <Row>
                <Col lg={6}>
                  <FormGroup.AsyncPaginateSelect
                    name="supplier"
                    label={t("common:supplier")}
                    loadOptions={(search, prevOptions, additional) =>
                      selectAPI.getSuppliersToConnect({
                        search,
                        prevOptions,
                        additional,
                        companyId,
                      })
                    }
                    onChange={(selected) => onSupplierSelected(values, selected, setValues)}
                    defaultValue={mapSupplier(supplier)}
                    required
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={4}>
                <FormGroup.SimpleSelect
                  name="payment_method"
                  label={t("common:paymentMethod")}
                  options={supplierPaymentMethods}
                  required
                />
              </Col>

              {paymentMethod.value === "BankGiro" && (
                <Col sm={6} xl={4}>
                  <FormGroup.Input label={`BankGiro ${t("common:no")}`} name="bankgiro_number" type="text" required />
                </Col>
              )}
              {paymentMethod.value === "PlusGiro" && (
                <Col sm={6} xl={4}>
                  <FormGroup.Input label={`PlusGiro ${t("common:no")}`} name="plusgiro_number" type="text" required />
                </Col>
              )}
              {paymentMethod.value === "BankAccount" && (
                <>
                  <Col sm={6} xl={4}>
                    <FormGroup.Input label={t("common:clearingNo")} name="clearing_number" type="number" required />
                  </Col>
                  <Col sm={6} xl={4}>
                    <FormGroup.Input
                      label={t("common:bankAccountNo")}
                      name="bank_account_number"
                      type="text"
                      required
                    />
                  </Col>
                </>
              )}
              {paymentMethod.value === "ForeignAccount" && (
                <>
                  <Col sm={6} xl={4}>
                    <FormGroup.Input label="BIC" name="bic" type="text" required />
                  </Col>
                  <Col sm={6} xl={4}>
                    <FormGroup.Input label={t("company:bankName")} name="bank_name" type="text" required />
                  </Col>
                  {bankCodeRequired && (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label={t("company:bankCode")} name="bank_code" required={!ibanSupported} />
                    </Col>
                  )}
                  {!ibanSupported ? (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label="BBAN" name="bank_account_number" required />
                    </Col>
                  ) : (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label="IBAN" name="iban" required />
                    </Col>
                  )}
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.street")} name="street" required={addressRequired} />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.city")} name="city" required={addressRequired} />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.zipCode")} name="zip_code" required={addressRequired} />
                  </Col>
                  {stateRequired && (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input label={t("common:contact.state")} name="state" required />
                    </Col>
                  )}
                  {clearingNoRequired && (
                    <Col sm={6} xl={4}>
                      <FormGroup.Input label={t("common:clearingNo")} name="clearing_number" type="number" required />
                    </Col>
                  )}
                </>
              )}
            </Row>
            <Row>
              <Col lg={4}>
                <FormGroup.AsyncSelect
                  name="contra_account"
                  label={t("common:costAccount")}
                  loadOptions={(params, callback) =>
                    selectAPI.getCompanyAccounts(
                      {
                        ...params,
                        companyId,
                        forEmployee: false,
                      },
                      callback
                    )
                  }
                  required
                  minSearchLength={0}
                />
              </Col>
              <Col xl={4}>
                <FormGroup.SimpleSelect
                  name="project"
                  label={t("common:project")}
                  options={projectOptions}
                  isClearable
                  required={values.account && values.account.project_option === "mandatory"}
                />
              </Col>
              <Col xl={4}>
                <FormGroup.SimpleSelect
                  name="cost_center"
                  label={t("common:costCenter")}
                  options={centerOptions}
                  required={values.account && values.account.cost_center_option === "mandatory"}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6} xl={4}>
                <FormGroup.Input
                  label={t("common:paymentTerms")}
                  name="payment_terms"
                  type="number"
                  step={1}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="label-control">{t("common:options")}</label>
                <FormGroup.Checkbox
                  formName="local-supplier-form"
                  label={t("si:deactivatePayment")}
                  name="deactivate_payment"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mt-2 mb-0">
                <AllError errors={errors} />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <SubmitButton isSubmitting={isSubmitting} />
                {supplier.id && allowDelete && (
                  <RemoveButton
                    variant="danger"
                    disabled={isSubmitting}
                    confirmMessage={t("confirm.removeLocalSupplier", {
                      name: supplier.supplier_name,
                    })}
                    className="float-right ml-2"
                    onClick={onDelete}
                  />
                )}
                {supplier.id && allowDeactivate && supplier.is_active && (
                  <ConfirmButton
                    variant="danger"
                    confirmMessage={t("confirm.deactivateSupplier", {
                      name: supplier.supplier_name,
                    })}
                    label={t("common:actions.deactivate")}
                    className="float-right"
                    icon="fas fa-trash-alt"
                    onClick={() => activeToggle()}
                  />
                )}
                {supplier.id && allowDeactivate && !supplier.is_active && (
                  <ConfirmButton
                    variant="warning"
                    label={t("common:actions.activate")}
                    icon="fa-redo"
                    confirmMessage={t("confirm.activateSupplier", {
                      name: supplier.supplier_name,
                    })}
                    className="float-right"
                    onClick={() => activeToggle()}
                  />
                )}
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SupplierForm;
