import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { StaffPermRequired } from "components/perms";
import { YearForm } from "./YearForm";

export function FortnoxWrapper({ data, onStart }) {
  const { t } = useTranslation("company");
  const showStartBtn = ["not_started", "error"].includes(data.import_status);
  return (
    <div className="fortnox-wrapper">
      {showStartBtn && (
        <div className="fortnox-control">
          <YearForm data={data} onStart={onStart} />
        </div>
      )}
      <div className="fortnox-results">
        {data.checklist.map((checkItem) => (
          <div key={checkItem.name} className={cx("fortnox-results__item", checkItem.import_status)}>
            <i
              className={cx({
                "fas fa-circle-notch fa-spin": checkItem.import_status === "in_progress",
                "fas fa-check": checkItem.import_status === "finished" || checkItem.import_status === "not_started",
                "fas fa-times": checkItem.import_status === "error",
              })}
            />{" "}
            {t("onboarding.fortnox.import")} {t(`onboarding.fortnox.${checkItem.name}`)}
            {checkItem.count >= 0 && (
              <StaffPermRequired>
                <small className="d-inline ml-2 text-info">
                  {checkItem.count} {t("common:imported")}
                </small>
              </StaffPermRequired>
            )}
            {checkItem.error && <small className="d-block">{checkItem.error}</small>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FortnoxWrapper;
