import React from "react";

import * as officeSupportApi from "api/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportRolesOtherAttorneyForm from "./OfficeSupportRolesOtherAttorneyForm";

function OfficeSupportRolesOtherAttorneyWidget({ data: item, company, changeEditedForm }) {
  return (
    <OfficeSupportRolesOtherAttorneyForm company={company} changeEditedForm={changeEditedForm} initialData={item} />
  );
}

const EnhancedOfficeSupportRolesOtherAttorneyWidget = withInitialAsync(
  OfficeSupportRolesOtherAttorneyWidget,
  ({ company }) =>
    React.useCallback((cancelToken) => officeSupportApi.rolesOtherAttorney.get(company.id, { cancelToken }), [company]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportRolesOtherAttorneyWidget;
