import React from "react";
import { Col, Row } from "react-bootstrap";

import { formatMoney } from "utils/money";
import { DataBox } from "components/ui/boxes";
import { useTranslation } from "react-i18next";
import PrelimTable from "./PrelimTable";
import UnpaidTable from "./UnpaidTable";
import UpcomingEventsTable from "./UpcomingEventsTable";
import BflowAccountTable from "./BflowAccountTable";
import YearToDateChart from "./YearToDateChart";
import YearsCompareChart from "./YearsCompareChart";
import YearToDateTable from "./YearToDateTable";

function DashboardContent({ financialYear, data }) {
  const { t } = useTranslation("reports");
  const getTaxSubtitle = () => {
    if (data.skatteverket_enabled) {
      return t("dashboard.fetched", { date: data.tax_balance_date });
    }
    return data.tax_balance_date
      ? t("dashboard.reconciled", { date: data.tax_balance_date })
      : t("dashboard.notReconciled");
  };

  return (
    <>
      <Row xs={1} sm={2} lg={2} xl={5}>
        <DataBox
          title={t("dashboard.openCustomerInvoices")}
          variant="bg-success"
          icon="mdi-arrow-down"
          value={data.customer_invoices.unpaid_count}
          subtitle={t("dashboard.openCustomerInvoicesSubtitle")}
        />
        <DataBox
          title={t("dashboard.openSupplierInvoices")}
          variant="bg-warning"
          icon="mdi-arrow-up"
          value={data.supplier_invoices.unpaid_count}
          subtitle={t("dashboard.openSupplierInvoicesSubtitle")}
        />
        <DataBox
          title={t("dashboard.balanceInPaymentAccount")}
          variant="bg-info"
          icon="mdi-bank"
          value={data.balances.bank ? formatMoney(data.balances.bank, 0, 0) : "-"}
          subtitle={
            data.balances.bank && data.bank_scan_date
              ? t("dashboard.fetched", { date: data.bank_scan_date })
              : t("dashboard.noBankConnected")
          }
          subtitle2={
            data.balances.bank &&
            t("dashboard.overdraft", {
              amount: formatMoney(data.overdraft_facility, 0, 0),
            })
          }
        />
        <DataBox
          title={t("dashboard.paymentsSentBank")}
          variant="bg-purple"
          icon="mdi-bank"
          value={formatMoney(data.expenses.sent_bank_sum, 0, 0)}
          subtitle={t("dashboard.inSEK")}
        />
        <DataBox
          title={data.skatteverket_enabled ? t("dashboard.taxAccountBalance") : t("dashboard.taxAccountBalance1630")}
          variant="bg-pink"
          icon="mdi-bank-remove"
          value={formatMoney(data.balances.tax, 0, 0)}
          subtitle={getTaxSubtitle()}
        />
      </Row>
      <Row>
        <Col xl={8}>
          <Row xl={2}>
            <Col>
              <YearToDateTable data={data} t={t} />
            </Col>
            <Col>
              <PrelimTable data={data} t={t} />
            </Col>
          </Row>
          <Row xl={2}>
            <Col>
              <YearToDateChart
                financialYear={financialYear}
                expenses={data.expenses.monthly}
                income={data.income.monthly}
              />
            </Col>
            <Col>
              <YearsCompareChart
                financialYear={financialYear}
                income={data.income.monthly}
                prevIncome={data.income.prev_monthly}
                expenses={data.expenses.monthly}
                prevExpenses={data.expenses.prev_monthly}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <UnpaidTable data={data} t={t} />
          <BflowAccountTable data={data} />
          <UpcomingEventsTable data={data} />
        </Col>
      </Row>
    </>
  );
}

export default DashboardContent;
