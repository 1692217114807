import React, { createContext, useMemo, useState } from "react";
import { useAuthState } from "hooks/useAuth";

const ContactContext = createContext(undefined);

function ContactProvider({ children }) {
  const { user } = useAuthState();

  const [state, setState] = useState(() => ({
    contactName: user.name,
    contactEmail: user.email,
    contactPhone: localStorage.getItem("contactPhone") || "",
  }));
  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
}

export default ContactProvider;
export { ContactContext };
