import React, { useEffect, useMemo, useState } from "react";
import { Card, Table } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { formatDate } from "utils/date";
import { formatMoney } from "utils/money";
import {
  fillByDayNumber,
  fillCompanyTax,
  fillPersonalTax,
  fillSocSec,
  fillVAT,
  getWeeks,
  groupCI,
  groupSI,
  simulateAddons,
  simulateCI,
  simulateSalaries,
  sumArrays,
} from "./helpers";
import CashIn from "./CashIn";
import CashOut from "./CashOut";
import CashflowChart from "./CashflowChart";

function CashFlowTable({ company, initial, numberOfWeeks = 15, updateSimulation }) {
  const weeks = useMemo(() => getWeeks(numberOfWeeks), [numberOfWeeks]);
  const weeksByNo = useMemo(
    () =>
      Object.assign(
        {},
        ...weeks.map((x, i) => ({
          [x.weekNumber]: {
            ...x,
            index: i,
          },
        }))
      ),
    [weeks]
  );

  const { t } = useTranslation("reports");
  const [showChart, setShowChart] = useState(false);
  const [simulation, setSimulation] = useState(initial.simulation);
  const [paymentsFromCustomers, setPaymentsFromCustomers] = useState(() =>
    groupCI(initial.ci, numberOfWeeks, weeksByNo, simulation.ci)
  );
  const [cashInAddons, setCashInAddons] = useState(() => simulateAddons(simulation.addons.cashIn, weeksByNo));
  const [cashOutAddons, setCashOutAddons] = useState(() => simulateAddons(simulation.addons.cashOut, weeksByNo));
  const [outlays, setOutlays] = useState(initial.outlays);
  const [taxAccount] = useState(initial.taxAccount);
  const [netSalary] = useState(() => fillByDayNumber(weeksByNo, 25, initial.netSalary));
  const [socSec] = useState(() => fillSocSec(weeksByNo, initial.taxes.soc_sec));
  const [personalTax] = useState(() => fillPersonalTax(weeksByNo, initial.taxes.personal_tax));
  const [companyTax] = useState(() => fillCompanyTax(weeksByNo, initial.taxes.company_tax));
  const [vat] = useState(() => fillVAT(weeksByNo, initial.taxes.vat_events));
  const [salaries, setSalaries] = useState(() => simulateSalaries(simulation.salary, weeksByNo));
  const [paymentsToSuppliers, setPaymentsToSuppliers] = useState(() => groupSI(initial.si, weeksByNo, simulation.si));

  const [options, setOptions] = useState({
    cashIn: true,
    paymentsFromCustomers: false,
    due0: false,
    due30: false,
    due90: false,
    due90p: false,
    addonsCashIn: false,
    addonsCashOut: false,
    si: false,
    cashOut: true,
    salary: false,
    taxes: false,
  });

  const incomingBalance = [...initial.incomingBalance];
  const cashIn = sumArrays(paymentsFromCustomers.weeks, cashInAddons.weeks);
  const taxes = sumArrays(socSec, personalTax, companyTax, vat);
  const cashOut = sumArrays(
    outlays,
    taxAccount,
    netSalary,
    taxes,
    cashOutAddons.weeks,
    salaries.weeks,
    paymentsToSuppliers.weeks
  );
  const outgoingBalance = _.fill(Array(numberOfWeeks), 0);
  outgoingBalance[0] = incomingBalance[0] + cashIn[0] + cashOut[0];
  for (let i = 1; i < numberOfWeeks; i++) {
    incomingBalance[i] = outgoingBalance[i - 1];
    outgoingBalance[i] = incomingBalance[i] + cashIn[i] + cashOut[i];
  }

  useEffect(() => {
    const newData = simulateCI(paymentsFromCustomers, simulation.ci, weeksByNo);
    setPaymentsFromCustomers(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simulation.ci]);

  const toggle = (key) => {
    setOptions({ ...options, [key]: !options[key] });
  };

  const handleSimulationCIChange = (key, newSimulationData) => {
    const newSimulation = {
      ...simulation,
      ci: { ...simulation.ci, [key]: newSimulationData },
    };
    updateSimulation(newSimulation);
    setSimulation(newSimulation);
  };

  function handleAddonChange(values, index, key = "cashIn") {
    const oldAddons = key === "cashIn" ? cashInAddons : cashOutAddons;
    let addons;
    if (index === null) {
      // add
      addons = [values, ...oldAddons.items.map((data) => data.item)];
    } else if (values === null) {
      // remove
      addons = oldAddons.items.filter((value, i) => i !== index).map((data) => data.item);
    } else {
      addons = oldAddons.items.map((data, i) => {
        // update
        if (i === index) {
          return values;
        }
        return data.item;
      });
    }
    if (addons) {
      if (key === "cashIn") {
        setCashInAddons(simulateAddons(addons, weeksByNo));
      } else {
        setCashOutAddons(simulateAddons(addons, weeksByNo));
      }
    }

    const newSimulation = {
      ...simulation,
      addons: { ...simulation.addons, [key]: addons },
    };
    updateSimulation(newSimulation);
  }

  function handleSalaryChange(values, index) {
    let newSalaries;
    if (index === null) {
      // add
      newSalaries = [...salaries.items, values];
    } else if (values === null) {
      // remove
      newSalaries = salaries.items.filter((value, i) => i !== index);
    } else {
      newSalaries = salaries.items.map((data, i) => {
        // update
        if (i === index) {
          return values;
        }
        return data;
      });
    }

    const newSimulation = { ...simulation, salary: newSalaries };
    setSalaries(simulateSalaries(newSimulation.salary, weeksByNo));
    updateSimulation(newSimulation);
  }

  const handleSIChange = (updatedData) => {
    updatedData.weeks = _.fill(Array(numberOfWeeks), 0);
    updatedData.untreated.forEach((row) => {
      updatedData.weeks = sumArrays(updatedData.weeks, row.weeks);
    });
    updatedData.simulation.forEach((row) => {
      updatedData.weeks = sumArrays(updatedData.weeks, row.weeks);
    });

    setPaymentsToSuppliers(updatedData);
    const newSimulation = {
      ...simulation,
      si: updatedData.simulation.map((item) => ({
        repeat: item.repeat,
        delay: item.delay,
        type: item.type,
        amount: item.amount,
        due_date: item.due_date,
        id: item.id,
        supplier: item.supplier,
      })),
    };
    updateSimulation(newSimulation);
  };

  const handleSimulationOutlayChange = (period) => {
    const newOutlays = [...initial.outlays];
    const periodInt = parseInt(period, 10);
    if (periodInt > 0) {
      for (let i = 0; i < numberOfWeeks; i += periodInt) {
        // eslint-disable-next-line prefer-destructuring
        newOutlays[i] = initial.outlays[0];
      }
    }
    setOutlays(newOutlays);
  };

  return (
    <>
      {showChart && <CashflowChart weeks={weeks} cashIn={cashIn} cashOut={cashOut} result={outgoingBalance} />}
      <Card>
        <Card.Body>
          <label>
            <input type="checkbox" defaultValue={showChart} onClick={() => setShowChart(!showChart)} />{" "}
            {t("cash.showChart")}
          </label>
          <div id="cashflow-table" className="cashflow-sticky-header">
            <Table>
              <thead>
                <tr>
                  <th>Cash Flow (Ksek)</th>
                  {weeks.map((week, i) => (
                    <th key={i}>
                      {i === 0 && t("common:dates.week")} {week.weekNumber}
                      <br />
                      {formatDate(week.date)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{t("cash.incomingBalance")}</th>
                  {incomingBalance.map((amount, i) => (
                    <td key={i}>{formatMoney(amount, 1, 1)}</td>
                  ))}
                </tr>
                <CashIn
                  options={options}
                  toggle={toggle}
                  cashIn={cashIn}
                  cashInAddons={cashInAddons}
                  paymentsFromCustomers={paymentsFromCustomers}
                  simulation={simulation}
                  handleSimulationCIChange={handleSimulationCIChange}
                  handleAddonChange={(values, index) => handleAddonChange(values, index, "cashIn")}
                  t={t}
                />
                <CashOut
                  options={options}
                  toggle={toggle}
                  cashOut={cashOut}
                  outlays={outlays}
                  taxAccount={taxAccount}
                  netSalary={netSalary}
                  taxes={taxes}
                  personalTax={personalTax}
                  companyTax={companyTax}
                  vat={vat}
                  socSec={socSec}
                  cashOutAddons={cashOutAddons}
                  salaries={salaries}
                  weeksByNo={weeksByNo}
                  paymentsToSuppliers={paymentsToSuppliers}
                  handleSimulationOutlayChange={handleSimulationOutlayChange}
                  handleAddonChange={(values, index) => handleAddonChange(values, index, "cashOut")}
                  handleSalaryChange={(values, index) => handleSalaryChange(values, index)}
                  handleSIChange={handleSIChange}
                  t={t}
                />
                <tr>
                  <th>{t("cash.outgoingBalance")}</th>
                  {outgoingBalance.map((amount, i) => (
                    <td key={i}>{formatMoney(amount, 1, 1)}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default CashFlowTable;
