import React from "react";

function ToggleMenu() {
  function toggle() {
    if (document.body.classList.contains("sidebar-enable") || document.body.classList.contains("enlarged")) {
      // show
      document.body.classList.remove("sidebar-enable");
      document.body.classList.remove("enlarged");
    } else if (window.innerWidth > 835) {
      document.body.classList.add("enlarged");
    } else {
      document.body.classList.add("sidebar-enable");
    }
  }

  return (
    <button type="button" className="button-menu-mobile waves-effect waves-light" onClick={toggle}>
      <i className="fe-menu" />
    </button>
  );
}

export default ToggleMenu;
