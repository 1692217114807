import React from "react";

import useTable from "components/tables/btable/useTable";
import OfficeScheduleTemplateForm from "./OfficeScheduleTemplateForm";

function OfficeScheduleTemplateEditWidget({ officeId, scheduleTemplate }) {
  const {
    dataActions: { reload },
  } = useTable();

  return (
    <OfficeScheduleTemplateForm
      officeId={officeId}
      scheduleTemplate={scheduleTemplate}
      onSuccess={reload}
      onDelete={reload}
    />
  );
}

export default OfficeScheduleTemplateEditWidget;
