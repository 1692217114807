import _ from "lodash";

import { addDays } from "date-fns";
import { formatDate } from "utils/date";
import { rotRutByValue } from "./rotRutTypes";
import { roundMoney } from "../money";

function floor(value) {
  // Math.floor(10.3) => 10, Math.floor(-10.3) => -11
  // needed 10.3 -> 10, -10.3 -> -10
  if (value < 0) {
    return Math.ceil(value);
  }
  return Math.floor(value);
}

export function toBillectaAmount(amount, currency) {
  // billecta require that all amounts are sent in cents (not all currencies have cents)
  return ["ISK", "JPY", "KRW"].includes(currency) ? roundMoney(amount, 0) : amount * 100;
}

export function fromBillectaAmount(amount, currency) {
  // billecta require that all amounts are sent in cents (not all currencies have cents)
  return ["ISK", "JPY", "KRW"].includes(currency) ? amount : amount / 100;
}

export function calculateRecordTotal(record) {
  if (!record || (record && (!record.UnitPrice || !record.Quantity))) {
    return 0;
  }
  const discountType = _.isObject(record.DiscountType) ? record.DiscountType.value : record.DiscountType;
  const partial = record.UnitPrice * record.Quantity;
  if (record.DiscountType && record.Discount) {
    if (discountType === "Percentage") {
      return partial - (Math.abs(record.Discount) / 100) * partial;
    }
    return partial - record.Discount;
  }
  return partial;
}

export function calculateRecordRotRutTotal(record) {
  const total = calculateRecordTotal(record);
  let type;
  if (record.UnitPrice < 0) {
    return 0;
  }
  if (record.RotRutActivated) {
    type = typeof record.RotRutType === "string" ? rotRutByValue(record.RotRutType).type : record.RotRutType.type;
    if (type === "ROT") {
      return floor(total * (1 + record.VAT.value / 100) * 0.3);
    }
    if (type === "RUT") {
      return floor(total * (1 + record.VAT.value / 100) * 0.5);
    }
  }
  return 0;
}

export function calculateRecordsRotRutTotal(records) {
  return records.reduce((total, record) => total + calculateRecordRotRutTotal(record), 0);
}

export function autoFillRotRut(values) {
  const newRecords = values.records.map((record) => ({
    ...record,
    RotRutAmount: calculateRecordRotRutTotal(record),
    RotRutHours: record.RotRutActivated ? record.Quantity || 1 : 0,
  }));
  const total = newRecords.reduce((subtotal, record) => subtotal + record.RotRutAmount, 0);
  const newCustomers = values.RotRutDetails.Customers.map((customer) => ({
    ...customer,
    AskedAmount: floor(total / values.RotRutDetails.Customers.length),
  }));
  const customersTotal = newCustomers.reduce((subtotal, customer) => subtotal + customer.AskedAmount, 0);
  const diff = total - customersTotal;
  if (newCustomers.length && diff !== 0) {
    newCustomers[newCustomers.length - 1].AskedAmount += diff;
  }
  return {
    records: newRecords,
    RotRutDetails: {
      ...values.RotRutDetails,
      Customers: newCustomers || [],
    },
  };
}

export function validRotRut(records) {
  const toDeduct = calculateRecordsRotRutTotal(records);
  const deducted = records.reduce((total, item) => total + item.RotRutAmount || 0, 0);
  return Math.abs(deducted) <= Math.abs(toDeduct);
}

export function getSummaryNet(standardRecords) {
  return standardRecords.reduce((sum, record) => {
    return sum + calculateRecordTotal(record);
  }, 0);
}

export function getSummaryVat(reversedVat, standardRecords) {
  if (reversedVat) {
    return 0;
  }
  let totalNet = 0;
  return standardRecords.reduce((sum, record) => {
    totalNet = calculateRecordTotal(record);
    return sum + (totalNet * record.VAT.value) / 100;
  }, 0);
}

export function getTaxReduction(standardRecords) {
  return -1 * standardRecords.reduce((total, item) => total + item.RotRutAmount || total, 0);
}

export function formatDiscount(record, currency) {
  if (!record.DiscountType || !record.Discount) {
    return {};
  }
  const output = {
    Discount: record.Discount ? parseFloat(record.Discount) : record.Discount,
    DiscountType: record.DiscountType,
  };
  if (record.DiscountType === "Amount") {
    output.DiscountAmount = {
      Value: roundMoney(toBillectaAmount(record.Discount, currency), 0),
      CurrencyCode: currency,
    };
  } else if (record.DiscountType === "Percentage") {
    output.DiscountPercentage = Math.abs(parseFloat(record.Discount));
  } else {
    delete output.DiscountPercentage;
  }
  if (output.DiscountPercentage) {
    output.DiscountPercentage = Math.abs(parseFloat(record.Discount || 0));
  }
  return output;
}

export function formatData(values) {
  const data = _.cloneDeep({ ...values, records: [...values.records] });
  const dueDate = addDays(data.booking_date, data.payment_terms.value || 30);
  const currency = data.amount_currency.value;
  if (data.periodisation_enabled && (data.periodisation_config === null || data.periodisation_config === undefined)) {
    data.periodisation_config = {};
  }

  return {
    ...data,
    billecta_lang: data.customer.DefaultActionConfig.CommunicationLanguage || "SV",
    delivery_method: data.delivery_method || "Email",
    amount: 0,
    amount_currency: data.amount_currency.value,
    billecta_customer_id: data.customer.value,
    payment_terms: data.payment_terms.value,
    booking_date: formatDate(data.booking_date),
    delivery_date: formatDate(data.booking_date), // when invoice send to customer?
    due_date: formatDate(dueDate),
    price_group: data.price_group.value,
    rot_rut: {
      PropertyDesignation: data.rot_rut.PropertyDesignation,
      ResidenceAssociationOrgNo: data.rot_rut.ResidenceAssociationOrgNo,
      Customers: data.rot_rut.Customers.map((item) => ({
        ...item,
        AskedAmount: { Value: toBillectaAmount(item.AskedAmount, currency), CurrencyCode: currency },
      })),
    },
    your_reference: data.your_reference || "",
    records: data.records.map((record, idx) => {
      if (record.RecordType === "Standard") {
        const _recordRotRutMaterialCostAmountValue = record.RotRutMaterialCostAmount?.Value
          ? record.RotRutMaterialCostAmount?.Value
          : toBillectaAmount(record.RotRutMaterialCostAmount || 0, currency);
        const _record = {
          ...record,
          UnitPrice:
            record.RecordType === "Standard"
              ? {
                  Value: roundMoney(toBillectaAmount(record.UnitPrice, currency)),
                  CurrencyCode: currency,
                }
              : "",
          Quantity: roundMoney(record.Quantity),
          RotRutAmount: {
            Value: roundMoney(toBillectaAmount(record.RotRutAmount || 0, currency)),
            CurrencyCode: currency,
          },
          RotRutHours: parseInt(record.RotRutHours || 0, 10),
          RotRutMaterialCostAmount: {
            Value: roundMoney(_recordRotRutMaterialCostAmountValue),
            CurrencyCode: currency,
          },
          SequenceNo: idx + 1,
          ...formatDiscount(record, currency),
          VAT: record.VAT && !data.reversed_vat ? parseInt(record.VAT.value, 10) : 0,
          BookKeepingAccount: record.BookKeepingAccount,
        };
        delete _record.key;
        delete _record.maxQtyForCredit;
        if (_record.RotRutMaterialCostAmount && !_record.RotRutMaterialCostAmount.Value) {
          delete _record.RotRutMaterialCostAmount;
        }
        if (_record.RotRutActivated === null) {
          delete _record.RotRutActivated;
        }
        if (_record.DiscountType === null) {
          delete _record.DiscountType;
        }
        if (_record.RotRutHours === null) {
          delete _record.RotRutHours;
        }
        if (_record.RotRutType == null) {
          delete _record.RotRutType;
        }
        return _record;
      }
      return {
        RecordType: record.RecordType,
        ArticleNumber: record.ArticleNumber,
        ArticleDescription: record.ArticleDescription,
        SequenceNo: idx + 1,
      };
    }),
  };
}
