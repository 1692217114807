import React, { useCallback, useEffect, useRef, useState } from "react";
import "./WeekPicker.scss";
import DayPicker from "react-day-picker";
import i18n from "i18next";
import { addDays, endOfISOWeek, isSameDay, lastDayOfMonth, startOfISOWeek, startOfMonth, subDays } from "date-fns";
import * as ttAPI from "api/time-tracker";
import { parseDate } from "utils/date";
import locale from "./locale";

function getCurrentWeek() {
  const dates = [];
  let start = startOfISOWeek(new Date());
  for (let i = 1; i <= 7; i++) {
    dates.push(start);
    start = addDays(start, 1);
  }
  return dates;
}

function WeekPicker({ officeId, changeWeek, scheduleDateStart }) {
  const ref = useRef();
  const [selectedDays, setSelectedDays] = useState(getCurrentWeek);
  const [hoverRange, setHoverRange] = useState([]);
  const [lockedDays, setLockedDays] = useState([]);

  const handleLockChange = (event) => {
    if (event.detail.locked) {
      setLockedDays((_lockedDays) => [..._lockedDays, event.detail.day]);
    } else {
      setLockedDays((_lockedDays) => _lockedDays.filter((_day) => !isSameDay(_day, event.detail.day)));
    }
  };

  const handleMonthChange = useCallback(
    (firstDayOfMonth) => {
      // want to display 36 days in grid
      const dateFrom = subDays(firstDayOfMonth, firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1);
      const lastDayMonth = lastDayOfMonth(firstDayOfMonth);
      const dateTo = addDays(lastDayMonth, 7 - lastDayMonth.getDay());
      ttAPI.timeGrid
        .getMonthlyLockDates(officeId, dateFrom, dateTo)
        .then((response) => {
          setLockedDays(response.data.map((_date) => parseDate(_date)));
        })
        .catch(() => {});
    },
    [officeId]
  );

  useEffect(() => {
    document.body.addEventListener("tt/lockChanged", handleLockChange);
    return () => document.body.removeEventListener("tt/lockChanged", handleLockChange);
  }, []);

  useEffect(() => {
    handleMonthChange(startOfMonth(new Date()));
  }, [handleMonthChange]);

  const handleWeekClick = (weekNumber, days) => {
    setSelectedDays(days);
    changeWeek(days[0]);
  };
  const handleDayChange = (selectedDay) => {
    setSelectedDays({
      from: startOfISOWeek(selectedDay),
      to: endOfISOWeek(selectedDay),
    });
    changeWeek(selectedDay);
  };

  const daysAreSelected = selectedDays.length > 0;
  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
    locked: lockedDays,
    unlocked: {
      before: new Date(),
      after: scheduleDateStart,
    },
    future: {
      after: addDays(new Date(), 1),
    },
  };

  const handleDayEnter = (date) => {
    setHoverRange({
      from: startOfISOWeek(date),
      to: endOfISOWeek(date),
    });
  };

  const handleDayLeave = () => {
    setHoverRange(undefined);
  };

  return (
    <div className="week-picker">
      <DayPicker
        ref={ref}
        selectedDays={selectedDays}
        showWeekNumbers
        showOutsideDays
        months={locale.MONTHS[i18n.language]}
        weekdaysShort={locale.DAYS[i18n.language]}
        locale={i18n.language}
        modifiers={modifiers}
        firstDayOfWeek={1}
        onWeekClick={handleWeekClick}
        onDayClick={handleDayChange}
        onDayMouseEnter={handleDayEnter}
        onDayMouseLeave={handleDayLeave}
        onMonthChange={handleMonthChange}
      />
    </div>
  );
}

export default WeekPicker;
