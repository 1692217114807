import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import FileArchiveForm from "components/forms/FileArchiveForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as archiveAPI from "api/archive";
import { RocketLoader } from "components/ui/loaders";

function FileArchiveEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: documentId } = useParams();
  const { loading, item } = useInitialAsync(
    () => {
      return archiveAPI.archiveDocument.details(company.id, documentId);
    },
    {},
    [documentId]
  );

  const onUpdate = () => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <FileArchiveForm companyId={company.id} archive={item} onSave={onUpdate} />;
}

export default FileArchiveEditPage;
