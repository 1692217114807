import React from "react";
import { useNavigate, useNavigationType, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";
import * as invoiceAPI from "api/customer-invoices";

function CustomerInvoiceEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate(); // useNavigationType
  const navigateType = useNavigationType();
  const { id: verificationId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return invoiceAPI.details(company.id, verificationId);
  });

  const onUpdate = (invoice) => {
    if (navigateType !== "POP") {
      navigate(-1);
    } else {
      navigate("/invoicing/customer-invoices", { replace: true });
    }
  };

  if (loading) {
    return <RocketLoader />;
  }
  const connectedProducts = item.connected_products || {};
  delete item.connected_products;
  return (
    <CustomerInvoiceForm
      invoice={item}
      invoiceConnectedProducts={connectedProducts}
      company={company}
      onSave={onUpdate}
    />
  );
}

export default CustomerInvoiceEditPage;
