import React from "react";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

function Step3() {
  const { t } = useTranslation("common");
  return (
    <Modal.Body className="step-details step3">
      <p className="step-counter">
        <strong>{t("common:step")} 3 </strong>
        {t("common:of")} 4
      </p>
      <ul>
        <Trans i18nKey="company:skat.step3">
          <li>
            <strong>
              Organization number: <span className="text-primary">556981-0939</span>
            </strong>
            <span className="text-muted">(which is Bflow's organization number)</span>
          </li>
          <li>
            <strong>Validity:</strong> Select validity period
            <span className="text-muted">(suggested from today's date until further notice)</span>
          </li>
          <li>Sign your choice</li>
        </Trans>
      </ul>
    </Modal.Body>
  );
}

export default Step3;
