import React, { useMemo, useState } from "react";
import * as options from "api/options";
import { paymentTermsOptionsWithoutDefault } from "api/options";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Alert, Button, ButtonGroup, Card, Col, Form as BForm, InputGroup, Row } from "react-bootstrap";
import _ from "lodash";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";

import { CurrencyPicker, CustomerPicker, FormGroup } from "components/formik";
import * as ciAPI from "api/customer-invoices";
import * as offerAPI from "api/offers";
import * as billectaAPI from "api/billecta";
import { HintButton, SubmitButton } from "components/ui/buttons";
import { getCustomerCountryArea } from "utils/countries";

import { CCProjModal, CIPreviewCreateModal, DiscountModal, RotRutModal } from "components/modals/invoices";
import useModal from "hooks/useModal";
import useInitialAsync from "hooks/useInitialAsync";
import { formatData, validRotRut } from "utils/calc/ci";
import { useCompanyState } from "hooks/useCompany";
import { confirmExecute } from "components/modals/ConfirmModal";
import { NewDocuments, SavedDocuments } from "components/ui/documents/verification-documents";
import * as documentAPI from "api/document";
import cx from "classnames";
import { confirmConfigurePeriodisation } from "components/modals/invoices/CIPeriodisationModal";
import {
  isReversedVATAllowed,
  onChangePriceGroup,
  onCustomerChange,
  onCustomerReset,
  onReversedVatChange,
  RecordsForm,
  RecordsTool,
} from "./helpers";

function CustomerInvoiceForm({
  invoice,
  company,
  onSave,
  invoiceConnectedProducts = {},
  forCreditInvoice = false,
  forOffer = false,
}) {
  const [originalPrices, setOriginalPrices] = useState({});
  const { t, i18n } = useTranslation("ci");
  const priceGroups = options.priceGroups.asList();
  const { item: prices } = useInitialAsync(() => ciAPI.productPrices(company.id), {});
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [connectedProducts, setConnectedProducts] = useState(invoiceConnectedProducts);
  const {
    currencies: { byId: currencies },
    costCenters: { byId: centerById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
  } = useCompanyState();
  const discountModal = useModal();
  const previewModal = useModal();
  const rotRutModal = useModal();
  const ccProjModal = useModal();
  const initialValues = useMemo(() => {
    const initial_amount_currency = {
      value: currencies[invoice.amount_currency].value,
      label:
        i18n.language === "sv"
          ? currencies[invoice.amount_currency].label_sv
          : currencies[invoice.amount_currency].label_en,
    };
    return {
      ...invoice,
      price_group: options.priceGroups.getOption(invoice.price_group) || priceGroups[0],
      your_reference: invoice.your_reference || "",
      payment_terms: options.paymentTerms.getOption(invoice.payment_terms || company.default_invoice_payment_terms),
      amount_currency: initial_amount_currency,
      periodisation_enabled: !(!invoice.id || invoice.periodisation_config === null),
      undo_periodisation_from_credited_invoice: false,
    };
  }, [company.default_invoice_payment_terms, priceGroups, invoice, currencies, i18n.language]);

  const formikProps = {
    initialValues,
    validationSchema: yup.object().shape({
      customer: yup.object().nullable().required(),
      booking_date: yup.date().nullable().required(),
      reversed_vat_receiver_no: yup.string().when("reversed_vat", (reversedVAT, schema, { parent }) => {
        const countryArea = getCustomerCountryArea(parent.customer && parent.customer.CountryCode);
        if (reversedVAT && countryArea === "outside_eu") {
          return schema;
        }
        return reversedVAT ? schema.required() : schema;
      }),
    }),
    onSubmit: async (values, { setErrors, setFieldError, setFieldValue }) => {
      if (!validRotRut(values.records)) {
        setFieldError("__all__", t("errors.rotRutAmountHigher"));
        return false;
      }
      if (!forOffer && !values.draft && _.includes(["Email", "Mail"], values.delivery_method)) {
        const answer = await confirmExecute(t("confirm.send"));
        if (!answer) {
          return false;
        }
      }
      if (
        !forOffer &&
        !values.draft &&
        forCreditInvoice &&
        values.periodisation_status &&
        !["undone", "stopped_before_execution"].includes(values.periodisation_status)
      ) {
        const answer = await confirmExecute(
          t("confirm.undoPeriodisationOrJustSend", { status: t(`common:${values.periodisation_status}`) }),
          t("confirm.undoAndSendInvoice"),
          t("confirm.onlySendInvoice")
        );
        if (answer === null) {
          return false;
        }
        if (answer) {
          setFieldValue("undo_periodisation_from_credited_invoice", true);
          values.undo_periodisation_from_credited_invoice = true;
        }
      }
      const data = formatData(values);

      let promise;
      const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
      if (!forOffer) {
        promise = ciAPI.save(company.id, data, _newFiles);
      } else {
        promise = offerAPI.save(company.id, data);
      }
      return promise
        .then(async (response) => {
          if (values.draft) {
            toast.success(t("draftSaved"), { autoClose: 2000 });
          } else if (!forOffer && values.delivery_method === "Email") {
            toast.success(t("invoiceSent"), { autoClose: 2000 });
          } else {
            toast.success(t("msg:saved"), { autoClose: 2000 });
          }
          if (!values.draft && values.periodisation_enabled && !forOffer) {
            await confirmConfigurePeriodisation(company.id, response.data.invoice.id, centerById, projectById);
          }
          if (forOffer && _newFiles.length >= 1) {
            documentAPI.uploadOffer(company.id, response.data.id, _newFiles).catch(() => {});
          }
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          if (error.status !== 403) {
            toast.error(t("msg:fixErrors"));
            if (error.data && error.data.amount) {
              setErrors({ __all__: error.data.amount });
            } else {
              setErrors(error.data);
            }
          }
        });
    },
  };

  async function openPreview(values, setTouched, setFieldError) {
    if (!validRotRut(values.records)) {
      setFieldError("__all__", t("errors.rotRutAmountHigher"));
      return false;
    }
    const errors = await setTouched({ customer: true });
    if (!Object.keys(errors).length) {
      const data = formatData(values);
      const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
      previewModal.open({
        ...data,
        newFiles: _newFiles,
        documents: values.documents,
      });
    }
    return true;
  }

  const onRotRutSelected = (updatedRecords, RotRutDetails, setFieldValue, values) => {
    if (RotRutDetails.updateCustomerPropertyDesignation || RotRutDetails.updateCustomerResidenceAssociationOrgNo) {
      const {
        updateCustomerPropertyDesignation,
        updateCustomerResidenceAssociationOrgNo,
        ResidenceAssociationOrgNo,
        PropertyDesignation,
      } = RotRutDetails;
      const { Notes, DebtorPublicId } = values.customer;
      const data = {};
      if (updateCustomerResidenceAssociationOrgNo && ResidenceAssociationOrgNo !== Notes.ResidenceAssociationOrgNo) {
        data.ResidenceAssociationOrgNo = ResidenceAssociationOrgNo;
        setFieldValue("customer.Notes.ResidenceAssociationOrgNo", ResidenceAssociationOrgNo);
      }
      if (updateCustomerPropertyDesignation && PropertyDesignation !== Notes.PropertyDesignation) {
        data.PropertyDesignation = PropertyDesignation;
        setFieldValue("customer.Notes.PropertyDesignation", PropertyDesignation);
      }
      billectaAPI.customerShortUpdate(company.id, DebtorPublicId, { Notes: data });
      delete RotRutDetails.updateCustomerPropertyDesignation;
      delete RotRutDetails.updateCustomerResidenceAssociationOrgNo;
    }
    setFieldValue("records", updatedRecords, false);
    setFieldValue("rot_rut", RotRutDetails, false);
    rotRutModal.close();
  };

  return (
    <Card>
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue, setTouched, setValues, setFieldError, isSubmitting }) => {
          const isCustomerPrivate = values.customer && values.customer.DebtorType === "Private";
          const rotRutEnabled = !forOffer && isCustomerPrivate && company.invoice_tax_deduction_enabled;
          const reversedVATAllowed = isReversedVATAllowed(values.customer);
          return (
            <Form
              id="ciForm"
              onKeyDown={(e) => {
                if (e.which === 13) {
                  e.preventDefault();
                }
              }} // block enter key to submit form
            >
              <Card.Body>
                <Row xl={2} lg={1}>
                  <Col xl={6} lg={12}>
                    <CustomerPicker
                      name="customer"
                      label={t("common:customer")}
                      companyId={company.id}
                      menuPosition="portal"
                      customer={values.customer}
                      changeAllowed={forCreditInvoice === false}
                      onChange={(customer) =>
                        onCustomerChange(
                          company,
                          customer,
                          values,
                          setValues,
                          setFieldValue,
                          t,
                          connectedProducts,
                          prices,
                          originalPrices,
                          setOriginalPrices,
                          false,
                          forOffer
                        )
                      }
                      onReset={() =>
                        onCustomerReset(setFieldValue, values, setValues, connectedProducts, rotRutEnabled)
                      }
                      required
                    />
                  </Col>
                </Row>
                {values.customer && (
                  <>
                    <Row>
                      <Col lg={3}>
                        <FormGroup.Input name="your_reference" label={t("common:yourReference")} />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.Input name="our_reference" label={t("common:ourReference")} />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.BookingDatePicker
                          name="booking_date"
                          label={forOffer ? t("offerDate") : t("common:dates.invoiceDate")}
                          required
                        />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.SimpleSelect
                          name="payment_terms"
                          label={forOffer ? t("ci:validPeriod") : t("common:paymentTerms")}
                          options={paymentTermsOptionsWithoutDefault()}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={3}>
                        <CurrencyPicker
                          name="amount_currency"
                          disabled={forCreditInvoice === true}
                          currentCode={values.amount_currency.value}
                          currentDate={values.booking_date}
                        />
                      </Col>
                      <Col lg={3}>
                        <div className={cx("form-group", { "is-invalid": !!errors?.reversed_vat_receiver_no })}>
                          <label className="form-label">{t("reversedVat")}</label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <span className="input-group-text">
                                <Field
                                  type="checkbox"
                                  name="reversed_vat"
                                  disabled={!reversedVATAllowed || forCreditInvoice}
                                  onChange={(e) =>
                                    onReversedVatChange(
                                      e.currentTarget.checked,
                                      values.customer,
                                      values,
                                      setValues,
                                      connectedProducts
                                    )
                                  }
                                />
                              </span>
                            </InputGroup.Prepend>
                            <Field
                              type="text"
                              name="reversed_vat_receiver_no"
                              placeholder={t("common:money.vatNo")}
                              disabled={!values.reversed_vat || values.customer.VatNumber || !reversedVATAllowed}
                              required={values.reversed_vat}
                              className="form-control"
                            />
                            <BForm.Control.Feedback type="invalid">
                              <ErrorMessage name="reversed_vat_receiver_no" />
                            </BForm.Control.Feedback>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <FormGroup.SimpleSelect
                          name="price_group"
                          isDisabled={forCreditInvoice}
                          onChange={(selected) =>
                            onChangePriceGroup(
                              selected,
                              prices,
                              originalPrices,
                              setOriginalPrices,
                              values,
                              setFieldValue
                            )
                          }
                          label={t("priceGroup")}
                          options={priceGroups}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <FieldArray
                  name="records"
                  render={(arrayHelper) => (
                    <>
                      <RecordsForm
                        values={values}
                        arrayHelper={arrayHelper}
                        currency={values.amount_currency.value}
                        forCreditInvoice={forCreditInvoice}
                        t={t}
                      />
                      <RecordsTool
                        companyId={company.id}
                        arrayHelper={arrayHelper}
                        currency={values.amount_currency.value}
                        vatDisabled={company.vat_disabled || values.reversed_vat}
                        forCreditInvoice={forCreditInvoice}
                        rotRutEnabled={rotRutEnabled}
                        discountModal={discountModal}
                        rotRutModal={rotRutModal}
                        ccProjModal={ccProjModal}
                        centerOptions={centerOptions}
                        projectOptions={projectOptions}
                        prices={prices}
                        priceGroup={values.price_group}
                        connectedProducts={connectedProducts}
                        setConnectedProducts={setConnectedProducts}
                        originalPrices={originalPrices}
                        setOriginalPrices={setOriginalPrices}
                        forOffer={forOffer}
                        t={t}
                      />
                    </>
                  )}
                />
                <Row>
                  <Col>
                    {invoice.id && (
                      <SavedDocuments
                        companyId={company.id}
                        documents={invoice.documents}
                        partUrl={forOffer ? "offer-documents" : "verification-documents"}
                      />
                    )}
                    <NewDocuments
                      documents={newFiles}
                      fileTypes={["application/pdf"]}
                      multiple
                      onChange={({ file, index }) =>
                        documentAPI.onNewFileChange({
                          file,
                          index,
                          allFiles: newFiles,
                          setFile: setNewFiles,
                          max: 3,
                        })
                      }
                    />
                  </Col>
                </Row>
                {values.delivery_method === "Email" && !forOffer && (
                  <FormGroup.MultiEmail
                    name="extra_receivers"
                    label={t("extraReceivers")}
                    helpText={t("helpExtraReceivers")}
                  />
                )}
                {!forOffer && (
                  <Row>
                    <Col sm={9}>
                      <div className="form-group">
                        <label className="form-label">{t("sendInvoiceBy")}</label>
                        <DeliveryMethodSwitcher
                          t={t}
                          deliveryMethod={values.delivery_method}
                          changeDeliveryMethod={(newMethod) => setFieldValue("delivery_method", newMethod)}
                        />
                      </div>
                    </Col>
                    <Col sm={3} className="text-right">
                      <HintButton hint={t("msg.enablePeriodisationHint")} />
                      <Button
                        type="button"
                        variant="primary"
                        className="ml-1"
                        onClick={() => {
                          setFieldValue("periodisation_enabled", !values.periodisation_enabled);
                        }}
                      >
                        {values.periodisation_enabled ? (
                          <i className="fas fa-check-square mr-1" />
                        ) : (
                          <i className="fas fa-square mr-1" />
                        )}
                        {t("enablePeriodisation")}
                      </Button>
                    </Col>
                  </Row>
                )}
                {discountModal.show && (
                  <DiscountModal
                    show
                    companyId={company.id}
                    currency={values.amount_currency.value}
                    forCreditInvoice={forCreditInvoice}
                    records={values.records}
                    onSave={(records) => {
                      setFieldValue("records", records);
                      discountModal.close();
                    }}
                    onCancel={discountModal.close}
                  />
                )}
                {rotRutModal.show && !forOffer && (
                  <RotRutModal
                    currency={values.amount_currency.value}
                    companyId={company.id}
                    customer={values.customer}
                    records={values.records.map((record, i) => ({
                      ...record,
                      index: i,
                    }))}
                    rotRutDetails={values.rot_rut}
                    onCancel={rotRutModal.close}
                    onSave={({ records, RotRutDetails }) => {
                      onRotRutSelected(records, RotRutDetails, setFieldValue, values);
                    }}
                  />
                )}
                {ccProjModal.show && !forOffer && (
                  <CCProjModal
                    companyId={company.id}
                    records={values.records}
                    onCancel={ccProjModal.close}
                    onSave={(records) => {
                      setFieldValue("records", records, false);
                      ccProjModal.close();
                    }}
                  />
                )}
                {previewModal.show && !!previewModal.data && (
                  <CIPreviewCreateModal
                    companyId={company.id}
                    invoiceData={previewModal.data}
                    forOffer={forOffer}
                    onClose={previewModal.close}
                  />
                )}
                {errors && errors.__all__ && <Alert variant="danger">{errors.__all__}</Alert>}
                {forOffer && <Alert variant="warning">{t("offerInfo")}</Alert>}
              </Card.Body>
              <Card.Footer className="space">
                <div className="space-1">
                  <ButtonGroup>
                    <Button
                      type="button"
                      variant="outline-secondary"
                      className="mr-2"
                      onClick={() => openPreview(values, setTouched, setFieldError)}
                    >
                      <i className="fas fa-search mr-1" />
                      {forOffer ? t("actions.previewOffer") : t("actions.previewInvoice")}
                    </Button>
                    {forOffer ? (
                      <SubmitButton title="actions.save" isSubmitting={isSubmitting} />
                    ) : (
                      <>
                        <SubmitButton
                          variant="outline-primary"
                          icon="fa-sticky-note"
                          className="mr-2"
                          isSubmitting={isSubmitting}
                          title="common:actions.saveAsDraft"
                          onClick={() => {
                            setFieldValue("draft", true);
                          }}
                        />
                        <SubmitButton
                          title={values.delivery_method !== "Manually" ? "actions.sendAndBook" : "actions.saveAndBook"}
                          isSubmitting={isSubmitting}
                          onClick={() => {
                            setFieldValue("draft", false);
                          }}
                        />
                      </>
                    )}
                  </ButtonGroup>
                </div>
              </Card.Footer>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}

function DeliveryMethodSwitcher({ deliveryMethod, changeDeliveryMethod, t }) {
  return (
    <div>
      <ButtonGroup aria-label="Status filter">
        <Button
          variant="outline-secondary"
          active={deliveryMethod === "Email"}
          onClick={() => changeDeliveryMethod("Email")}
        >
          <i className="fas fa-at" /> {t("actions.sendEmail")}
        </Button>
        <Button
          variant="outline-secondary"
          active={deliveryMethod === "Mail"}
          onClick={() => changeDeliveryMethod("Mail")}
        >
          <i className="far fa-envelope-open" /> {t("actions.sendMail")}
        </Button>
        <Button
          variant="outline-secondary"
          active={deliveryMethod === "EInvoice"}
          onClick={() => changeDeliveryMethod("EInvoice")}
        >
          <i className="fas fa-desktop" /> {t("actions.sendEInvoice")}
        </Button>
        <Button
          variant="outline-secondary"
          active={deliveryMethod === "Manually"}
          onClick={() => changeDeliveryMethod("Manually")}
        >
          <i className="fas fa-exclamation" /> {t("actions.sendDoNot")}
        </Button>
      </ButtonGroup>
      <small className="form-text">
        {
          {
            Email: t("actions.sendEmailInfo"),
            // Draft: t("ci.sendDraftInfo"),
            Mail: t("actions.sendMailInfo"),
            EInvoice: t("actions.sendEInvoiceInfo"),
            Manually: t("actions.sendDoNotInfo"),
          }[deliveryMethod]
        }
      </small>
    </div>
  );
}

export default CustomerInvoiceForm;
export { DeliveryMethodSwitcher };
