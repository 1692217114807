import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { formatDuration } from "utils/date";
import { formatMoney } from "utils/money";
import BTable from "components/tables/btable";
import Filters from "./ProfitabilityFilters";
import ProfitabilityExtraHeader from "./ProfitabilityExtraHeader";

function ProfitabilityTable() {
  const { t } = useTranslation("company");

  const headers = useMemo(() => {
    return [
      {
        field: "name",
        label: t("common:companyName"),
      },
      {
        field: "org_no",
        label: t("common:orgNo"),
        canSort: false,
      },
      {
        field: "consult_name",
        label: t("common:consult"),
        sortField: "consult__first_name",
      },
      {
        field: "time_spend",
        label: t("manage.timeSpend"),
        className: "text-center",
        width: 200,
        render: (values) => formatDuration(values.time_spend),
      },
      {
        field: "invoiced_amount",
        label: t("manage.invoiced"),
        className: "text-center",
        width: 200,
        render: (values) => formatMoney(values.invoiced_amount),
      },
      {
        field: "invoiced_hour",
        label: t("manage.invoicedHour"),
        className: "text-center",
        width: 200,
        render: (values) => formatMoney(values.invoiced_hour),
      },
    ];
  }, [t]);

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      selectable={false}
      ExtraHeaderComponent={<ProfitabilityExtraHeader />}
      FiltersComponent={<Filters />}
    />
  );
}

export default ProfitabilityTable;
