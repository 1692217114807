import client from "./client";
//
// function details(companyId, verificationId) {
//   return client.get(`/companies/${companyId}/verifications/${verificationId}/`).then((response) => ({
//     ...response.data,
//     booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
//     newDocuments: [{ key: _.uniqueId("nd.") }],
//   }));
// }
//
// function documentsIds(companyId, verificationId) {
//   return client.get(`/companies/${companyId}/verifications/${verificationId}/documents_ids/`);
// }
//
// function update(companyId, data) {
//   return client.put(`/companies/${companyId}/verifications/${data.id}/`, data);
// }
//
// function create(companyId, data) {
//   return client.post(`/companies/${companyId}/verifications/`, data);
// }
//
// function save(companyId, data) {
//   if (data.id) {
//     return update(companyId, data);
//   }
//   return create(companyId, data);
// }
//
//
// function changePeriodisationStatus(companyId, verId, configId, status) {
//   return client.patch(`/companies/${companyId}/verifications/${verId}/periodisation-config/${configId}/${status}/`);
// }
//
// function remove(companyId, verificationId) {
//   // allowed only for staff
//   return client.delete(`/companies/${companyId}/verifications/${verificationId}/remove/`);
// }
//
// function removeLastSeries(companyId, verificationId) {
//   return client.delete(`/companies/${companyId}/verifications/${verificationId}/remove_last_from_series/`);
// }

// function duplicate(companyId, verificationId) {
//   return client.get(`/companies/${companyId}/verifications/${verificationId}/duplicate/`);
// }

// const comment = {
//   list(companyId, verificationId, config) {
//     return client.get(`/companies/${companyId}/verifications/${verificationId}/comments/`, {
//       params: { page_size: 100 },
//       ...config,
//     });
//   },
//   remove(companyId, verificationId, commentId) {
//     return client.delete(`/companies/${companyId}/verifications/${verificationId}/comments/${commentId}/`);
//   },
//   create(companyId, verificationId, text) {
//     return client.post(`/companies/${companyId}/verifications/${verificationId}/comments/`, { text });
//   },
// };

function logs(verificationId, verLogsOnly) {
  return client.get(`/verification-history/${verificationId}/`, { params: { verLogsOnly } });
}

export { logs };
