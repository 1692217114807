import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import Filters from "./ContractTemplateFilters";
import Actions from "./ContractTemplateActions";

function ContractTemplateTable({ companyId, selectable = false }) {
  const { t } = useTranslation("ci");

  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
      },
      {
        field: "ongoing",
        label: t("common:statuses.ongoing"),
        render: (values) => (values.ongoing ? t("common:Yes") : t("common:No")),
      },
      {
        field: "contract_length",
        label: t("contractLength"),
      },
      {
        field: "invoice_interval",
        label: t("invoiceIntervalMonths"),
      },
    ],
    [t]
  );

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.total")}:</strong>
      {responseHeaders["x-pagination-count"]}
    </div>
  );

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
    />
  );
}

export default ContractTemplateTable;
