import cx from "classnames";
import { Col } from "react-bootstrap";
import React from "react";
import { addMonths, format, parseISO, subMonths } from "date-fns";

function yM(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return format(date, "yyyy-MM");
}

function pad2(number) {
  return (number < 10 ? "0" : "") + number;
}

function arrayFromRange(start, end, initial) {
  const output = {};
  while (start < end) {
    output[yM(start)] = {
      name: format(start, "yyyy MMM"),
      date: start,
      ...initial,
    };
    start = addMonths(start, 1);
  }
  return output;
}

function arrayMonthsBack(months, initial) {
  const output = {};
  let start = new Date();
  const end = subMonths(start, months);
  while (start > end) {
    output[yM(start)] = {
      name: format(start, "yyyy MMM"),
      date: start,
      ...initial,
    };
    start = subMonths(start, 1);
  }
  return output;
}

function StatusBox({ label, value }) {
  return (
    <Col xl={4}>
      <div className={cx("card widget-box-two bg-success")}>
        <div className="card-body p-2">
          <div className="media">
            <div className="media-body wigdet-two-content">
              <p className="m-0 text-uppercase text-white font-11">{label}</p>
              <h2 className="text-white">
                <span data-plugin="counterup">{value}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

export { StatusBox, pad2, arrayFromRange, arrayMonthsBack };
