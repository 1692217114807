import CreatableSelect from "react-select/creatable";
import React, { useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useAuthState } from "hooks/useAuth";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const schema = yup.string().email();

function MultiEmailInput({ initial, onChange, showAddMe }) {
  const { t } = useTranslation("common");
  const { user } = useAuthState();
  const [state, setState] = useState({
    inputValue: "",
    value: initial ? initial.map((email) => createOption(email)) : [],
  });

  const handleBlur = () => {
    onChange(state.value.map((item) => item.value));
  };

  const handleChange = (value) => {
    setState({ ...state, value: value || [] });
  };

  const handleInputChange = (inputValue) => {
    setState({ ...state, inputValue });
  };

  const handleKeyDown = async (event) => {
    const { inputValue, value } = state;
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab": {
        event.preventDefault();
        const isValid = await schema.isValid(inputValue);
        const alreadyExists = value.map((item) => item.value).includes(inputValue);
        if (!isValid || alreadyExists) {
          setState({ ...state, inputValue: "" });
          return;
        }
        setState({
          inputValue: "",
          value: [...value, createOption(inputValue)],
        });
        break;
      }
      default:
        break;
    }
  };

  const addMyEmail = () => {
    if (!state.value.find((option) => option.value === user.email)) {
      const newState = {
        ...state,
        value: [...state.value, createOption(user.email)],
      };
      setState(newState);
      onChange(newState.value.map((item) => item.value));
    }
  };

  const { inputValue, value } = state;
  return (
    <div className="multi-email-field">
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        className="react-select"
        classNamePrefix="select"
        menuIsOpen={false}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={t("typeAndEnter")}
        value={value}
      />
      {showAddMe && (
        <Button type="button" onClick={addMyEmail}>
          {t("sendCopyToMe")}
        </Button>
      )}
    </div>
  );
}

export default MultiEmailInput;
