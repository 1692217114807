import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Alert, Card } from "react-bootstrap";
import { SubmitButton } from "components/ui/buttons";

function ReconciliationSubmit({ accountNumber, date }) {
  const { isSubmitting, status } = useFormikContext();
  const { t } = useTranslation("reports");
  return (
    <Card>
      <Card.Footer>
        <Alert variant="warning">{t("onSubmitNote1", { account: accountNumber, date })}</Alert>
        <SubmitButton title="actions.saveStay" disabled={status} isSubmitting={isSubmitting} />
      </Card.Footer>
    </Card>
  );
}

export default ReconciliationSubmit;
