import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import { TableGroup } from "components/formik";
import { useCompanyState } from "hooks/useCompany";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { DraggableModalDialog } from "../DraggableModalDialog";

function CCProjModal({ records, onSave, onCancel }) {
  const { t } = useTranslation("common");
  const {
    costCenters: { asOptions: centerOptions, byId: centerById },
    projects: { asOptions: projectOptions, byId: projectById },
  } = useCompanyState();
  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenter = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0;
  const showCostCenter = activeCenter.length !== 0;

  function getInitialCenter(record) {
    if (record.BflowCostCenter && centerById[record.BflowCostCenter] && centerById[record.BflowCostCenter].is_active) {
      return centerById[record.BflowCostCenter];
    }
    if (
      record.ProductExternalId &&
      centerById[record.ProductExternalId] &&
      centerById[record.ProductExternalId].is_active
    ) {
      return centerById[record.ProductExternalId];
    }
    return null;
  }

  const formikProps = {
    initialValues: {
      records: records.map((record) => ({
        ...record,
        BflowProject: record.BflowProject ? projectById[record.BflowProject] : null,
        BflowCostCenter: getInitialCenter(record),
      })),
    },
    onSubmit: async (values) => {
      onSave(
        values.records.map((record) => ({
          ...record,
          BflowProject: record.BflowProject ? record.BflowProject.value : null,
          BflowCostCenter: record.BflowCostCenter ? record.BflowCostCenter.value : null,
        }))
      );
    },
  };

  return (
    <Modal scrollable show onHide={onCancel} size="lg" dialogAs={DraggableModalDialog}>
      <Formik {...formikProps}>
        {({ values, setFieldValue, errors, isValid }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="m-0">{t("ccAndProj")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0" style={{ maxHeight: "60vh" }}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>{t("common:description")}</th>
                    {showCostCenter && (
                      <th style={{ width: 250 }} className="text-right">
                        {t("common:costCenter")}
                      </th>
                    )}
                    {showProjects && (
                      <th style={{ width: 250 }} className="text-center">
                        {t("common:project")}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name="records">
                    <>
                      {values.records.map(
                        (record, index) =>
                          record.RecordType === "Standard" && (
                            <React.Fragment key={record.key}>
                              <tr>
                                <td className="disabled">
                                  <span>{record.ArticleDescription}</span>
                                </td>
                                {showCostCenter && (
                                  <TableGroup.SimpleSelect
                                    options={centerOptions.filter(filterActiveCC)}
                                    isClearable
                                    name={`records[${index}].BflowCostCenter`}
                                  />
                                )}
                                {showProjects && (
                                  <TableGroup.SimpleSelect
                                    options={projectOptions.filter(filterActiveProjects)}
                                    isClearable
                                    name={`records[${index}].BflowProject`}
                                  />
                                )}
                              </tr>
                            </React.Fragment>
                          )
                      )}
                      {!values.records.filter((item) => item.RecordType === "Standard").length && (
                        <tr>
                          <td colSpan={8} className="p-2">
                            <span>{t("common:noResultsFound")}</span>
                          </td>
                        </tr>
                      )}
                    </>
                  </FieldArray>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer className="space">
              <Button type="button" variant="secondary" onClick={onCancel}>
                {t("common:actions.cancel")}
              </Button>
              <SubmitButton disabled={!isValid} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default CCProjModal;
