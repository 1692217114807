const categories = [
  {
    label: "other",
    options: [
      { value: "bank", label: "Bank", type: "Other" },
      { value: "vat", label: "VAT & EU VAT", type: "Other" },
      { value: "tax_soc", label: "Tax & Soc Sec.", type: "Other" },
      { value: "ctrl_rec", label: "Control records", type: "Other" },
      { value: "recon", label: "Reconciliation", type: "Other" },
      { value: "auth_grant", label: "Authorities & grants", type: "Other" },
      { value: "properties", label: "Properties", type: "Other" },
      { value: "cus_agreement", label: "Customer agreements", type: "Other" },
      { value: "sup_agreement", label: "Supplier agreements", type: "Other" },
      { value: "other", label: "Other", type: "Other" },
    ],
  },
  {
    label: "basicDocuments",
    options: [
      { value: "certificate", label: "Registration certificate", type: "Basic" },
      { value: "share", label: "Share register", type: "Basic" },
      { value: "assoc", label: "Articles of association", type: "Basic" },
      {
        value: "business_desc",
        label: "General assignment routines and description of the business",
        type: "Basic",
      },
      { value: "sys_doc", label: "System documentation", type: "Basic" },
    ],
  },
  {
    label: "yearlyDocuments",
    options: [
      { value: "rec_spec", label: "Reconciliation and specifications", type: "Yearly" },
      { value: "bs_pl", label: "Balance sheet and P&L", type: "Yearly" },
      { value: "fin_statement", label: "Financial statement appendices", type: "Yearly" },
      { value: "annual", label: "Annual report", type: "Yearly" },
      { value: "income", label: "Income declaration", type: "Yearly" },
      { value: "tax_calc", label: "Tax calculation", type: "Yearly" },
      { value: "sie", label: "SIE-file", type: "Yearly" },
      { value: "board", label: "Board meetings", type: "Yearly" },
    ],
  },
];

export function categoryByValue(value) {
  for (let i = 0; i < categories.length; i++) {
    for (let j = 0; j < categories[i].options.length; j++) {
      if (categories[i].options[j].value === value) {
        return categories[i].options[j];
      }
    }
  }
  return null;
}

export default categories;
