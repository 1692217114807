import React, { useCallback, useEffect, useState } from "react";
import client from "api2/client";
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import { Loader } from "components/ui/loaders";
import { Alert } from "react-bootstrap";
import loadImage from "blueimp-load-image";
import { getFileNameDispositionContent } from "utils/file";
import { Document, FileInfo } from "components/ui/documents/document-box";

function DocumentBox({ documentId, companyId, partUrl, removable = false, extraActionData = {} }) {
  const { t } = useTranslation("common");
  const [data, setData] = useState({
    url: "",
    type: "",
    fileName: "",
    size: 0,
    loading: true,
    error: "",
  });
  const dataUrl = data ? data.url : null;
  useEffect(() => {
    const signal = axios.CancelToken.source();
    client
      .get(`${partUrl}/documents/${documentId}/file/`, {
        responseType: "blob",
        cancelToken: signal.token,
        headers: {
          "x-company": companyId,
        },
      })
      .then((response) => {
        const blob = response.data;
        const fileName = getFileNameDispositionContent(response) || "";
        setData({
          url: window.URL.createObjectURL(blob),
          fileName,
          type: blob.type,
          size: blob.size,
          loading: false,
          error: "",
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setData({
            url: "",
            fileName: "",
            type: "",
            size: 0,
            rotation: 0,
            loading: false,
            error: t("common:file.cannotLoadFile"),
          });
        }
      });
    return () => {
      signal.cancel("aborted");
    };
  }, [companyId, documentId, partUrl, t]);

  const onRemove = useCallback(() => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      client
        .delete(`${partUrl}/documents/${documentId}/delete/`, {
          headers: {
            "x-company": companyId,
          },
        })
        .then(() => {
          document.body.dispatchEvent(new CustomEvent("document/removed", { detail: { id: documentId } }));
          setData(null);
        });
    }
  }, [companyId, documentId, partUrl]);

  const onRotate = useCallback(() => {
    loadImage(
      dataUrl,
      (image) => {
        image.toBlob((blob) => {
          setData((_old) => ({
            url: window.URL.createObjectURL(blob),
            fileName: _old.fileName,
            type: blob.type,
            size: blob.size,
            loading: false,
            error: "",
          }));
        });
      },
      { meta: true, orientation: 6 }
    );
  }, [dataUrl]);

  if (!data) {
    return <div />;
  }
  return (
    <div className="document-box rotate90">
      {data.loading && <Loader />}
      {data.url && !data.loading && (
        <FileInfo
          type={data.type}
          size={data.size}
          removable={removable}
          onRemove={onRemove}
          onRotate={onRotate}
          extraActionData={extraActionData}
        />
      )}
      {data.url && (
        <Document url={data.url} fileName={data.fileName} type={data.type} rotation={data.rotation} fullMode={false} />
      )}
      {!data.loading && data.error && <Alert variant="danger">{data.error}</Alert>}
    </div>
  );
}

const NoDocumentBox = withTranslation("common")(({ t }) => (
  <div className="document-box document-empty">{t("common:file.noFile")}</div>
));

export { DocumentBox, Document, FileInfo, NoDocumentBox };
