import React, { useEffect } from "react";

import useTable from "components/tables/btable/useTable";

function countFilledInputs(data) {
  const output = {
    date_client_send: 0,
    date_bolagsverket_send: 0,
    date_bolagsverket_send_max: 0,
    date_ink2_register: 0,
    date_control_bv: 0,
    date_invoiced: 0,
    date_k10_ready: 0,
  };
  let consultInfo;
  data.forEach((company) => {
    consultInfo = company.consult_info;
    if (consultInfo) {
      output.date_client_send += consultInfo.date_client_send ? 1 : 0;
      output.date_bolagsverket_send += consultInfo.date_bolagsverket_send ? 1 : 0;
      output.date_ink2_register += consultInfo.date_ink2_register ? 1 : 0;
      output.date_control_bv += consultInfo.date_control_bv ? 1 : 0;
      output.date_invoiced += consultInfo.date_invoiced ? 1 : 0;
      output.date_k10_ready += consultInfo.date_k10_ready ? 1 : 0;
      if (company.auditor_name) {
        output.date_bolagsverket_send_max += 1;
      }
    }
  });
  return output;
}

function YearEndClosureExtraHeader() {
  const { data } = useTable();
  const totalRows = data.length;
  const [calc, setCalc] = React.useState({});

  useEffect(() => {
    setCalc(countFilledInputs(data));
  }, [data]);

  useEffect(() => {
    function bmdUpdated(event) {
      const { field, value } = event.detail;
      setCalc((state) => ({
        ...state,
        [field]: state[field] + (value ? 1 : -1),
      }));
    }

    document.body.addEventListener("bmd/changed", bmdUpdated);
    return () => document.body.removeEventListener("bmd/changed", bmdUpdated);
  }, []);

  return (
    <tr className="sticky2">
      <th colSpan={5} />
      <th className="text-center">
        {calc.date_client_send}/{totalRows}
      </th>
      <th className="text-center">
        {calc.date_bolagsverket_send}/{calc.date_bolagsverket_send_max}
      </th>
      <th className="text-center">
        {calc.date_ink2_register}/{totalRows}
      </th>
      <th className="text-center">
        {calc.date_control_bv}/{totalRows}
      </th>
      <th className="text-center">
        {calc.date_invoiced}/{totalRows}
      </th>
      <th className="text-center">
        {calc.date_k10_ready}/{totalRows}
      </th>
    </tr>
  );
}

export default YearEndClosureExtraHeader;
