import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { EmployeeScheduleExtendedTable } from "components/tables";
import * as tableAPI from "api/table";

function EmployeeScheduleListPage({ tableId = "employeesSchedules" }) {
  const { companyId } = useParams();
  const initialFilters = {};
  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => tableAPI.companyEmployees(companyId, { ...filters, for_schedule: 1 }, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <EmployeeScheduleExtendedTable companyId={companyId} />
        </Card.Body>
      </Card>
    </TableProvider>
  );
}

export default EmployeeScheduleListPage;
