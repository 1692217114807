import React, { useState } from "react";
import { useNavigate, useOutletContext, useParams, useResolvedPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, ButtonGroup, Card } from "react-bootstrap";

import useModal from "hooks/useModal";
import * as outlayAPI from "api2/outlays";
import { formatDate } from "utils/date";
import { formatMoney } from "utils/money";
import DisputeModal from "components/modals/DisputeModal";
import ReInvoiceModal from "components/modals/ReInvoiceModal";
import { VerificationCommentsModal } from "components/modals/CommentsModal";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { useCompanyState } from "hooks/useCompany";
import { codesFor, HasPermCode } from "components/perms";
import * as options from "api/options";
import { confirmExecute } from "components/modals/ConfirmModal";
import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";
import _ from "lodash";
import { ItemNotFoundErrorPage } from "pages/errors";
import { handleActionErrors } from "api/errors";
import MileagePreviewContent from "./MileagePreviewContent";
import ReceiptPreviewContent from "./ReceiptPreviewContent";

function OutlayPreviewPage({ type = "R" }) {
  const company = useOutletContext();
  const navigate = useNavigate();
  const currentPath = useResolvedPath("").pathname;
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const [refreshCount, setRefreshCount] = useState(0);
  const { t } = useTranslation("outlay");
  const disputeModal = useModal();
  const reInvoiceModal = useModal();
  const commentsModal = useModal();
  const { id: receiptId } = useParams();
  const fetchOutlay = React.useCallback(
    (cancelToken) => {
      const apiRequest = type === "R" ? outlayAPI.receiptDetails : outlayAPI.mileageDetails;
      return apiRequest(company.id, receiptId, { cancelToken });
    },
    [company.id, receiptId, type]
  );
  const { loading, item } = useInitialAsync(({ cancelToken }) => fetchOutlay(cancelToken), {}, [
    refreshCount,
    receiptId,
  ]);
  const {
    accounts: { byId: accountById },
    costCenters: { byId: centerById },
    projects: { byId: projectById },
  } = useCompanyState();
  const canApproveOutlays = HasPermCode(codesFor.outlays.approve);
  const canManageCustomerInvoices = HasPermCode(codesFor.customerInvoices.manage);
  const isCompanyConsult = HasPermCode(codesFor.consultManage.enabled);
  const handlePartialUpdate = (values) => {
    return outlayAPI.approveUpdate(company.id, values).then((response) => {
      setRefreshCount(refreshCount + 1);
      return response;
    });
  };

  const handleApprove = async () => {
    const unsavedWarn = !!document.getElementById("updateWarn");
    if (unsavedWarn) {
      const answer = await confirmExecute(t("common:confirm.unsavedData"));
      if (!answer) {
        return true;
      }
    }

    await outlayAPI
      .approve(company.id, item.id, { next: true })
      .then((response) => {
        reloadMenuNumbers();
        if (response.data.next) {
          const isReceipt = currentPath.includes("receipt");
          const nextLoc = currentPath
            .replace(item.id, response.data.next.id)
            .replace(isReceipt ? "receipt" : "mileage", response.data.next.is_receipt === true ? "receipt" : "mileage");
          navigate("/temp", { replace: true });
          navigate(nextLoc, { replace: true });
        } else {
          navigate("/approve/outlays", { replace: true });
        }
      })
      .catch((error) => {
        const errorMsg = "__all__" in error.data ? error.data.__all__ : error.data;
        toast.error(errorMsg);
      });
    return true;
  };

  const handleDispute = async (values) => {
    await outlayAPI.dispute(company.id, item.id, values).then(() => {
      reloadMenuNumbers();
      navigate(-1);
    });
  };
  const handlePrelim = async () => {
    await outlayAPI
      .prelim(company.id, item.id)
      .then((response) => {
        reloadMenuNumbers();
        if (response.data.next) {
          const isReceipt = currentPath.includes("receipt");
          const nextLoc = currentPath
            .replace(item.id, response.data.next)
            .replace(isReceipt ? "receipt" : "mileage", response.data.next_is_receipt === true ? "receipt" : "mileage");
          navigate("/temp", { replace: true });
          navigate(nextLoc, { replace: true });
        } else {
          navigate(-1);
        }
        toast.success(t("msg:saved"), { autoClose: 2000 });
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };
  const handleReInvoice = async (values) => {
    await outlayAPI
      .reInvoice(company.id, item.id, {
        billecta_customer_id: values.customer.value,
        your_reference: values.customer.ContactName || "",
        our_reference: values.customer.DefaultActionConfig.OurReference || "",
      })
      .then(() => {
        // refresh
        navigate("/temp", { replace: true });
        navigate(-1);
      });
  };

  if (loading) {
    return <RocketLoader />;
  }

  if (!loading && _.isEmpty(item)) {
    return <ItemNotFoundErrorPage />;
  }

  const outlay = {
    ...item,
    booking_date: formatDate(item.booking_date),
    receipt_date: item.receipt_date && formatDate(item.receipt_date),
    paid_by: item.paid_by === "P" ? t("paidWithP") : t("paidWithF"),
    account: item.account && accountById[item.account],
    amount: `${formatMoney(item.amount)} ${item.amount_currency}`,
    vat_amount: `${formatMoney(item.vat_amount)} ${item.amount_currency}`,
    ownership: item.ownership === "private" ? t("privateCar") : t("companyCar"),
    fuel_type: item.fuel_type ? t(item.fuel_type) : "-",
    cost_center: item.cost_center && centerById[item.cost_center],
    project: item.project && projectById[item.project],
    representation:
      item.representation && item.account === 6072
        ? {
            participants: item.representation.participants,
            activity: options.repActivities.getOption(item.representation.activity),
            purpose: options.repPurposes.getOption(item.representation.purpose),
          }
        : null,
  };

  const inApproval = outlay.sub_status === "prelim";
  const inInvestigate = outlay.sub_status === "investigate";

  const Buttons = (
    <ButtonGroup>
      {inApproval && canApproveOutlays && (
        <Button variant="first" onClick={handleApprove}>
          <i className="fas fa-save" /> {t("common:actions.approve")}
        </Button>
      )}
      <Button variant={outlay.comments.length ? "warning" : "second"} onClick={() => commentsModal.open(outlay.id)}>
        <i className="fas fa-comment-dots" /> {t("common:comments")}
      </Button>
      {inApproval && (
        <>
          {canApproveOutlays && (
            <Button variant="second" onClick={disputeModal.open}>
              {t("common:actions.dispute")}
            </Button>
          )}
          {canManageCustomerInvoices && !outlay.reinvoice_to && (
            <Button variant="second" onClick={reInvoiceModal.open}>
              {t("common:actions.reInvoice")}
            </Button>
          )}
        </>
      )}
      {inInvestigate && (
        <>
          <Button
            variant="second"
            onClick={() => navigate(`${currentPath.replace("/preview", "")}/change`, { replace: true })}
          >
            {t("common:actions.change")}
          </Button>
          {isCompanyConsult && (
            <>
              <Button variant="second" onClick={disputeModal.open}>
                {t("common:actions.dispute")}
              </Button>
              <Button variant="second" onClick={handlePrelim}>
                {t("common:actions.movePrelim")}
              </Button>
            </>
          )}
        </>
      )}
    </ButtonGroup>
  );

  return (
    <>
      <Card>
        <Card.Body>
          {type === "R" ? (
            <ReceiptPreviewContent
              company={company}
              outlay={outlay}
              inApproval={inApproval}
              handlePartialUpdate={handlePartialUpdate}
              t={t}
              reInvoiceModal={reInvoiceModal}
              disputeModal={disputeModal}
            >
              {Buttons}
            </ReceiptPreviewContent>
          ) : (
            <MileagePreviewContent
              company={company}
              outlay={outlay}
              t={t}
              reInvoiceModal={reInvoiceModal}
              disputeModal={disputeModal}
            >
              {Buttons}
            </MileagePreviewContent>
          )}
        </Card.Body>
      </Card>
      {disputeModal.show && <DisputeModal handleClose={disputeModal.close} onSubmit={handleDispute} />}
      {reInvoiceModal.show && (
        <ReInvoiceModal companyId={company.id} handleClose={reInvoiceModal.close} onSubmit={handleReInvoice} />
      )}
      {commentsModal.show && (
        <VerificationCommentsModal
          companyId={company.id}
          verification={{ id: commentsModal.data }}
          handleClose={commentsModal.close}
        />
      )}
    </>
  );
}

export default OutlayPreviewPage;
