import React, { useEffect, useMemo, useState } from "react";
import { useAuthState } from "hooks/useAuth";
import * as selectAPI from "api/select";
import { RocketLoader } from "components/ui/loaders";
import { Alert } from "react-bootstrap";

const TimeTrackerStateContext = React.createContext(undefined);

function TimeTrackerProvider({ children }) {
  const { user } = useAuthState();
  const [selected, setSelected] = useState(user.offices.length ? user.offices[0] : null);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState({
    consults: [],
    companies: [],
  });

  useEffect(() => {
    const consultPromise = selectAPI.getConsults({
      officeId: selected.office,
      roles: ["consult", "manager"],
    });
    const companyPromise = selectAPI.getCompanies({
      officeId: selected.office,
    });
    setLoading(true);
    Promise.all([consultPromise, companyPromise])
      .then(([consults, companies]) => {
        setOptions({
          consults,
          companies,
        });
      })
      .catch((errors) => {
        // todo: need info?
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selected.office]);

  const stateValue = useMemo(
    () => ({
      options,
      loading,
      selected,
      offices: user.offices,
      selectOffice: (selectedOffice) => setSelected(selectedOffice),
    }),
    [loading, options, selected, user.offices]
  );

  if (loading) {
    return <RocketLoader />;
  }
  if (!stateValue.offices.length) {
    return <Alert>You are not a part of any office. Can not access to Time tracker</Alert>;
  }

  return <TimeTrackerStateContext.Provider value={stateValue}>{children}</TimeTrackerStateContext.Provider>;
}

export { TimeTrackerStateContext };

export default TimeTrackerProvider;
