import React, { useContext, useEffect } from "react";
import { Outlet, useOutletContext, useResolvedPath } from "react-router-dom";
import { FiltersContext, FiltersProvider } from "state/providers/FiltersProvider";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import { isAfter } from "date-fns";
import { useQuery } from "../../utils/others";
import { parseDate } from "../../utils/date";

function FinancialYearTrigger({ children }) {
  const { financialYear } = useContext(FinancialYearStateContext);
  const queryParams = useQuery();
  const startParam = queryParams.get("start");
  const endParam = queryParams.get("end");

  const { filters, updateFilter } = useContext(FiltersContext);
  useEffect(() => {
    let start;
    let end;
    if (!startParam || !endParam) {
      end = isAfter(financialYear.date_end, new Date()) ? new Date() : financialYear.date_end;
      start = financialYear.date_start;
    } else {
      // from query param
      start = parseDate(startParam);
      end = parseDate(endParam);
    }
    updateFilter({ fYear: financialYear, start, end });
  }, [startParam, endParam, updateFilter, financialYear]);

  if (!filters.fYear) {
    return null;
  }

  return children;
}

function AccountingReportsLayout() {
  const parentPath = useResolvedPath("").pathname;
  const company = useOutletContext();
  return (
    <FiltersProvider>
      <FinancialYearTrigger>
        <div className="tab-card">
          <Outlet context={{ company, parentPath }} />
        </div>
      </FinancialYearTrigger>
    </FiltersProvider>
  );
}

export default AccountingReportsLayout;
