import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./SalaryFilters";
import Actions from "./SalaryActions";

function SalaryTable({ companyId, forStatus, allowedModes, selectable = false }) {
  const { t } = useTranslation("salary");

  const headers = useMemo(
    () => [
      {
        field: "receiver",
        label: t("common:employee"),
      },
      {
        field: "salary_gross",
        label: t("grossSalary"),
        className: "text-right",
        render: (values) => formatMoney(values.salary_gross),
      },
      {
        field: "amount",
        label: t("common:money.toPay"),
        className: "text-right",
        render: (values) => formatMoney(values.amount),
      },
      {
        field: "paid_to",
        label: t("paidTo"),
        render: (values) => {
          return t(`common:account${values.paid_to}`);
        },
      },
      {
        field: "payment_date",
        label: t("common:dates.paymentDate"),
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return {
      className: cx(row.sub_status || "draft", {
        "has-message": !!row.comments.length,
      }),
    };
  };

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.toPay")}:</strong>
      {formatMoney(responseHeaders["x-pagination-amount"])}
    </div>
  );

  const renderSelect = (row) => {
    if (row.sub_status === "unpaid") {
      if (row.in_signing) {
        return "SIGN";
      }
    }
    return undefined;
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      renderSelect={renderSelect}
      FiltersComponent={<Filters companyId={companyId} forStatus={forStatus} allowedModes={allowedModes} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
      getRowProps={getRowProps}
    />
  );
}

export default SalaryTable;
