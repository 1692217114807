import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import { formatMoney } from "utils/money";
import Filters from "./OfferFilters";
import Actions from "./OfferActions";

function OfferTable({ companyId }) {
  const { t } = useTranslation("common");
  const headers = useMemo(
    () => [
      {
        field: "customer_snapshot",
        label: t("common:customer"),
        render: (values) =>
          values.customer_snapshot ? (
            <span title={values.customer_snapshot.Name}>{truncateText(values.customer_snapshot.Name, 20)}</span>
          ) : (
            "-"
          ),
      },
      {
        field: "amount_sek",
        label: t("common:money.amount"),
        className: "text-right",
        render: (value) => formatMoney(value.amount_sek),
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
      {
        field: "last_email_sent_date",
        label: t("common:dates.sentDate"),
      },
    ],
    [t]
  );

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.amount")}:</strong>
      {formatMoney(responseHeaders["x-pagination-amount"])}
    </div>
  );

  return (
    <BTable
      headers={headers}
      selectable={false}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
    />
  );
}

export default OfferTable;
