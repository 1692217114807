import { addBusinessDays, format } from "date-fns";
import { formatDate, formatDay, formatDuration, isBefore, parseDate } from "utils/date";
import * as trackerAPI from "api/time-tracker";
import { sv } from "date-fns/locale";
import { toast } from "react-toastify";

export function getNextDays(start, numberOfDays = 5, language = "en") {
  const output = {};
  if (!start) {
    start = new Date();
  }
  let dateStr;
  for (let i = 0; i < numberOfDays; i++) {
    dateStr = formatDate(start);
    output[dateStr] = {
      key: dateStr,
      date: start,
      name: formatDay(start, language),
      items: [],
    };
    start = addBusinessDays(start, 1);
  }
  return output;
}

export function getDaysTitle(days, language) {
  const daysList = Object.values(days);
  const first = Object.values(days)[0].date;
  const last = Object.values(days)[daysList.length - 1].date;
  const options = { locale: language === "sv" ? sv : null };
  let title = "";
  if (first.getMonth() === last.getMonth()) {
    title = `${format(first, "d", options)} \u2014 ${format(last, "d MMM yyyy", options)}`;
  } else if (first.getYear() !== last.getYear()) {
    title = `${format(first, "d MMM yyyy", options)} \u2014 ${format(last, "d MMM yyyy", options)}`;
  } else {
    title = `${format(first, "d MMM", options)} \u2014 ${format(last, "d MMM yyyy", options)}`;
  }
  return title;
}

export function stopTask(stoppedTask, data) {
  stoppedTask.overdue = isBefore(parseDate(stoppedTask.deadline_date), new Date());
  if (stoppedTask.overdue) {
    const stopIdx = data.overdue.findIndex((_task) => _task.id === stoppedTask.id);
    data.overdue[stopIdx].overdue = true;
    data.overdue[stopIdx].playing = false;
    data.overdue[stopIdx].duration = stoppedTask.duration;
    data.overdue[stopIdx].durationStr = formatDuration(stoppedTask.duration);
  } else if (data.daily[stoppedTask.deadline_date]) {
    const stopIdx = data.daily[stoppedTask.deadline_date].findIndex((_task) => _task.id === stoppedTask.id);
    data.daily[stoppedTask.deadline_date][stopIdx].overdue = false;
    data.daily[stoppedTask.deadline_date][stopIdx].playing = false;
    data.daily[stoppedTask.deadline_date][stopIdx].duration = stoppedTask.duration;
    data.daily[stoppedTask.deadline_date][stopIdx].durationStr = formatDuration(stoppedTask.duration);
  }
}

export function stopTaskByCompany(stoppedTask, data) {
  let stopIdx;
  // eslint-disable-next-line consistent-return
  Object.values(data.companies).forEach((company) => {
    stopIdx = company.tasks.findIndex((_task) => _task.id === stoppedTask.id);
    if (stopIdx !== -1) {
      company.tasks[stopIdx].playing = false;
      company.tasks[stopIdx].duration = stoppedTask.duration;
      company.tasks[stopIdx].durationStr = formatDuration(stoppedTask.duration);
      return true;
    }
  });
  return false;
}

export async function startTaskTimer(tasks, task, index) {
  return trackerAPI
    .startTimer(task.id)
    .then((response) => {
      const newData = {
        ...tasks,
        currentId: task.id,
        daily: { ...tasks.daily },
        overdue: [...tasks.overdue],
      };
      const { warning } = response.data;
      if (warning) {
        toast.warning(warning, { autoClose: 10000 });
      }
      const stoppedTask = response.data.stopped_task;
      if (stoppedTask) {
        stopTask(stoppedTask, newData);
      }
      if (task.overdue) {
        newData.overdue[index] = { ...task, playing: true };
      } else {
        newData.daily[task.deadline_date][index] = {
          ...task,
          playing: true,
        };
      }
      document.body.dispatchEvent(new Event("task/changed"));
      return newData;
    })
    .catch((error) => {
      if (error.data.__all__) {
        toast.error(error.data.__all__, { autoClose: 10000 });
      }
    });
}
