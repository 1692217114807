import { useLayoutEffect, useState } from "react";

export const useIsOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = useState(undefined);
  const [scrollBarWidth, setScrollBarWidth] = useState(0);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;
      const scrollBarDimension = current.offsetWidth - current.clientWidth;

      setIsOverflow(hasOverflow);
      setScrollBarWidth(scrollBarDimension);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return { isOverflow, scrollBarWidth };
};
