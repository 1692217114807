import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Card, Table, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { FormGroup } from "components/formik";
import cx from "classnames";
import { handleActionErrors } from "api/errors";

import mapValues from "lodash/mapValues";

import { RocketLoader } from "components/ui/loaders";
import * as officeSupportApi from "api/office-support";
import useInitialAsync from "hooks/useInitialAsync";
import { toast } from "react-toastify";
import { formatDatetime } from "utils/date";
import * as yup from "yup";

import UnsavedWarn from "../../../components/ui/UnsavedWarn";
import { SubmitButton } from "../../../components/ui/buttons";

function OfficeSupportCompanyAssessmentsPage({ forReassessment = false }) {
  const { company, reloadIndicators } = useOutletContext();
  const { t } = useTranslation("company");
  const [successSubmitRefresh, setSuccessSubmitRefresh] = useState(0);
  const { loading, item } = useInitialAsync(
    () => {
      return officeSupportApi.assessment.get(company.id, forReassessment);
    },
    {},
    [forReassessment, successSubmitRefresh]
  );
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [triggeredEditMode, setTriggeredEditMode] = useState(false);

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      setIsFormDirty(dirty);
    }, [dirty]);
    return null;
  }
  React.useEffect(() => {
    setEditMode(!item.form?.last_edited_at);
    setTriggeredEditMode(false);
    setIsFormDirty(false);
  }, [item]);
  if (loading) {
    return <RocketLoader />;
  }
  const formikProps = {
    initialValues: {
      answers: item.form.answers,
      description: item.form?.description || "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object().shape({
      description: yup.string().max(1000),
      answers: yup.lazy((obj) =>
        yup.object(
          mapValues(obj, (value) => {
            return yup.object().shape({
              answer: yup.boolean().nullable().oneOf([true, false], t("msg:fieldRequired")),
            });
          })
        )
      ),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await officeSupportApi.assessment
        .save(
          company.id,
          {
            answers: values.answers,
            description: values.description,
          },
          forReassessment
        )
        .then((response) => {
          if (forReassessment) {
            toast.success(t("msg:reassessmentUpdated"), { autoClose: 2000 });
          } else {
            toast.success(t("msg:assessmentUpdated"), { autoClose: 2000 });
          }
          setSuccessSubmitRefresh(successSubmitRefresh + 1);
          setIsFormDirty(false);
          setEditMode(false);
          setTriggeredEditMode(false);
          reloadIndicators();
        })
        .catch((errors) => {
          handleActionErrors(errors);
          setErrors(errors.data);
        });
    },
  };
  const setYes = (questionId, setFieldValue, values) => {
    setFieldValue(`answers[${questionId}].answer`, true);
  };
  const setNo = (questionId, setFieldValue, values) => {
    setFieldValue(`answers[${questionId}].answer`, false);
  };
  const onCancel = (resetForm) => {
    resetForm();
    if (triggeredEditMode) {
      setEditMode(false);
      setTriggeredEditMode(false);
    }
  };
  const onTriggerNewVersion = () => {
    setEditMode(true);
    setTriggeredEditMode(true);
  };
  return (
    <Card>
      <Card.Body>
        <Formik {...formikProps}>
          {({ values, isSubmitting, errors, setFieldValue, dirty, resetForm }) => {
            return (
              <Form id="assessment">
                <Card>
                  <FormUpdateChecker />
                  <UnsavedWarn isUnsaved={isFormDirty || triggeredEditMode} />
                  <Card.Body>
                    <p>
                      <b>
                        {t("common:client")}: {item.company_name}
                      </b>
                      <br />
                      <b>{t("other.lastEditedInfo")}: </b>
                      {item.form.last_edited_at ? (
                        <>
                          <b>{item.last_edited_by}</b> {formatDatetime(item.form.last_edited_at)}
                        </>
                      ) : (
                        <b>-</b>
                      )}
                    </p>
                    <Table bordered id="assessment-table">
                      <thead>
                        <tr>
                          <th>Fråga</th>
                          <th colSpan={2}>Svar</th>
                          <th>Kommentar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(item.tree).map(([catId, catData], index) => (
                          <>
                            <tr
                              className={cx({
                                break: catId === "500",
                              })}
                            >
                              <td colSpan={4}>
                                <b>{catData.category_name}</b>
                              </td>
                            </tr>
                            {Object.entries(catData.questions).map(([questionId, questionData]) => (
                              <tr
                                className={cx({
                                  has_errors: errors?.answers && errors?.answers[questionId],
                                })}
                              >
                                <td>
                                  {questionData.label}
                                  {errors?.answers && errors?.answers[questionId] && (
                                    <p>*{errors?.answers[questionId].answer || errors?.answers[questionId]}</p>
                                  )}
                                </td>
                                <td>
                                  <FormGroup.InlineRadio
                                    name={`${questionId}_yes`}
                                    value={values.answers[questionId]?.answer}
                                    label="Ja"
                                    disabled={!editMode}
                                    checked={values.answers[questionId]?.answer}
                                    onChange={(e) => setYes(questionId, setFieldValue, values)}
                                  />
                                </td>
                                <td>
                                  <FormGroup.InlineRadio
                                    name={`${questionId}_no`}
                                    value={values.answers[questionId]?.answer === false}
                                    label="Nej"
                                    disabled={!editMode}
                                    checked={values.answers[questionId]?.answer === false}
                                    onChange={(e) => setNo(questionId, setFieldValue, values)}
                                  />
                                </td>
                                <td>
                                  <FormGroup.AutoHeightEditableArea
                                    name={`answers[${questionId}].comment`}
                                    disabled={!editMode}
                                  />
                                </td>
                              </tr>
                            ))}
                          </>
                        ))}
                      </tbody>
                    </Table>
                    <>
                      <p>
                        <b>Beskrivning</b>
                      </p>
                      <div className="description-container">
                        <FormGroup.AutoHeightEditableArea name="description" disabled={!editMode} />
                      </div>
                    </>
                    <Row>
                      <Col className="text-right">
                        {(dirty || triggeredEditMode) && (
                          <Button variant="link" size="lg" onClick={() => onCancel(resetForm)} className="cancel-btn">
                            {t("common:actions.cancel")}
                          </Button>
                        )}
                        {item.form.last_edited_at && !editMode ? (
                          <Button variant="first" onClick={() => onTriggerNewVersion()}>
                            <i className={`fas "fa-save mr-1`} />
                            {t("common:actions.createNewVersion")}
                          </Button>
                        ) : (
                          <SubmitButton
                            title={editMode ? "actions.saveToDocuments" : "actions.createNewVersion"}
                            isSubmitting={isSubmitting}
                            disabled={!dirty}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
}

export default OfficeSupportCompanyAssessmentsPage;
