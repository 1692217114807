import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipActionLink } from "./TooltipButton";

function PreviewButton({ link, text }) {
  const { t } = useTranslation("common");
  return <TooltipActionLink text={text || t("actions.preview")} link={link} icon="fas fa-search" />;
}

export default PreviewButton;
