import React, { useState, useContext, useCallback } from "react";
import { Card } from "react-bootstrap";
import { SearchFilter } from "components/filters";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAsync from "hooks/useAsync";

import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import {
  OnboardingDispatchContext,
  OnboardingStateContext,
} from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import CompanyConsultsPermsForm from "components/forms/CompanyConsultPermsForm";
import * as companyAPI from "api/company";
import { handleActionErrors } from "api/errors";
import ConsultSettingsSaveButtons from "./ConsultSettingsSaveButtons";

function ConsultSettingsStep() {
  const [filterParams, setFilterParams] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const { companyId } = useContext(OnboardingStateContext);
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const { t } = useTranslation("msg");

  const dataSource = useCallback(
    (cancelToken) => companyAPI.onboarding.consults.get(companyId, { term: filterParams.term }, { cancelToken }),
    [filterParams.term, companyId]
  );

  const [{ data, loading }, { updateData: setData }] = useAsync(dataSource, []);

  const savePerms = useCallback(
    async (finish) => {
      setSubmitting(true);

      try {
        await companyAPI.onboarding.consults.save(companyId, data, { finish });
        await fetchDetails(true);
        toast.success(t("msg:updated"));
      } catch (error) {
        handleActionErrors(error);
      } finally {
        setSubmitting(false);
      }
    },
    [companyId, data, fetchDetails, t]
  );

  return (
    <Card className="consult-settings-step">
      <Card.Body>
        <StepHeader rightComponent={<ConsultSettingsSaveButtons isSubmitting={isSubmitting} savePerms={savePerms} />} />
      </Card.Body>
      <Card.Body>
        <SearchFilter onFilter={setFilterParams} defaultValue={filterParams.term} />
        <br />
        <CompanyConsultsPermsForm data={data} setData={setData} loading={loading} />
      </Card.Body>
    </Card>
  );
}

export default ConsultSettingsStep;
