import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import * as documentAPI from "api/document";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SubmitButton } from "components/ui/buttons";
import { DraggableModalDialog } from "../DraggableModalDialog";

function FileUploadModal({ handleClose, uploadHandler, onSuccess, max = 1 }) {
  const { t } = useTranslation("common");
  const [isSubmitting, setSubmitting] = useState(false);
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);

  const handleSubmit = async () => {
    const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
    if (!_newFiles.length) {
      toast.error(t("form.file.minOneFileRequired"));
      return;
    }
    setSubmitting(true);
    await uploadHandler(_newFiles)
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        toast.success(t("msg:uploaded"), { autoClose: 2000 });
        handleClose();
      })
      .catch((error) => {
        if (error.data && error.data.file) {
          toast.error(error.data.file, { autoClose: 4000 });
        } else {
          toast.error(t("msg:fileError"));
        }
        setSubmitting(false);
      });
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("common:actions.upload")} {t("documents")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewDocuments
          documents={newFiles}
          onChange={({ file, index }) =>
            documentAPI.onNewFileChange({
              file,
              index,
              allFiles: newFiles,
              setFile: setNewFiles,
              max,
            })
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton isSubmitting={isSubmitting} onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
}

export default FileUploadModal;
