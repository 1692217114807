import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import Filters from "./SilverbackFilters";
import Actions from "./SilverbackActions";

function SilverbackTable({ companyId, selectable = false }) {
  const { t } = useTranslation(["common", "others"]);

  const headers = useMemo(
    () => [
      {
        field: "meeting_date",
        label: t("others:meetingDate"),
        canSort: true,
      },
      {
        field: "created_by_name",
        label: t("common:createdBy"),
        canSort: false,
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
    />
  );
}

export default SilverbackTable;
