import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StepProgress from "pages/companies/SkatteverketConnectModal/StepProgress";
import { useCompanyDispatch } from "hooks/useCompany";
import * as companyAPI from "api/company";
import { toast } from "react-toastify";
import { DraggableModalDialog } from "../DraggableModalDialog";

function Step1({ companyId, setStep }) {
  const { t } = useTranslation("company");
  const [loading, setLoading] = useState(false);

  const startKyc = () => {
    setLoading(true);
    companyAPI.kyc
      .initiate(companyId)
      .then((response) => {
        setStep(2);
        window.open(response.data.kyc_url, "_blank");
      })
      .catch((error) => {
        toast.error(t("kyc.serviceUnavailable"));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      <p className="step-info mb-4">{t("kyc.step1Info")}</p>
      <Button onClick={startKyc} disabled={loading}>
        {t("kyc.startBtn")} <i className="fe-chevron-right" />
      </Button>
    </div>
  );
}

function Step2({ companyId, setStep }) {
  const { t } = useTranslation("company");
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(undefined);
  const { updateCompanyData } = useCompanyDispatch();

  const checkKYC = () => {
    setLoading(true);
    companyAPI.kyc
      .check(companyId)
      .then((response) => {
        const isValid = response.data.is_valid;
        setValid(isValid);
        if (isValid) {
          updateCompanyData({ kyc_enabled: true });
        }
      })
      .catch((error) => {
        setValid(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div>
      {valid === true ? (
        <p className="step-info mb-4">
          <i className="fe-check text-success font-24" />
          <br /> {t("kyc.statusValid")}
        </p>
      ) : (
        <>
          {valid === false && <p className="step-info mb-4 text-warning">{t("kyc.statusInvalid")}</p>}
          {valid === undefined && <p className="step-info mb-4">{t("kyc.statusInfo")}</p>}
          <Button variant="link" onClick={() => setStep(1)} disabled={loading}>
            <i className="fe-chevron-left" /> {t("common:actions.goBack")}
          </Button>
          <Button onClick={checkKYC} disabled={loading}>
            {valid === false ? t("common:actions.tryAgain") : t("common:actions.testConnection")}{" "}
            <i className="fe-chevron-right" />
          </Button>
        </>
      )}
    </div>
  );
}

function ConnectKYCModal({ companyId, handleClose }) {
  const { t } = useTranslation("company");
  const [step, setStep] = useState(1);
  return (
    <Modal
      show
      onHide={handleClose}
      size="lg"
      className="skatteverket-connect-modal"
      scrollable
      backdrop="static"
      keyboard={false}
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>{step === 1 && <p className="agency-info">{t("kyc.connectInfo")}</p>}</Modal.Header>
      <div className="modal-wrapper">
        <Modal.Body className="step-details">
          <p className="step-counter">
            <strong>
              {t("common:step")} {step}{" "}
            </strong>
            {t("common:of")} 2
          </p>
          {step === 1 && <Step1 companyId={companyId} setStep={setStep} />}
          {step === 2 && <Step2 companyId={companyId} setStep={setStep} />}
          <StepProgress active={step} numberOfSteps={2} />
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default ConnectKYCModal;
