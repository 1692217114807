import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const mapping = {
  4415: { percent: 25, account1: 2617, account2: 2647 },
  4416: { percent: 12, account1: 2627, account2: 2647 },
  4417: { percent: 6, account1: 2637, account2: 2647 },
  4425: { percent: 25, account1: 2617, account2: 2647 },
  4426: { percent: 12, account1: 2627, account2: 2647 },
  4427: { percent: 6, account1: 2637, account2: 2647 },
  4515: { percent: 25, account1: 2614, account2: 2645 },
  4516: { percent: 12, account1: 2624, account2: 2645 },
  4517: { percent: 6, account1: 2634, account2: 2645 },
  4531: { percent: 25, account1: 2614, account2: 2645 },
  4535: { percent: 25, account1: 2614, account2: 2645 },
  4545: { percent: 25, account1: 2615, account2: 2645 },
  4546: { percent: 12, account1: 2625, account2: 2645 },
  4547: { percent: 6, account1: 2635, account2: 2645 },
  5421: { percent: 25, account1: 2614, account2: 2645 },
  7400: { percent: 24.26, account1: 2514, account2: 7530 },
};

function AutoBookingSIInfo({ costAccountNumber }) {
  const { t } = useTranslation("si");
  const msgId = costAccountNumber === 7400 ? "autoBookingInfoSpecial" : "autoBookingInfo";
  const params = mapping[costAccountNumber];
  if (!params) {
    return null;
  }
  return <Alert variant="warning">{t(msgId, params)}</Alert>;
}

export default AutoBookingSIInfo;
