import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as verAPI from "api2/verification";
import { handleActionErrors } from "api/errors";

import {
  TooltipActionButton,
  VerificationBookingButton,
  VerificationCommentsButton,
  VerificationDocumentsButton,
  VerificationHistoryButton,
} from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import { useVerificationDispatch } from "hooks/useVerification";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { useCompanyState } from "hooks/useCompany";

function VerificationActions({ row, companyId }) {
  const { t } = useTranslation("ver");
  const {
    accounts: { byId: accountById },
    costCenters: { byId: centerById },
    projects: { byId: projectById },
  } = useCompanyState();
  const { duplicate } = useVerificationDispatch();
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const {
    dataActions: { reload },
  } = useTable();

  const show = {
    bookingBtn: row.status === 1,
    deleteBtn: row.status === 1 && row.object_type !== "ci",
  };

  const onDelete = async () => {
    const answer = await confirmRemove(
      t(row.assets.length ? "confirm.removeWithAssetInfo" : "confirm.remove", { invoiceNo: row.verification_number })
    );
    if (answer) {
      verAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reloadMenuNumbers();
          reload();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
  };

  const onDuplicate = async () => {
    const ver = await verAPI.details(companyId, row.id);
    await duplicate(companyId, {
      ...ver,
      booking_date: ver.booking_date,
      transactions: ver.transactions.map((item) => ({
        ...item,
        id: undefined,
        account: {
          id: item.account,
          number: item.account,
          value: item.account,
          label: `${item.account} - ${accountById[item.account].name}`,
        },
        project: item.project ? projectById[item.project] : null,
        cost_center: item.cost_center ? centerById[item.cost_center] : null,
        debit: item.amount >= 0 ? item.amount : null,
        credit: item.amount < 0 ? Math.abs(item.amount) : null,
        periodisation_config: null,
      })),
    });
  };

  return (
    <>
      <VerificationHistoryButton verificationId={row.id} verLogsOnly />
      <VerificationDocumentsButton verificationId={row.id} companyId={companyId} documentsIds={row.documents} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      <TooltipActionButton text={t("common:actions.duplicate")} onClick={onDuplicate} icon="fas fa-copy" />
      {show.deleteBtn && (
        <TooltipActionButton text={t("common:actions.removeVer")} onClick={onDelete} icon="fas fa-trash" />
      )}
    </>
  );
}

export default VerificationActions;
