import { parseISO } from "date-fns";
import _ from "lodash";
import client from "./client";
import * as options from "./options";
import { _getDiscount } from "./customer-invoices";

export const contracts = {
  details(companyId, contractId) {
    const vatOptions = options.vatOptions.asList();
    return client.get(`/companies/${companyId}/contract-invoicing/${contractId}/`).then((response) => ({
      ...response.data,
      customer: {
        ...response.data.customer,
        value: response.data.customer_billecta_id,
        label: response.data.customer?.Name,
      },
      contract_date: response.data.contract_date ? parseISO(response.data.contract_date) : null,
      period_start: response.data.period_start ? parseISO(response.data.period_start) : null,
      period_end: response.data.period_end ? parseISO(response.data.period_end) : null,
      records: response.data.records.map((record) => ({
        ...record,
        key: _.uniqueId("ln"),
        VAT: options.vatOptions.getOption(record.VAT) || vatOptions[0],
        UnitPrice: record.UnitPrice && record.UnitPrice.Value / 100,
        RotRutAmount: record.RotRutAmount && record.RotRutAmount.Value / 100,
        Discount: _getDiscount(record),
      })),
    }));
  },
  save(companyId, data) {
    if (data.id) {
      return client.put(`/companies/${companyId}/contract-invoicing/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/contract-invoicing/`, data);
  },
  remove(companyId, contractId) {
    return client.delete(`/companies/${companyId}/contract-invoicing/${contractId}/`);
  },
  createDrafts(companyId, data) {
    return client.post(`/companies/${companyId}/contract-invoicing/force_generate/`, data);
  },
  draftInvoices(companyId, params) {
    return client.get(`/companies/${companyId}/contract-invoicing/get_invoices_for_company_contracts/`, { params });
  },
  sendDraft(companyId, invoiceId) {
    return client.patch(`/companies/${companyId}/customer-invoices/${invoiceId}/billecta_create_and_attest/`);
  },
};

export const templates = {
  details(companyId, templateId) {
    const vatOptions = options.vatOptions.asList();
    return client.get(`/companies/${companyId}/contract-templates/${templateId}/`).then((response) => ({
      ...response.data,
      customer: {
        value: response.data.customer_billecta_id,
        label: response.data.customer_billecta_name,
        DebtorPublicId: response.data.customer_billecta_id,
        Name: response.data.customer_billecta_name,
        Email: response.data.customer_billecta_email || "",
      },
      contract_date: response.data.contract_date ? parseISO(response.data.contract_date) : null,
      period_start: response.data.period_start ? parseISO(response.data.period_start) : null,
      period_end: response.data.period_end ? parseISO(response.data.period_end) : null,
      records: response.data.records.map((record) => ({
        ...record,
        key: _.uniqueId("ln"),
        VAT: options.vatOptions.getOption(record.VAT) || vatOptions[0],
        UnitPrice: record.UnitPrice && record.UnitPrice.Value / 100,
        RotRutAmount: record.RotRutAmount && record.RotRutAmount.Value / 100,
        Discount: _getDiscount(record),
      })),
    }));
  },
  save(companyId, data) {
    if (data.id) {
      return client.put(`/companies/${companyId}/contract-templates/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/contract-templates/`, data);
  },
  remove(companyId, templateId) {
    return client.delete(`/companies/${companyId}/contract-templates/${templateId}/`);
  },
};
