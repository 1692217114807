import React, { useCallback } from "react";
import * as trackerAPI from "api/time-tracker";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAuthState } from "hooks/useAuth";
import { useCompanyState } from "hooks/useCompany";
import TTTask from "./TTTask";

function WorkTimeTask({ officeId }) {
  const [isPlaying, setPlaying] = React.useState(null);
  const [companyName, setCompanyName] = React.useState("");
  const [duration, setDuration] = React.useState(0);
  const { t } = useTranslation("tt");
  const fetchActiveTask = useCallback(() => {
    trackerAPI.workTime
      .isPlaying(officeId)
      .then((response) => {
        if (response.status === 200) {
          setPlaying(response.data.playing);
          setCompanyName(response.data.company);
          setDuration(response.data.duration);
        }
      })
      .catch(() => {});
  }, [officeId]);

  React.useEffect(() => {
    fetchActiveTask();

    const interval = setInterval(() => {
      fetchActiveTask();
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, [fetchActiveTask]);

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("task/changed", fetchActiveTask);
    return () => document.body.removeEventListener("task/changed", fetchActiveTask);
  }, [fetchActiveTask]);

  const play = () => {
    trackerAPI.workTime
      .play(officeId)
      .then((response) => {
        if (response.data.warning) {
          toast.warning(response.data.warning, { autoClose: 10000 });
        }
        setPlaying(true);
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 10000 });
        }
      });
  };

  const stop = () => {
    trackerAPI.workTime.stop(officeId).then(() => {
      document.body.dispatchEvent(new Event("task/changed"));
      document.body.dispatchEvent(new Event("work/stopped"));
      setPlaying(false);
    });
  };
  const title = isPlaying ? t("tt:working") : t("tt:startWorking");
  if (!companyName) {
    return null;
  }
  return (
    <TTTask title={title} company={companyName} duration={duration} playing={isPlaying}>
      {isPlaying ? (
        <i className="fas fa-stop-circle" onClick={stop} />
      ) : (
        <i className="fas fa-play-circle" onClick={play} />
      )}
    </TTTask>
  );
}

function WorkTimeTaskWrapper({ officeId }) {
  const { user } = useAuthState();
  const { perms } = useCompanyState();
  const isConsult = user.is_superuser || perms.isOfficeConsult || user.is_consult;
  return isConsult ? <WorkTimeTask officeId={officeId} /> : null;
}

export default WorkTimeTaskWrapper;
