import React, { useContext } from "react";
import { Modal } from "react-bootstrap";

import "./SigningBasketModal.scss";

import { useCompanyState } from "hooks/useCompany";
import BasketProvider, { BasketContext } from "./BasketProvider";
import InitialStage from "./stages/InitialStage";
import DecoupledStage from "./stages/DecoupledStage";
import SummaryStage from "./stages/SummaryStage";
import SinglePayments from "./stages/SinglePayments/SinglePayments";

function ModalContentSwitcher({ initialSelection, handleClose }) {
  const { stage, singlePayments } = useContext(BasketContext);
  let content;
  if (stage === "decoupled") {
    content = <DecoupledStage />;
  } else if (stage === "summary") {
    content = <SummaryStage />;
  } else if (stage === "single" && singlePayments.length !== 0) {
    content = <SinglePayments initialPayments={singlePayments} />;
  } else {
    content = <InitialStage initialSelection={initialSelection} />;
  }

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleClose}
      backdrop="static"
      dialogClassName="signing-basket-modal"
    >
      <button className="close-btn" type="button" onClick={handleClose}>
        <i className="fa fa-times" />
      </button>
      {content}
    </Modal>
  );
}

function ModalWrapper({ verifications, basketId, handleClose }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  return (
    <BasketProvider companyId={companyId} initialBasketId={basketId}>
      <ModalContentSwitcher initialSelection={verifications} handleClose={handleClose} />
    </BasketProvider>
  );
}

export default ModalWrapper;
