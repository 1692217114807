import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { CheckSVG } from "components/ui/svg";
import { useAuthDispatch } from "hooks/useAuth";
import { Trans, useTranslation } from "react-i18next";

function LogoutPage() {
  const { logout } = useAuthDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div className="text-center">
      <div className="mb-3">
        <CheckSVG />
      </div>
      <h3 className="text-white">{t("others:auth.seeAgain")}</h3>
      <p className="text-muted mt-2">
        <Trans i18nKey="others:auth.logoutMsg">
          You are now successfully sign out. Back to
          <Link className="text-white m1-1" to="/auth/login">
            Sign In
          </Link>
        </Trans>
      </p>
    </div>
  );
}

export default LogoutPage;
