import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { SelectionContext } from "state/providers/SelectionProvider";
import cx from "classnames";
import _ from "lodash";

function Headers({
  initialHeaders,
  onSort,
  actionColumn = true,
  expandColumn = false,
  selectColumn = false,
  tableId,
  onSelectAll,
}) {
  const [headers, setHeaders] = useState(
    initialHeaders.map((header) => ({
      canSort: true,
      isSorted: false,
      isSortedDesc: false,
      className: "",
      ...header,
    }))
  );

  useEffect(() => {
    // reset/translate labels on lang change
    setHeaders((headers2) =>
      headers2.map((header, i) => ({
        ...header,
        label: initialHeaders[i].label,
      }))
    );
  }, [setHeaders, initialHeaders]);

  const { toggleAllValues } = useContext(SelectionContext);

  function onSortPress(field, index) {
    let isSorted = false;
    let isSortedDesc = false;
    setHeaders(
      headers.map((header, hi) => {
        if (index === hi) {
          isSorted = !header.isSorted || header.isSortedDesc;
          isSortedDesc = isSorted && !header.isSortedDesc;
          // isSorted = !header.isSorted || !header.isSortedDesc;
          // isSortedDesc = !!header.isSorted && header.isSortedDesc === false;
          return {
            ...header,
            isSorted,
            isSortedDesc,
          };
        }
        return { ...header, isSorted: false, isSortedDesc: undefined };
      })
    );
    onSort(isSorted, field, isSortedDesc);
  }

  const SortIcon = useCallback(({ header }) => {
    if (header.isSorted) {
      if (header.isSortedDesc) {
        return <i className="fas fa-sort-down" />;
      }
      return <i className="fas fa-sort-up" />;
    }
    return <i className="fas fa-sort" />;
  }, []);

  return (
    <tr>
      {expandColumn && <th className="expand" />}
      {selectColumn && (
        <th className="selectable" onClick={onSelectAll}>
          <div className="checkbox custom-control checkbox-primary">
            <input type="checkbox" className="d-none" checked={toggleAllValues[tableId] || false} onChange={() => {}} />
            <label className="custom-control-label" />
          </div>
        </th>
      )}
      {headers.map((header, i) => (
        <th
          key={`th.${header.field}`}
          className={cx(_.isFunction(header.className) ? header.className() : header.className)}
          style={{ width: header.width }}
        >
          {header.canSort ? (
            <div className="sortable" onClick={() => onSortPress(header.sortField || header.field, i)}>
              {header.label}
              <SortIcon header={header} />
            </div>
          ) : (
            header.label
          )}
        </th>
      ))}
      {actionColumn && <th />}
    </tr>
  );
}

Headers.propTypes = {
  initialHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      render: PropTypes.func,
      canSort: PropTypes.bool,
    })
  ).isRequired,
  onSort: PropTypes.func.isRequired,
  actionColumn: PropTypes.bool,
};

export default Headers;
