import React from "react";
import companyReducer, { initialState } from "state/reducers/companyReducer";

const CompanyStateContext = React.createContext();
const CompanyDispatchContext = React.createContext();

function CompanyProvider({ children }) {
  const [state, dispatch] = React.useReducer(companyReducer, initialState, undefined);

  return (
    <CompanyStateContext.Provider value={state}>
      <CompanyDispatchContext.Provider value={dispatch}>{children}</CompanyDispatchContext.Provider>
    </CompanyStateContext.Provider>
  );
}

export { CompanyStateContext, CompanyDispatchContext };

export default CompanyProvider;
