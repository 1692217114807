import i18next from "i18next";

function T(text) {
  return i18next.t(`options:country.${text}`);
}

export const getCountries = () => [
  {
    label: T("sweden"),
    options: [{ value: "SE", label: T("sweden") }],
  },
  {
    label: T("withinEU"),
    options: [
      { value: "BE", label: T("belgium"), isEU: true },
      { value: "BG", label: T("bulgaria"), isEU: true },
      { value: "CY", label: T("cyprus"), isEU: true },
      { value: "DK", label: T("denmark"), isEU: true },
      { value: "EE", label: T("estonia"), isEU: true },
      { value: "FI", label: T("finland"), isEU: true },
      { value: "FR", label: T("france"), isEU: true },
      { value: "GR", label: T("greece"), isEU: true },
      { value: "IE", label: T("ireland"), isEU: true },
      { value: "IT", label: T("italy"), isEU: true },
      { value: "HR", label: T("croatia"), isEU: true },
      { value: "LV", label: T("latvia"), isEU: true },
      { value: "LT", label: T("lithuania"), isEU: true },
      { value: "LU", label: T("luxembourg"), isEU: true },
      { value: "MT", label: T("malta"), isEU: true },
      { value: "NL", label: T("netherlands"), isEU: true },
      { value: "PL", label: T("poland"), isEU: true },
      { value: "PT", label: T("portugal"), isEU: true },
      { value: "RO", label: T("romania"), isEU: true },
      { value: "SK", label: T("slovakia"), isEU: true },
      { value: "SI", label: T("slovenia"), isEU: true },
      { value: "ES", label: T("spain"), isEU: true },
      { value: "CZ", label: T("czech"), isEU: true },
      { value: "DE", label: T("germany"), isEU: true },
      { value: "HU", label: T("hungary"), isEU: true },
      { value: "AT", label: T("austria"), isEU: true },
    ],
  },
  {
    label: T("outsideEU"),
    options: [
      { value: "AF", label: T("afghanistan") },
      { value: "AL", label: T("albania") },
      { value: "DZ", label: T("algeria") },
      { value: "VI", label: T("virginislands") },
      { value: "AS", label: "Samoa" },
      { value: "AD", label: "Andorra" },
      { value: "AO", label: "Angola" },
      { value: "AI", label: "Anguilla" },
      { value: "AQ", label: T("antarctica") },
      { value: "AG", label: "Barbuda" },
      { value: "AR", label: "Argentina" },
      { value: "AM", label: T("armenia") },
      { value: "AW", label: "aruba" },
      { value: "AU", label: T("australia") },
      { value: "AZ", label: T("azerbaijan") },
      { value: "BS", label: "Bahamas" },
      { value: "BH", label: "Bahrain" },
      { value: "BD", label: "Bangladesh" },
      { value: "BB", label: "Barbados" },
      { value: "BZ", label: "Belize" },
      { value: "BJ", label: "Benin" },
      { value: "BM", label: "Bermuda" },
      { value: "BT", label: "Bhutan" },
      { value: "BO", label: "Bolivia" },
      { value: "BA", label: T("bosnia") },
      { value: "BW", label: "Botswana" },
      { value: "BV", label: T("bouvet") },
      { value: "BR", label: T("brazil") },
      { value: "VG", label: T("britishvirgin") },
      { value: "IO", label: T("britishindian") },
      { value: "BN", label: "Brunei" },
      { value: "BF", label: "Burkina Faso" },
      { value: "MM", label: "Burma" },
      { value: "BI", label: "Burundi" },
      { value: "KY", label: T("cayman") },
      { value: "CF", label: T("africa") },
      { value: "CL", label: "Chile" },
      { value: "CO", label: "Colombia" },
      { value: "CK", label: T("cook") },
      { value: "CR", label: "Costa Rica" },
      { value: "CD", label: T("kongo") },
      { value: "DJ", label: "Djibouti" },
      { value: "DM", label: "Dominica" },
      { value: "DO", label: T("dominican") },
      { value: "EC", label: "Ecuador" },
      { value: "EG", label: T("egypt") },
      { value: "GB", label: T("uk") },
      { value: "GQ", label: T("guinea") },
      { value: "SV", label: "El Salvador" },
      { value: "CI", label: T("ivorycoast") },
      { value: "ER", label: "Eritrea" },
      { value: "ET", label: T("ethiopia") },
      { value: "FK", label: T("falkland") },
      { value: "FJ", label: "Fiji" },
      { value: "PH", label: T("philippines") },
      {
        value: "FX",
        label: T("francemetro"),
      },
      { value: "GF", label: T("frenchguiana") },
      { value: "PF", label: T("frenchpoly") },
      { value: "TF", label: T("frenchsouth") },
      { value: "FO", label: T("faroe") },
      { value: "AE", label: T("arabemi") },
      { value: "GA", label: "Gabon" },
      { value: "GM", label: "Gambia" },
      { value: "GE", label: T("georgia") },
      { value: "GH", label: "Ghana" },
      { value: "GI", label: "Gibraltar" },
      { value: "GD", label: "Grenada" },
      { value: "GL", label: T("greenland") },
      { value: "GP", label: "Guadeloupe" },
      { value: "GU", label: "Guam" },
      { value: "GT", label: "Guatemala" },
      { value: "GG", label: "Guernsey" },
      { value: "GN", label: "Guinea" },
      { value: "GW", label: "Guinea Bissau" },
      { value: "GY", label: "Guyana" },
      { value: "HT", label: "Haiti" },
      { value: "HM", label: T("mcdonald") },
      { value: "HN", label: "Honduras" },
      { value: "HK", label: "Hong Kong" },
      { value: "IN", label: T("india") },
      { value: "ID", label: T("indonesia") },
      { value: "IQ", label: T("iraq") },
      { value: "IR", label: "Iran" },
      { value: "IS", label: T("iceland") },
      { value: "IM", label: "Isle of Man" },
      { value: "IL", label: "Israel" },
      { value: "JM", label: "Jamaica" },
      { value: "JP", label: T("japanese") },
      { value: "YE", label: T("yemen") },
      { value: "JE", label: "Jersey" },
      { value: "JO", label: T("jordan") },
      { value: "CX", label: T("christmas") },
      { value: "KH", label: T("cambodia") },
      { value: "CM", label: "Cameroon" },
      { value: "CA", label: T("canada") },
      { value: "CV", label: T("capeverde") },
      { value: "KZ", label: T("kazakhstan") },
      { value: "KE", label: "Kenya" },
      { value: "CN", label: T("china") },
      { value: "KG", label: T("kyrgyzstan") },
      { value: "KI", label: "Kiribati" },
      { value: "CC", label: T("cocos") },
      { value: "KM", label: T("comoros") },
      { value: "CG", label: "Kongo-Brazzaville" },
      { value: "CU", label: T("cuba") },
      { value: "KW", label: "Kuwait" },
      { value: "LA", label: "Laos" },
      { value: "LS", label: "Lesotho" },
      { value: "LB", label: "Lebanon" },
      { value: "LR", label: "Liberia" },
      { value: "LY", label: T("libya") },
      { value: "LI", label: "Liechtenstein" },
      { value: "MO", label: "Macau" },
      { value: "MG", label: T("madagascar") },
      { value: "MK", label: T("macedonia") },
      { value: "MW", label: "Malawi" },
      { value: "MY", label: "Malaysia" },
      { value: "MV", label: T("maldives") },
      { value: "ML", label: "Mali" },
      { value: "MA", label: T("morocco") },
      { value: "MH", label: T("marshall") },
      { value: "MQ", label: "Martinique" },
      { value: "MR", label: T("mauritania") },
      { value: "MU", label: "Mauritius" },
      { value: "YT", label: "Mayotte" },
      { value: "MX", label: T("mexico") },
      { value: "FM", label: T("micronesia") },
      { value: "MZ", label: T("mozambique") },
      { value: "MD", label: T("moldova") },
      { value: "MC", label: "Monaco" },
      { value: "MN", label: T("mongolia") },
      { value: "ME", label: "Montenegro" },
      { value: "MS", label: "Montserrat" },
      { value: "NA", label: "Namibia" },
      { value: "NR", label: "Nauru" },
      { value: "AN", label: T("netherlandsantilles") },
      { value: "NP", label: "Nepal" },
      { value: "NI", label: "Nicaragua" },
      { value: "NE", label: "Niger" },
      { value: "NG", label: "Nigeria" },
      { value: "NU", label: "Niue" },
      { value: "KP", label: T("northkorea") },
      { value: "MP", label: T("mariana") },
      { value: "NF", label: T("norfolk") },
      { value: "NO", label: T("norway") },
      { value: "NC", label: T("caledonia") },
      { value: "NZ", label: T("zealand") },
      { value: "OM", label: "Oman" },
      { value: "PK", label: "Pakistan" },
      { value: "PW", label: "Palau" },
      { value: "PA", label: "Panama" },
      { value: "PG", label: T("papua") },
      { value: "PY", label: "Paraguay" },
      { value: "PE", label: "Peru" },
      { value: "PN", label: T("pitcairn") },
      { value: "PR", label: "Puerto Rico" },
      { value: "QA", label: "Qatar" },
      { value: "RE", label: T("reunion") },
      { value: "RW", label: "Rwanda" },
      { value: "RU", label: T("russia") },
      { value: "KN", label: T("saintkitts") },
      { value: "LC", label: "Saint Lucia" },
      { value: "VC", label: T("saintvincent") },
      { value: "BL", label: "Saint-Barthélemy" },
      { value: "PM", label: "Saint-Pierre och Miquelon" },
      { value: "SB", label: T("solomon") },
      { value: "WS", label: "Samoa" },
      { value: "SM", label: "San Marino" },
      { value: "SH", label: T("helena") },
      { value: "ST", label: "São Tomé och Príncipe" },
      { value: "SA", label: T("saudiarabia") },
      { value: "CH", label: T("switzerland") },
      { value: "SN", label: "Senegal" },
      { value: "RS", label: T("serbia") },
      { value: "SC", label: T("seychelles") },
      { value: "SL", label: "Sierra Leone" },
      { value: "SG", label: "Singapore" },
      { value: "SO", label: "Somalia" },
      { value: "LK", label: "Sri Lanka" },
      { value: "SD", label: "Sudan" },
      { value: "SR", label: T("suriname") },
      { value: "SJ", label: "Svalbard och Jan Mayen" },
      { value: "SZ", label: "Swaziland" },
      { value: "ZA", label: T("southafrica") },
      { value: "KR", label: T("southkorea") },
      { value: "SY", label: T("syria") },
      { value: "TJ", label: T("tajikistan") },
      { value: "TW", label: "Taiwan" },
      { value: "TZ", label: "Tanzania" },
      { value: "TD", label: T("chad") },
      { value: "TH", label: "Thailand" },
      { value: "TG", label: "Togo" },
      { value: "TK", label: T("tokelau") },
      { value: "TO", label: "Tonga" },
      { value: "TT", label: "Trinidad och Tobago" },
      { value: "TN", label: T("tunisia") },
      { value: "TR", label: T("turkey") },
      { value: "TM", label: "Turkmenistan" },
      { value: "TC", label: T("turkscaico") },
      { value: "TV", label: "Tuvalu" },
      { value: "UG", label: "Uganda" },
      { value: "UA", label: T("ukraine") },
      { value: "UY", label: "Uruguay" },
      { value: "US", label: "USA" },
      { value: "UM", label: T("outerUSA") },
      { value: "UZ", label: "Uzbekistan" },
      { value: "WF", label: T("wallis") },
      { value: "VU", label: "Vanuatu" },
      { value: "VA", label: T("vatican") },
      { value: "VE", label: "Venezuela" },
      { value: "VN", label: "Vietnam" },
      { value: "BY", label: T("belarus") },
      { value: "EH", label: T("westernsahara") },
      { value: "ZM", label: "Zambia" },
      { value: "ZW", label: "Zimbabwe" },
      { value: "AX", label: "Åland" },
      { value: "TP", label: T("easttimor") },
    ],
  },
];

export function findCountryByCode(countryCode) {
  const countries = getCountries();
  if (!countryCode || countryCode === "SE") {
    return countries[0].options[0];
  }
  return [...countries[1].options, ...countries[2].options].find((country) => country.value === countryCode);
}

export function getCustomerCountryArea(countryCode) {
  const _countries = getCountries();
  if (!countryCode) {
    return "";
  }
  if (countryCode === "SE") {
    return "sweden";
  }
  if (_countries[1].options.find((c) => c.value === countryCode)) {
    return "within_eu";
  }
  return "outside_eu";
}

export function isIBANSupported(countryCode) {
  const isWithinEU = getCustomerCountryArea(countryCode) !== "outside_eu";
  return isWithinEU || ["BR", "EG", "IL", "NO", "CH", "GB"].includes(countryCode);
}

export function isBankCodeRequired(countryCode) {
  return ["AU", "CA", "CN", "DK", "HK", "ID", "IL", "UK", "US", "IN"].includes(countryCode);
}

export function isAddressRequired(countryCode) {
  return ["AU", "CA", "ET", "GH", "ID", "KE", "NG", "QA", "TW", "TZ", "US", "VN"].includes(countryCode);
}

export function isStateRequired(countryCode) {
  return ["AU", "CA", "US"].includes(countryCode);
}

export function isClearingNumberRequired(countryCode) {
  return ["BR", "CA", "ID", "CN", "HK"].includes(countryCode);
}
