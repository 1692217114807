import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./TaxDirectPaymentFilters";
import Actions from "./TaxDirectPaymentActions";
import { HintButton } from "../../ui/buttons";

function TaxDirectPaymentTable({ companyId, taxOCR, forStatus, allowedModes, selectable = false }) {
  const { t } = useTranslation("tax");

  const headers = useMemo(
    () => [
      {
        field: "ocr",
        label: "OCR",
        render: () => taxOCR, // print tax_ocr from company
        canSort: false,
        className: "text-right",
      },
      {
        field: "receiver",
        label: t("common:receiver"),
        render: () => "Skatteverket",
        canSort: false,
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        className: "text-right",
        render: (values) => formatMoney(values.amount),
      },
      {
        field: "payment_date",
        label: t("common:dates.paymentDate"),
        render: (values) => {
          return (
            <>
              <span>{values.payment_date}</span>
              {values.is_created_by_system && values.sub_status === "unpaid" && (
                <span className="float-right pr-1">
                  <HintButton hint={t("tooltipForGeneratedPayment")} />
                </span>
              )}
            </>
          );
        },
        extraTdProps: { "row-title": t("disabledPaymentRowTitle") },
      },
    ],
    [taxOCR, t]
  );

  const getRowProps = (row) => {
    return {
      className: cx(
        row.sub_status || "unpaid",
        {
          "has-message": !!row.comments.length,
        },
        row.disabled && row.sub_status === "unpaid" ? "show-row-tooltip-by-td disabled" : ""
      ),
    };
  };

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.amount")}:</strong>
      {formatMoney(responseHeaders["x-pagination-amount"])}
    </div>
  );

  const renderSelect = (row) => {
    if (row.in_signing) {
      return "SIGN";
    }
    if (row.disabled) {
      return "";
    }
    return undefined;
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      renderSelect={renderSelect}
      FiltersComponent={<Filters companyId={companyId} forStatus={forStatus} allowedModes={allowedModes} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
      getRowProps={getRowProps}
    />
  );
}

export default TaxDirectPaymentTable;
