import { useHotkeys } from "react-hotkeys-hook";

function TrackCalendarHotKeys({ addAndTick }) {
  function startInternalTask(event, taskName) {
    event.preventDefault();
    addAndTick(taskName);
    return false;
  }

  useHotkeys("alt+b", (event) => startInternalTask(event, "Development - Bflow"));
  useHotkeys("alt+m", (event) => startInternalTask(event, "Internal meeting"));
  useHotkeys("alt+f", (event) => startInternalTask(event, "Fika"));
  useHotkeys("alt+s", (event) => startInternalTask(event, "Non deb time - system fault"));
  useHotkeys("alt+k", (event) => startInternalTask(event, "Non deb time - human error"));

  useHotkeys("alt+u", (event) => startInternalTask(event, "Education"));

  return null;
}

export default TrackCalendarHotKeys;
