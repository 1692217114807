import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import ReceiptForm from "components/forms/ReceiptForm";

function ReceiptAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const outlay = {
    booking_date: new Date(),
    receipt_date: new Date(),
    account: undefined,
    amount: undefined,
    amount_currency: "SEK",
    vat_amount: 0,
    paid_by: "P",
    sub_status: "",
    description: "",
  };

  const onCreate = (newOutlay) => {
    navigate(-1);
  };

  return <ReceiptForm company={company} outlay={outlay} onSave={onCreate} />;
}

export default ReceiptAddPage;
