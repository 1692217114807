import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Document } from "components/ui/documents/document-box";
import { DraggableModalDialog } from "../DraggableModalDialog";

function LocalFilePreviewModal({ file, handleClose }) {
  const { t } = useTranslation("common");
  const fileUrl = file && window.URL.createObjectURL(file);
  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("documents")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Document url={fileUrl} type={file && file.type} fullMode />
      </Modal.Body>
    </Modal>
  );
}

export default LocalFilePreviewModal;
