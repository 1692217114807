import client from "./client";

export function registerOffice(officeData) {
  return client.post("/offices/", officeData);
}

export function getOffice(officeId, config) {
  return client.get(`/offices/${officeId}/`, config);
}

export function updateOffice(officeData) {
  return client.put(`/offices/${officeData.id}/`, officeData);
}

export const scheduleTemplate = {
  save: (officeId, templateData) => {
    if (templateData.id) {
      return client.put(`/offices/${officeId}/time-template/${templateData.id}/`, templateData);
    }
    return client.post(`/offices/${officeId}/time-template/`, templateData);
  },
  remove: (officeId, templateId) => {
    return client.delete(`/offices/${officeId}/time-template/${templateId}/`);
  },
  removeException: (officeId, templateId, exceptionId) => {
    return client.delete(`/offices/${officeId}/time-template/${templateId}/exceptions/${exceptionId}/`);
  },
};

export const subOffices = {
  save: (officeId, subOfficeData) => {
    if (subOfficeData.id) {
      return client.put(`/offices/${officeId}/sub-offices/${subOfficeData.id}/`, subOfficeData);
    }
    return client.post(`/offices/${officeId}/sub-offices/`, subOfficeData);
  },
  remove: (officeId, subOfficeId) => {
    return client.delete(`/offices/${officeId}/sub-offices/${subOfficeId}/`);
  },
};

export function getUser(officeId, userId, config) {
  return client.get(`/offices/${officeId}/users/${userId}/`, config);
}

export function getShortCompaniesConsultPerms(officeId, userId) {
  return client.get(`/offices/${officeId}/consults/${userId}/companies-accesses/`);
}

export function saveShortCompaniesConsultPerms(officeId, userId, perms) {
  return client.post(
    `/offices/${officeId}/consults/${userId}/companies-accesses/`,
    perms.map((perm) => ({
      ...perm,
      // has_access: perm.has_access ? true : perm.has_salary_access, todo
    }))
  );
}

export const consultPerms = {
  get: (officeId, consultId) => {
    return client.get(`/offices/${officeId}/office-permissions/${consultId}/`);
  },
  update: (officeId, consultId, data) => {
    return client.put(`/offices/${officeId}/office-permissions/${consultId}/`, data);
  },
};
