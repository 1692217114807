import React from "react";
import cx from "classnames";

function StepProgress({ active = 1, numberOfSteps = 4 }) {
  return (
    <div className="step-progress">
      {Array(numberOfSteps)
        .fill(0)
        .map((_, index) => (
          <div key={index} className={cx("step", { active: index <= active - 1 })} />
        ))}
    </div>
  );
}

export default StepProgress;
