import { formatDate } from "utils/date";
import { formatDiscount } from "utils/calc/ci";
import { roundMoney } from "../../../utils/money";

export function formatData(data) {
  return {
    ...data,
    customer_billecta_id: data.customer.DebtorPublicId,
    customer_billecta_name: data.customer.Name,
    customer_billecta_email: data.customer.Email,
    contract_date: formatDate(data.contract_date),
    period_start: formatDate(data.period_start),
    period_end: !data.ongoing && data.period_end ? formatDate(data.period_end) : null,
    payment_terms: data.payment_terms.value,
    create_terms: data.create_terms.value,
    records: data.records.map((record, idx) => {
      delete record.key;
      if (record.RecordType === "Standard") {
        return {
          ...record,
          UnitPrice:
            record.RecordType === "Standard"
              ? {
                  Value: record.UnitPrice * 100,
                  CurrencyCode: "SEK",
                }
              : "",
          RotRutAmount: {
            Value: record.RotRutAmount * 100 || 0,
            CurrencyCode: "SEK",
          },
          Quantity: roundMoney(record.Quantity),
          SequenceNo: idx + 1,
          ...formatDiscount(record, "SEK"),
          VAT: record.VAT && !data.reversed_vat ? parseInt(record.VAT.value, 10) : 0,
        };
      }
      return {
        RecordType: record.RecordType,
        ArticleDescription: record.ArticleDescription,
        SequenceNo: idx + 1,
      };
    }),
  };
}
