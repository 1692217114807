import React from "react";

import { VerificationDocumentsButton, VerificationHistoryButton } from "components/ui/buttons";

function GlobalSearchActions({ row }) {
  const show = {
    documentsBtn: !!row.documents,
  };

  return (
    <>
      <VerificationHistoryButton verificationId={row.id} />
      {show.documentsBtn && (
        <VerificationDocumentsButton companyId={row.company} verificationId={row.id} documentsIds={row.documents} />
      )}
    </>
  );
}

export default GlobalSearchActions;
