import { addMonths, format, parseISO } from "date-fns";

export function yM(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return format(date, "yyyy-MM");
}

export function _arrayFromRange(start, end, initial) {
  const output = {};
  while (start < end) {
    output[yM(start)] = {
      name: format(start, "MMM"),
      date: start,
      ...initial,
    };
    start = addMonths(start, 1);
  }
  return output;
}
