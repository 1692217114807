import React, { useCallback } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { SelectionProvider } from "state/providers/SelectionProvider";
import * as officeSupportApi from "api/office-support";
import useAsync from "hooks/useAsync";
import CompanyCardNavTabs from "./CompanyCardNavTabs";

function CompanyCardLayout() {
  const company = useOutletContext();
  const [tabIndicators, setTabIndicators] = React.useState({});
  const getCompanyDetailsIndicators = useCallback(
    (cancelToken) =>
      officeSupportApi.statusIndicator.get_company_details_indicators(company.id, { cancelToken }).then((response) => {
        setTabIndicators(response.data);
      }),
    [company]
  );
  const [{ data, loading }, { reload: reloadIndicators }] = useAsync(getCompanyDetailsIndicators, []);

  return (
    <div className="tab-card">
      <CompanyCardNavTabs tabIndicators={tabIndicators} />
      <SelectionProvider>
        <Outlet context={{ company, tabIndicators, reloadIndicators }} />
      </SelectionProvider>
    </div>
  );
}

export default CompanyCardLayout;
