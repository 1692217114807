import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import ConsultArchiveForm from "components/forms/ConsultArchiveForm/ConsultArchiveForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as archiveAPI from "api/archive";
import { RocketLoader } from "components/ui/loaders";

function ConsultArchiveEditPage() {
  const { company, reloadIndicators } = useOutletContext();
  const navigate = useNavigate();
  const { id: documentId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return archiveAPI.consultDocument.details(company.id, documentId);
  });

  const onUpdate = () => {
    reloadIndicators();
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <ConsultArchiveForm companyId={company.id} archive={item} onSave={onUpdate} />;
}

export default ConsultArchiveEditPage;
