import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import "./ImportFortnoxStep.scss";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";
import { OnboardingDispatchContext } from "../../provider/OnboardingProvider";
import { FortnoxWrapper } from "./FortnoxWrapper";

function ImportFortnoxStep({ data: initialData, companyId }) {
  const { t } = useTranslation("common");
  const [data, setData] = useState(initialData);
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const fetchChecklist = useCallback(() => {
    companyAPI.onboarding.fortnoxImportStep.get(companyId).then((response) => {
      setData(response.data);
      if (response.data.import_status !== "finished" || response.data.import_status !== "error") {
        fetchDetails(false);
      }
    });
  }, [companyId, fetchDetails]);

  const onStart = useCallback(
    (financialYearIds) => {
      companyAPI.onboarding.fortnoxImportStep.start(companyId, financialYearIds).then(() => {
        // fetch steps details
        fetchDetails();
        setData((_data) => ({ ..._data, import_status: "in_progress" }));
      });
    },
    [companyId, fetchDetails]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      fetchChecklist();
    }, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [fetchChecklist]);

  return (
    <Card className="import-fortnox-step">
      <Card.Body>
        <StepHeader
          rightComponent={
            <Button onClick={() => fetchDetails(true)} size="lg">
              {t("actions.goNext")}
            </Button>
          }
        />
      </Card.Body>
      <Card.Body>
        <FortnoxWrapper companyId={companyId} data={data} onStart={onStart} />
      </Card.Body>
    </Card>
  );
}

const EnhancedImportFortnoxStep = withInitialAsync(
  ImportFortnoxStep,
  ({ companyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboarding.fortnoxImportStep.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedImportFortnoxStep;
