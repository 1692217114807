import React, { useCallback } from "react";
import * as consultAPI from "api/consult";
import * as officeAPI from "api/office";
import { Loader } from "components/ui/loaders";
import useTable from "components/tables/btable/useTable";
import { useParams } from "react-router-dom";
import useAsync from "hooks/useAsync";
import UserGlobalForm from "./UserGlobalForm";
import UserForm from "./UserForm";

function UserEditWidget({ id }) {
  const { officeId } = useParams();
  const dataSource = useCallback(
    (cancelToken) => {
      if (officeId) {
        return officeAPI.getUser(officeId, id, { cancelToken });
      }
      return consultAPI.getUser(id, { cancelToken });
    },
    [officeId, id]
  );
  const [{ data: item, loading }] = useAsync(dataSource, []);
  const {
    dataActions: { reload, updateRow },
  } = useTable();
  if (loading) {
    return <Loader />;
  }

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };

  const FormComponent = officeId ? UserForm : UserGlobalForm;

  return (
    <FormComponent
      officeId={officeId}
      formName="edit-form"
      user={{ ...item, name: `${item.first_name} ${item.last_name}` }}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
    />
  );
}

export default UserEditWidget;
