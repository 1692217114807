import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import useTable from "components/tables/btable/useTable";
import { ConsultFilter, NumberRangeFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import * as options from "api/options";
import { FilterButton } from "components/ui/buttons";

function BMD3Filters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  const filtersCount = !!filters.invoiced_hour_min + !!filters.invoiced_hour_max;
  const [more, setMore] = useState(!!filtersCount);
  const bdmPeriods = options.bdmPeriods.asList();
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <ConsultFilter onFilter={setFilters} defaultValue={filters.consult} placeholder={t("common:consult")} />
        <SelectSimpleFilter options={bdmPeriods} name="period" onFilter={setFilters} defaultValue={filters.period} />
        <Button
          variant="outline-secondary"
          onClick={() => setFilters({ favourites: filters.favourites ? null : true })}
          active={filters.favourites}
        >
          {t("favouritesOnly")}
        </Button>
      </section>
      {more && (
        <section className="more-filters">
          <NumberRangeFilter
            label={t("manage.invoicedHour")}
            numberFrom={filters.invoiced_hour_min}
            numberTo={filters.invoiced_hour_max}
            onChange={(values) =>
              setFilters({
                invoiced_hour_min: values.numberFrom,
                invoiced_hour_max: values.numberTo,
              })
            }
          />
        </section>
      )}
    </>
  );
}

export default BMD3Filters;
