import React from "react";

import useTable from "components/tables/btable/useTable";
import { SearchFilter } from "components/filters";

function OfficeFilters() {
  const { filters, setFilters } = useTable();

  return (
    <section className="table-filters-left">
      <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} autoFocus />
    </section>
  );
}

export default OfficeFilters;
