import React from "react";

import useTable from "components/tables/btable/useTable";
import { MonthDateFilter, MultiSelectSimpleFilter } from "components/filters";
import { useTranslation } from "react-i18next";

function ConsultTTReportFilters({ options }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("tt");

  return (
    <section className="table-filters-left">
      <MultiSelectSimpleFilter
        label={t("common:company")}
        defaultValue={filters.companies}
        options={options.companies}
        isClearable
        name="companies"
        onFilter={setFilters}
      />
      <MultiSelectSimpleFilter
        label={t("assignee")}
        defaultValue={filters.users}
        options={options.consults}
        isClearable
        name="users"
        onFilter={setFilters}
      />
      <MonthDateFilter
        label={t("common:dates.month")}
        defaultValue={filters.month_holder}
        isClearable
        name="month_holder"
        onChange={(d) => setFilters({ ...filters, month_holder: d })}
      />
    </section>
  );
}

export default ConsultTTReportFilters;
