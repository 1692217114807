import React, { useContext } from "react";

import NavBar from "components/ui/navbar";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import { RocketLoader } from "components/ui/loaders";
// import { useAuthState } from "hooks/useAuth";
import { Navigate, Outlet } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { codeInList } from "components/perms";
import { codesForCompanySettings } from "components/perms/PermCodes";
import { SetDocTitle } from "components/ui/titles/PageTitle";
import CompanySettingsMenu from "./CompanySettingsMenu";

function CompanySettingsLayout() {
  // const { user } = useAuthState();
  const { t } = useTranslation(["common", "msg"]);
  const { info, loading, userPermCodes } = useContext(CompanySettingStateContext);
  const hasPerm = codeInList(codesForCompanySettings.enabled, userPermCodes);
  if (loading) {
    return <RocketLoader />;
  }

  if (!loading && _.isEmpty(userPermCodes)) {
    return <Navigate to="/companies" />;
  }
  if (hasPerm) {
    return (
      <>
        <SetDocTitle title={info.name} />
        <NavBar company={info} forDashboard={false} />
        <CompanySettingsMenu company={info} userPermCodes={userPermCodes} />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
  }
  toast.error(t("msg:itemNotFoundOrMissingPerm"));
  return <Navigate to="/companies" />;
}

export default CompanySettingsLayout;
