import React, { useState } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { useAuthDispatch } from "hooks/useAuth";

function StandardLoginForm() {
  const { standardLogin } = useAuthDispatch();
  const [showCodeFields, setShowCodeFields] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const { t } = useTranslation(["others", "common"]);

  const formikProps = {
    initialValues: {
      email: "",
      password: "",
      code: "",
      save_device: false,
      dummy_show_code_fields: true,
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
      password: yup.string().required(),
      show_code_fields: yup.boolean(),
      code: yup.string().when("dummy_show_code_fields", {
        is: showCodeFields && !resendCode,
        then: yup.string().required(),
        otherwise: yup.string().nullable(true),
      }),
    }),

    onSubmit: (values) => {
      values.resend_code = resendCode;
      return standardLogin(values)
        .then((response) => {
          setResendCode(false);
          if (response.status === 200) {
            toast.success(t("msg:signedIn"), { autoClose: 2000 });
          } else {
            setShowCodeFields(true);
            toast.info(t("msg:signRequireCode"), { autoClose: 5000 });
          }
        })
        .catch((response) => {
          setResendCode(false);
          if (response.data.__all__) {
            toast.error(response.data.__all__); // eg. wrong 2Factor code
          } else {
            toast.error(t("common:errors.sthWentWrong"));
          }
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting }) => {
        return (
          <Form noValidate>
            <div className="text-center">
              <p className="text-muted mb-4">
                {showCodeFields ? t("auth.standardLoginCodeText") : t("auth.standardLoginEmailPasswordText")}
              </p>
            </div>
            <div className={showCodeFields ? "d-none" : ""}>
              <FormGroup.Input
                name="email"
                type="email"
                label={t("common:contact.emailLong")}
                autoFocus
                disabled={showCodeFields}
              />
              <FormGroup.Input name="password" type="password" label={t("auth.password")} disabled={showCodeFields} />
            </div>
            {showCodeFields && (
              <>
                <FormGroup.Input name="code" type="text" label={t("others:auth.code")} />
                <FormGroup.Checkbox
                  wrapperClass="mb-3"
                  name="save_device"
                  type="checkbox"
                  label={t("others:auth.saveDevice")}
                />
              </>
            )}

            <div className="form-group text-center mb-3">
              <SubmitButton
                size="lg"
                variant="primary"
                className="width-lg btn-rounded"
                title="others:auth.signIn"
                icon="fa-unlock"
                isSubmitting={isSubmitting && !resendCode}
              />
              {showCodeFields ? (
                <SubmitButton
                  size="lg"
                  icon=""
                  className="btn-rounded ml-2"
                  variant="warning"
                  title="others:auth.resendCode"
                  isSubmitting={isSubmitting && resendCode}
                  type="submit"
                  onClick={() => setResendCode(true)}
                >
                  Resend code
                </SubmitButton>
              ) : null}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default StandardLoginForm;
