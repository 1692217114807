import { useState } from "react";

function useModal() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  function open(newData) {
    setShow(true);
    setData(newData || null);
  }

  function close() {
    setShow(false);
    setData(null);
  }

  return { show, data, open, close };
}

export default useModal;
