import { formatDate } from "utils/date";
import client from "./client";

export function dashboardReport(companyId, params, config) {
  return client.get(`/companies/${companyId}/statistics/dashboard/`, {
    params,
    ...config,
  });
}

export function cashflowReport(companyId, params, config) {
  return client.get(`/companies/${companyId}/statistics/cashflow/`, {
    params,
    ...config,
  });
}

export function cashflowSimulation(companyId, simulation) {
  return client.put(`/companies/${companyId}/statistics/cashflow/`, simulation);
}

export function menuNumbers(companyId) {
  return client.get(`/companies/${companyId}/statistics/menu/`);
}

export function cashInfo(companyId, config) {
  return client.get(`/companies/${companyId}/statistics/cash_info/`, config);
}

export function kycConnectionStatus(companyId) {
  return client.get(`/companies/${companyId}/statistics/cash_info/kyc_connection/`);
}

export function bankConnectionStatus(companyId) {
  return client.get(`/companies/${companyId}/statistics/cash_info/bank_connection/`);
}

export function skatteverketConnectionStatus(companyId) {
  return client.get(`/companies/${companyId}/statistics/cash_info/skatteverket_connection/`);
}

export function testSkatteverketConnection(companyId) {
  return client.put(`/companies/${companyId}/statistics/cash_info/test_skatteverket_connection/`);
}

export function companies(forYear, officeId) {
  if (officeId) {
    return client.get(`/offices/${officeId}/statistics/?forYear=${forYear}`, { timeout: 60000 });
  }
  return client.get(`/statistics/companies/?forYear=${forYear}`, { timeout: 60000 });
}

export function verifications() {
  return client.get("/statistics/verifications/");
}

export function customerInvoices() {
  return client.get("/statistics/customer_invoices/");
}

export function supplierInvoices() {
  return client.get("/statistics/supplier_invoices/");
}

export function outlays() {
  return client.get("/statistics/receipts/");
}

export function salaries() {
  return client.get("/statistics/salaries/");
}

export function users() {
  return client.get("/statistics/users/");
}

export function other() {
  return client.get("/statistics/other/");
}

export function manualVerifications(selectedMonth) {
  return client.get("/statistics/manual_verifications/", {
    params: {
      date: formatDate(selectedMonth),
    },
  });
}

export function getCurrencyRate(code, date) {
  return client.get("/currency-rate/", {
    params: {
      code,
      created: formatDate(date),
    },
  });
}
