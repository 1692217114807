import qs from "qs";
import { isBefore } from "date-fns";
import * as reportAPI from "api2/reports";
import { parseDate } from "utils/date";

function getPreviousFinancialYear(financialYear, financialYears) {
  const currentDateStart = parseDate(financialYear.date_start);
  let dateStart;
  let previousFinancialYear = null;
  for (let i = financialYears.length - 1; i > 0; i--) {
    dateStart = parseDate(financialYears[i].date_start);
    if (isBefore(dateStart, currentDateStart)) {
      previousFinancialYear = financialYears[i];
    }
  }
  return previousFinancialYear;
}

async function getPreviousFinancialYearResults(companyId, financialYear, financialYears) {
  const previousResults = {};
  const previousFinancialYear = getPreviousFinancialYear(financialYear, financialYears);
  if (previousFinancialYear) {
    return reportAPI
      .profitAndLoss12(
        companyId,
        {
          date_from: previousFinancialYear.date_start,
          date_to: previousFinancialYear.date_end,
          financial_year: previousFinancialYear.id,
          show_all_accounts: false,
          with_budget: true,
          use_ksek: false,
        },
        {
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      )
      .then((response) => {
        response.data.categories.forEach((mainCategory) => {
          if (mainCategory.child_categories) {
            mainCategory.child_categories.forEach((subCategory) => {
              if (subCategory.accounts) {
                subCategory.accounts.forEach((account) => {
                  previousResults[account.number] = Object.values(account.sums);
                });
              }
            });
          }
        });
        return previousResults;
      })
      .catch(() => {});
  }
  return previousResults;
}

export { getPreviousFinancialYearResults };
