import React from "react";
import { withTranslation } from "react-i18next";
import logoBankID from "assets/images/bankid.svg";

function BankIDLoader({ t, bankIdLogo = false, text = true, children }) {
  return (
    <div className="text-center bankId-loader">
      {bankIdLogo && (
        <a href="https://www.bankid.com/" target="_blank" rel="noopener noreferrer">
          <img src={logoBankID} alt="Bank ID" height={80} />
        </a>
      )}
      {text && <p className="text-muted mb-4">{t("others:auth.bankIdLoaderPrompt")}</p>}
      <div className="blink_me mb-3">
        <i className="fas fa-mobile-alt fa-10x" />
      </div>
      {children}
    </div>
  );
}

export default withTranslation("others")(BankIDLoader);
