import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import SilverbackForm from "components/forms/SilverbackForm";

function SilverbackAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();

  const onCreate = () => {
    navigate(-1);
  };

  return (
    <div>
      <SilverbackForm companyId={company.id} onSave={onCreate} />
    </div>
  );
}

export default SilverbackAddPage;
