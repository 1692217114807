import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import OfficeConsultPermForm from "components/forms/OfficeConsultPermForm";
import Filters from "./OfficeConsultFilters";

function OfficeConsultExtendedTable({ officeId }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
        sortField: "last_name",
      },
      {
        field: "email",
        label: t("contact.emailLong"),
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters officeId={officeId} />}
          renderExpandedRow={(row) => <OfficeConsultPermForm officeId={officeId} userId={row.id} />}
        />
      </Col>
    </Row>
  );
}

export default OfficeConsultExtendedTable;
