import { Modal } from "react-bootstrap";
import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import archiveCategories from "utils/calc/companyArchiveCategories";
import * as archiveAPI from "api/archive";
import { toast } from "react-toastify";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { useModalScroll } from "../hooks/useModalScroll";

function ToCompanyArchiveModal({ fileArchive, companyId, handleClose }) {
  const { t } = useTranslation("common");
  const { shouldScroll } = useModalScroll();

  const formikProps = {
    initialValues: {
      title: fileArchive.title,
      category: undefined,
      financial_year: null,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
      category: yup.object().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      await archiveAPI.archiveDocument
        .toCompanyArchive(companyId, fileArchive.id, values.category.value, values.title, values.financial_year)
        .then(() => {
          toast.success(t("msg:sent"));
          handleClose(true);
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };

  return (
    <Modal show onHide={handleClose} animation={false} dialogAs={DraggableModalDialog} scrollable={shouldScroll}>
      <Formik {...formikProps}>
        {({ values, isSubmitting, errors }) => {
          const fYearRequired = values.category ? ["Yearly", "Basic"].includes(values.category.type) : false;
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title as="h4">{t("common:actions.moveToCompanyArchive")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup.Input label={t("common:name")} name="title" required />
                <FormGroup.SimpleSelect
                  label={t("common:category")}
                  name="category"
                  options={archiveCategories}
                  getOptionLabel={(option) => t(`options:cdc.${option.value}`)}
                  formatGroupLabel={(group) => t(`options:cdc.${group.label}`)}
                  required
                />
                <FormGroup.FinancialYearPicker
                  label={t("common:financialYear")}
                  name="financial_year"
                  required={fYearRequired}
                  isClearable
                />
                <AllError errors={errors} />
              </Modal.Body>
              <Modal.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default ToCompanyArchiveModal;
