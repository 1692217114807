import FullCalendar from "@fullcalendar/react";
import timegridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";

import React, { forwardRef, useContext, useEffect } from "react";
import { parseDate } from "utils/date";
import "./TimeGridCalendar.scss";
import i18n from "i18n";
import { TimeGridDispatchContext, TimeGridStateContext } from "state/providers/TimeGridProvider";
import EditTTLogModal from "components/modals/EditTTLogModal";
import useModal from "hooks/useModal";
import EventComponent from "./EventComponent";
import DayHeader from "./DayHeader";

const calendarConfig = {
  plugins: [timegridPlugin, dayGridPlugin],
  initialView: "timeGridWeek",
  headerToolbar: null,
  slotLabelFormat: {
    hour: "numeric",
    minute: "2-digit",
  },
  nowIndicator: true,
  firstDay: 1,
  slotLabelInterval: "01:00",
  scrollTime: "08:00",
  allDaySlot: false,
  eventMinHeight: false,
  slotDuration: "00:15:00",
  contentHeight: window.innerHeight - 280,
};

const TimeGridCalendar = forwardRef(({ ...props }, ref) => {
  const { logs, lockedDays } = useContext(TimeGridStateContext);
  const { updateLog } = useContext(TimeGridDispatchContext);
  const logModal = useModal();

  const events = logs.map((log) => {
    const locked = lockedDays.includes(log.started.slice(0, 10));
    return {
      id: log.id,
      title: log.task_title,
      start: parseDate(log.started),
      end: parseDate(log.stopped),
      url: locked ? "" : "#",
      display: log.task__category === "fixed" ? "background" : "",
      classNames: locked ? "fc-locked" : "",
      durationEditable: false,
      extendedProps: {
        companyName: log.task__company__name,
        category: log.task__category,
      },
    };
  });

  useEffect(() => {
    // fix: broken layout on initialize sometimes.
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 800);
  }, []);

  const onEventClick = ({ event, jsEvent }) => {
    jsEvent.preventDefault();
    if (!event.url) {
      return;
    }

    logModal.open({
      id: event.id,
      started: event.startStr,
      stopped: event.endStr,
      task_title: event.title,
      task_category: event.extendedProps.category,
    });
  };

  const onLogModalClose = (updatedLog) => {
    if (updatedLog) {
      updateLog(updatedLog);
    }
    logModal.close();
  };

  return (
    <div className="time-report-calendar">
      <FullCalendar
        ref={ref}
        locale={i18n.language}
        themeSystem="bootstrap"
        eventContent={EventComponent}
        events={events}
        {...calendarConfig}
        dayHeaderContent={(dayProps) => <DayHeader {...dayProps} />}
        eventClick={onEventClick}
      />
      {logModal.show && logModal.data && <EditTTLogModal timeLog={logModal.data} handleClose={onLogModalClose} />}
    </div>
  );
});

export default TimeGridCalendar;
