import React, { useState } from "react";
import _ from "lodash";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import * as companyAPI from "api/company";
import { AllError, FormGroup } from "components/formik";
import { useCompanyState } from "hooks/useCompany";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import useModal from "hooks/useModal";
import { formatDate, isBefore, parseDate } from "utils/date";
import {
  filterAssetAccountOptions,
  filterAssetCostAccountOptions,
  filterDepreciationAccountOptions,
  disallowedAssetAccountsForDepreciationList,
} from "utils/assets";
import { HasPermCode } from "components/perms";
import { codesForVerifications } from "components/perms/PermCodes";
import { useVerificationDispatch } from "hooks/useVerification";

import { parseISO } from "date-fns";
import FileUploadModal from "components/modals/FileUploadModal";
import * as documentAPI from "api/document";
import { handleActionErrors, handleFormErrors } from "api/errors";
import { confirmExecute } from "components/modals/ConfirmModal";
import { NewDocuments } from "components/ui/documents/verification-documents";
import {
  depreciationAmountPerMonth,
  depreciationAmountTotal,
  getCountOfAutoVerToCreate,
  onAssetAccountChange,
  onDepreciationMonthsChange,
  onDepreciationStartChange,
  onIsFullyDepreciatedClicked,
  outputBalance,
  yupForAssetForm,
} from "./AssetFormUtils";

function AssetForm({ companyId, asset, successCallback, deleteCallback, onSuccessUpload }) {
  const { t } = useTranslation("asset");
  const uploadModal = useModal();
  const decommissionAndSellModal = useModal();
  const [newFiles, setNewFiles] = useState(asset.newDocuments || [{ key: _.uniqueId("nd.") }]);
  const {
    description,
    config,
    cost_center_for_depreciation,
    project_for_depreciation,
    id,
    status,
    is_initially_depreciated_fully,
    output_balance,
    verification_id,
    depreciation_end,
    cancel_verification_id,
  } = asset;

  const {
    accounts: { byId: accountById, asOptions: accountOptions },
    costCenters: { byId: centerOptionsById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
  } = useCompanyState();

  const deprecationDisallowed = (values) =>
    values?.depreciation_disallowed ?? disallowedAssetAccountsForDepreciationList.includes(config?.asset_account);
  const { open: openVerificationModal } = useVerificationDispatch();
  const canOpenVerModal = HasPermCode(codesForVerifications.enabled);

  const editableStatusesForAsset = ["draft", "material_in_approval"];
  const assetIsEditable = editableStatusesForAsset.includes(status) || !id;

  const possibleDecommissionOrSoldStatusesForAsset = ["active_ongoing", "active_finished"];
  const assetCanBeDecommissionedOrSold = possibleDecommissionOrSoldStatusesForAsset.includes(status);

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      ...asset,
      is_initially_depreciated_fully: is_initially_depreciated_fully || false,
      description: description || "",
      project_for_depreciation: projectById[project_for_depreciation] || null,
      cost_center_for_depreciation: centerOptionsById[cost_center_for_depreciation] || null,
      depreciation_disallowed: deprecationDisallowed(true, false),
      depreciation_months: config.depreciation_months || 0,
      acquisition_value: config.acquisition_value || 0,
      residual_value: config.residual_value || 0,
      cost_account: accountById[asset.config.cost_account] || null,
      asset_account: accountById[asset.config.asset_account] || null,
      depreciation_account: accountById[asset.config.depreciation_account] || null,
      acquisition_date: config?.acquisition_date ? parseDate(asset.config.acquisition_date) : null,
      latest_depreciation_date: config?.latest_depreciation_date ? parseDate(asset.latest_depreciation_date) : null,
      depreciation_start: config?.depreciation_start ? parseDate(asset.config.depreciation_start) : null,
      depreciation_end: depreciation_end ? parseDate(asset.depreciation_end) : null,
      initial_depreciated_months: config?.initial_depreciated_months || 0,
      initial_depreciated_amount: config?.initial_depreciated_amount || 0,
    },
    validationSchema: yupForAssetForm(assetIsEditable),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const countOfAutoVerToCreate = getCountOfAutoVerToCreate(values);
      if (countOfAutoVerToCreate > 0 && !asset.id) {
        const confirmed = await confirmExecute(
          t("warning.versToBeCreatedAfterAssetCreation", { countOfAutoVerToCreate })
        );
        if (!confirmed) {
          return;
        }
      }
      await companyAPI.asset
        .save(companyId, {
          ...values,
          is_initially_depreciated_fully: values.is_initially_depreciated_fully,
          project_for_depreciation: values.project_for_depreciation ? values.project_for_depreciation.value : null,
          cost_center_for_depreciation: values.cost_center_for_depreciation
            ? values.cost_center_for_depreciation.value
            : null,
          config: {
            acquisition_value: values.acquisition_value,
            residual_value: values.residual_value,
            depreciation_months: values.depreciation_months,
            initial_depreciated_months: values.is_initially_depreciated_fully
              ? values.depreciation_months
              : values.initial_depreciated_months,
            initial_depreciated_amount: values.initial_depreciated_amount,
            cost_account: values.cost_account?.value,
            asset_account: values.asset_account.value,
            depreciation_account: values.depreciation_account?.value,
            depreciation_start: formatDate(values.depreciation_start),
            acquisition_date: formatDate(values.acquisition_date),
          },
        })
        .then(async (response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (!asset.id) {
            resetForm();
            const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
            if (_newFiles.length) {
              await documentAPI.uploadAsset(companyId, response.data.id, _newFiles).catch(() => {
                // pass silently
              });
              setNewFiles([{ key: _.uniqueId("nd.") }]);
            }
          }
          if (successCallback) {
            successCallback();
          }
        })
        .catch((error) => {
          handleFormErrors(error, setErrors);
        });
    },
  };
  const handleNewDocumentChange = (file, index) => {
    documentAPI.onNewFileChange({
      file,
      index,
      allFiles: newFiles,
      setFile: setNewFiles,
    });
  };

  const onUpload = async (files) => {
    await documentAPI
      .uploadAsset(companyId, asset.id, [files][0])
      .then((response) => {
        if (onSuccessUpload) {
          onSuccessUpload(response.data);
        }
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };
  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenter = centerOptions.filter(filterActiveCC);
  const onDelete = async () => {
    companyAPI.asset
      .remove(companyId, asset.id)
      .then(() => {
        toast.success(t("msg:deleted"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 4000 });
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };
  return (
    <Formik
      {...formikProps}
      key={id ? `formik_${id}` : "formik_creation"}
      id={id ? `formik_${asset.id}` : "formik_creation"}
    >
      {({ values, errors, isSubmitting, setFieldValue }) => {
        return (
          <>
            <Form noValidate key={id ? `form_${id}` : "form_creation"} id={id ? `form_${asset.id}` : "form_creation"}>
              <Row>
                <Col xl={3}>
                  <FormGroup.Input label={t("common:description")} name="description" required />
                </Col>
                <Col xl={3}>
                  <FormGroup.SimpleSelect
                    options={accountOptions.filter(filterAssetAccountOptions)}
                    name="asset_account"
                    label={t("asset:assetAccount")}
                    isDisabled={!assetIsEditable}
                    onChange={(selected) => onAssetAccountChange(selected, setFieldValue, values, accountById, t)}
                    required
                  />
                </Col>
                <Col xl={3}>
                  <FormGroup.SimpleSelect
                    options={accountOptions.filter(filterDepreciationAccountOptions)}
                    name="depreciation_account"
                    label={t("depreciationAccount")}
                    disabled
                    required={!deprecationDisallowed(values)}
                  />
                </Col>
                <Col xl={3}>
                  <FormGroup.SimpleSelect
                    options={accountOptions.filter(filterAssetCostAccountOptions)}
                    name="cost_account"
                    label={t("common:costAccount")}
                    isDisabled={
                      !assetIsEditable || deprecationDisallowed(values) || values.is_initially_depreciated_fully
                    }
                    required={!(deprecationDisallowed(values) || values.is_initially_depreciated_fully)}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={3}>
                  <FormGroup.MoneyInput
                    name="acquisition_value"
                    label={t("acquisitionValue")}
                    required
                    disabled={!assetIsEditable}
                  />
                </Col>
                <Col xl={3}>
                  <FormGroup.DatePicker
                    name="acquisition_date"
                    label={t("acquisitionDate")}
                    required
                    disabled={!assetIsEditable}
                  />
                </Col>
                <Col xl={3}>
                  {!deprecationDisallowed(values) ? (
                    <FormGroup.MoneyInput
                      name="residual_value"
                      label={t("residualValue")}
                      required
                      disabled={!assetIsEditable}
                    />
                  ) : (
                    <FormGroup.MoneyInput
                      name="residual_value"
                      label={t("residualValue")}
                      required={false}
                      disabled
                      value={values.acquisition_value}
                    />
                  )}
                </Col>
                <Col xl={3}>
                  <FormGroup.LabeledCheckbox
                    key={id ? `is_initially_depreciated_fully_${asset.id}` : "is_initially_depreciated_fully_creation"}
                    id={id ? `is_initially_depreciated_fully_${asset.id}` : "is_initially_depreciated_fully_creation"}
                    name="is_initially_depreciated_fully"
                    label={t("alreadyFullyDeprecated")}
                    required={false}
                    disabled={!assetIsEditable || deprecationDisallowed(values)}
                    onClick={() => onIsFullyDepreciatedClicked(setFieldValue, values)}
                    checked={values.is_initially_depreciated_fully}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={3}>
                  {deprecationDisallowed(values) ? (
                    <FormGroup.Input
                      type="number"
                      label={t("depreciationPeriod")}
                      name="depreciation_months_fake"
                      disabled
                      required={false}
                      value={0}
                    />
                  ) : (
                    <FormGroup.Input
                      type="number"
                      label={t("depreciationPeriod")}
                      name="depreciation_months"
                      disabled={!assetIsEditable}
                      onBlur={(e) => onDepreciationMonthsChange(e.currentTarget.value, setFieldValue, values)}
                      required={!deprecationDisallowed(values)}
                    />
                  )}
                </Col>
                <Col xl={3}>
                  {deprecationDisallowed(values) ? (
                    <FormGroup.DatePicker
                      name="depreciation_start_fake"
                      label={t("depreciationStart")}
                      disabled
                      required={false}
                    />
                  ) : (
                    <FormGroup.DatePicker
                      name="depreciation_start"
                      label={t("depreciationStart")}
                      required
                      disabled={!assetIsEditable}
                      onChange={(selected) => onDepreciationStartChange(selected, setFieldValue, values)}
                    />
                  )}
                </Col>
                <Col xl={3}>
                  {deprecationDisallowed(values) ? (
                    <FormGroup.DatePicker name="depreciation_end_fake" label={t("depreciationEnd")} disabled />
                  ) : (
                    <FormGroup.DatePicker name="depreciation_end" label={t("depreciationEnd")} disabled />
                  )}
                </Col>
                <Col xl={3}>
                  <FormGroup.MoneyInput
                    name="depreciation_amount_total"
                    label={t("depreciationAmountTotal")}
                    disabled
                    required={false}
                    value={depreciationAmountTotal(values)}
                  />
                </Col>
                <Col xl={3}>
                  <FormGroup.MoneyInput
                    name="depreciation_amount_per_months"
                    label={t("depreciationAmountPerMonth")}
                    disabled
                    required={false}
                    value={depreciationAmountPerMonth(values, values.is_initially_depreciated_fully)}
                  />
                </Col>
                <Col xl={3}>
                  {assetIsEditable && !values.is_initially_depreciated_fully && (
                    <FormGroup.Input
                      type="number"
                      label={t("executedMonths")}
                      name="initial_depreciated_months"
                      required={!deprecationDisallowed(values)}
                      disabled={deprecationDisallowed(values)}
                    />
                  )}
                  {assetIsEditable && !!values.is_initially_depreciated_fully && (
                    <FormGroup.Input
                      type="number"
                      label={t("executedMonths")}
                      name="initial_depreciated_months"
                      disabled
                      required={false}
                      value={values.depreciation_months}
                    />
                  )}
                  {!assetIsEditable && (
                    <FormGroup.Input
                      type="number"
                      label={t("executedMonths")}
                      name="depreciated_months"
                      disabled
                      required={false}
                    />
                  )}
                </Col>
                <Col xl={3}>
                  {assetIsEditable && !values.is_initially_depreciated_fully && (
                    <FormGroup.MoneyInput
                      name="initial_depreciated_amount"
                      label={t("accumulatedAmountOfDepreciation")}
                      required={!deprecationDisallowed(values)}
                      disabled={deprecationDisallowed(values)}
                    />
                  )}
                  {assetIsEditable && !!values.is_initially_depreciated_fully && (
                    <FormGroup.MoneyInput
                      name="initial_depreciated_amount"
                      label={t("accumulatedAmountOfDepreciation")}
                      disabled
                      required={false}
                      value={depreciationAmountTotal(values)}
                    />
                  )}
                  {!assetIsEditable && (
                    <FormGroup.MoneyInput
                      name="depreciated_amount"
                      label={t("accumulatedAmountOfDepreciation")}
                      required={false}
                      disabled
                    />
                  )}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xl={3}>
                  <FormGroup.SimpleSelect
                    maxMenuHeight={130}
                    options={activeCenter}
                    isDisabled={deprecationDisallowed(values) || values.is_initially_depreciated_fully}
                    isClearable
                    name="cost_center_for_depreciation"
                    label={t("label.costCenter")}
                  />
                </Col>
                <Col xl={3}>
                  <FormGroup.SimpleSelect
                    maxMenuHeight={130}
                    options={activeProjects}
                    isDisabled={deprecationDisallowed(values) || values.is_initially_depreciated_fully}
                    isClearable
                    name="project_for_depreciation"
                    label={t("label.project")}
                  />
                </Col>
                <Col xl={3}>
                  <FormGroup.MoneyInput
                    name="output_balance"
                    label={t("outputBalance")}
                    required={false}
                    disabled
                    value={outputBalance(values, output_balance)}
                  />
                </Col>
                {verification_id && canOpenVerModal && (
                  <Col xl={3} className="text-right">
                    <Button
                      type="button"
                      className="mt-3 my-3"
                      onClick={() => openVerificationModal(companyId, { id: verification_id })}
                    >
                      {t("connectedVerificationBtn")}
                    </Button>
                  </Col>
                )}
              </Row>
              {cancel_verification_id && canOpenVerModal && (
                <Row>
                  <Col xl={12} className="text-center">
                    <Button
                      type="button"
                      className="mt-3 my-3"
                      variant="info"
                      onClick={() => openVerificationModal(companyId, { id: cancel_verification_id })}
                    >
                      {t("decommissionVerificationBtn")}
                    </Button>
                  </Col>
                </Row>
              )}
              <AllError errors={errors} />
              <hr style={{ paddingBottom: 30 }} />
              <Row>
                <Col>
                  <SubmitButton isSubmitting={isSubmitting} />
                  {asset.id && (
                    <Button type="button" variant="outline-info" className="ml-2" onClick={uploadModal.open}>
                      {t("common:actions.connectDocument")} <i className="fas fa-file-upload" />
                    </Button>
                  )}
                  {assetCanBeDecommissionedOrSold && (
                    <>
                      <Button
                        type="button"
                        variant="outline-danger"
                        className="float-right ml-2"
                        onClick={() => decommissionAndSellModal.open({ forSell: true })}
                      >
                        {t("actions.sell")} <i className="fas fa-dollar-sign" />
                      </Button>
                      <Button
                        type="button"
                        variant="outline-danger"
                        className="float-right ml-2"
                        onClick={() => decommissionAndSellModal.open({ forSell: false })}
                      >
                        {t("actions.decommission")} <i className="fas fa-trash-alt" />
                      </Button>
                    </>
                  )}
                  {asset.id && assetIsEditable && (
                    <RemoveButton
                      variant="danger"
                      disabled={isSubmitting}
                      className="float-right ml-2"
                      confirmMessage={t("confirm.remove", {
                        assetDescription: asset.description,
                      })}
                      onClick={onDelete}
                    />
                  )}
                </Col>
                {!asset.id && (
                  <Col>
                    <span className="form-label">{t("common:actions.connectDocument")}</span>
                    <NewDocuments
                      documents={newFiles}
                      multiple
                      onChange={({ file, index }) => handleNewDocumentChange(file, index)}
                    />
                  </Col>
                )}
              </Row>
            </Form>
            {uploadModal.show && <FileUploadModal uploadHandler={onUpload} handleClose={uploadModal.close} max={3} />}
            {decommissionAndSellModal.show && (
              <DecommissionAndSellModal
                companyId={companyId}
                assetId={asset.id}
                successCallback={successCallback}
                handleClose={decommissionAndSellModal.close}
                forSell={decommissionAndSellModal.data.forSell}
                assetData={asset}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}

function DecommissionAndSellModal({ companyId, assetId, successCallback, handleClose, forSell, assetData }) {
  const { t } = useTranslation(["asset"]);
  return (
    <Modal show onHide={handleClose} scrollable size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{forSell ? t("actions.sell") : t("actions.decommission")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {forSell ? (
          <SellForm
            companyId={companyId}
            assetId={assetId}
            successCallback={successCallback}
            handleClose={handleClose}
            assetData={assetData}
          />
        ) : (
          <DecommissionForm
            companyId={companyId}
            assetId={assetId}
            successCallback={successCallback}
            handleClose={handleClose}
            assetData={assetData}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

function showSaleOrDecommissionBeforeDepreciationWarning(selected_date, last_depreciation_date_str) {
  if (!selected_date || !last_depreciation_date_str) {
    return false;
  }
  return selected_date <= new Date(last_depreciation_date_str);
}

function SellForm({ companyId, assetId, successCallback, handleClose, assetData }) {
  const { t } = useTranslation("asset");
  const {
    company,
    accounts: { asOptions: accountOptions },
  } = useCompanyState();
  const formikProps = {
    initialValues: {
      sales_date: parseISO(new Date().toJSON().slice(0, 10)),
    },
    validationSchema: yup
      .object()
      .shape({
        sales_date: yup.date().nullable().required(),
        sales_amount: yup.number().nullable().required().min(1),
        sales_account: yup.object().nullable().required(),
      })
      .test("sales_date", function (values) {
        if (values.sales_date) {
          if (company.lock_accounting_date && values.sales_date <= new Date(company.lock_accounting_date)) {
            return this.createError({
              path: "sales_date",
              message: t("ver:errors.bookingInLockedDate", { lockAccountingDate: company.lock_accounting_date }),
            });
          }
          if (isBefore(values.sales_date, new Date(assetData.config.acquisition_date))) {
            return this.createError({
              path: "sales_date",
              message: t("errors.saleOrDecommissionBeforeAcquisition"),
            });
          }
        }
        return true;
      }),
    onSubmit: async (values) => {
      return companyAPI.asset
        .sell(companyId, assetId, {
          ...values,
          sales_date: formatDate(values.sales_date),
          sales_account: values.sales_account?.value,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback();
          }
          handleClose();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form id="sell-form">
            <FormGroup.DatePicker label={t("salesDate")} name="sales_date" required popperClassName="popper-in-modal" />
            <FormGroup.MoneyInput label={t("salesAmount")} name="sales_amount" required />
            <FormGroup.SimpleSelect
              label={t("salesAccount")}
              options={accountOptions}
              name="sales_account"
              tdProps={{ style: { width: 160, maxWidth: 160 } }}
              required
            />
            {showSaleOrDecommissionBeforeDepreciationWarning(values.sales_date, assetData.latest_depreciation_date) && (
              <Alert variant="danger">{t("warning.saleOrDecommissionBeforeDepreciation")}</Alert>
            )}
            <>
              <hr />
              <Row>
                <Col md={4} />
                <Col md={4} className="text-center">
                  <Button type="button" className="btn btn-white" onClick={() => handleClose()}>
                    {t("common:actions.cancel")}
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} />
                </Col>
                <Col md={4} />
              </Row>
            </>
          </Form>
        );
      }}
    </Formik>
  );
}

function DecommissionForm({ companyId, assetId, successCallback, handleClose, assetData }) {
  const { t } = useTranslation("asset");
  const { company } = useCompanyState();
  const formikProps = {
    initialValues: {
      decommission_date: parseISO(new Date().toJSON().slice(0, 10)),
      amount: 0,
    },
    validationSchema: yup
      .object()
      .shape({
        decommission_date: yup.date().nullable().required(),
      })
      .test("decommission_date", function (values) {
        if (values.decommission_date) {
          if (company.lock_accounting_date && values.decommission_date <= new Date(company.lock_accounting_date)) {
            return this.createError({
              path: "decommission_date",
              message: t("ver:errors.bookingInLockedDate", { lockAccountingDate: company.lock_accounting_date }),
            });
          }
          if (isBefore(values.decommission_date, new Date(assetData.config.acquisition_date))) {
            return this.createError({
              path: "decommission_date",
              message: t("errors.saleOrDecommissionBeforeAcquisition"),
            });
          }
        }
        return true;
      }),
    onSubmit: async (values) => {
      return companyAPI.asset
        .decommission(companyId, assetId, {
          ...values,
          decommission_date: formatDate(values.decommission_date),
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback();
          }
          handleClose();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form id="decommission-form">
            <FormGroup.DatePicker
              label={t("decommissionDate")}
              name="decommission_date"
              required
              popperClassName="popper-in-modal"
            />
            <FormGroup.MoneyInput label={t("common:money.amount")} name="amount" required={false} disabled />
            {showSaleOrDecommissionBeforeDepreciationWarning(
              values.decommission_date,
              assetData.latest_depreciation_date
            ) && <Alert variant="danger">{t("warning.saleOrDecommissionBeforeDepreciation")}</Alert>}
            <>
              <hr />
              <Row>
                <Col md={4} />
                <Col md={4} className="text-center">
                  <Button type="button" className="btn btn-white" onClick={() => handleClose()}>
                    {t("common:actions.cancel")}
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} />
                </Col>
                <Col md={4} />
              </Row>
            </>
          </Form>
        );
      }}
    </Formik>
  );
}
export default AssetForm;
