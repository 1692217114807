import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import AuditorForm from "./AuditorForm";

function AuditorAddWidget({ companyId }) {
  const {
    dataActions: { reload },
  } = useTable();
  const auditor = {
    user: null,
  };

  const { t } = useTranslation("company");
  return (
    <Row>
      <Col>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addAuditor")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <AuditorForm companyId={companyId} auditor={auditor} successCallback={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default AuditorAddWidget;
