import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { Card, Col, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import OfficeSupportCompanyListLayout from "components/layouts/OfficeSupportCompanyListLayout";
import * as officeSupportAPI from "api/office-support";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import { saveAs } from "file-saver";
import "./OfficeSupportExport.scss";
import OfficeSupportExportFilters from "./OfficeSupportExportFilters";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";

function OfficeSupportExportPage() {
  const { t } = useTranslation("company");
  const [filters, setFilters] = useState({});
  const [companiesData, setCompaniesData] = useState([]);
  const [sectionVisibility, setSectionVisibility] = useState({
    headerClientDetails: true,
    headerBusinessProfile: true,
    headerRoles: true,
    headerCompanyStatus: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await officeSupportAPI.exportOfficeSupport.get(filters);
        setCompaniesData(response.data);
      } catch (error) {
        setCompaniesData([]);
      }
    };
    fetchData();
  }, [filters]);

  const formikProps = {
    initialValues: {
      // client details:
      clientDetails: false,
      client_details: false,
      client_no: false,
      company_name: true,
      org_no: true,
      company_type: false,
      based_in: false,
      public_company: false,
      sni_code: false,
      registered_aga: false,
      approved_company_tax: false,
      // contact person:
      contact_persons: false,
      contact_name: false,
      contact_email: false,
      contact_phone: false,
      contact_business_owner: false,
      roles: false,
      // accounting
      customer_manager: false,
      bank_name: false,
      software_accounting: false,
      software_payroll: false,
      accounting_method: false,
      accounting_month: false,
      vat_report_period: false,
      auditor: false,
      auditor_firstname: false,
      auditor_name: false,
      auditor_role: false,
      auditor_email: false,
      auditor_phone: false,
      // business profile
      businessProfile: false,
      additional: false,
      registration_date: false,
      conducted: false,
      // roles
      headerRoles: false,
      essential: false,
      payroll_consultant: false,
      coordinator: false,
      roles_customer_manager: false,
      accounting_consultant: false,
      accounting_assistant: false,
      invoicing_manager: false,
      // bank power of attorney
      bank_power_of_attorney: false,
      bank_consultant: false,
      permission: false,
      bank: false,
      // tax agency power of attorney
      tax_agency_attorneys: false,
      tax_agency_consults: false,
      // other power of attorney
      other_power_of_attorney: false,
      other_consultant: false,
      other_attorney: false,
      // company status
      status: false,
      companies_ids: [],
    },
    onSubmit: async (values) => {
      return officeSupportAPI.exportOfficeSupport
        .save({ ...values, companies_ids: companiesData })
        .then((response) => {
          const timestamp = new Date().toISOString().slice(0, 19).replace(/[:T]/g, "-");
          const filename = `${timestamp}-export.csv`;
          saveAs(response.data, filename);
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(error, { autoClose: 2000 });
        });
    },
  };
  const clientDetailsFields = [
    "client_no",
    "company_name",
    "org_no",
    "company_type",
    "based_in",
    "public_company",
    "sni_code",
    "registered_aga",
    "approved_company_tax",
  ];
  const contactPersonsFields = ["contact_name", "contact_business_owner", "contact_email", "roles", "contact_phone"];
  const accountingFields = [
    "customer_manager",
    "auditor",
    "bank_name",
    "auditor_firstname",
    "software_accounting",
    "auditor_lastname",
    "software_payroll",
    "auditor_name",
    "accounting_method",
    "auditor_email",
    "accounting_month",
    "auditor_phone",
    "vat_report_period",
    "auditor_role",
  ];
  const businessProfileAdditional = ["registration_date", "conducted"];
  const rolesEssentialFields = [
    "roles_customer_manager",
    "coordinator",
    "accounting_consultant",
    "payroll_consultant",
    "accounting_assistant",
    "invoicing_manager",
  ];
  const bankAttorneyFields = ["bank_consultant", "permission", "bank"];
  const taxAgencyPowerOfAttorneyFields = ["tax_agency_consults"];
  const otherAttorneyFields = ["other_consultant", "other_attorney"];
  const checkIndeterminateState = (fields, values) => {
    const checkedCount = fields.filter((field) => values[field]).length;
    return checkedCount > 0 && checkedCount < fields.length;
  };

  const getHeaderInfoMessage = () => {
    if (Object.keys(filters).length === 0) {
      return t("export.noFiltersApplied");
    }
    if (companiesData.length > 0) {
      return t("export.companiesMatch", { count: companiesData.length });
    }
    return <span style={{ color: "rgba(253, 103, 103, 1)" }}>{t("export.noDataFound")}</span>;
  };
  const areAllFieldsChecked = (fields, values) => fields.every((field) => values[field]);

  function FormikEffect() {
    const formik = useFormikContext();
    const { values, setFieldValue } = formik;

    useEffect(() => {
      const updateGroupCheckbox = (groupField, fields) => {
        const allChecked = areAllFieldsChecked(fields, values);
        setFieldValue(groupField, allChecked);
      };
      updateGroupCheckbox("clientDetails", [...clientDetailsFields, ...contactPersonsFields, ...accountingFields]);
      updateGroupCheckbox("contact_persons", contactPersonsFields);
      updateGroupCheckbox("client_details", clientDetailsFields);
      updateGroupCheckbox("accounting", accountingFields);
      updateGroupCheckbox("additional", businessProfileAdditional);
      updateGroupCheckbox("businessProfile", [...businessProfileAdditional, "additional"]);
      updateGroupCheckbox("headerRoles", [
        ...rolesEssentialFields,
        ...bankAttorneyFields,
        "essential",
        "bank_power_of_attorney",
        "tax_agency_attorneys",
        "tax_agency_consults",
        "other_power_of_attorney",
        "other_consultant",
        "other_attorney",
      ]);
      updateGroupCheckbox("essential", rolesEssentialFields);
      updateGroupCheckbox("bank_power_of_attorney", bankAttorneyFields);
      updateGroupCheckbox("bank_power_of_attorney", bankAttorneyFields);
      updateGroupCheckbox("tax_agency_attorneys", ["tax_agency_consults"]);
      updateGroupCheckbox("other_power_of_attorney", ["other_consultant", "other_attorney"]);
    }, [values, setFieldValue]);

    return null;
  }

  return (
    <OfficeSupportCompanyListLayout>
      <Card>
        <Card.Body>
          <Formik {...formikProps}>
            {({ values, errors, isSubmitting, setFieldValue }) => {
              const headerClientDetailsIndeterminate = checkIndeterminateState(
                [...clientDetailsFields, ...contactPersonsFields, ...accountingFields],
                values
              );
              const clientDetailsIndeterminate = checkIndeterminateState(clientDetailsFields, values);
              const contactPersonsIndeterminate = checkIndeterminateState(contactPersonsFields, values);
              const accountingIndeterminate = checkIndeterminateState(accountingFields, values);
              const headerBusinessProfileIndeterminate = checkIndeterminateState(
                [...businessProfileAdditional, "additional"],
                values
              );
              const businessProfileAdditionalIndeterminate = checkIndeterminateState(businessProfileAdditional, values);
              const headerRolesIndeterminate = checkIndeterminateState(
                [
                  ...rolesEssentialFields,
                  ...bankAttorneyFields,
                  ...taxAgencyPowerOfAttorneyFields,
                  ...otherAttorneyFields,
                  "essential",
                  "bank_power_of_attorney",
                  "tax_agency_attorneys",
                  "other_power_of_attorney",
                ],
                values
              );
              const rolesEssentialIndeterminate = checkIndeterminateState(rolesEssentialFields, values);
              const rolesBankPowerOfAttorneyIndeterminate = checkIndeterminateState(bankAttorneyFields, values);
              const rolesTaxAgencyPowerOfAttorneyIndeterminate = checkIndeterminateState(
                taxAgencyPowerOfAttorneyFields,
                values
              );
              const rolesOtherAttorneyIndeterminate = checkIndeterminateState(otherAttorneyFields, values);
              return (
                <Form id="office-support-export">
                  <FormikEffect />
                  <OfficeSupportExportFilters filters={filters} setFilters={setFilters} />
                  <Row>
                    <Col>
                      <div className="header-info">{getHeaderInfoMessage()}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table
                        bordered
                        responsive
                        size="sm"
                        name="office-support-export"
                        className="office-support-export-table"
                      >
                        <tbody>
                          {/* Client details */}
                          <tr>
                            <IndeterminateCheckbox
                              name="clientDetails"
                              label={t("clientDetails")}
                              tdProps={{ colSpan: 3, className: "header" }}
                              indeterminate={headerClientDetailsIndeterminate}
                              onChange={(e) => {
                                setFieldValue("company_name", true);
                                setFieldValue("org_no", true);
                                const isChecked = e.target.checked;
                                const allFieldsToUpdate = [
                                  ...clientDetailsFields,
                                  ...contactPersonsFields,
                                  ...accountingFields,
                                  "client_details",
                                  "contact_persons",
                                  "accounting",
                                  "status",
                                ];
                                const fieldsToExclude = ["org_no", "company_name"];
                                const fieldsToUpdate = allFieldsToUpdate.filter(
                                  (field) => !fieldsToExclude.includes(field)
                                );
                                fieldsToUpdate.forEach((field) => {
                                  setFieldValue(field, isChecked);
                                });
                              }}
                              header
                              toggleSection={() =>
                                setSectionVisibility({
                                  ...sectionVisibility,
                                  headerClientDetails: !sectionVisibility.headerClientDetails,
                                })
                              }
                              parentShowMore={sectionVisibility.headerClientDetails}
                            />
                          </tr>
                          {sectionVisibility.headerClientDetails && (
                            <>
                              <tr>
                                <IndeterminateCheckbox
                                  name="client_details"
                                  label={t("clientDetails")}
                                  tdProps={{ rowSpan: 6, width: "50%" }}
                                  indeterminate={clientDetailsIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    const fieldsToExclude = ["org_no", "company_name"];
                                    const fieldsToUpdate = clientDetailsFields.filter(
                                      (field) => !fieldsToExclude.includes(field)
                                    );
                                    fieldsToUpdate.forEach((field) => {
                                      setFieldValue(field, isChecked);
                                    });
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="client_no"
                                  label={t("common:customerNo")}
                                  tdProps={{ width: "25%" }}
                                />
                                <IndeterminateCheckbox
                                  name="public_company"
                                  label={t("title.publicCompany")}
                                  tdProps={{ width: "25%" }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="company_name" label={t("common:company")} disabled />
                                <IndeterminateCheckbox name="sni_code" label={t("sniCode")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="org_no" label={t("common:orgNo")} disabled />
                                <IndeterminateCheckbox name="registered_aga" label={t("generalTax")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="company_type" label={t("company:companyType")} />
                                <IndeterminateCheckbox name="approved_company_tax" label={t("approvedCompanyTax")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="based_in" label={t("basedIn")} />
                                <td />
                              </tr>
                              {/* Contact persons */}
                              <tr>
                                <IndeterminateCheckbox
                                  name="contact_persons"
                                  label={t("header.contactPersons")}
                                  tdProps={{ rowSpan: 4 }}
                                  indeterminate={contactPersonsIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    contactPersonsFields.forEach((field) => {
                                      setFieldValue(field, isChecked);
                                    });
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="contact_name" label={t("common:contact.contactName")} />
                                <IndeterminateCheckbox
                                  name="contact_business_owner"
                                  label={t("common:businessOwner")}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="contact_email" label={t("common:contact.contactEmail")} />
                                <IndeterminateCheckbox name="roles" label={t("common:roles")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="contact_phone" label={t("common:contact.phone")} />
                                <td />
                              </tr>
                              {/* Accounting */}
                              <tr>
                                <IndeterminateCheckbox
                                  name="accounting"
                                  label={t("accounting")}
                                  tdProps={{ rowSpan: 8 }}
                                  indeterminate={accountingIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    accountingFields.forEach((field) => {
                                      setFieldValue(field, isChecked);
                                    });
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="customer_manager" label={t("common:customerManager")} />
                                <IndeterminateCheckbox name="auditor" label={t("hasAuditor")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="bank_name" label={t("company:bankName")} />
                                <IndeterminateCheckbox name="auditor_firstname" label={t("export.auditorName")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="software_accounting" label={t("softwareAccounting")} />
                                <IndeterminateCheckbox name="auditor_name" label={t("export.auditorAgencyName")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="software_payroll" label={t("softwarePayroll")} />
                                <IndeterminateCheckbox name="auditor_email" label={t("export.auditorEmail")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="accounting_method" label={t("accountingMethod")} />
                                <IndeterminateCheckbox name="auditor_phone" label={t("export.auditorPhone")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="accounting_month" label={t("title.accountingMonth")} />
                                <IndeterminateCheckbox name="auditor_role" label={t("export.auditorRole")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="vat_report_period" label={t("company:vatReport")} />
                                <td />
                              </tr>
                              {/* Administration */}
                              <tr>
                                <IndeterminateCheckbox
                                  name="status"
                                  label={t("export.administration")}
                                  tdProps={{ rowSpan: 2 }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="status" label={t("export.companyStatus")} />
                                <td />
                              </tr>
                            </>
                          )}
                          {/* Business Profile */}
                          <tr>
                            <IndeterminateCheckbox
                              name="businessProfile"
                              label={t("company:businessProfile")}
                              tdProps={{ colSpan: 3, className: "header" }}
                              indeterminate={headerBusinessProfileIndeterminate}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                const fieldsToUpdate = [...businessProfileAdditional, "additional"];
                                fieldsToUpdate.forEach((field) => {
                                  setFieldValue(field, isChecked);
                                });
                              }}
                              header
                              toggleSection={() =>
                                setSectionVisibility({
                                  ...sectionVisibility,
                                  headerBusinessProfile: !sectionVisibility.headerBusinessProfile,
                                })
                              }
                              parentShowMore={sectionVisibility.headerBusinessProfile}
                            />
                          </tr>
                          {sectionVisibility.headerBusinessProfile && (
                            <>
                              <tr>
                                <IndeterminateCheckbox
                                  name="additional"
                                  label={t("header.businessAdditional")}
                                  tdProps={{ rowSpan: 2, width: "50%" }}
                                  indeterminate={businessProfileAdditionalIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    businessProfileAdditional.forEach((field) => {
                                      setFieldValue(field, isChecked);
                                    });
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="registration_date"
                                  label={t("title.registrationDate")}
                                  tdProps={{ width: "25%" }}
                                />
                                <IndeterminateCheckbox
                                  name="conducted"
                                  label={t("title.businessConducted")}
                                  tdProps={{ width: "25%" }}
                                />
                              </tr>
                            </>
                          )}
                          {/* Roles */}
                          <tr>
                            <IndeterminateCheckbox
                              name="headerRoles"
                              label={t("company:roles")}
                              tdProps={{ colSpan: 3, className: "header" }}
                              indeterminate={headerRolesIndeterminate}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                const fieldsToUpdate = [
                                  ...rolesEssentialFields,
                                  ...bankAttorneyFields,
                                  "essential",
                                  "bank_power_of_attorney",
                                  "tax_agency_attorneys",
                                  "tax_agency_consults",
                                  "other_power_of_attorney",
                                  "other_consultant",
                                  "other_attorney",
                                ];
                                fieldsToUpdate.forEach((field) => {
                                  setFieldValue(field, isChecked);
                                });
                              }}
                              header
                              toggleSection={() =>
                                setSectionVisibility({
                                  ...sectionVisibility,
                                  headerRoles: !sectionVisibility.headerRoles,
                                })
                              }
                              parentShowMore={sectionVisibility.headerRoles}
                            />
                          </tr>
                          {sectionVisibility.headerRoles && (
                            <>
                              <tr>
                                <IndeterminateCheckbox
                                  name="essential"
                                  label={t("header.businessEssential")}
                                  tdProps={{ rowSpan: 4, width: "50%" }}
                                  indeterminate={rolesEssentialIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    rolesEssentialFields.forEach((field) => {
                                      setFieldValue(field, isChecked);
                                    });
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="roles_customer_manager"
                                  label={t("common:customerManager")}
                                  tdProps={{ width: "25%" }}
                                />
                                <IndeterminateCheckbox
                                  name="coordinator"
                                  label={t("title.coordinator")}
                                  tdProps={{ width: "25%" }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="accounting_consultant"
                                  label={t("title.accounting_consultant")}
                                />
                                <IndeterminateCheckbox name="payroll_consultant" label={t("title.payrollConsultant")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="accounting_assistant"
                                  label={t("title.accounting_assistance")}
                                />
                                <IndeterminateCheckbox name="invoicing_manager" label={t("title.invoicing_manager")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="bank_power_of_attorney"
                                  label={t("header.bankAttorney")}
                                  tdProps={{ rowSpan: 3 }}
                                  indeterminate={rolesBankPowerOfAttorneyIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    bankAttorneyFields.forEach((field) => {
                                      setFieldValue(field, isChecked);
                                    });
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="bank_consultant" label={t("export.bankConsultant")} />
                                <IndeterminateCheckbox name="permission" label={t("title.permission")} />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="bank" label={t("bankName")} />
                                <td />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="tax_agency_attorneys"
                                  label={t("header.taxAgencyAttorney")}
                                  tdProps={{ rowSpan: 2 }}
                                  indeterminate={rolesTaxAgencyPowerOfAttorneyIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    setFieldValue("tax_agency_consults", isChecked);
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="tax_agency_consults" label={t("export.taxConsultants")} />
                                <td />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox
                                  name="other_power_of_attorney"
                                  label={t("header.otherAttorney")}
                                  tdProps={{ rowSpan: 2 }}
                                  indeterminate={rolesOtherAttorneyIndeterminate}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;

                                    setFieldValue("other_consultant", isChecked);
                                    setFieldValue("other_attorney", isChecked);
                                  }}
                                />
                              </tr>
                              <tr>
                                <IndeterminateCheckbox name="other_consultant" label={t("export.otherConsultant")} />
                                <IndeterminateCheckbox name="other_attorney" label={t("export.otherPowerOfAttorney")} />
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-right">
                      <SubmitButton
                        title={t("navigation:export")}
                        icon=""
                        isSubmitting={isSubmitting}
                        disabled={companiesData.length === 0}
                      />
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </OfficeSupportCompanyListLayout>
  );
}

export default OfficeSupportExportPage;
