import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  DocumentDocumentsButton,
  DocumentHistoryButton,
  TooltipActionButton,
  TooltipActionLink,
} from "components/ui/buttons";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import * as archiveAPI from "api/archive";
import useTable from "components/tables/btable/useTable";
import { useResolvedPath } from "react-router-dom";

function ConsultArchiveActions({ row, companyId, setCounter, reloadIndicators }) {
  const { t } = useTranslation("common");
  const url = useResolvedPath("").pathname;
  const {
    dataActions: { reload },
  } = useTable();

  const onDelete = async () => {
    const answer = await confirmRemove(t("others:confirm.removeFileArchive", { title: row.title }));
    if (answer) {
      archiveAPI.consultDocument
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          setCounter((c) => c + 1);
          reload();
          reloadIndicators();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  const onDuplicate = async () => {
    const answer = await confirmExecute(t("others:confirm.duplicateFileArchive", { title: row.title }));
    if (answer) {
      archiveAPI.consultDocument
        .duplicate(companyId, row.id)
        .then(() => {
          toast.success(t("msg:generated"));
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  return (
    <>
      <DocumentHistoryButton documentId={row.id} />
      {row.has_file && (
        <DocumentDocumentsButton companyId={companyId} documentsIds={[row.id]} partUrl="consultant-documents" />
      )}
      <TooltipActionButton text={t("common:actions.duplicate")} onClick={onDuplicate} icon="fas fa-copy" />
      <TooltipActionLink text={t("common:actions.change")} link={`${url}/${row.id}/change`} icon="fas fa-pen" />
      <TooltipActionButton variant="danger" text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
    </>
  );
}

export default ConsultArchiveActions;
