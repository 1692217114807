import React from "react";
import { useTranslation } from "react-i18next";
import useTable from "components/tables/btable/useTable";
import * as companyAPI from "api/company";
import { TooltipActionButton } from "components/ui/buttons";

function OfficeSupportCompanyActions({ row, forStaff, userId }) {
  const { t } = useTranslation(["company", "navigation"]);
  const {
    dataActions: { updateRow },
  } = useTable();
  function toggleFavourite(companyId) {
    return companyAPI.consultToggleFavourite(companyId).then((response) => {
      updateRow(response.data);
    });
  }
  return (
    <TooltipActionButton
      text={t("toggleFavourite")}
      icon="fas fa-heart"
      onClick={() => toggleFavourite(row.id)}
      variant={row.favourite_for.includes(userId) ? "warning" : "secondary"}
    />
  );
}

export default OfficeSupportCompanyActions;
