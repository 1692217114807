import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import addBusinessDays from "date-fns/addBusinessDays";

import DirectPaymentForm from "components/forms/DirectPaymentForm";

function DirectPaymentAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const directPayment = {
    booking_date: addBusinessDays(new Date(), 1),
    receiver: null,
    amount: 0,
    amount_currency: "SEK",
    description: "",
  };

  const onCreate = (newItem) => {
    navigate(-1);
  };

  return <DirectPaymentForm companyId={company.id} directPayment={directPayment} onSave={onCreate} />;
}

export default DirectPaymentAddPage;
