import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

function NumberRangeFilter({ label, numberFrom, numberTo, min, max, onChange }) {
  const { t } = useTranslation("common");
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <div className="number-range">
        <Form.Control
          type="number"
          placeholder={t("from")}
          defaultValue={numberFrom}
          min={min}
          max={max}
          onBlur={(e) => onChange({ numberFrom: e.currentTarget.value, numberTo })}
        />
        <Form.Control
          type="number"
          placeholder={t("to")}
          defaultValue={numberTo}
          min={numberFrom || min}
          max={max}
          onBlur={(e) => onChange({ numberFrom, numberTo: e.currentTarget.value })}
        />
      </div>
    </Form.Group>
  );
}

export default NumberRangeFilter;
