import React, { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import useModal from "hooks/useModal";
import { PayBankIDModal } from "components/modals";
import { confirmExecute } from "components/modals/ConfirmModal";
import * as siAPI from "api/supplier-invoices";
import useTable from "components/tables/btable/useTable";
import { getBankIDMessage } from "utils/others";
import { SelectionContext } from "state/providers/SelectionProvider";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { TooltipActionButton } from "./TooltipButton";

function PayAtOnceButton({ invoice, companyId }) {
  const { t } = useTranslation("si");
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const {
    tableId,
    dataActions: { reload },
  } = useTable();
  const { removeSelection } = useContext(SelectionContext);
  const bankIdModal = useModal();
  if (!invoice) {
    return null;
  }

  const openBankId = async () => {
    const answer = await confirmExecute(
      t("confirm.payAtOnce", {
        invoiceNo: invoice.invoice_no || invoice.ocr,
      })
    );

    if (answer) {
      bankIdModal.open();
    }
  };

  const onBankIDSuccess = async (bankIdToken) => {
    bankIdModal.close();
    await siAPI
      .payAtOnce(companyId, invoice.id, bankIdToken)
      .then((response) => {
        toast.success(t("msg:sent"));
        if (removeSelection) {
          removeSelection(tableId, invoice);
        }
        reload();
        reloadMenuNumbers();
      })
      .catch((error) => {
        if (error.data && error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 6000 });
        }
      });
  };

  function getMessage() {
    return getBankIDMessage({ unpaidSupplierInvoices: { 1: invoice } });
  }

  return (
    <>
      <TooltipActionButton text={t("actions.payAtOnce")} icon="fas fa-exclamation" onClick={openBankId} />
      {bankIdModal.show && (
        <PayBankIDModal handleSuccess={onBankIDSuccess} handleClose={bankIdModal.close} bankIDMsg={getMessage()} />
      )}
    </>
  );
}

export default PayAtOnceButton;
