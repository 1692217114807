import React, { useState } from "react";
import { Modal, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EmployeeEditWidget } from "components/forms/company-settings-forms/EmployeeForm";
import EmployeeSchedulesForm from "components/forms/company-settings-forms/EmployeeSchedulesForm";
import EmployeePermissionsForm from "components/forms/company-settings-forms/EmployeePermissionsForm";
import "./EmployeeModal.scss";
import { DraggableModalDialog } from "../DraggableModalDialog";

function EmployeeModal({ companyId, employeeId, handleClose, forOnboarding }) {
  const { t } = useTranslation("common");
  const [tab, setTab] = useState(0);

  return (
    <Modal show onHide={handleClose} size="xl" scrollable className="employee-modal" dialogAs={DraggableModalDialog}>
      <Nav variant="tabs" activeKey={tab}>
        <Nav.Item>
          <Nav.Link eventKey={0} onSelect={() => setTab(0)}>
            {t("navigation:general")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={1} onSelect={() => setTab(1)}>
            {t("navigation:employeesSchedules")}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={2} onSelect={() => setTab(2)}>
            {t("navigation:permissions")}
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="modal-content__wrapper">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="p-1">
            {tab === 0 && <EmployeeEditWidget companyId={companyId} id={employeeId} />}
            {tab === 1 && <EmployeeSchedulesForm companyId={companyId} employeeId={employeeId} />}
            {tab === 2 && (
              <EmployeePermissionsForm companyId={companyId} employeeId={employeeId} forOnboarding={forOnboarding} />
            )}
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default EmployeeModal;
