import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import TaxDirectPaymentListPage from "./TaxDirectPaymentListPage";

function PayTaxDirectPaymentListPage() {
  const canPay = HasPermCode(codesFor.taxes.pay);
  return <TaxDirectPaymentListPage tableId="unpaidTaxDP" initialMode="unpaid" selectable={canPay} />;
}

export default PayTaxDirectPaymentListPage;
