import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { SilverbackTable } from "components/tables";
import * as tableAPI from "api/table";
import { useOutletContext } from "react-router-dom";

function SilverbackListPage({ selectable = false, tableId = "silverback" }) {
  const company = useOutletContext();
  const initialFilters = {};

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => tableAPI.companySilverbacks(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <SilverbackTable companyId={company.id} selectable={selectable} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default SilverbackListPage;
