import React, { useCallback, useContext, useEffect, useState } from "react";
import { DATE_FORMAT, formatDate, parseDate, SHORT_DATE_FORMAT } from "utils/date";
import ReactDatePicker from "react-datepicker";
import { Button, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import * as companyAPI from "api/company";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import { confirmExecute } from "components/modals/ConfirmModal";

function TTActivationInput({ companyId, setIsDateValid, isDateValid }) {
  const { t } = useTranslation("company");
  const { info: companyInfo, updateInfo } = useContext(CompanySettingStateContext);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const checkValidity = useCallback(() => {
    if (!value) {
      setIsDateValid(false);
    }
  }, [setIsDateValid, value]);

  const onDateChange = useCallback(
    (dateValue) => {
      setIsDateValid(!!dateValue);
      setValue(dateValue);
    },
    [setIsDateValid]
  );

  useEffect(() => {
    if (companyInfo) {
      setValue(parseDate(companyInfo.tt_active_date));
    }
  }, [companyInfo]);

  const onChange = useCallback(async () => {
    checkValidity();
    if (!value) return;

    let ttActive = false;

    if (!!value && companyInfo.status === "draft") {
      ttActive = await confirmExecute(t("tt.confirm.changeStatusToTT"));
    }

    if (ttActive || companyInfo.status !== "draft") {
      setLoading(true);
      try {
        await companyAPI.setTTActiveDate(companyId, formatDate(value), ttActive);
        await updateInfo({ ...companyInfo, tt_active_date: formatDate(value) });
        toast.success(t("msg:updated"), { autoClose: 1500 });
      } catch {
        toast.error(t("msg:canNotExecuteAction"));
      } finally {
        setLoading(false);
      }
    }
  }, [checkValidity, companyId, companyInfo, t, updateInfo, value]);

  return (
    <div>
      <InputGroup className={cx({ danger: !isDateValid })}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            {t("tt:startWorking")}
            <span className="asterisk">*</span>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <ReactDatePicker
          className="form-control"
          selected={value}
          isClearable={!loading}
          disabled={loading}
          minDate={new Date()}
          dateFormat={[DATE_FORMAT, SHORT_DATE_FORMAT]}
          onChange={onDateChange}
          onBlur={checkValidity}
        />
        <InputGroup.Append>
          <Button type="button" onClick={onChange}>
            {t("ttDateSave")}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
}

export default TTActivationInput;
