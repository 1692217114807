import CreatableSelect from "react-select/creatable";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

function MultiTextInput({ initial, onChange, showAddMe }) {
  const { t } = useTranslation("common");
  const [state, setState] = useState({
    inputValue: "",
    value: initial ? initial.map((email) => createOption(email)) : [],
  });

  const handleBlur = () => {
    onChange(state.value.map((item) => item.value));
  };

  const handleChange = (value) => {
    setState({ ...state, value: value || [] });
  };

  const handleInputChange = (inputValue) => {
    setState({ ...state, inputValue });
  };

  const handleKeyDown = async (event) => {
    const { inputValue, value } = state;
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab": {
        event.preventDefault();
        const alreadyExists = value.map((item) => item.value).includes(inputValue);
        if (alreadyExists) {
          setState({ ...state, inputValue: "" });
          return;
        }
        setState({
          inputValue: "",
          value: [...value, createOption(inputValue)],
        });
        break;
      }
      default:
        break;
    }
  };

  const { inputValue, value } = state;
  return (
    <div className="multi-email-field">
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        className="react-select"
        classNamePrefix="select"
        menuIsOpen={false}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={t("typeAndEnter")}
        value={value}
      />
    </div>
  );
}

export default MultiTextInput;
