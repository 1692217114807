function filterPeriodisationCostAccountsForSI(option) {
  return option.id >= 4000 && option.id <= 7999;
}

function filterPeriodisationCostAccountsForVer(option) {
  return option.id === 3800 || (option.id >= 4000 && option.id <= 7999);
}

const periodisationAccounts = [1791, 2991];
const periodisationToogleAccounts = [1790, 2990, 1791, 2991];

export {
  filterPeriodisationCostAccountsForVer,
  filterPeriodisationCostAccountsForSI,
  periodisationToogleAccounts,
  periodisationAccounts,
};
