import React from "react";
import { Card, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import * as options from "api/options";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import SupplierForm from "./SupplierForm";

function SupplierAddModal({ companyId, initialSupplier, handleClose }) {
  const supplierPaymentMethods = options.supplierPaymentMethods.asDict();
  const supplier = initialSupplier
    ? {
        supplier: {
          value: initialSupplier.value,
          label: initialSupplier.label,
        },
        id: initialSupplier.id,
        payment_method: initialSupplier.payment_method,
        contra_account: initialSupplier.contra_account,
        bankgiro_number: initialSupplier.bankgiro_number,
        plusgiro_number: initialSupplier.plusgiro_number,
        clearing_number: initialSupplier.clearing_number,
        bank_account_number: initialSupplier.bank_account_number,
        contact_email: "",
        bic: initialSupplier.bic,
        iban: initialSupplier.iban,
        project: initialSupplier.project || "",
        cost_center: initialSupplier.cost_center || "",
        payment_terms: initialSupplier.payment_terms || 30,
        is_active: initialSupplier.is_active,
        deactivate_payment: initialSupplier.deactivate_payment,
        street: initialSupplier.street,
        city: initialSupplier.city,
        state: initialSupplier.state,
        zip_code: initialSupplier.zip_code,
        country: initialSupplier.country,
        bank_name: initialSupplier.bank_name,
        bank_code: initialSupplier.bank_code,
      }
    : {
        supplier: null,
        payment_method: supplierPaymentMethods.BankGiro,
        contra_account: null,
        bankgiro_number: "",
        plusgiro_number: "",
        clearing_number: "",
        bank_account_number: "",
        deactivate_payment: false,
        contact_email: "",
        bic: "",
        iban: "",
        payment_terms: 30,
        is_active: true,
        street: "",
        city: "",
        state: "",
        zip_code: "",
        country: "SE",
        bank_name: "",
        bank_code: "",
      };

  const onSave = (connectedSupplier) => {
    handleClose({
      ...connectedSupplier,
      value: connectedSupplier.supplier,
      supplier_id: connectedSupplier.supplier,
    });
  };

  const { t } = useTranslation("si");

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          <Card.Title>{t("actions.connectSupplier")}</Card.Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SupplierForm
          companyId={companyId}
          supplier={supplier}
          shouldResetForm={false}
          allowDelete={false}
          allowDeactivate={false}
          successCallback={onSave}
        />
      </Modal.Body>
    </Modal>
  );
}

export default SupplierAddModal;
