import React from "react";
import { useTranslation } from "react-i18next";

import { TooltipActionButton } from "components/ui/buttons";
import EditTTLogModal from "components/modals/EditTTLogModal";
import useTable from "components/tables/btable/useTable";
import useModal from "hooks/useModal";
import { confirmRemove } from "components/modals/ConfirmModal";
import * as trackerAPI from "api/time-tracker";
import { toast } from "react-toastify";
import { useAuthState } from "hooks/useAuth";

function TimeLogTTActions({ row }) {
  const { t } = useTranslation("tt");
  const {
    user: { id: userId },
  } = useAuthState();
  const editModal = useModal();
  const {
    dataActions: { reload },
  } = useTable();

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeTimeLog"));
    if (answer) {
      trackerAPI
        .removeTimeLog(row.id)
        .then(() => {
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onEditClose = (saved) => {
    if (saved) {
      reload();
    }
    editModal.close();
  };

  const canEdit = userId === row.user_id;
  if (!canEdit) {
    return null;
  }
  return (
    <>
      <TooltipActionButton text={t("common:actions.change")} onClick={editModal.open} icon="fas fa-pen" />
      <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      {editModal.show && <EditTTLogModal timeLog={row} handleClose={onEditClose} />}
    </>
  );
}

export default TimeLogTTActions;
