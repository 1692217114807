import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import RepeatedTaskForm from "./RepeatedTaskForm";

function RepeatedTaskAddWidget({ companyId }) {
  const {
    dataActions: { reload },
  } = useTable();
  const task = {
    active: true,
    system: false,
    title: "",
    byweekday: null,
    bymonthday: null,
    bymonth: null,
    assignee: null,
    bysetpos: "",
  };

  const { t } = useTranslation("company");
  return (
    <Row>
      <Col>
        <Card className="card-border" id="create-tt-config">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addTask")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <RepeatedTaskForm companyId={companyId} task={task} successCallback={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default RepeatedTaskAddWidget;
