import React from "react";
import { TooltipActionButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import * as companyAPI from "api/company";
import useTable from "../btable/useTable";

function BankRulesActions({ companyId, row, index }) {
  const { t } = useTranslation("common");
  const {
    responseHeaders: { "x-pagination-count": itemsCount },
    dataActions: { reload },
  } = useTable();

  const priorityDown = async () => {
    return companyAPI.bankRules.priorityDown(companyId, row.id).then(() => {
      reload();
    });
  };

  const priorityUp = async () => {
    return companyAPI.bankRules.priorityUp(companyId, row.id).then(() => {
      reload();
    });
  };

  return (
    <>
      <TooltipActionButton
        text={t("common:actions.priorityUp")}
        icon="fas fa-arrow-up"
        disabled={index === 0}
        onClick={priorityUp}
      />
      <TooltipActionButton
        text={t("common:actions.priorityDown")}
        icon="fas fa-arrow-down"
        disabled={itemsCount - 1 === index}
        onClick={priorityDown}
      />
    </>
  );
}

export default BankRulesActions;
