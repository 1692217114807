import React from "react";
import { formatDuration } from "utils/date";

function DurationInterval({ initialDuration }) {
  const [duration, setDuration] = React.useState(initialDuration);

  React.useEffect(() => {
    setDuration(initialDuration);
  }, [initialDuration]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDuration((state) => state + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [initialDuration]);

  return formatDuration(duration);
}

export default DurationInterval;
