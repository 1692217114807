import React from "react";
import { Col } from "react-bootstrap";
import cx from "classnames";

function DataBox({ title, value, subtitle, subtitle2, variant }) {
  return (
    <Col>
      <div className={cx("card widget-box-two", variant)}>
        <div className={cx("card-body", { "pb-0": subtitle2 })}>
          <div className="media">
            <div className="media-body wigdet-two-content">
              <p className="m-0 text-uppercase text-white">{title}</p>
              <h2 className="text-white">
                <span data-plugin="counterup">{value}</span>
              </h2>
              {subtitle && <small className="text-white d-block m-0">{subtitle}</small>}
              {subtitle2 && <small className="text-white d-block mb-1">{subtitle2}</small>}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default DataBox;
