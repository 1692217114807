import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import cx from "classnames";

function MoneyRangeFilter({ label, numberFrom, numberTo, onChange }) {
  const { t } = useTranslation("common");
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <div className="number-range">
        <NumberFormat
          thousandSeparator={" "}
          decimalSeparator=","
          placeholder={t("from")}
          autoComplete="off"
          decimalScale={false}
          defaultValue={numberFrom}
          isAllowed={({ floatValue }) => (floatValue ? floatValue <= 9999999999 : true)}
          className={cx(["money-input form-control"])}
          fixedDecimalScale
          onBlur={(e) =>
            onChange({
              numberFrom: e.currentTarget.value.replace(" ", ""),
              numberTo,
            })
          }
        />
        <NumberFormat
          thousandSeparator={" "}
          decimalSeparator=","
          placeholder={t("to")}
          autoComplete="off"
          decimalScale={false}
          defaultValue={numberTo}
          isAllowed={({ floatValue }) => (floatValue ? floatValue <= 9999999999 : true)}
          className={cx(["money-input form-control"])}
          fixedDecimalScale
          onBlur={(e) =>
            onChange({
              numberTo: e.currentTarget.value.replace(" ", ""),
              numberFrom,
            })
          }
        />
      </div>
    </Form.Group>
  );
}

export default MoneyRangeFilter;
