import React, { useState } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import * as selectAPI from "api/select";
import CustomerAddModal from "components/modals/CustomerAddModal";
import useModal from "hooks/useModal";
import { FormGroup } from "components/formik";

function CustomerPicker({ customer, name, label, companyId, onReset, changeAllowed = true, ...props }) {
  const { t } = useTranslation("common");
  const customerModal = useModal();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const onAddCustomer = (newCustomer) => {
    customerModal.close();
    setRefreshCounter((c) => c + 1);
  };

  return (
    <>
      {customer && (
        <div className="supplier-picker-details form-group">
          <div className="details">
            <label className="form-label">{label}</label>
            <div>
              {t("common:name")}: <strong>{customer.Name}</strong>
            </div>
            {customer.Address && (
              <div>
                {t("common:contact.address")}:{" "}
                <strong>
                  {customer.Address} {customer.Address2}
                </strong>
              </div>
            )}
            {customer.City && (
              <div>
                {t("common:contact.post")}:{" "}
                <strong>
                  {customer.ZipCode} {customer.City}
                </strong>
              </div>
            )}
            {customer.Email && (
              <div>
                {t("common:contact.emailLong")}: <strong>{customer.Email}</strong>
              </div>
            )}
            {changeAllowed && (
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={onReset}>
                {t("actions.change")}
              </button>
            )}
          </div>
        </div>
      )}
      <div className={cx({ "d-none": !!customer }, "customer-picker")}>
        <FormGroup.AsyncSelect
          key={`cus-${refreshCounter}`}
          className="customer-select"
          name={name}
          label={label}
          placeholder={t("common:actions.select")}
          loadOptions={(params, callback) =>
            selectAPI.getCompanyCustomers(companyId, { ...params, mode: "active" }, callback)
          }
          minSearchLength={0}
          required
          {...props}
        />
        <Button variant="second" onClick={customerModal.open}>
          <i className="fas fa-plus" /> {t("ci:actions.addCustomer")}
        </Button>
      </div>
      {customerModal.show && (
        <CustomerAddModal companyId={companyId} handleClose={customerModal.close} onSuccess={onAddCustomer} />
      )}
    </>
  );
}

export default CustomerPicker;
