function formatWithDecimalComma(exp) {
  return Number(exp).toFixed(2)?.replace(/\./g, ",");
}

function formatWithDecimalPoint(exp) {
  return Number(exp?.toString()?.replace(/,/g, ".")).toFixed(2);
}

function isStartsWithEqual(exp) {
  const pattern = /^=/;

  return pattern.test(exp);
}

function isValidExpression(exp) {
  const pattern = /^[0-9,+\-/*=()]+$/;

  return pattern.test(exp);
}

function containsOnlyAllowedCharacters(exp) {
  const pattern = /^-?\d+(,\d+)?$/;

  return pattern.test(exp);
}

function formatForEval(exp) {
  return exp.slice(1).replace(/,/g, ".");
}

function removeSpacesFromString(string) {
  return string.replace(/\s/g, "");
}

export {
  formatWithDecimalComma,
  formatWithDecimalPoint,
  isStartsWithEqual,
  isValidExpression,
  containsOnlyAllowedCharacters,
  formatForEval,
  removeSpacesFromString,
};
