import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import { BankIDLoader } from "components/ui/loaders";
import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import logoBankID from "assets/images/bankid-white.svg";

import * as authAPI from "api2/auth";
import { useAuthDispatch } from "hooks/useAuth";

function BankIDLoginForm({ initialSSN }) {
  const { t } = useTranslation("others");
  const { bankIDLoginCheck } = useAuthDispatch();
  const [staticSSN, setStaticSSN] = useState(initialSSN ? `${initialSSN.slice(0, -4)}****` : "");
  const [verifying, setVerifying] = useState(false);
  const formikProps = {
    initialValues: {
      ssn: initialSSN,
    },
    validationSchema: yup.object().shape({
      ssn: yup.string().required(),
    }),
    onSubmit: async (values) => {
      const token = await authAPI.bankIDLoginRequest(values.ssn);
      if (token) {
        localStorage.setItem("ssn", values.ssn);
        setVerifying(true);
        await bankIDLoginCheck(token).catch((err) => {
          toast.error(t("msg:bankIdNotConfirmed"));
          setVerifying(false);
        });
      } else {
        toast.error(t("msg:wrongSSN"));
        setVerifying(false);
      }
    },
  };

  function onStaticSSNChange(setFieldValue) {
    setFieldValue("ssn", "");
    setStaticSSN("");
  }

  if (verifying) {
    return (
      <BankIDLoader>
        <a href="/auth/login" className="btn btn-link">
          {t("common:actions.cancel")}
        </a>
        <div className="bankId-logo">
          <a href="https://www.bankid.com/" target="_blank" rel="noopener noreferrer">
            <img src={logoBankID} alt="Bank ID" />
          </a>
        </div>
      </BankIDLoader>
    );
  }
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form>
            {staticSSN ? (
              <div className="initial-ssn">
                <label className="mb-0">{t("common:contact.personalNumber")}</label>
                <div className="static-ssn">{staticSSN}</div>
                <Button variant="secondary" size="sm" onClick={() => onStaticSSNChange(setFieldValue)}>
                  {t("common:actions.change")}
                </Button>
              </div>
            ) : (
              <FormGroup.Input
                name="ssn"
                label={t("common:contact.personalNumber")}
                className="bankid"
                placeholder="YYYYMMDDXXXX"
                autoFocus
                autoComplete="off"
              />
            )}
            <div className="form-group text-center mb-3">
              <SubmitButton
                size="lg"
                variant="primary"
                className="width-lg"
                title="others:auth.signIn"
                icon="fa-unlock"
                isSubmitting={isSubmitting}
              />
            </div>
            <div className="bankId-logo">
              <a href="https://www.bankid.com/" target="_blank" rel="noopener noreferrer">
                <img src={logoBankID} alt="Bank ID" />
              </a>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default BankIDLoginForm;
