import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BankConnectionContext } from "state/providers/BankConnectionProvider";

function RedirectFlow() {
  const { t } = useTranslation("company");
  const [loading, setLoading] = useState(false);
  const { link } = useContext(BankConnectionContext);

  const onRedirect = () => {
    setLoading(true);
    window.location = link;
  };

  return (
    <div className="redirect-flow">
      <button type="button" className="btn btn-primary" onClick={onRedirect} disabled={loading}>
        {t("psd.redirectNote")} <i className="fe-chevron-right" />
      </button>
    </div>
  );
}

export default RedirectFlow;
