import cx from "classnames";
import React, { useEffect, useState } from "react";

import { formatDuration } from "utils/date";
import { truncateText } from "utils/text";

function Task({ task, playTimer, stopTimer, finish, revertFinish, openTask, byCompany = false }) {
  const initials = task.assignee_name
    ?.split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  return (
    <div
      className={cx("task", {
        "task-overdue": task.overdue,
        "task-internal": task.category === "internal",
        "task-custom": task.category === "custom",
        "task-finished": task.finished,
        "task-playing": task.playing,
        "task-company": !!task.main_company_task,
      })}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        return !task.permanent && openTask(task);
      }}
    >
      <div className="title">
        {truncateText(task.title, 15)}
        {task.comment && (
          <span className="comment">
            <i className="far fa-comment" />
          </span>
        )}
        {!byCompany && (
          <div className="float-right initials-hover">
            <div className="initials-author initials-author-background">{initials}</div>
            <div className="author-tooltip">{task.assignee_name}</div>
          </div>
        )}
      </div>
      {!task.permanent && !byCompany && (
        <div className="company">
          {truncateText(task.company_name, 16)}
          <i className="fe-briefcase suitcase-color" />
        </div>
      )}
      <div className="t-row">
        <div className="duration">{task.playing ? <Duration task={task} /> : task.durationStr}</div>
        {!task.permanent && !task.main_company_task && (task.overdue || byCompany) && (
          <div className="date">{task.deadline_date}</div>
        )}
      </div>
      <div className="task-overlay">
        <div className="task-overlay-wrapper">
          {!task.permanent && !task.finished && (
            <div className="btn-finish" onClick={finish}>
              <i className="fas fa-check" />
            </div>
          )}
          {(() => {
            if (task.finished) {
              return (
                <div className="btn-revert" onClick={revertFinish}>
                  <i className="fas fa-recycle" />
                </div>
              );
            }
            if (!task.playing) {
              return (
                <div className="btn-play" onClick={playTimer}>
                  <i className="fas fa-play" />
                </div>
              );
            }
            return (
              <div className="btn-stop" onClick={stopTimer}>
                <i className="fas fa-stop" />
              </div>
            );
          })()}
        </div>
      </div>
    </div>
  );
}

function Duration({ task }) {
  const [duration, setDuration] = useState(task.duration);
  useEffect(() => {
    let interval;
    if (task.playing) {
      interval = setInterval(() => {
        setDuration((state) => state + 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [task.playing]);
  return <span>{formatDuration(duration)}</span>;
}

export default Task;
