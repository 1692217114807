import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BankIDLoader } from "components/ui/loaders";
import * as authAPI from "api2/auth";
import { DraggableModalDialog } from "../DraggableModalDialog";

function PayBankIDModal({ handleSuccess, handleClose, bankIDMsg = "" }) {
  const { t } = useTranslation("others");
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const ref = useRef(1);
  const successRef = useRef(false);
  useEffect(() => {
    let interval = null;
    (async function signRequest() {
      const token = await authAPI.bankIDSignRequest(bankIDMsg);
      if (!token) {
        setError(true);
        return;
      }
      if (ref.current === 0) {
        return;
      }
      interval = setInterval(() => {
        return authAPI
          .bankIDSignCheck(token)
          .then((response) => {
            if (response.status === 201) {
              if (!successRef.current) {
                // if bank id will not get response in 2s then we may get duplicated 201 response and duplicated payments
                // this trick prevents that
                successRef.current = true;
                clearInterval(interval);
                handleSuccess(token);
              }
            }
          })
          .catch((err) => {
            clearInterval(interval);
            setError(err);
          });
      }, 2000);
    })();

    return () => {
      ref.current = 0; // prevent to continue if closed before token set
      clearInterval(interval);
    };
  }, [bankIDMsg, retryCount, handleSuccess]);

  const retry = () => {
    setError(false);
    setRetryCount(retryCount + 1);
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleClose}
      backdrop="static"
      dialogClassName="bankid-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("payBankIDModalTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BankIDLoader bankIdLogo>
          {error && (
            <>
              <p className="font-15 mb-0 text-danger">{t("payBankIDFail")}</p>
              <Button variant="outline-danger" onClick={retry}>
                {t("payBankRetry")}
              </Button>
            </>
          )}
        </BankIDLoader>
      </Modal.Body>
    </Modal>
  );
}

export default PayBankIDModal;
