import _ from "lodash";
import { parseISO } from "date-fns";
import client from "./client";

export function details(companyId, invoiceId) {
  return client.get(`/companies/${companyId}/supplier-invoices/${invoiceId}/`).then((response) => ({
    ...response.data,
    booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
    due_date: response.data.due_date ? parseISO(response.data.due_date) : null,
    periodisation_date_start: response.data.periodisation_date_start
      ? parseISO(response.data.periodisation_date_start)
      : null,
    newDocuments: [{ key: _.uniqueId("nd.") }],
  }));
}

export function save(companyId, data) {
  if (data.id) {
    return client.put(`/companies/${companyId}/supplier-invoices/${data.id}/`, data, { params: { next: true } });
  }
  return client.post(`/companies/${companyId}/supplier-invoices/`, data, {
    params: { next: true },
  });
}

export function approveUpdate(companyId, data) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${data.id}/update_on_prelim/`, data);
}

export function previewUpdate(companyId, data) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${data.id}/update_on_preview/`, data);
}

export function approve(companyId, invoiceId, params) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/approve_invoice/`, null, { params });
}

export function dispute(companyId, invoiceId, values) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/dispute_invoice/`, values);
}

export function investigate(companyId, invoiceId, values) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/investigate_invoice/`, values);
}

export function reInvoice(companyId, invoiceId, values) {
  return client.post(`/companies/${companyId}/supplier-invoices/${invoiceId}/add_reinvoice/`, values);
}

export function getNextDraft(companyId, invoiceId) {
  return client.get(`/companies/${companyId}/supplier-invoices/${invoiceId}/get_next_draft_invoice/`);
}

export function pay(companyId, invoiceId, bankIDToken) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/pay_invoice/`, {
    bank_id_token: bankIDToken,
  });
}

export function payAtOnce(companyId, invoiceId, bankIDToken) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/pay_invoice/`, {
    bank_id_token: bankIDToken,
    pay_at_once: true,
  });
}

export function remove(companyId, invoiceId, params) {
  return client.delete(`/companies/${companyId}/supplier-invoices/${invoiceId}/`, { params });
}

export function moveDraft(companyId, invoiceId) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/draft_invoice/`);
}

export function moveFileArchive(companyId, invoiceId, getNext = false) {
  return client.patch(
    `/companies/${companyId}/supplier-invoices/${invoiceId}/move_to_file_archive/`,
    {},
    { params: { next: getNext } }
  );
}

export function manual_pay(companyId, invoiceId, data) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/manual_pay_invoice/`, data);
}

export function cancelPayment(companyId, invoiceId) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/cancel_invoice_payment/`);
}

export function creditInvoicesForDebit(companyId, debitInvoiceId) {
  return client.get(`/companies/${companyId}/supplier-invoices/${debitInvoiceId}/possible_credits_for_debit/`);
}

export function creditInvoice(companyId, debitInvoiceId, creditInvoiceId) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${debitInvoiceId}/credit_invoice/${creditInvoiceId}/`);
}

export function checkDuplicates(companyId, data) {
  return client.post(`/companies/${companyId}/supplier-invoices/check_duplicates/`, data);
}

export function toggleDeactivatePayment(companyId, invoiceId) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/toggle_deactivate_payment/`);
}

export function rescanInvoice(companyId, invoiceId) {
  return client.get(`/companies/${companyId}/supplier-invoices/${invoiceId}/extract_from_invoice/`);
}

export function approveRepresentationUpdate(companyId, invoiceId, data) {
  return client.patch(`/companies/${companyId}/supplier-invoices/${invoiceId}/update_representation_on_prelim/`, data);
}
