import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { TaxAuthorityTable } from "components/tables";
import * as tableAPI from "api/table";

function TaxAuthorityListPage() {
  const company = useOutletContext();

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId="taxAuthorities"
          dataSource={(filters, config) => tableAPI.companyTaxAuthorities(company.id, filters, config)}
        >
          <TaxAuthorityTable companyId={company.id} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default TaxAuthorityListPage;
