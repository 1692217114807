import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/loaders";
import * as statsAPI from "api/stats";
import StepProgress from "../StepProgress";

function Step4({ companyId, step, setStep }) {
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(undefined);
  const { t } = useTranslation("common");

  const checkConnection = () => {
    setLoading(true);
    statsAPI
      .testSkatteverketConnection(companyId)
      .then((response) => {
        setConnected(response.data.connected);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal.Body className="step-details step3">
        <p className="step-counter">
          <strong>{t("common:step")} 4 </strong>
          {t("common:of")} 4
        </p>
        <p className="step-info">{t("company:skat.step4")}</p>
      </Modal.Body>
      <Modal.Body>
        {loading && <Loader />}
        {!loading && connected === undefined && (
          <>
            <Button className="prev-step" variant="link" onClick={() => setStep(step - 1)}>
              <i className="fe-chevron-left" /> {t("common:actions.goBack")}
            </Button>

            <Button className="next-step" onClick={checkConnection}>
              {t("common:actions.testConnection")} <i className="fe-chevron-right" />
            </Button>
          </>
        )}
        {!loading && connected && <p className="text-success">{t("company.skat.testConnectionOk")}</p>}
        {!loading && connected === false && (
          <div>
            <p className="text-warning">
              <strong>{t("company:skat.testConnectionFail")}</strong>
            </p>
            <Button className="next-step" onClick={checkConnection}>
              {t("common:actions.testConnection")} <i className="fe-chevron-right" />
            </Button>
          </div>
        )}
        <StepProgress active={step} />
      </Modal.Body>
    </>
  );
}

export default Step4;
