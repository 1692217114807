import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DraggableModalDialog } from "../DraggableModalDialog";

function ConfirmModal({ description, giveAnswer, onConfirmCallback }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const confirm = () => {
    setLoading(true);
    onConfirmCallback()
      .then((response) => {
        giveAnswer(true);
        return response;
      })
      .catch((response) => {
        setLoading(false);
        return response;
      });
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => giveAnswer(false)}
      backdrop="static"
      dialogClassName="confirmation-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.areYouSure")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer className="space">
        <Button disabled={loading} type="button" variant="secondary" onClick={() => giveAnswer(false)}>
          {t("actions.cancel")}
        </Button>
        {loading ? (
          <Button type="button" variant="danger" disabled>
            <i className="fas fa-spinner fa-pulse mr-1" /> {t("common:actions.processing")}...
          </Button>
        ) : (
          <Button type="button" variant="danger" onClick={confirm}>
            <span>
              <i className="fas fa-exclamation mr-1" /> {t("actions.yesExecute")}
            </span>
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
