import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Form, InputGroup, ToggleButton } from "react-bootstrap";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import cx from "classnames";

import { AsyncPaginate } from "react-select-async-paginate";
import { HintButton } from "components/ui/buttons";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import { useCompanyState } from "hooks/useCompany";
import Mexp from "math-expression-evaluator";
import { toast } from "react-toastify";
import ReactTextareaAutosize from "react-textarea-autosize";
import MultiEmailInput from "./MultiEmailInput";
import MultiTextInput from "./MultiTextInput";
import { BDatePicker, BDateTimePicker, BSelect, BSelectAsync } from "./pickers";
import {
  containsOnlyAllowedCharacters,
  formatForEval,
  formatWithDecimalComma,
  formatWithDecimalPoint,
  isStartsWithEqual,
  isValidExpression,
  removeSpacesFromString,
} from "./utils/moneyCalculation";

function Asterisk() {
  return <span className="text-danger">*</span>;
}

function Group({ label, name, required, isInvalid = false, helpText, children }) {
  return (
    <Form.Group controlId={name} className={cx({ "is-invalid": isInvalid })}>
      <Form.Label>
        {label}
        {required && <Asterisk />}
        {helpText && <HintButton hint={helpText} />}
      </Form.Label>
      {children}
    </Form.Group>
  );
}

function Input({ name, type = "text", label, required = false, helpText, ...props }) {
  const [field, meta] = useField({ name, ...props });
  return (
    <Group label={label} name={name} helpText={helpText} required={required} isInvalid={meta.touched && !!meta.error}>
      <Form.Control {...field} type={type} autoComplete="off" {...props} />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function Checkbox({ name, id, label, formName, wrapperClass, ...props }) {
  const [field] = useField({ name, ...props });
  let fieldId = id || `id_${name}`;
  if (formName) {
    fieldId = `${formName}_${fieldId}`;
  }
  return (
    <div className={cx("checkbox custom-control checkbox-primary", wrapperClass)}>
      <input type="checkbox" name={name} {...field} id={fieldId} checked={field.value} {...props} />
      <label htmlFor={fieldId}>{label}</label>
    </div>
  );
}

// function Checkbox({ name, id, label, wrapperClass, formName, ...props }) {
//   let fieldId = id || `id_${name}`;
//   if (formName) {
//     fieldId = `${formName}_${fieldId}`;
//   }
//   return (
//     <div className={cx("checkbox custom-control checkbox-primary", wrapperClass)}>
//       <Field type="checkbox" className="d-none" name={name}>
//         {({ field, form }) => {
//           console.log(field, props);
//           return (
//             <input
//               {...field}
//               {...props}
//               id={fieldId}
//               onChange={(e) => form.setFieldValue(name, e.target.checked)}
//               type="checkbox"
//             />
//           );
//         }}
//       </Field>
//       <label className="custom-control-label" htmlFor={fieldId}>
//         {label}
//       </label>
//     </div>
//   );
// }

function LabeledCheckbox({ title, label, name, ...props }) {
  const [field, meta] = useField({ name, ...props });
  return (
    <Group label={title} name={name} isInvalid={!!meta.error}>
      <Checkbox label={label} name={name} id={`id_${name}`} {...props} />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={field.name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function Radio({ label, id, name, value, ...props }) {
  return (
    <div className="radio custom-control radio-primary">
      <Field type="radio" className="d-none" name={name} id={id} value={value} {...props} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

function LabeledRadio({ title, name, options, ...props }) {
  return (
    <Group label={title} name={name} {...props}>
      {options.map((option, idx) => (
        <Radio
          name={name}
          key={`${name}_${idx}`}
          id={`id_${name}_${idx}`}
          value={option.value}
          label={option.label}
          disabled={props?.disabled}
        />
      ))}
    </Group>
  );
}

function DatePicker({ label, name, required = false, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <Group label={label} name={name} isInvalid={!!meta.error} required={required}>
      <BDatePicker
        field={field}
        onChange={(date) => {
          helpers.setTouched(true);
          helpers.setValue(date);
        }}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function DateQuarterPicker({ label, name, required = false, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <Group label={label} name={name} isInvalid={!!meta.error} required={required}>
      <BDatePicker
        field={field}
        dateFormat="yyyy, QQQ"
        showQuarterYearPicker
        onChange={(date) => {
          helpers.setTouched(true);
          helpers.setValue(date);
        }}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function DateMonthPicker({ label, name, required = false, monthOnly = false, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  const dateFormat = monthOnly ? "MMMM" : "MM/yyyy";
  return (
    <Group label={label} name={name} isInvalid={!!meta.error} required={required}>
      <BDatePicker
        field={field}
        dateFormat={[dateFormat]}
        showMonthYearPicker
        onChange={(date) => {
          helpers.setTouched(true);
          helpers.setValue(date);
        }}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function DateTimePicker({ label, name, required = false, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <Group label={label} name={name} isInvalid={!!meta.error} required={required}>
      <BDateTimePicker
        field={field}
        {...props}
        onChange={(date) => {
          helpers.setTouched(true);
          helpers.setValue(date);
        }}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function BookingDatePicker({ label, id, name, required = false, ...props }) {
  const [field, { touched, error }, { setValue, setTouched, setError }] = useField({ name, ...props });
  const { t } = useTranslation("common");
  const { getForDate } = useContext(FinancialYearDispatchContext);
  const year = getForDate(field.value);

  React.useEffect(() => {
    if (!year && field.value && !error) {
      setError(t("missingFyear"));
    }
  }, [year, field.value, error, t, setError]);

  return (
    <Group label={label} name={name} isInvalid={(touched && !!error) || (field.value && !year)} required={required}>
      <BDatePicker
        field={field}
        {...props}
        onChange={(date) => {
          setTouched(true);
          setValue(date);
          if (props.onChange) {
            props.onChange(date);
          }
        }}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
      <small className="form-text fyear-info">{year && year.label}</small>
    </Group>
  );
}

function SimpleSelect({ label, name, options, required = false, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });

  const onChange = (selected) => {
    helpers.setValue(selected);
    if (props.onChange) {
      props.onChange(selected);
    }
  };

  return (
    <Group label={label} name={name} required={required} isInvalid={meta.touched && !!meta.error}>
      <BSelect
        className={cx({ "multi-select": props.isMulti })}
        placeholder=""
        field={field}
        styles={{
          ...props.styles,
          groupHeading: (base) => ({ ...base, fontSize: 14, color: "#666" }),
        }}
        {...field}
        {...props}
        options={options}
        onBlur={(e) => {
          helpers.setTouched(e);
        }}
        onChange={onChange}
        isDisabled={props.disabled || false}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={field.name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function FinancialYearPicker({ label, id, name, required = false, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  const { financialYears } = useCompanyState();
  const defaultValue = financialYears.filter((option) => option.value === field.value);

  return (
    <Group label={label} name={name} required={required} isInvalid={meta.touched && !!meta.error}>
      <BSelect
        field={field}
        {...field}
        value={defaultValue}
        {...props}
        options={financialYears}
        onBlur={(e) => {
          helpers.setTouched(e);
        }}
        onChange={(selected) => helpers.setValue(selected ? selected.value : selected)}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={field.name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function AsyncSelect({
  label,
  name,
  loadOptions,
  minSearchLength = 1,
  required = false,
  onChange,
  preventSetValue = false,
  ...props
}) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <Group label={label} name={name} required={required} isInvalid={meta.touched && !!meta.error}>
      <BSelectAsync
        field={field}
        {...props}
        loadOptions={loadOptions}
        minSearchLength={minSearchLength}
        onBlur={(e) => helpers.setTouched(e)}
        onChange={(selected) => {
          if (onChange) {
            onChange(selected);
          }
          if (!preventSetValue) {
            helpers.setValue(selected);
          }
        }}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function AsyncPaginateSelect({
  label,
  name,
  loadOptions,
  minSearchLength = 1,
  required = false,
  onChange,
  preventSetValue = false,
  ...props
}) {
  const [field, meta, helpers] = useField({ name, ...props });
  const { t } = useTranslation("common");
  return (
    <Group label={label} name={name} required={required} isInvalid={meta.touched && !!meta.error}>
      <AsyncPaginate
        field={field}
        {...props}
        additional={{ page: 1 }}
        loadOptions={loadOptions}
        minSearchLength={minSearchLength}
        onBlur={(e) => helpers.setTouched(e)}
        placeholder={t("actions.select")}
        onChange={(selected) => {
          if (onChange) {
            onChange(selected);
          }
          if (!preventSetValue) {
            helpers.setValue(selected);
          }
        }}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function MoneyInput({ label, name, required = false, decimalPlaces = 2, onChange, helpText, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <Group label={label} name={name} required={required} helpText={helpText} isInvalid={!!meta.error}>
      <NumberFormat
        name={field.name}
        thousandSeparator={" "}
        decimalSeparator=","
        autoComplete="off"
        decimalScale={decimalPlaces}
        value={field.value}
        isAllowed={({ floatValue }) => (floatValue ? floatValue <= 9999999999.99 : true)}
        className={cx(["money-input form-control", props.className])}
        fixedDecimalScale
        onBlurCapture={() => {
          helpers.setTouched(true, true);
        }}
        onValueChange={(values) => {
          helpers.setTouched(true, false);
          helpers.setValue(values.floatValue);
          if (onChange) {
            onChange(values.floatValue);
          }
        }}
        {...props}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function MoneyCalculationInput({ label, name, required = false, onChange, helpText, ...props }) {
  const { setStatus } = useFormikContext();
  const { t } = useTranslation("ci");

  const [field, meta, helpers] = useField({ name, ...props });

  const mexp = new Mexp();

  const [expression, setExpression] = useState(
    required ? field.value && formatWithDecimalComma(field.value) : formatWithDecimalComma(field.value)
  );

  function handleChange(event) {
    setStatus(false);

    const exp = removeSpacesFromString(event.target.value);

    if (!isValidExpression(exp) && exp.length) {
      return;
    }

    if (!isStartsWithEqual(exp) && containsOnlyAllowedCharacters(exp)) {
      const resultWithDecimalPoint = formatWithDecimalPoint(exp);

      helpers.setValue(resultWithDecimalPoint);
      setExpression(exp);

      return;
    }

    setExpression(exp);
  }

  function setFormInvalidToSubmit() {
    setStatus(true);
  }

  function handleSubmit(event) {
    const exp = event.target.value;

    if (isStartsWithEqual(exp)) {
      if (exp.length === 1) {
        setExpression("");
      } else {
        try {
          const expressionForEval = formatForEval(exp);

          const resultOfEval = mexp.eval(expressionForEval);

          if (!Number.isFinite(resultOfEval)) {
            setFormInvalidToSubmit();

            throw new Error(t("isNotValidAmount"));
          }

          const resultWithDecimalPoint = formatWithDecimalPoint(resultOfEval);

          const resultWithDecimalComma = formatWithDecimalComma(resultWithDecimalPoint);

          helpers.setValue(resultWithDecimalPoint);
          setExpression(resultWithDecimalComma);
        } catch (error) {
          setFormInvalidToSubmit();

          helpers.setError("Field is invalid");
          helpers.setValue(undefined);
          helpers.setTouched(true);

          toast.error(`${t("cannotRead")} "${exp}"`);
        }
      }
    } else if (exp.length && !containsOnlyAllowedCharacters(exp)) {
      setFormInvalidToSubmit();

      helpers.setError("Field is invalid");
      helpers.setValue(undefined);
      helpers.setTouched(true);

      toast.error(`"${expression}" ${t("isNotValidAmount")}`);
    } else {
      const resultWithDecimalPoint = formatWithDecimalPoint(exp);
      const resultWithDecimalComma = formatWithDecimalComma(resultWithDecimalPoint);

      if (!exp.length) {
        helpers.setError("Field is invalid");
        helpers.setValue(undefined);
        helpers.setTouched(true);

        return;
      }

      helpers.setValue(resultWithDecimalPoint);
      setExpression(resultWithDecimalComma);
    }
  }

  function handleEnterPress(event) {
    if (event.key !== "Enter") {
      return;
    }

    handleSubmit(event);
  }

  return (
    <Group label={label} name={name} required={required} helpText={helpText} isInvalid={!!meta.error}>
      <Form.Control
        type="input"
        name={field.name}
        value={expression || ""}
        disabled={props?.disabled}
        className={cx(["money-input form-control", props.className])}
        onBlur={(event) => {
          props?.onBlur?.(event);
          handleSubmit(event);
        }}
        onChange={(event) => {
          props?.onChange?.(event);
          handleChange(event);
        }}
        onKeyDown={(event) => handleEnterPress(event)}
      />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function MultiEmail({ name, label, required = false, helpText, showAddMe = true, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <Group label={label} name={name} helpText={helpText} required={required} isInvalid={meta.touched && !!meta.error}>
      <MultiEmailInput name={name} onChange={helpers.setValue} initial={field.value} showAddMe={showAddMe} />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function MultiText({ name, label, required = false, helpText, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <Group label={label} name={name} helpText={helpText} required={required} isInvalid={meta.touched && !!meta.error}>
      <MultiTextInput name={name} onChange={helpers.setValue} initial={field.value} />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </Group>
  );
}

function YesNoToggle({ name, label, className, disabled = false, ...props }) {
  const { t } = useTranslation("common");
  const [field, meta, helpers] = useField({ name, type: "checkbox", ...props });

  return (
    <Group label={label} name={name} isInvalid={meta.touched && !!meta.error}>
      <ButtonGroup toggle className={`mb-2 d-block ${className}`}>
        <ToggleButton
          type="checkbox"
          variant="outline-secondary"
          checked={!field.checked}
          value={0}
          onChange={() => helpers.setValue(false)}
          disabled={disabled}
        >
          {t("actions.no")}
        </ToggleButton>
        <ToggleButton
          type="checkbox"
          variant="outline-secondary"
          checked={field.checked}
          value={1}
          onChange={() => helpers.setValue(true)}
          disabled={disabled}
        >
          {t("actions.yes")}
        </ToggleButton>
      </ButtonGroup>
    </Group>
  );
}

function RadioToggle({ name, label, options, ...props }) {
  const [field, meta, helpers] = useField({ name, type: "checkbox", ...props });
  return (
    <Group label={label} name={name} isInvalid={meta.touched && !!meta.error}>
      <ButtonGroup toggle className="mb-2 d-block">
        {options.map((option) => (
          <ToggleButton
            key={option.value}
            type="checkbox"
            name={name}
            variant="outline-secondary"
            value={option.value}
            checked={option.value === field.value.value}
            onChange={() => helpers.setValue(option)}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Group>
  );
}

function MandrillReceiveEmailInput({ name, label, allowedDomain, required, ...props }) {
  const { t } = useTranslation("company");
  const [field, meta, helpers] = useField({ name, ...props });
  const displayValue = field.value ? field.value.split("@")[0] : field.value;
  const onChange = (e) => {
    helpers.setValue(e.currentTarget.value ? `${e.currentTarget.value}` : "");
  };

  return (
    <Group label={label} name={name} required={required} isInvalid={meta.touched && !!meta.error}>
      <InputGroup>
        <Form.Control {...field} onChange={onChange} value={displayValue} type="text" autoComplete="off" {...props} />
        <InputGroup.Append>
          <InputGroup.Text>@{allowedDomain}</InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
      {field.value && (
        <small className="form-text text-muted">
          {t("mandrillNote2", {
            mail1: `${field.value}.kvitto@${allowedDomain}`,
            mail2: `${field.value}.arkiv@${allowedDomain}`,
          })}
        </small>
      )}
    </Group>
  );
}

function TextAreaInput({ name, label, required = false, helpText, showExpandButton = true, rows = 3, ...props }) {
  const [field, meta] = useField({ name, ...props });
  const [isExpanded, setIsExpanded] = useState(false);
  const textAreaRef = useRef(null);
  const { t } = useTranslation("common");

  const initialHeight = rows * 21;
  const approxCharsPerLine = 50;
  const maxTextLength = rows * approxCharsPerLine;
  const adjustHeight = useCallback(() => {
    if (textAreaRef.current) {
      const newHeight = Math.max(textAreaRef.current.scrollHeight, initialHeight);
      textAreaRef.current.style.height = `${newHeight}px`;
    }
  }, [initialHeight]);

  useEffect(() => {
    if (isExpanded) {
      adjustHeight();
    }
  }, [isExpanded, adjustHeight, field.value]);

  const handleFocus = () => {
    setIsExpanded(true);
  };

  const handleBlur = () => {
    const isTextShort = field.value.length <= maxTextLength;
    if (!isTextShort) {
      setIsExpanded(false);
      textAreaRef.current.style.height = `${initialHeight}px`;
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setTimeout(adjustHeight, 0);
    }
  };

  const getPreviewText = useCallback(
    (text) => {
      if (isExpanded) {
        return text;
      }

      const lines = text.split("\n");
      const maxCharsPerLine = Math.floor(maxTextLength / rows);

      const shortenedByLines = lines.slice(0, rows).join("\n");

      if (shortenedByLines.length > maxTextLength) {
        return `${text.substring(0, maxTextLength)}...`;
      }
      if (lines.length > rows - 3 && text.length > maxCharsPerLine) {
        return `${shortenedByLines}...`;
      }
      return text;
    },
    [isExpanded, rows, maxTextLength]
  );

  const displayValue = getPreviewText(field.value);

  return (
    <Group label={label} name={name} required={required} isInvalid={meta.touched && !!meta.error} helpText={helpText}>
      <div className="wrapper" style={{ paddingBottom: "20px", position: "relative" }}>
        <Form.Control
          {...field}
          as="textarea"
          ref={textAreaRef}
          style={{
            height: `${isExpanded ? "auto" : initialHeight}px`,
            resize: "none",
            overflow: "hidden",
          }}
          {...props}
          value={displayValue}
          onChange={(e) => {
            field.onChange(e);
            if (isExpanded) {
              adjustHeight();
            }
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {!document.activeElement.isEqualNode(textAreaRef.current) &&
          showExpandButton &&
          field.value.length > maxTextLength && (
            <Button
              variant="link"
              onClick={toggleExpand}
              style={{
                position: "absolute",
                bottom: "-11px",
                left: "-15px",
                zIndex: 99,
                color: "#348CD4",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                letterSpacing: "-0.13px",
              }}
            >
              {isExpanded ? t("common:actions.showLess") : t("common:actions.showMore")}
              <i
                className={isExpanded ? "fas fa-chevron-up" : "fas fa-chevron-down"}
                style={{ color: "#348CD466", position: "relative", left: "6px", top: "2px" }}
              />
            </Button>
          )}
      </div>
      {meta.touched && meta.error ? (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          <ErrorMessage name={name} />
        </Form.Control.Feedback>
      ) : null}
    </Group>
  );
}

function InlineRadio({ label, id, name, value, ...props }) {
  return (
    <div
      className={cx("radio radio-inline custom-control-inline", {
        "radio-primary": !props.disabled,
        "radio-secondary": !!props.disabled,
      })}
    >
      <Field type="radio" name={name} id={id} value={value} label={label} {...props} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

function LabeledInlineRadio({ title, name, options, ...props }) {
  const [field, meta, helpers] = useField(name);

  return (
    <Group label={title} name={name} isInvalid={meta.touched && !!meta.error} {...props}>
      {options.map((option, idx) => (
        <InlineRadio
          name={name}
          key={`${name}_${idx}`}
          id={`id_${name}_${idx}`}
          value={option.value}
          label={option.label}
          disabled={props?.disabled}
          checked={field.value === option.value}
          onChange={() => helpers.setValue(option.value)}
        />
      ))}
      {meta.touched && meta.error ? (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          <ErrorMessage name={name} />
        </Form.Control.Feedback>
      ) : null}
    </Group>
  );
}

function InlineOptionsSelect({ title, name, options, label, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <div className={props.className || "inline-options-select"}>
      {label && <label className={props.labelClassName || "label"}>{label}</label>}
      <Group label={title} name={name} isInvalid={meta.touched && !!meta.error} {...props}>
        <ButtonGroup>
          {options.map((option) => (
            <Button
              key={option.value}
              className={props.btnClassName || "btn-option"}
              variant=""
              active={field.value === option.value}
              onClick={() => helpers.setValue(option.value)}
              disabled={!!props.disabled}
            >
              {field.value === option.value && <i className="fe-check" />} {option.label}
            </Button>
          ))}
        </ButtonGroup>
        {meta.touched && meta.error ? (
          <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
            <ErrorMessage name={name} />
          </Form.Control.Feedback>
        ) : null}
      </Group>
    </div>
  );
}

function LabeledInlineYesNoRadio({ title, name, ...props }) {
  const { t } = useTranslation("common");
  return (
    <LabeledInlineRadio
      title={title}
      name={name}
      options={[
        { value: "1", label: t("common:actions.yes") },
        { value: "0", label: t("common:actions.no") },
      ]}
      {...props}
    />
  );
}

function AutoHeightEditableArea({ name, label, required = false, helpText, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });

  return (
    <>
      <ReactTextareaAutosize
        disabled={props.disabled}
        className="comment commentEditable"
        onInput={(e) => {
          helpers.setValue(e.target.value);
        }}
        value={field.value}
      />
      {meta.touched && meta.error ? (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          <ErrorMessage name={name} />
        </Form.Control.Feedback>
      ) : null}
    </>
  );
}

const FormGroup = {
  Input,
  Checkbox,
  LabeledCheckbox,
  LabeledRadio,
  DatePicker,
  DateMonthPicker,
  DateTimePicker,
  BookingDatePicker,
  FinancialYearPicker,
  SimpleSelect,
  AsyncSelect,
  MoneyInput,
  MoneyCalculationInput,
  MultiEmail,
  MandrillReceiveEmailInput,
  DateQuarterPicker,
  AsyncPaginateSelect,
  YesNoToggle,
  RadioToggle,
  MultiText,
  TextAreaInput,
  LabeledInlineRadio,
  LabeledInlineYesNoRadio,
  InlineRadio,
  AutoHeightEditableArea,
  InlineOptionsSelect,
};

export default FormGroup;
