import React from "react";
import { useTranslation } from "react-i18next";
import * as selectAPI from "api/select";
import FormGroup from "./FormGroup";

function ConsultPicker({ name = "consult", companyId = null, officeId = null, ...props }) {
  const { t } = useTranslation("common");
  return (
    <FormGroup.AsyncSelect
      name={name}
      label={t("consult")}
      lazyLoad
      loadOptions={(params, callback) =>
        selectAPI.getConsults(
          {
            ...params,
            companyId,
            officeId,
            for: props.for || "",
            roles: ["consult"],
            mode: "active",
          },
          callback
        )
      }
      minSearchLength={0}
      isDisabled={props.disabled || false}
      styles={{
        menu: (provided, state) => ({
          ...provided,
          width: 350,
        }),
      }}
      {...props}
    />
  );
}

export default ConsultPicker;
