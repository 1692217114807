import React from "react";
import { useParams } from "react-router-dom";

import * as officeAPI from "api/office";
import { OfficeForm } from "components/forms/office-settings-forms";
import { withInitialAsync } from "hooks/useAsync";

function OfficeFormPage({ data: item }) {
  return <OfficeForm office={item} />;
}

const EnhancedOfficeFormPage = withInitialAsync(
  OfficeFormPage,
  () => {
    const { officeId } = useParams();
    return React.useCallback((cancelToken) => officeAPI.getOffice(officeId, { cancelToken }), [officeId]);
  },
  {},
  true
);

export default EnhancedOfficeFormPage;
