import qs from "qs";
import client from "./client";

export function balanceReport(companyId, params, config) {
  return client.get(`/reports/balance/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function accountBalance(companyId, params, config) {
  return client.get(`/reports/account-balance/`, {
    params,
    paramsSerializer: (args) => {
      return qs.stringify(args, { indices: false });
    },
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function ledgerReport(companyId, params, config) {
  return client.get(`/reports/ledger/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function profitAndLoss(companyId, params, config) {
  return client.get(`/reports/profit-loss/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function profitAndLoss12(companyId, params, config) {
  return client.get(`/reports/profit-loss-year/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
