import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import * as officeAPI from "api/office";
import { OfficeFreshdeskForm } from "components/forms/office-settings-forms";
import { withInitialAsync } from "hooks/useAsync";

function OfficeAdminPage({ data: item }) {
  return (
    <Row>
      <Col xl={4} sm={6}>
        <OfficeFreshdeskForm office={item} />
      </Col>
    </Row>
  );
}

const EnhancedOfficeAdminPage = withInitialAsync(
  OfficeAdminPage,
  () => {
    const { officeId } = useParams();
    return React.useCallback((cancelToken) => officeAPI.getOffice(officeId, { cancelToken }), [officeId]);
  },
  {},
  true
);

export default EnhancedOfficeAdminPage;
