import client from "./client";

export function get(companyId, instanceId) {
  return client.get(`/companies/${companyId}/silverback/${instanceId}/`);
}

export function save(companyId, data) {
  if (data.id) {
    return client.put(`/companies/${companyId}/silverback/${data.id}/`, data);
  }
  return client.post(`/companies/${companyId}/silverback/`, data);
}

export function remove(companyId, instanceId) {
  return client.delete(`/companies/${companyId}/silverback/${instanceId}/`);
}
