import React, { useEffect, useState } from "react";
import DraggableCore from "react-draggable";
import { Modal } from "react-bootstrap";

function DraggableModalDialog(props) {
  const [boundaries, setBoundaries] = useState();
  const { children } = props;

  const getComputedStyles = () => {
    const dialog = document.querySelector(".modal-dialog");
    const header = document.querySelector(".modal-header");
    const windowHeight = window.innerHeight;

    if (!dialog || !header) return {};

    const { marginTop, marginLeft, marginRight } = window.getComputedStyle(dialog);
    const { height } = window.getComputedStyle(header);

    const computedStyles = {
      top: marginTop,
      left: marginLeft,
      right: marginRight,
      headerHeight: height,
    };

    Object.keys(computedStyles).forEach((key) => {
      computedStyles[key] = +computedStyles[key].replace("px", "");
    });

    const { top, left, right, headerHeight } = computedStyles;

    return {
      top: -top,
      bottom: windowHeight - top - headerHeight,
      left: -left,
      right,
    };
  };

  useEffect(() => {
    const getStyles = () => {
      const bounds = getComputedStyles();
      setBoundaries(bounds);
    };

    getStyles();
    window.addEventListener("resize", getStyles);

    return () => window.removeEventListener("resize", getStyles);
  }, []);

  return (
    <DraggableCore bounds={boundaries} handle=".modal-header">
      <Modal.Dialog {...props}>{children}</Modal.Dialog>
    </DraggableCore>
  );
}

export default DraggableModalDialog;
