import React from "react";
import NavBar from "components/ui/navbar";
import CompanyListMenu from "./CompanyListMenu";

function GlobalDraftSILayout({ children }) {
  return (
    <div className="draft-si-list">
      <NavBar companyMenu={false} />
      <CompanyListMenu />
      <div className="content-page">
        <div className="content pt-3">
          <div className="container-fluid">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default GlobalDraftSILayout;
