export const PERMISSION_ROLES = ["read", "approve", "pay", "invoice", "manager", "admin"];

export const PERMISSION_MODULES = [
  {
    id: 1,
    label: {
      en: "Verification",
      sv: "Verifikationer",
    },
    enabled: {
      code: "V00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "V00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "V01",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
    ],
  },
  {
    id: 2,
    label: {
      en: "Supplier invoices",
      sv: "Leverantörsfakturor",
    },
    enabled: {
      code: "S00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "S00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Create",
          sv: "Skapa",
        },
        code: "S01",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "S02",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Approve",
          sv: "Godkänna",
        },
        code: "S03",
        roles: {
          employee: "approve",
        },
      },
      {
        label: {
          en: "Pay",
          sv: "Betala",
        },
        code: "S04",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        code: "S05",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Credit invoice",
          sv: "Kreditfaktura",
        },
        code: "S06",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        code: "S07",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Local suppliers",
          sv: "Lokala leverantörer",
        },
        code: "S08",
        roles: {
          employee: "admin",
        },
      },
    ],
  },
  {
    id: 3,
    label: {
      en: "Outlays",
      sv: "Utlägg",
    },
    enabled: {
      code: "O00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "O00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "O01",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Approve",
          sv: "Godkänna",
        },
        code: "O02",
        roles: {
          employee: "approve",
        },
      },
      {
        label: {
          en: "Pay",
          sv: "Betala",
        },
        code: "O03",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        code: "O04",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        code: "O05",
        roles: {
          employee: "pay",
        },
      },
    ],
  },
  {
    id: 4,
    label: {
      en: "Salaries",
      sv: "Löner",
    },
    enabled: {
      code: "SAL00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "SAL00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "SAL01",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Approve",
          sv: "Godkänna",
        },
        code: "SAL02",
        roles: {
          employee: "approve",
        },
      },
      {
        label: {
          en: "Pay",
          sv: "Betala",
        },
        code: "SAL03",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        code: "SAL04",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        code: "SAL05",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Salary archive",
          sv: "Lönearkiv",
        },
        code: "SAL06",
        roles: {
          employee: "read",
        },
      },
    ],
  },
  {
    id: 5,
    label: {
      en: "Customer invoices",
      sv: "Kundfakturor",
    },
    enabled: {
      code: "C00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "C00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "C01",
        roles: {
          employee: "invoice",
        },
      },
      {
        label: {
          en: "Book payment",
          sv: "Bokför betalning",
        },
        code: "C02",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Contract invoices",
          sv: "Avtalsfakturering",
        },
        code: "C03",
        roles: {
          employee: "invoice",
        },
      },
      {
        label: {
          en: "Offer & Order",
          sv: "Order & Offert",
        },
        code: "C04",
        roles: {
          employee: "invoice",
        },
      },
    ],
  },
  {
    id: 6,
    label: {
      en: "Direct payments",
      sv: "Direktbetalningar",
    },
    enabled: {
      code: "DP00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "DP00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "DP01",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Pay",
          sv: "Betala",
        },
        code: "DP02",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        code: "DP03",
        roles: {
          employee: "pay",
        },
      },
    ],
  },
  {
    id: 7,
    label: {
      en: "Taxes",
      sv: "Skatter",
    },
    enabled: {
      code: "T00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "T00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "T01",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Pay",
          sv: "Betala",
        },
        code: "T02",
        roles: {
          employee: "pay",
        },
      },
      {
        label: {
          en: "Cancel payment",
          sv: "Avbryt betalning",
        },
        code: "T03",
        roles: {
          employee: "pay",
        },
      },
    ],
  },
  {
    id: 8,
    label: {
      en: "Employee Absences",
      sv: "Avvikelserapporter",
    },
    enabled: {
      code: "EA00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "EA00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Approve",
          sv: "Godkänna",
        },
        code: "EA01",
        roles: {
          employee: "approve",
        },
      },
      {
        label: {
          en: "Download",
          sv: "Ladda ned",
        },
        code: "EA02",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
    ],
  },
  {
    id: 9,
    label: {
      en: "Time report",
      sv: "Tidrapporter",
    },
    enabled: {
      code: "TR00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "TR00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Approve",
          sv: "Godkänna",
        },
        code: "TR01",
        roles: {
          employee: "approve",
        },
      },
    ],
  },
  {
    id: 10,
    label: {
      en: "File Archive",
      sv: "Filarkiv",
    },
    enabled: {
      code: "FA00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "FA00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "FA01",
        roles: {
          employee: "manager",
        },
      },
    ],
  },
  {
    id: 11,
    label: {
      en: "Company Archive",
      sv: "Företagsarkiv",
    },
    enabled: {
      code: "CA00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "CA00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "CA01",
        roles: {
          employee: "manager",
        },
      },
    ],
  },
  {
    id: 12,
    label: {
      en: "Upload web",
      sv: "Ladda upp",
    },
    enabled: {
      code: "UP00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "UP00",
        roles: {
          employee: "read",
        },
      },
    ],
  },
  {
    id: 13,
    label: {
      en: "Company settings",
      sv: "Företagsinställningar",
    },
    enabled: {
      code: "CS00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "CS00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Edit",
          sv: "Ändra",
        },
        code: "CS01",
        roles: {
          employee: "manager",
        },
      },
      {
        label: {
          en: "Account plan",
          sv: "Kontoplan",
        },
        code: "CS02",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Bank connection",
          sv: "Bankförbindelse",
        },
        code: "CS03",
        roles: {
          employee: "manager",
        },
      },
      {
        label: {
          en: "TT",
          sv: "TT",
        },
        code: "CS04",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Bank matching rules",
          sv: "Bankmatchningsregler",
        },
        code: "CS05",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "SIE file import",
          sv: "Import av SIE",
        },
        code: "CS06",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Export",
          sv: "Export",
        },
        code: "CS07",
        roles: {
          employee: "manager",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Processing History",
          sv: "Användarhistorik",
        },
        code: "CS08",
        roles: {
          employee: "manager",
        },
        forConsult: true,
      },
    ],
  },
  {
    id: 14,
    label: {
      en: "Employees",
      sv: "Anställda",
    },
    enabled: {
      code: "CS12",
      roles: {
        employee: "manager",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "CS12",
        roles: {
          employee: "manager",
        },
      },
      {
        label: {
          en: "Edit",
          sv: "Ändra",
        },
        code: "CS14",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Delete",
          sv: "Ta bort",
        },
        code: "CS15",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Edit permissions",
          sv: "Ändra behörigheter",
        },
        code: "CS16",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Edit schedules",
          sv: "Ändra scheman",
        },
        code: "CS17",
        roles: {
          employee: "admin",
        },
      },
    ],
  },
  {
    id: 15,
    label: {
      en: "Auditors",
      sv: "Revisorer",
    },
    enabled: {
      code: "CS18",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "CS18",
        roles: {
          employee: "read",
        },
      },
    ],
  },
  {
    id: 16,
    label: {
      en: "Reports",
      sv: "Rapporter",
    },
    enabled: {
      code: "R00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "R00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Ledger",
          sv: "Huvudbok",
        },
        code: "R01",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Reconciliation",
          sv: "Avstämning",
        },
        code: "R02",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
    ],
  },
  {
    id: 17,
    label: {
      en: "Financial Years",
      sv: "Räkenskapsår",
    },
    enabled: {
      code: "FY00",
      roles: {
        employee: "admin",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "FY00",
        roles: {
          employee: "admin",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "FY01",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
      {
        label: {
          en: "Export SIE",
          sv: "Export av SIE",
        },
        code: "FY02",
        roles: {
          employee: "admin",
        },
        forConsult: true,
      },
    ],
  },
  {
    id: 18,
    label: {
      en: "Inventory register",
      sv: "Anläggningsregister",
    },
    enabled: {
      code: "IR00",
      roles: {
        employee: "read",
      },
    },
    children: [
      {
        label: {
          en: "Enabled",
          sv: "Se",
        },
        code: "IR00",
        roles: {
          employee: "read",
        },
      },
      {
        label: {
          en: "Manage",
          sv: "Hantera",
        },
        code: "IR01",
        roles: {
          employee: "admin",
        },
      },
    ],
  },
];
