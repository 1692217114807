import React, { useCallback, useContext } from "react";
import { Button, Card } from "react-bootstrap";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { TimeTrackerSettingsPage } from "pages/office-support";
import { useTranslation } from "react-i18next";
import { OnboardingDispatchContext } from "../../provider/OnboardingProvider";

function TTConfigurationStep() {
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const { t } = useTranslation("common");
  const onActivationDateSet = useCallback(() => {
    fetchDetails(true);
  }, [fetchDetails]);

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("tt/activationDateSet", onActivationDateSet);
    return () => document.body.removeEventListener("tt/activationDateSet", onActivationDateSet);
  }, [onActivationDateSet]);

  return (
    <Card className="tt-configuration-step">
      <Card.Body>
        <StepHeader
          rightComponent={
            <Button size="lg" onClick={onActivationDateSet}>
              {t("actions.goNext")}
            </Button>
          }
        />
      </Card.Body>
      <TimeTrackerSettingsPage />
    </Card>
  );
}

export default TTConfigurationStep;
