import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { CostCenterExtendedTable } from "components/tables";
import * as tableAPI from "api/table";
import { CostCenterAddWidget } from "components/forms/company-settings-forms/CostCenterForm";
import { CodeRequiredInList } from "components/perms";
import { codesForCompanySettings } from "components/perms/PermCodes";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";

function CostCenterListPage({ tableId = "costCenters" }) {
  const { companyId } = useParams();
  const initialFilters = {
    mode: "active",
  };
  const { userPermCodes } = useContext(CompanySettingStateContext);

  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => tableAPI.companyCostCenters(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <CostCenterExtendedTable companyId={companyId} />
        </Card.Body>
      </Card>
      <CodeRequiredInList code={codesForCompanySettings.edit} codeList={userPermCodes}>
        <CostCenterAddWidget companyId={companyId} />
      </CodeRequiredInList>
    </TableProvider>
  );
}

export default CostCenterListPage;
