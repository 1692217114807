import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { parseDate } from "utils/date";
import OfficeSupportBusinessProfileEssentialForm from "components/forms/OfficeSupportForms/OfficeSupportBusinessProfileEssentialForm/OfficeSupportBusinessProfileEssentialForm";
import OfficeSupportBusinessProfileAdditionalForm from "components/forms/OfficeSupportForms/OfficeSupportBusinessProfileAdditionalForm/OfficeSupportBusinessProfileAdditionalForm";
import { RocketLoader } from "components/ui/loaders";
import * as officeSupportApi from "api/office-support";
import useInitialAsync from "hooks/useInitialAsync";
import { toast } from "react-toastify";
import { checkFormState } from "utils/forms";
import UnsavedWarn from "components/ui/UnsavedWarn";
import { UpToDateToggleButton } from "components/ui/buttons/ToggleButton";

function BusinessProfilePage() {
  const { company, tabIndicators, reloadIndicators } = useOutletContext();
  const { t } = useTranslation("company");
  const { loading, item, set } = useInitialAsync(() => {
    return officeSupportApi.businessProfile.get(company.id);
  });
  const [editedForms, setEditedForms] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [checkedToggle, setCheckedToggle] = useState(false);
  const [checkIndicators, setCheckIndicators] = useState(false);
  const changeEditedForm = (formState) => {
    setEditedForms(checkFormState(formState, editedForms));
    setEditMode(editedForms.length > 0);
    if (editMode) {
      setCheckIndicators(true);
    } else {
      if (checkIndicators) {
        reloadIndicators();
      }
      setCheckIndicators(false);
    }
  };

  async function toggleEvent() {
    await officeSupportApi.statusIndicator
      .update(company.id, {
        business_profile_up_to_date: !checkedToggle,
      })
      .then((response) => {
        reloadIndicators();
        toast.success(t("msg:saved"), { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error(error.data, { autoClose: 5000 });
      });
  }
  React.useEffect(() => {
    setCheckedToggle(tabIndicators.business_profile?.up_to_date);
  }, [tabIndicators]);

  if (loading) {
    return <RocketLoader />;
  }
  const refreshProfileData = async () => {
    const updatedData = await officeSupportApi.businessProfile.get(company.id);
    set(updatedData.data);
  };
  const formatLastEdited = (lastEdited, createdBy) => {
    const editorName = createdBy;
    const formattedDate = format(parseISO(lastEdited), "yyyy-MM-dd HH:mm");
    return `${editorName} ${formattedDate}`;
  };
  const lastEditedInfo =
    item.last_edited && item.created_by_name ? formatLastEdited(item.last_edited, item.created_by_name) : null;
  const essentialData = { description: item?.description || "" };
  const additionalData = {
    registration_date: item?.registration_date ? parseDate(item.registration_date) : null,
    conducted: item?.conducted || "",
    staff: item?.staff || "",
    owner_structure: item?.owner_structure || "",
    owner_vision: item?.owner_vision || "",
    extra_attention: item?.extra_attention || "",
  };

  return (
    <Card>
      <UnsavedWarn isUnsaved={editMode} />
      <Card.Body>
        <Row>
          <Col>
            {lastEditedInfo && (
              <div className="header-info">
                {t("other.lastEditedInfo")} {lastEditedInfo}
              </div>
            )}
          </Col>
          <Col>
            <UpToDateToggleButton
              onClickEvent={() => toggleEvent()}
              checked={checkedToggle}
              disabled={!tabIndicators.business_profile?.allow_toggle}
              tooltipText={t("msg:missingInformations")}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <OfficeSupportBusinessProfileEssentialForm
              companyId={company.id}
              initialData={essentialData}
              changeEditedForm={changeEditedForm}
              refreshProfileData={refreshProfileData}
            />
          </Col>
          <Col lg={6}>
            <OfficeSupportBusinessProfileAdditionalForm
              companyId={company.id}
              initialData={additionalData}
              changeEditedForm={changeEditedForm}
              refreshProfileData={refreshProfileData}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default BusinessProfilePage;
