import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { CustomerEditWidget } from "components/forms/CustomerForm";
import Filters from "./CustomerFilters";

function CustomerExtendedTable({ companyId, onboarding = false }) {
  const { t } = useTranslation("ci");
  const headers = useMemo(
    () => [
      {
        field: "DebtorNo",
        label: t("customerNumber"),
      },
      {
        field: "Name",
        label: t("common:name"),
      },
      {
        field: "OrgNo",
        label: t("common:orgNo"),
      },
      {
        field: "ContactName",
        label: t("common:contact.contactName"),
      },
      {
        field: "Email",
        label: t("common:contact.contactEmail"),
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return { className: row.IsActive === true ? "paid" : "inactive" };
  };

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          idKey="DebtorPublicId"
          getRowProps={getRowProps}
          FiltersComponent={<Filters companyId={companyId} onboarding={onboarding} />}
          renderExpandedRow={(row) => <CustomerEditWidget companyId={companyId} customer={row} />}
        />
      </Col>
    </Row>
  );
}

export default CustomerExtendedTable;
