import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuthState } from "hooks/useAuth";

function ConditionalOverlayTrigger({ placement, children, useSpan = false }) {
  const { t } = useTranslation("company");
  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  const overlay = (
    <Tooltip className="step-status-tooltip" id="disabledField">
      {t(`goToOfficeSupportForEdit`)}
    </Tooltip>
  );
  if (isConsult) {
    return (
      <OverlayTrigger overlay={overlay} placement={placement}>
        {useSpan ? <span style={{ pointerEvents: "auto" }}>{children}</span> : children}
      </OverlayTrigger>
    );
  }
  return children;
}

export default ConditionalOverlayTrigger;
