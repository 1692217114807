import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { formatDatetime } from "utils/date";
import useTable from "components/tables/btable/useTable";
import { FileArchiveDocumentsLink } from "components/ui/buttons/DocumentsButton";
import Filters from "./FileArchiveFilters";
import Actions from "./FileArchiveActions";

function FileArchiveTable({ companyId, selectable = false }) {
  const { t } = useTranslation("common");
  const {
    filters,
    dataActions: { reload },
  } = useTable();

  function handleVerificationSaved(event) {
    // After close verification modal we need to update table  with new/changed verifications
    const savedVer = event.detail;
    if (savedVer.archive) {
      reload();
    }
  }

  const headers = useMemo(
    () => [
      {
        field: "title",
        label: t("common:name"),
        canSort: false,
        render: (values) => (
          <FileArchiveDocumentsLink
            companyId={companyId}
            archiveId={values.id}
            text={values.title || "-"}
            filters={filters}
          />
        ),
      },
      {
        field: "file_type",
        label: t("common:file.fileType"),
      },
      {
        field: "created",
        label: t("common:created"),
        canSort: true,
        render: (values) => formatDatetime(values.created),
      },
    ],
    [t, companyId, filters]
  );

  useEffect(() => {
    // After close verification modal we need to update ledger with new/changed verifications
    document.body.addEventListener("verification/saved", handleVerificationSaved);
    return () => document.body.removeEventListener("verification/saved", handleVerificationSaved);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.total")}:</strong>
      {responseHeaders["x-pagination-count"]}
    </div>
  );

  const getRowProps = (row) => {
    return {
      className: cx(row.status, { "has-message": !!row.comments.length }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
      getRowProps={getRowProps}
    />
  );
}

export default FileArchiveTable;
