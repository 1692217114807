import React from "react";
import { withTranslation } from "react-i18next";

function Loader({ t }) {
  return (
    <div className="loader">
      <div>
        <i className="fas fa-2x fa-spin fa-spinner" />
      </div>
      <p className="text-muted">{t("loading")}...</p>
    </div>
  );
}

export default withTranslation()(Loader);
