import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { formatDuration, formatMonth } from "utils/date";
import BTable from "components/tables/btable";
import Filters from "./ConsultTTReportFilters";

function ConsultTTReportTable({ options }) {
  const { t } = useTranslation("tt");

  const headers = useMemo(
    () => [
      {
        field: "company_name",
        label: t("common:company"),
      },
      {
        field: "month_holder",
        label: t("common:dates.month"),
        render: (values) => formatMonth(values.month_holder),
      },
      {
        field: "consult_name",
        label: t("common:consult"),
      },
      {
        field: "time_spent",
        label: t("timeSpent"),
        render: (values) => formatDuration(values.time_spent),
      },
      {
        field: "max_time",
        label: t("expectedMaxTime"),
      },
      {
        field: "percentage",
        label: t("percentage"),
        canSort: false,
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return { className: row.percentage > 90 ? "inactive" : "active" };
  };

  return (
    <BTable
      headers={headers}
      FiltersComponent={<Filters options={options} />}
      renderActions={null}
      getRowProps={getRowProps}
    />
  );
}

export default ConsultTTReportTable;
