import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import AssetForm from "./AssetForm";

function AssetAddWidget({ companyId }) {
  const {
    dataActions: { reload },
  } = useTable();
  const { t } = useTranslation("asset");
  const asset = { config: {} };
  return (
    <Row>
      <Col>
        <Card className="card-border" id="create-asset-section">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.createAsset")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <AssetForm companyId={companyId} asset={asset} successCallback={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default AssetAddWidget;
