export function formatMoney(amount, fraction = 2, maxFraction = 6, initial = 0) {
  if (!amount) {
    return initial;
  }
  return Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: fraction,
    maximumFractionDigits: maxFraction,
  }).format(amount);
}

export function formatMoneyRounding(amount, rounding, initial = 0) {
  if (!amount) {
    return initial;
  }
  const roundingStr = `${rounding}`;
  let amountFinal = amount;
  let fraction;
  let maxFraction = 0;
  if (roundingStr === "1") {
    fraction = 0;
    maxFraction = 0;
    amountFinal /= 1000;
  } else if (roundingStr === "2") {
    fraction = 0;
    maxFraction = 0;
  } else {
    fraction = 2;
    maxFraction = 2;
  }
  return Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: fraction,
    maximumFractionDigits: maxFraction,
  }).format(amountFinal);
}

export function roundMoney(amount, precision = 2) {
  // TODO: check if can be replaced. VerificationForm usage.
  return parseFloat(parseFloat(amount).toFixed(precision));
}
