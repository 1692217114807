import React, { useEffect, useRef, useState } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatMoney, roundMoney } from "utils/money";
import { useVerificationDispatch } from "hooks/useVerification";
import _ from "lodash";
import { toast } from "react-toastify";
import { parseDate } from "utils/date";
import * as bankMatchingAPI from "api/bankMatching";
import { StaffPermRequired } from "components/perms";
import { TooltipActionButton } from "components/ui/buttons";
import { TDCheckInput, THCheckInput } from "./CheckInputs";

function VerificationButton({ transaction, text, label, variant = "secondary", onClick }) {
  const click = () => {
    onClick(transaction);
  };

  return (
    <OverlayTrigger
      trigger={["hover", "click"]}
      placement="top"
      overlay={<Tooltip id={String(_.uniqueId("tooltip_"))}>{text}</Tooltip>}
    >
      <Button onClick={click} variant={variant} size="sm">
        {label}
      </Button>
    </OverlayTrigger>
  );
}

function BankTransTable({ data, set, matchOnVerCreate, companyId, reloadTable }) {
  const { t } = useTranslation("common");
  const lastUsedTransactionRef = useRef(null);
  const [checkedAll, setCheckedAll] = useState(false);
  const { open: openVerificationModal } = useVerificationDispatch();

  async function toggleSelect(item, index) {
    const newData = { ...data };
    const trans = newData.bank[index];
    newData.bank[index].checked = !trans.checked;
    set(newData);

    if (trans.matched) {
      await bankMatchingAPI
        .undoBankMatching(companyId, {
          id: trans.id,
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
      reloadTable();
    }
  }

  function handleVerificationClose(event) {
    lastUsedTransactionRef.current = null;
  }

  function handleVerificationSaved(event) {
    const savedVer = event.detail;
    const lastUsedTransaction = lastUsedTransactionRef.current;
    if (!lastUsedTransaction || !savedVer) {
      return;
    }
    if (!lastUsedTransaction) {
      reloadTable();
      return;
    }
    const bflowTrans = savedVer.transactions
      .filter((tr) => tr.account === lastUsedTransaction.account_id)
      .map((tr) => tr.id);
    matchOnVerCreate(bflowTrans, lastUsedTransaction.id);
  }

  useEffect(() => {
    // After close verification modal we need to update ledger with new/changed verifications
    document.body.addEventListener("verification/closed", handleVerificationClose);

    document.body.addEventListener("verification/saved", handleVerificationSaved);
    return () => document.body.removeEventListener("verification/saved", handleVerificationSaved);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModalB = (transaction) => {
    lastUsedTransactionRef.current = transaction;
    const amountSEK = roundMoney(transaction.amount * transaction.exchange_rate);
    openVerificationModal(transaction.company_id, {
      booking_date: parseDate(transaction.booking_date),
      amount: amountSEK,
      title: transaction.text,
      transactions: [
        {
          credit: amountSEK < 0 ? Math.abs(amountSEK) : null,
          debit: amountSEK > 0 ? amountSEK : null,
          amount: amountSEK,
          account: transaction.account_id,
          title: "",
          is_deleted: false,
        },
        {
          credit: amountSEK > 0 ? amountSEK : null,
          debit: amountSEK < 0 ? Math.abs(amountSEK) : null,
          amount: amountSEK,
          account: transaction.account_id === 1630 ? 8423 : 6570,
          title: "",
          is_deleted: false,
        },
        {
          credit: null,
          debit: null,
          amount: 0,
          title: "",
          is_deleted: false,
        },
      ],
    });
  };

  const openModalU = (transaction) => {
    lastUsedTransactionRef.current = transaction;
    const amountSEK = roundMoney(transaction.amount * transaction.exchange_rate);
    openVerificationModal(transaction.company_id, {
      booking_date: parseDate(transaction.booking_date),
      amount: amountSEK,
      title: transaction.text,
      transactions: [
        {
          credit: amountSEK > 0 ? amountSEK : null,
          debit: amountSEK < 0 ? Math.abs(amountSEK) : null,
          amount: amountSEK,
          account: 2999,
          title: "",
          is_deleted: false,
        },
        {
          credit: amountSEK < 0 ? Math.abs(amountSEK) : null,
          debit: amountSEK > 0 ? amountSEK : null,
          amount: amountSEK,
          account: transaction.account_id,
          title: "",
          is_deleted: false,
        },
        {
          credit: null,
          debit: null,
          amount: 0,
          title: "",
          is_deleted: false,
        },
      ],
    });
  };

  const openModalM = (transaction) => {
    lastUsedTransactionRef.current = transaction;
    const amountSEK = roundMoney(transaction.amount * transaction.exchange_rate);
    openVerificationModal(transaction.company_id, {
      booking_date: parseDate(transaction.booking_date),
      amount: amountSEK,
      title: transaction.text,
      transactions: [
        {
          credit: amountSEK < 0 ? Math.abs(amountSEK) : null,
          debit: amountSEK > 0 ? amountSEK : null,
          amount: amountSEK,
          account: transaction.account_id,
          title: "",
          is_deleted: false,
        },
        {
          credit: null,
          debit: null,
          amount: 0,
          title: "",
          is_deleted: false,
        },
      ],
    });
  };

  const selectAll = () => {
    setCheckedAll(!checkedAll);
    const newData = {
      ...data,
      bank: data.bank.map((trans) => ({
        ...trans,
        checked: trans.matched ? false : !checkedAll,
      })),
    };
    set(newData);
  };

  const deleteBankTransaction = (transId) => {
    bankMatchingAPI
      .removeBankTransaction(companyId, transId)
      .then(() => {
        reloadTable();
      })
      .catch(() => {});
  };

  return (
    <>
      <h6>{t("common:transactionsFromBank")}</h6>
      <div className="scroller">
        <Table bordered size="sm" className="sticky-header">
          <thead>
            <tr>
              <THCheckInput
                checked={checkedAll}
                toggleSelect={selectAll}
                label=""
                className="th-bank-checkbox"
                style={{ width: 40 }}
              />
              <th>{t("common:dates.date")}</th>
              <th>{t("common:description")}</th>
              <th className="text-right">{t("common:money.amount")}</th>
              <th className="text-right">{t("common:money.balance")}</th>
              <th style={{ width: 1 }} />
            </tr>
          </thead>
          <tbody>
            {!data.bank.length && (
              <tr>
                <td colSpan={4}>{t("common:noResultsFound")}</td>
              </tr>
            )}
            {data.bank.map((trans, index) => (
              <React.Fragment key={trans.id}>
                <tr>
                  <TDCheckInput trans={trans} toggleSelect={() => toggleSelect(trans, index)} />
                  <td>{trans.booking_date}</td>
                  {trans.is_ci_payment ? (
                    <td title={trans.text} className="warning-corner">
                      {trans.debtor_name || trans.text}
                    </td>
                  ) : (
                    <td>{trans.text || "-"}</td>
                  )}
                  <td className="text-right" title={`${formatMoney(trans.amount)} ${trans.currency}`}>
                    <small>{formatMoney(roundMoney(trans.amount * trans.exchange_rate))} SEK</small>
                  </td>
                  <td className="text-right">
                    <small>{formatMoney(roundMoney(trans.balance))}</small>
                  </td>
                  <td className="td-actions text-right">
                    {!trans.matched && (
                      <>
                        {trans.account_id === 1630 ? (
                          <VerificationButton
                            transaction={trans}
                            text={`${t("tax:interest")[0]} - ${t("tax:interest")}`}
                            label={`${t("tax:interest")[0]}`}
                            onClick={openModalB}
                          />
                        ) : (
                          <VerificationButton
                            transaction={trans}
                            text={`${t("si:bankFees")[0]} - ${t("si:bankFees")}`}
                            label={`${t("si:bankFees")[0]}`}
                            onClick={openModalB}
                          />
                        )}
                        <VerificationButton
                          transaction={trans}
                          text={`${t("si:unidentifiedPayment")[0]} - ${t("si:unidentifiedPayment")}`}
                          label={`${t("si:unidentifiedPayment")[0]}`}
                          onClick={openModalU}
                        />
                        <VerificationButton
                          transaction={trans}
                          text={`${t("si:manualBooking")[0]} - ${t("si:manualBooking")}`}
                          label={`${t("si:manualBooking")[0]}`}
                          onClick={openModalM}
                        />
                      </>
                    )}
                    <StaffPermRequired>
                      <TooltipActionButton
                        variant="danger"
                        text={t("common:actions.remove")}
                        onClick={() => deleteBankTransaction(trans.id)}
                        icon="fas fa-trash"
                      />
                    </StaffPermRequired>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default BankTransTable;
