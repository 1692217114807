import React from "react";

import * as archiveAPI from "api/archive";
import useTable from "components/tables/btable/useTable";
import BaseCommentsModal from "./BaseCommentsModal";

function DocumentCommentsModal({ handleClose, companyId, document }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const onClose = (comments) => {
    handleClose();
    updateRow({ ...document, comments });
  };

  return (
    <BaseCommentsModal
      handleClose={onClose}
      companyId={companyId}
      itemId={document.id}
      apiCreate={archiveAPI.comment.create}
      apiList={archiveAPI.comment.list}
      apiRemove={archiveAPI.comment.remove}
    />
  );
}

export default DocumentCommentsModal;
