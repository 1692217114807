import React from "react";
import { withTranslation } from "react-i18next";

import rocketSrc from "assets/images/animat-rocket-color.gif";

function RocketLoader({ t }) {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="text-center mt-5">
          <div className="text-center mt-5 my-5">
            <img src={rocketSrc} alt="" height="200" />
            <p className="text-muted">{t("loading")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(RocketLoader);
