import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { SearchFilter } from "components/filters";
import { PermCodeRequired } from "components/perms";
import { codesForDirectPayments } from "components/perms/PermCodes";

function DirectPaymentFilters({ companyId, forStatus, allowedModes }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const show = {
    createBtn: !forStatus || (forStatus && forStatus === "unpaid"),
  };

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        {forStatus && allowedModes && (
          <div className="status-filter">
            {allowedModes.map((mode) => (
              <Button
                key={mode.value}
                variant={`outline-${mode.variant}`}
                active={filters.mode === mode.value}
                onClick={() => setFilters({ mode: mode.value })}
              >
                {t(mode.label)}
              </Button>
            ))}
          </div>
        )}
        {forStatus && !allowedModes && (
          <Button variant={forStatus} onClick={() => {}}>
            {t(`common:statuses.${forStatus}`)}
          </Button>
        )}
        {!forStatus && (
          <ButtonGroup aria-label="Status filter" className="status-filter">
            <Button
              variant="outline-secondary"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="outline-unpaid"
              active={filters.mode === "unpaid"}
              onClick={() => setFilters({ mode: "unpaid" })}
            >
              {t("common:statuses.unpaid")}
            </Button>
            <Button
              variant="outline-sent-bank"
              active={filters.mode === "sent_bank"}
              onClick={() => setFilters({ mode: "sent_bank" })}
            >
              {t("common:statuses.sentBank")}
            </Button>
            <Button
              variant="outline-paid"
              active={filters.mode === "paid"}
              onClick={() => setFilters({ mode: "paid" })}
            >
              {t("common:statuses.paid")}
            </Button>
          </ButtonGroup>
        )}
      </section>
      <section className="table-filters-right">
        <PermCodeRequired code={codesForDirectPayments.manage}>
          {show.createBtn && (
            <Link to="/pay/direct-payments/form" className="btn btn-first">
              <i className="fas fa-plus" /> {t("common:actions.createPayment")}
            </Link>
          )}
        </PermCodeRequired>
      </section>
    </>
  );
}

export default DirectPaymentFilters;
