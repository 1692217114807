import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { useModalScroll } from "components/modals/hooks/useModalScroll";
import GlobalSupplierForm from "./GlobalSupplierForm";

function GlobalSupplierAddModal({ handleClose }) {
  const { shouldScroll } = useModalScroll();

  const supplier = {
    name: "",
    alias_name: "",
    org_no: "",
    bankgiro_number: "",
    vat_number: "",
    contact_email: "",
    bic: "",
    iban: "",
    plusgiro_number: "",
    clearing_number: "",
    bank_account_number: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    bank_name: "",
    bank_code: "",
  };

  useEffect(() => {
    document.body.classList.add("scrollable");
    return () => {
      document.body.classList.remove("scrollable");
    };
  }, []);

  const { t } = useTranslation("company");
  return (
    <Modal
      show
      onHide={handleClose}
      size="lg"
      className="global-supplier-modal"
      tabIndex="-1"
      dialogAs={DraggableModalDialog}
      scrollable={shouldScroll}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("si:actions.addGlobalSupplier")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GlobalSupplierForm supplier={supplier} successCallback={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

export default GlobalSupplierAddModal;
