import { Modal } from "react-bootstrap";
import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { differenceInSeconds, formatISO, isAfter, isSameDay } from "date-fns";

import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import { formatDuration, parseDate } from "utils/date";
import * as trackerAPI from "api/time-tracker";
import { AllError, FormGroup } from "components/formik";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { useModalScroll } from "../hooks/useModalScroll";

function EditTTLogModal({ timeLog, handleClose }) {
  const { t } = useTranslation("tt");
  const { shouldScroll } = useModalScroll();

  const formikProps = {
    initialValues: {
      started: parseDate(timeLog.started),
      stopped: parseDate(timeLog.stopped),
    },
    validationSchema: yup
      .object()
      .shape({
        started: yup.date().nullable().required(),
        stopped: yup.date().nullable().required(),
      })
      .test("validDates", function (values) {
        if (values.started && values.stopped && isAfter(values.started, values.stopped)) {
          return this.createError({
            path: "stopped",
            message: t("common:errors.mustLaterStartDate"),
          });
        }

        if (values.started && values.stopped && !isSameDay(values.started, values.stopped)) {
          return this.createError({
            path: "stopped",
            message: t("common:errors.mustBeWithinSameDay"),
          });
        }

        return true;
      }),
    onSubmit: async (values, { setErrors }) => {
      const started = formatISO(values.started);
      const stopped = formatISO(values.stopped);
      await trackerAPI
        .updateTimeLog(timeLog.id, {
          started,
          stopped,
        })
        .then((response) => {
          handleClose(response.data);
        })
        .catch((error) => {
          // errors
          if (error.status === 400) {
            setErrors(error.data);
          }
        });
    },
  };

  return (
    <Modal show onHide={handleClose} animation={false} dialogAs={DraggableModalDialog} scrollable={shouldScroll}>
      <Formik {...formikProps}>
        {({ values, isSubmitting, errors }) => {
          const duration = values.started && values.stopped ? differenceInSeconds(values.stopped, values.started) : 0;
          return (
            <Form noValidate>
              <Modal.Header closeButton>
                <Modal.Title as="h4">{timeLog.task_title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup.DateTimePicker label={t("started")} name="started" />
                <FormGroup.DateTimePicker label={t("stopped")} name="stopped" />
                <strong>
                  Duration:
                  <br />
                  {duration > 0 ? formatDuration(duration) : 0}
                </strong>
                <AllError errors={errors} />
              </Modal.Body>
              <Modal.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default EditTTLogModal;
