import React, { useCallback, useEffect, useMemo, useState } from "react";

import * as statsAPI from "api/stats";

const MenuNumberStateContext = React.createContext();
const MenuNumberDispatchContext = React.createContext();

const initialState = {
  draft: {
    outlays: 0,
  },
  prelim: {
    supplier_invoices: 0,
    outlays: 0,
    salaries: 0,
    employee_absences: 0,
    count: 0,
  },
  unpaid: {
    supplier_invoices: 0,
    outlays: 0,
    salaries: 0,
    direct_payments: 0,
    taxes: 0,
    count: 0,
  },
  sent_bank: {
    supplier_invoices: 0,
    outlays: 0,
    salaries: 0,
    direct_payments: 0,
    taxes: 0,
    count: 0,
  },
  consult: {
    bank_matching: 0,
    draft_si: 0,
    file_archive: 0,
  },
};

function MenuNumberProvider({ children }) {
  const [number, setNumbers] = useState(initialState);
  const companyId = localStorage.getItem("lastCompanyId");

  const reload = useCallback(() => {
    if (!companyId) {
      return;
    }
    statsAPI
      .menuNumbers(companyId)
      .then((response) => {
        setNumbers(response.data);
      })
      .catch(() => {});
  }, [companyId]);

  useEffect(() => {
    reload();
  }, [reload]);

  const dispatchValue = useMemo(
    () => ({
      reload,
      setNumbers,
    }),
    [reload]
  );

  return (
    <MenuNumberStateContext.Provider value={number}>
      <MenuNumberDispatchContext.Provider value={dispatchValue}>{children}</MenuNumberDispatchContext.Provider>
    </MenuNumberStateContext.Provider>
  );
}

export { MenuNumberStateContext, MenuNumberDispatchContext };

export default MenuNumberProvider;
