import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { useQuery } from "utils/others";
import PasswordResetForm from "./PasswordResetForm";

function PasswordResetPage() {
  const { t } = useTranslation("others");
  const queryParams = useQuery();
  const token = queryParams.get("token");

  if (!token) {
    return <div className="text-center">Malformed</div>;
  }

  return (
    <>
      <div className="text-center">
        <p className="text-muted mb-4">
          <Trans t={t} i18nKey="auth.introReset">
            {t("auth.toResetPasswordPartOne")}
            <br />
            {t("auth.toResetPasswordPartTwo")}.
          </Trans>
        </p>
      </div>
      <PasswordResetForm token={token} />
    </>
  );
}

export default PasswordResetPage;
