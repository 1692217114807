import React, { useContext, useMemo } from "react";
import { Link, useResolvedPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import { formatMoney } from "utils/money";
import { codesFor, HasPermCode } from "components/perms";
import { SelectionContext } from "state/providers/SelectionProvider";
import Filters from "./CustomerInvoiceFilters";
import Actions from "./CustomerInvoiceActions";
import useTable from "../btable/useTable";

function UnpaidSelectionCounter() {
  const { t } = useTranslation("common");
  const { filters } = useTable();
  const { getFlatten } = useContext(SelectionContext);
  const unpaidTotal = getFlatten().reduce((total, invoice) => total + invoice.balance, 0);
  if (filters.mode !== "open") {
    return null;
  }
  return (
    <div className="selected-to-pay-box">
      <strong>{t("money.toPaySelected")}:</strong>
      {formatMoney(unpaidTotal)}
    </div>
  );
}

function CustomerInvoiceTable({ companyId, selectable }) {
  const { t } = useTranslation("ci");
  const url = useResolvedPath("").pathname;
  const canEdit = HasPermCode(codesFor.customerInvoices.manage);

  const headers = useMemo(
    () => [
      {
        field: "invoice_no",
        sortField: ["invoice_no2"],
        label: t("common:invoiceNo"),
        render: (values) => {
          if (values.status !== 0 || !canEdit || values.is_fortnox_voided) {
            return (
              <Link to={`${url}/${values.id}/preview`} className="table-link">
                {values.invoice_no || t("common:notSet")} <i className="fa fa-search" />
              </Link>
            );
          }
          return (
            <Link to={`${url}/${values.id}/change`} className="table-link">
              {values.invoice_no || t("common:notSet")} <i className="fas fa-pen" />
            </Link>
          );
        },
      },
      {
        field: "customer_snapshot",
        label: t("common:customer"),
        sortField: "customer_name",
        render: (values) =>
          values.customer_snapshot ? (
            <span title={values.customer_snapshot.Name}>{truncateText(values.customer_snapshot.Name, 20)}</span>
          ) : (
            "-"
          ),
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        sortField: "amount_sek",
        className: "text-right",
        render: (value) => `${formatMoney(value.amount)} ${value.amount_currency}`,
      },
      {
        field: "balance",
        label: t("common:money.toPay"),
        className: "text-right",
        render: (value) => `${formatMoney(value.balance / value.exchange_rate, 2, 2)} ${value.amount_currency}`,
      },
      {
        field: "booking_date",
        label: t("common:dates.invoiceDate"),
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
      {
        field: "payment_date",
        label: t("common:dates.paymentDate"),
      },
    ],
    [canEdit, t, url]
  );

  const getRowProps = (row) => {
    return {
      className: cx(row.sub_status || "draft", {
        "has-problem": row.einvoice_status === "failed" || row.delivery_error,
        "has-message": !!row.comments.length,
        voided: row.is_fortnox_voided,
      }),
    };
  };

  const renderTotals = (responseHeaders) => {
    return (
      <>
        <div>
          <strong>{t("common:money.amount")}:</strong>
          {formatMoney(responseHeaders["x-pagination-amount"])}
        </div>
        <div>
          <strong>{t("common:money.toPay")}:</strong>
          {formatMoney(responseHeaders["x-pagination-balance"])}
        </div>
        <UnpaidSelectionCounter />
      </>
    );
  };

  const renderSelect = (row, filters) => {
    if (row.sub_status === "draft") {
      return undefined;
    }
    if (filters.mode === "open") {
      return undefined;
    }
    return "";
  };

  return (
    <div id="customer-invoice-table">
      <BTable
        headers={headers}
        selectable={selectable}
        FiltersComponent={<Filters companyId={companyId} />}
        renderActions={(row) => <Actions companyId={companyId} row={row} />}
        renderTotals={renderTotals}
        getRowProps={getRowProps}
        renderSelect={renderSelect}
      />
    </div>
  );
}

export default CustomerInvoiceTable;
