import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TooltipActionButton } from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import * as archiveAPI from "api/archive";
import useTable from "components/tables/btable/useTable";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";
import useModal from "hooks/useModal";

function TrashActions({ row }) {
  const { t } = useTranslation("common");
  const {
    dataActions: { reload },
  } = useTable();
  const fileModal = useModal();

  const openFileModal = () => {
    fileModal.open(`/trash/${row.id}/file/`);
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("others:confirm.removeFileArchive", { title: row.title }));
    if (answer) {
      archiveAPI.trashDocument
        .remove(row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  return (
    <>
      <TooltipActionButton text={t("common:documents")} onClick={openFileModal} icon="fas fa-images" />
      <TooltipActionButton variant="danger" text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      {fileModal.show && <FileUrlModalOld fileUrl={fileModal.data} handleClose={fileModal.close} />}
    </>
  );
}

export default TrashActions;
