export function truncateText(text, maxLength = 25) {
  return (text && text.length) > maxLength ? `${text.substr(0, maxLength)}...` : text;
}

export function humanFileSize(size) {
  const display = size / 1000000;
  if (display < 1) {
    return `${(size / 1000).toFixed(1)}kb`;
  }
  return `${display.toFixed(1)}mb`;
}

export function slugify(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}
