import React, { useContext } from "react";
import * as tableAPI from "api/table";
import { Card } from "react-bootstrap";
import TableProvider from "components/tables/btable/provider";
import { TimeLogTTTable } from "components/tables";
import { useAuthState } from "hooks/useAuth";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";

function TimeTrackerLogsPage({ tableId = "trackerTimeLogs" }) {
  const { user } = useAuthState();
  const { selected: selectedOffice, options } = useContext(TimeTrackerStateContext);

  const initialFilters = {
    users: [user.id],
    companies: [],
    ordering: "-started",
    work_time_only: false,
  };

  return (
    <TableProvider
      tableId={tableId}
      initialFilters={initialFilters}
      dataSource={(filtersTable, config) =>
        tableAPI.trackerTimeLogs({ ...filtersTable, office: selectedOffice.office }, config)
      }
    >
      <Card>
        <Card.Body>
          <TimeLogTTTable options={options} selectedOffice={selectedOffice} />
        </Card.Body>
      </Card>
    </TableProvider>
  );
}

export default TimeTrackerLogsPage;
