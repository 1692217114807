import React from "react";
import { useTranslation } from "react-i18next";
import { Alert, Modal } from "react-bootstrap";
import useInitialAsync from "hooks/useInitialAsync";
import * as verAPI from "api2/verification";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { SavedVerificationDocuments } from "../../ui/documents-refactored";

function SpecificationVerificationFileModal({ handleClose, companyId, verification }) {
  const { t } = useTranslation("common");
  const { loading, item: documentIds } = useInitialAsync(() => verAPI.documentsIds(companyId, verification.id), []);
  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("documents")} {verification.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">{t("ver:specVerFiles")}</Alert>
        {!loading && (
          <SavedVerificationDocuments
            companyId={companyId}
            verificationId={verification.id}
            removable={false}
            documents={documentIds}
          />
        )}
        {!loading && !documentIds.length && <Alert variant="info">{t("common:noResultsFound")}</Alert>}
      </Modal.Body>
    </Modal>
  );
}

export default SpecificationVerificationFileModal;
