import React, { useContext } from "react";

import NavBar from "components/ui/navbar";
import OfficeSettingProvider, { OfficeSettingStateContext } from "state/providers/OfficeSettingProvider";
import { RocketLoader } from "components/ui/loaders";
import { Outlet, useParams } from "react-router-dom";
import { codesForOffice } from "components/perms/PermCodes";
import { codeInList } from "components/perms";
import OfficeSettingsMenu from "./OfficeSettingsMenu";
import { useAuthState } from "../../../hooks/useAuth";

function OfficeSettingsLayout({ children }) {
  const { info, loading } = useContext(OfficeSettingStateContext);

  if (loading) {
    return <RocketLoader />;
  }
  return (
    <>
      <NavBar company={info} forDashboard={false} />
      <OfficeSettingsMenu />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

function OfficeSettingsWrapper() {
  const { officeId } = useParams();
  const { user } = useAuthState();
  const office = user.offices.find((officeUser) => officeUser.office === parseInt(officeId, 10));

  if ((office && codeInList(codesForOffice.enabled, office.perm_codes)) || user.is_superuser) {
    return (
      <OfficeSettingProvider officeId={officeId}>
        <OfficeSettingsLayout />
      </OfficeSettingProvider>
    );
  }
  return <div>NO PERMISSIONS</div>;
}

export default OfficeSettingsWrapper;
