import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCompanyState } from "hooks/useCompany";
import { getCurrencyRate } from "api/stats";
import { formatDate } from "utils/date";
import FormGroup from "./FormGroup";

function CurrencyPicker({ name = "amount_currency", currentCode, currentDate, ...props }) {
  const { t, i18n } = useTranslation("common");
  const [current, setCurrent] = useState({
    code: currentCode,
    rate: 0,
    created: formatDate(currentDate),
  });
  const {
    currencies: { asOptions: currencyOptions },
  } = useCompanyState();

  const translatedCurrencyOptions = currencyOptions.map((currency) => ({
    ...currency,
    label: i18n.language === "sv" ? currency.label_sv : currency.label_en,
  }));

  const getHistoricalDate = useCallback((code, date) => {
    if (code === "SEK") {
      setCurrent({ code, date, rate: 1 });
    } else {
      getCurrencyRate(code, date).then((response) => {
        setCurrent(response.data);
      });
    }
  }, []);

  useEffect(() => {
    getHistoricalDate(currentCode, currentDate);
  }, [getHistoricalDate, currentCode, currentDate]);

  return (
    <div className="currency-picker">
      <FormGroup.SimpleSelect
        name={name}
        label={t("money.currency")}
        options={translatedCurrencyOptions}
        isDisabled={props.disabled || false}
        styles={{
          menu: (provided, state) => ({
            ...provided,
            width: 350,
          }),
        }}
        {...props}
      />
      {current.code !== "SEK" && (
        <small>
          1{current.code} = {current.rate} SEK ({current.created})
        </small>
      )}
    </div>
  );
}

export default CurrencyPicker;
