import _ from "lodash";
import { differenceInCalendarDays, parseISO } from "date-fns";

import client from "./client";
import * as options from "./options";
import { fromBillectaAmount } from "../utils/calc/ci";

export function _getDiscount(record) {
  if (record.Discount) {
    return record.Discount;
  }
  if (record.DiscountType === "Percentage") {
    return record.DiscountPercentage || 0;
  }
  if (record.DiscountType === "Amount") {
    return record.DiscountAmount ? record.DiscountAmount.Value : 0;
  }
  return 0;
}

export function details(companyId, invoiceId, forDuplicate = false) {
  const vatOptions = options.vatOptions.asList();
  return client
    .get(`/companies/${companyId}/customer-invoices/${invoiceId}/`, { params: { for_duplicate: forDuplicate } })
    .then((response) => {
      const booking_date = response.data.booking_date ? parseISO(response.data.booking_date) : null;
      const due_date = response.data.due_date ? parseISO(response.data.due_date) : null;
      const payment_terms = differenceInCalendarDays(due_date, booking_date);
      const currency = response.data.amount_currency;

      return {
        ...response.data,
        payment_terms,
        booking_date,
        due_date,
        customer: !_.isEmpty(response.data.customer_snapshot) && {
          value: response.data.customer_snapshot.DebtorPublicId,
          label: response.data.customer_snapshot.Name,
          ...response.data.customer_snapshot,
        },
        rot_rut: {
          Customers:
            response.data.rot_rut && response.data.rot_rut.Customers
              ? response.data.rot_rut.Customers.map((customer) => ({
                  ...customer,
                  AskedAmount: fromBillectaAmount(customer.AskedAmount.Value, currency),
                }))
              : [],
          PropertyDesignation: response.data.rot_rut ? response.data.rot_rut.PropertyDesignation : "",
          ResidenceAssociationOrgNo: response.data.rot_rut ? response.data.rot_rut.ResidenceAssociationOrgNo : "",
        },
        extra_receivers: response.data.extra_receivers
          ? response.data.extra_receivers.filter((item) => item.length > 0)
          : [],
        records: response.data.records.map((record) => ({
          ...record,
          VAT:
            options.vatOptions.getOption(
              forDuplicate && !response.data.reversed_vat
                ? response.data.connected_products[record.ProductPublicId]?.VAT
                : record.VAT
            ) || vatOptions[0],
          UnitPrice: record.UnitPrice && fromBillectaAmount(record.UnitPrice.Value, currency),
          RotRutAmount: record.RotRutAmount && fromBillectaAmount(record.RotRutAmount.Value, currency),
          Discount: _getDiscount(record),
          key: _.uniqueId("ln"),
          BookKeepingAccount: forDuplicate
            ? response.data.connected_products[record.ProductPublicId]?.BookKeepingAccount || 3001
            : record.BookKeepingAccount || 3001,
        })),
        newFiles: [{ key: _.uniqueId("nd.") }],
      };
    });
}

export function save(companyId, data, _newFiles) {
  const formData = new FormData();
  _newFiles.forEach((file, i) => {
    if (file) {
      formData.append("attachments", file, file.name);
    }
  });
  formData.append("invoice", JSON.stringify(data));
  if (data.id) {
    return client.put(`/companies/${companyId}/customer-invoices/${data.id}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      timeout: 80000,
    });
  }
  return client.post(`/companies/${companyId}/customer-invoices/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 80000,
  });
}

export function remove(companyId, invoiceId) {
  return client.delete(`/companies/${companyId}/customer-invoices/${invoiceId}/`);
}

export function sendInvoiceReminder(companyId, invoiceId) {
  return client.get(`/companies/${companyId}/customer-invoices/${invoiceId}/send_invoice_reminder/`);
}

export function manual_pay(companyId, invoiceId, data) {
  return client.patch(`/companies/${companyId}/customer-invoices/${invoiceId}/manual_pay_invoice/`, data);
}

export function productPrices(companyId) {
  return client.get(`/companies/${companyId}/product-prices/`).then((response) => {
    return _.keyBy(response.data, (product) => product.billecta_product_id);
  });
}

export function getProducts(companyId, params) {
  return client.get(`/companies/${companyId}/billecta-products/`, {
    params,
  });
}

export function sendAndAttest(companyId, invoiceId) {
  return client.patch(`/companies/${companyId}/customer-invoices/${invoiceId}/billecta_create_and_attest/`);
}

export function send_to_customer(companyId, invoiceId, data) {
  return client.patch(`/companies/${companyId}/customer-invoices/${invoiceId}/send_invoice_to_customer/`, data);
}

export function checkVatVies(companyId, vatNumber) {
  return client.get(`/companies/${companyId}/customer-invoices/check_vat_vies/`, { params: { vatNumber } });
}

export function getPeriodisation(companyId, invoiceId) {
  return client.get(`/companies/${companyId}/customer-invoices/${invoiceId}/periodisation/`);
}

export function setPeriodisation(companyId, invoiceId, data) {
  return client.put(`/companies/${companyId}/customer-invoices/${invoiceId}/periodisation/`, data);
}

export function possibleCreditsForDebit(companyId, invoiceId) {
  return client.get(`/companies/${companyId}/customer-invoices/${invoiceId}/possible_credits_for_debit/`);
}

export function creditInvoice(companyId, debitInvoiceId, creditInvoiceId) {
  return client.patch(`/companies/${companyId}/customer-invoices/${debitInvoiceId}/credit_invoice/${creditInvoiceId}/`);
}
