import React from "react";
import { Card } from "react-bootstrap";
import CompanyListLayout from "components/layouts/CompanyListLayout";
import TableProvider from "components/tables/btable/provider";
import { OnboardingFinishingStepsTable } from "components/tables";
import * as tableAPI from "api/table";

function OnboardingFinishingStepsPage() {
  const initialFilters = {
    status: ["in_progress", "error"],
  };

  return (
    <CompanyListLayout>
      <Card>
        <Card.Body>
          <TableProvider
            tableId="onboarding-finishing-steps"
            dataSource={tableAPI.onboardingFinishingSteps}
            initialFilters={initialFilters}
            keepTerm={false}
          >
            <OnboardingFinishingStepsTable />
          </TableProvider>
        </Card.Body>
      </Card>
    </CompanyListLayout>
  );
}

export default OnboardingFinishingStepsPage;
