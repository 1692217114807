import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BankConnectionContext } from "state/providers/BankConnectionProvider";

function ConnectFailed({ errorMsg }) {
  const { t } = useTranslation("common");
  const { reset } = useContext(BankConnectionContext);
  return (
    <div className="connect-failed">
      <p className="text-danger">{t("company:psd.connectionFailed")}</p>
      {errorMsg && <p className="error-detailed">{errorMsg}</p>}
      <Button type="button" onClick={reset}>
        {t("common:actions.tryAgain")} <i className="fe-chevron-right" />
      </Button>
    </div>
  );
}

export default ConnectFailed;
