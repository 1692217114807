import React from "react";
import TTTask from "./TTTask";
import * as trackerAPI from "../../../../api/time-tracker";

function NormalTimeTask() {
  const [task, setTask] = React.useState(null);

  function fetchActiveTask() {
    trackerAPI
      .getActiveTask()
      .then((response) => {
        setTask(response.data);
      })
      .catch(() => {});
  }

  React.useEffect(() => {
    fetchActiveTask();

    const interval = setInterval(() => {
      fetchActiveTask();
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("task/changed", fetchActiveTask);
    return () => document.body.removeEventListener("task/changed", fetchActiveTask);
  }, []);

  const stopTask = async (event) => {
    event.stopPropagation();
    await trackerAPI
      .stopTimer(task.id)
      .then((response) => {})
      .catch(() => {})
      .finally(() => {
        document.body.dispatchEvent(new Event("task/changed"));
        document.body.dispatchEvent(new Event("work/stopped"));
      });
  };

  return task ? (
    <TTTask title={task.title} company={task.company_name} duration={task.duration} taskClassName="active-task--normal">
      <i className="fas fa-stop-circle" onClick={stopTask} />
    </TTTask>
  ) : null;
}

export default NormalTimeTask;
