import React from "react";
import { useNavigate, useOutletContext, useParams, useResolvedPath } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as outlayAPI from "api2/outlays";
import { RocketLoader } from "components/ui/loaders";
import MileageForm from "components/forms/MileageForm";

function MileageEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const currentPath = useResolvedPath("").pathname;
  const { id: mileageId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return outlayAPI.mileageDetails(company.id, mileageId);
  });

  const onUpdate = (outlay) => {
    if (outlay.next) {
      const isReceipt = currentPath.includes("receipt");
      const nextLoc = currentPath
        .replace(item.id, outlay.next)
        .replace(isReceipt ? "receipt" : "mileage", outlay.next_is_receipt === true ? "receipt" : "mileage");
      navigate("/temp", { replace: true });
      navigate(nextLoc, { replace: true });
    } else {
      navigate(-1);
    }
  };

  if (loading) {
    return <RocketLoader />;
  }
  return <MileageForm companyId={company.id} outlay={item} onSave={onUpdate} />;
}

export default MileageEditPage;
