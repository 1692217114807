import React, { useState } from "react";

function OnboardingCheckbox({ checked = false, companyId, name, onChange, disabled = false }) {
  const [marked, setMarked] = useState(checked);

  const change = () => {
    if (!disabled) {
      const newValue = !marked;
      setMarked(newValue);
      onChange(companyId, name, newValue);
    }
  };

  return (
    <div className="checkbox custom-control checkbox-primary" onClick={change}>
      <input type="checkbox" className="d-none" checked={marked} readOnly disabled={disabled} />
      <label className="custom-control-label" />
    </div>
  );
}

export default OnboardingCheckbox;
