import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import _ from "lodash";

function RepresentationPreviewContent({ t, instance }) {
  const isThisRepresentation = (account) => {
    return account && [6071, 6072].includes(account.number);
  };
  if (
    !instance.account ||
    (instance.account && !isThisRepresentation(instance.account) && _.isEmpty(instance.representation))
  ) {
    return null;
  }

  if (_.isEmpty(instance.representation)) return null;

  return (
    <Row>
      <Col>
        <label className="mb-0">{t("common:rep.title")}</label>
        <Table size="sm" bordered>
          <thead>
            <tr>
              <th>{t("common:rep.participant")}</th>
              <th>{t("common:company")}</th>
            </tr>
          </thead>
          <tbody>
            {instance.representation?.participants?.map((p, i) => (
              <tr key={`p${i}`}>
                <td>{p.name || "-"}</td>
                <td>{p.company || "-"}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>
                {t("common:rep.activity")} - <strong>{instance.representation.activity.label || "-"}</strong>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {t("common:rep.purpose")} - <strong>{instance.representation.purpose.label || "-"}</strong>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Col>
    </Row>
  );
}

export default RepresentationPreviewContent;
