import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { accountPlanOptions } from "api/options";
import { BExpandedTable } from "components/tables/btable";
import { AccountPlanEditWidget } from "components/forms/company-settings-forms/AccountPlanForm";
import Filters from "./AccountPlanFilters";

function AccountPlanExtendedTable({ companyId }) {
  const { t } = useTranslation("company");
  const _defaultOptionLabel = accountPlanOptions.asList()[0].label;
  const headers = useMemo(
    () => [
      {
        field: "number",
        label: t("common:no"),
        canSort: false,
      },
      {
        field: "name",
        label: t("common:accountName"),
        canSort: false,
        render: (values) => (
          <span>
            {values.name}
            {!values.is_active && (
              <small className="text-danger text-uppercase ml-2">{t("common:statuses.inactive")}</small>
            )}
          </span>
        ),
      },
      {
        field: "cost_center_option",
        label: t("common:costCenter"),
        canSort: false,
        render: (values) => accountPlanOptions.getLabel(values.cost_center_option) || _defaultOptionLabel,
      },
      {
        field: "project_option",
        label: t("common:project"),
        canSort: false,
        render: (values) => accountPlanOptions.getLabel(values.project_option) || _defaultOptionLabel,
      },
      {
        field: "vat_code",
        label: t("vatCode"),
        canSort: false,
      },
    ],
    [_defaultOptionLabel, t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          idKey="number"
          headers={headers}
          FiltersComponent={<Filters />}
          renderExpandedRow={(row) => <AccountPlanEditWidget companyId={companyId} account={row} />}
        />
      </Col>
    </Row>
  );
}

export default AccountPlanExtendedTable;
