import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import SalaryListPage from "./SalaryListPage";

function PaySalaryListPage() {
  const canPay = HasPermCode(codesFor.salaries.pay);
  return <SalaryListPage tableId="unpaidSalaries" initialMode="unpaid" selectable={canPay} />;
}

export default PaySalaryListPage;
