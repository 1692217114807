import React from "react";
import { Card } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import LedgerDateTable from "./LedgerDateTable";
import LedgerTable from "./LedgerStandardTable";

function groupVerifications(verifications, groupByDate) {
  if (!groupByDate) {
    return verifications;
  }
  const dataMap = {};
  const output = [];
  let outputIdx = 0;
  verifications.forEach((ver, i) => {
    if (dataMap[ver.booking_date] === undefined) {
      dataMap[ver.booking_date] = output.length;
      output.push({
        category: ver.booking_date,
        debit: 0,
        credit: 0,
        balance: 0,
        verifications: [],
      });
    }
    outputIdx = dataMap[ver.booking_date];
    output[outputIdx].debit += ver.debit;
    output[outputIdx].credit += ver.credit;
    output[outputIdx].balance = ver.balance;
    output[outputIdx].verifications.push({ ...ver, targetIndex: i });
  });
  return output;
}

function FilterableLedgerTable({ ledger, companyId, handleAddVerToSpec, handleVerCheck }) {
  const [showChecked, setShowChecked] = React.useState(true);
  const [byDate, setByDate] = React.useState(true);
  const ledgerData = {
    title: `${ledger.number} - ${ledger.name}`,
    groups: groupVerifications(
      showChecked === false ? ledger.verifications.filter((ver) => ver.is_checked === false) : ledger.verifications,
      byDate
    ),
    initialBalance: ledger.initial_balance,
    endingBalance: ledger.ending_balance,
  };

  const TableRenderClass = byDate ? LedgerDateTable : LedgerTable;

  return (
    <Card>
      <Card.Body className="reports">
        <LedgerFilters
          showChecked={showChecked}
          setShowChecked={setShowChecked}
          byDate={byDate}
          setByDate={setByDate}
        />
        <TableRenderClass
          ledger={ledgerData}
          companyId={companyId}
          handleAddVerToSpec={handleAddVerToSpec}
          handleVerCheck={handleVerCheck}
        />
      </Card.Body>
    </Card>
  );
}

function CheckBox({ name, label, ...inputProps }) {
  return (
    <div className="checkbox custom-control checkbox-primary mb-1">
      <input type="checkbox" name={name} id={`id_${name}`} {...inputProps} />
      <label htmlFor={`id_${name}`}>{label}</label>
    </div>
  );
}

function LedgerFilters({ showChecked, setShowChecked, byDate, setByDate }) {
  const { t } = useTranslation("reports");
  return (
    <div className="float-right">
      <CheckBox
        name="showChecked"
        label={t("showCheckedOnly")}
        defaultChecked={showChecked}
        onClick={() => setShowChecked(!showChecked)}
      />
      <CheckBox
        name="groupByDate"
        label={t("groupByDate")}
        defaultChecked={byDate}
        onClick={() => setByDate(!byDate)}
      />
    </div>
  );
}

export default FilterableLedgerTable;
