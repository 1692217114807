import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { CheckboxFilter, DateFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import * as tableAPI from "api/table";
import axios from "axios";
import { FiltersContext } from "state/providers/FiltersProvider";
import { FilterButton } from "components/ui/buttons";
import { formatDate } from "utils/date";
import TaskFormModal from "components/modals/TaskFormModal";
import useModal from "hooks/useModal";
import * as trackerAPI from "api/time-tracker";
import { formatFilters } from "utils/others";
import { toast } from "react-toastify";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";
import { stopTaskByCompany } from "./helpers";
import Task from "./Task";

function TrackByCompany() {
  const { t } = useTranslation("tt");
  const [counter, setCounter] = useState(1); // to refresh
  const { filters, updateFilter } = useContext(FiltersContext);
  const { options, selected: selectedOffice } = useContext(TimeTrackerStateContext);
  const taskModal = useModal();
  const [tasks, setTasks] = useState({
    companies: {},
    currentId: null,
  });
  const filtersCount = !!filters.user + !!filters.company + !!filters.deadline_date__end + !!filters.with_tickets_only;
  const [more, setMore] = useState(true);

  useEffect(() => {
    document.body.addEventListener("work/stopped", () => {
      setCounter((prevCounter) => prevCounter + 1);
    });
  }, []);

  useEffect(() => {
    const signal = axios.CancelToken.source();
    tableAPI
      .trackerTasksByCompany(
        { office: selectedOffice.office, ...formatFilters(filters) },
        {
          cancelToken: signal.token,
        }
      )
      .then((response) => {
        setTasks({
          companies: response.data.companies_tasks,
          currentId: response.data.running_task_id,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // pass
        }
      });
    return function cleanup() {
      signal.cancel("aborted");
    };
  }, [filters, selectedOffice.office, counter]);

  const onTaskSave = () => {
    taskModal.close();
    updateFilter({ counter: (filters.counter || 0) + 1 });
  };

  async function playTimer(event, task, index, permanent = false) {
    event.stopPropagation();
    const newData = {
      currentId: task.id,
      companies: { ...tasks.companies },
    };
    await trackerAPI
      .startTimer(task.id)
      .then((response) => {
        const { warning, stoppedTask } = response.data;
        if (warning) {
          toast.warning(warning, { autoClose: 10000 });
        }
        if (stoppedTask) {
          stopTaskByCompany(stoppedTask, newData);
        }
        if (!permanent) {
          newData.companies[task.company_id].tasks[index] = {
            ...task,
            playing: true,
          };
        }
        document.body.dispatchEvent(new Event("task/changed"));
        setTasks(newData);
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 10000 });
        }
      });
  }

  async function stopTimer(event, task) {
    event.stopPropagation();
    const newData = {
      currentId: null,
      companies: { ...tasks.companies },
    };
    await trackerAPI.stopTimer(task.id).then((response) => {
      const stoppedTask = response.data;
      if (stoppedTask) {
        stopTaskByCompany(stoppedTask, newData);
      }
      document.body.dispatchEvent(new Event("task/changed"));
      setTasks(newData);
    });
  }

  async function finish(event, task, index, permanentRow = false) {
    event.stopPropagation();
    if (task.permanent || task.main_company_task || task.finished) {
      return;
    }
    await trackerAPI.finish(task.id);
    if (permanentRow) {
      setTasks({
        ...tasks,
        currentId: null,
      });
    } else if (tasks.companies[task.company_id]) {
      setTasks({
        ...tasks,
        currentId: null,
        companies: {
          ...tasks.companies,
          [task.company_id]: {
            company_name: task.company_name,
            main_task: task.main_task,
            tasks: [
              ...tasks.companies[task.company_id].tasks.slice(0, index),
              ...tasks.companies[task.company_id].tasks.slice(index + 1),
            ],
          },
        },
      });
    }
    document.body.dispatchEvent(new Event("task/changed"));
    toast.success(t("taskFinished", { title: task.title }));
  }

  const sortedCompaniesTasks = _.sortBy(Object.values(tasks.companies), (item) => item.company_name);

  return (
    <div id="tt-calendar" className="by-company">
      <div className="table-filters-group pl-2 pt-2">
        <section className="table-filters-left">
          <SearchFilter
            onFilter={updateFilter}
            defaultValue={filters.term}
            placeholder={`${t("searchForTask")}...`}
            width={300}
          />
          <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        </section>
        <section className="table-filters-right mr-2">
          <Button
            variant="first"
            onClick={() =>
              taskModal.open({
                company_id: filters.company,
                assignee: filters.user,
                deadline_date: formatDate(new Date()),
                category: "custom",
                comment: "",
                title: "",
              })
            }
          >
            <i className="fas fa-plus" /> {t("common:actions.create")}
          </Button>
        </section>
        {more && (
          <section className="more-filters">
            <SelectSimpleFilter
              label={t("common:company")}
              defaultValue={filters.company}
              options={options.companies}
              isClearable
              name="company"
              onFilter={updateFilter}
            />
            <SelectSimpleFilter
              label={t("assignee")}
              defaultValue={filters.user}
              options={options.consults}
              isClearable={false}
              name="user"
              onFilter={updateFilter}
            />
            <DateFilter
              label={t("dueDeadline")}
              defaultValue={filters.deadline_date__end}
              name="deadline_date__end"
              onChange={(date) => updateFilter({ deadline_date__end: date })}
            />
            <CheckboxFilter
              wrapperClass="hacked-checkbox"
              title={t("withTickets")}
              name="with_tickets_only"
              defaultChecked={filters.with_tickets_only}
              onFilter={updateFilter}
            />
          </section>
        )}
      </div>
      <div className="table-wrapper">
        <Table bordered>
          <tbody>
            {sortedCompaniesTasks.map((company) => (
              <tr key={`c.${company.company_name}`}>
                <td>
                  {company.tasks.map((task, index) => (
                    <Task
                      key={`t.${task.id}`}
                      task={task}
                      playTimer={(event) => playTimer(event, task, index, false)}
                      stopTimer={(event) => stopTimer(event, task, index)}
                      finish={(event) => finish(event, task, index)}
                      openTask={() => taskModal.open(task)}
                      byCompany
                    />
                  ))}
                </td>
              </tr>
            ))}
            {_.isEmpty(sortedCompaniesTasks) && (
              <tr>
                <td>{t("common:noResultsFound")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {taskModal.show && (
        <TaskFormModal handleClose={taskModal.close} handleSave={onTaskSave} task={taskModal.data} options={options} />
      )}
    </div>
  );
}

export default TrackByCompany;
