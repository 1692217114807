import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BankConnectionProvider, { BankConnectionContext } from "state/providers/BankConnectionProvider";
import { Loader } from "components/ui/loaders";
import "./BankConnectModal.scss";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import BankList from "./BankList";
import RedirectFlow from "./RedirectFlow";
import DecoupledQRFlow from "./DecoupledQRFlow";
import ConnectFailed from "./ConnectFailed";
import ConnectionResult from "./ConnectionResult";

function BankConnectModal({ companyId, initialConsentId, renewForBic, handleClose }) {
  const { t } = useTranslation("company");
  return (
    <Modal
      show
      onHide={handleClose}
      size="lg"
      className="bank-connect-modal"
      scrollable
      backdrop="static"
      keyboard={false}
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header>
        <Modal.Title>
          {t("psd.addBankConnection")}{" "}
          <Button type="button" className="modal-close" onClick={handleClose}>
            <i className="fe-x" />
          </Button>
        </Modal.Title>
      </Modal.Header>
      <div className="modal-wrapper">
        <Modal.Body>
          <BankConnectionProvider companyId={companyId} consentId={initialConsentId} renewForBic={renewForBic}>
            <ModalContent handleClose={handleClose} companyId={companyId} />
          </BankConnectionProvider>
          <div className="notes">{t("psd.integrationNote")}</div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

function ModalContent({ handleClose, companyId }) {
  const { step, error, loading } = useContext(BankConnectionContext);
  if (loading) {
    return <Loader />;
  }
  switch (step) {
    case "banklist":
      return <BankList companyId={parseInt(companyId, 10)} />;
    case "rflow":
      return <RedirectFlow />;
    case "dflow":
      return <DecoupledQRFlow />;
    case "result":
      return <ConnectionResult handleClose={handleClose} />;
    case "error":
      return <ConnectFailed onReset={() => {}} errorMsg={error} />;
    default:
      return <BankList companyId={parseInt(companyId, 10)} />;
  }
}

export default BankConnectModal;
