import React from "react";
import { Card } from "react-bootstrap";

import TrackCalendar from "./calendar/TrackCalendar";

function TimeTrackerCalendarPage() {
  return (
    <Card className="mb-0">
      <TrackCalendar />
    </Card>
  );
}

export default TimeTrackerCalendarPage;
