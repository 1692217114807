import { formatDate, parseDate } from "utils/date";
import qs from "qs";
import client from "./client";

export function quickInfo(companyId, config) {
  return client.get(`/companies/${companyId}/quick_info/`, { ...config });
}

export function selectCompany(companyId, forOfficeSupport = false) {
  return client.get(`/companies/${companyId}/select/`, { params: { forOfficeSupport } });
}

export function registerCompany(companyData) {
  return client.post("/companies/register/", companyData);
}

export function importCompaniesCSV(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return client.post("/companies/import_csv/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function registerBillecta(companyId) {
  return client.patch(`/companies/${companyId}/register_billecta/`);
}

export function registerEInvoice(companyId) {
  return client.patch(`/companies/${companyId}/register_einvoice/`);
}

export function lockAccountingDate(companyId, lockDate) {
  return client.put(`/companies/${companyId}/lock-accounting-date/`, {
    lock_accounting_date: lockDate,
  });
}

export function getCompanyGeneralData(companyId, config) {
  return client.get(`/companies/${companyId}/general/`, config);
}

export function saveCompanyGeneralData(data) {
  return client.put(`/companies/${data.id}/general/`, data);
}

export function getCompanyLogo(companyId, config) {
  return client.get(`/companies/${companyId}/logo/`, config).then((response) => response.data);
}

export function removeCompanyLogo(companyId) {
  return client.delete(`/companies/${companyId}/logo/`).catch(() => {
    /* pass */
  });
}

export function saveCompanyLogo(companyId, file) {
  const formData = new FormData();
  formData.append("logo", file, file.name);
  return client.put(`/companies/${companyId}/logo/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadSIEFile(companyId, file, data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  });
  formData.append("file", file, file.name);
  return client.post(`/companies/${companyId}/sie-file/upload_sie/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function deleteSIEFile(companyId, sieFileId) {
  return client.delete(`/companies/${companyId}/sie-file/${sieFileId}/`);
}

export function checkSIEFileToRevert(companyId, sieFileId) {
  return client.get(`/companies/${companyId}/sie-file/${sieFileId}/summary_for_revert/`);
}

export function saveAccountMapping(companyId, mapping) {
  return client.post(`/companies/${companyId}/sie-file/send_mapped_accounts/`, mapping, { timeout: 0 });
}

export function getCompanyConsultData(companyId, config) {
  return client.get(`/companies/${companyId}/consult/`, config);
}

export function saveCompanyConsultData(data) {
  return client.put(`/companies/${data.id}/consult/`, data);
}

export function getCompanyInvoicingData(companyId, config) {
  return client.get(`/companies/${companyId}/invoicing/`, config);
}

export function getCheckPermCode(companyId, data) {
  return client.post(`/companies/${companyId}/check_perm_code/`, data);
}

export function saveCompanyInvoicingData(data) {
  return client.put(`/companies/${data.id}/invoicing/`, data);
}

export function consultToggleFavourite(companyId) {
  return client.get(`/companies/${companyId}/toggle_favourite/`);
}

export function getCompanyAccountPlan(companyId, filters, config) {
  return client.get(`/companies/${companyId}/account-plan/`, {
    params: filters,
    ...config,
  });
}

export function saveCompanyAccountPlan(companyId, accountData) {
  return client.post(`/companies/${companyId}/account-plan/`, accountData);
}

export function getCompanyEmployee(companyId, employeeId, config) {
  return client.get(`/companies/${companyId}/employees/${employeeId}/`, {
    ...config,
  });
}

export function saveCompanyEmployee(companyId, employeeData) {
  if (employeeData.id) {
    return client.put(`/companies/${companyId}/employees/${employeeData.id}/`, {
      ...employeeData,
      company: companyId,
    });
  }
  return client.post(`/companies/${companyId}/employees/`, {
    ...employeeData,
    company: companyId,
  });
}

export function deleteCompanyEmployee(companyId, employeeId) {
  return client.delete(`/companies/${companyId}/employees/${employeeId}/`);
}

export function deleteCompanyProject(companyId, projectId) {
  return client.delete(`/companies/${companyId}/projects/${projectId}/`);
}

export function createCompanyAuditor(companyId, data) {
  return client.post(`/companies/${companyId}/auditors/`, data);
}

export function deleteCompanyAuditor(companyId, auditorId) {
  return client.delete(`/companies/${companyId}/auditors/${auditorId}`);
}

export function saveCompanyProject(companyId, projectData) {
  if (projectData.id) {
    return client.put(`/companies/${companyId}/projects/${projectData.id}/`, {
      ...projectData,
      company: companyId,
    });
  }
  return client.post(`/companies/${companyId}/projects/`, {
    ...projectData,
    company: companyId,
  });
}

export function deleteCompanyCostCenter(companyId, costCenterId) {
  return client.delete(`/companies/${companyId}/cost-centers/${costCenterId}/`);
}

export function saveCompanyCostCenter(companyId, costCenterData) {
  if (costCenterData.id) {
    return client.put(`/companies/${companyId}/cost-centers/${costCenterData.id}/`, {
      ...costCenterData,
      company: companyId,
    });
  }
  return client.post(`/companies/${companyId}/cost-centers/`, {
    ...costCenterData,
    is_active: true,
    company: companyId,
  });
}

export function deleteCompanyFinancialYear(companyId, financialYearId) {
  return client.delete(`/companies/${companyId}/financial-years/${financialYearId}`);
}

export function exportFinancialYear(companyId, financialYearId, formData) {
  return client.post(`/companies/${companyId}/financial-years/${financialYearId}/export/`, formData, {
    responseType: "blob",
  });
}

export function saveCompanyFinancialYear(companyId, data) {
  if (data.id) {
    return client.put(`/companies/${companyId}/financial-years/${data.id}/`, {
      ...data,
      company: companyId,
    });
  }
  return client.post(`/companies/${companyId}/financial-years/`, {
    ...data,
    company: companyId,
  });
}

export function getCompanySupplierDetails(companyId, supplierId) {
  return client.get(`/companies/${companyId}/suppliers/${supplierId}/`);
}

export function saveCompanySupplier(companyId, data) {
  if (data.id) {
    return client.put(`/companies/${companyId}/suppliers/${data.id}/`, {
      ...data,
      company: companyId,
    });
  }
  return client.post(`/companies/${companyId}/suppliers/`, {
    ...data,
    company: companyId,
  });
}

export function deleteCompanySupplier(companyId, supplierId) {
  return client.delete(`/companies/${companyId}/suppliers/${supplierId}/`);
}

export function activeCompanySupplier(companyId, supplierId) {
  return client.post(`/companies/${companyId}/suppliers/${supplierId}/active/`);
}

export function deactiveCompanySupplier(companyId, supplierId) {
  return client.post(`/companies/${companyId}/suppliers/${supplierId}/deactive/`);
}

export function forceReminderEmails(companyId) {
  return client.patch(`/company-admin/${companyId}/force_reminder_emails/`);
}

export function specificationsCopy(companyId) {
  return client.patch(`/company-admin/${companyId}/copy_specifications/`);
}

export function getCompanyTimeReporting(companyId, config) {
  return client.get(`companies/${companyId}/company-time-reporting/`, config);
}

export function initialRepeatedTasks(companyId) {
  return client.post(`companies/${companyId}/time-tracker/repeated-tasks/initialize/`);
}

export function setTTActiveDate(companyId, selected_date, ttActive) {
  return client.put(`companies/${companyId}/time-tracker/repeated-tasks/set_tt_active_date/`, {
    date: selected_date,
    tt_active: ttActive,
  });
}

export function saveRepeatedTask(companyId, taskData) {
  if (taskData.id) {
    return client.put(`companies/${companyId}/time-tracker/repeated-tasks/${taskData.id}/`, taskData);
  }
  return client.post(`companies/${companyId}/time-tracker/repeated-tasks/`, {
    company: companyId,
    ...taskData,
  });
}

export function getOccurrences(companyId, taskData) {
  return client.post(`companies/${companyId}/time-tracker/repeated-tasks/show_occurrences/`, {
    company: companyId,
    ...taskData,
  });
}

export function getCompanyRepeatedTask(companyId, taskId, config) {
  return client.get(`companies/${companyId}/time-tracker/repeated-tasks/${taskId}/`, { ...config });
}

export function removeCompanyTTRepeatedTask(companyId, taskId) {
  return client.delete(`companies/${companyId}/time-tracker/repeated-tasks/${taskId}`);
}

export function getCompanyProjects(companyId, config) {
  return client.get("/select/projects/", { params: { companyId, ...config } }).then((response) =>
    response.data.map((item) => ({
      value: item.id,
      label: item.name,
      ...item,
    }))
  );
}

export function getCompanyProject(companyId, projectId, config) {
  return client.get(`/companies/${companyId}/projects/${projectId}/`, config);
}

export function getCompanyCostCenter(companyId, costCenterId, config) {
  return client.get(`/companies/${companyId}/cost-centers/${costCenterId}/`, config);
}

export function getCompanyFinancialYear(companyId, fYearId, config) {
  return client.get(`/companies/${companyId}/financial-years/${fYearId}/`, config);
}

export function saveCompanyTimeReportingConfig(companyId, data) {
  return client.post(`/companies/${companyId}/company-time-reporting/update_access/`, data);
}

export function getAnnualReportData(companyId, config) {
  return client.get(`/companies/${companyId}/annual-report/`, config).then((response) => response.data);
}

export function saveAnnualReportData(companyId, data) {
  return client.post(`/companies/${companyId}/annual-report/`, data);
}

export function updateAnnualReportStatus(companyId, status) {
  return client.put(`/companies/${companyId}/annual-report/change_status/`, { status });
}

export function normalGoogleSync(companyId) {
  return client.get(`/companies/${companyId}/annual-report/google_sync/`);
}

export function annualGoogleSync(companyId, params) {
  return client.get(`/companies/${companyId}/annual-report/google_sync_annual_report/`, { params });
}

export function saveCompanyManagement(companyId, data) {
  return client.patch(`/companies/${companyId}/manage/`, data);
}

export const psd2 = {
  accounts: {
    list: (companyId, consentId) => client.get(`/companies/${companyId}/psd2/${consentId}/accounts/`),
    update: (companyId, consentId, data) =>
      client.put(`/companies/${companyId}/psd2/${consentId}/accounts/update_many/`, data),
    create: (companyId, consentId, data) => client.post(`/companies/${companyId}/psd2/${consentId}/accounts/`, data),
    refetchBankStatement: (companyId, consentId) =>
      client.get(`/companies/${companyId}/psd2/${consentId}/accounts/refetch_bank_statement/`),
    setAsPaymentAccount: (companyId, consentId, accountId) =>
      client.patch(`/companies/${companyId}/psd2/${consentId}/accounts/${accountId}/set_as_payment_account/`),
  },
  bankList: (companyId, config) => client.get(`/companies/${companyId}/psd2/bank_list/`, config),
  createConsent: (companyId, bicFi, forRenew) =>
    client.post(`/companies/${companyId}/psd2/`, {
      bic_fi: bicFi,
      for_renew: forRenew,
    }),
  deleteConsent: (companyId, consentId) => client.delete(`/companies/${companyId}/psd2/${consentId}/`),
  checkSCA: (companyId, consentId) => client.get(`/companies/${companyId}/psd2/${consentId}/check_sca_status/`),
  consentDetails: (companyId, consentId) => client.get(`/companies/${companyId}/psd2/${consentId}/`),
  consents: (companyId, params) => client.get(`/companies/${companyId}/psd2/`, { params }),
  bflowAccounts: (companyId) =>
    client
      .get("/select/company-accounts/", {
        params: { companyId, forBank: true },
      })
      .then((response) =>
        response.data.map((account) => ({
          value: account.number,
          label: `${account.number} - ${account.name}`,
          isDisabled: !account.is_active,
        }))
      ),
  selectedAccounts: (companyId, consentId) => client.get(`/companies/${companyId}/psd2/${consentId}/psd2_accounts/`),
  accountsSelection: (companyId, consentId, data) =>
    client.post(`/companies/${companyId}/psd2/${consentId}/psd2_accounts_selection/`, data),
  accountsMap: (companyId, consentId, data) =>
    client.patch(`/companies/${companyId}/psd2/${consentId}/psd2_accounts_map/`, data),
};

export const fortnox = {
  info: (companyId) => client.get(`/companies/${companyId}/fortnox/info/`),
  connect: (companyId) => client.get(`/companies/${companyId}/fortnox/activate/`),
  disconnect: (companyId) => client.delete(`/companies/${companyId}/fortnox/disconnect/`),
  importCompanyInfo: (companyId) => client.post(`/companies/${companyId}/fortnox/update_company/`, {}),
  importNextInvoiceNo: (companyId) => client.post(`/companies/${companyId}/fortnox/update_next_invoice_no/`, {}),
  importCustomers: (companyId) => client.post(`/companies/${companyId}/fortnox/import_customers/`, {}),
  importProducts: (companyId) => client.post(`/companies/${companyId}/fortnox/import_products/`, {}),
  importCC: (companyId) => client.post(`/companies/${companyId}/fortnox/import_cost_centers/`, {}),
  importProjects: (companyId) => client.post(`/companies/${companyId}/fortnox/import_projects/`, {}),
  importSIE: (companyId, data, config) => client.post(`/companies/${companyId}/fortnox/import_sie/`, data, config),
  getFortnoxYears: (companyId, config) => client.get(`/companies/${companyId}/fortnox/fortnox_years/`, config),
  importVerificationFiles: (companyId, fortnoxYearId) =>
    client.post(`/companies/${companyId}/fortnox/import_files/`, {
      fortnox_year_id: fortnoxYearId,
    }),
};

export const contactPersons = {
  save: (companyId, data) => {
    return client.post(`/companies/${companyId}/contact-persons/`, data);
  },
  list: (companyId) => client.get(`/companies/${companyId}/contact-persons/`),
  remove: (companyId, itemId) => client.delete(`/companies/${companyId}/contact-persons/${itemId}`),
};

export const bankRules = {
  save: (companyId, data) => {
    if (data.id) {
      return client.put(`/companies/${companyId}/bank-matching-rules/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/bank-matching-rules/`, {
      ...data,
      company: companyId,
    });
  },
  details: (companyId, ruleId) => client.get(`/companies/${companyId}/bank-matching-rules/${ruleId}/`),
  remove: (companyId, ruleId) => client.delete(`/companies/${companyId}/bank-matching-rules/${ruleId}/`),
  priorityUp: (companyId, ruleId) => client.put(`/companies/${companyId}/bank-matching-rules/${ruleId}/priority_up/`),
  priorityDown: (companyId, ruleId) =>
    client.put(`/companies/${companyId}/bank-matching-rules/${ruleId}/priority_down/`),
};

export function formatRepeatedTask(task) {
  return {
    ...task,
    assignee: task.assignee && task.assignee.value,
    bymonthday: task.bymonthday ? task.bymonthday.map((t) => t.value).join() : "",
    byweekday: task.byweekday ? task.byweekday.map((t) => t.value).join() : "",
    bymonth: task.bymonth ? task.bymonth.map((t) => t.value).join() : "",
    bysetpos: task.bysetpos ? task.bysetpos : null,
  };
}

export const employeeSchedule = {
  templates: (companyId) => client.get("/select/office-templates/", { params: { companyId } }),
  list: (companyId, employeeId) => {
    return client.get(`/table/companies/${companyId}/employees-schedules/`, {
      params: { employee_id: employeeId },
    });
  },
  save: (companyId, data) => {
    if (data.id) {
      return client.put(`/companies/${companyId}/employee-schedules/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/employee-schedules/`, data);
  },
  remove: (companyId, scheduleId) => client.delete(`/companies/${companyId}/employee-schedules/${scheduleId}/`),
};

export const employeePermissions = {
  get: (companyId, employeeId) => {
    return client.get(`/companies/${companyId}/employees/${employeeId}/permissions/`);
  },
  update: (companyId, employeeId, data) => {
    return client.put(`/companies/${companyId}/employees/${employeeId}/permissions/`, data);
  },
};

export function sendWelcomeEmail(companyId, employeeId) {
  return client.put(`/companies/${companyId}/employees/${employeeId}/send_welcome_email/`);
}

export function setAzoraPrgenitor(companyId, data) {
  return client.post(`/companies/${companyId}/set_progenitor/`, data);
}

export const consultPermissions = {
  get: (companyId, consultId) => {
    return client.get(`/companies/${companyId}/consult-perms-for/${consultId}/`);
  },
  update: (companyId, consultId, data) => {
    return client.put(`/companies/${companyId}/consult-perms-for/${consultId}/`, data);
  },
};

export const onboarding = {
  create: (companyId) => {
    return client.post(`/companies/${companyId}/onboarding/`);
  },
  changeFinishingStep: (companyId, key, value) => {
    return client.patch(`/companies/${companyId}/onboarding/finishing_steps/`, { [key]: value });
  },
  details: (companyId) => {
    return client.get(`/companies/${companyId}/onboarding/info/`);
  },
  companyInfoStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/company_info/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/company_info/`, data),
  },
  previousSystemStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/previous_system/`, config),
    rollback: (companyId) => client.delete(`/companies/${companyId}/onboarding/previous_system/`),
    otherSystemPath: (companyId) => client.post(`/companies/${companyId}/onboarding/previous_system/other_system/`),
    fortnoxPath: (companyId) => client.get(`/companies/${companyId}/onboarding/previous_system/fortnox_connect/`),
  },
  generalInfoStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/general_info/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/general_info/`, data),
  },
  saveAccountMappingStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/save_accounting/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/save_accounting/`, data),
  },
  importAccountingStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/import_accounting/`, config),
    save: (companyId) => client.put(`/companies/${companyId}/onboarding/import_accounting/`),
  },
  companySettingsStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/company_settings/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/company_settings/`, data),
  },
  employees: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/employees/`, config),
    finish: (companyId, config) => client.put(`/companies/${companyId}/onboarding/employees/`, config),
  },
  finishStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/finished/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/finished/`, data),
  },
  customers: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/import_customers/`, config),
    save: (companyId, data) => client.put(`/companies/${companyId}/onboarding/import_customers/`, data),
  },
  fortnoxImportStep: {
    get: (companyId, config) =>
      client.get(`/companies/${companyId}/onboarding/import_fortnox/`, config).then((response) => {
        response.data.fortnox_financial_years.forEach((fYear) => {
          fYear.from = parseDate(fYear.from);
          fYear.to = parseDate(fYear.to);
        });
        return response;
      }),
    start: (companyId, financialYearIds) =>
      client.put(`/companies/${companyId}/onboarding/import_fortnox/`, { ids: financialYearIds }),
  },
  fortnoxSaveLedgerStep: {
    get: (companyId, config) => client.get(`/companies/${companyId}/onboarding/save_ledgers/`, config),
    start: (companyId) => client.put(`/companies/${companyId}/onboarding/save_ledgers/`),
  },
  consults: {
    get: (companyId, params, config) =>
      client.get(`/companies/${companyId}/onboarding/consultant_settings/`, { params, ...config }),
    save: (companyId, data, params, config) =>
      client.put(`/companies/${companyId}/onboarding/consultant_settings/`, data, { params, ...config }),
  },
};

export const signingBasket = {
  initialList: (companyId, verificationIds) =>
    client.post(`/companies/${companyId}/signing-basket/initial_list/`, { verifications: verificationIds }),
  create: (companyId, itemsToPay) =>
    client.post(`/companies/${companyId}/signing-basket/`, { items: itemsToPay }, { timeout: 5 * 60000 }),
  checkSignStatus: (companyId, basketId) =>
    client.get(`/companies/${companyId}/signing-basket/${basketId}/check_sign_status/`),
  checkBasket: (companyId, basketId) =>
    client.get(`/companies/${companyId}/signing-basket/${basketId}/check_basket/`, { timeout: 5 * 60000 }),
};

export const singlePayment = {
  initialList: (companyId, verificationIds) =>
    client.post(`/companies/${companyId}/signing-basket/initial_list/`, { verifications: verificationIds }),
  create: (companyId, payload) => client.post(`/companies/${companyId}/single-payment/`, payload, { timeout: 60000 }),
  checkSignStatus: (companyId, paymentId) =>
    client.get(`/companies/${companyId}/single-payment/${paymentId}/check_sign_status/`),
  checkPayment: (companyId, paymentId) =>
    client.get(`/companies/${companyId}/single-payment/${paymentId}/check_payment/`),
};

export const notifications = {
  list: (companyId, config) => client.get(`/companies/${companyId}/notifications/`, { ...config }),
};

export const euVat = {
  logs: (vatId) => client.get(`/eu-vat-history/${vatId}/`),
  uploadSKV: (companyId, vatId, file) => {
    const formData = new FormData();
    formData.append("file", file, file.name);

    return client.post(`/companies/${companyId}/eu-vats/${vatId}/upload_skv_file/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  downloadSKV: (companyId, vatId, contactData) =>
    client.post(`/companies/${companyId}/eu-vats/${vatId}/download_skv_file/`, contactData, {
      responseType: "blob",
    }),
  scan: (companyId, startDate, type, config) => {
    return client.post(
      `/companies/${companyId}/eu-vats/scan/`,
      {
        period_month: formatDate(startDate),
        period: type,
      },
      { ...config }
    );
  },
  scanAll: (filters, config) => {
    const newFilters = { ...filters, period_month: formatDate(filters.period_month) };
    // delete newFilters.period;
    delete newFilters.show_all;
    delete newFilters.ordering;
    newFilters.scan_all = true;
    return client.post(
      `/table/staff/eu-vats/scan_all/`,
      {},
      {
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
        params: newFilters,
        ...config,
      }
    );
  },
  markReport: (companyId, euVatId, isReported) =>
    client.patch(`/companies/${companyId}/eu-vats/${euVatId}/mark_report/`, {
      is_reported: isReported,
    }),
};

export const asset = {
  save: (companyId, data) => {
    if (data.id) {
      return client.put(`/companies/${companyId}/assets/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/assets/`, {
      ...data,
    });
  },
  remove: (companyId, assetId) => client.delete(`/companies/${companyId}/assets/${assetId}/`),
  get: (companyId, assetId) => client.get(`/companies/${companyId}/assets/${assetId}/`),
  decommission: (companyId, assetId, data) =>
    client.put(`/companies/${companyId}/assets/${assetId}/decommission/`, data),
  sell: (companyId, assetId, data) => client.put(`/companies/${companyId}/assets/${assetId}/sell/`, data),
  logs: (companyId, assetId) => client.get(`/asset-history/${assetId}/`),
};

export const exporter = {
  list: (companyId) => client.get(`/companies/${companyId}/export/`),
  listYear: (companyId, financialYearId) => client.get(`/companies/${companyId}/export/yearly/${financialYearId}/`),
  create: (companyId, financialYearId, category) =>
    client.post(`/companies/${companyId}/export/`, {
      category,
      company: companyId,
      financial_year: financialYearId,
    }),
  checkStatus: (companyId, exportId) => client.get(`/companies/${companyId}/export/${exportId}/check_status/`),
  getFile: (companyId, exportId) => client.get(`/companies/${companyId}/export/${exportId}/`),
  uploadYear: (file, companyId, financialYearId, category) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("company", companyId);
    formData.append("financial_year", financialYearId);
    formData.append("category", category);
    return client.post(`/companies/${companyId}/export/yearly/${financialYearId}/upload/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteYear: (companyId, financialYearId, exportId) =>
    client.delete(`/companies/${companyId}/export/yearly/${financialYearId}/${exportId}/`),
  notifyClient: (companyId, financialYearId) =>
    client.get(`/companies/${companyId}/export/yearly/${financialYearId}/notify_client/`),
};

export const kyc = {
  initiate: (companyId) => client.post(`/companies/${companyId}/kyc/initiate/`),
  check: (companyId) => client.get(`/companies/${companyId}/kyc/check/`),
};

export const vatPeriod = {
  list: (companyId) => client.get(`/table/companies/${companyId}/vat-periods/`),
  save: (companyId, data) => {
    if (data.id) {
      return client.put(`/companies/${companyId}/vat-periods/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/vat-periods/`, data);
  },
  remove: (companyId, vatPeriodId) => client.delete(`/companies/${companyId}/vat-periods/${vatPeriodId}/`),
};
