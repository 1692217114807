import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { OnboardingStateContext } from "../../provider/OnboardingProvider";
import "./StepHeader.scss";
import StepStatus from "../StepsProgress/StepStatus";

function StepHeader({ stepNumber, stepId, status }) {
  const stepNumberStr = `${stepNumber}`.padStart(2, "0");
  const { t } = useTranslation("company");
  return (
    <div className="step-header">
      <div className="step-container">
        <div className="step-container__number">{stepNumberStr}</div>
        <div>
          <div className="step-container__subtitle">
            {t("common:step")} {stepNumberStr}
          </div>
          <div className="step-container__title">{t(`onboarding.steps.${stepId}`)}</div>
        </div>
      </div>
      <StepStatus status={status} />
    </div>
  );
}

function StepHeaderWrapper({ rightComponent }) {
  const { activeStep } = useContext(OnboardingStateContext);
  return (
    <div className="step-wrapper">
      <StepHeader stepId={activeStep.id} status={activeStep.status} stepNumber={activeStep.number} />
      <div className="step-right-tool">{rightComponent}</div>
    </div>
  );
}

export default StepHeaderWrapper;
