import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as options from "api/options";
import { useAuthState } from "hooks/useAuth";
import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import Filters from "./CompanyFilters";
import Actions from "./CompanyActions";

function RedLabel({ value }) {
  return <span className="font-16">{value === 0 ? value : <strong className="text-danger">{value}</strong>}</span>;
}

function CompanyTable({ forStaff, selectCompany }) {
  const { t } = useTranslation("company");
  const {
    user: { id: userId, show_offices_filter: showOfficesFilter },
  } = useAuthState();

  const headers = useMemo(() => {
    const _headers = [
      {
        field: "name",
        label: t("common:name"),
        className: "text-left",
        width: 200,
        render: (row) => (
          <Button variant="link" onClick={() => selectCompany(row.id)} title={row.name}>
            {truncateText(row.name, 19)}
          </Button>
        ),
      },
      {
        field: "org_no",
        label: t("common:orgNo"),
      },
    ];
    if (!forStaff) {
      return [
        ..._headers,
        {
          field: "draft_outlays_count",
          label: t("draftOutlayCount"),
          canSort: false,
          render: (values) => <RedLabel value={values.stats.draft_outlays_count} />,
        },
        {
          field: "to_approve_count",
          label: t("toApproveCount"),
          canSort: false,
          render: (values) => <RedLabel value={values.stats.to_approve_count} />,
        },
        {
          field: "to_pay_count",
          label: t("toPayCount"),
          canSort: false,
          render: (values) => <RedLabel value={values.stats.to_pay_count} />,
        },
        {
          field: "conditional_last_reconciliation_date",
          canSort: false,
          label: t("manage.lastRecon"),
          className: "text-right",
          width: 110,
          render: (row) => row.conditional_last_reconciliation_date || "-",
        },
      ];
    }
    if (forStaff) {
      return [
        {
          field: "id",
          label: "#",
          canSort: true,
        },
        ..._headers,
        {
          field: "bank_name",
          label: t("common:bank"),
          width: 100,
          render: (row) =>
            row.bank_name === "other"
              ? row.other_bank_name || "-"
              : options.bankChoices.getOption(row.bank_name)?.label || "-",
        },
        {
          field: "unbooked_archives_count",
          label: t("unbookedArchives"),
          className: "text-right",
          width: 180,
          render: (row) =>
            row.unbooked_archives_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/file-archive?mode=new")}>
                {row.unbooked_archives_count}
              </Button>
            ) : (
              "-"
            ),
        },
        {
          field: "draft_si_count",
          label: t("draftSi"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.draft_si_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/supplier-invoices?mode=draft")}>
                {row.draft_si_count}
              </Button>
            ) : (
              "-"
            ),
        },
        {
          field: "investigate_si_count",
          label: t("investigateSi"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.investigate_si_count ? (
              <Button
                variant="link"
                onClick={() => selectCompany(row.id, "/consult/supplier-invoices?mode=investigate")}
              >
                {row.investigate_si_count}
              </Button>
            ) : (
              "-"
            ),
        },
        {
          field: "unpaid_si_inter_count",
          label: t("unpaidSi"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.unpaid_si_inter_count ? (
              <Button
                variant="link"
                onClick={() => selectCompany(row.id, "/consult/supplier-invoices?mode=unpaid&foreign=1")}
              >
                {row.unpaid_si_inter_count}
              </Button>
            ) : (
              "-"
            ),
        },
        {
          field: "investigate_outlay_count",
          label: t("investigateOutlays"),
          className: "text-right",
          width: 100,
          render: (row) =>
            row.investigate_outlay_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/outlays?mode=investigate")}>
                {row.investigate_outlay_count}
              </Button>
            ) : (
              "-"
            ),
        },
        {
          field: "unmatched_bank_count",
          sortField: "consult_info__unmatched_bank_count",
          label: t("common:bank"),
          className: "text-right",
          getRowClass: (row) => {
            return `text-right ${row.scanBankStatus}`;
          },
          width: 80,
          render: (row) =>
            !row.is_sole_type && row.unmatched_bank_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/bank-matching")}>
                {row.unmatched_bank_count}
              </Button>
            ) : (
              "-"
            ),
        },
        {
          field: "unmatched_tax_count",
          sortField: "consult_info__unmatched_tax_count",
          label: t("common:tax"),
          className: "text-right",
          width: 80,
          getRowClass: (row) => {
            return `text-right ${row.scanTaxStatus}`;
          },
          render: (row) =>
            row.unmatched_tax_count ? (
              <Button variant="link" onClick={() => selectCompany(row.id, "/consult/bank-matching/1630")}>
                {row.unmatched_tax_count}
              </Button>
            ) : (
              "-"
            ),
        },
        {
          field: "last_reconciliation_date",
          label: t("manage.lastRecon"),
          width: 110,
          className: "text-right",
          getRowClass: (row) => {
            return `text-right ${row.lastReconStatus}`;
          },
          render: (row) => row.last_reconciliation_date || "-",
        },
        {
          field: "consult_name",
          label: t("common:consult"),
          sortField: "consult__first_name",
        },
      ];
    }
    _headers.push({
      field: "consult_name",
      label: t("common:consult"),
      sortField: "consult__first_name",
    });
    return _headers;
  }, [t, forStaff, selectCompany]);

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      FiltersComponent={<Filters forStaff={forStaff} showOfficesFilter={showOfficesFilter} />}
      renderActions={(row) => <Actions row={row} forStaff={forStaff} userId={userId} />}
    />
  );
}

export default CompanyTable;
