import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { NumberRangeFilter, SearchFilter, VerificationSeriesPicker } from "components/filters";

function GlobalSearchFilters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount = !!filters.receiver + !!filters.payment_date;
  const [more, setMore] = useState(!!filtersCount);

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <VerificationSeriesPicker onFilter={setFilters} defaultValue={filters.series} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="outline-secondary"
            active={filters.status === "" || filters.status === "all"}
            onClick={() => setFilters({ status: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button variant="outline-draft" active={filters.status === 0} onClick={() => setFilters({ status: 0 })}>
            {t("common:statuses.draft")}
          </Button>
          <Button variant="outline-unpaid" active={filters.status === 2} onClick={() => setFilters({ status: 2 })}>
            {t("common:statuses.removed")}
          </Button>
          <Button variant="outline-paid" active={filters.status === 1} onClick={() => setFilters({ status: 1 })}>
            {t("common:statuses.booked")}
          </Button>
        </ButtonGroup>
      </section>
      {more && (
        <section className="more-filters">
          <NumberRangeFilter
            label={t("common:money.amountRange")}
            numberFrom={filters.amount_min}
            numberTo={filters.amount_max}
            onChange={(values) =>
              setFilters({
                amount_min: values.numberFrom,
                amount_max: values.numberTo,
              })
            }
          />
        </section>
      )}
    </>
  );
}

export default GlobalSearchFilters;
