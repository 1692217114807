import { OPEN_MODAL, TOGGLE_MODAL } from "state/types";

const initialState = {
  modalVisible: false,
  verification: null,
};

function verificationReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        modalVisible: true,
        verification: action.payload,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        modalVisible: !state.modalVisible,
        verification: action.payload,
      };
    default:
      throw new Error(`Unhandled type: ${action.type}`);
  }
}

export { initialState };

export default verificationReducer;
