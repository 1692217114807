import React, { useContext } from "react";

import { AbsenceCommentsButton, AbsenceHistoryButton, TooltipActionButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import DisputeModal from "components/modals/DisputeModal";
import * as absenceAPI from "api2/absence";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import useTable from "components/tables/btable/useTable";
import { toast } from "react-toastify";
import { SelectionContext } from "state/providers/SelectionProvider";
import { FileUrlModal } from "components/modals";

function EmployeeAbsenceActions({ row, companyId, forOffice }) {
  const { t } = useTranslation("common");
  const disputeModal = useModal();
  const { removeSelection } = useContext(SelectionContext);
  const {
    tableId,
    dataActions: { reload },
  } = useTable();

  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const fileModal = useModal();
  const openModal = () => {
    fileModal.open(`/absence-reports/${row.id}/pdf/`);
  };

  const handleDispute = async (values) => {
    await absenceAPI
      .dispute(companyId, row.id, values)
      .then(() => {
        if (removeSelection) {
          removeSelection(tableId, row);
        }
        reloadMenuNumbers();
        reload();
        disputeModal.close();
        toast.success(t("msg:updated"));
      })
      .catch((error) => {
        disputeModal.close();
        toast.error(t("msg:canNotExecuteAction"));
      });
  };

  return (
    <>
      <AbsenceHistoryButton reportId={row.id} />
      <AbsenceCommentsButton companyId={companyId} report={row} />
      <>
        {forOffice && <TooltipActionButton text={t("actions.summary")} onClick={openModal} icon="fas fa-file-pdf" />}
        {forOffice && fileModal.show && (
          <FileUrlModal companyId={companyId} fileUrl={fileModal.data} handleClose={fileModal.close} />
        )}
      </>
      {row.status === "prelim" && (
        <TooltipActionButton
          text={t("actions.dispute")}
          variant="dispute"
          onClick={disputeModal.open}
          icon="fas fa-ban"
        />
      )}
      {disputeModal.show && <DisputeModal handleClose={disputeModal.close} onSubmit={handleDispute} />}
    </>
  );
}

export default EmployeeAbsenceActions;
