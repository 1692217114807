import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import * as tableAPI from "api/table";
import { formatMonth, formatQuarter, parseDate } from "utils/date";
import { Loader } from "components/ui/loaders";
import cx from "classnames";

function MonthlyView({ eventsData }) {
  const part1 = eventsData.slice(0, 6);
  const part2 = eventsData.slice(6, 12);
  const part3 = eventsData.slice(12, 20);
  const renderTable = (part) => {
    if (!part) {
      return null;
    }
    return (
      <Table bordered className="manage-overview">
        <thead>
          <tr>
            {part.map((event) => (
              <th key={event.period_start}>{formatMonth(event.period_start)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {part.map((event) => (
              <td key={event.period_end} className={cx(event.id ? "green" : "red")}>
                {event.id ? "Reported" : "Not reported"}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    );
  };
  return (
    <>
      {renderTable(part1)}
      {renderTable(part2)}
      {renderTable(part3)}
    </>
  );
}

function QuarterlyView({ eventsData }) {
  return (
    <Table bordered className="manage-overview">
      <thead>
        <tr>
          {eventsData.map((event) => (
            <th key={event.period_start}>{formatQuarter(event.period_start)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {eventsData.map((event) => (
            <td key={event.period_end} className={cx(event.id ? "green" : "red")}>
              {event.id ? "Reported" : "Not reported"}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}

function YearlyView({ eventsData }) {
  const FormatYear = ({ startDate, endDate }) => {
    if (startDate.getFullYear() !== endDate.getFullYear()) {
      return `${startDate.getFullYear()}/${endDate.getFullYear()}`;
    }
    return startDate.getFullYear();
  };
  return (
    <Table bordered className="manage-overview">
      <thead>
        <tr>
          {eventsData.map((event) => (
            <th key={event.period_start}>
              <FormatYear startDate={event.period_start} endDate={event.period_end} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {eventsData.map((event) => (
            <td key={event.period_start} className={cx(event.id ? "green" : "red")}>
              {event.id ? "Reported" : ""}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}

function VatReportedPreviewModal({ companyId, vatPeriod, handleClose }) {
  const { t } = useTranslation("common");
  const [fetching, setFetching] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    tableAPI
      .skvCustomerEventsVAT(companyId)
      .then((response) => {
        setData(
          response.data.map((event) => ({
            ...event,
            period_start: parseDate(event.period_start),
            period_end: parseDate(event.period_end),
          }))
        );
      })
      .finally(() => {
        setFetching(false);
      });
  }, [companyId]);

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleClose}
      dialogClassName="confirmation-modal"
      size="lg"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("common:actions.preview")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fetching ? (
          <Loader />
        ) : (
          <>
            {vatPeriod.includes("monthly") && <MonthlyView eventsData={data} />}
            {vatPeriod.includes("quarterly") && <QuarterlyView eventsData={data} />}
            {vatPeriod.includes("yearly") && <YearlyView eventsData={data} />}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default VatReportedPreviewModal;
