import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SearchFilter } from "components/filters";
import useTable from "components/tables/btable/useTable";

function AccountPlanFilters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");

  return (
    <section className="table-filters-left">
      <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
      <ButtonGroup>
        <Button
          variant="outline-secondary"
          active={!filters.mode || filters.mode === "all"}
          onClick={() => setFilters({ mode: "" })}
        >
          {t("common:statuses.all")}
        </Button>
        <Button variant="outline-secondary" active={filters.mode === "B"} onClick={() => setFilters({ mode: "B" })}>
          {t("assets")}
        </Button>
        <Button variant="outline-secondary" active={filters.mode === "C"} onClick={() => setFilters({ mode: "C" })}>
          {t("liabilities")}
        </Button>
        <Button variant="outline-secondary" active={filters.mode === "D"} onClick={() => setFilters({ mode: "D" })}>
          {t("revenues")}
        </Button>
        <Button variant="outline-secondary" active={filters.mode === "E"} onClick={() => setFilters({ mode: "E" })}>
          {t("expenses")}
        </Button>
      </ButtonGroup>
    </section>
  );
}

export default AccountPlanFilters;
