import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { formatDatetime } from "utils/date";
import BTable from "components/tables/btable";
import Filters from "./TrashFilters";
import Actions from "./TrashActions";

function TrashTable({ selectable = false }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "company_name",
        label: t("common:company"),
        sortField: "company__name",
        canSort: true,
      },
      {
        field: "object_type",
        label: t("common:objectTypes.name"),
        render: (values) => t(`common:objectTypes.${values.object_type}`),
      },
      {
        field: "title",
        canSort: false,
        label: t("common:name"),
      },
      {
        field: "file_type",
        label: t("common:file.fileType"),
      },
      {
        field: "created",
        label: t("common:removedAt"),
        render: (values) => formatDatetime(values.created),
      },
      {
        field: "created_by_name",
        sortField: "created_by__last_name",
        label: t("common:removedBy"),
      },
    ],
    [t]
  );

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.total")}:</strong>
      {responseHeaders["x-pagination-count"]}
    </div>
  );

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters />}
      renderActions={(row) => <Actions row={row} />}
      renderTotals={renderTotals}
    />
  );
}

export default TrashTable;
