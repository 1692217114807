import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { formatDatetime } from "utils/date";
import { UserEditWidget } from "components/forms/UserForm";
import Filters from "./UserFilters";

function UserExtendedTable({ officeId }) {
  const { t } = useTranslation("common");

  const headers = useMemo(() => {
    const _headers = [
      {
        field: "name",
        label: t("common:name"),
        sortField: "last_name",
      },
      {
        field: "personal_number",
        label: t("contact.personalNumber"),
        canSort: false,
      },
      {
        field: "email",
        label: t("contact.emailLong"),
      },
      {
        field: "offices",
        label: t("navigation:agencies"),
        canSort: false,
        render: (values) => values.user_offices.map((office) => office.office_name).join(", "),
      },
      {
        field: "last_login",
        label: t("others:lastLogin"),
        render: (values) => (values.last_login ? formatDatetime(values.last_login) : "-"),
      },
    ];
    if (officeId) {
      _headers.splice(3, 1);
    }
    return _headers;
  }, [t, officeId]);

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters officeId={officeId} />}
          renderExpandedRow={(row) => <UserEditWidget id={row.id} />}
        />
      </Col>
    </Row>
  );
}

export default UserExtendedTable;
