import React, { useContext } from "react";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";
import UserMenu from "./UserMenu";
import OfficeSwitcher from "./OfficeSwitcher";
import NavBarLeftSide from "./NavBarLeftSide";

function TTNavBar() {
  const { selected, offices, selectOffice } = useContext(TimeTrackerStateContext);
  return (
    <div className="navbar-custom tt-layout">
      <NavBarLeftSide company={null} forDashboard={false} />
      <ul className="list-unstyled topnav-menu float-right mb-0">
        <li>
          <OfficeSwitcher selected={selected} offices={offices} onSelect={selectOffice} />
        </li>
        <li className="dropdown">
          <UserMenu />
        </li>
      </ul>
    </div>
  );
}

export default TTNavBar;
