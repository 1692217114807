import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { formatISO, isLastDayOfMonth, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";

import * as companyAPI from "api/company";
import { AllError, FormGroup } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import { codesForFinancialYears } from "components/perms/PermCodes";
import { CodeRequiredInList } from "components/perms";

function FinancialYearForm({ companyId, financialYear, successCallback, deleteCallback, userPermCodes }) {
  const { t } = useTranslation("company");

  const formikProps = {
    initialValues: {
      ...financialYear,
      date_start: financialYear.date_start && parseISO(financialYear.date_start),
      date_end: financialYear.date_end && parseISO(financialYear.date_end),
    },
    validationSchema: yup.object().shape({
      date_start: yup.date().nullable().required(),
      date_end: yup
        .date()
        .nullable()
        .required()
        .test("lastDayMonth", function (dateEnd) {
          if (dateEnd && !isLastDayOfMonth(dateEnd)) {
            return this.createError({
              path: this.path,
              message: t("msg:lastDayOfMonth"),
            });
          }
          return true;
        })
        .when("date_start", (startDate, schema) => {
          return startDate ? schema.min(startDate, t("common:errors.mustLaterStartDate")) : schema;
        }),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await companyAPI
        .saveCompanyFinancialYear(companyId, {
          ...values,
          date_start: formatISO(values.date_start, { representation: "date" }),
          date_end: formatISO(values.date_end, { representation: "date" }),
        })
        .then((response) => {
          if (!financialYear.id) {
            resetForm();
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onDelete = async () => {
    companyAPI
      .deleteCompanyFinancialYear(companyId, financialYear.id)
      .then(() => {
        if (deleteCallback) {
          toast.success(t("msg:deleted"));
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 4000 });
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.DatePicker label={t("common:starts")} name="date_start" isClearable required />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.DatePicker label={t("common:ends")} name="date_end" isClearable required />
              </Col>
            </Row>
            <AllError errors={errors} />
            <hr />
            <CodeRequiredInList code={codesForFinancialYears.manage} codeList={userPermCodes}>
              <div className="space-1">
                <SubmitButton isSubmitting={isSubmitting} />
                {financialYear.id && (
                  <RemoveButton
                    variant="danger"
                    confirmMessage={t("confirm.removeFYear", {
                      dateStart: financialYear.date_start,
                      dateEnd: financialYear.date_end,
                    })}
                    disabled={isSubmitting}
                    onClick={onDelete}
                  />
                )}
              </div>
            </CodeRequiredInList>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FinancialYearForm;
