import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./VATReportFilters";
import Actions from "./VATReportActions";

function VATReportTable({ companyId, forStatus, selectable = false }) {
  const { t } = useTranslation("tax");

  const headers = useMemo(
    () => [
      {
        field: "description",
        label: t("common:name"),
      },
      {
        field: "date_start",
        label: t("common:dates.period"),
        render: (values) => `${values.date_start} - ${values.date_end}`,
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        className: "text-right",
        render: (values) => formatMoney(values.amount),
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return { className: row.vat_report ? "paid" : "unpaid" };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} forStatus={forStatus} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default VATReportTable;
