import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import GlobalSupplierForm from "./GlobalSupplierForm";

function GlobalSupplierAddWidget() {
  const {
    dataActions: { reload },
  } = useTable();
  const supplier = {
    name: "",
    alias_name: "",
    org_no: "",
    contact_email: "",
    bankgiro_number: "",
    bic: "",
    iban: "",
    plusgiro_number: "",
    clearing_number: "",
    bank_account_number: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    bank_name: "",
    bank_code: "",
  };

  const { t } = useTranslation("company");
  return (
    <Row>
      <Col>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("si:actions.addGlobalSupplier")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <GlobalSupplierForm supplier={supplier} successCallback={reload} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default GlobalSupplierAddWidget;
