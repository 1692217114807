import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DocumentBox } from "components/ui/documents-refactored";
import { DraggableModalDialog } from "../DraggableModalDialog";

function DocumentsModalRefactored({ handleClose, companyId, documentIds, partUrl, removable = false }) {
  const { t } = useTranslation("common");
  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("documents")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {documentIds &&
          documentIds.map((docId) => (
            <DocumentBox
              partUrl={partUrl}
              companyId={companyId}
              key={`doc.${docId}`}
              documentId={docId}
              removable={removable}
            />
          ))}
      </Modal.Body>
    </Modal>
  );
}

export default DocumentsModalRefactored;
