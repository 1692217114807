import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import qs from "qs";
import { FilterButton } from "components/ui/buttons";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import useTable from "components/tables/btable/useTable";
import {
  DateRangeFilters,
  MoneyRangeFilter,
  MultiSelectSimpleFilter,
  SearchFilter,
  VerificationSeriesPicker,
} from "components/filters";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";
import { useCompanyState } from "hooks/useCompany";
import { formatDate } from "utils/date";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import customConfirm from "components/modals/ConfirmModal";
import ConfirmCrossedModal from "./ConfirmCrossedModal";

function VerificationFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { financialYear } = useContext(FinancialYearStateContext);
  const {
    costCenters: { asOptions: costOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const pdfModal = useModal();
  const { t } = useTranslation("ver");
  const filtersCount =
    !!filters.receiver + !!filters.payment_date + !!filters.amount_sek_min + !!filters.amount_sek_max;
  const [more, setMore] = useState(!!filtersCount);
  const showCostCenter = costOptions.filter(filterActiveCC).length !== 0;
  const showProjects = projectOptions.filter(filterActiveProjects).length !== 0;
  const openListPDF = async () => {
    const includeCrossedTrans = await customConfirm(({ giveAnswer }) => (
      <ConfirmCrossedModal giveAnswer={giveAnswer} />
    ));
    if (includeCrossedTrans === null) {
      return;
    }

    const params = qs.stringify(
      {
        ...filters,
        financial_year: financialYear && financialYear.id,
        booking_date__start: filters.booking_date__start ? formatDate(filters.booking_date__start) : undefined,
        booking_date__end: filters.booking_date__end ? formatDate(filters.booking_date__end) : undefined,
        cost_center: filters.costcenter_ids || [],
        project: filters.project_ids || [],
        crossed_trans: includeCrossedTrans,
      },
      { indices: false }
    );

    pdfModal.open(`/verifications/pdf/?${params}`);
  };
  const onCCChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };
  const onProjectChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <VerificationSeriesPicker onFilter={setFilters} defaultValue={filters.series} />
      </section>
      <section className="table-filters-right">
        <Button onClick={openListPDF}>{t("verificationsListPdf")}</Button>
      </section>
      {more && (
        <section className="more-filters">
          <DateRangeFilters
            label={t("common:dates.bookingDate")}
            dateStart={filters.booking_date__start}
            dateEnd={filters.booking_date__end}
            onChange={({ start, end }) => setFilters({ booking_date__start: start, booking_date__end: end })}
          />
          <MoneyRangeFilter
            label={t("common:money.amountRange")}
            numberFrom={filters.transaction_range_min}
            numberTo={filters.transaction_range_max}
            onChange={(values) =>
              setFilters({
                transaction_range_min: values.numberFrom,
                transaction_range_max: values.numberTo,
              })
            }
          />
          {showCostCenter && (
            <MultiSelectSimpleFilter
              label={t("common:costCenter")}
              defaultValue={filters.cost_centers}
              options={costOptions}
              isClearable={false}
              name="cost_centers"
              onFilter={onCCChange}
            />
          )}
          {showProjects && (
            <MultiSelectSimpleFilter
              label={t("common:project")}
              defaultValue={filters.projects}
              options={projectOptions}
              isClearable={false}
              name="projects"
              onFilter={onProjectChange}
            />
          )}
        </section>
      )}
      {pdfModal.show && pdfModal.data && (
        <FileUrlModal companyId={companyId} fileUrl={pdfModal.data} handleClose={pdfModal.close} />
      )}
    </>
  );
}

export default VerificationFilters;
