const MONTHS = {
  sv: [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ],
};
const DAYS = {
  sv: ["Sö", "Må", "Ti", "On", "To", "Fr", "Lö"],
};

const locale = { MONTHS, DAYS };

export default locale;
