import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import LanguageSwitcher from "components/ui/LanguageSwitcher";

import logoLight from "assets/images/bflow-logo.png";
import SystemNotificationBar from "components/layouts/DashboardLayout/SystemNotificationBar";

function AuthLayout() {
  useEffect(() => {
    document.body.classList.add("authentication-bg");
    return () => {
      document.body.classList.remove("authentication-bg");
    };
  });

  return (
    <div id="auth-wrapper">
      <div className="home-btn d-none d-sm-block">
        <a href="https://bfree.se">
          <i className="fas fa-home h2" />
        </a>
      </div>
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div>
                <div className="text-center mb-4">
                  <a href="/">
                    <img src={logoLight} alt="" height="100" />
                  </a>
                </div>
              </div>
              <SystemNotificationBar />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <LanguageSwitcher />
    </div>
  );
}

export default AuthLayout;
