import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { FinancialYearEditWidget } from "components/forms/company-settings-forms/FinancialYearForm";
import Actions from "./FinancialYearActions";

function FinancialYearExtendedTable({ companyId, userPermCodes }) {
  const { t } = useTranslation("company");
  const headers = useMemo(
    () => [
      {
        field: "date_start",
        label: t("common:starts"),
      },
      {
        field: "date_end",
        label: t("common:ends"),
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={null}
          renderActions={(row) => <Actions companyId={companyId} row={row} userPermCodes={userPermCodes} />}
          renderExpandedRow={(row) => (
            <FinancialYearEditWidget companyId={companyId} id={row.id} userPermCodes={userPermCodes} />
          )}
        />
      </Col>
    </Row>
  );
}

export default FinancialYearExtendedTable;
