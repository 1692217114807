import React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { SubmitButton } from "components/ui/buttons";
import { CustomerPicker } from "components/formik";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { useModalOnPage } from "../hooks/useModalOnPage";
import { useModalScroll } from "../hooks/useModalScroll";

function ReInvoiceModal({ companyId, handleClose, onSubmit }) {
  const { t } = useTranslation(["common", "msg"]);
  const isModal = useModalOnPage();
  const { shouldScroll } = useModalScroll();

  const formikProps = {
    initialValues: {
      customer: null,
    },
    validationSchema: yup.object().shape({
      customer: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      return onSubmit(values).catch((error) => {
        setErrors(error.data);
      });
    },
  };
  return (
    <Modal show onHide={handleClose} size="lg" dialogAs={DraggableModalDialog} scrollable={shouldScroll}>
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue, isSubmitting }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="m-0">{t("common:actions.reInvoice")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <CustomerPicker
                    name="customer"
                    label={t("common:customer")}
                    companyId={companyId}
                    customer={values.customer}
                    onReset={() => setFieldValue("customer", null)}
                    required
                    menuPlacement={isModal ? "bottom" : "auto"}
                    menuPosition={isModal ? "absolute" : "fixed"}
                  />
                </Col>
              </Row>
              {values.customer && (
                <Alert variant="warning">{t("msg:reInvoiceText", { customer: values.customer.Name })}</Alert>
              )}
              {errors && errors.__all__ && <Alert variant="danger">{errors.__all__}</Alert>}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ReInvoiceModal;
