import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import OutlayListPage from "./OutlayListPage";

function PayOutlayListPage() {
  const canPayOutlays = HasPermCode(codesFor.outlays.pay);
  return <OutlayListPage tableId="unpaidOutlays" initialMode="unpaid" selectable={canPayOutlays} />;
}

export default PayOutlayListPage;
