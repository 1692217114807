import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { Trans, useTranslation } from "react-i18next";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";
import { SubmitButton } from "components/ui/buttons";
import * as vatAPI from "api/vat";
import { confirmExecute } from "components/modals/ConfirmModal";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import client from "api/client";
import { saveBlobToComputer } from "utils/file";

function BookVATReportModal({ handleClose, companyId, data, downloadXML, openVatIssuedVerifications }) {
  const { t } = useTranslation("tax");
  const [isSubmitting, setSubmitting] = useState(false);
  const hasVatReport = !!data.vat_report;
  const [showWarning, setShowWarning] = useState(false);
  const { financialYear } = useContext(FinancialYearStateContext);

  useEffect(() => {
    const fetchData = () => {
      vatAPI
        .showWarning(companyId, financialYear.id, data.id)
        .then((shouldShowWarning) => {
          setShowWarning(shouldShowWarning);
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    };

    fetchData();
  }, [companyId, financialYear.id, data.id, t]);

  function autoDownloadOnBook(eventId) {
    if (!eventId) {
      return;
    }
    const fileUrl = `/companies/${companyId}/reports/${financialYear.id}/events/${eventId}/vat_xml_report/`;
    client
      .get(fileUrl, {
        responseType: "blob",
      })
      .then((response) => {
        saveBlobToComputer(response, "Momsrapport.xml", false);
      });
  }

  const saveVatReport = async () => {
    const canLock = await vatAPI.canLockPeriod(companyId, financialYear.id, {
      event: data.id,
    });
    let answer = true;
    if (!canLock) {
      answer = await confirmExecute(t("confirm.sendVATNoLock"), "tax:confirm.bookVATWithoutLock");
    }
    if (!answer) {
      return;
    }
    setSubmitting(true);
    await vatAPI
      .save(companyId, financialYear.id, { event: data.id })
      .then((response) => {
        handleClose(true);
        autoDownloadOnBook(response.data.event);
        toast.success(t("vatBooked"));
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.status === 400) {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(error.data);
          }
        } else {
          toast.error(t("vatCanNotBook"));
        }
      });
  };

  return (
    <FileUrlModalOld
      show
      handleClose={handleClose}
      fileUrl={`/companies/${companyId}/reports/${financialYear.id}/events/${data.id}/vat_pdf_report/`}
      headerChildren={
        showWarning && (
          <Alert variant="warning">
            <Trans i18nKey="tax:vatWarning">Note that there is an opening balance on one or more VAT accounts.</Trans>
          </Alert>
        )
      }
    >
      <Modal.Footer className="space">
        <Button variant="outline-primary" onClick={openVatIssuedVerifications}>
          {t("checkVerificationsDeviations")}
        </Button>
        {hasVatReport ? (
          <Button variant="primary" size="lg" onClick={downloadXML}>
            {t("common:actions.download")} XML
          </Button>
        ) : (
          <SubmitButton isSubmitting={isSubmitting} onClick={saveVatReport} title="tax:confirm.bookVAT" />
        )}
      </Modal.Footer>
    </FileUrlModalOld>
  );
}

export default BookVATReportModal;
