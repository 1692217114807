import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as companyAPI from "api/company";
import { confirmExecute } from "components/modals/ConfirmModal";
import Box from "./Box";
import AzoraProgenitorBox from "./AzoraProgenitorBox";
import { StaffPermRequired } from "../../../perms";

function ExtraActions({ companyId }) {
  const { t } = useTranslation("company");
  const [loading, setLoading] = useState(false);

  const forceApproverReminderEmails = async () => {
    const answer = await confirmExecute(`${t("confirm.sendApproverEmails")}`);
    if (answer) {
      setLoading(true);
      await companyAPI
        .forceReminderEmails(companyId)
        .then((response) => {
          toast.success(t("msg:generated"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const copySpecifications = async () => {
    const answer = await confirmExecute(`${t("confirm.copySpecs")} ?`);
    if (answer) {
      setLoading(true);
      await companyAPI
        .specificationsCopy(companyId)
        .then((response) => {
          toast.success(t("msg:generated"));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Row>
        <Col xl={6}>
          <Box
            t={t}
            loading={loading}
            title={t("reminderEmail")}
            info={t("reminderEmailInfo")}
            onExecute={forceApproverReminderEmails}
          />
        </Col>
        <Col xl={6}>
          <Box
            t={t}
            loading={loading}
            title={t("copyRecon")}
            info={t("copyReconInfo")}
            onExecute={copySpecifications}
          />
        </Col>
      </Row>
      <StaffPermRequired>
        <Row>
          <Col xl={6}>
            <AzoraProgenitorBox companyId={companyId} />
          </Col>
        </Row>
      </StaffPermRequired>
    </>
  );
}

export default ExtraActions;
