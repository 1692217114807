import React from "react";
import { useTranslation } from "react-i18next";

import { TooltipActionButton } from "components/ui/buttons";

function VerificationActions({ row, companyId, onModalClose }) {
  const { t } = useTranslation("common");

  const onSelect = async () => {
    await onModalClose(row);
  };

  return <TooltipActionButton text={t("actions.select")} onClick={onSelect} icon="fas fa-hand-pointer" />;
}

export default VerificationActions;
