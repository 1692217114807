import React, { useContext } from "react";
import { Table } from "react-bootstrap";

import "./WeekSummary.scss";
import { useTranslation } from "react-i18next";
import { TimeGridStateContext } from "state/providers/TimeGridProvider";
import { formatDurationShort } from "utils/date";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";

function WeekSummary() {
  const { t } = useTranslation("tt");
  const { selected: selectedOffice } = useContext(TimeTrackerStateContext);
  const { reported } = useContext(TimeGridStateContext);
  const isOfficeCompanyEmployee = selectedOffice.is_main_company_employee;
  const reportedTime = isOfficeCompanyEmployee ? reported.presence + reported.absence : reported.logsTime;
  return (
    <div className="week-summary" translate="no">
      <p className="mb-1">{t("timegrid.summaryOfWeek")}</p>
      <Table bordered size="sm">
        <tbody>
          <tr>
            <th>{t("timegrid.totalReportedTime")}</th>
            <td style={{ width: "50%" }}>{formatDurationShort(reportedTime)}</td>
          </tr>
          {isOfficeCompanyEmployee && (
            <>
              <tr>
                <th>- {t("timegrid.ofAbsence")}</th>
                <td>{formatDurationShort(reported.absence)}</td>
              </tr>
              <tr>
                <th>- {t("timegrid.ofPresence")}</th>
                <td>{formatDurationShort(reported.presence)}</td>
              </tr>
              <tr>
                <th>{t("timegrid.timeBankTotal")}</th>
                <td>
                  {reported.timeBank < 0 && "-"}
                  {formatDurationShort(Math.abs(reported.timeBank))}
                </td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default WeekSummary;
