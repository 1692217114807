import _ from "lodash";

import {
  RESET_COMPANY,
  SET_COMPANY_ERROR,
  SET_COMPANY_OK,
  SET_COMPANY_WAIT,
  SET_CURRENT_FYEAR,
  SET_LOADED,
  SET_LOADING,
  SET_MENU_NUMBERS,
  UPDATE_COMPANY,
} from "state/types";
import { parseDate } from "utils/date";

const initialState = {
  perms: {
    canApproveOutlay: false,
    isOfficeConsult: false,
  },
  company: null,
  employee: {},
  financialYears: [],
  financialYearSelected: null,
  accounts: { byId: {}, asOptions: [] },
  receiptAccounts: [],
  currencies: { byId: {}, asOptions: [] },
  projects: { byId: {}, asOptions: [] },
  costCenters: { byId: {}, asOptions: [] },
  menuNumbers: {
    draft: {
      outlays: 0,
    },
    prelim: {
      supplier_invoices: 0,
      outlays: 0,
      salaries: 0,
      employee_absences: 0,
      count: 0,
    },
    unpaid: {
      supplier_invoices: 0,
      outlays: 0,
      salaries: 0,
      direct_payments: 0,
      taxes: 0,
      count: 0,
    },
    sent_bank: {
      supplier_invoices: 0,
      outlays: 0,
      salaries: 0,
      direct_payments: 0,
      taxes: 0,
      count: 0,
    },
  },
  loading: true,
};

function companyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMPANY_WAIT:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING:
      return {
        ...initialState,
        loading: true,
      };
    case SET_LOADED:
      return {
        ...state,
        loading: false,
      };
    case SET_COMPANY_OK: {
      const currencies = [
        {
          value: "SEK",
          code: "SEK",
          label: "SEK - Swedish Krona",
          label_en: "SEK - Swedish Krona",
          label_sv: "SEK - Svensk Krona",
        },
        ...action.payload.currencies.map((currency) => ({
          ...currency,
          value: currency.code,
          label: `${currency.code} - ${currency.description}`,
          label_en: `${currency.code} - ${currency.description_en}`,
          label_sv: `${currency.code} - ${currency.description_sv}`,
        })),
      ];
      const accounts = action.payload.accounts.map((account) => ({
        ...account,
        id: account.number,
        value: account.number,
        label: `${account.number} - ${account.name}`,
        isDisabled: !account.is_active,
        project_option: account.project_option,
        cost_center_option: account.cost_center_option,
      }));
      const receiptAccounts = action.payload.receipt_accounts.map((account) => ({
        id: account.number,
        value: account.number,
        number: account.number,
        label: account.name,
        isDisabled: !account.is_active,
        project_option: account.project_option,
        cost_center_option: account.cost_center_option,
      }));
      const paymentAccounts = action.payload.payments_accounts.map((account) => ({
        id: account.number,
        value: account.number,
        number: account.number,
        label: `${account.number} - ${account.name}`,
        isDisabled: !account.is_active,
        project_option: account.project_option,
        cost_center_option: account.cost_center_option,
      }));
      const projects = action.payload.projects.map((project) => ({
        ...project,
        value: project.id,
        label: `${project.serial} ${project.name}`.trim(),
        isDisabled: !project.is_active,
      }));
      const costCenters = action.payload.cost_centers.map((costCenter) => ({
        ...costCenter,
        value: costCenter.id,
        label: `${costCenter.serial} ${costCenter.name}`.trim(),
        isDisabled: !costCenter.is_active,
      }));
      return {
        ...state,
        employee: action.payload.employee,
        perms: {
          isEmployee: action.payload.user_privileges.is_employee || false,
          isAdmin: action.payload.user_privileges.is_superuser || false,
          isOfficeConsult: action.payload.user_privileges.is_office_consult || false,
          isAuditor: action.payload.user_privileges.is_auditor || false,
        },
        userPermCodes: action.payload.user_privileges.permissions_codes,
        company: { ...action.payload.company, ttOnly: action.payload.company.status === "basic" },
        financialYears: action.payload.financial_years.map((fYear) => ({
          ...fYear,
          date_start: parseDate(fYear.date_start),
          date_end: parseDate(fYear.date_end),
          value: fYear.id,
          label: `${fYear.date_start} — ${fYear.date_end}`,
        })),
        financialYearSelected: action.payload.pre_selected_year_id,
        accounts: {
          byId: _.keyBy(accounts, (account) => account.number),
          asOptions: accounts,
        },
        receiptAccounts: _.sortBy(receiptAccounts, ["label", "value"]),
        paymentAccounts: {
          byId: _.keyBy(paymentAccounts, (account) => account.number),
          asOptions: paymentAccounts,
        },
        projects: {
          byId: _.keyBy(projects, (project) => project.id),
          asOptions: projects,
        },
        costCenters: {
          byId: _.keyBy(costCenters, (costCenter) => costCenter.id),
          asOptions: costCenters,
        },
        currencies: {
          byId: _.keyBy(
            [
              {
                value: "SEK",
                code: "SEK",
                label: "SEK - Swedish Krona",
                label_en: "SEK - Swedish Krona",
                label_sv: "SEK - Svensk Krona",
              },
              ...currencies,
            ],
            (account) => account.code
          ),
          asOptions: currencies,
        },
      };
    }
    case SET_COMPANY_ERROR:
      return { ...initialState, loading: false };
    case UPDATE_COMPANY:
      return { ...state, company: { ...state.company, ...action.payload } };
    case RESET_COMPANY:
      return { ...initialState, loading: true };
    case SET_MENU_NUMBERS:
      return { ...state, menuNumbers: action.payload };
    case SET_CURRENT_FYEAR:
      return { ...state, financialYearSelected: action.payload };
    default:
      throw new Error(`Unhandled type: ${action.type}`);
  }
}

export { initialState };

export default companyReducer;
