import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { OFFICE_PERMISSION_ROLES } from "utils/permissions/officePermissions";
import { useTranslation } from "react-i18next";
import { CHECKBOX_CLASSES } from "utils/permissions/constants";
import { handleParentCheckboxChange } from "utils/permissions/helpers";
import { PERMISSION_MODULE_TYPE } from "./models";

const { checked, partiallyChecked, allowed } = CHECKBOX_CLASSES;

function ParentCheckBoxes({ permissionModule, permsChecked, setPermsChecked, withExpandClass, expandParentRow }) {
  const { label, id, children: childPerms } = permissionModule;

  const {
    i18n: { language },
  } = useTranslation();

  return (
    <tr className={cx("parent", withExpandClass)} key={id}>
      <td onClick={expandParentRow}>
        <button className="arrow-btn" type="button">
          {withExpandClass ? <i className="fe-chevron-up" /> : <i className="fe-chevron-down" />}
        </button>
        <span>{label[language]}</span>
      </td>

      {OFFICE_PERMISSION_ROLES.map((role) => {
        const allCodes = childPerms
          .filter((currentPerm) => currentPerm.role === role)
          .map((currentPerm) => currentPerm.code);
        const enabledCodes = allCodes.filter((code) => permsChecked.includes(code));
        const isSomethingChecked = !!enabledCodes.length;

        const forAllChecked = allCodes.length === enabledCodes.length && isSomethingChecked ? checked : "";
        const forPartiallyChecked = isSomethingChecked ? partiallyChecked : "";
        const forAllowed = allCodes.length ? allowed : "";

        const styles = forAllChecked || forPartiallyChecked || forAllowed;

        return (
          <td key={permissionModule.value}>
            <div className={cx("checkbox-wrapper", styles)}>
              <button
                onClick={() => handleParentCheckboxChange(role, setPermsChecked, isSomethingChecked, allCodes)}
                type="button"
                className="checkbox"
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
}

ParentCheckBoxes.propTypes = {
  permissionModule: PERMISSION_MODULE_TYPE,
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
  withExpandClass: PropTypes.string.isRequired,
  expandParentRow: PropTypes.func.isRequired,
};

export default React.memo(ParentCheckBoxes);
