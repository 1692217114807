import React, { useCallback, useMemo } from "react";
import _ from "lodash";

import { quickInfo } from "api/company";
import useAsync from "hooks/useAsync";
import { useParams } from "react-router-dom";

const CompanySettingStateContext = React.createContext(undefined);

function CompanySettingProvider({ children, companyId: propCompanyId }) {
  const { companyId: paramsCompanyId } = useParams();
  const companyId = propCompanyId ?? paramsCompanyId;
  const dataSource = useCallback((cancelToken) => quickInfo(companyId, { cancelToken }), [companyId]);
  const [{ data: item, loading }, { updateData }] = useAsync(dataSource, {});
  const stateValue = useMemo(
    () => ({
      info: item,
      userPermCodes: !loading && !_.isEmpty(item) && item.user_privileges.permissions_codes,
      perms: !loading &&
        !_.isEmpty(item) && {
          isSuperUser: item.user_privileges.is_superuser,
          isOfficeConsult: item.user_privileges.is_office_consult || false,
        },
      loading,
      updateInfo: updateData,
    }),
    [item, loading, updateData]
  );

  return <CompanySettingStateContext.Provider value={stateValue}>{children}</CompanySettingStateContext.Provider>;
}

export { CompanySettingStateContext };

export default CompanySettingProvider;
