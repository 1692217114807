import ReactDatePicker from "react-datepicker";
import React from "react";

import { DATE_FORMAT, SHORT_DATE_FORMAT } from "utils/date";

function BMDDatePicker({ defaultValue, onSelect, ...props }) {
  const [selected, setSelected] = React.useState(defaultValue);

  function onChange(newSelected) {
    setSelected(newSelected);
    onSelect(newSelected);
  }

  return (
    <ReactDatePicker
      className="form-control"
      selected={selected}
      isClearable
      dateFormat={[DATE_FORMAT, SHORT_DATE_FORMAT]}
      onChange={(date) => onChange(date)}
      {...props}
      popperPlacement="left-start"
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
    />
  );
}
export default BMDDatePicker;
