import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SalaryForm from "components/forms/SalaryForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as salaryAPI from "api/salaries";
import { RocketLoader } from "components/ui/loaders";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";

function SalaryAddPage() {
  const company = useOutletContext();
  const { t } = useTranslation("salary");
  const navigate = useNavigate();
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const { loading, item } = useInitialAsync(() => {
    return salaryAPI.employees(company.id);
  });

  if (loading) {
    return <RocketLoader />;
  }

  const onCreate = (newItem) => {
    reloadMenuNumbers();
    navigate(-1);
  };

  const salaries = {
    booking_date: new Date(),
    payment_date: null,
    salaries: item.map((employee) => ({
      employee: employee.user_name,
      receiver: employee.user,
      account: employee.account,
      paid_to: { value: "P", label: t("common:accountP") },
      salary_gross: 0,
      fringe_benefits: 0,
      soc_sec: 0,
      personal_tax: 0,
      tax_free: 0,
      holiday_allowance: 0,
      holiday_allowance_soc_sec: 0,
      selected: false,
      specification: null,
      amount: 0,
      cost_center: null,
      project: null,
    })),
  };
  return <SalaryForm companyId={company.id} salaries={salaries} onSave={onCreate} />;
}

export default SalaryAddPage;
