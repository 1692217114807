import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import NavBar from "components/ui/navbar";
import { useCompanyDispatch } from "hooks/useCompany";
import cx from "classnames";
import { SetDocTitle } from "components/ui/titles/PageTitle";
import OfficeSupportMenu from "./OfficeSupport/OfficeSupportMenu";

function OfficeSupportCompanyListLayout({ children }) {
  const { t } = useTranslation(["company"]);
  const { reset } = useCompanyDispatch();

  useEffect(() => {
    reset();
  }, [reset]);
  return (
    <>
      <div className="company-list">
        <NavBar company={null} forDashboard={false} />
        <OfficeSupportMenu />
        <div className={cx("content-page")}>
          <div className="content pt-3">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </div>
      <SetDocTitle title={t("officeSupport")} />
    </>
  );
}

export default OfficeSupportCompanyListLayout;
