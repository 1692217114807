import React, { useContext, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import { FieldArray, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";

import * as verAPI from "api2/verification";
import { confirmExecute } from "components/modals/ConfirmModal";
import { FormGroup, TableGroup } from "components/formik";
import { TooltipActionButton, VerificationHistoryButton } from "components/ui/buttons";
import { formatMoney, roundMoney } from "utils/money";
import { collectionSum } from "utils/list";
import { CheckboxFilter, SelectSimpleFilter } from "components/filters";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { BalanceStateContext } from "state/providers/BalanceProvider";
import { handleActionErrors } from "api/errors";
import { toast } from "react-toastify";
import { appendAutoAccounts, emptyTransaction } from "utils/verification";
import { periodisationAccounts, periodisationToogleAccounts } from "utils/periodisation";
import { isAssetToAutoAddBasedOnTransactions } from "utils/assets";
import { HasPermCode } from "components/perms";
import { codesForInventoryRegister } from "components/perms/PermCodes";
import TransactionBalance from "./TransactionBalance";

function VerificationForm({
  isDeleted,
  isConsult,
  accountOptions,
  accountById,
  perCostAccountOptions,
  projectOptions,
  centerOptions,
  verificationId,
  verificationSeries,
  companyId,
}) {
  const { t } = useTranslation("ver");
  const bookingDateDisabled = verificationSeries === "C" || verificationSeries === "S";
  const [globalProject, setGlobalProject] = useState(undefined);
  const [globalCC, setGlobalCC] = useState(undefined);
  return (
    <>
      <Row>
        <Col md={3}>
          <FormGroup.BookingDatePicker
            label={t("common:dates.bookingDate")}
            name="booking_date"
            popperClassName="popper-in-modal"
            isClearable={false}
            readOnly={bookingDateDisabled}
            required
          />
        </Col>
        <Col md={6}>
          <FormGroup.Input label={`${t("common:verShort")} ${t("common:name")}`} name="title" required />
        </Col>
        {verificationId && !isDeleted && (
          <Col md={3}>
            <VerificationHistoryButton verificationId={verificationId} verLogsOnly />
          </Col>
        )}
      </Row>
      <GlobalPRCC
        projectOptions={projectOptions}
        centerOptions={centerOptions}
        setGlobalProject={setGlobalProject}
        setGlobalCC={setGlobalCC}
      />
      <FieldArray
        name="transactions"
        render={(arrayHelper) => (
          <TransactionsForm
            isDeleted={isDeleted}
            isConsult={isConsult}
            arrayHelper={arrayHelper}
            accountOptions={accountOptions}
            accountById={accountById}
            perCostAccountOptions={perCostAccountOptions}
            projectOptions={projectOptions}
            centerOptions={centerOptions}
            verificationId={verificationId}
            verificationSeries={verificationSeries}
            globalProject={globalProject}
            globalCC={globalCC}
            companyId={companyId}
          />
        )}
      />
    </>
  );
}

function GlobalPRCC({ projectOptions, centerOptions, setGlobalProject, setGlobalCC }) {
  const { t } = useTranslation("common");
  const {
    values: { transactions },
    setFieldValue,
  } = useFormikContext();
  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0;
  const showCostCenter = activeCenters.length !== 0;
  const onSelect = ({ project, costCenter }) => {
    if (project !== undefined) {
      const projectOption = project ? activeProjects.filter((option) => option.value === project)[0] : null;
      setGlobalProject(projectOption);
      setFieldValue(
        "transactions",
        transactions.map((tran) => ({
          ...tran,
          project: !!tran.account && !tran.is_deleted ? projectOption : null,
        }))
      );
    } else if (costCenter !== undefined) {
      const ccOption = costCenter ? activeCenters.filter((option) => option.value === costCenter)[0] : null;
      setGlobalCC(ccOption);
      setFieldValue(
        "transactions",
        transactions.map((tran) => ({
          ...tran,
          cost_center: !!tran.account && !tran.is_deleted ? ccOption : null,
        }))
      );
    }
  };

  if (!showProjects && !showCostCenter) {
    return null;
  }
  return (
    <Row>
      {showProjects && (
        <Col md={3}>
          <SelectSimpleFilter
            options={activeProjects}
            label={t("project")}
            isClearable
            name="project"
            onFilter={onSelect}
          />
        </Col>
      )}
      {showCostCenter && (
        <Col md={3}>
          <SelectSimpleFilter
            options={activeCenters}
            label={t("costCenter")}
            isClearable
            name="costCenter"
            onFilter={onSelect}
          />
        </Col>
      )}
    </Row>
  );
}

function TransactionsForm({
  isDeleted,
  isConsult,
  accountOptions,
  accountById,
  perCostAccountOptions,
  projectOptions,
  centerOptions,
  arrayHelper,
  verificationId,
  verificationSeries,
  globalProject,
  globalCC,
  companyId,
}) {
  const { t } = useTranslation("ver");
  const { values, errors, setValues, setFieldValue } = useFormikContext();
  const [showDeleted, setShowDeleted] = useState(isDeleted);
  const { fetchBalance, updateBalances } = useContext(BalanceStateContext);
  const { transactions } = values;
  const debounceRef = useRef(null);
  const activeTrans = transactions.filter((tr) => !tr.is_deleted);
  const debit = roundMoney(collectionSum(activeTrans, "debit"));
  const credit = roundMoney(collectionSum(activeTrans, "credit"));
  const diff = roundMoney(debit - credit);
  const isManualVerification = verificationSeries === "M";
  const [fieldIndexesWithPerRowShowed, setFieldIndexesWithPerRowShowed] = useState([]);
  const [existPerConfigStatuses, setExistPerConfigStatuses] = useState(
    transactions
      .filter((tran) => !!tran.periodisation_config?.id)
      .reduce((result, filter) => {
        result[filter.periodisation_config.id] = filter.periodisation_config.status;
        return result;
      }, {})
  );
  const hasPermForInventoryRead = HasPermCode(codesForInventoryRegister.enabled);

  function undoRemoveTransaction({ transaction, index }) {
    arrayHelper.replace(index, { ...transaction, is_deleted: false });
    updateBalances(
      transactions.map((tran) => {
        if (tran.id !== transaction.id) {
          return tran;
        }
        return { ...tran, is_deleted: false };
      })
    );
  }

  function addTransaction(initialData) {
    const item = {
      ...emptyTransaction,
      uid: _.uniqueId("tran"),
      ...initialData,
    };
    arrayHelper.push(item);
    return item;
  }

  function onAccountSelected(account, index) {
    // try to have only 1 extra empty
    if (!periodisationAccounts.includes(account)) {
      setFieldValue(`transactions[${index}].periodisation_config`, null);
    }
    if (isConsult) {
      fetchBalance(account.value);
    }
    const lastIndex = transactions.length - 1;
    if (lastIndex === index) {
      setFieldValue(`transactions[${index}].project`, globalProject);
      setFieldValue(`transactions[${index}].cost_center`, globalCC);
    }
    if (!transactions[index].debit && !transactions[index].credit && transactions[transactions.length - 1].account) {
      addTransaction();
    } else if (lastIndex === index) {
      addTransaction();
    }
  }

  const onAmountChange = (account) => {
    if (account) {
      updateBalances(transactions);
    }
  };
  const creditOrDebitBlur = (event, index) => {
    if (event.key !== "Tab" && event.key !== "Enter") {
      return;
    }
    const transaction = transactions[index];
    if (diff > 0 && !transaction.debit && !transaction.credit) {
      transaction.credit = diff;
    } else if (diff < 0 && !transaction.debit && !transaction.credit) {
      transaction.debit = Math.abs(diff);
    }
    const newTransactions = appendAutoAccounts(transaction, index, transactions, accountById);
    newTransactions[index] = transaction;
    setValues({ ...values, transactions: newTransactions }, false);
  };

  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0 || transactions.filter((tr) => tr.project).length !== 0;
  const showCostCenter = activeCenters.length !== 0 || transactions.filter((tr) => tr.cost_center).length !== 0;

  function onClickPerTd(field, index) {
    if (verificationSeries === "PER") {
      return;
    }
    if (field.account.number === 1790) {
      if (field.id) {
        return;
      }
      const trans_account_field = `transactions[${index}].account`;
      setFieldValue(trans_account_field, accountById[1791]);
      onAccountSelected(accountById[1791], index);
      toast.warning(t("warning.perAccountChanged", { accountNumber: 1791 }));
    }
    if (field.account.number === 2990) {
      if (field.id) {
        return;
      }
      const trans_account_field = `transactions[${index}].account`;
      setFieldValue(trans_account_field, accountById[2991]);
      onAccountSelected(accountById[2991], index);
      toast.warning(t("warning.perAccountChanged", { accountNumber: 2991 }));
    }
    if (!field.periodisation_config) {
      setFieldIndexesWithPerRowShowed((f) => [...f, index]); // here you add the index
      setFieldValue(`transactions[${index}].periodisation_config`, { status: "creating", enabled: true });
    } else if (field.periodisation_config?.status === "creating") {
      setFieldIndexesWithPerRowShowed((f) => f.filter((i) => i !== index)); // here you remove the index
      setFieldValue(`transactions[${index}].periodisation_config`, null);
    }
  }

  function showHidePerRow(field, index) {
    if (field.periodisation_config?.enabled === true) {
      if (fieldIndexesWithPerRowShowed.includes(index)) {
        setFieldIndexesWithPerRowShowed((f) => f.filter((i) => i !== index)); // here you remove the index
      } else {
        setFieldIndexesWithPerRowShowed((f) => [...f, index]); // here you add the index
      }
    }
  }

  function hidePerRow(index) {
    if (fieldIndexesWithPerRowShowed.includes(index)) {
      setFieldIndexesWithPerRowShowed((f) => f.filter((i) => i !== index)); // here you remove the index
    }
  }

  function removeAllTransactions() {
    const newTransactions = [];
    for (let i = 0; i < transactions.length; i++) {
      if (transactions[i].id) {
        hidePerRow(i);
        newTransactions.push({
          ...transactions[i],
          is_deleted: true,
          periodisation_config: transactions[i].periodisation_config,
        });
      }
    }
    setValues({ ...values, transactions: [...newTransactions, { ...emptyTransaction, uid: _.uniqueId("tran") }] });
    updateBalances(newTransactions);
  }

  const removeTransaction = async ({ transaction, index }) => {
    if (
      transaction.periodisation_config?.id &&
      !["undone", "stopped_before_execution"].includes(existPerConfigStatuses[transaction.periodisation_config.id])
    ) {
      toast.error(t("error.undoPerBeforeTransDelete"));
      return null;
    }
    if (
      transaction.periodisation_config?.id &&
      existPerConfigStatuses[transaction.periodisation_config.id] === "undone"
    ) {
      const answer = await confirmExecute(t("ver:warning.onTranRemoveWithUndonePeriodisation"));
      if (!answer) {
        return null;
      }
    }
    if (!transaction.id) {
      arrayHelper.remove(index);
    } else {
      arrayHelper.replace(index, { ...transaction, is_deleted: true });
    }
    hidePerRow(index);
    updateBalances(
      transactions.map((tran) => {
        if (tran.id !== transaction.id) {
          return tran;
        }
        return { ...tran, is_deleted: true };
      })
    );
    return null;
  };

  function tdForPerColumn(field, index) {
    if (
      field.account &&
      periodisationToogleAccounts.includes(field.account.id) &&
      verificationSeries !== "PER" &&
      (!field.periodisation_config || field.periodisation_config.status === "creating")
    ) {
      if (field.id && !periodisationAccounts.includes(field.account.id)) {
        return <td />;
      }
      return (
        <td className="text-center periodisation-toogle" onClick={() => onClickPerTd(field, index)}>
          <i className={field.periodisation_config ? "fa fa-toggle-on" : "fa fa-toggle-off"} />
        </td>
      );
    }
    if (field.periodisation_config && field.periodisation_config.id) {
      return (
        <td className="text-center text-primary" onClick={() => showHidePerRow(field, index)}>
          <i className={fieldIndexesWithPerRowShowed.includes(index) ? "fa fa-angle-up" : "fa fa-angle-down"} />
        </td>
      );
    }
    return <td />;
  }

  function tdForDeleteColumn(field, index) {
    if (field.is_deleted) {
      return (
        <td className="text-center">
          <TooltipActionButton
            size="xs"
            icon="fas fa-undo-alt"
            variant="danger"
            text={t("common:actions.undoRemove")}
            disabled={isDeleted}
            onClick={() =>
              undoRemoveTransaction({
                transaction: field,
                index,
                arrayHelper,
              })
            }
          />
        </td>
      );
    }
    return (
      <td className="text-center">
        <TooltipActionButton
          size="xs"
          icon="fas fa-trash"
          variant="danger"
          text={t("common:actions.remove")}
          disabled={isDeleted}
          onClick={() =>
            removeTransaction({
              transaction: field,
              index,
              arrayHelper,
            })
          }
        />
      </td>
    );
  }

  const getWarningText = (newStatus) => {
    if (newStatus === "end") {
      return t("ver:confirm.endPeriodisation");
    }
    if (newStatus === "stop") {
      return t("ver:confirm.stopPeriodisation");
    }
    return t("ver:confirm.undoPeriodisation");
  };
  const onClickPerStatusChange = async (field, index, action) => {
    const textWarning = getWarningText(action);
    const answer = await confirmExecute(textWarning);
    if (answer) {
      await verAPI
        .changePeriodisationStatus(companyId, field.periodisation_config.id, action)
        .then((response) => {
          existPerConfigStatuses[field.periodisation_config.id] = response.data.status;
          setExistPerConfigStatuses({ ...existPerConfigStatuses });
          toast.success(t("msg:updated"));
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
    return null;
  };

  function trForPeriodisationConfig(field, index) {
    if (!field.account) {
      return null;
    }
    if (!field.periodisation_config) {
      return null;
    }
    if (!fieldIndexesWithPerRowShowed.includes(index)) {
      return null;
    }
    return (
      <tr>
        <td colSpan={12}>
          <table className="periodisation-row" width="100%">
            <tbody>
              <tr>
                <td className="label">{t("ver:perStartOn")}</td>
                <TableGroup.DatePicker
                  name={`transactions[${index}].periodisation_config.date_start`}
                  required
                  popperClassName="popper-in-modal"
                  tdProps={{ style: { width: 130, maxWidth: 130 } }}
                  disabled={field.periodisation_config?.id}
                />
                <td className="label">{t("common:months")}</td>
                <TableGroup.NumberInput
                  name={`transactions[${index}].periodisation_config.months`}
                  required
                  tdProps={{ style: { width: 95, maxWidth: 95 } }}
                  disabled={field.periodisation_config?.id}
                  min={1}
                />
                <td className="label">{t("ver:plAccount")}</td>
                <TableGroup.SimpleSelect
                  options={perCostAccountOptions}
                  name={`transactions[${index}].periodisation_config.pl_account`}
                  tdProps={{ style: { width: 160, maxWidth: 160 } }}
                  isDisabled={field.periodisation_config?.id}
                />
                {!(field.periodisation_config?.status === "creating") && (
                  <>
                    <td className="label">{t("common:statuses.status")}</td>
                    <td>{t(`common:${existPerConfigStatuses[field.periodisation_config.id]}`)}</td>
                  </>
                )}
                <td />
                <td />
                <td />
                {field.periodisation_config?.id &&
                  existPerConfigStatuses[field.periodisation_config.id] === "not_finished" && (
                    <td>
                      <ButtonGroup className="periodisation-actions-group-buttons">
                        <Button
                          type="button"
                          variant="outline-secondary"
                          onClick={() => onClickPerStatusChange(field, index, "end")}
                        >
                          <i className="fas fa-step-forward mr-1" />
                          {t("common:actions.end")}
                        </Button>
                        <Button
                          type="button"
                          variant="outline-secondary"
                          onClick={() => onClickPerStatusChange(field, index, "stop")}
                        >
                          <i className="fas fa-square mr-1" />
                          {t("common:actions.stop")}
                        </Button>
                        <Button
                          type="button"
                          variant="outline-secondary"
                          onClick={() => onClickPerStatusChange(field, index, "undo")}
                        >
                          <i className="fas fa fa-undo mr-1" />
                          {t("common:actions.undo")}
                        </Button>
                      </ButtonGroup>
                    </td>
                  )}
                {field.periodisation_config?.id &&
                  ["stopped", "ended", "finished"].includes(existPerConfigStatuses[field.periodisation_config.id]) && (
                    <td>
                      <ButtonGroup className="periodisation-actions-group-buttons">
                        <Button
                          type="button"
                          variant="outline-secondary"
                          onClick={() => onClickPerStatusChange(field, index, "undo")}
                        >
                          <i className="fas fa fa-undo mr-1" />
                          {t("common:actions.undo")}
                        </Button>
                      </ButtonGroup>
                    </td>
                  )}
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  function cleanInputAfterTyping(name, event, index) {
    const { value } = event.target;
    if (debounceRef.current) {
      debounceRef.current.cancel();
    }
    if (value) {
      const debounced = _.debounce(() => {
        setFieldValue(`transactions[${index}].${name}`, "");
      }, 800);
      debounced();
      debounceRef.current = debounced;
    }
  }

  return (
    <>
      <label>{t("transactions")}</label>
      <Button
        variant="primary"
        className="addTransBtn"
        size="xs"
        tabIndex="-1"
        title={t("addTransaction")}
        onClick={() => addTransaction()}
      >
        <i className="fas fa-plus" />
      </Button>
      <Table bordered responsive size="sm" className="transactions">
        <thead>
          <tr>
            <th style={{ minWidth: 100 }}>{t("common:account")}</th>
            <th>{t("common:description")}</th>
            {showProjects && <th>{t("common:project")}</th>}
            {showCostCenter && <th>{t("common:costCenter")}</th>}
            <th className="text-right" style={{ width: 100 }}>
              {t("common:money.debit")}
            </th>
            <th className="text-right" style={{ width: 100 }}>
              {t("common:money.credit")}
            </th>
            {isConsult && (
              <th className="text-right" style={{ width: 100 }}>
                {t("common:money.balance")}
              </th>
            )}
            <th className="text-right">PER</th>
            <th style={{ width: 50 }}>
              <TooltipActionButton
                size="xs"
                icon="fas fa-trash"
                variant="danger"
                text={t("common:actions.remove")}
                onClick={() => removeAllTransactions(arrayHelper)}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((field, index) => (
            <React.Fragment key={field.id || field.uid}>
              <tr
                className={cx({
                  removed: field.is_deleted,
                  "d-none": field.is_deleted && !showDeleted,
                })}
              >
                <TableGroup.SimpleSelect
                  options={accountOptions}
                  name={`transactions[${index}].account`}
                  isDisabled={field.is_deleted || !!field.id || !!field.autoParent}
                  onChange={(selected) => onAccountSelected(selected, index, arrayHelper)}
                />
                <TableGroup.Input name={`transactions[${index}].title`} disabled={field.is_deleted} />
                {showProjects && (
                  <TableGroup.SimpleSelect
                    options={activeProjects}
                    isClearable
                    name={`transactions[${index}].project`}
                    isDisabled={field.is_deleted || field.periodisation_config?.id}
                  />
                )}
                {showCostCenter && (
                  <TableGroup.SimpleSelect
                    options={activeCenters}
                    isClearable
                    name={`transactions[${index}].cost_center`}
                    isDisabled={field.is_deleted || field.periodisation_config?.id}
                  />
                )}
                {isManualVerification ? (
                  <>
                    <TableGroup.MoneyCalculationTableInput
                      name={`transactions[${index}].debit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("credit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                    />
                    <TableGroup.MoneyCalculationTableInput
                      name={`transactions[${index}].credit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("debit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                      tabIndex="-1"
                    />
                  </>
                ) : (
                  <>
                    <TableGroup.MoneyInput
                      name={`transactions[${index}].debit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("credit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                    />
                    <TableGroup.MoneyInput
                      name={`transactions[${index}].credit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("debit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                      tabIndex="-1"
                    />
                  </>
                )}
                {isConsult && (
                  <td className="text-right pr-1 disabled">
                    {field.account && !field.is_deleted && <TransactionBalance account={field.account.number} />}
                  </td>
                )}
                {}
                {tdForPerColumn(field, index)}
                {tdForDeleteColumn(field, index)}
              </tr>
              {trForPeriodisationConfig(field, index)}
              <TableGroup.RowErrors errors={errors.transactions && errors.transactions[index]} />
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr className="error">
            <td
              colSpan={2 + showCostCenter + showProjects}
              className={cx({
                "has-error": _.isString(errors.transactions),
              })}
            >
              {_.isString(errors.transactions) && errors.transactions}
            </td>
            <td className="text-right">{formatMoney(debit)}</td>
            <td className="text-right">{formatMoney(credit)}</td>
            <td />
            <td />
          </tr>
          {hasPermForInventoryRead &&
            isAssetToAutoAddBasedOnTransactions(values.transactions.filter((tran) => !tran.id)) && (
              <tr>
                <td colSpan={10}>
                  <Alert variant="warning">{t("assetRedirectionInfo")}</Alert>
                </td>
              </tr>
            )}
          <tr className="summary">
            <td colSpan={2 + showCostCenter + showProjects}>
              <CheckboxFilter
                label={t("showDeleted")}
                name="showDeleted"
                defaultChecked={showDeleted}
                onFilter={() => setShowDeleted(!showDeleted)}
              />
            </td>
            <td className="text-right">{t("common:money.deviation")}</td>
            <td>{formatMoney(diff)}</td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}

export default VerificationForm;
