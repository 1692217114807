import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as companyAPI from "api/company";
import { formatDate, formatMonthName, parseDate } from "utils/date";
import BTable from "components/tables/btable";
import { BMDDatePicker } from "components/forms/bmd";
import Filters from "./YearEndClosureFilters";
import YearEndClosureExtraHeader from "./YearEndClosureExtraHeader";

function YearEndClosureTable() {
  const { t } = useTranslation("company");

  async function updateInfo(companyId, field, value) {
    await companyAPI
      .saveCompanyManagement(companyId, {
        [field]: value ? formatDate(value) : null,
      })
      .then(() => {
        document.body.dispatchEvent(
          new CustomEvent("bmd/changed", {
            detail: { field, value },
          })
        );
      });
  }

  const headers = useMemo(() => {
    return [
      {
        field: "name",
        label: t("common:companyName"),
        width: 200,
      },
      {
        field: "org_no",
        label: t("common:orgNo"),
        canSort: false,
        width: 120,
      },
      {
        field: "latest_fyear_date_end",
        label: t("manage.financialYearMonth"),
        canSort: true,
        width: 120,
        render: (row) => (row.latest_fyear_date_end ? formatMonthName(row.latest_fyear_date_end) : "-"),
      },
      {
        field: "consult_name",
        label: t("common:consult"),
        sortField: "consult__first_name",
        width: 120,
      },
      {
        field: "auditor_name",
        label: t("manage.auditor"),
        canSort: false,
        width: 150,
      },
      {
        field: "consult_info__date_client_send",
        className: "text-center",
        label: t("manage.sentToClient"),
        render: (values) => (
          <BMDDatePicker
            defaultValue={values.consult_info ? parseDate(values.consult_info.date_client_send) : null}
            onSelect={(selected) => updateInfo(values.id, "date_client_send", selected)}
          />
        ),
      },
      {
        field: "consult__info__date_bolagsverket_send",
        className: "text-center",
        label: t("manage.sentToAudit"),
        render: (values) => {
          return (
            <BMDDatePicker
              disabled={!values.auditor_name}
              defaultValue={values.consult_info ? parseDate(values.consult_info.date_bolagsverket_send) : null}
              onSelect={(selected) => updateInfo(values.id, "date_bolagsverket_send", selected)}
            />
          );
        },
      },
      {
        field: "consult__info__date_ink2_register",
        className: "text-center",
        label: t("manage.ink2Registered"),
        render: (values) => (
          <BMDDatePicker
            defaultValue={values.consult_info ? parseDate(values.consult_info.date_ink2_register) : null}
            onSelect={(selected) => updateInfo(values.id, "date_ink2_register", selected)}
          />
        ),
      },
      {
        field: "consult__info__date_control_bv",
        className: "text-center",
        label: t("manage.controlBV"),
        render: (values) => (
          <BMDDatePicker
            defaultValue={values.consult_info ? parseDate(values.consult_info.date_control_bv) : null}
            onSelect={(selected) => updateInfo(values.id, "date_control_bv", selected)}
          />
        ),
      },
      {
        field: "consult_info__date_invoiced",
        className: "text-center",
        label: t("manage.invoiced"),
        render: (values) => (
          <BMDDatePicker
            defaultValue={values.consult_info ? parseDate(values.consult_info.date_invoiced) : null}
            onSelect={(selected) => updateInfo(values.id, "date_invoiced", selected)}
          />
        ),
      },
      {
        field: "consult_info__date_k10_ready",
        className: "text-center",
        label: t("manage.k10Ready"),
        render: (values) => (
          <BMDDatePicker
            defaultValue={values.consult_info ? parseDate(values.consult_info.date_k10_ready) : null}
            onSelect={(selected) => updateInfo(values.id, "date_k10_ready", selected)}
          />
        ),
      },
    ];
  }, [t]);

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      ExtraHeaderComponent={<YearEndClosureExtraHeader />}
      selectable={false}
      FiltersComponent={<Filters />}
    />
  );
}

export default YearEndClosureTable;
