import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as companyAPI from "api/company";
import useAsync from "hooks/useAsync";
import { handleActionErrors } from "api/errors";
import { Loader } from "components/ui/loaders";
import useTable from "components/tables/btable/useTable";
import { confirmExecute } from "components/modals/ConfirmModal";
import "./EmployeePermissionsForm.scss";
import { SubmitButton } from "components/ui/buttons";
import EmployeePermissionsTable from "./EmployeePermissionsTable";

function EmployeePermissionsForm({
  companyId,
  employeeId,
  permissions,
  permsChecked,
  setPermsChecked,
  initialPermsChecked,
  setInitialPermsChecked,
  forOnboarding,
}) {
  const { t } = useTranslation("company");
  const {
    dataActions: { reload },
  } = useTable();
  const formikProps = {
    initialValues: { permissions_options: permissions },
    onSubmit: async (values, { setErrors, resetForm }) => {
      return companyAPI.employeePermissions
        .update(companyId, employeeId, { checked: permsChecked })
        .then(async (response) => {
          if (!forOnboarding && initialPermsChecked.length === 0 && permsChecked.length > 0) {
            const answer = await confirmExecute(t("action.sendWelcomeEmail"));
            if (answer) {
              companyAPI
                .sendWelcomeEmail(companyId, employeeId)
                .then(() => {
                  toast.success(t("msg:sent"));
                })
                .catch(() => toast.error(`${t("msg:sent")} - ${t("msg:canNotExecuteAction")}`));
            }
          }
          setInitialPermsChecked(permsChecked);
          toast.success(t("msg:saved"), { autoClose: 2000 });
          reload();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setFieldValue }) => {
        return (
          <Form className="permissions-form">
            <EmployeePermissionsTable
              setPermsChecked={setPermsChecked}
              permissions={permissions}
              permsChecked={permsChecked}
            />
            <section className="save">
              <SubmitButton icon="" title="actions.saveChanges" isSubmitting={isSubmitting} />
            </section>
          </Form>
        );
      }}
    </Formik>
  );
}

function EmployeePermissionsWrapper({ companyId, employeeId, forOnboarding }) {
  const [permsChecked, setPermsChecked] = useState([]);
  const [permsExpanded, setPermsExpanded] = useState([]);
  const [initialPermsChecked, setInitialPermsChecked] = useState([]);

  const getPermissions = useCallback(
    (cancelToken) =>
      companyAPI.employeePermissions.get(companyId, employeeId).then((response) => {
        setPermsChecked(response.data.checked);
        setInitialPermsChecked(response.data.checked);
        return response.data.tree;
      }),
    [companyId, employeeId]
  );

  const [{ data: permissions, loading: loadingPermissions }] = useAsync(getPermissions, []);
  if (loadingPermissions) {
    return <Loader />;
  }

  return (
    <EmployeePermissionsForm
      companyId={companyId}
      employeeId={employeeId}
      permissions={permissions}
      permsChecked={permsChecked}
      setPermsChecked={setPermsChecked}
      permsExpanded={permsExpanded}
      setPermsExpanded={setPermsExpanded}
      initialPermsChecked={initialPermsChecked}
      setInitialPermsChecked={setInitialPermsChecked}
      forOnboarding={forOnboarding}
    />
  );
}

export default EmployeePermissionsWrapper;
