import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { DateRangeFilters, SearchFilter } from "components/filters";

function VATReportFilters({ companyId, forStatus }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("tax");
  const filtersCount = !!filters.receiver + !!filters.payment_date;
  const [more, setMore] = useState(!!filtersCount);

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        {!forStatus && (
          <ButtonGroup aria-label="Status filter" className="status-filter">
            <Button
              variant="outline-secondary"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="outline-unpaid"
              active={filters.mode === "draft"}
              onClick={() => setFilters({ mode: "draft" })}
            >
              {t("common:statuses.unbooked")}
            </Button>
            <Button
              variant="outline-paid"
              active={filters.mode === "booked"}
              onClick={() => setFilters({ mode: "booked" })}
            >
              {t("common:statuses.booked")}
            </Button>
          </ButtonGroup>
        )}
      </section>
      {more && (
        <section className="more-filters">
          <DateRangeFilters
            label={t("common:dates.dueDate")}
            dateStart={filters.due_date__start}
            dateEnd={filters.due_date__end}
            onChange={({ start, end }) => setFilters({ due_date__start: start, due_date__end: end })}
          />
        </section>
      )}
    </>
  );
}

export default VATReportFilters;
