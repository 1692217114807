import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import {
  TooltipActionButton,
  VerificationBookingButton,
  VerificationCommentsButton,
  VerificationHistoryButton,
} from "components/ui/buttons";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as taxAPI from "api/taxes";
import { SelectionContext } from "state/providers/SelectionProvider";
import { formatMoney } from "utils/money";
import { useCompanyState } from "hooks/useCompany";
import useModal from "hooks/useModal";
import { PermCodeRequired } from "components/perms";
import { codesForTaxes } from "components/perms/PermCodes";
import { TaxDPBookPaymentModal } from "components/modals";

function TaxDirectPaymentActions({ row, companyId }) {
  const { t } = useTranslation("tax");
  const {
    tableId,
    dataActions: { reload },
  } = useTable();
  const {
    company: { payment_provider: paymentProvider },
  } = useCompanyState();
  const { removeSelection } = useContext(SelectionContext);
  const bookPayModal = useModal();
  const show = {
    bookBtn: paymentProvider === "pis" && row.sub_status === "sent_bank",
    bookingBtn: row.status === 1,
    deleteBtn: _.includes(["", "unpaid"], row.sub_status),
    cancelPayBtn: row.sub_status === "sent_bank",
    history: row.status === 1,
    comments: row.status === 1,
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeTaxDP", { amount: formatMoney(row.amount, 0) }));
    if (answer) {
      taxAPI.taxDP
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const onCancelPayment = async () => {
    const answer = await confirmExecute(t("confirm.cancelTaxDP", { amount: formatMoney(row.amount, 0) }));
    if (answer) {
      taxAPI.taxDP
        .cancelPayment(companyId, row.id)
        .then(() => {
          toast.success(t("msg:updated"));
          reload();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onBookPayModalClose = (saved) => {
    bookPayModal.close();
    if (saved) {
      if (removeSelection) {
        removeSelection(tableId, row);
      }
      reload();
    }
  };
  if (row.is_created_by_system && row.sub_status === "unpaid") {
    return null;
  }
  return (
    <>
      {show.history && <VerificationHistoryButton verificationId={row.id} />}
      {show.comments && <VerificationCommentsButton companyId={companyId} verification={row} />}
      <PermCodeRequired code={codesForTaxes.bookPayment}>
        {show.bookBtn && (
          <TooltipActionButton
            text={t("common:actions.bookPayment")}
            onClick={bookPayModal.open}
            icon="fas fa-dollar-sign"
          />
        )}
      </PermCodeRequired>
      {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      {show.deleteBtn && (
        <TooltipActionButton
          variant="danger"
          text={t("common:actions.remove")}
          onClick={onDelete}
          icon="fas fa-trash"
        />
      )}
      {show.cancelPayBtn && (
        <TooltipActionButton
          variant="danger"
          text={t("common:actions.cancelPayment")}
          onClick={onCancelPayment}
          icon="fas fa-ban"
        />
      )}
      {bookPayModal.show && (
        <TaxDPBookPaymentModal companyId={companyId} dpData={row} handleClose={onBookPayModalClose} />
      )}
    </>
  );
}

export default TaxDirectPaymentActions;
