import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import { OfficeFilter, SearchFilter } from "components/filters";

function UserFilters({ officeId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");

  return (
    <section className="table-filters-left">
      <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
      {!officeId && <OfficeFilter onFilter={setFilters} placeholder={t("common:agency")} />}
      <ButtonGroup aria-label="Status filter" className="status-filter">
        <Button
          variant="outline-secondary"
          active={filters.is_active === "" || filters.is_active === "all"}
          onClick={() => setFilters({ is_active: "" })}
        >
          {t("common:statuses.all")}
        </Button>
        <Button
          variant="outline-success"
          active={filters.is_active === true}
          onClick={() => setFilters({ is_active: true })}
        >
          {t("common:statuses.active")}
        </Button>
        <Button
          variant="outline-warning"
          active={filters.is_active === false}
          onClick={() => setFilters({ is_active: false })}
        >
          {t("common:statuses.inactive")}
        </Button>
      </ButtonGroup>
    </section>
  );
}

export default UserFilters;
