import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as invoiceAPI from "api/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";

function CustomerInvoiceDuplicatePage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: verificationId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return invoiceAPI.details(company.id, verificationId, true);
  });
  const { loading: loading2, item: products } = useInitialAsync(() => {
    return invoiceAPI.getProducts(company.id, verificationId);
  }, []);

  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading || loading2) {
    return <RocketLoader />;
  }

  function getArticleNumber(productId) {
    const product = products.find((data) => data.ProductPublicId === productId);
    if (product) {
      return product.ArticleNumber;
    }
    return "";
  }
  const connectedProducts = item.connected_products || {};
  delete item.connected_products;
  const invoice = {
    ...item,
    id: null,
    booking_date: new Date(),
    status: 0,
    sub_status: "",
    invoice_no: "",
    delivery_method: item.customer?.DefaultActionConfig?.DeliveryMethod || item.delivery_method,
    periodisation_config: null,
    periodisation_status: null,
    records: item.records.map((record) => {
      if (record.RecordType === "Standard") {
        return {
          ...record,
          ArticleNumber: getArticleNumber(record.ProductPublicId),
        };
      }
      return { ...record };
    }),
  };
  return (
    <CustomerInvoiceForm
      invoice={invoice}
      company={company}
      onSave={onCreate}
      invoiceConnectedProducts={connectedProducts}
    />
  );
}

export default CustomerInvoiceDuplicatePage;
