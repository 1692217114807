import React from "react";
import { Card } from "react-bootstrap";
import * as tableAPI from "api/table";
import TableProvider from "components/tables/btable/provider";
import { useParams } from "react-router-dom";
import SubOfficeExtendedTable from "components/tables/SubOfficeTable/OfficeTable";
import SubOfficeAddWidget from "components/forms/office-settings-forms/SubOfficeAddWidget";

function SubOfficeListPage() {
  const { officeId } = useParams();
  const initialFilters = {};

  return (
    <TableProvider
      tableId="subOffices"
      dataSource={(filters, config) => tableAPI.subOffices(officeId, filters, config)}
      initialFilters={initialFilters}
      keepTerm={false}
    >
      <Card>
        <Card.Body>
          <SubOfficeExtendedTable officeId={officeId} />
        </Card.Body>
      </Card>
      <SubOfficeAddWidget officeId={officeId} />
    </TableProvider>
  );
}

export default SubOfficeListPage;
