import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as selectAPI from "api/select";
import * as optionsAPI from "api/options";
import useAsync from "hooks/useAsync";
import useModalOnPage from "components/modals/hooks/useModalOnPage";

function SelectSimpleFilter({
  name,
  label,
  options,
  onFilter,
  placeholder,
  isClearable = false,
  defaultValue,
  ...props
}) {
  const { t } = useTranslation("common");
  const isModal = useModalOnPage();
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        className="react-select"
        classNamePrefix="select"
        placeholder={placeholder || t("common:actions.select")}
        menuPlacement={isModal ? "bottom" : "auto"}
        // menuPortalTarget={document.body}
        // menuPosition="fixed"
        options={options}
        theme={(theme) => ({
          ...theme,
          borderRadius: "0.2rem",
          colors: {
            ...theme.colors,
            primary: "#b1bbc4",
          },
        })}
        styles={{
          menuPortal: (styles) => ({ ...styles, zIndex: 99 }),
          groupHeading: (base) => ({ ...base, fontSize: 14, color: "#666" }),
        }}
        defaultValue={() => {
          if (props.getDefaultFn) {
            return props.getDefaultFn(defaultValue);
          }
          return options.filter((option) => option.value === defaultValue);
        }}
        isClearable={isClearable}
        onChange={(option) => {
          onFilter({ [name]: option ? option.value : "" });
        }}
        {...props}
      />
    </Form.Group>
  );
}

function MultiSelectSimpleFilter({
  name,
  label,
  options,
  onFilter,
  placeholder,
  isClearable = false,
  isDisabled = false,
  defaultValue,
  ...props
}) {
  const { t } = useTranslation("common");
  const isModal = useModalOnPage();

  function getInitialValues() {
    if (!defaultValue) {
      return null;
    }
    return options.filter((option) => defaultValue.includes(option.value));
  }

  function onChange(values) {
    const optionValues = values.map((option) => option.value);
    onFilter({ [name]: optionValues });
  }

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        className="react-select multi-select"
        isMulti
        classNamePrefix="select"
        placeholder={placeholder || t("common:actions.select")}
        menuPlacement={isModal ? "bottom" : "auto"}
        options={options}
        theme={(theme) => ({
          ...theme,
          borderRadius: "0.2rem",
          colors: {
            ...theme.colors,
            primary: "#b1bbc4",
          },
        })}
        styles={{ menuPortal: (styles) => ({ ...styles, zIndex: 99 }) }}
        value={getInitialValues()}
        isClearable={isClearable}
        onChange={(values) => onChange(values)}
        isDisabled={isDisabled}
        {...props}
      />
    </Form.Group>
  );
}

function ConsultFilter({ name = "consult", label, onFilter, placeholder, isClearable = true, defaultValue }) {
  const dataSource = useCallback(
    (cancelToken) => selectAPI.getConsults({ roles: ["consult", "manager"] }, { cancelToken }),
    []
  );
  const [{ data: options }] = useAsync(dataSource, []);

  return (
    <SelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
    />
  );
}

function MainConsultFilter({ name = "consult", label, onFilter, placeholder, isClearable = true, defaultValue }) {
  const dataSource = useCallback((cancelToken) => selectAPI.getMainConsults({}, { cancelToken }), []);
  const [{ data: options, loading }] = useAsync(dataSource, []);
  if (loading) {
    return null;
  }

  return (
    <SelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
    />
  );
}

function MultiConsultFilter({
  name = "consult",
  label,
  onFilter,
  placeholder,
  isClearable = true,
  isDisabled = false,
  defaultValue,
}) {
  const dataSource = useCallback((cancelToken) => selectAPI.getMainConsults({}, { cancelToken }), []);
  const [{ data: options }] = useAsync(dataSource, []);

  return (
    <MultiSelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
      isDisabled={isDisabled}
    />
  );
}

function OfficeFilter({ name = "office", label, onFilter, placeholder, isClearable = true, defaultValue }) {
  const dataSource = useCallback((cancelToken) => selectAPI.getOfficesSimple({}, { cancelToken }), []);
  const [{ data: options }] = useAsync(dataSource, []);

  return (
    <SelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
    />
  );
}

function MultiOfficeFilter({
  name = "office",
  label,
  onFilter,
  placeholder,
  isClearable = true,
  isDisabled = false,
  defaultValue,
}) {
  const dataSource = useCallback((cancelToken) => selectAPI.getOfficesSimple({}, { cancelToken }), []);
  const [{ data: options }] = useAsync(dataSource, []);
  if (options.length === 0) {
    return null;
  }
  return (
    <MultiSelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
      isDisabled={isDisabled}
    />
  );
}

function MultiSubOfficeFilter({
  name = "office",
  label,
  onFilter,
  placeholder,
  isClearable = true,
  isDisabled = false,
  defaultValue,
  queryParams = {},
}) {
  const dataSource = useCallback(
    (cancelToken) =>
      selectAPI.getSubOfficesSimple(
        { offices: queryParams.offices, belongsMyOffices: queryParams.belongsMyOffices },
        { cancelToken }
      ),
    [queryParams.offices, queryParams.belongsMyOffices]
  );
  const [{ data: options }] = useAsync(dataSource, []);
  // if (options.length === 0) {
  //   return null;
  // }
  return (
    <MultiSelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
      isDisabled={isDisabled}
    />
  );
}

function CompanyEmployeeFilter({
  companyId,
  name = "employee",
  label,
  onFilter,
  placeholder,
  isClearable = true,
  defaultValue,
}) {
  const dataSource = useCallback(
    (cancelToken) => selectAPI.getCompanyEmployees({ companyId }, { cancelToken }),
    [companyId]
  );
  const [{ data: options }] = useAsync(dataSource, []);

  return (
    <SelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
    />
  );
}

function VerificationSeriesPicker({ name = "series", label, isClearable = true, onFilter, defaultValue }) {
  const { t } = useTranslation("ver");
  const series = optionsAPI.verSeries.asList();
  return (
    <SelectSimpleFilter
      name={name}
      label={label}
      placeholder={`${t("series")}...`}
      isClearable={isClearable}
      defaultValue={defaultValue}
      onFilter={onFilter}
      options={series}
      cacheOptions
      styles={{
        container: (provided) => ({
          ...provided,
          width: 350,
        }),
        menu: (provided, state) => ({
          ...provided,
          width: 350,
        }),
      }}
    />
  );
}

function HistoryUserFilter({
  name = "user_id",
  companyId,
  label,
  onFilter,
  placeholder,
  isClearable = true,
  defaultValue,
}) {
  const dataSource = useCallback(
    (cancelToken) => selectAPI.getHistoryUsers({ companyId }, { cancelToken }),
    [companyId]
  );
  const [{ data: options }] = useAsync(dataSource, []);

  return (
    <SelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
    />
  );
}

function MultiOrgNoFilter({
  name = "org_no",
  label,
  onFilter,
  placeholder,
  isClearable = true,
  isDisabled = false,
  defaultValue,
  queryParams = {},
}) {
  const dataSource = useCallback(
    (cancelToken) =>
      selectAPI.getCompaniesOrgNo(
        { offices: queryParams.offices, subOffices: queryParams.subOffices, consult: queryParams.consult },
        { cancelToken },
        true
      ),
    [queryParams.offices, queryParams.subOffices, queryParams.consult]
  );
  const [{ data: options }] = useAsync(dataSource, []);
  return (
    <MultiSelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
      isDisabled={isDisabled}
    />
  );
}
function MultiCustomerNameFilter({
  name = "name",
  label,
  onFilter,
  placeholder,
  isClearable = true,
  isDisabled = false,
  defaultValue,
  queryParams = {},
}) {
  const dataSource = useCallback(
    (cancelToken) =>
      selectAPI.getCompanies(
        { offices: queryParams.offices, subOffices: queryParams.subOffices, consult: queryParams.consult },
        { cancelToken },
        true
      ),
    [queryParams.offices, queryParams.subOffices, queryParams.consult]
  );
  const [{ data: options }] = useAsync(dataSource, []);
  return (
    <MultiSelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
      isDisabled={isDisabled}
    />
  );
}

function MultiCustomerManagerFilter({
  name = "consult",
  label,
  onFilter,
  placeholder,
  isClearable = true,
  isDisabled = false,
  defaultValue,
  queryParams = {},
}) {
  const dataSource = useCallback(
    (cancelToken) =>
      selectAPI.getConsults(
        {
          officeId: queryParams.offices,
          subOffices: queryParams.subOffices,
          roles: ["consult"],
        },
        { cancelToken }
      ),
    [queryParams.offices, queryParams.subOffices]
  );
  const [{ data: options }] = useAsync(dataSource, []);

  return (
    <MultiSelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
      isDisabled={isDisabled}
    />
  );
}

export {
  SelectSimpleFilter,
  ConsultFilter,
  OfficeFilter,
  MultiSelectSimpleFilter,
  CompanyEmployeeFilter,
  VerificationSeriesPicker,
  MainConsultFilter,
  MultiConsultFilter,
  MultiOfficeFilter,
  HistoryUserFilter,
  MultiSubOfficeFilter,
  MultiOrgNoFilter,
  MultiCustomerNameFilter,
  MultiCustomerManagerFilter,
};
