import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import Filters from "./ContractInvoiceFilters";
import Actions from "./ContractInvoiceActions";

function ContractInvoiceTable({ companyId, selectable = false }) {
  const { t } = useTranslation("ci");

  const headers = useMemo(
    () => [
      {
        field: "id",
        label: t("common:no"),
        width: 100,
      },
      {
        field: "customer_billecta_name",
        label: t("common:customer"),
        canSort: false,
      },
      {
        field: "contract_date",
        label: t("common:dates.date"),
      },
      {
        field: "ongoing",
        label: t("common:statuses.ongoing"),
        render: (values) => (values.ongoing ? t("common:actions.yes") : t("common:actions.no")),
      },
      {
        field: "period_start",
        label: t("common:starts"),
      },
      {
        field: "period_end",
        label: t("common:ends"),
      },
    ],
    [t]
  );

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.total")}:</strong>
      {responseHeaders["x-pagination-count"]}
    </div>
  );

  const getRowProps = (row) => {
    return { className: row.is_active ? "active" : "inactive" };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
      getRowProps={getRowProps}
    />
  );
}

export default ContractInvoiceTable;
