import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import FinancialYearForm from "./FinancialYearForm";

function FinancialYearAddWidget({ companyId, userPermCodes }) {
  const {
    dataActions: { reload },
  } = useTable();
  const financialYear = {
    date_start: undefined,
    date_end: undefined,
  };

  const { t } = useTranslation("company");
  return (
    <Row>
      <Col>
        <Card className="card-border">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addFYear")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <FinancialYearForm
              companyId={companyId}
              financialYear={financialYear}
              successCallback={reload}
              userPermCodes={userPermCodes}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default FinancialYearAddWidget;
