import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Trans, useTranslation } from "react-i18next";

function PostRegisterCompanyConfirmTT({ companyId, companyName, close }) {
  const { t } = useTranslation("company");
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={close}
      dialogClassName="confirmation-modal"
      backdrop="static"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("onboarding.newCompanyCreated")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans i18nKey="company:onboarding.postCreateInfo" companyName={companyName}>
          <p>Company {{ companyName }} has been created but TT is not active yet</p>
          <p>
            We have started the configuration but need you to check the tasks and set the start date.
            <br />
            Would you like to start revieving the TT configuration now ?
          </p>
        </Trans>
      </Modal.Body>
      <Modal.Footer className="space">
        <Button as={Link} type="button" variant="danger" to={`/onboarding/${companyId}`}>
          {t("onboarding.ttLater")}
        </Button>
        <Button as={Link} type="button" variant="primary" to={`/companies/${companyId}/settings/tt`}>
          {t("onboarding.ttNow")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PostRegisterCompanyConfirmTT;
