import React, { useContext, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { formatMoney } from "utils/money";
import { SelectionContext } from "state/providers/SelectionProvider";
import useModal from "hooks/useModal";
import { useCompanyState } from "hooks/useCompany";
import { PayBankIDModal, SigningBasketModal } from "components/modals";
import { getBankIDMessage } from "utils/others";
import { pay as siPay } from "api/supplier-invoices";
import { pay as outPay } from "api2/outlays";
import { pay as salPay } from "api/salaries";
import { pay as dpPay } from "api/direct-payments";
import { taxDP } from "api/taxes";
import { codesFor, HasPermCode, PermCodeRequired } from "components/perms";
import { PayResultsModal } from "components/modals/ResultsModal";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { ConnectBankBox, ConnectKYCBox, ConnectTaxBox } from "./ConnectBoxes";
import CashBox from "./CashBox";

function _chunkSum(list, key, field = "balance") {
  return Object.values(list[key] || []).reduce((a, b) => a + b[field], 0);
}

function PayBox({ basketId }) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const {
    company: { id: companyId, payment_provider: paymentProvider },
  } = useCompanyState();
  const bankIdModal = useModal();
  const signingBasketModal = useModal();
  const payModal = useModal();
  const { selection, getFlatten, clearSelection } = useContext(SelectionContext);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const flattenSelection = getFlatten();
  const count = flattenSelection.length;
  const selectedAmount = flattenSelection.reduce((a, b) => a + (b.balance || b.amount_sek), 0);
  const siAmount = _chunkSum(selection, "unpaidSupplierInvoices");
  const outlaysAmount = _chunkSum(selection, "unpaidOutlays");
  const dpAmount = _chunkSum(selection, "unpaidDirectPayments", "amount_sek");
  const salariesAmount = _chunkSum(selection, "unpaidSalaries", "amount_sek");
  const taxesAmount = _chunkSum(selection, "unpaidTaxDP", "amount_sek");
  const canPaySI = HasPermCode(codesFor.supplierInvoices.pay);
  const canPayOutlays = HasPermCode(codesFor.outlays.pay);
  const canPaySalaries = HasPermCode(codesFor.salaries.pay);
  const canPayDirectPayments = HasPermCode(codesFor.directPayments.pay);
  const canPayTaxes = HasPermCode(codesFor.taxes.pay);
  const canPay = canPaySI || canPayOutlays || canPaySalaries || canPayDirectPayments || canPayTaxes;

  useEffect(() => {
    if (basketId) {
      signingBasketModal.open({ basketId, items: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketId]);

  const onSigningBasketModalClose = () => {
    signingBasketModal.close();
    clearSelection();
    reloadMenuNumbers();
    navigate(location.pathname, { replace: true }); // to reload table, because outside of table
  };

  const onBankIDSuccess = async (bankIdToken) => {
    const flattenSelection2 = getFlatten();
    const items = [];
    flattenSelection2.forEach((item) => {
      let apiRequest;
      switch (item.object_type) {
        case "si":
          apiRequest = siPay;
          break;
        case "out":
          apiRequest = outPay;
          break;
        case "dp":
          apiRequest = dpPay;
          break;
        case "sal":
          apiRequest = salPay;
          break;
        case "tax_dp":
          apiRequest = taxDP.pay;
          break;
        default:
          apiRequest = undefined;
      }
      if (apiRequest) {
        items.push({ id: item.id, title: item.title, apiRequest });
      }
    });
    bankIdModal.close();
    payModal.open({ token: bankIdToken, items });
  };

  function getMessage() {
    return getBankIDMessage(selection);
  }

  const onPayModalClose = () => {
    payModal.close();
    clearSelection();
    reloadMenuNumbers();
    navigate(location.pathname, { replace: true }); // to reload table, because outside of table
  };

  const handlePay = () => {
    if (paymentProvider === "bgc" && canPay) {
      bankIdModal.open();
    } else if (paymentProvider === "pis" && canPay) {
      const flattenSelection2 = getFlatten();
      signingBasketModal.open({ items: flattenSelection2 });
    } else {
      alert("Can not execute payment. Contact with your consultant.");
    }
  };

  return (
    <div id="payment-box">
      <Card>
        <Card.Body>
          <div className="summary-box">
            <div className="selected-box">
              <div className="box">
                <span>{t("navigation:supplierInvoices")}</span>
                <p>{formatMoney(siAmount, 0, 0)}</p>
              </div>
              <div className="box">
                <span>{t("navigation:outlays")}</span>
                <p>{formatMoney(outlaysAmount, 0, 0)}</p>
              </div>
              <div className="box">
                <span>{t("navigation:directPayments")}</span>
                <p>{formatMoney(dpAmount, 0, 0)}</p>
              </div>
              <div className="box">
                <span>{t("navigation:salaries")}</span>
                <p>{formatMoney(salariesAmount, 0, 0)}</p>
              </div>
              <div className="box">
                <span>{t("navigation:taxes")}</span>
                <p>{formatMoney(taxesAmount, 0, 0)}</p>
              </div>
            </div>
            <CashBox companyId={companyId} selectedAmount={selectedAmount} />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body className="pay-and-connect-box">
          <PermCodeRequired code={codesFor.companySettings.bankConnection}>
            <div className="d-flex">
              <ConnectBankBox companyId={companyId} />
              <ConnectTaxBox companyId={companyId} />
              <ConnectKYCBox companyId={companyId} />
            </div>
          </PermCodeRequired>
          <div className="payBtn">
            {canPay && (
              <Button className="pay-btn ml-2" size="lg" disabled={count <= 0} onClick={handlePay}>
                {t("actions.pay")}: {count} <span>({formatMoney(selectedAmount)} SEK)</span>
              </Button>
            )}
          </div>
        </Card.Body>
      </Card>
      {bankIdModal.show && (
        <PayBankIDModal handleSuccess={onBankIDSuccess} handleClose={bankIdModal.close} bankIDMsg={getMessage()} />
      )}
      {payModal.show && payModal.data && (
        <PayResultsModal
          bankIDToken={payModal.data.token}
          selectedItems={payModal.data.items}
          handleClose={onPayModalClose}
        />
      )}
      {signingBasketModal.show && (
        <SigningBasketModal
          basketId={signingBasketModal.data.basketId}
          verifications={signingBasketModal.data.items}
          handleClose={onSigningBasketModalClose}
        />
      )}
    </div>
  );
}

export default PayBox;
