import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./DirectPaymentFilters";
import Actions from "./DirectPaymentActions";

function DirectPaymentTable({ companyId, forStatus, allowedModes, selectable = false }) {
  const { t } = useTranslation("common");

  const headers = useMemo(() => {
    const _headers = [
      {
        field: "receiver",
        label: t("common:employee"),
        canSort: true,
      },
      {
        field: "description",
        label: t("common:description"),
      },
      {
        field: "account",
        label: t("common:accountF"),
      },
      {
        field: "amount_sek",
        label: t("common:money.amount"),
        className: "text-right",
        render: (values) => formatMoney(values.amount_sek),
      },
    ];
    if (_.includes(["paid", "sent_bank"], forStatus)) {
      _headers.push({
        field: "payment_date",
        label: t("common:dates.paymentDate"),
      });
    }
    return _headers;
  }, [t, forStatus]);

  const getRowProps = (row) => {
    return {
      className: cx(row.sub_status || "unpaid", {
        "has-message": !!row.comments.length,
      }),
    };
  };

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.toPay")}:</strong>
      {formatMoney(responseHeaders["x-pagination-amount"])}
    </div>
  );

  const renderSelect = (row) => {
    if (row.in_signing) {
      return "SIGN";
    }
    return undefined;
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      renderSelect={renderSelect}
      FiltersComponent={<Filters companyId={companyId} forStatus={forStatus} allowedModes={allowedModes} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
      getRowProps={getRowProps}
    />
  );
}

export default DirectPaymentTable;
