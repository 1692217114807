import React from "react";
import { TooltipActionButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import { CodeRequiredInList } from "components/perms";
import { codesForFinancialYears } from "components/perms/PermCodes";
import useModal from "hooks/useModal";
import { SieFileExportModal } from "components/modals";

function FinancialYearActions({ companyId, row, userPermCodes }) {
  const { t } = useTranslation("common");
  const sieModal = useModal();

  return (
    <CodeRequiredInList code={codesForFinancialYears.exportSIE} codeList={userPermCodes}>
      <TooltipActionButton text={t("common:actions.download")} onClick={sieModal.open} icon="fas fa-download" />
      {sieModal.show && <SieFileExportModal handleClose={sieModal.close} companyId={companyId} fyData={row} />}
    </CodeRequiredInList>
  );
}

export default FinancialYearActions;
