import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { config as chartConfig } from "components/charts/helpers";
import { formatMoney } from "utils/money";
import React from "react";

function CompanyBarChart({ t, chartData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={Object.values(chartData)}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" interval={0} />
        <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
        <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)}`} />
        <Legend />
        <Bar dataKey="count" name={t("stats.newCompanies")} barSize={20} fill={chartConfig.purple} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function OutlayBarChart({ t, chartData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={Object.values(chartData)}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" interval={0} />
        <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
        <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)}`} />
        <Legend />
        <Bar dataKey="count" name={t("stats.newOutlays")} barSize={20} fill={chartConfig.purple} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function SalaryBarChart({ t, chartData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={Object.values(chartData)}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" interval={0} />
        <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
        <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)}`} />
        <Legend />
        <Bar dataKey="count" name={t("stats.newSalaries")} barSize={20} fill={chartConfig.purple} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function VerificationsBarChart({ t, chartData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={Object.values(chartData)}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" interval={0} />
        <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
        <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)}`} />
        <Legend />
        <Bar dataKey="newAll" name={t("stats.newVer")} barSize={20} fill={chartConfig.purple} />
        <Bar dataKey="newManual" name={t("stats.newManualVer")} barSize={20} fill={chartConfig.orange} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function CIBarChart({ t, chartData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={Object.values(chartData)}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" interval={0} />
        <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
        <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)}`} />
        <Legend />
        <Bar dataKey="newAll" name={t("stats.newCI")} barSize={20} fill={chartConfig.purple} />
        <Bar dataKey="newInter" name={t("stats.newCIInter")} barSize={20} fill={chartConfig.orange} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function SIBarChart({ t, chartData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={Object.values(chartData)}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" interval={0} />
        <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
        <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)}`} />
        <Legend />
        <Bar dataKey="newAll" name={t("stats.newSI")} barSize={20} fill={chartConfig.purple} />
        <Bar dataKey="newInter" name={t("stats.newSIInter")} barSize={20} fill={chartConfig.orange} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function ArchiveBarChart({ t, chartData }) {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={Object.values(chartData)}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" interval={0} />
        <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
        <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)}`} />
        <Legend />
        <Bar dataKey="newFileArchive" name={t("stats.newFileArchive")} barSize={20} fill={chartConfig.purple} />
        <Bar dataKey="newCompanyArchive" name={t("stats.newCompanyArchive")} barSize={20} fill={chartConfig.orange} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export {
  CompanyBarChart,
  VerificationsBarChart,
  CIBarChart,
  SIBarChart,
  OutlayBarChart,
  SalaryBarChart,
  ArchiveBarChart,
};
