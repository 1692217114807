import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FilterButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { DateRangeFilters, SearchFilter, VerificationSeriesPicker } from "components/filters";

function VerificationFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const filtersCount = !!filters.receiver + !!filters.payment_date;
  const [more, setMore] = useState(!!filtersCount);

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <VerificationSeriesPicker onFilter={setFilters} defaultValue={filters.series} />
      </section>
      {more && (
        <section className="more-filters">
          <DateRangeFilters
            label={t("dates.bookingDate")}
            dateStart={filters.booking_date__start}
            dateEnd={filters.booking_date__end}
            onChange={({ start, end }) => setFilters({ booking_date__start: start, booking_date__end: end })}
          />
        </section>
      )}
    </>
  );
}

export default VerificationFilters;
