import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import * as reportAPI from "api2/reports";
import qs from "qs";
import TableLoader from "components/tables/btable/TableLoader";
import ProfitLossTable from "./ProfitLossTable";

const initialReport = {
  categories: [],
  final_amounts: {
    accumulated: 0,
    period: 0,
    period_prev_year: 0,
    accumulated_prev_year: 0,
  },
};

function BudgetPLMonthly() {
  const { companyId, financialYear, showAllAccounts, setEditMode, accountTerm } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(initialReport);
  useEffect(() => {
    const signal = axios.CancelToken.source();

    function getReport() {
      setLoading(true);
      reportAPI
        .profitAndLoss(
          companyId,
          {
            date_from: financialYear.date_start,
            date_to: financialYear.date_end,
            financial_year: financialYear.id,
            show_all_accounts: showAllAccounts,
            term: accountTerm,
            with_budget: true,
          },
          {
            cancelToken: signal.token,
            paramsSerializer: (params) => {
              return qs.stringify(params, { indices: false });
            },
          }
        )
        .then((response) => {
          setReport(response.data);
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setReport(initialReport);
            setLoading(false);
          }
        });
    }

    getReport();

    return () => {
      signal.cancel("aborted");
    };
  }, [
    setEditMode,
    companyId,
    financialYear.id,
    financialYear.date_start,
    financialYear.date_end,
    showAllAccounts,
    accountTerm,
  ]);
  if (loading) {
    return <TableLoader />;
  }
  return <ProfitLossTable report={report} rounding="2" />;
}

export default BudgetPLMonthly;
