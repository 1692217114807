import React, { useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import qs from "qs";
import { DateRangeFilters, MultiSelectSimpleFilter, SearchFilter } from "components/filters";
import { formatDate } from "utils/date";
import { FilterButton } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";
import useTable from "components/tables/btable/useTable";
import { taskCategories } from "api/options";
import TTAbsenceLogModal from "components/modals/TTAbsenceLogModal";
import { StaffPermRequired } from "components/perms";

function TimeLogTTFilters({ selectedOffice, options }) {
  const {
    filters,
    setFilters,
    dataActions: { reload },
  } = useTable();
  const { t } = useTranslation("tt");
  const taskCategoriesOptions = taskCategories.asList().filter((category) => {
    if (filters.work_time_only) {
      return category.value === "absence" || category.value === "fixed";
    }
    return category.value !== "absence" && category.value !== "fixed";
  });
  const filtersCount =
    !!filters.users.length +
    !!filters.companies.length +
    !!filters.work_time_only +
    !!(filters.categories && filters.categories.length) +
    !!filters.started__start +
    !!filters.started__end;
  const [more, setMore] = useState(false);
  const csvModal = useModal();
  const ttAbsenceModal = useModal();

  const openCSVModal = () => {
    const params = qs.stringify(
      {
        ...filters,
        started__start: filters.started__start ? formatDate(filters.started__start) : undefined,
        started__end: filters.started__end ? formatDate(filters.started__end) : undefined,
        office: selectedOffice.office,
      },
      { indices: false }
    );
    csvModal.open(`/table/tracker-logs/csv/?${params}`);
  };

  const openAbsenceModal = () => {
    const absenceLog = {
      date_from: formatDate(new Date()),
      date_to: formatDate(new Date()),
      hours_per_day: 8,
      comment: "",
      reason: null,
    };
    ttAbsenceModal.open(absenceLog);
  };

  const onAbsenceModalClose = (generated) => {
    if (generated) {
      reload();
    }
    ttAbsenceModal.close();
  };

  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
        <Button
          variant="outline-secondary"
          onClick={() => {
            const newValue = !filters.work_time_only;
            if (newValue) {
              setFilters({
                work_time_only: true,
                companies: [],
                categories: [],
              });
            } else {
              setFilters({
                work_time_only: false,
                categories: [],
              });
            }
          }}
          active={filters.work_time_only}
        >
          {t("common:worktime")}
        </Button>
      </section>
      <section className="table-filters-right">
        <ButtonGroup>
          {selectedOffice.is_main_company_employee && (
            <Button variant="first" onClick={openAbsenceModal}>
              {t("actions.addAbsence")}
            </Button>
          )}
          <StaffPermRequired officeConsult>
            <Button variant="secondary" onClick={openCSVModal}>
              <i className="fas fa-file-csv" /> {t("reports:reportCSV")}
            </Button>
          </StaffPermRequired>
        </ButtonGroup>
      </section>
      {more && (
        <section className="more-filters">
          {!filters.work_time_only && (
            <MultiSelectSimpleFilter
              label={t("common:company")}
              defaultValue={filters.companies}
              options={options.companies}
              isClearable
              name="companies"
              onFilter={setFilters}
            />
          )}
          <StaffPermRequired officeConsult>
            <MultiSelectSimpleFilter
              label={t("assignee")}
              defaultValue={filters.users}
              options={options.consults}
              isClearable
              name="users"
              onFilter={setFilters}
            />
          </StaffPermRequired>

          <MultiSelectSimpleFilter
            label={t("common:category")}
            defaultValue={filters.categories}
            options={taskCategoriesOptions}
            isClearable
            name="categories"
            onFilter={setFilters}
          />
          <DateRangeFilters
            label={t("started")}
            dateStart={filters.started__start}
            dateEnd={filters.started__end}
            onChange={({ start, end }) => setFilters({ started__start: start, started__end: end })}
          />
        </section>
      )}
      {csvModal.show && (
        <FileUrlModalOld fileUrl={csvModal.data} downloadFileName="tt_report.csv" handleClose={csvModal.close} />
      )}
      {ttAbsenceModal.show && (
        <TTAbsenceLogModal
          absenceLog={ttAbsenceModal.data}
          handleClose={onAbsenceModalClose}
          officeId={selectedOffice.office}
        />
      )}
    </>
  );
}

export default TimeLogTTFilters;
