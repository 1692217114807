import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as ttAPI from "api/time-tracker";
import { endOfISOWeek, startOfISOWeek } from "date-fns";
import { formatDate, parseDate } from "utils/date";
import { toast } from "react-toastify";

const TimeGridStateContext = React.createContext();
const TimeGridDispatchContext = React.createContext();

function TimeGridProvider({ userId, officeId, children }) {
  const dateRangeRef = useRef({ dateFrom: null, dateTo: null });
  const [lockedDays, setLockedDays] = useState([]);
  const [scheduled, setScheduled] = useState([8, 8, 8, 8, 8, 0, 0]);
  const [logs, setLogs] = useState([]);
  const [reported, setReported] = useState({
    absence: 0,
    presence: 0,
    timeBank: 0,
    timeTasks: 0,
    dates: {},
    logsTime: 0,
    logsDates: {},
  });

  const toggleLockDay = useCallback(
    (day) => {
      const dateStr = formatDate(day);
      if (!lockedDays.includes(dateStr)) {
        return ttAPI.timeGrid
          .lockDate(officeId, day)
          .then((response) => {
            document.body.dispatchEvent(
              new CustomEvent("tt/lockChanged", {
                detail: { locked: true, day },
              })
            );
            setLockedDays((_data) => [..._data, dateStr]);
            setReported((_reported) => ({
              ..._reported,
              timeBank: response.data.time_bank,
            }));
          })
          .catch((error) => {
            if (error.data.__all__) {
              toast.error(error.data.__all__);
            }
          });
      }
      return ttAPI.timeGrid
        .unlockDate(officeId, day)
        .then((response) => {
          document.body.dispatchEvent(
            new CustomEvent("tt/lockChanged", {
              detail: { locked: false, day },
            })
          );
          setLockedDays(lockedDays.filter((item) => item !== dateStr));
          setReported((_reported) => ({
            ..._reported,
            timeBank: response.data.time_bank,
          }));
        })
        .catch();
    },
    [lockedDays, officeId]
  );

  const fetchLogs = useCallback(
    (dateFrom, dateTo) => {
      dateRangeRef.current = { dateFrom, dateTo };
      ttAPI.timeGrid.getLogs(officeId, dateFrom, dateTo).then((response) => {
        const report = {
          absence: 0,
          presence: 0,
          timeBank: response.data.time_bank,
          dates: {},
          logsTime: 0,
          logsDates: {},
          scheduleDateStart: null,
        };
        let _date;
        response.data.logs
          .filter(
            (log) =>
              log.task__category === "fixed" ||
              (log.task__category === "absence" && log.task_title !== "compensation_timebank")
          )
          .forEach((log) => {
            if (log.task__category === "absence") {
              report.absence += log.duration;
            } else {
              report.presence += log.duration;
            }
            _date = log.started.slice(0, 10);
            if (_date in report.dates) {
              report.dates[_date] += log.duration;
            } else {
              report.dates[_date] = log.duration;
            }
          });
        response.data.logs
          .filter((log) => log.task__category !== "fixed" && log.task__category !== "absence")
          .forEach((log) => {
            _date = log.started.slice(0, 10);
            if (_date in report.logsDates) {
              report.logsDates[_date] += log.duration;
            } else {
              report.logsDates[_date] = log.duration;
            }
            report.logsTime += log.duration;
          });
        report.scheduleDateStart = parseDate(response.data.schedule_date_start);
        setReported(report);
        setLockedDays(response.data.locked);
        setLogs(response.data.logs);
        setScheduled(response.data.scheduled);

        return response;
      });
    },
    [officeId]
  );

  const updateLog = useCallback(
    (updatedLog) => {
      const { dateFrom, dateTo } = dateRangeRef.current;
      fetchLogs(dateFrom, dateTo);
    },
    [fetchLogs]
  );

  useEffect(() => {
    fetchLogs(startOfISOWeek(new Date()), endOfISOWeek(new Date()));
  }, [fetchLogs]);

  const stateValue = useMemo(
    () => ({
      reported,
      scheduled,
      logs,
      lockedDays,
    }),
    [reported, scheduled, logs, lockedDays]
  );
  const dispatchValue = useMemo(
    () => ({
      toggleLockDay,
      fetchLogs,
      updateLog,
    }),
    [fetchLogs, toggleLockDay, updateLog]
  );

  return (
    <TimeGridStateContext.Provider value={stateValue}>
      <TimeGridDispatchContext.Provider value={dispatchValue}>{children}</TimeGridDispatchContext.Provider>
    </TimeGridStateContext.Provider>
  );
}

export { TimeGridStateContext, TimeGridDispatchContext };

export default TimeGridProvider;
