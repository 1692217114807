import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { Col, Row, Table } from "react-bootstrap";
import _ from "lodash";

import { AllError, FormGroup, TableGroup } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import * as options from "api/options";
import * as companyAPI from "api/company";
import { toast } from "react-toastify";
import TransactionRuleListForm from "./TransactionRuleListForm";

function mapAccounts(allAccounts, selectedAccounts) {
  const accounts = [];
  for (let i = 0; i < selectedAccounts.length; i++) {
    const account = allAccounts.find((_a) => _a.value === selectedAccounts[i]);
    if (account) {
      accounts.push(account);
    }
  }
  return accounts;
}

function BankRuleForm({ companyId, bankRule, successCallback, deleteCallback, companyAccounts }) {
  const { t } = useTranslation("company");
  const bankRuleConditions = options.bankRuleConditions.asList();
  const bankRuleCharges = options.bankRuleCharges.asList();
  const formikProps = {
    initialValues: {
      ...bankRule,
      condition: options.bankRuleConditions.getOption(bankRule.condition),
      main: {
        ...bankRule.main,
        account_ids: mapAccounts(companyAccounts, bankRule.main.account_ids),
        charge: options.bankRuleCharges.getOption(bankRule.main.charge),
      },
      rules: bankRule.rules.map((rule) => ({
        ...rule,
        account_id: rule.account_ids.length ? mapAccounts(companyAccounts, rule.account_ids)[0] : null,
        key: _.uniqueId(),
        charge: options.bankRuleCharges.getOption(rule.charge),
      })),
    },
    validationSchema: yup.object().shape({
      condition: yup.object().required(),
      search_text: yup.string().required(),
      verification_title: yup.string().max(200),
      main: yup.object().shape({
        charge: yup.object().required(),
        percent: yup.number().positive().required().min(1).max(100),
        account_ids: yup.array().of(yup.object()).min(1, t("bankRule.errors.minOneAccount")),
      }),
      rules: yup
        .array()
        .of(
          yup.object().shape({
            charge: yup.object().required(),
            percent: yup.number().positive().required(t("bankRule.errors.percentRequired")).min(1).max(100),
            account_id: yup.object().nullable().required(t("bankRule.errors.accountRequired")),
          })
        )
        .min(1)
        .test(function (rules, { parent }) {
          if (rules.length < 1) {
            return this.createError({
              path: "__all__",
              message: t("bankRule.errors.minTwoRows"),
            });
          }
          const parentDebit = parent.main.charge.value === "debit";
          let debitSum = parentDebit ? 100 : 0;
          let creditSum = parentDebit ? 0 : 100;
          rules.forEach((rule) => {
            if (rule.charge.value === "debit") {
              debitSum += rule.percent;
            } else {
              creditSum += rule.percent;
            }
          });
          if (debitSum !== 100 || creditSum !== 100) {
            return this.createError({
              path: "__all__",
              message: t("bankRule.errors.percentageNotBalance"),
            });
          }
          return true;
        }),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      return companyAPI.bankRules
        .save(companyId, {
          ...values,
          condition: values.condition.value,
          rules: [
            {
              charge: values.main.charge.value,
              percent: 100,
              account_ids: values.main.account_ids.map((acc) => acc.value),
              is_bank_account: true,
            },
            ...values.rules.map((rule) => ({
              charge: rule.charge.value,
              percent: rule.percent,
              account_ids: [rule.account_id.value],
              is_bank_account: false,
            })),
          ],
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (!values.id) {
            resetForm();
          }
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  const onDelete = async () => {
    return companyAPI.bankRules
      .remove(companyId, bankRule.id)
      .then((response) => {
        toast.success(t("msg:deleted"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 4000 });
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };
  const bankAccounts = companyAccounts.filter((acc) =>
    [1630, 1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938, 1939].includes(acc.value)
  );

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        return (
          <Form>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.SimpleSelect
                  label={t("bankRule.condition")}
                  name="condition"
                  options={bankRuleConditions}
                  required
                />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("bankRule.text")} name="search_text" required />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={`${t("common:verification")} ${t("common:name")}`} name="verification_title" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table bordered responsive size="sm" id="bank-rules-form">
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>{t("bankRule.charge")}</th>
                      <th style={{ width: 200 }}>{t("bankRule.percent")}</th>
                      <th>{t("bankRule.accounts")}</th>
                      <th style={{ width: 50 }} />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <TableGroup.SimpleSelect name="main.charge" options={bankRuleCharges} />
                      <TableGroup.MoneyInput name="main.percent" disabled />
                      <TableGroup.SimpleSelect
                        isMulti
                        name="main.account_ids"
                        options={bankAccounts}
                        minSearchLength={0}
                      />
                      <td />
                    </tr>
                    <TableGroup.RowErrors errors={errors.main} />
                  </tbody>
                </Table>
              </Col>
            </Row>
            <p>{t("bankRule.shouldBeBooked")}</p>
            <Row>
              <Col>
                <TransactionRuleListForm companyId={companyId} companyAccounts={companyAccounts} />
              </Col>
            </Row>
            <AllError errors={errors} />
            <hr />
            <div className="space-1">
              <SubmitButton isSubmitting={isSubmitting} />
              {bankRule.id && (
                <RemoveButton
                  variant="danger"
                  disabled={isSubmitting}
                  confirmMessage={t("confirm.removeBankRule")}
                  onClick={onDelete}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default BankRuleForm;
