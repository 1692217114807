import React from "react";
import { Card } from "react-bootstrap";

import { ProfitabilityTable } from "components/tables";
import TableProvider from "components/tables/btable/provider";
import * as tableAPI from "api/table";

function CompanyBMD3ListPage() {
  return (
    <Card id="company-manage-table">
      <Card.Body>
        <TableProvider
          tableId="company-manage3"
          pageSize={150}
          initialFilters={{ favourites: true, period: "0" }}
          dataSource={tableAPI.manageProfitability}
        >
          <ProfitabilityTable />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}
export default CompanyBMD3ListPage;
