import React, { Fragment } from "react";
import useTable from "components/tables/btable/useTable";
import TableLoader from "components/tables/btable/TableLoader";
import { Table } from "react-bootstrap";
import Headers from "components/tables/btable/Headers";
import BPagination from "components/tables/btable/BPagination";
import PropTypes from "prop-types";
import cx from "classnames";
import { withTranslation } from "react-i18next";

function BExpandedTable({ t, idKey = "id", headers, FiltersComponent, renderActions, getRowProps, renderExpandedRow }) {
  const { data, pagination, loading, setOrdering, toggleExpand } = useTable();

  const actionsColumn = !!renderActions;

  return (
    <div className="b-table">
      {loading && <TableLoader />}
      <div className="table-filters-group">{FiltersComponent}</div>
      <Table responsive bordered striped hover className="table-expanded">
        <thead>
          <Headers initialHeaders={headers} onSort={setOrdering} actionColumn={!!renderActions} expandColumn />
        </thead>
        <tbody>
          {data.map((item, index) => (
            <Fragment key={`ctr.${item[idKey]}`}>
              <tr {...getRowProps(item)}>
                <td className="expand" onClick={() => toggleExpand(index)}>
                  <i className={cx("fas", item.expanded ? "fa-chevron-down" : "fa-chevron-right")} />
                </td>
                {headers.map((header) => (
                  <td key={`ctd${header.field}`}>{header.render ? header.render(item) : item[header.field]}</td>
                ))}
                {!!renderActions && <td className="td-actions">{renderActions(item, index)}</td>}
              </tr>
              {item.expanded && (
                <tr className="expanded">
                  <td colSpan={headers.length + (actionsColumn ? 2 : 1)}>{renderExpandedRow(item)}</td>
                </tr>
              )}
            </Fragment>
          ))}
          {!data.length && (
            <tr>
              <td colSpan={headers.length + (actionsColumn ? 2 : 1)}>{t("common:noResultsFound")}</td>
            </tr>
          )}
        </tbody>
      </Table>
      {pagination.count > 1 && <BPagination pagination={pagination} />}
    </div>
  );
}

BExpandedTable.propTypes = {
  idKey: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      render: PropTypes.func,
      canSort: PropTypes.bool,
    })
  ),
  renderExpandedRow: PropTypes.func.isRequired,
  getRowProps: PropTypes.func,
};

BExpandedTable.defaultProps = {
  getRowProps: () => {},
};

export default withTranslation("common")(BExpandedTable);
