import { formatDate } from "utils/date";
import client from "./client";

export function getTransactions(companyId, params) {
  return client.get(`/companies/${companyId}/bank-matching/`, { params });
}

export function getUnmatchedByAccount(companyId, params) {
  return client.get(`/companies/${companyId}/bank-matching/unmatched_count/`, {
    params,
  });
}

export function resetBalances(companyId, account) {
  return client.post(`/companies/${companyId}/bank-matching/reset_balances/`, {
    account,
  });
}

export function fetchFromBank(companyId, dateFrom, dateTo) {
  return client.post(
    `/companies/${companyId}/bank-matching/fetch_from_psd_bank/`,
    {
      date_from: formatDate(dateFrom),
      date_to: formatDate(dateTo),
    },
    {
      timeout: 2 * 60000,
    }
  );
}

export function fetchFromSkv(companyId, dateFrom, dateTo) {
  return client.post(`/companies/${companyId}/bank-matching/fetch_from_skatteverket/`, {
    date_from: formatDate(dateFrom),
    date_to: formatDate(dateTo),
  });
}

export function deleteMonthMatching(companyId, params) {
  return client.delete(`/companies/${companyId}/bank-matching/remove_matching/`, { params });
}

export function manualMatching(companyId, data) {
  return client.post(`/companies/${companyId}/bank-matching/manual_matching/`, data);
}

export function automaticMatching(companyId, params) {
  return client.post(`/companies/${companyId}/bank-matching/automatic_matching/`, {}, { params, timeout: 120000 });
}

export function removeBankTransaction(companyId, bankTransId) {
  return client.delete(`/companies/${companyId}/bank-matching/remove_bank_transaction/${bankTransId}/`, {});
}

export function undoBflowMatching(companyId, data) {
  return client.post(`/companies/${companyId}/bank-matching/undo_bflow_matching/`, data);
}

export function undoBankMatching(companyId, data) {
  return client.post(`/companies/${companyId}/bank-matching/undo_bank_matching/`, data);
}
