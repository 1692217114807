import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import OfficeScheduleTemplateForm from "./OfficeScheduleTemplateForm";

function OfficeScheduleTemplateAddWidget({ officeId }) {
  const { t } = useTranslation("others");
  const {
    dataActions: { reload },
  } = useTable();

  const scheduleTemplate = {
    template_name: "",
    monday: 8,
    tuesday: 8,
    wednesday: 8,
    thursday: 8,
    friday: 8,
    saturday: 0,
    sunday: 0,
    is_holidays_active: false,
  };

  return (
    <Row>
      <Col>
        <Card className="card-border" id="create-customer-section">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addScheduleTemplate")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <OfficeScheduleTemplateForm
              officeId={officeId}
              scheduleTemplate={scheduleTemplate}
              onSuccess={reload}
              onDelete={reload}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default OfficeScheduleTemplateAddWidget;
