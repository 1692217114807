import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as companyAPI from "api/company";
import useInitialAsync from "hooks/useInitialAsync";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Loader } from "components/ui/loaders";

function SieRevertConfirmModal({ companyId, sieFile, handleClose, reload }) {
  const { t } = useTranslation(["company", "common"]);
  const { loading, item: revertSummary } = useInitialAsync(() =>
    companyAPI.checkSIEFileToRevert(companyId, sieFile.id)
  );
  function deleteSIEFile() {
    return companyAPI
      .deleteSIEFile(companyId, sieFile.id)
      .then((response) => {
        toast.success(t("msg:removingInProgress"));
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          reload();
        } else if (error.data.__all__) {
          toast.error(error.data.__all__);
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  }

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("common:actions.revertAndRemove")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        {!loading && revertSummary.warning && (
          <>
            <h6>{revertSummary.warning.msg}</h6>
            <ul>
              {revertSummary.warning.items.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
            <br />
          </>
        )}
        {!loading && revertSummary.error && (
          <>
            <h6>{revertSummary.error.msg}</h6>
            <ul>
              {revertSummary.error.items.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
            <br />
          </>
        )}
        <h5>{t("others:confirm.revertAndRemoveSieFile", { title: sieFile.name })}</h5>
      </Modal.Body>
      {!loading && (
        <Modal.Footer className="space">
          <Button type="button" variant="secondary" onClick={() => handleClose()}>
            {t("common:actions.cancel")}
          </Button>
          <Button disabled={!!revertSummary.error} type="button" variant="danger" onClick={() => deleteSIEFile()}>
            <span>
              <i className="fas fa-trash-alt mr-1" /> {t("common:actions.yesRemove")}
            </span>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default SieRevertConfirmModal;
