import React, { useCallback, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import * as contractAPI from "api/contracts";
import useInitialAsync from "hooks/useInitialAsync";
import { Loader } from "components/ui/loaders";
import { SubmitButton } from "components/ui/buttons";
import * as ciAPI from "api/customer-invoices";

import { confirmRemove } from "components/modals/ConfirmModal";
import { DraggableModalDialog } from "../DraggableModalDialog";

function SendDraftModal({ companyId, onCancel }) {
  const { t } = useTranslation("ci");
  const [selectedAll, setSelectedAll] = useState(true);
  const [sent, setSent] = useState(false);
  const [counter, setCounter] = useState(1);
  const [sortBy, setSortBy] = useState({
    field: "contract__customer_billecta_name",
    desc: false,
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    item: contracts,
    set,
    loading,
  } = useInitialAsync(
    () =>
      contractAPI.contracts
        .draftInvoices(companyId, {
          ordering: `${sortBy.desc ? "-" : ""}${sortBy.field}`,
        })
        .then((response) => {
          response.data = response.data.map((invoice, i) => ({
            ...invoice,
            index: i,
            checked: true,
            status: "READY",
          }));
          return response;
        }),
    [],
    [counter, sortBy.field, sortBy.desc]
  );

  function selectItem(index) {
    contracts[index].checked = !contracts[index].checked;
    set(contracts);
    setSelectedAll(false);
  }

  function selectToggleAll() {
    contracts.forEach((invoice) => {
      invoice.checked = !selectedAll;
    });
    // Object.keys(contracts).forEach((key) => {
    //   contracts[key] = contracts[key].map((invoice) => ({
    //     ...invoice,
    //     checked: !selectedAll,
    //   }));
    // });
    setSelectedAll(!selectedAll);
  }

  function sendInvoice(invoice) {
    return contractAPI.contracts
      .sendDraft(companyId, invoice.id)
      .then((response) => {
        contracts[invoice.index] = {
          ...contracts[invoice.index],
          status: "SUCCESS",
        };
        return response;
      })
      .catch((error) => {
        contracts[invoice.index] = {
          ...contracts[invoice.index],
          status: "ERROR",
          error: error.data,
        };
        return error;
      })
      .finally(() => {
        set(contracts);
      });
  }

  function sort(field) {
    const isDesc = sortBy.field === field && sortBy.desc;
    setSortBy({ field, desc: !isDesc });
  }

  const sendSelected = () => {
    const checkedInvoices = [];
    contracts
      .filter((invoice) => invoice.checked)
      .forEach((invoice) => {
        checkedInvoices.push({
          ...invoice,
        });
      });
    if (checkedInvoices.length) {
      setSubmitting(true);
    } else {
      return;
    }
    const promises = [];
    checkedInvoices.forEach((invoice, i) => {
      const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          return sendInvoice(invoice)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        }, i * 800);
      });
      promises.push(promise);
    });
    Promise.all(promises).then(() => {
      setSent(true);
      setSubmitting(false);
    });
  };

  const SortType = useCallback(
    ({ field }) => {
      if (sortBy.field === field) {
        if (sortBy.desc) {
          return <i className="fas fa-sort-down" />;
        }
        return <i className="fas fa-sort-up" />;
      }
      return <i className="fas fa-sort" />;
    },
    [sortBy.desc, sortBy.field]
  );

  const reload = () => {
    setCounter(counter + 1);
    setSent(false);
  };

  async function removeDraftInvoice(invoiceId) {
    const answer = await confirmRemove(t("confirm.removeDraft"));
    if (answer) {
      ciAPI
        .remove(companyId, invoiceId)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch(() => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  }

  return (
    <Modal animation={false} show onHide={onCancel} size="lg" dialogAs={DraggableModalDialog} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.contractSendDrafts")}</Modal.Title>
      </Modal.Header>
      {loading && <Loader />}
      {!loading && (
        <Table bordered>
          <thead>
            <tr>
              <td className="selectable" onClick={() => selectToggleAll()}>
                <div className="checkbox custom-control checkbox-primary">
                  <input type="checkbox" className="d-none" checked={selectedAll} readOnly />
                  <label className="custom-control-label" />
                </div>
              </td>
              <th>
                <div className="sortable" onClick={() => sort("contract__customer_billecta_name")}>
                  {t("common:customer")}
                  <SortType field="contract__customer_billecta_name" />
                </div>
              </th>
              <th>{t("common:dates.invoiceDate")}</th>
              <th className="text-right">{t("common:money.amount")}</th>
              <th>{t("sendInvoiceBy")}</th>
              <th colSpan={3} />
            </tr>
          </thead>
          <tbody>
            {contracts.map((invoice, idx) => (
              <React.Fragment key={invoice.id}>
                <tr>
                  <td className="selectable" onClick={() => selectItem(idx)}>
                    <div className="checkbox custom-control checkbox-primary">
                      <input type="checkbox" className="d-none" checked={invoice.checked} readOnly />
                      <label className="custom-control-label" />
                    </div>
                  </td>
                  {invoice.customer_snapshot?.Name ? (
                    <td>{invoice.customer_snapshot?.Name}</td>
                  ) : (
                    <td className="text-danger">{t("contract.invoiceMissingCustomer")}</td>
                  )}
                  <td>{invoice.booking_date}</td>
                  <td className="text-right">{invoice.amount}</td>
                  <td>{invoice.delivery_method}</td>
                  <td>
                    <Link to={`/invoicing/customer-invoices/${invoice.id}/change`} target="_blank">
                      {t("common:link")}
                    </Link>
                  </td>
                  <td>
                    {
                      {
                        READY: <span className="text-info">{t("common:READY")}</span>,
                        SUCCESS: <span className="text-success">{t("common:SUCCESS")}</span>,
                        ERROR: <span className="text-danger">{t("common:ERROR")}</span>,
                      }[invoice.status]
                    }
                  </td>
                  <td>
                    <Button variant="danger" size="sm" onClick={() => removeDraftInvoice(invoice.id)}>
                      <i className="fas fa-trash-alt" />
                    </Button>
                  </td>
                </tr>
                {invoice.error && (
                  <tr>
                    <td className="has-errors" colSpan={7}>
                      <ul>
                        {Object.values(invoice.error).map((error, index) => (
                          <li key={`er.${index}`}>{error}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
          {!contracts.length && (
            <tfoot>
              <tr>
                <td colSpan={7}>{t("common:noResultsFound")}</td>
              </tr>
            </tfoot>
          )}
        </Table>
      )}
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={onCancel}>
          {t("common:actions.cancel")}
        </Button>
        <Button type="button" variant="warning" onClick={reload}>
          {t("common:actions.reload")}
        </Button>
        {!sent && <SubmitButton title="actions.send" onClick={sendSelected} isSubmitting={isSubmitting} />}
      </Modal.Footer>
    </Modal>
  );
}

export default SendDraftModal;
