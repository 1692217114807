import React from "react";
import { Card, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useInitialAsync from "hooks/useInitialAsync";
import * as statsAPI from "api/stats";
import { Loader } from "components/ui/loaders";
import { formatMoney } from "utils/money";
import ManualVerificationTrack from "../ManualVerificationTrack/ManualVerificationTrack";
import { VerificationsBarChart } from "../charts";
import { arrayMonthsBack, pad2, StatusBox } from "../helpers";

function StatsVerificationPage() {
  const { t } = useTranslation("reports");
  const { item: data, loading } = useInitialAsync(statsAPI.verifications, {});
  const chartData = arrayMonthsBack(24, { newAll: 0, newManual: 0 });
  if (!loading) {
    data.newVerifications24months.forEach((item) => {
      try {
        chartData[`${item.year}-${pad2(item.month)}`].newAll = item.value;
      } catch (error) {
        // pass
      }
    });
    data.newManualVerifications24months.forEach((item) => {
      try {
        chartData[`${item.year}-${pad2(item.month)}`].newManual = item.value;
      } catch (error) {
        // pass
      }
    });
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <StatusBox label={t("stats.verInTotal")} value={formatMoney(data.numbers.total, 0, 0)} />
            <StatusBox label={t("stats.verAmount")} value={formatMoney(data.numbers.amount / 1000, 0, 0)} />
            <StatusBox label={t("stats.newVerThisMonth")} value={data.numbers.new} />
            <StatusBox label={t("stats.newManualThisMonth")} value={data.numbers.new_manual} />
          </Row>
        </Card.Body>
        <Card.Body>
          <VerificationsBarChart t={t} chartData={chartData} />
        </Card.Body>
      </Card>
      <ManualVerificationTrack />
    </>
  );
}

export default StatsVerificationPage;
