import React from "react";

function TableLoader({ ...props }) {
  return (
    <div className="table-loader" {...props}>
      <div className="table-loader-inner">
        <i className="fas fa-spinner fa-spin fa-5x" />
      </div>
    </div>
  );
}

export default TableLoader;
