import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BTable from "components/tables/btable";
import { NavLink } from "react-router-dom";

import * as companyAPI from "api/company";
import Filters from "./OnboardingFilters";
import OnboardingCheckbox from "./OnboardingCheckbox";
import "./OnboardingFinishingStepsTable.scss";

function OnboardingFinishingStepsTable({ hideFilters = false }) {
  const { t } = useTranslation("common");

  const onUpdate = useCallback((companyId, key, value) => {
    companyAPI.onboarding.changeFinishingStep(companyId, key, value).catch(() => {});
  }, []);

  const headers = useMemo(
    () => [
      {
        field: "company_name",
        sortField: "company__name",
        label: t("common:companyName"),
        render: (row) => (
          <NavLink to={`/onboarding/${row.id}`}>
            <span> {row.company_name}</span>
          </NavLink>
        ),
      },
      {
        field: "consult_name",
        sortField: "company__consult__last_name",
        label: t("common:consult"),
      },
      {
        field: "finishing_steps.cancel_licenses",
        label: t("company:cancelLicences"),
        canSort: false,
        className: "checkbox",
        render: (row) => (
          <OnboardingCheckbox
            checked={row.finishing_steps.cancel_licenses}
            name="cancel_licenses"
            companyId={row.company_id}
            onChange={onUpdate}
          />
        ),
      },
      {
        field: "finishing_steps.intro_meeting_booked",
        label: t("company:introMeetingBooked"),
        canSort: false,
        className: "checkbox",
        render: (row) => (
          <OnboardingCheckbox
            checked={row.finishing_steps.intro_meeting_booked}
            name="intro_meeting_booked"
            companyId={row.company_id}
            onChange={onUpdate}
          />
        ),
      },
      {
        field: "finishing_steps.intro_meeting",
        label: t("company:introMeeting"),
        canSort: false,
        className: "checkbox",
        render: (row) => (
          <OnboardingCheckbox
            checked={row.finishing_steps.intro_meeting}
            name="intro_meeting"
            companyId={row.company_id}
            onChange={onUpdate}
          />
        ),
      },
      {
        field: "finishing_steps.followup_meeting",
        label: t("company:followupMeeting"),
        canSort: false,
        className: "checkbox",
        render: (row) => (
          <OnboardingCheckbox
            checked={row.finishing_steps.followup_meeting}
            name="followup_meeting"
            companyId={row.company_id}
            onChange={onUpdate}
          />
        ),
      },
      {
        field: "finishing_steps.bank_access",
        label: t("company:bankAccess"),
        canSort: false,
        className: "checkbox",
        render: (row) => (
          <OnboardingCheckbox
            checked={row.finishing_steps.bank_access}
            name="bank_access"
            companyId={row.company_id}
            onChange={onUpdate}
            disabled
          />
        ),
      },
      {
        field: "finishing_steps.skv_access",
        label: t("company:skvDeklAccess"),
        canSort: false,
        className: "checkbox",
        render: (row) => (
          <OnboardingCheckbox
            checked={row.finishing_steps.skv_access}
            name="skv_access"
            companyId={row.company_id}
            onChange={onUpdate}
            disabled
          />
        ),
      },
    ],
    [t, onUpdate]
  );

  return (
    <BTable
      headers={headers}
      tableClassName="table-finishing-steps sticky-header"
      tableResponsive={false}
      FiltersComponent={hideFilters ? null : <Filters forSteps />}
    />
  );
}

export default OnboardingFinishingStepsTable;
