import client from "api/client";
import _ from "lodash";
import { getFileNameDispositionContent } from "utils/file";

export function onNewFileChange({ file, index, allFiles, setFile, max = 6 }) {
  let newData;

  if (Array.isArray(file) && file.length) {
    const newFiles = file.map((_file) => ({ key: _.uniqueId("nd."), file: _file }));
    newData = [...allFiles.slice(0, index), ...newFiles, ...allFiles.slice(index + 1)];
  } else if (file) {
    newData = [
      ...allFiles.map((d, di) => {
        return di !== index
          ? d
          : {
              ...d,
              file,
            };
      }),
    ];
  } else {
    newData = [...allFiles.slice(0, index), ...allFiles.splice(index + 1)].filter((_file) => {
      return "file" in _file;
    });
  }

  if ((allFiles.length < max && allFiles.length - 1 === index) || !newData.length) {
    newData.push({ key: _.uniqueId("nd.") });
  }
  setFile(newData);
}

export function uploadVerification(companyId, verificationId, files, fileId = "") {
  const formData = new FormData();
  files.forEach((file, i) => {
    if (file) {
      formData.append("file", file, file.name);
      if (file.description) {
        formData.append("description", file.description);
      }
      if (fileId) {
        formData.append("file_id", fileId);
      }
    }
  });
  return client.post(`/companies/${companyId}/verifications/${verificationId}/upload/verification/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadOffer(companyId, offerId, files) {
  const formData = new FormData();
  files.forEach((file) => {
    if (file) {
      formData.append("file", file, file.name);
    }
  });
  return client.post(`/companies/${companyId}/upload/offer/${offerId}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadAsset(companyId, assetId, files) {
  const formData = new FormData();
  files.forEach((file) => {
    if (file) {
      formData.append("file", file, file.name);
    }
  });
  return client.post(`/companies/${companyId}/upload/asset/${assetId}/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadSupplierInvoice(companyId, file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return client.post(`/companies/${companyId}/upload/supplier_invoice/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadReceipt(companyId, file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return client.post(`/companies/${companyId}/upload/receipt/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function logs(documentId) {
  return client.get(`/document-history/${documentId}/`);
}

export function rescanReceipt(companyId, file) {
  const formData = new FormData();

  formData.append("file", file, file?.name ?? "");

  return client.post(`/companies/${companyId}/upload/extract_from_document/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function fetchReceipt(companyId, documentId) {
  return client
    .get(`/companies/${companyId}/verification-documents/${documentId}/file/`, {
      responseType: "blob",
    })
    .then((response) => {
      const blob = response.data;
      const fileName = getFileNameDispositionContent(response) || "";

      return {
        url: blob,
        fileName,
        type: blob.type,
        size: blob.size,
        loading: false,
        error: "",
      };
    });
}
