import React from "react";
import { Alert, Col, Row, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { AllError, FormGroup, TableGroup } from "components/formik";
import { SubmitButton, TooltipActionButton } from "components/ui/buttons";
import _ from "lodash";
import * as options from "api/options";
import { toast } from "react-toastify";

function ApprovalRepresentationUpdateForm({ invoice, onSubmit }) {
  const { t } = useTranslation("si");
  const repActivities = options.repActivities.asList();
  const repPurposes = options.repPurposes.asList();
  const defaultActivity = null;
  const defaultPurpose = repPurposes[0];
  function filterActivites(option) {
    if (invoice.account?.number === 6071) {
      return option.value === "3"; // lunch dinner
    }
    return true;
  }
  const isThisRepresentation = () => {
    return [6071, 6072].includes(invoice.account.number);
  };
  const formikProps = {
    initialValues: {
      representation: !_.isEmpty(invoice.representation)
        ? {
            activity: invoice.representation.activity || defaultActivity,
            purpose: invoice.representation.purpose || defaultPurpose,
            participants: invoice.representation.participants
              ? invoice.representation.participants.filter((p) => !!p.name.trim() && !!p.company.trim())
              : [],
          }
        : {
            activity: defaultActivity,
            purpose: defaultPurpose,
            participants: [],
          },
    },
    onSubmit: async (values, { setErrors }) => {
      if (!isThisRepresentation()) {
        toast.error("Invalid account for representation.", { autoClose: 4000 });
        return;
      }
      const activityValue = values.representation.activity && values.representation.activity.value;
      const purposeValue = values.representation.purpose && values.representation.purpose.value;
      const participants = values.representation.participants.filter((p) => p.name.trim() && p.company.trim());
      if (!activityValue || !purposeValue || participants.length === 0) {
        toast.error("Representation invalid. Ensure correct activity, purpose, and at least 1 participant.", {
          autoClose: 4000,
        });
        return;
      }

      onSubmit({
        representation: {
          activity: activityValue,
          purpose: purposeValue,
          participants,
        },
      }).catch((response) => {
        setErrors(response.data);
      });
    },
  };
  const netAmountSek = (values) => {
    if (!invoice.amount) {
      return null;
    }
    return invoice.amount_sek - invoice.vat_amount_sek;
  };
  const showNonDeductibleWarning = (values) => {
    const participants = values.representation.participants.filter((p) => p.name.trim() && p.company.trim());
    if (invoice.account.number !== 6071) {
      return false;
    }
    return netAmountSek(invoice) / participants.length > 60;
  };
  return (
    <fieldset className="p-1 border-left-content border-info">
      <Formik {...formikProps}>
        {({ values, isSubmitting, errors, touched, dirty }) => {
          return (
            <Form noValidate>
              <Row>
                <Col>
                  {!invoice.manual_booking_enabled && isThisRepresentation() && (
                    <fieldset>
                      <h5>{t("common:rep.title")}</h5>
                      {showNonDeductibleWarning(values) && (
                        <Alert variant="danger">{t("common:rep.nonDeductibleWarningOn6071")}</Alert>
                      )}
                      <Row className="p-2">
                        <Col lg={6}>
                          <FormGroup.SimpleSelect
                            name="representation.activity"
                            label={t("common:rep.activity")}
                            options={repActivities.filter(filterActivites)}
                            value={values.representation.activity || defaultActivity}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormGroup.SimpleSelect
                            name="representation.purpose"
                            label={t("common:rep.purpose")}
                            options={repPurposes}
                            value={values.representation.purpose || defaultPurpose}
                          />
                        </Col>
                        <Col lg={12}>
                          <FieldArray
                            name="representation.participants"
                            render={({ remove, push }) => (
                              <Table size="sm" bordered>
                                <thead>
                                  <tr>
                                    <th>{t("common:rep.participant")}</th>
                                    <th>{t("common:company")}</th>
                                    <th>
                                      <TooltipActionButton
                                        size="xs"
                                        icon="fas fa-plus"
                                        variant="info"
                                        text={t("common:rep.addParticipant")}
                                        onClick={() =>
                                          push({
                                            name: "",
                                            company: "",
                                          })
                                        }
                                      />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {values.representation?.participants?.map((member, idx) => (
                                    <tr key={`p.${idx}`}>
                                      <TableGroup.Input name={`representation.participants[${idx}].name`} />
                                      <TableGroup.Input name={`representation.participants[${idx}].company`} />
                                      <td>
                                        <TooltipActionButton
                                          size="xs"
                                          icon="fas fa-trash"
                                          variant="danger"
                                          text={t("common:actions.remove")}
                                          onClick={() => remove(idx)}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                          />
                        </Col>
                      </Row>
                    </fieldset>
                  )}
                </Col>
              </Row>
              {dirty && (
                <Alert variant="danger" id="updateWarn">
                  <Trans i18nKey="msg:updateRepresentationOnPrelimWarn">
                    Changes detected! Press <strong>Save</strong> button bellow in order to submit changes.
                  </Trans>
                </Alert>
              )}
              <AllError errors={errors} />
              <SubmitButton variant="outline-info" size="sm" isSubmitting={isSubmitting} title="actions.save" />
            </Form>
          );
        }}
      </Formik>
    </fieldset>
  );
}

export default ApprovalRepresentationUpdateForm;
