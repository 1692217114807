import React from "react";
import { useTranslation } from "react-i18next";

import { useVerificationDispatch } from "hooks/useVerification";
import { HasPermCode } from "components/perms";
import { codesForVerifications } from "components/perms/PermCodes";

import { TooltipActionButton } from "./TooltipButton";

function VerificationBookingButton({ companyId, verification }) {
  const { t } = useTranslation("common");
  const { open } = useVerificationDispatch();

  const handleOpen = async () => {
    await open(companyId, verification);
  };
  if (HasPermCode(codesForVerifications.enabled)) {
    return <TooltipActionButton text={t("booking")} onClick={handleOpen} icon="fas fa-book-open" />;
  }
  return null;
}

export default VerificationBookingButton;
