import React, { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as ReactDOM from "react-dom";
import { toast } from "react-toastify";

import { DraggableModalDialog } from "../DraggableModalDialog";

function ExtraChargeModal({ giveAnswer, close }) {
  const { t } = useTranslation(["tt", "common"]);
  const ref = useRef();

  const yesCharge = () => {
    const comment = ref.current.value.trim();
    if (!comment) {
      toast.error(t("chargeComment"));
    } else {
      giveAnswer(true, comment);
    }
  };

  const noCharge = () => {
    giveAnswer(false, "");
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => giveAnswer(undefined, "")}
      dialogClassName="confirmation-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("extraCharge")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Control as="textarea" rows={3} ref={ref} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={noCharge}>
          {t("common:actions.no")}
        </Button>
        <Button type="button" variant="danger" onClick={yesCharge}>
          <span>
            <i className="fas fa-exclamation mr-1" /> {t("common:actions.yes")}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const confirmRoot = document.createElement("div");
const body = document.querySelector("body");
body.appendChild(confirmRoot);

const confirmExtraCharge = () =>
  new Promise((res) => {
    const giveAnswer = (answer, comment) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      res({ answer, comment });
    };

    ReactDOM.render(<ExtraChargeModal giveAnswer={giveAnswer} />, confirmRoot);
  });

export default confirmExtraCharge;
