import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./StepsProgress.scss";
import cx from "classnames";
import {
  OnboardingDispatchContext,
  OnboardingStateContext,
} from "pages/companies/OnboardingWizard/provider/OnboardingProvider";

import StepStatus from "./StepStatus";

function StepBar({ number, name, status, active = false, stepId }) {
  const stepNumberStr = `${number}`.padStart(2, "0");
  const { goTo } = useContext(OnboardingDispatchContext);

  return (
    <div className={cx("step-bar", { "step-bar--active": active })}>
      <div className={cx("step-progress", status)} />
      <div className="step-number">Step {stepNumberStr}</div>
      <div className="step-name">{name}</div>
      <div className="step-actions">
        <StepStatus onClick={() => goTo(stepId)} status={status} disabled={status === "locked"} />
      </div>
    </div>
  );
}

const elementWidth = 246;

function StepsProgress({ activeStep, steps }) {
  const { t } = useTranslation("company");
  const [isRightHidden, setIsRightHidden] = useState(false);
  const [isLeftHidden, setIsLeftHidden] = useState(false);
  const scrollRef = useRef();

  const getRightVisibility = () => {
    const { scrollLeft, scrollWidth, offsetWidth } = scrollRef.current;
    const isHidden = Math.ceil(scrollLeft) >= scrollWidth - offsetWidth;
    setIsRightHidden(isHidden);
  };

  const getLeftVisibility = () => {
    const isHidden = scrollRef.current.scrollLeft === 0;
    setIsLeftHidden(isHidden);
  };

  useEffect(() => {
    let timer = 0;
    const currentScrollRef = scrollRef.current;

    getRightVisibility();
    getLeftVisibility();

    const handleScroll = () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        getRightVisibility();
        getLeftVisibility();
      }, 150);
    };

    currentScrollRef.addEventListener("scroll", handleScroll, false);

    return () => {
      clearTimeout(timer);
      currentScrollRef.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    scrollRef.current.scrollLeft = (activeStep - 1) * elementWidth;
  }, [activeStep]);

  const handleRightClick = () => {
    scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
    getRightVisibility();
  };

  const handleLeftClick = () => {
    scrollRef.current.scrollLeft = 0;
    getLeftVisibility();
  };

  return (
    <div className="steps-progress__wrapper">
      <nav className={cx("arrow-left", { hidden: isLeftHidden })} onClick={handleLeftClick}>
        <i className="fe-chevrons-left" />
      </nav>
      <div className="steps-progress__scroll-container" ref={scrollRef}>
        <div className="steps-progress">
          {steps.map((step, idx) => (
            <StepBar
              key={step.id}
              number={idx + 1}
              stepId={step.id}
              name={t(`onboarding.steps.${step.id}`)}
              status={step.status}
              active={idx + 1 === activeStep}
            />
          ))}
        </div>
      </div>
      <nav className={cx("arrow-right", { hidden: isRightHidden })} onClick={handleRightClick}>
        <i className="fe-chevrons-right" />
      </nav>
    </div>
  );
}

function StepsProgressWrapper() {
  const { steps, activeStep } = useContext(OnboardingStateContext);

  return <StepsProgress steps={steps} activeStep={activeStep.number} />;
}

export default StepsProgressWrapper;
