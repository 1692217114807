import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BTable from "components/tables/btable";
import { NavLink } from "react-router-dom";
import Filters from "./OfficeFilters";
import Actions from "./OfficeActions";

function OfficeTable() {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:agencyName"),
        render: (row) => (
          <NavLink to={`/offices/${row.id}/settings`}>
            <span> {row.name}</span>
          </NavLink>
        ),
      },
      {
        field: "contact_name",
        label: t("common:contact.contactName"),
        canSort: false,
        render: (row) => `${row.contact_first_name || ""} ${row.contact_last_name || ""}`,
      },
      {
        field: "contact_email",
        label: t("common:contact.contactEmail"),
        canSort: false,
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      FiltersComponent={<Filters />}
      renderActions={(row) => <Actions row={row} />}
    />
  );
}

export default OfficeTable;
