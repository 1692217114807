import React from "react";
import useTable from "components/tables/btable/useTable";
import AccountPlanForm from "./AccountPlanForm";

function AccountPlanEditWidget({ companyId, account }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(
      {
        ...newData,
        number: newData.account,
      },
      "number"
    );
  };

  return <AccountPlanForm companyId={companyId} account={account} successCallback={successCallback} />;
}

export default AccountPlanEditWidget;
