import React from "react";

import { HistoryButtonCreated, TimeLogCommentsButton } from "components/ui/buttons";

function TimeReportActions({ row, companyId }) {
  return (
    <>
      <HistoryButtonCreated createdAt={row.created} createdBy={row.user_name} id={row.id} />
      <TimeLogCommentsButton companyId={companyId} log={row} />
    </>
  );
}

export default TimeReportActions;
