import React, { useState } from "react";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { SubmitButton } from "components/ui/buttons";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { handleActionErrors } from "api/errors";

import { FormGroup } from "components/formik";
import * as companyAPI from "api/company";
import { formatDate, parseDate } from "../../../utils/date";

function SieExportForm({ companyId, fy, onFinish }) {
  const { t } = useTranslation("common");
  const [isSubmitting, setSubmitting] = useState(false);

  const downloadSE = async (values) => {
    setSubmitting(true);

    await companyAPI
      .exportFinancialYear(companyId, fy.id, {
        period_start: formatDate(values.period_start),
        period_end: formatDate(values.period_end),
      })
      .then((response) => {
        const element = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        element.href = url;
        element.download = `${companyId}_${formatDate(values.period_start)}_${formatDate(values.period_end)}.se`;
        document.body.appendChild(element);
        element.click();
        element.remove();
        URL.revokeObjectURL(url);
        onFinish();
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };
  const formikProps = {
    initialValues: {
      period_start: parseDate(fy.date_start),
      period_end: parseDate(fy.date_end),
    },
    validationSchema: yup.object().shape({
      period_start: yup.date().nullable().required(),
      period_end: yup.date().nullable().required(),
    }),
    onSubmit: async (values) => {
      downloadSE(values);
      onFinish();
    },
  };

  return (
    <Formik {...formikProps}>
      {() => {
        return (
          <Form id="sieExportForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <FormGroup.DatePicker name="period_start" label={t("common:starts")} required />
                  </Col>
                  <Col>
                    <FormGroup.DatePicker name="period_end" label={t("common:ends")} required />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <Button type="button" variant="warning" onClick={onFinish}>
                    {t("actions.cancel")}
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} title="actions.download" />
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SieExportForm;
