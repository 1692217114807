import React, { useContext } from "react";

import "./BankList.scss";
import danskeLogo from "assets/images/banks/danske.png";
import handelLogo from "assets/images/banks/handel.png";
import nordeaLogo from "assets/images/banks/nordea.png";
import sebLogo from "assets/images/banks/seb.png";
import swedbankLogo from "assets/images/banks/swedbank.png";
import lsLogo from "assets/images/banks/ls.png";
import { BankConnectionContext } from "../../../state/providers/BankConnectionProvider";

const banks = [
  {
    bicFi: "ESSESESS",
    name: "Skandinaviska Enskilda Banken AB",
    logoUrl: sebLogo,
  },
  {
    bicFi: "HANDSESS",
    name: "Handelsbanken",
    logoUrl: handelLogo,
  },
  {
    bicFi: "SWEDSESS",
    name: "Swedbank",
    logoUrl: swedbankLogo,
  },
  {
    bicFi: "NDEASESS",
    name: "Nordea Bank AB",
    logoUrl: nordeaLogo,
  },

  {
    bicFi: "DABASESX",
    name: "Danske Bank A/S",
    logoUrl: danskeLogo,
  },
];

function BankItem({ item, onSelect }) {
  return (
    <div className="bank-item" onClick={() => onSelect(item.bicFi)}>
      <img src={item.logoUrl} alt={item.name} title={item.name} />
      <i className="fe-chevron-right" />
    </div>
  );
}

function BankList({ companyId }) {
  const { onBankSelection } = useContext(BankConnectionContext);
  const betaBankLS = {
    bicFi: "ELLFSESS",
    name: "Länsförsäkringar",
    logoUrl: lsLogo,
  };

  return (
    <div className="bank-list">
      {banks.map((item) => (
        <BankItem key={item.bicFi} item={item} onSelect={onBankSelection} />
      ))}
      {companyId === 966 && <BankItem key={betaBankLS.bicFi} item={betaBankLS} onSelect={onBankSelection} />}
    </div>
  );
}

export default BankList;
