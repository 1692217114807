import React, { useState } from "react";
import { useNavigate, useOutletContext, useParams, useResolvedPath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { addDays } from "date-fns";
import { Button, ButtonGroup, Card, Col, Row, Table } from "react-bootstrap";

import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";
import { ItemNotFoundErrorPage } from "pages/errors";
import * as siAPI from "api/supplier-invoices";
import * as options from "api/options";
import { SavedDocuments } from "components/ui/documents/verification-documents";
import { NoDocumentBox } from "components/ui/documents/document-box";
import { PreviewCheckbox, PreviewInput } from "components/formik/PreviewInput";
import { formatDate } from "utils/date";
import { formatMoney } from "utils/money";
import useModal from "hooks/useModal";
import InvestigateModal from "components/modals/InvestigateModal";
import ReInvoiceModal from "components/modals/ReInvoiceModal";
import { ReInvoicedAlert } from "components/ui/alerts";
import { toast } from "react-toastify";
import ApprovalDataUpdateForm from "components/forms/ApprovalDataUpdateForm";
import { useCompanyState } from "hooks/useCompany";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { confirmExecute } from "components/modals/ConfirmModal";
import { VerificationCommentsModal, VerificationInlineComments } from "components/modals/CommentsModal";
import { codesFor, HasPermCode } from "components/perms";
import { getSupplierName } from "utils/others";
import { truncateText } from "utils/text";
import RepresentationPreviewContent from "pages/dashboard/expenses/RepresentationPreviewContent/RepresentationPreviewContent";
import ApprovalRepresentationUpdateForm from "components/forms/ApprovalRepresentationUpdateForm";
import PreviewPartialUpdateForm from "./PartialUpdateForm";

function SupplierInvoicePreviewPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const currentPath = useResolvedPath("").pathname;
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const { t } = useTranslation("si");
  const [refreshCount, setRefreshCount] = useState(0);
  const investigateModal = useModal();
  const reInvoiceModal = useModal();
  const commentsModal = useModal();
  const { id: invoiceId } = useParams();
  const { loading, item } = useInitialAsync(() => siAPI.details(company.id, invoiceId), {}, [refreshCount, invoiceId]);
  const {
    accounts: { byId: accountById },
    costCenters: { byId: centerById },
    projects: { byId: projectById },
  } = useCompanyState();
  const canApproveSI = HasPermCode(codesFor.supplierInvoices.approve);
  const canManageCI = HasPermCode(codesFor.customerInvoices.manage);

  if (loading) {
    return <RocketLoader />;
  }

  if (!loading && _.isEmpty(item)) {
    return <ItemNotFoundErrorPage />;
  }

  const invoice = {
    ...item,
    booking_date: item.booking_date ? formatDate(item.booking_date) : null,
    due_date: formatDate(item.due_date ? item.due_date : addDays(item.booking_date, 30)),
    amount: `${formatMoney(item.amount)} ${item.amount_currency}`,
    vat_amount: `${formatMoney(item.vat_amount)} ${item.amount_currency}`,
    account: item.account && accountById[item.account],
    cost_center: item.cost_center && centerById[item.cost_center],
    project: item.project && projectById[item.project],
    periodisation_account: item.periodisation_account ? accountById[item.periodisation_account].label : null,
    manualBooking: item.manual_booking_enabled
      ? item.manual_booking.map((trans) => ({
          account: accountById[trans.account].label,
          project: trans.project ? projectById[trans.project].label : "",
          cost_center: trans.cost_center ? centerById[trans.cost_center].label : "",
          amount: `${formatMoney(trans.amount)}`,
          periodisation_config: trans.periodisation_config,
        }))
      : [],
    representation: !_.isEmpty(item.representation)
      ? {
          activity: options.repActivities.getOption(item.representation.activity),
          purpose: options.repPurposes.getOption(item.representation.purpose),
          participants:
            item.representation && item.representation.participants
              ? item.representation.participants.filter((p) => !!p.name.trim() && !!p.company.trim())
              : [],
        }
      : null,
  };

  const inApproval = _.includes(["prelim", "disputed"], invoice.sub_status);
  const previewUpdate = _.includes(["prelim", "disputed", "unpaid"], invoice.sub_status);

  const handleApprove = async () => {
    const unsavedWarn = !!document.getElementById("updateWarn");
    if (unsavedWarn) {
      const answer = await confirmExecute(t("common:confirm.unsavedData"));
      if (!answer) {
        return true;
      }
    }
    await siAPI
      .approve(company.id, item.id, { next: true })
      .then((response) => {
        if (response.data.next) {
          const nextLoc = currentPath.replace(item.id, response.data.next);
          navigate(nextLoc, { replace: true });
        } else {
          navigate(-1);
        }
        reloadMenuNumbers();
      })
      .catch((error) => {
        const errorMsg = "__all__" in error.data ? error.data.__all__ : error.data;
        if (errorMsg) {
          toast.error(errorMsg);
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
    return true;
  };

  const handlePartialUpdate = (values) => {
    return siAPI.approveUpdate(company.id, values).then((response) => {
      setRefreshCount(refreshCount + 1);
      return response;
    });
  };

  const handleRepresentationUpdate = (values) => {
    return siAPI.approveRepresentationUpdate(company.id, item.id, values).then((response) => {
      setRefreshCount(refreshCount + 1);
      return response;
    });
  };

  const handlePreviewUpdate = async (values) => {
    const answer = await confirmExecute(t("confirm.updateOnPreview"));
    if (answer) {
      return siAPI.previewUpdate(company.id, values).then((response) => {
        setRefreshCount(refreshCount + 1);
        return response;
      });
    }
    return null;
  };

  const handleInvestigate = async (values) => {
    await siAPI.investigate(company.id, item.id, values).then(() => {
      navigate(-1);
    });
  };

  const handleReInvoice = async (values) => {
    await siAPI
      .reInvoice(company.id, item.id, {
        billecta_customer_id: values.customer.value,
        your_reference: values.customer.ContactName || "",
        our_reference: values.customer.DefaultActionConfig.OurReference || "",
      })
      .then(() => {
        // refresh
        navigate("/temp");
        navigate(-1);
      });
  };

  const Buttons = (
    <ButtonGroup>
      {inApproval && canApproveSI && (
        <Button variant="first" onClick={handleApprove}>
          <i className="fas fa-save" /> {t("common:actions.approve")}
        </Button>
      )}
      <Button variant={invoice.comments.length ? "warning" : "second"} onClick={() => commentsModal.open(invoice.id)}>
        <i className="fas fa-comment-dots" /> {t("common:comments")}
      </Button>
      {inApproval && (
        <>
          {canApproveSI && (
            <Button variant="second" onClick={investigateModal.open}>
              {t("common:actions.investigate")}
            </Button>
          )}
          {canManageCI && !invoice.reinvoice_to && (
            <Button variant="second" onClick={reInvoiceModal.open}>
              {t("common:actions.reInvoice")}
            </Button>
          )}
        </>
      )}
    </ButtonGroup>
  );

  return (
    <>
      <Card>
        <Card.Body>
          <InvoiceContent
            company={company}
            invoice={invoice}
            handlePartialUpdate={handlePartialUpdate}
            handlePreviewUpdate={handlePreviewUpdate}
            handleRepresentationUpdate={handleRepresentationUpdate}
            inApproval={inApproval}
            previewUpdate={previewUpdate}
            t={t}
          >
            {Buttons}
          </InvoiceContent>
        </Card.Body>
      </Card>
      {investigateModal.show && <InvestigateModal handleClose={investigateModal.close} onSubmit={handleInvestigate} />}
      {reInvoiceModal.show && (
        <ReInvoiceModal companyId={company.id} handleClose={reInvoiceModal.close} onSubmit={handleReInvoice} />
      )}
      {commentsModal.show && (
        <VerificationCommentsModal
          companyId={company.id}
          verification={{ id: commentsModal.data }}
          handleClose={commentsModal.close}
        />
      )}
    </>
  );
}

function InvoiceContent({
  company,
  invoice,
  inApproval,
  previewUpdate,
  handlePartialUpdate,
  handlePreviewUpdate,
  handleRepresentationUpdate,
  t,
  children,
}) {
  const canManageSI = HasPermCode(codesFor.supplierInvoices.manage);
  const canApproveSI = HasPermCode(codesFor.supplierInvoices.approve) || HasPermCode(codesFor.consultManage.enabled);
  const isThisRepresentation = () => {
    return [6071, 6072].includes(invoice.account.number);
  };
  return (
    <Row>
      <Col xl={5}>
        <Row>
          <Col lg={3}>
            <PreviewInput
              label={t("common:supplier")}
              value={getSupplierName(invoice.supplier_obj, invoice.supplier_name) || "-"}
            />
          </Col>
          <Col lg={3}>
            <PreviewInput
              label={t("common:paymentMethod")}
              value={invoice.supplier_obj ? invoice.supplier_obj.payment_method_str : "-"}
            />
          </Col>
          <Col lg={3}>
            <PreviewInput label={t("common:invoiceNo")} value={invoice.invoice_no} />
          </Col>
          <Col lg={3}>
            <PreviewInput label="OCR" value={invoice.ocr} />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <PreviewInput label={t("common:dates.invoiceDate")} value={invoice.booking_date} />
          </Col>
          <Col lg={3}>
            <PreviewInput label={t("common:dates.dueDate")} value={invoice.due_date} />
          </Col>
          <Col lg={3}>
            <PreviewInput label={t("common:money.amount")} value={invoice.amount} />
          </Col>
          <Col lg={3}>
            <PreviewInput label={t("common:money.vatAmount")} value={invoice.vat_amount} />
          </Col>
        </Row>
        {invoice.manual_booking_enabled && <ManualBookingContent manualBooking={invoice.manualBooking} />}
        {!inApproval && (
          <Row>
            <Col lg={6}>
              <PreviewInput label={t("common:costAccount")} value={invoice.account && invoice.account.label} />
            </Col>
            <Col lg={3}>
              <PreviewInput label={t("common:costCenter")} value={invoice.cost_center && invoice.cost_center.label} />
            </Col>
            <Col lg={3}>
              <PreviewInput label={t("common:project")} value={invoice.project && invoice.project.label} />
            </Col>
          </Row>
        )}
        {!invoice.manual_booking_enabled &&
          invoice.account &&
          isThisRepresentation() &&
          (inApproval && canApproveSI ? (
            <ApprovalRepresentationUpdateForm invoice={invoice} onSubmit={handleRepresentationUpdate} />
          ) : (
            <RepresentationPreviewContent t={t} instance={invoice} />
          ))}
        <Row className="mb-2">
          <Col>
            <Card.Title>{t("common:options")}</Card.Title>
            <PreviewCheckbox label={t("deactivatePayment")} checked={invoice.deactivate_payment} />
            <PreviewCheckbox label={t("common:periodisation")} checked={invoice.periodisation_enabled} />
            {invoice.periodisation_enabled && (
              <Row className="p-1">
                <Col xl={4}>
                  <PreviewInput label={t("perMonthsNo")} value={invoice.periodisation_months} />
                </Col>
                <Col xl={3}>
                  <PreviewInput label={t("perStartOn")} value={formatDate(invoice.periodisation_date_start)} />
                </Col>
                <Col xl={5}>
                  <PreviewInput label={`${t("common:costAccount")} (PER)`} value={invoice.periodisation_account} />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {inApproval && canApproveSI && <ApprovalDataUpdateForm invoice={invoice} onSubmit={handlePartialUpdate} />}
        {previewUpdate && canManageSI && <PreviewPartialUpdateForm invoice={invoice} onSubmit={handlePreviewUpdate} />}
        <VerificationInlineComments companyId={company.id} itemId={invoice.id} />
        <ReInvoicedAlert invoiceId={invoice.reinvoice_to} />
        <div className="mt-3">
          <hr />
          {children}
        </div>
      </Col>
      <Col xl={7} className="verification-files">
        <SavedDocuments companyId={company.id} documents={invoice.documents} removable={false} />
        {!invoice.documents.length && <NoDocumentBox />}
      </Col>
    </Row>
  );
}

function ManualBookingContent({ manualBooking }) {
  const { t } = useTranslation("common");
  return (
    <Row>
      <Col>
        <label className="mb-0">{t("si:manualBooking")}</label>
        <Table size="sm" bordered>
          <thead>
            <tr>
              <th>{t("account")}</th>
              <th style={{ width: 130 }}>{t("project")}</th>
              <th style={{ width: 130 }}>{t("costCenter")}</th>
              <th style={{ width: 100 }} className="text-right">
                {t("money.amount")}
              </th>
            </tr>
          </thead>
          <tbody>
            {manualBooking.map((trans, i) => (
              <>
                <tr key={`t${i}`}>
                  <td title={trans.account}>{truncateText(trans.account)}</td>
                  <td title={trans.project}>{truncateText(trans.project)}</td>
                  <td title={trans.cost_center}>{truncateText(trans.cost_center)}</td>
                  <td className="text-right">{trans.amount}</td>
                </tr>
                {trans.periodisation_config && (
                  <tr>
                    <td colSpan={12} style={{ "padding-top": 0 }}>
                      <table style={{ "border-top": "none", padding: 0 }} width="100%" cellSpacing="0" cellPadding="0">
                        <tr style={{ "border-top": "none", padding: 0 }}>
                          <td style={{ width: 100, border: "none" }} />
                          <td style={{ "border-top": "none", "border-right": "none" }} className="text-right">
                            Periodisation
                          </td>
                          <td
                            style={{
                              "border-top": "none",
                              "border-right": "none",
                              "border-left": "none",
                            }}
                          >
                            Starts: {trans.periodisation_config.date_start}
                          </td>
                          <td
                            style={{
                              "border-top": "none",
                              "border-right": "none",
                              "border-left": "none",
                            }}
                          >
                            {trans.periodisation_config.months} months
                          </td>
                          <td style={{ "border-top": "none", "border-left": "none" }}>
                            P&L acc.: {trans.periodisation_config.pl_account_id}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default SupplierInvoicePreviewPage;
