import { formatDiscount } from "utils/calc/ci";
import { roundMoney } from "utils/money";

export function formatData(data) {
  return {
    ...data,
    payment_terms: data.payment_terms.value,
    records: data.records.map((record, idx) => {
      delete record.key;
      if (record.RecordType === "Standard") {
        return {
          ...record,
          UnitPrice:
            record.RecordType === "Standard"
              ? {
                  Value: record.UnitPrice * 100,
                  CurrencyCode: "SEK",
                }
              : "",
          RotRutAmount: {
            Value: record.RotRutAmount * 100 || 0,
            CurrencyCode: "SEK",
          },
          Quantity: roundMoney(record.Quantity),
          SequenceNo: idx + 1,
          ...formatDiscount(record, "SEK"),
          VAT: record.VAT ? parseInt(record.VAT.value, 10) : 0,
        };
      }
      return {
        RecordType: record.RecordType,
        ArticleDescription: record.ArticleDescription,
        SequenceNo: idx + 1,
      };
    }),
  };
}
