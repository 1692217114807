import React from "react";

import * as officeSupportApi from "api/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportAccountingForm from "./OfficeSupportAccountingForm";

function OfficeSupportAccountingWidget({ data: item, companyId, changeEditedForm }) {
  return <OfficeSupportAccountingForm companyId={companyId} changeEditedForm={changeEditedForm} data={item} />;
}

const EnhancedOfficeSupportAccountingWidget = withInitialAsync(
  OfficeSupportAccountingWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => officeSupportApi.accountingDetails.get(companyId, { cancelToken }), [companyId]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportAccountingWidget;
