import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, Card, Modal } from "react-bootstrap";
import useInitialAsync from "../../../hooks/useInitialAsync";
import { consultDocument } from "../../../api/archive";
import { RocketLoader } from "../../ui/loaders";
import customConfirm, { confirmRemove } from "../../modals/ConfirmModal";
import useModal from "../../../hooks/useModal";
import { DocumentsModal } from "../../modals";
import { DraggableModalDialog } from "../../modals/DraggableModalDialog";
import { FormGroup } from "../../formik";

import { SubmitButton } from "../../ui/buttons";
import { formatDate } from "../../../utils/date";
import * as options from "../../../api/options";

function getArchiveTemplate(category) {
  return {
    category,
    title: "",
  };
}

function UploadBtn({ onFilesSelected }) {
  const fileRef = useRef(null);

  return (
    <div className="upload-btn upload">
      <Button variant="second" size="sm" onClick={() => fileRef.current.click()}>
        <i className="fe-upload" />
      </Button>
      <input ref={fileRef} type="file" onChange={onFilesSelected} multiple />
    </div>
  );
}

function CardItem({ companyId, item, onDelete }) {
  const navigate = useNavigate();
  const fileModal = useModal();
  return (
    <>
      <li key={item.id} className="card-item">
        <div className="details" onClick={fileModal.open}>
          <span>{item.title}</span>
        </div>
        <i
          className="edit-btn fa fa-pencil-alt"
          onClick={() => navigate(`/office-support-companies/documents/${item.id}/change`)}
        />
        <i className="del-btn fa fa-times-circle" onClick={() => onDelete(item)} />
      </li>
      {fileModal.show && (
        <DocumentsModal
          partUrl="consultant-documents"
          companyId={companyId}
          documentIds={[item.id]}
          handleClose={fileModal.close}
          removable={false}
        />
      )}
    </>
  );
}

const ALLOWED_FILESIZE = 1024 * 1024 * 5; // 5 MB;

function AddMoreDataModalForServiceAgreement({ giveAnswer }) {
  const { t } = useTranslation("common");
  const forFirstDayOfCurrentMonth = new Date();
  const formikProps = {
    initialValues: {
      month: new Date(forFirstDayOfCurrentMonth.getFullYear(), forFirstDayOfCurrentMonth.getMonth(), 1),
    },
    validationSchema: yup.object().shape({
      month: yup.date().nullable().required(),
    }),
    onSubmit: async (values) => {
      return giveAnswer(values);
    },
  };
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => giveAnswer(null)}
      dialogClassName="confirmation-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("company:actions.consultantDocumentMonthQuestion")}</Modal.Title>
      </Modal.Header>
      <Formik {...formikProps}>
        {({ values, isSubmitting }) => {
          return (
            <Form id="form-more-data">
              <Modal.Body>
                <FormGroup.DateMonthPicker
                  required
                  name="month"
                  label={t("common:dates.month")}
                  popperClassName="popper-in-modal"
                />
              </Modal.Body>
              <Modal.Footer className="space">
                <Button type="button" variant="secondary" onClick={() => giveAnswer(false)}>
                  {t("common:actions.cancel")}
                </Button>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

function ConsultCategoryDropZoneCategory({ companyId, category, items, reloadData }) {
  const [dragging, setDragging] = useState(false);
  const { t } = useTranslation("options");
  const onDelete = async (row) => {
    const answer = await confirmRemove(t("others:confirm.removeFileArchive", { title: row.title }));
    if (answer) {
      consultDocument
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reloadData();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  const _onFilesSelected = (event, extraData, selectedFiles) => {
    if (!selectedFiles.length) {
      setDragging(false);
      return false;
    }
    const maxCount = 10;
    if (selectedFiles) {
      if (selectedFiles.length > maxCount) {
        setDragging(false);
        toast.error(t("msg:fileLengthLimit", { max: maxCount }));
        return false;
      }
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles.item(i).size > ALLOWED_FILESIZE) {
        toast.error(t("msg:fileToBig", { max: (ALLOWED_FILESIZE / (1024 * 1024)).toFixed(1) }), {
          autoClose: 4000,
        });
        setDragging(false);
        return false;
      }
    }
    const promises = [];
    const body = getArchiveTemplate(category);
    let promise;
    for (let i = 0; i < selectedFiles.length; i++) {
      promise = consultDocument
        .save(companyId, [selectedFiles.item(i)], {
          ...body,
          ...extraData,
          title: selectedFiles.item(i).name,
          month: extraData.month ? formatDate(extraData.month) : null,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {});
      promises.push(promise);
    }
    Promise.all(promises).then((results) => {
      toast.success(t("msg:uploaded"));
      setDragging(false);
      reloadData();
    });

    return true;
  };

  const onFilesSelected = async (event, selectedFiles) => {
    event.stopPropagation();
    event.preventDefault();
    const extraDataProvided = await customConfirm(({ giveAnswer }) => (
      <AddMoreDataModalForServiceAgreement giveAnswer={giveAnswer} />
    ));
    if (!extraDataProvided) {
      reloadData();
      return false;
    }
    _onFilesSelected(event, extraDataProvided, selectedFiles);
    return true;
  };

  const onDragOver = (event) => {
    event.preventDefault();
    return false;
  };
  const onDragEnter = (event) => {
    setDragging(true);
    event.preventDefault();
    return false;
  };
  const onDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };
  return (
    <div className="draggable" onDragEnter={onDragEnter}>
      {dragging && (
        <div
          className="draggable-layer"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={(e) => onFilesSelected(e, e.dataTransfer.files)} // files need to be provided here, to do not lose them from event on async/await
        />
      )}
      <Card className="archive-category-card">
        <Card.Header>{options.consultArchiveCategories.getOption(category).label}</Card.Header>
        {items.length ? (
          <Card.Body className="files">
            <ul>
              {items.map((item) => (
                <CardItem key={item.id} companyId={companyId} item={item} onDelete={onDelete} />
              ))}
            </ul>
            <UploadBtn onFilesSelected={(e) => onFilesSelected(e, e.target.files)} />
          </Card.Body>
        ) : (
          <Card.Body className="text-muted">
            <ul>
              <li className="text-muted">{t("common:noFilesUploaded")}</li>
            </ul>
            <UploadBtn onFilesSelected={(e) => onFilesSelected(e, e.target.files)} />
          </Card.Body>
        )}
      </Card>
    </div>
  );
}

function ConsultArchiveDropzonesWidget({ companyId, counter, setCounter, reloadIndicators }) {
  const {
    dataActions: { reload },
  } = useTable();

  const { loading, item } = useInitialAsync(
    () => {
      return consultDocument.quickList(companyId);
    },
    {},
    [counter]
  );
  const reloadData = useCallback(() => {
    setCounter((c) => c + 1);
    reload();
    reloadIndicators();
  }, [setCounter, reload, reloadIndicators]);

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <Card id="company-archive">
      <Card.Body className="grid-1" style={{ padding: "0.2em" }}>
        <div>
          <ConsultCategoryDropZoneCategory
            key="kyc"
            companyId={companyId}
            category="kyc"
            items={item.kyc}
            reloadData={reloadData}
          />
          <ConsultCategoryDropZoneCategory
            key="service_agreement_incl_attachments"
            companyId={companyId}
            category="service_agreement_incl_attachments"
            items={item.service_agreement_incl_attachments}
            reloadData={reloadData}
          />
        </div>
      </Card.Body>
    </Card>
  );
}

export default ConsultArchiveDropzonesWidget;
