import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./SkatteverketConnectModal.scss";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import StepProgress from "./StepProgress";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";

function SkatteverketConnectModal({ companyId, handleClose }) {
  const { t } = useTranslation("company");
  const [step, setStep] = useState(1);
  return (
    <Modal
      show
      onHide={handleClose}
      size="lg"
      className="skatteverket-connect-modal"
      scrollable
      backdrop="static"
      keyboard={false}
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        {step === 1 && <p className="agency-info">{t("skat.addSkatConnection")}</p>}
      </Modal.Header>
      <div className="modal-wrapper">
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}
        {step === 4 && <Step4 companyId={companyId} step={step} setStep={setStep} />}
        {step !== 4 && (
          <Modal.Body>
            {step > 1 && (
              <Button className="prev-step" variant="link" onClick={() => setStep(step - 1)}>
                <i className="fe-chevron-left" /> {t("common:actions.goBack")}
              </Button>
            )}
            <Button className="next-step" onClick={() => setStep(step + 1)}>
              {t("common:actions.next")} <i className="fe-chevron-right" />
            </Button>
            <StepProgress active={step} />
          </Modal.Body>
        )}
      </div>
    </Modal>
  );
}

export default SkatteverketConnectModal;
