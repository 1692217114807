import React from "react";
import DropZoneInput from "./DropZoneInput";
import { DocumentBox } from "./document-box";

function NewDocuments({ documents, onChange, fileTypes, customValidate, fileSize, multiple }) {
  return (
    documents &&
    documents.map((doc, index) => (
      <DropZoneInput
        initialFile={doc.file}
        data-id={doc.key || "00"}
        key={doc.key}
        fileSize={fileSize}
        fileTypes={fileTypes}
        customValidate={customValidate}
        onFileChange={(file) => onChange({ file, index })}
        multiple={multiple}
      />
    ))
  );
}

function SavedDocuments({ documents, companyId, partUrl = "verification-documents", removable = true }) {
  return (
    <>
      {documents.map((docId) => (
        <DocumentBox
          key={`sd.${docId}`}
          partUrl={partUrl}
          companyId={companyId}
          documentId={docId}
          removable={removable}
        />
      ))}
    </>
  );
}

export { NewDocuments, SavedDocuments };
