import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import SupplierInvoiceListPage from "./SupplierInvoiceListPage";

function ApproveSupplierInvoiceListPage() {
  const allowedModes = [
    { value: "approvalAndDraft", label: "common:statuses.all", variant: "secondary" },
    { value: "approval", label: "common:statuses.prelim", variant: "prelim" },
    { value: "draft", label: "common:statuses.draft", variant: "draft" },
  ];
  const canApprove = HasPermCode(codesFor.supplierInvoices.approve);
  return (
    <SupplierInvoiceListPage
      tableId="prelimSupplierInvoices"
      initialMode={allowedModes[1].value}
      allowedModes={allowedModes}
      selectable={canApprove}
    />
  );
}

export default ApproveSupplierInvoiceListPage;
