import React, { useCallback, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedSpecificationDocuments } from "components/ui/documents-refactored";
import * as documentAPI from "api/document";
import { SubmitButton } from "components/ui/buttons";
import { DraggableModalDialog } from "../DraggableModalDialog";

function SpecificationFilesUploadPreviewModal({ handleClose, specification, companyId, uploadHandler, max = 3 }) {
  const { t } = useTranslation("common");
  const [newFiles, setNewFiles] = useState(() => {
    if (specification.documents.length < max) {
      return [...specification.documents.filter((f) => !_.isNumber(f)), { key: _.uniqueId("nd.") }];
    }
    return specification.documents;
  });
  const [savedFiles, setSavedFiles] = useState(specification.documents.filter((f) => _.isNumber(f)));

  const onDocumentRemove = useCallback(
    (event) => {
      const _files = savedFiles.filter((fileId) => fileId !== event.detail.id);
      uploadHandler(_files);
      setSavedFiles(_files);
    },
    [savedFiles, uploadHandler]
  );

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("document/removed", onDocumentRemove);
    return () => document.body.removeEventListener("document/removed", onDocumentRemove);
  }, [onDocumentRemove]);

  const handleSubmit = async () => {
    const _newFiles = newFiles.filter((d) => !!d.file);
    uploadHandler([...savedFiles, ..._newFiles]);
    handleClose();
  };

  return (
    <Modal show backdrop="static" onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("common:actions.upload")} {t("documents")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger">
          {t("common:file.noteFilesOnSave")}
          {savedFiles.length ? (
            <>
              <br />
              {t("common:file.noteFilesOnDelete")}
            </>
          ) : null}
        </Alert>
        <SavedSpecificationDocuments documents={savedFiles} specificationId={specification.id} companyId={companyId} />
        <NewDocuments
          documents={newFiles.filter((f) => !_.isNumber(f))}
          onChange={({ file, index }) =>
            documentAPI.onNewFileChange({
              file,
              index,
              allFiles: newFiles,
              setFile: setNewFiles,
              max: max - savedFiles.length,
            })
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <SubmitButton isSubmitting={false} onClick={handleSubmit} />
      </Modal.Footer>
    </Modal>
  );
}

export default SpecificationFilesUploadPreviewModal;
