import React from "react";
import { Card } from "react-bootstrap";
import { endOfMonth, startOfQuarter } from "date-fns";
import { useParams, useOutletContext } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { SelectionProvider } from "state/providers/SelectionProvider";
import { TimeReportTable } from "components/tables";
import { HasPermCode } from "components/perms";
import { codesForTimeReport } from "components/perms/PermCodes";
import * as tableAPI from "api/table";
import ApproveAction from "./ApproveAction";

function TimeReportListPage({ initialMode, selectable = true, tableId = "timeReports" }) {
  const company = useOutletContext();
  const { mode } = useParams();
  const startDate = startOfQuarter(new Date());
  const endDate = endOfMonth(new Date());
  const initialFilters = {
    mode: initialMode || mode || "",
    at_date__start: startDate,
    at_date__end: endDate,
  };
  const canApprove = HasPermCode(codesForTimeReport.approve);

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          pageSize={40}
          dataSource={(filters, config) =>
            tableAPI.companyTimeReports(company.id, filters, config).then((response) => {
              response.data.forEach((item) => {
                if (item.status === 1) {
                  item.notSelectable = true;
                }
              });
              return response;
            })
          }
          initialFilters={initialFilters}
        >
          <SelectionProvider>
            <TimeReportTable companyId={company.id} selectable={canApprove} />
            {canApprove && <ApproveAction companyId={company.id} />}
          </SelectionProvider>
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default TimeReportListPage;
