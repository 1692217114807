import "react-checkbox-tree/lib/react-checkbox-tree.css";
import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import * as companyAPI from "api/company";
import useAsync from "hooks/useAsync";
import { SubmitButton } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { handleActionErrors } from "api/errors";
import { Loader } from "components/ui/loaders";
import CompanyConsultPermDetailedTable from "./CompanyConsultPermDetailedTable";

function ConsultPermissionsForm({ companyData, consultId, permissions, permsChecked, setPermsChecked, t }) {
  const { companyId, companyName } = companyData;
  const { data: rowData, toggleExpand } = useTable();
  const formikProps = {
    initialValues: { permissions_options: permissions },
    onSubmit: async () => {
      return companyAPI.consultPermissions
        .update(companyId, consultId, { checked: permsChecked })
        .then(() => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          // workaround for refresh row with keeping it open (close and reopen)
          const rowIndex = rowData.findIndex((x) => x.expanded === true);
          toggleExpand(rowIndex);
          toggleExpand(rowIndex);
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting }) => {
        return (
          <Form className="permissions-form">
            <CompanyConsultPermDetailedTable
              permsChecked={permsChecked}
              setPermsChecked={setPermsChecked}
              permissions={permissions}
              companyName={companyName}
            />
            <section className="save">
              <SubmitButton icon="" title="actions.saveChanges" isSubmitting={isSubmitting} />
            </section>
          </Form>
        );
      }}
    </Formik>
  );
}

function ConsultPermissionsWrapper({ companyData, consultId }) {
  const { companyId } = companyData;
  const [permsChecked, setPermsChecked] = useState([]);
  const { t } = useTranslation("company");

  const getPermissions = useCallback(
    () =>
      companyAPI.consultPermissions.get(companyId, consultId).then((response) => {
        setPermsChecked(response.data.checked);
        return response.data.tree;
      }),
    [companyId, consultId]
  );
  //

  const [{ data: permissions, loading: loadingPermissions }] = useAsync(getPermissions, []);

  if (loadingPermissions) {
    return <Loader />;
  }

  return ConsultPermissionsForm({
    companyData,
    consultId,
    permissions,
    permsChecked,
    setPermsChecked,
    t,
  });
}

export default ConsultPermissionsWrapper;
