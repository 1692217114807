import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import Actions from "./AuditorActions";

function AuditorTable({ companyId, userPermCodes }) {
  const { t } = useTranslation("company");

  const headers = useMemo(
    () => [
      {
        field: "user_name",
        label: t("common:name"),
        sortField: "user__first_name",
      },
      {
        field: "user_personal_number",
        label: t("common:contact.personalNumber"),
        sortField: "user__personal_number",
      },
      {
        field: "user_email",
        label: t("common:contact.emailLong"),
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      FiltersComponent={null}
      renderActions={(row) => <Actions companyId={companyId} row={row} userPermCodes={userPermCodes} />}
    />
  );
}

export default AuditorTable;
