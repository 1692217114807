import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Alert, Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Form, Formik } from "formik";
import { formatISO, isFirstDayOfMonth } from "date-fns";
import * as yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import cx from "classnames";

import { SubmitButton } from "components/ui/buttons";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedVerificationDocuments } from "components/ui/documents-refactored";
import * as verAPI from "api2/verification";
import * as documentAPI from "api/document";
import { collectionSum } from "utils/list";
import { useTranslation } from "react-i18next";
import { formatMoney, roundMoney } from "utils/money";
import { useVerificationDispatch, useVerificationState } from "hooks/useVerification";
import { useCompanyState } from "hooks/useCompany";
import { enterKeyDownAsTab } from "utils/others";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import { HasPermCode, PermCodeRequired } from "components/perms";
import { codesForInventoryRegister, codesForVerifications } from "components/perms/PermCodes";
import { handleActionErrors } from "api/errors";
import BalanceProvider, { BalanceStateContext } from "state/providers/BalanceProvider";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { formatDatetime, parseDate } from "utils/date";
import { filterPeriodisationCostAccountsForVer } from "utils/periodisation";
import { isAssetToAutoAddBasedOnTransactions } from "utils/assets";
import TableLoader from "components/tables/btable/TableLoader";
import { confirmExecute, confirmRemove } from "../ConfirmModal";
import RelatedInfo from "./RelatedBox";
import { DraggableModalDialog } from "../DraggableModalDialog";
import VerificationForm from "./VerificationForm";
import useAsync from "../../../hooks/useAsync";
import { RocketLoader } from "../../ui/loaders";
import { companyFileArchives } from "../../../api/table";
import useModal from "../../../hooks/useModal";
import { FileArchiveDocumentModal } from "../DocumentsModal";
import { DocumentBox } from "../../ui/documents";

function ArchiveFilesToConnect({ companyId, docIDsAttached, setDocIDsAttached }) {
  const dataSource = useCallback(
    (cancelToken) => companyFileArchives(companyId, { status: "new", page_size: 9999 }, { cancelToken }),
    [companyId]
  );
  const { t } = useTranslation("common");
  const [{ data: documents, loading: loadingProjects }] = useAsync(dataSource, []);
  const [searchText, setSearchText] = useState("");
  const fileModal = useModal();

  const openModal = (archiveId) => {
    fileModal.open(archiveId);
  };
  if (loadingProjects) {
    return <RocketLoader />;
  }
  const onArchiveDocConnectSwitch = (docId) => {
    const _docIDsAttached = [...docIDsAttached];
    if (!_docIDsAttached.includes(docId)) {
      _docIDsAttached.push(docId);
    } else {
      _docIDsAttached.splice(_docIDsAttached.indexOf(docId), 1);
    }
    setDocIDsAttached(_docIDsAttached);
  };
  const isDocChecked = (docId) => {
    return docIDsAttached.includes(docId);
  };
  const search = _.debounce((event) => {
    setSearchText(event.target.value.trim());
  }, 500);
  if (!documents.length) {
    return (
      <Table className="pt-2">
        <tr>
          <td colSpan={3}>{t("common:noResultsFound")}</td>
        </tr>
      </Table>
    );
  }
  return (
    <>
      <div className="search-input-wrapper">
        <input
          placeholder={t("common:search")}
          name="search"
          type="text"
          className="form-control"
          autoComplete="off"
          onChange={search}
        />
      </div>
      <Scrollbars className="body" style={{ height: documents.length < 9 ? documents.length * 50 : "450px" }}>
        <Table>
          {documents
            .filter((doc) => doc.title.toLowerCase().includes(searchText.toLowerCase()))
            .map((doc) => (
              <tr key={doc.id} style={{ background: isDocChecked(doc.id) ? "rgba(52, 140, 212, 0.07)" : "initial" }}>
                <td
                  className="text-right"
                  width="25"
                  onClick={() => {
                    openModal(doc.id);
                  }}
                >
                  {isDocChecked(doc.id) ? (
                    <i className="icon-checked fas fa-check" />
                  ) : (
                    <i className="fas fa-search icon-unchecked" />
                  )}
                </td>
                <td
                  className="text-left float-left pl-0"
                  onClick={() => {
                    onArchiveDocConnectSwitch(doc.id);
                  }}
                >
                  <span className={cx("title ", { checked: isDocChecked(doc.id) })}>{doc.title}</span>{" "}
                  <span className="file-type">{doc.file_type}</span>
                </td>
                <td className="created">{formatDatetime(doc.created)}</td>
              </tr>
            ))}
        </Table>
      </Scrollbars>
      {fileModal.show && (
        <FileArchiveDocumentModal
          companyId={companyId}
          archiveId={fileModal.data}
          handleClose={fileModal.close}
          handleOpen={fileModal.open}
          connectConfig={{ onArchiveDocConnectSwitch, isDocChecked }}
        />
      )}
    </>
  );
}

function VerificationModalWrapper() {
  const { close, open: openVerificationModal } = useVerificationDispatch();
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const { t } = useTranslation("common");
  const { verification } = useVerificationState();
  const { pathname } = useLocation();
  const {
    company: { id: companyId },
    accounts: { byId: accountById, asOptions: accountOptions },
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const isConsult = true;

  const onHide = async (isDirty) => {
    let confirmed = true;
    if (isDirty === true) {
      confirmed = await confirmExecute(t("common:confirm.unsavedData"), "actions.yes");
    }
    if (confirmed) {
      close();
      setTimeout(() => {
        reloadMenuNumbers();
      }, 3000);
    }
  };

  useEffect(() => {
    return () => {
      close();
    };
  }, [pathname, close]);

  if (!verification) {
    return null;
  }
  const initialAccounts = verification.transactions
    .filter((tr) => tr.is_deleted === false && !!tr.account)
    .map((tr) => tr.account.number);
  return (
    <Modal
      animation
      backdrop="static"
      show
      onHide={onHide}
      dialogClassName="verification-modal"
      key={verification.id}
      dialogAs={DraggableModalDialog}
      scrollable
    >
      <BalanceProvider initialAccounts={isConsult ? initialAccounts : []} companyId={companyId}>
        <VerificationModal
          isConsult={isConsult}
          verification={verification}
          closeModal={onHide}
          openModal={openVerificationModal}
          companyId={companyId}
          accountOptions={accountOptions}
          accountById={accountById}
          centerOptions={centerOptions}
          projectOptions={projectOptions}
        />
      </BalanceProvider>
    </Modal>
  );
}

function VerificationModal({
  verification,
  closeModal,
  openModal,
  companyId,
  isConsult,
  accountOptions,
  accountById,
  projectOptions,
  centerOptions,
}) {
  const { company } = useCompanyState();
  const [newFiles, setNewFiles] = useState(verification.newDocuments || [{ key: _.uniqueId("nd.") }]);
  const [savedDocuments, setSavedDocuments] = useState(verification.documents);
  // const [toAttachArchiveDocuments, setToAttachArchiveDocuments] = useState([]);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const { t } = useTranslation(["ver", "common", "ci"]);
  const isDeleted = verification.status === 2;
  const isManualVerification = verification.series === "M";
  const { checkYears } = useContext(FinancialYearDispatchContext);
  const hasPermForInventoryRead = HasPermCode(codesForInventoryRegister.enabled);
  const { pathname } = useLocation();
  const { fetchBalance } = useContext(BalanceStateContext);
  const [docIDsAttached, setDocIDsAttached] = useState(verification.archive ? [verification.archive] : []);
  const [showAttach, setShowAttach] = useState(docIDsAttached.length >= 1);

  const [isRescanAvailable, setIsRescanAvailable] = useState(false);
  const [isReceiptScanning, setIsReceiptScanning] = useState(false);
  const [isReinitialized, setIsReinitialized] = useState(false);

  const [scannedReceipt, setScannedReceiptReceipt] = useState(null);

  const [scannedFileId, setScannedFileId] = useState("");

  const confirmMessageOnEmptyVer = useMemo(() => t("confirm.wantToSendForInterpretation", { ns: "ci" }), [t]);

  const confirmMessageOnNonEmptyVer = useMemo(
    () => `
	${t("confirm.wantToSendForInterpretation", { ns: "ci" })} 
	${t("overwriteDataOnConfirm", { ns: "ci" })}`,
    [t]
  );

  const confirmMessageOnOldVerExiting = useMemo(
    () => `
	${t("confirm.wantToSendForInterpretation", { ns: "ci" })} 
	${t("confirm.appendNewDataOnConfirm", { ns: "ci" })} 
	${t("confirm.crossOverUnusedData", { ns: "ci" })}`,
    [t]
  );

  const initialReceipt = {
    ...verification,
    title: verification.title || "",
    transactions: verification.transactions.map((tran) => ({
      ...tran,
      credit: tran.credit || "",
      debit: tran.debit || "",
      uid: tran.id || _.uniqueId("tran"),
      periodisation_config: !tran.periodisation_config
        ? null
        : {
            ...tran.periodisation_config,
            date_start: parseDate(tran.periodisation_config.date_start),
            show: false,
            enabled: true,
            status: tran.periodisation_config.status,
          },
    })),
  };

  function async_delay(ts, val) {
    return new Promise((resolve) => {
      setTimeout(resolve, ts, val);
    });
  }

  const saveVer = (formData, setErrors) => {
    return verAPI
      .save(companyId, formData)
      .catch(async (error) => {
        if (error.data.__all__ === "vat_diff_error") {
          const answer = await confirmExecute(t("confirm.diffTransactionVerifications"));
          if (answer) {
            await saveVer({ ...formData, allow_incorrect_vat_deviation: true }, setErrors);
          }
          return;
        }
        if (error.status !== 403) {
          toast.error(t("msg:fixErrors"));
        }
        if (error.data.__all__) {
          error.data.transactions = error.data.__all__;
          delete error.data.__all__;
        }
        setErrors(error.data);
      })
      .then(async (response) => {
        if (!response) {
          return;
        }
        // used only in create as on update file is uploaded instantly on selection
        const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
        if (_newFiles.length) {
          await verAPI.document.uploadMany(companyId, response.data.id, _newFiles, scannedFileId).catch(() => {
            // pass silently
          });
          if (response.data.new_assets) {
            const assetPromises = [];
            for (let i = 0; i < response.data.new_assets.length; i++) {
              assetPromises.push(
                documentAPI.uploadAsset(companyId, response.data.new_assets[i], _newFiles).catch(() => {
                  // pass silently
                })
              );
            }
            await Promise.all(assetPromises);
          }
        }
        toast.success(t("msg:saved"));
        closeModal();
        if (
          hasPermForInventoryRead &&
          isAssetToAutoAddBasedOnTransactions(formData.transactions.filter((tran) => !tran.id))
        ) {
          window.location.href = `${
            pathname.includes("consult") ? "/consult" : ""
          }/inventory-register?draftsForVer=ver. ${response.data.series}${response.data.series_number}`;
        } else {
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { ...formData, ...response.data },
            })
          );
        }
        if (docIDsAttached) {
          async_delay(8000).then(function () {
            return reloadMenuNumbers();
          });
        }
      });
  };
  const formikProps = {
    initialValues: scannedReceipt ?? initialReceipt,
    validationSchema: yup.object().shape({
      booking_date: yup
        .date()
        .nullable()
        .required()
        .test("error", function (value) {
          const { createError } = this;
          if (company.lock_accounting_date && value <= new Date(company.lock_accounting_date)) {
            return createError({
              message: t("errors.bookingInLockedDate", { lockAccountingDate: company.lock_accounting_date }),
            });
          }
          return true;
        }),
      title: yup.string().required(),
      transactions: yup
        .array()
        .ensure()
        .of(
          yup
            .object()
            .shape({
              credit: yup.number().positive(t("errors.creditPositive")).nullable(),
              debit: yup.number().nullable().positive(t("errors.debitPositive")),
              is_deleted: yup.bool(),
            })
            .test("error", function (transaction) {
              const { account, cost_center, project, debit, credit } = transaction;
              if (account && !debit && !credit) {
                return this.createError({
                  path: `${this.path}.error`,
                  message: t("errors.transactionNoDebitAndCredit"),
                });
              }
              if (transaction.is_deleted === true) {
                return true;
              }
              if (account) {
                if (account.project_option === "mandatory" && !project) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.projectMustBeSet"),
                  });
                }
                if (account.cost_center_option === "mandatory" && !cost_center) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.centerMustBeSet"),
                  });
                }
              }
              if (transaction.periodisation_config?.status === "creating") {
                if (!transaction.periodisation_config.date_start) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.periodisationDateStartMustBeSet"),
                  });
                }
                if (!transaction.periodisation_config.months) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.periodisationMonthsMustBeSet"),
                  });
                }
                if (transaction.periodisation_config.date_start) {
                  const today = new Date();
                  const earielstPeriodisationDateStartNew = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                  if (earielstPeriodisationDateStartNew > transaction.periodisation_config.date_start) {
                    // earliest day is first day of last month
                    return this.createError({
                      path: `${this.path}.error`,
                      message: t("ver:errors.periodisationStartOnToEarly"),
                    });
                  }
                }
                if (!isFirstDayOfMonth(transaction.periodisation_config.date_start)) {
                  // earliest day is first day of last month
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("ver:errors.periodisationStartOnNotFirstDay"),
                  });
                }
                if (!transaction.periodisation_config.pl_account) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.periodisationPlAccountMustBeSet"),
                  });
                }
                if (
                  transaction.periodisation_config.date_start &&
                  transaction.periodisation_config.months &&
                  transaction.project &&
                  transaction.project.date_end
                ) {
                  const finishDateOfPeriodisation = new Date(transaction.periodisation_config.date_start.getTime());
                  const endMonth =
                    parseInt(finishDateOfPeriodisation.getMonth(), 10) +
                    parseInt(transaction.periodisation_config.months, 10) -
                    1;
                  finishDateOfPeriodisation.setMonth(endMonth);
                  const projectEndDate = new Date(transaction.project.date_end);
                  if (finishDateOfPeriodisation > projectEndDate) {
                    return this.createError({
                      path: `${this.path}.error`,
                      message: t("ver:errors.periodisationFinishAfterProjectEnds"),
                    });
                  }
                }
              }
              return true;
            })
        )
        .test("min2", function (values) {
          const { path, createError } = this;
          const validTrans = values.filter((tr) => !!tr.account);
          if (validTrans.length < 2) {
            return createError({
              path,
              message: t("errors.min2transactions"),
            });
          }
          return true;
        })
        .test("diffEqual", function (values) {
          const { path, createError } = this;
          const trans = values.filter((tr) => !tr.is_deleted);
          const debit = roundMoney(collectionSum(trans, "debit"));
          const credit = roundMoney(collectionSum(trans, "credit"));
          const diff = roundMoney(debit - credit);
          if (diff !== 0) {
            return createError({
              path,
              message: t("errors.transactionDoesNotBalance", {
                diff: formatMoney(diff),
              }),
            });
          }
          return true;
        }),
    }),

    onSubmit: async (values, { setErrors }) => {
      const formData = {
        ...verification,
        ...values,
        title: values?.title?.trim?.() ?? values?.title ?? "",
        booking_date: formatISO(values.booking_date, {
          representation: "date",
        }),
        archives_to_connect: docIDsAttached,
        newDocuments: undefined,
        allow_incorrect_vat_deviation: false,
        transactions: values.transactions
          .filter((tr) => !!tr.account)
          .map((transaction) => ({
            is_deleted: transaction.is_deleted,
            account: transaction.account.value,
            project: transaction.project ? transaction.project.value : null,
            cost_center: transaction.cost_center ? transaction.cost_center.value : null,
            amount: transaction.credit ? -transaction.credit : transaction.debit,
            title: transaction.title,
            id: transaction.id || undefined,
            sie_file_name: transaction.sie_file_name,
            periodisation_config: !transaction.periodisation_config
              ? null
              : {
                  ...transaction.periodisation_config,
                  date_start: formatISO(transaction.periodisation_config.date_start, {
                    representation: "date",
                  }),
                  pl_account_id: transaction.periodisation_config.pl_account.value,
                  status:
                    transaction.periodisation_config.status === "creating"
                      ? "not_finished"
                      : transaction.periodisation_config.status,
                },
          })),
      };
      const executeAnyway = await checkYears(values.booking_date);
      if (!executeAnyway) {
        return null;
      }
      return saveVer(formData, setErrors);
    },
  };

  const onDeleteNoTrace = async () => {
    const answer = await confirmRemove(
      t("confirm.removeNoTrace", {
        invoiceNo: `${verification.series}${verification.series_number}`,
      })
    );
    if (answer) {
      verAPI
        .removeLastSeries(companyId, verification.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reloadMenuNumbers();
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { ...verification },
            })
          );
          document.body.dispatchEvent(
            new CustomEvent("verification/deleted", {
              detail: { ...verification },
            })
          );
          closeModal();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
  };

  const onRemoveVer = async () => {
    const answer = await confirmRemove(
      t(verification.has_assets ? "confirm.removeWithAssetInfo" : "confirm.remove", {
        invoiceNo: `${verification.series}${verification.series_number}`,
      })
    );
    if (answer) {
      return verAPI
        .remove(companyId, verification.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reloadMenuNumbers();
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { ...verification },
            })
          );
          document.body.dispatchEvent(
            new CustomEvent("verification/deleted", {
              detail: { ...verification },
            })
          );
          closeModal();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
    return null;
  };

  const handleConfirmRescan = useCallback(
    async (file, verName) => {
      try {
        setIsReceiptScanning(true);

        const response = await documentAPI.rescanReceipt(companyId, file);

        setScannedFileId(response.data.file_id);

        const transactions = response.data.transactions.map((item) => {
          fetchBalance(item.account);

          return {
            ...item,
            account: accountOptions.find((a) => a.number === item.account),
            debit: item.amount > 0 ? item.amount : null,
            credit: item.amount < 0 ? Math.abs(item.amount) : null,
          };
        });

        const data = {
          ...verification,
          ...response.data,
          title: response.data.title || verName,
          transactions: [...[...verification.transactions].slice(0, -1), ...transactions],
        };

        setScannedReceiptReceipt(() => {
          setIsReinitialized(true);

          return data;
        });
      } catch (error) {
        // pass silently
      } finally {
        setIsReinitialized(false);
        setIsReceiptScanning(false);
      }
    },
    [accountOptions, companyId, fetchBalance, verification]
  );

  const handleNewDocumentChange = async (verId, file, index, isFormDirty) => {
    setIsReinitialized(false);
    if (verId) {
      // if ver. is already created then auto upload, no need to press save btn
      // remove from NewDocuments
      documentAPI.onNewFileChange({
        file: null,
        index,
        allFiles: newFiles,
        setFile: setNewFiles,
      });
      verAPI.document
        .uploadMany(companyId, verId, file)
        .then((response) => {
          setSavedDocuments((docs) => [...docs, response.data.documents[0]]);
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { id: verId, documents: savedDocuments },
            })
          );
        })
        .catch(() => {
          // pass silently
        });
    } else {
      documentAPI.onNewFileChange({
        file,
        index,
        allFiles: newFiles,
        setFile: setNewFiles,
      });
    }

    if (isManualVerification && file && !isFormDirty && !verification.id) {
      const answer = await confirmExecute(confirmMessageOnEmptyVer);

      if (answer) {
        handleConfirmRescan(file[0]);
      }
    }
  };

  useEffect(() => {
    const isNewFileExist = newFiles.some((item) => !!item?.file);
    const isSavedDocumentsExist = !!savedDocuments.length;

    setIsRescanAvailable(isNewFileExist || isSavedDocumentsExist);
  }, [newFiles, savedDocuments]);

  const setFormDirty = (setFieldValue, fieldValue) => {
    const modifiedValue = fieldValue?.length === fieldValue.trim()?.length ? `${fieldValue} ` : fieldValue.trim();

    setFieldValue("title", modifiedValue);
  };

  const handleRescanReceipt = async (isFormDirty, verName, setFieldValue) => {
    let answer = false;

    if (isFormDirty && !verification.id) {
      answer = await confirmExecute(confirmMessageOnNonEmptyVer);
    } else if (!isFormDirty && !verification.id) {
      answer = await confirmExecute(confirmMessageOnEmptyVer);
    } else if (verification.id) {
      answer = await confirmExecute(confirmMessageOnOldVerExiting);
    }

    if (answer) {
      let file = null;

      if (savedDocuments.length) {
        const receipt = await documentAPI.fetchReceipt(companyId, savedDocuments[0]);

        file = new File([receipt.url], receipt.fileName, { type: receipt.type });
      } else {
        file = newFiles[0].file;
      }

      await handleConfirmRescan(file, verName);

      setFormDirty(setFieldValue, verName);
    }
  };

  const accountsForPeriodisationCostAccount = useMemo(() => {
    return accountOptions.filter(filterPeriodisationCostAccountsForVer);
  }, [accountOptions]);
  const disconnectDoc = (docId) => {
    setDocIDsAttached([...docIDsAttached.filter((eachDocId) => docId !== eachDocId)]);
  };
  return (
    <Formik {...formikProps} key={verification.id} enableReinitialize={isReinitialized}>
      {({ values, errors, isSubmitting, dirty, setFieldValue }) => {
        return (
          <Form noValidate onKeyDown={enterKeyDownAsTab}>
            {isReceiptScanning && <TableLoader />}
            <Scrollbars autoHide={false} thumbMinSize={100}>
              <Row noGutters className="verification-container">
                <Col md={8} className="verification-details">
                  <Modal.Header className="bg-primary">
                    <h5 className="text-white">
                      <VerificationTitle
                        t={t}
                        preliminary={verification.preliminary}
                        series={verification.series}
                        series_number={verification.series_number}
                      />
                    </h5>
                  </Modal.Header>
                  <Modal.Body>
                    <RelatedInfo
                      related={verification.related}
                      current={verification}
                      onSelect={(item) => openModal(companyId, item)}
                      sie_file_name={verification.sie_file_name}
                    />
                    <fieldset disabled={isDeleted}>
                      <VerificationForm
                        isConsult={isConsult}
                        accountOptions={accountOptions}
                        perCostAccountOptions={accountsForPeriodisationCostAccount}
                        accountById={accountById}
                        projectOptions={projectOptions}
                        centerOptions={centerOptions}
                        series={verification ? verification.series : "M"}
                        isDeleted={isDeleted}
                        verificationId={verification ? verification.id : null}
                        verificationSeries={verification.series}
                        companyId={companyId}
                      />
                    </fieldset>
                    {verification?.has_assets && <Alert variant="info">{t("verificationIsContainingAssetsMsg")}</Alert>}
                    {isManualVerification && (
                      <Row>
                        <Col>
                          <Button
                            variant="outline-primary"
                            onClick={() => handleRescanReceipt(dirty, values.title, setFieldValue)}
                            disabled={!isRescanAvailable}
                          >
                            {t("actions.scanDocument", { ns: "common" })}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Modal.Body>
                </Col>
                <Col md={4} className="verification-files">
                  <Modal.Header className="bg-primary">
                    <h5 className="text-white">{t("verificationFiles")}</h5>
                    <button
                      type="button"
                      className="btn btn-secondary float-right"
                      onClick={() => closeModal(dirty || docIDsAttached.length >= 1)}
                    >
                      <i className="mdi mdi-close" />
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <SavedVerificationDocuments
                      verificationId={verification.id}
                      companyId={companyId}
                      documents={savedDocuments}
                    />
                    <Card className="file-archive-attachments">
                      <fieldset disabled={isDeleted}>
                        <div className="control show-hide" onClick={() => setShowAttach(!showAttach)}>
                          {showAttach ? <i className="fe-chevron-up" /> : <i className="fe-chevron-down" />}
                          {t("actions.showAttachFileArchive")}
                        </div>
                        {showAttach && (
                          <div>
                            <ArchiveFilesToConnect
                              companyId={companyId}
                              docIDsAttached={docIDsAttached}
                              setDocIDsAttached={setDocIDsAttached}
                            />
                          </div>
                        )}
                      </fieldset>
                    </Card>
                    <>
                      {docIDsAttached.map((docId) => (
                        <DocumentBox
                          key={`sd.${docId}`}
                          partUrl="archive-documents"
                          companyId={companyId}
                          documentId={docId}
                          removable={false}
                          extraActionData={{
                            divClassName: "remove",
                            actionName: t("common:actions.disconnect").toLowerCase(),
                            onAction: disconnectDoc,
                            documentId: docId,
                          }}
                        />
                      ))}
                    </>
                    <NewDocuments
                      documents={newFiles}
                      multiple
                      onChange={({ file, index }) => handleNewDocumentChange(values.id, file, index)}
                    />
                  </Modal.Body>
                </Col>
              </Row>
            </Scrollbars>
            <Footer
              t={t}
              isDeleted={verification.status === 2}
              canBeDeletedForever={verification.can_be_deleted_forever || false}
              onDeleteNoTrace={onDeleteNoTrace}
              onRemoveVer={onRemoveVer}
              closeModal={() => closeModal(dirty)}
              companyId={companyId}
              formProps={{ isSubmitting, values, errors }}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

function Footer({ isDeleted, formProps, closeModal, companyId, canBeDeletedForever, onDeleteNoTrace, onRemoveVer, t }) {
  const { duplicate } = useVerificationDispatch();
  const isRemovable = formProps.values.id && formProps.values.series && formProps.values.series !== "C";
  const onDuplicate = async () => {
    await duplicate(companyId, formProps.values);
  };

  return (
    <Modal.Footer className="space" style={{ justifyContent: "center" }}>
      <div className="space-1">
        <Button variant="second" onClick={closeModal}>
          <i className="fas fa-arrow-left" /> {t("common:actions.cancel")}
        </Button>
        {isDeleted ? (
          <p className="font-20 mb-0 text-danger text-uppercase">{t("common:deleted")}</p>
        ) : (
          <PermCodeRequired code={codesForVerifications.manage}>
            {formProps.values.id && formProps.values.status === 1 && (
              <Button variant="second" onClick={onDuplicate}>
                {t("common:actions.duplicate")}
              </Button>
            )}
            {isRemovable && (
              <Button variant="danger" onClick={onRemoveVer}>
                <i className="fas fa-trash" /> {t("common:actions.removeVer")}
              </Button>
            )}
            {canBeDeletedForever && isRemovable && (
              <Button variant="danger" onClick={onDeleteNoTrace}>
                <i className="fas fa-exclamation" /> {t("common:actions.deleteNoTrace")}
              </Button>
            )}
            <SubmitButton isSubmitting={formProps.isSubmitting} />
          </PermCodeRequired>
        )}
      </div>
    </Modal.Footer>
  );
}

function VerificationTitle({ t, preliminary, series, series_number }) {
  return (
    <>
      {t("verificationDetails")}
      <strong>
        {" "}
        {series}
        {series_number}
      </strong>
      {preliminary && <small> - {t("preliminary")}</small>}
    </>
  );
}

export default VerificationModalWrapper;
