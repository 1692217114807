import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { OFFICE_PERMISSION_ROLES, OFFICE_PERMISSION_MODULES } from "utils/permissions/officePermissions";
import { CHECKBOX_CLASSES } from "utils/permissions/constants";
import { handleGlobalCheckboxChange } from "utils/permissions/helpers";
import { PERMISSION_MODULE_TYPE } from "./models";

const { checked, partiallyChecked, allowed } = CHECKBOX_CLASSES;

function GlobalCheckboxes({ permsChecked, setPermsChecked }) {
  const { t } = useTranslation(["common"]);

  return OFFICE_PERMISSION_ROLES.map((role) => {
    const allCodes = OFFICE_PERMISSION_MODULES.reduce((result, currentPerm) => {
      const childrenMappedToRole = currentPerm.children
        .filter((child) => child.role === role)
        .map((child) => child.code);
      return [...result, ...childrenMappedToRole];
    }, []);

    const enabledCodes = allCodes.filter((code) => permsChecked.includes(code));
    const isSomethingChecked = !!enabledCodes.length;

    const forAllChecked = allCodes.length === enabledCodes.length && isSomethingChecked ? checked : "";

    const forPartiallyChecked = isSomethingChecked ? partiallyChecked : "";

    const styles = forAllChecked || forPartiallyChecked || allowed;

    return (
      <th key={role}>
        <div>
          <div className="label">{t(role)}</div>
          <div
            onClick={() => handleGlobalCheckboxChange(setPermsChecked, isSomethingChecked, allCodes, enabledCodes)}
            className={cx("checkbox-wrapper", styles)}
          >
            <button type="button" className="checkbox" />
          </div>
        </div>
      </th>
    );
  });
}

GlobalCheckboxes.propTypes = {
  permissionModules: PropTypes.arrayOf(PERMISSION_MODULE_TYPE),
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
};

export default GlobalCheckboxes;
