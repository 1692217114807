import React from "react";
import _ from "lodash";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { SubmitButton } from "components/ui/buttons";
import { formatMoney } from "utils/money";
import { FormGroup, TableGroup } from "components/formik";
import { autoFillRotRut, calculateRecordRotRutTotal } from "utils/calc/ci";
import cx from "classnames";
import PersonalNumber from "personnummer";
import rotRutTypes, { rotRutByValue } from "utils/calc/rotRutTypes";
import { DraggableModalDialog } from "../DraggableModalDialog";

function parseItemValue(record, key) {
  if (!record[key]) {
    return 0;
  }
  if (_.isObject(record[key])) {
    return record[key].Value / 100;
  }
  return record[key];
}

function RotRutModal({ currency, companyId, customer, records, rotRutDetails, onSave, onCancel, previewOnly = false }) {
  const { t } = useTranslation("ci");
  const initialPropertyDesignation = (() => {
    if (previewOnly) {
      return rotRutDetails.PropertyDesignation || "";
    }
    if (rotRutDetails.PropertyDesignation === undefined) {
      return customer?.Notes?.PropertyDesignation || "";
    }
    return rotRutDetails.PropertyDesignation || "";
  })();
  const initialResidenceAssociationOrgNo = (() => {
    if (previewOnly) {
      return rotRutDetails.ResidenceAssociationOrgNo || "";
    }
    if (rotRutDetails.ResidenceAssociationOrgNo === undefined) {
      return customer?.Notes?.ResidenceAssociationOrgNo || "";
    }
    return rotRutDetails.ResidenceAssociationOrgNo || "";
  })();
  const formikProps = {
    initialValues: {
      RotRutDetails: {
        ...rotRutDetails,
        PropertyDesignation: initialPropertyDesignation,
        ResidenceAssociationOrgNo: initialResidenceAssociationOrgNo,
      },
      records: records.map((record) => ({
        ...record,
        RotRutAmount: record.RotRutAmount || 0,
        RotRutActivated: record.RotRutActivated || false,
        RotRutHours: record.RotRutHours || 0,
        RotRutMaterialCostAmount: parseItemValue(record, "RotRutMaterialCostAmount") || 0,
        RotRutType: record.RotRutType ? rotRutByValue(record.RotRutType) : rotRutTypes[0].options[0],
      })),
    },
    validationSchema: yup
      .object()
      .shape({
        RotRutDetails: yup.object().shape({
          PropertyDesignation: yup.string(),
          ResidenceAssociationOrgNo: yup.string(),
          Customers: yup.array().of(
            yup.object().shape({
              SSN: yup
                .string()
                .required(t("common:errors.personalNumberRequired"))
                .test("ssnValid", function (ssn2) {
                  if (!PersonalNumber.valid(ssn2)) {
                    return this.createError({
                      path: this.path,
                      message: t("common:errors.invalidPersonalNumber"),
                    });
                  }
                  return true;
                }),
              AskedAmount: yup.number().required(t("errors.deductionRequired")),
            })
          ),
        }),
        records: yup.array().test("rotRutType", function (records2) {
          let type = "";
          for (let i = 0; i < records2.length; i++) {
            if (records2[i].RotRutActivated) {
              if (type && records2[i].RotRutType.type !== type) {
                // eslint-disable-next-line react/no-this-in-sfc
                return this.createError({
                  path: "__all__",
                  message: t("errors.ROTorRUT"),
                });
              }
              type = records2[i].RotRutType.type;
            }
          }
          return true;
        }),
      })
      // eslint-disable-next-line func-names
      .test("rotExtra", function (values) {
        const activeRecords = values.records.filter((record) => record.RotRutActivated === true);
        const isROT = activeRecords.length && activeRecords[0].RotRutType.type === "ROT";
        if (isROT && !values.RotRutDetails.PropertyDesignation) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            path: "RotRutDetails.PropertyDesignation",
            message: t("common:errors.required"),
          });
        }
        return true;
      }),
    onSubmit: async (values) => {
      let tmp;
      onSave({
        ...values,
        records: values.records.map((record) => {
          // record.RotRutAmount = calculateRecordRotRutTotal(record);
          tmp = { ...record, RotRutType: record.RotRutType.value };
          delete tmp.index;
          return tmp;
        }),
      });
    },
  };

  function autoFill(values, setValues) {
    const newRotRut = autoFillRotRut(values);
    setValues(newRotRut);
  }

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onCancel}
      dialogClassName="rot-rut-modal"
      size="xl"
      dialogAs={DraggableModalDialog}
    >
      <Formik {...formikProps}>
        {({ values, errors, setValues, isValid, setFieldValue }) => {
          const serviceRecords = values.records
            .filter((item) => item.ProductType === "Service")
            .map((item) => ({
              ...item,
              availableDeduction: calculateRecordRotRutTotal(item),
            }));
          const toDeduct = serviceRecords.reduce((total, record) => total + record.RotRutAmount, 0);
          const leftDeduct =
            toDeduct - values.RotRutDetails.Customers.reduce((total, c) => total + c.AskedAmount || 0, 0);
          const activeRecords = serviceRecords.filter((record) => record.RotRutActivated === true);
          const isROT = activeRecords.length && activeRecords[0].RotRutType.type === "ROT";
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title className="m-0">{t("rotRutDeduction")}</Modal.Title>
              </Modal.Header>
              <fieldset disabled={previewOnly}>
                <Modal.Body className="p-0" style={{ maxHeight: "60vh", overflowX: "hidden" }}>
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>{t("rotRutEnabled")}</th>
                        <th style={{ width: 200 }}>{t("rotRut")}</th>
                        <th style={{ width: 150 }} className="text-right">
                          {t("hours")}
                        </th>
                        <th style={{ width: 150 }} className="text-right">
                          {t("materialCost")}
                        </th>
                        <th style={{ width: 180 }} className="text-right">
                          {t("availableDeduction")}
                        </th>
                        <th style={{ width: 150 }} className="text-right">
                          {t("usedDeduction")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray name="records">
                        <>
                          {serviceRecords.map((record) => (
                            <React.Fragment key={record.key}>
                              <tr>
                                <td>
                                  <FormGroup.Checkbox
                                    name={`records[${record.index}].RotRutActivated`}
                                    label={record.ArticleDescription || t("common:product")}
                                  />
                                </td>
                                <TableGroup.SimpleSelect
                                  name={`records[${record.index}].RotRutType`}
                                  options={rotRutTypes}
                                  getOptionLabel={(option) => t(`options:rotRut.${option.label}`)}
                                  isDisabled={!record.RotRutActivated}
                                />
                                <TableGroup.NumberInput
                                  className="text-right"
                                  name={`records[${record.index}].RotRutHours`}
                                  disabled={!record.RotRutActivated}
                                />
                                <TableGroup.MoneyInput
                                  name={`records[${record.index}].RotRutMaterialCostAmount`}
                                  disabled={!record.RotRutActivated}
                                />
                                <th
                                  className="disabled text-right align-middle"
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                  {formatMoney(record.availableDeduction)}
                                </th>
                                <TableGroup.MoneyInput
                                  name={`records[${record.index}].RotRutAmount`}
                                  disabled={!record.RotRutActivated}
                                />
                              </tr>
                            </React.Fragment>
                          ))}
                          {!serviceRecords.length && (
                            <tr>
                              <td colSpan={6} className="p-2">
                                <span>{t("common:noResultsFound")}</span>
                              </td>
                            </tr>
                          )}
                        </>
                      </FieldArray>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={5} className={cx({ "has-errors": errors.__all__ })}>
                          {errors.__all__}
                        </td>
                        <td className="text-right">
                          <span>
                            {formatMoney(toDeduct)} {currency}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <>
                    {isROT ? (
                      <>
                        <Row>
                          <Col lg={5} sm={12} className="ml-2">
                            <FormGroup.Input
                              name="RotRutDetails.PropertyDesignation"
                              required
                              label={t("propertyDesignation")}
                            />
                          </Col>
                          {!previewOnly &&
                            !!customer.Notes &&
                            values.RotRutDetails.PropertyDesignation !== customer.Notes.PropertyDesignation && (
                              <Col lg={5} sm={12}>
                                <Button
                                  type="button"
                                  variant="warning"
                                  className="mt-3"
                                  onClick={() => {
                                    setFieldValue(
                                      "RotRutDetails.updateCustomerPropertyDesignation",
                                      !values.RotRutDetails.updateCustomerPropertyDesignation
                                    );
                                  }}
                                >
                                  {values.RotRutDetails.updateCustomerPropertyDesignation ? (
                                    <i className="fas fa-check-square mr-1" />
                                  ) : (
                                    <i className="fas fa-square mr-1" />
                                  )}
                                  {t("updateCustomerWithNewData")}
                                </Button>
                              </Col>
                            )}
                        </Row>
                        <Row>
                          <Col lg={5} sm={12} className="ml-2">
                            <FormGroup.Input name="RotRutDetails.ResidenceAssociationOrgNo" label={t("residenceNo")} />
                          </Col>
                          {!previewOnly &&
                            !!customer.Notes &&
                            values.RotRutDetails.ResidenceAssociationOrgNo !==
                              customer.Notes.ResidenceAssociationOrgNo && (
                              <Col lg={5} sm={12}>
                                <Button
                                  type="button"
                                  variant="warning"
                                  className="mt-3"
                                  onClick={() => {
                                    setFieldValue(
                                      "RotRutDetails.updateCustomerResidenceAssociationOrgNo",
                                      !values.RotRutDetails.updateCustomerResidenceAssociationOrgNo
                                    );
                                  }}
                                >
                                  {values.RotRutDetails.updateCustomerResidenceAssociationOrgNo ? (
                                    <i className="fas fa-check-square mr-1" />
                                  ) : (
                                    <i className="fas fa-square mr-1" />
                                  )}
                                  {t("Update customer with new value")}
                                </Button>
                              </Col>
                            )}
                        </Row>
                      </>
                    ) : null}
                    <p className="p-0 m-0 ml-2">{t("howShouldDeduct")}</p>
                    <FieldArray name="RotRutDetails.Customers">
                      {({ remove, push }) => (
                        <Table bordered className="border-bottom-0">
                          <thead>
                            <tr>
                              <th>{t("common:name")}</th>
                              <th>{t("common:contact.personalNumber")}*</th>
                              <th style={{ width: 200 }} className="text-right">
                                {t("deduction")}*
                              </th>
                              <th style={{ width: 50 }} />
                            </tr>
                          </thead>
                          <tbody>
                            {values.RotRutDetails.Customers.map((c, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <TableGroup.Input name={`RotRutDetails.Customers[${index}].Name`} />
                                  <TableGroup.Input name={`RotRutDetails.Customers[${index}].SSN`} />
                                  <TableGroup.MoneyInput name={`RotRutDetails.Customers[${index}].AskedAmount`} />
                                  <td className="text-center">
                                    {!previewOnly && (
                                      <Button variant="outline-danger" size="sm" onClick={() => remove(index)}>
                                        <i className="fas fa-trash" />
                                      </Button>
                                    )}
                                  </td>
                                </tr>
                                <TableGroup.RowErrors
                                  errors={
                                    errors.RotRutDetails &&
                                    errors.RotRutDetails.Customers &&
                                    errors.RotRutDetails.Customers[index]
                                  }
                                />
                              </React.Fragment>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td className="border-0">
                                {!previewOnly && (
                                  <Button
                                    variant="outline-success"
                                    size="sm"
                                    onClick={() => {
                                      push({
                                        AskedAmount: leftDeduct || 0,
                                        SSN: !values.RotRutDetails.Customers.length ? customer.OrgNo || "" : "",
                                        Name: !values.RotRutDetails.Customers.length ? customer.Name : "",
                                      });
                                    }}
                                  >
                                    <i className="fas fa-plus" /> {t("actions.addMember")}
                                  </Button>
                                )}
                              </td>
                              <td className="border-0" />
                              <td className="text-right text-danger">
                                {t("leftDeduct")} {formatMoney(leftDeduct)} {currency}
                              </td>
                              <td className="border-0" />
                            </tr>
                          </tfoot>
                        </Table>
                      )}
                    </FieldArray>
                  </>
                </Modal.Body>
              </fieldset>
              <Modal.Footer className="space">
                <Button type="button" variant="secondary" onClick={onCancel}>
                  {t("common:actions.cancel")}
                </Button>
                {!previewOnly && (
                  <>
                    <Button type="button" variant="primary" onClick={() => autoFill(values, setValues)}>
                      {t("common:actions.autoFill")}
                    </Button>
                    <SubmitButton disabled={!isValid || leftDeduct !== 0} />
                  </>
                )}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RotRutModal;
