import React, { useCallback, useMemo, useState } from "react";

const INITIAL_STATE = {};

const FiltersContext = React.createContext({
  filters: {},
  setFilters: () => {},
});

function FiltersProvider({ initial, options, children }) {
  const [filters, setFilters] = useState(() => initial || INITIAL_STATE);

  const updateFilter = useCallback(
    (newFilter) => {
      setFilters((state) => ({ ...state, ...newFilter }));
    },
    [setFilters]
  );

  function clearSelection() {
    setFilters({});
  }

  const stateValue = useMemo(
    () => ({
      options,
      filters,
      updateFilter,
      clearSelection,
    }),
    [filters, options, updateFilter]
  );

  return <FiltersContext.Provider value={stateValue}>{children}</FiltersContext.Provider>;
}

export { FiltersContext, FiltersProvider };
