import { parseISO } from "date-fns";
import client from "./client";

export const taxAuthority = {
  save(companyId, data) {
    if (data.id) {
      return client.put(`/companies/${companyId}/tax-authorities/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/tax-authorities/`, data);
  },
  details(companyId, taxId) {
    return client.get(`/companies/${companyId}/tax-authorities/${taxId}/`).then((response) => ({
      ...response.data,
      booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
      payment_date: response.data.booking_date ? parseISO(response.data.payment_date) : null,
    }));
  },
  latestUnpaid(companyId) {
    return client.get(`/companies/${companyId}/tax-authorities/latest_unpaid/`);
  },
  remove(companyId, taxId) {
    return client.delete(`/companies/${companyId}/tax-authorities/${taxId}/`);
  },
};

export const taxDP = {
  save(companyId, data) {
    if (data.id) {
      return client.put(`/companies/${companyId}/tax-direct-payments/${data.id}/`, data);
    }
    return client.post(`/companies/${companyId}/tax-direct-payments/`, data);
  },
  details(companyId, dpId) {
    return client.get(`/companies/${companyId}/tax-direct-payments/${dpId}/`).then((response) => ({
      ...response.data,
      booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
      payment_date: response.data.booking_date ? parseISO(response.data.payment_date) : null,
    }));
  },
  remove(companyId, dpId) {
    return client.delete(`/companies/${companyId}/tax-direct-payments/${dpId}/`);
  },
  cancelPayment(companyId, dpId) {
    return client.patch(`/companies/${companyId}/tax-direct-payments/${dpId}/cancel_payment/`);
  },
  pay(companyId, dpId, bankIDToken) {
    return client.patch(`/companies/${companyId}/tax-direct-payments/${dpId}/send_money_bank/`, {
      bank_id_token: bankIDToken,
    });
  },
  manualPay(companyId, dpId, data) {
    return client.patch(`/companies/${companyId}/tax-direct-payments/${dpId}/add_manual_payment/`, data);
  },
};

export const skatteverket = {
  checkAuth: () => {
    return client.get(`/skatteverket/check_user_skv_authorization/`);
  },
  doAuth: () => {
    return client.get(`/skatteverket/skv_acg_auth/`);
  },
  fetchCompanyEvents: (companyId) => {
    return client.post(`/companies/${companyId}/fetch_company_customer_events/`);
  },
  fetchAllEvents: (companyId) => {
    return client.post(`/skatteverket/fetch_all_companies_customer_events/`);
  },
};
