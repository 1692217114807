import { Modal } from "react-bootstrap";
import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SubmitButton } from "components/ui/buttons";
import * as officeAPI from "api/office";
import { AllError, FormGroup } from "components/formik";
import { DraggableModalDialog } from "../DraggableModalDialog";

function RegisterOfficeModal({ handleClose }) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const formikProps = {
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      await officeAPI
        .registerOffice({
          ...values,
        })
        .then((response) => {
          navigate(`/offices/${response.data.id}/settings`, { replace: true });
        })
        .catch((error) => {
          setErrors(error.data);
        });
    },
  };

  return (
    <Modal show onHide={handleClose} animation={false} dialogAs={DraggableModalDialog} scrollable>
      <Formik {...formikProps}>
        {({ isSubmitting, errors }) => {
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title as="h4">{t("others:actions.registerAgency")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup.Input label={t("common:agencyName")} name="name" required />
                <AllError errors={errors} />
              </Modal.Body>
              <Modal.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RegisterOfficeModal;
