import React from "react";
import cx from "classnames";

function TDCheckInput({ trans, toggleSelect }) {
  return (
    <td className={cx("selectable", { matched: trans.matched })} onClick={toggleSelect}>
      <div className="checkbox custom-control checkbox-primary">
        <input type="checkbox" className="d-none" checked={!!trans.checked} readOnly />
        <label className="custom-control-label" />
      </div>
    </td>
  );
}

function THCheckInput({ checked, label, toggleSelect, className }) {
  return (
    <th className={cx("sticky1 text-right", className)} onClick={toggleSelect}>
      <div className="checkbox custom-control checkbox-primary">
        <input type="checkbox" className="d-none" checked={!!checked} readOnly />
        <label className="custom-control-label"> {label}</label>
      </div>
    </th>
  );
}

export { TDCheckInput, THCheckInput };
