import React from "react";

import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";
import useTable from "components/tables/btable/useTable";
import { byMonths, byWeekDays } from "api/options";
import RepeatedTaskForm from "./RepeatedTaskForm";

function RepeatedTaskEditWidget({ data: item, companyId, id }) {
  const {
    dataActions: { reload },
  } = useTable();

  const successCallback = () => {
    reload();
  };

  const deleteCallback = () => {
    reload();
  };

  const task = {
    ...item,
    assignee: item.assignee ? { value: item.assignee, label: item.assignee_name } : null,
    byweekday: item.byweekday
      ? item.byweekday.split(",").map((value) => ({ value, label: byWeekDays.getLabel(value) }))
      : [],
    bymonthday: item.bymonthday ? item.bymonthday.split(",").map((value) => ({ value, label: value })) : [],
    bymonth: item.bymonth ? item.bymonth.split(",").map((value) => ({ value, label: byMonths.getLabel(value) })) : [],
    bysetpos: item.bysetpos || "",
  };

  return (
    <RepeatedTaskForm
      companyId={companyId}
      task={task}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
    />
  );
}

const EnhancedRepeatedTaskEditWidget = withInitialAsync(
  RepeatedTaskEditWidget,
  ({ companyId, taskId }) =>
    React.useCallback(
      (cancelToken) => {
        return companyAPI.getCompanyRepeatedTask(companyId, taskId, {
          cancelToken,
        });
      },
      [companyId, taskId]
    ),
  {},
  false
);

export default EnhancedRepeatedTaskEditWidget;
