import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useCompanyState } from "hooks/useCompany";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { formatMoney } from "utils/money";
import ActionResultsModal from "./ActionResultsModal";

function CustomerInvoiceMultiActionResultsModal({ selectedItems, handleClose, apiRequest, successText }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  const { t } = useTranslation();
  const [data, setData] = useState(
    selectedItems.map((invoice) => ({
      ...invoice,
      title: `${invoice.customer_snapshot.Name} / ${formatMoney(invoice.amount_sek, 0)} SEK  / ${invoice.booking_date}`,
    }))
  );
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();

  useEffect(() => {
    const promises = [];
    selectedItems.forEach((item, i) => {
      const promise = setTimeout(() => {
        return apiRequest(companyId, item.id)
          .then((response) => {
            setData((_data) => _data.map((it) => (it.id === item.id ? { ...it, progress: 1 } : it)));
            return response;
          })
          .catch((error) => {
            let errorMsg = "__all__" in error.data ? error.data.__all__ : error.data;
            if (_.isObject(errorMsg)) {
              errorMsg = t("msg:fixErrors");
            }
            setData((d) =>
              d.map((it) =>
                it.id === item.id
                  ? {
                      ...it,
                      progress: -1,
                      error: errorMsg,
                    }
                  : it
              )
            );
            return error;
          });
      }, i * 300);
      promises.push(promise);
    });

    Promise.all(promises).then((responses) => {
      // force user to close modal by himself
      reloadMenuNumbers();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ActionResultsModal data={data} handleClose={handleClose} successText={successText} />;
}

export default CustomerInvoiceMultiActionResultsModal;
