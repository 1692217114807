import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Step1() {
  const { t } = useTranslation("common");
  return (
    <Modal.Body className="step-details">
      <p className="step-counter">
        <strong>{t("common:step")} 1 </strong>
        {t("common:of")} 4
      </p>
      <p className="step-info">{t("company:skat.step1Info")}</p>
      <Button as="a" variant="link" href="https://sso.skatteverket.se/ea/ea_eanmalan/Start" target="_blank">
        {t("company:skat.goToTaxAgency")} <i className="fe-external-link" />
      </Button>
    </Modal.Body>
  );
}

export default Step1;
