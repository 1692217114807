import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useTable from "components/tables/btable/useTable";
import ProductForm from "./ProductForm";

function ProductAddWidget({ companyId }) {
  const {
    dataActions: { reload },
  } = useTable();
  const product = {
    Description: "",
    ProductType: "Service",
    IsActive: true,
    UnitPrice: "",
    Units: "",
    VAT: "25",
    ArticleNumber: "",
  };

  const { t } = useTranslation("ci");
  return (
    <Row>
      <Col>
        <Card className="card-border" id="create-product-section">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addProduct")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0" style={{ marginBottom: "110px" }}>
            <ProductForm companyId={companyId} product={product} successCallback={reload} resetOnSuccess />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default ProductAddWidget;
