import React, { useEffect } from "react";

import NavBar from "components/ui/navbar";
import { useCompanyDispatch } from "hooks/useCompany";
import cx from "classnames";
import { SetDocTitle } from "components/ui/titles/PageTitle";
import CompanyListMenu from "./CompanyListMenu";

function CompanyListLayout({ children }) {
  const { reset } = useCompanyDispatch();

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <>
      <SetDocTitle title="Bflow" />
      <div className="company-list">
        <NavBar companyMenu={false} />
        <CompanyListMenu />
        <div className={cx("content-page")}>
          <div className="content pt-3">
            <div className="container-fluid">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyListLayout;
