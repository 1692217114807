import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import UnsavedWarn from "components/ui/UnsavedWarn";
import { checkFormState } from "utils/forms";
import { OfficeSupportRolesEssentialWidget } from "components/forms/OfficeSupportForms/OfficeSupportRolesEssentialForm";
import { OfficeSupportRolesAdditionalWidget } from "components/forms/OfficeSupportForms/OfficeSupportRolesAdditionalForm";
import { OfficeSupportRolesTaxAgencyAttorneyWidget } from "components/forms/OfficeSupportForms/OfficeSupportTaxAgencyAttorneyForm";
import { OfficeSupportRolesBankAttorneyWidget } from "components/forms/OfficeSupportForms/OfficeSupportBankAttorneyForm";
import { OfficeSupportRolesOtherAttorneyWidget } from "components/forms/OfficeSupportForms/OfficeSupportOtherAttorneyForm";
import { toast } from "react-toastify";
import * as officeSupportApi from "api/office-support";
import { UpToDateToggleButton } from "components/ui/buttons/ToggleButton";
import { useTranslation } from "react-i18next";

function RolesPage() {
  const { t } = useTranslation("office-support");
  const { company, tabIndicators, reloadIndicators } = useOutletContext();
  const [editedForms, setEditedForms] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [checkedToggle, setCheckedToggle] = useState(false);
  const [checkIndicators, setCheckIndicators] = useState(false);

  const changeEditedForm = (formState) => {
    setEditedForms(checkFormState(formState, editedForms));
    setEditMode(editedForms.length > 0);
    if (editMode) {
      setCheckIndicators(true);
    } else {
      if (checkIndicators) {
        reloadIndicators();
      }
      setCheckIndicators(false);
    }
  };

  async function toggleEvent() {
    await officeSupportApi.statusIndicator
      .update(company.id, {
        roles_up_to_date: !checkedToggle,
      })
      .then((response) => {
        reloadIndicators();
        toast.success(t("msg:saved"), { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error(error.data, { autoClose: 5000 });
      });
  }
  React.useEffect(() => {
    setCheckedToggle(tabIndicators.roles?.up_to_date);
  }, [tabIndicators]);

  return (
    <>
      <UnsavedWarn isUnsaved={editMode} />
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <UpToDateToggleButton
                onClickEvent={() => toggleEvent()}
                checked={checkedToggle}
                disabled={!tabIndicators.roles?.allow_toggle}
                tooltipText={t("msg:missingRequiredInformation")}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <OfficeSupportRolesEssentialWidget companyId={company.id} changeEditedForm={changeEditedForm} />
              <OfficeSupportRolesAdditionalWidget companyId={company.id} changeEditedForm={changeEditedForm} />
            </Col>
            <Col lg={6}>
              <OfficeSupportRolesBankAttorneyWidget companyId={company.id} changeEditedForm={changeEditedForm} />
              <OfficeSupportRolesTaxAgencyAttorneyWidget companyId={company.id} changeEditedForm={changeEditedForm} />
              <OfficeSupportRolesOtherAttorneyWidget company={company} changeEditedForm={changeEditedForm} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default RolesPage;
