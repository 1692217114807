import { useTranslation } from "react-i18next";
import React from "react";
import { Modal } from "react-bootstrap";

import TaskForm from "./TaskForm";
import { DraggableModalDialog } from "../DraggableModalDialog";

function TaskFormModal({ handleClose, handleSave, task, options }) {
  const { t } = useTranslation("tt");

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => handleClose()}
      dialogClassName="task-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("taskDetails")}</Modal.Title>
      </Modal.Header>
      <TaskForm task={task} onSave={handleSave} options={options} />
    </Modal>
  );
}

export default TaskFormModal;
