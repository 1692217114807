import React from "react";

import * as absenceAPI from "api2/absence";
import useTable from "components/tables/btable/useTable";
import BaseCommentsModal from "./BaseCommentsModal";

function AbsenceCommentsModal({ handleClose, companyId, report }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const onClose = (comments) => {
    handleClose();
    updateRow({ ...report, comments });
  };

  return (
    <BaseCommentsModal
      handleClose={onClose}
      companyId={companyId}
      itemId={report.id}
      apiCreate={absenceAPI.comment.create}
      apiList={absenceAPI.comment.list}
      apiRemove={absenceAPI.comment.remove}
    />
  );
}

export default AbsenceCommentsModal;
