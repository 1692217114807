import React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";

function BillectaNotConfiguredErrorPage() {
  const { t } = useTranslation("msg");

  return (
    <div className="page-alert pt-2">
      <Alert variant="warning">
        <span>
          <i className="fas fa-exclamation mr-2" />
          {t("billectaNotConfigured")}
        </span>
      </Alert>
    </div>
  );
}
export default BillectaNotConfiguredErrorPage;
