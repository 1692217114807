import React, { useCallback, useContext } from "react";
import { Form, Formik } from "formik";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import TableProvider from "components/tables/btable/provider";
import { SIEFileForm } from "components/forms/company-settings-forms";
import { FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import {
  OnboardingDispatchContext,
  OnboardingStateContext,
} from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import * as companyAPI from "api/company";
import * as tableAPI from "api/table";
import { SieFileTable } from "components/tables";

function ImportAccountingStep({ companyId }) {
  const { sieImported } = useContext(OnboardingStateContext);
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  const onSIEImported = useCallback(() => {
    fetchDetails(true);
  }, [fetchDetails]);

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("SIE/imported", onSIEImported);
    return () => document.body.removeEventListener("SIE/imported", onSIEImported);
  }, [onSIEImported]);

  const { t } = useTranslation("company");
  const formikProps = {
    initialValues: {
      importSIE: sieImported || false,
    },
    onSubmit: (values) => {
      return companyAPI.onboarding.importAccountingStep.save(companyId).then(() => {
        fetchDetails(true);
      });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, handleSubmit }) => {
        return (
          <Card className="import-accounting-step">
            <Card.Body>
              <StepHeader
                rightComponent={!values.importSIE && <SaveButton disabled={isSubmitting} onSave={handleSubmit} />}
              />
            </Card.Body>

            <>
              <Card.Body>
                <Form noValidate>
                  <FormGroup.YesNoToggle
                    className="btn-outline-secondary-blue"
                    name="importSIE"
                    label={t("onboarding.wantImportSIE")}
                  />
                </Form>
              </Card.Body>
              {values.importSIE && (
                <TableProvider
                  tableId="sie_files_table"
                  dataSource={(filters, config) => tableAPI.companySieFiles(companyId, filters, config)}
                >
                  <Card.Body>
                    <Card.Title>{t("sieTableTitle")}</Card.Title>
                    <SieFileTable companyId={companyId} selectable={false} />
                  </Card.Body>
                  <SIEFileForm companyId={companyId} />
                </TableProvider>
              )}
            </>
          </Card>
        );
      }}
    </Formik>
  );
}

export default ImportAccountingStep;
