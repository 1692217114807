import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { parseDate } from "utils/date";
import SilverbackForm from "components/forms/SilverbackForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as silverAPI from "api/silverback";
import { RocketLoader } from "components/ui/loaders";

function SilverbackEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: silverId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return silverAPI.get(company.id, silverId);
  });

  const onSave = () => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <div>
      <SilverbackForm
        data={{ ...item, meeting_date: parseDate(item.meeting_date) }}
        companyId={company.id}
        onSave={onSave}
      />
    </div>
  );
}

export default SilverbackEditPage;
