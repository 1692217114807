import React from "react";
import { Alert } from "react-bootstrap";

function AllError({ errors, ...props }) {
  if (errors && errors.__all__) {
    return (
      <Alert variant="danger" {...props}>
        {errors.__all__}
      </Alert>
    );
  }
  return null;
}

export default AllError;
