import React, { useEffect, useState } from "react";
import { useCompanyState } from "hooks/useCompany";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import ActionResultsModal from "../ActionResultsModal";

function PayResultsModal({ selectedItems, handleClose, bankIDToken }) {
  const {
    company: { id: companyId },
  } = useCompanyState();
  const [data, setData] = useState([...selectedItems]);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();

  useEffect(() => {
    const promises = [];
    selectedItems.forEach((item, i) => {
      const promise = setTimeout(() => {
        return item
          .apiRequest(companyId, item.id, bankIDToken)
          .then((response) => {
            setData((_data) => _data.map((it) => (it.id === item.id ? { ...it, progress: 1 } : it)));
            return response;
          })
          .catch((error) => {
            const errorMsg = "__all__" in error.data ? error.data.__all__ : error.data;
            setData((d) => d.map((it) => (it.id === item.id ? { ...it, progress: -1, error: errorMsg } : it)));
            return error;
          });
      }, i * 700);
      promises.push(promise);
    });

    Promise.all(promises).then((responses) => {
      // force user to close modal by himself
      reloadMenuNumbers();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ActionResultsModal data={data} handleClose={handleClose} successText="common:statuses:sentBank" />;
}

export default PayResultsModal;
