import React from "react";
import { Card } from "react-bootstrap";

import TrackByCompany from "./calendar/TrackByCompany";

function TimeTrackerByCompanyPage() {
  return (
    <Card className="mb-0">
      <TrackByCompany />
    </Card>
  );
}

export default TimeTrackerByCompanyPage;
