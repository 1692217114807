import React from "react";
import { Card } from "react-bootstrap";
import * as tableAPI from "api/table";
import TableProvider from "components/tables/btable/provider";
import OfficeScheduleTemplateExtendedTable from "components/tables/OfficeScheduleTemplateExtendedTable/OfficeScheduleTemplateExtendedTable";
import OfficeScheduleTemplateAddWidget from "components/forms/office-settings-forms/OfficeScheduleTemplateAddWidget";
import { useParams } from "react-router-dom";

function OfficeScheduleTemplatePage() {
  const { officeId } = useParams();
  const initialFilters = {};

  return (
    <TableProvider
      tableId="scheduleTemplates"
      dataSource={(filters, config) => tableAPI.officeScheduleTemplates(officeId, filters, config)}
      initialFilters={initialFilters}
      keepTerm={false}
    >
      <Card>
        <Card.Body>
          <OfficeScheduleTemplateExtendedTable officeId={officeId} />
        </Card.Body>
      </Card>
      <OfficeScheduleTemplateAddWidget officeId={officeId} />
    </TableProvider>
  );
}

export default OfficeScheduleTemplatePage;
