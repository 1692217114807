import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import * as statsAPI from "api/stats";
import { MonthDateFilter } from "components/filters/DateFilters";

function ManualVerificationTrack() {
  const { t } = useTranslation("reports");
  const [month, setMonth] = useState(null);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    if (month) {
      statsAPI.manualVerifications(month).then((response) => {
        setCompanies(
          response.data.map((company) => ({
            ...company,
            percentage: company.all_count ? ((company.manual_count / company.all_count) * 100).toFixed(1) : 0,
          }))
        );
      });
    }
  }, [month]);

  return (
    <Card>
      <Card.Header>
        <Card.Title>{t("stats.manualVerifications")}</Card.Title>
      </Card.Header>
      <Card.Body className="pt-0">
        <Row>
          <Col xl={3}>
            <MonthDateFilter label={t("common:dates.month")} defaultValue={month} onChange={setMonth} />
          </Col>
        </Row>

        <Table bordered>
          <thead>
            <tr>
              <th>{t("common:company")}</th>
              <th className="text-right">{t("stats.manualVerifications")}</th>
              <th className="text-right">{t("common:money.total")}</th>
              <th className="text-right">{t("stats.percentage")}</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => (
              <tr key={company.id}>
                <td>{company.company_name}</td>
                <td className="text-right">{company.manual_count}</td>
                <td className="text-right">{company.all_count}</td>
                <td className="text-right">{company.percentage}%</td>
              </tr>
            ))}
            {!companies.length && (
              <tr>
                <td colSpan={4}>{t("common:noResultsFound")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
export default ManualVerificationTrack;
