import React, { Fragment, useState, useCallback } from "react";

import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { OFFICE_PERMISSION_MODULES } from "utils/permissions/officePermissions";
import ChildCheckboxes from "./ChildCheckboxes";

import ParentCheckBoxes from "./ParentCheckboxes";
import GlobalCheckboxes from "./GlobalCheckboxes";

function CompanyConsultPermDetailedTable({ permsChecked, setPermsChecked, companyName }) {
  const [activeParentRow, setActiveParentRow] = useState(0);

  const expandParentRow = useCallback(
    (rowIndex) => {
      if (rowIndex === activeParentRow) {
        setActiveParentRow(-1);
      } else {
        setActiveParentRow(rowIndex);
      }
    },
    [activeParentRow]
  );

  return (
    <>
      <section className="headers">
        <table>
          <thead>
            <tr>
              <th>
                <p className="company-name">{companyName}</p>
              </th>
              <GlobalCheckboxes permsChecked={permsChecked} setPermsChecked={setPermsChecked} />
            </tr>
          </thead>
        </table>
      </section>
      <Scrollbars className="body" style={{ height: 415 }}>
        <table>
          <tbody>
            {OFFICE_PERMISSION_MODULES.map((permissionModule, rowIndex) => {
              const withExpandClass = rowIndex === activeParentRow ? "expand" : "";

              return (
                <Fragment key={permissionModule.id}>
                  <ParentCheckBoxes
                    setPermsChecked={setPermsChecked}
                    permsChecked={permsChecked}
                    permissionModule={permissionModule}
                    withExpandClass={withExpandClass}
                    expandParentRow={() => expandParentRow(rowIndex)}
                  />
                  {!!withExpandClass &&
                    permissionModule.children.map((child) => {
                      return (
                        <ChildCheckboxes
                          key={child.code}
                          setPermsChecked={setPermsChecked}
                          child={child}
                          permsChecked={permsChecked}
                          withExpandClass={withExpandClass}
                        />
                      );
                    })}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </Scrollbars>
    </>
  );
}

CompanyConsultPermDetailedTable.propTypes = {
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
};

export default CompanyConsultPermDetailedTable;
