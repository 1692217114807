import React from "react";
import { Trans, useTranslation } from "react-i18next";

function PasswordResetSuccessPage() {
  const { t } = useTranslation("others");
  return (
    <div className="text-center">
      <p className="text-muted mb-4 font-15">
        <Trans t={t} i18nKey="auth.introResetOk">
          Your password to mobile application has been changed successfully
          <br />
        </Trans>
      </p>
    </div>
  );
}

export default PasswordResetSuccessPage;
