import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import { GlobalSupplierEditWidget } from "components/forms/GlobalSupplierForm";
import Filters from "./GlobalSupplierFilters";

function GlobalSupplierExtendedTable() {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
      },
      {
        field: "alias_name",
        label: t("common:aliasName"),
      },
      {
        field: "org_no",
        label: t("common:orgNo"),
        canSort: false,
      },
      {
        field: "country",
        label: t("common:contact.country"),
      },
    ],
    [t]
  );
  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters />}
          renderExpandedRow={(row) => <GlobalSupplierEditWidget id={row.id} />}
        />
      </Col>
    </Row>
  );
}

export default GlobalSupplierExtendedTable;
