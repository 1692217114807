import React from "react";
import { TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import { useTranslation } from "react-i18next";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";
import useModal from "hooks/useModal";

function OfficeActions({ row }) {
  const { t } = useTranslation("navigation");
  const fileModal = useModal();

  const downloadOfficeData = () => {
    fileModal.open(`/offices/${row.id}/export_contact_csv/`);
  };

  return (
    <>
      <TooltipActionButton text={t("common:actions.download")} onClick={downloadOfficeData} icon="fas fa-download" />
      <TooltipActionLink text={t("settings")} icon="fa fa-cog" link={`/offices/${row.id}/settings`} />
      {fileModal.show && (
        <FileUrlModalOld
          fileUrl={fileModal.data}
          handleClose={fileModal.close}
          downloadFileName="office.csv"
          autoDownload
        />
      )}
    </>
  );
}

export default OfficeActions;
