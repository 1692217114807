import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import * as yup from "yup";
import { ConsultPicker, FormGroup } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import * as officeAPI from "api/office";
import { toast } from "react-toastify";
import { handleActionErrors } from "../../../api/errors";

function SubOfficeForm({ officeId, subOffice, onSuccess }) {
  const { t } = useTranslation(["common", "msg"]);
  const onDelete = () => {
    return officeAPI.subOffices
      .remove(officeId, subOffice.id)
      .then(() => {
        toast.success(t("msg:deleted"), { autoClose: 2000 });
        onSuccess();
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };
  const formikProps = {
    initialValues: {
      ...subOffice,
      manager: subOffice.manager
        ? {
            value: subOffice.manager,
            label: subOffice.manager_name,
          }
        : null,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      manager: yup.object().nullable().required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await officeAPI.subOffices
        .save(officeId, {
          ...values,
          manager: values.manager.value,
        })
        .then((response) => {
          if (onSuccess) {
            onSuccess();
          }
          if (!values.id) {
            resetForm();
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, errors }) => {
        return (
          <Form>
            <Row className="pb-4">
              <Col lg={4}>
                <FormGroup.Input label={t("common:nameOfOffice")} name="name" required />
              </Col>
              <Col>
                <ConsultPicker
                  name="manager"
                  officeId={officeId}
                  label={t("common:officeManager")}
                  required
                  maxMenuHeight={150}
                />
              </Col>
            </Row>
            <Row className="pt-4">
              <Col lg={4}>
                {subOffice.id && (
                  <RemoveButton
                    variant="danger"
                    onClick={onDelete}
                    confirmMessage={t("common:actions.remove")}
                    className="mr-2"
                  />
                )}
                <SubmitButton isSubmitting={isSubmitting} />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SubOfficeForm;
