import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as verificationAPI from "api2/verification";
import useInitialAsync from "hooks/useInitialAsync";
import { SubmitButton } from "components/ui/buttons";
import { useAuthState } from "hooks/useAuth";
import CommentsBox from "./CommentsBox";

function VerificationInlineComments({ companyId, itemId, allowCreate = false }) {
  const { t } = useTranslation("common");
  const [counter, setCounter] = useState(1); // to refresh
  const ref = React.useRef();
  const {
    loading,
    item: comments,
    set: setComments,
  } = useInitialAsync(() => verificationAPI.comment.list(companyId, itemId), [], [counter]);
  const {
    user: { id: userId },
  } = useAuthState();

  const refreshComments = useCallback(() => {
    setCounter((c) => c + 1);
  }, []);

  React.useEffect(() => {
    document.body.addEventListener("comments/changed", refreshComments);
    return () => document.body.removeEventListener("comments/changed", refreshComments);
  }, [refreshComments]);

  const onRemove = (index, comment) => {
    verificationAPI.comment.remove(companyId, itemId, comment.id).then(() => {
      setComments([...comments.slice(0, index), ...comments.slice(index + 1)]);
    });
  };

  const newMessage = () => {
    const text = ref.current.value.trim();
    if (!text) {
      toast.error(t("errors.messageRequired"), { autoClose: 3000 });
      return;
    }
    verificationAPI.comment
      .create(companyId, itemId, text)
      .then((res) => {
        ref.current.value = "";
        setComments([...comments, res.data]);
        toast.success(t("msg:saved"), { autoClose: 3000 });
      })
      .catch(() => {
        toast.error(t("errors.sthWentWrong"), { autoClose: 3000 });
      });
  };

  if (loading) {
    return null;
  }
  return (
    <div className="inline-comments">
      <span>{t("comments")}:</span>
      <CommentsBox t={t} comments={comments} userId={userId} onRemove={onRemove} />
      {allowCreate && (
        <div>
          <textarea className="form-control" placeholder={t("yourMsg")} autoFocus={false} ref={ref} />
          <SubmitButton
            type="button"
            className="mt-1"
            size="sm"
            variant="secondary"
            title="saveComment"
            onClick={newMessage}
          />
        </div>
      )}
    </div>
  );
}

export default VerificationInlineComments;
