import React from "react";
import { Card } from "react-bootstrap";

import { YearEndClosureTable } from "components/tables";
import TableProvider from "components/tables/btable/provider";
import * as tableAPI from "api/table";

function YearEndClosureListPage() {
  return (
    <Card id="company-manage-table">
      <Card.Body>
        <TableProvider
          tableId="company-manage1"
          initialFilters={{ favourites: true }}
          pageSize={150}
          dataSource={tableAPI.manageYearEndClosure}
        >
          <YearEndClosureTable />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}
export default YearEndClosureListPage;
