import { parseISO } from "date-fns";
import client from "./client";

export function save(companyId, data) {
  if (data.id) {
    return client.put(`/companies/${companyId}/salaries/${data.id}/`, data);
  }
  return client.post(`/companies/${companyId}/salaries/`, data);
}

export function details(companyId, salaryId) {
  return client.get(`/companies/${companyId}/salaries/${salaryId}/`).then((response) => ({
    ...response.data,
    booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
  }));
}

export function employees(companyId) {
  return client.get(`/companies/${companyId}/salaries/employees/`);
}

export function remove(companyId, salaryId) {
  return client.delete(`/companies/${companyId}/salaries/${salaryId}/`);
}

export function removeSpec(companyId, salaryId) {
  return client.delete(`/companies/${companyId}/salaries/${salaryId}/remove_spec/`);
}

export function approve(companyId, salaryId) {
  return client.patch(`/companies/${companyId}/salaries/${salaryId}/approve/`);
}

export function movePrelim(companyId, salaryId) {
  return client.patch(`/companies/${companyId}/salaries/${salaryId}/prelim/`);
}

export function cancelPayment(companyId, salaryId) {
  return client.patch(`/companies/${companyId}/salaries/${salaryId}/cancel_payment/`);
}

export function pay(companyId, salaryId, bankIDToken) {
  return client.patch(`/companies/${companyId}/salaries/${salaryId}/send_money_bank/`, {
    bank_id_token: bankIDToken,
  });
}

export function manual_pay(companyId, salaryId, data) {
  return client.patch(`/companies/${companyId}/salaries/${salaryId}/add_manual_payment/`, data);
}

export function bookHolidaySoc(companyId, values) {
  return client.post(`/companies/${companyId}/holiday-all-soc/`, values);
}

export function agaPdfReduction(companyId, formData) {
  return client.post(`/companies/${companyId}/aga-pdf-reduction/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
