import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Scrollbars } from "react-custom-scrollbars";

import "./SinglePayments.scss";
import { formatMoney } from "utils/money";
import { useCompanyState } from "hooks/useCompany";
import DecoupledSigning from "./DecoupledSigning";

function SinglePayments({ initialPayments }) {
  const { t } = useTranslation("common");
  const {
    company: { id: companyId },
  } = useCompanyState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [payments, setPayments] = useState(
    initialPayments.map((item) => ({
      ...item,
      state: "",
      error: "",
    }))
  );

  const updatePayment = useCallback(
    ({ error, state }) => {
      setPayments((_payments) =>
        _payments.map((item, idx) => {
          if (idx !== currentIndex) {
            return item;
          }
          return {
            ..._payments[currentIndex],
            error,
            state,
          };
        })
      );
      if (currentIndex < initialPayments.length - 1) {
        setCurrentIndex(currentIndex + 1);
      } else {
        setCurrentIndex(-1);
      }
    },
    [currentIndex, initialPayments.length]
  );
  const finished = currentIndex === -1;
  const minHeight = useMemo(() => {
    if (initialPayments.length > 10) {
      return 700;
    }
    if (initialPayments.length >= 5) {
      return 500;
    }
    return 350;
  }, [initialPayments.length]);

  return (
    <div className="single-payments2">
      <div className="title">
        <h2>{finished ? t("basket.paymentsSummary") : t("basket.paymentsSigning")}</h2>
        {!finished && (
          <h4>
            {currentIndex + 1} {t("of")} {payments.length}
          </h4>
        )}
      </div>
      <div className="box">
        <div className="box-left" style={{ minHeight }}>
          <Scrollbars>
            <table>
              <thead>
                <tr>
                  <th>{t("receiver")}</th>
                  <th style={{ width: 150 }} />
                  <th style={{ width: 50 }} />
                </tr>
              </thead>
              <tbody>
                {payments.map((item, idx) => (
                  <tr
                    key={item.id}
                    className={cx(`state state--${item.state}`, {
                      "is-active": idx === currentIndex,
                      "has-error": !!item.error,
                    })}
                  >
                    <td>
                      <span>{item.title}</span>
                      {item.error && <p className="text-error">{item.error}</p>}
                      {item.state === "partially_signed" && (
                        <p className="text-warn">{t("basket.partiallySignedInfo")}</p>
                      )}
                      {item.state === "pending" && <p className="text-warn">{t("basket.pendingSignedInfo")}</p>}
                    </td>
                    <td className="text-right">{formatMoney(item.payment_amount_sek, 2, 2)} SEK</td>
                    <td className="state-icon">
                      <i className="fe-clock" />
                      <i className="fe-check-circle" />
                      <i className="fe-x-circle" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Scrollbars>
        </div>
        {!finished && (
          <div className="box-right">
            <DecoupledSigning
              key={currentIndex}
              companyId={companyId}
              payment={payments[currentIndex]}
              updatePayment={updatePayment}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default SinglePayments;
