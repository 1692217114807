import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import "./StepStatus.scss";

function StepStatus({ status, disabled, ...props }) {
  const { t } = useTranslation("company");

  if (status === "locked") {
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip className={cx("step-status-tooltip")}>{t(`onboarding.lockedTooltip`)}</Tooltip>}
      >
        <Button size="sm" variant="secondary" className={cx("step-status", status)} disabled={disabled} {...props}>
          {t(`onboarding.${status}`)}
        </Button>
      </OverlayTrigger>
    );
  }

  return (
    <Button size="sm" variant="secondary" className={cx("step-status", status)} disabled={disabled} {...props}>
      {t(`onboarding.${status}`)}
    </Button>
  );
}

export default StepStatus;
