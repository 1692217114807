import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import * as options from "api/options";
import { formatDatetime } from "utils/date";
import Actions from "./SieFileActions";
import Filters from "./SieFileFilters";

function SieFileTable({ companyId }) {
  const { t } = useTranslation();

  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
        className: "w-300px",
        canSort: true,
        render: (values) => truncateText(values.name, 103),
      },
      {
        field: "imported_at",
        label: t("common:imported_at"),
        className: "w-300px",
        canSort: true,
        render: (values) => formatDatetime(values.imported_at),
      },
      {
        field: "series",
        label: t("ver:series"),
        canSort: true,
        render: (values) => options.sieFileVerSeries.getLabel(values.series),
      },
      {
        field: "financial_year__date_start",
        label: t("common:financialYear"),
        canSort: true,
        render: (values) => `${values.financial_year__date_start} - ${values.financial_year__date_end}`,
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      selectable={false}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
    />
  );
}

export default SieFileTable;
