import React, { useCallback, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { RocketLoader } from "components/ui/loaders";
import * as companyAPI from "api/company";
import { TimeReportingForm } from "components/forms/company-settings-forms";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import useAsync, { withInitialAsync } from "hooks/useAsync";

function TimeReportingFormPage({ data }) {
  const { companyId } = useParams();
  const { info: companyInfo } = useContext(CompanySettingStateContext);

  const dataSource = useCallback(
    (cancelToken) => companyAPI.getCompanyProjects(companyId, { mode: "active" }, { cancelToken }),
    [companyId]
  );
  const [{ data: projects, loading: loadingProjects }] = useAsync(dataSource, []);

  if (loadingProjects) {
    return <RocketLoader />;
  }
  return (
    <Row>
      <Col>
        <TimeReportingForm company={companyInfo} data={data} projects={projects} />
      </Col>
    </Row>
  );
}

const EnhancedTimeReportingFormPage = withInitialAsync(
  TimeReportingFormPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback(
      (cancelToken) => companyAPI.getCompanyTimeReporting(companyId, { cancelToken }),
      [companyId]
    );
  },
  { employees: [] },
  true
);

export default EnhancedTimeReportingFormPage;
