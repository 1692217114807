import React from "react";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

function Step2() {
  const { t } = useTranslation("common");
  return (
    <Modal.Body className="step-details">
      <p className="step-counter">
        <strong>{t("common:step")} 2 </strong>
        {t("common:of")} 4
      </p>
      <ul>
        <Trans i18nKey="company:skat.step2">
          <li>
            Go to <strong>"Appoint company as representative - see, add, change or terminate representative."</strong>
          </li>
          <li>Select the company you want to connect to the service for.</li>
          <li>
            Click <strong>"Next"</strong>
          </li>
        </Trans>
      </ul>
    </Modal.Body>
  );
}

export default Step2;
