import { debounce } from "lodash";
import qs from "qs";
import client from "./client";

export const getUsers = debounce((params, callback) => {
  client
    .get("/select/users/", { params })
    .then((response) =>
      callback(response.data.map((item) => ({ value: item.id, label: item.name, email: item.email })))
    );
}, 500);

export const getEmployeesForDP = debounce((params, callback) => {
  client.get("/select/employees-with-account/", { params }).then((response) =>
    callback(
      response.data.map((item) => ({
        ...item,
        value: item.user_id,
        label: `${item.user_name} - ${item.account}`,
      }))
    )
  );
}, 500);

export const getCompanyEmployees = (params, config) => {
  return client.get("/select/employees/", { params, ...config }).then((response) =>
    response.data.map((item) => ({
      ...item,
      value: item.user_id,
      label: `${item.user_name}`,
    }))
  );
};

export const getCompanyFinancialYears = (params, config) => {
  return client.get("/select/financial-years/", { params, ...config }).then((response) =>
    response.data.map((item) => ({
      ...item,
      value: item.id,
      label: `${item.date_start} - ${item.date_end}`,
    }))
  );
};

export const getConsults = (params, config) => {
  return client
    .get("/select/users/", {
      params,
      paramsSerializer: (params2) => {
        return qs.stringify(params2, { indices: false });
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.name}`,
      }))
    );
};

export const getMainConsults = (params, config) => {
  return client
    .get("/select/consults/", {
      params,
      paramsSerializer: (params2) => {
        return qs.stringify(params2, { indices: false });
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.name}`,
      }))
    );
};
export const getOfficesSimple = (params, config) => {
  return client.get("/select/offices/", { params, ...config }).then((response) =>
    response.data.map((office) => ({
      value: office.id,
      label: office.name,
    }))
  );
};

export const getCompanies = (params, config, forOfficeSupport = false) => {
  if (forOfficeSupport) {
    return client
      .get("select/office-support-companies/", {
        params,
        paramsSerializer: (param) => {
          return qs.stringify(param, { indices: false });
        },
        ...config,
      })
      .then((response) =>
        response.data.map((item) => ({
          ...item,
          value: item.id,
          label: `${item.name}`,
        }))
      );
  }
  return client
    .get("/select/companies/", {
      params,
      paramsSerializer: (param) => {
        return qs.stringify(param, { indices: false });
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.name}`,
      }))
    );
};
export const getCompaniesOrgNo = (params, config, forOfficeSupport = false) => {
  if (forOfficeSupport) {
    return client
      .get("/select/office-support-companies/", {
        params,
        paramsSerializer: (param) => {
          return qs.stringify(param, { indices: false });
        },
        ...config,
      })
      .then((response) =>
        response.data.map((item) => ({
          ...item,
          value: item.id,
          label: `${item.org_no}`,
        }))
      );
  }
  return client
    .get("/select/companies/", {
      params,
      paramsSerializer: (param) => {
        return qs.stringify(param, { indices: false });
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.org_no}`,
      }))
    );
};

export const getCompanyAccounts = debounce((params, callback) => {
  client.get("/select/company-accounts/", { params }).then((response) =>
    callback(
      response.data.map((item) => ({
        value: item.number,
        label: `${item.number} - ${item.name}`,
        isDisabled: !item.is_active,
      }))
    )
  );
}, 500);

export const getAccounts = debounce((params, callback) => {
  client.get("/select/accounts/", { params }).then((response) =>
    callback(
      response.data.map((item) => ({
        value: item.number,
        label: `${item.number} - ${item.name}`,
        isDisabled: !item.is_active,
      }))
    )
  );
}, 500);

export const getCompanySuppliers = debounce((params, callback) => {
  client.get("/select/company-suppliers/", { params }).then((response) => {
    const output = response.data;
    response.data.forEach((group) => {
      group.options.forEach((item) => {
        item.group = group.label;
        item.value = item.id;
        item.label = `${item.supplier_name} | ${item.payment_method_str}`;
      });
    });
    callback(output);
  });
}, 500);

export const getCompanyCustomers = debounce((companyId, params, callback) => {
  client
    .get(`/companies/${companyId}/billecta-customers/`, { params })
    .then((response) =>
      callback(
        response.data.map((item) => ({
          value: item.DebtorPublicId,
          label: `${item.Name}`,
          ...item,
        }))
      )
    )
    .catch(() => callback([]));
}, 500);

export const getCompanyProducts = debounce((companyId, params, callback) => {
  client
    .get(`/companies/${companyId}/billecta-products/`, { params })
    .then((response) =>
      callback(
        response.data.map((item) => ({
          value: item.ProductPublicId,
          label: `${item.ArticleNumber ? `${item.ArticleNumber} - ` : ""} ${item.Description}`.trim(),
          ...item,
        }))
      )
    )
    .catch(() => callback([]));
}, 500);

export const getSuppliersToConnect = async ({ search, prevOptions, additional, companyId }) => {
  return client
    .get("/select/supplier-connect/", { params: { companyId, page: additional.page, term: search } })
    .then((response) => {
      const sup_options = response.data.results.map((item) => ({
        value: item.id,
        label: `${item.supplier_name}`,
        id: item.id,
        ...item,
      }));
      return {
        options: sup_options,
        hasMore: response.data.next !== null,
        additional: { page: additional.page + 1 },
      };
    });
};

export const getCompanyContractTemplates = debounce((params, callback) => {
  client.get("/select/contract-templates/", { params }).then((response) =>
    callback(
      response.data.map((item) => ({
        value: item.id,
        label: item.name,
        ...item,
      }))
    )
  );
}, 500);

export const getOffices = debounce((params, callback) => {
  client.get("/select/offices/", { params }).then((response) => {
    const output = response.data.map((office) => ({
      value: office.id,
      label: office.name,
    }));
    callback(output);
  });
}, 500);

export const getHistoryUsers = (params, config) => {
  return client.get("/select/history-users/", { params, ...config }).then((response) =>
    response.data.map((item) => ({
      ...item,
      value: item.id,
      label: `${item.name}`,
    }))
  );
};

export const getSubOffices = debounce((params, callback) => {
  client
    .get("/select/sub-offices/", {
      params,
      paramsSerializer: (param) => {
        return qs.stringify(param, { indices: false });
      },
    })
    .then((response) => {
      const output = response.data.map((subOffice) => ({
        value: subOffice.id,
        label: subOffice.name,
      }));
      callback(output);
    });
}, 500);

export const getSubOfficesSimple = (params, config) => {
  return client
    .get("/select/sub-offices/", {
      params,
      paramsSerializer: (param) => {
        return qs.stringify(param, { indices: false });
      },
    })
    .then((response) =>
      response.data.map((office) => ({
        value: office.id,
        label: office.name,
      }))
    );
};
