import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import useTable from "components/tables/btable/useTable";
import { SearchFilter, SelectSimpleFilter } from "components/filters";
import * as selectAPI from "api/select";
import useAsync from "hooks/useAsync";

function SieFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const dataSource = useCallback(() => selectAPI.getCompanyFinancialYears({ companyId }), [companyId]);
  const [{ data: financial_year_options }] = useAsync(dataSource, []);

  return (
    <section className="table-filters-left">
      <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
      <SelectSimpleFilter
        name="financial_year"
        placeholder={t("financialYear")}
        options={financial_year_options}
        onFilter={setFilters}
        isClearable="true"
      />
    </section>
  );
}

export default SieFilters;
