import { formatDuration, isBefore, parseDate } from "utils/date";
import { differenceInCalendarDays } from "date-fns";
import qs from "qs";
import client from "./client";

const PREFIX = "/table";

function list(filters, config, endpoint) {
  return client.get(`${PREFIX}${endpoint}`, { params: filters, ...config });
}

export function offices(filters, config) {
  return list(filters, config, "/offices/");
}

export function officeScheduleTemplates(officeId, filters, config) {
  return list(filters, config, `/offices/${officeId}/offices-time-template/`);
}

export function subOffices(officeId, filters, config) {
  return list(filters, config, `/offices/${officeId}/sub-offices/`);
}

export function officeScheduleTemplateExceptions(officeId, templateId, filters, config) {
  return list(filters, config, `/offices/${officeId}/offices-time-template/${templateId}/exception/`);
}

export function companies(filters, config) {
  return list(filters, config, "/companies/");
}

export function onboardingSelectCompany(filters, config) {
  return list(filters, config, "/company-select/");
}

export function officeSupportCompanies(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    "/office-support/companies/"
  );
}

export function staffCompanies(filters, config) {
  const today = new Date();
  const scanTaxStatus = (taxScanDate, isSoleType) => {
    if (isSoleType) {
      return "bg-dark";
    }
    if (taxScanDate) {
      const diffDays = differenceInCalendarDays(today, taxScanDate);
      if (diffDays === 0) return "success";
      if (diffDays === 1) return "warning";
      return "danger";
    }
    return "";
  };
  const scanBankStatus = (bankScanDate) => {
    if (bankScanDate) {
      const diffDays = differenceInCalendarDays(today, bankScanDate);
      if (diffDays === 0) return "success";
      if (diffDays === 1) return "warning";
      return "danger";
    }
    return "";
  };
  const lastReconStatus = (lastReconDate) => {
    if (lastReconDate) {
      let dueDate = new Date(lastReconDate.getFullYear(), lastReconDate.getMonth() + 1, 15);
      const lastDay = new Date(lastReconDate.getFullYear(), lastReconDate.getMonth() + 1, 0);
      const isLastDay = lastReconDate.getDate() === lastDay.getDate();
      if (isLastDay) dueDate = new Date(lastReconDate.getFullYear(), lastReconDate.getMonth() + 2, 15);
      const diffDays = differenceInCalendarDays(dueDate, today);
      if (diffDays > 5) return "success";
      if (diffDays > 0) return "warning";
      return "danger";
    }
    return "";
  };
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    "/staff/companies/"
  ).then((response) => {
    response.data.forEach((company) => {
      const { tax_scan_date, is_sole_type, bank_scan_date, last_reconciliation_date } = company;
      const taxScanDate = tax_scan_date ? parseDate(tax_scan_date) : null;
      const bankScanDate = bank_scan_date ? parseDate(bank_scan_date) : null;
      const lastReconDate = last_reconciliation_date ? parseDate(last_reconciliation_date) : null;

      company.scanTaxStatus = scanTaxStatus(taxScanDate, is_sole_type);
      company.scanBankStatus = scanBankStatus(bankScanDate);
      company.lastReconStatus = lastReconStatus(lastReconDate);
    });
    return response;
  });
}

export function euVatTable(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    "/staff/eu-vats/"
  ).then((response) => {
    response.data.forEach((item) => {
      item.vat_id = item.id;
      if (!item.id) {
        item.id = `c.${item.company_id}`;
      }
    });
    return response;
  });
}

export function manageYearEndClosure(filters, config) {
  return list(filters, config, "/manage/year-end-closure/");
}

export function manageDayToDay(filters, config) {
  return list(filters, config, "/manage/day-to-day/");
}

export function manageProfitability(filters, config) {
  return list(filters, config, "/manage/profitability/");
}

export function manageOngoing(filters, config) {
  return list(filters, config, "/manage/ongoing-overview/");
}

export function companyEmployees(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/employees/`);
}

export function companyFinancialYears(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/financial-years/`);
}

export function companyAuditors(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/auditors/`);
}

export function companyProjects(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/projects/`);
}

export function companyCostCenters(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/cost-centers/`);
}

// export function companyVerifications(companyId, filters, config) {
//   return list(
//     filters,
//     {
//       ...config,
//       paramsSerializer: (params) => {
//         return qs.stringify(params, { indices: false });
//       },
//     },
//     `/companies/${companyId}/verifications/`
//   );
// }

export function companySupplierInvoices(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/supplier-invoices/`);
}

export function globalDraftSI(filters, config) {
  return list(filters, config, `/staff/draft-si/`);
}

export function companySuppliers(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/suppliers/`);
}

export function suppliers(filters, config) {
  return list(filters, config, `/suppliers/`);
}

export function users(filters, config) {
  return list(filters, config, `/users/`);
}

export function events(filters, config) {
  return list(filters, config, `/events/`);
}

export function companyDirectPayments(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/direct-payments/`);
}

export function companyCustomerInvoices(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/customer-invoices/`);
}

export function companyOffers(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/offers/`);
}

export function companyCustomers(companyId, filters, config) {
  return client.get(`/companies/${companyId}/billecta-customers/`, {
    params: filters,
    ...config,
  });
}

export function companyProducts(companyId, filters, config) {
  return client.get(`/companies/${companyId}/billecta-products/`, {
    params: filters,
    ...config,
  });
}

export function companyArchives(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/company-documents/`);
}

export function consultArchives(companyId, filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    `/companies/${companyId}/consultant-documents/`
  );
}

export function salaryArchives(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/salary-documents/`);
}

export function companyFileArchives(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/archive-documents/`);
}

export function companyFileArchivesSwitcher(companyId, archiveId, filters, config) {
  return list(filters, config, `/companies/${companyId}/archive-documents/${archiveId}/get_next_and_prev/`);
}

export function trashDocuments(filters, config) {
  return list(filters, config, `/trash-documents/`);
}

export function companySalaries(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/salaries/`);
}

export function companyTaxAuthorities(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/tax-authorities/`);
}

export function companyTaxDP(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/tax-direct-payments/`);
}

export function companyVatReport(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/events/`);
}

// export function specifications(companyId, accountNumber, financialYearId, selectedDate, config) {
//   const filters = {
//     selected_date: formatDate(selectedDate),
//     account: accountNumber,
//   };
//   return list(filters, config, `/companies/${companyId}/financial-years/${financialYearId}/specifications/`).then(
//     (response) =>
//       response.data.map((spec) => ({
//         ...spec,
//         booking_date: parseDate(spec.booking_date),
//       }))
//   );
// }

// export function companyEmployeeOfficeAbsences(companyId, filters, config) {
//   return list(filters, config, `/companies/${companyId}/employees-absence-office-reports/`).then((response) => {
//     response.data.forEach((report) => {
//       report.title = `Report ${report.user_name}`;
//     });
//     return response;
//   });
// }
//
// export function companyEmployeeAbsences(companyId, filters, config) {
//   return list(filters, config, `/companies/${companyId}/employees-absence-reports/`).then((response) => {
//     // group logs by periods
//     response.data.forEach((report) => {
//       const tasks = {};
//       report.title = `Report ${report.user_name}`;
//       report.logs.forEach((log) => {
//         const started = parseDate(log.started);
//         const stopped = parseDate(log.stopped);
//         if (!tasks[log.task]) {
//           tasks[log.task] = {
//             started,
//             stopped,
//             task_title: log.task_title,
//             task_comment: log.task_comment,
//             id: log.task,
//           };
//         } else {
//           if (isBefore(started, tasks[log.task].started)) {
//             tasks[log.task].started = started;
//           }
//           if (isAfter(stopped, tasks[log.task].stopped)) {
//             tasks[log.task].stopped = stopped;
//           }
//         }
//       });
//       report.tasks = Object.values(tasks);
//     });
//     return response;
//   });
// }

export function companyContracts(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/contract-invoicing/`);
}

export function companyContractsTemplates(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/contract-templates/`);
}

export function companyTimeReports(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/time-logs/`);
}

export function companySilverbacks(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/silverback/`);
}

export function trackerTasks(filters, config) {
  return list(filters, config, `/tracker-tasks/`).then((response) => {
    response.data.outdated_tasks.forEach((task) => {
      task.playing = task.id === response.data.running_task_id;
      task.durationStr = formatDuration(task.duration);
      task.permanent = !task.company_id;
      task.overdue = true;
    });
    Object.values(response.data.tasks_by_day).forEach((tasks) => {
      tasks.forEach((task) => {
        task.overdue = false;
        task.playing = task.id === response.data.running_task_id;
        task.durationStr = formatDuration(task.duration);
        task.permanent = false;
      });
    });
    return response;
  });
}

export function trackerTasksByCompany(filters, config) {
  return list(filters, config, `/tracker-tasks/by_company/`).then((response) => {
    Object.values(response.data.companies_tasks).forEach((company) => {
      company.tasks.forEach((task) => {
        task.overdue = isBefore(parseDate(task.deadline_date), new Date());
        task.durationStr = formatDuration(task.duration);
        if (task.main_company_task) {
          task.overdue = false;
          task.permanent = true;
        }
        if (task.id === response.data.running_task_id) {
          task.playing = true;
        }
      });
    });
    return response;
  });
}

export function trackerTimeLogs(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    `/tracker-logs/`
  );
}

export function trackerConsultReport(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    `/tracker-company-summary/`
  ).then((response) => {
    response.data.forEach((item) => ({
      ...item,
      percentage: item.max_time ? `${item.time_spent / item.max_time}%` : "0%",
    }));
    return response;
  });
}

export function companyRepeatedTasks(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/repeated-tasks/`);
}

export function companyBankRules(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/bank-matching-rules/`);
}

export function companySieFiles(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/sie-files/`);
}

export function onboardingProgress(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    "/staff/onboarding/"
  ).then((response) => {
    response.data.forEach((item) => {
      item.id = item.company_id;
    });
    return response;
  });
}

export function onboardingFinishingSteps(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    "/staff/onboarding/finishing-steps/"
  ).then((response) => {
    response.data.forEach((item) => {
      item.id = item.company_id;
    });
    return response;
  });
}

export function companyAssets(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/assets/`);
}

export function historyLogs(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/full-history/`);
}

export function skvCustomerEventsVAT(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/skv-customer-events/vat_report/`);
}

export function skvCustomerEventsSocSec(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/skv-customer-events/soc_sec/`);
}
