import axios from "axios";

import { handleError } from "api2/errors";

const client = axios.create({
  baseURL: process.env.REACT_APP_INTERNAL_API_URL || "http://localhost:8000/internal",
  timeout: 40000,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  config.headers["Accept-Language"] = localStorage.getItem("i18nextLng") || "en";
  config.headers["x-source"] = "web";
  return config;
});
client.interceptors.response.use(
  (response) => response,
  (error) => handleError(error)
);

export default client;
