import { NavLink } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

import Pill from "components/ui/pills/Pill";
import { useMenuNumbersState } from "hooks/useMenuNumbers";
import { codesFor, HasPermCode } from "components/perms";

function PayNavTabs() {
  const { t } = useTranslation("navigation");
  const { unpaid } = useMenuNumbersState();
  const badgeClass = "badge-unpaid";
  const hasConsultManage = HasPermCode(codesFor.consultManage.enabled);
  const canSeeSI = HasPermCode(codesFor.supplierInvoices.enabled);
  const canSeeOutlays = HasPermCode(codesFor.outlays.enabled);
  const canSeeSalaries = HasPermCode(codesFor.salaries.enabled);
  const canSeeTaxes = HasPermCode(codesFor.taxes.enabled);
  const canSeeDirectPayments = HasPermCode(codesFor.directPayments.enabled);
  const canSeePaySI = HasPermCode(codesFor.supplierInvoices.pay) || (canSeeSI && hasConsultManage);
  const canSeePayOutlays = HasPermCode(codesFor.outlays.pay) || (canSeeOutlays && hasConsultManage);
  const canSeePaySalaries = HasPermCode(codesFor.salaries.pay) || (canSeeSalaries && hasConsultManage);
  const canSeePayDirectPayments =
    HasPermCode(codesFor.directPayments.pay) || (canSeeDirectPayments && hasConsultManage);
  const canSeePayTaxes = HasPermCode(codesFor.taxes.pay) || (canSeeTaxes && hasConsultManage);
  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      {canSeePaySI && (
        <li className="nav-item">
          <NavLink to="/pay/supplier-invoices" className="nav-link">
            {t("supplierInvoices")} <Pill number={unpaid.supplier_invoices} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
      {canSeePayOutlays && (
        <li className="nav-item">
          <NavLink to="/pay/outlays" className="nav-link">
            {t("outlays")} <Pill number={unpaid.outlays} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
      {canSeePayDirectPayments && (
        <li className="nav-item">
          <NavLink to="/pay/direct-payments" className="nav-link">
            {t("directPayments")} <Pill number={unpaid.direct_payments} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
      {canSeePaySalaries && (
        <li className="nav-item">
          <NavLink to="/pay/salaries" className="nav-link">
            {t("salaries")} <Pill number={unpaid.salaries} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
      {canSeePayTaxes && (
        <li className="nav-item">
          <NavLink to="/pay/taxes" className="nav-link">
            {t("taxes")} <Pill number={unpaid.taxes} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
    </ul>
  );
}

export default PayNavTabs;
