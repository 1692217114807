import React, { useState } from "react";
import { Button, ButtonGroup, Card, Col, Row, Table, ToggleButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as statsAPI from "api/stats";
import { Loader } from "components/ui/loaders";

function StatsCompanyPage({ forYear = false, insideOffice = false }) {
  const { t } = useTranslation("reports");
  const [showPercentage, setShowPercentage] = useState(false);
  const { officeId: _officeId } = useParams();
  const officeId = insideOffice && _officeId ? _officeId : null;
  const { item: data, loading } = useInitialAsync(() => statsAPI.companies(forYear, officeId), {});
  if (loading) {
    return <Loader />;
  }
  function gotTo(location) {
    window.location = location;
  }

  const {
    months,
    percentage_company_selection_data,
    per_month_by_office_id,
    total_per_office,
    stats_before_time_period_by_office_id,
    total_per_month,
    total,
    // total_drafts,
  } = data;
  const generalWidth = 700 / months.length + 25;
  const colspan_extra = 2;
  const smallWidth = generalWidth - 20;
  const hidePercentage = true;
  return (
    <Card>
      <Card.Body>
        <Row className="pb-3">
          <Col>
            <ButtonGroup toggle className="d-block">
              <ToggleButton
                type="checkbox"
                variant="outline-primary"
                checked={months.length === 6}
                onChange={(e) => gotTo("./companies")}
              >
                6 {t("common:months").toLowerCase()}
              </ToggleButton>
              <ToggleButton
                type="checkbox"
                variant="outline-primary"
                checked={months.length === 12}
                onChange={(e) => gotTo("./companies-yearly")}
              >
                12 {t("common:months").toLowerCase()}
              </ToggleButton>
            </ButtonGroup>
          </Col>
          {!hidePercentage && (
            <Col className="text-right">
              <Button
                className="btn-link"
                variant=""
                size="lg"
                onClick={() => {
                  setShowPercentage(!showPercentage);
                }}
              >
                {t("loginPercentage")} <i className={showPercentage ? "fa fa-toggle-on" : "fa fa-toggle-off"} />{" "}
              </Button>
            </Col>
          )}
        </Row>
        <Table bordered striped className="stats-table">
          <thead>
            <tr>
              <th key="offices" className="text-left">
                {t("agencies")}
              </th>
              {/*  <th key="drafts" style={{ minWidth: smallWidth, width: smallWidth }} className="draft">  */}
              {/*  {t("common:drafts")}  */}
              {/*  </th>  */}
              <th key="acc" style={{ minWidth: smallWidth, width: smallWidth }}>
                {t("acc")}
              </th>
              {months.map((item, index) => (
                <th key={item} style={{ minWidth: generalWidth, width: generalWidth }}>
                  {t(item)}
                </th>
              ))}
              <th key="total-right" style={{ minWidth: generalWidth + 20, width: generalWidth }}>
                {t("common:total")}
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading && ![per_month_by_office_id].length && (
              <tr>
                <td key="no results" colSpan={5}>
                  {t("noResultsFound")}
                </td>
              </tr>
            )}
            {Object.entries(per_month_by_office_id).map(([office_id, office_monthly], index) => (
              <tr key={`office_${office_id}`}>
                <td key={`office_${office_id}_name`} className="text-left">
                  {office_monthly.name}
                </td>
                {/*  <td key={`office_${office_id}_drafts`} className="draft">  */}
                {/*  {total_per_office[office_id].drafts}  */}
                {/*  </td>  */}
                <td key={`office_${office_id}_data`} colSpan={months.length + colspan_extra} style={{ padding: 0 }}>
                  <table>
                    <tbody className="nested">
                      <tr>
                        <td
                          key={`office_${office_id}_acc_activated`}
                          style={{ borderTop: 0, borderLeft: 0, minWidth: smallWidth, width: smallWidth }}
                        >
                          {stats_before_time_period_by_office_id[office_id].activated}
                        </td>
                        {months.map((month) => (
                          <td
                            key={`office_${office_id}_activated_${month}`}
                            style={{ borderTop: 0, width: generalWidth }}
                          >
                            {per_month_by_office_id[office_id][month]?.activated || 0}
                            {showPercentage && (
                              <span>{` ${percentage_company_selection_data[office_id].active ?? "-"}%`}</span>
                            )}
                          </td>
                        ))}
                        <td
                          key={`office_${office_id}_total_activated`}
                          className="total-right-activated"
                          style={{ borderTop: 0, borderRight: 0, width: generalWidth + 20 }}
                        >
                          {total_per_office[office_id].activated}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data[office_id].active ?? "-"}%`}</span>
                          )}
                        </td>
                      </tr>
                      <tr style={{ border: 0 }}>
                        <td
                          key={`office_${office_id}_acc_tt`}
                          style={{ borderBottom: 0, borderLeft: 0, width: smallWidth }}
                        >
                          {stats_before_time_period_by_office_id[office_id].tt_only_activated}
                        </td>
                        {months.map((month) => (
                          <td key={`office_${office_id}_tt_${month}`} style={{ borderBottom: 0, width: generalWidth }}>
                            {per_month_by_office_id[office_id][month]?.tt_only_activated || "0"}
                            {showPercentage && (
                              <span>{` ${percentage_company_selection_data[office_id].tt_only_active ?? "-"}%`}</span>
                            )}
                          </td>
                        ))}
                        <td
                          key={`office_${office_id}_total_tt`}
                          className="total-right-activated-tt-only"
                          style={{ borderBottom: 0, borderRight: 0, width: generalWidth + 20, background: "#BC7BBF" }}
                        >
                          {total_per_office[office_id].tt_only_activated}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data[office_id].tt_only_active ?? "-"}%`}</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td key="total-bottom" className="text-left total-separator">
                {t("common:total")}
              </td>
              {/*  <td  */}
              {/*  style={{ minWidth: smallWidth, width: smallWidth }}  */}
              {/*  key="total-drafts"  */}
              {/*  className="total-separator draft"  */}
              {/*  >  */}
              {/*  {total_drafts}  */}
              {/*  </td>  */}
              <td
                key="total-separator"
                className="total-separator"
                colSpan={months.length + colspan_extra}
                style={{ padding: 0 }}
              >
                <table className="stats-table">
                  <tbody className="nested">
                    <tr className="highlighted">
                      <td key="total-bottom-acc-activated" style={{ borderLeft: 0, width: smallWidth }}>
                        {total.before_time_period_activated}
                      </td>
                      {months.map((month) => (
                        <td key={`total-activated-bottom_${month}`} style={{ width: generalWidth }}>
                          {total_per_month[month]?.activated || "0"}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data.total_avg_active ?? "-"}%`}</span>
                          )}
                        </td>
                      ))}
                      <td
                        key="total-acctivated-bottom"
                        className="total-right-activated"
                        style={{ borderRight: 0, width: generalWidth + 20 }}
                      >
                        {total.activated}
                        {showPercentage && (
                          <span>{` ${percentage_company_selection_data.total_avg_active ?? "-"}%`}</span>
                        )}
                      </td>
                    </tr>
                    <tr className="highlighted" style={{ border: 0 }}>
                      <td key="total-bottom-acc-tt" style={{ borderBottom: 0, borderLeft: 0, width: smallWidth }}>
                        {total.before_time_period_activated_tt_only}
                      </td>
                      {months.map((month) => (
                        <td key={`total-tt-bottom_${month}`} style={{ borderBottom: 0, width: generalWidth }}>
                          {total_per_month[month]?.tt_only_activated || "0"}
                          {showPercentage && (
                            <span>{` ${percentage_company_selection_data.total_avg_tt_only_active ?? "-"}%`}</span>
                          )}
                        </td>
                      ))}
                      <td
                        key="total-tt-bottom"
                        className="total-right-activated-tt-only"
                        style={{ borderBottom: 0, borderRight: 0, width: generalWidth + 20 }}
                      >
                        {total.tt_only_activated}
                        {showPercentage && (
                          <span>{` ${percentage_company_selection_data.total_avg_tt_only_active ?? "-"}%`}</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tfoot>
        </Table>
        <Row>
          <Col className="text-right" style={{ color: "#5DB870" }}>
            <span>
              <i className="fas fa-square" /> {t("common:statuses.full")}
            </span>
          </Col>
          <Col className="text-left" style={{ color: "#BC7BBF" }}>
            <span>
              <i className="fas fa-square" /> {t("common:statuses.basic")}
            </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default StatsCompanyPage;
