import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";

import { BExpandedTable } from "components/tables/btable";
import SubOfficeEditWidget from "components/forms/office-settings-forms/SubOfficeEditWidget";
import Filters from "./OfficeFilters";

function SubOfficeExtendedTable({ officeId }) {
  const { t } = useTranslation("company");
  const headers = useMemo(
    () => [
      {
        field: "name",
        label: t("common:name"),
      },
      {
        field: "manager_name",
        label: t("common:officeManager"),
      },
    ],
    [t]
  );

  return (
    <Row>
      <Col>
        <BExpandedTable
          headers={headers}
          FiltersComponent={<Filters />}
          renderExpandedRow={(row) => <SubOfficeEditWidget officeId={officeId} subOffice={row} />}
        />
      </Col>
    </Row>
  );
}

export default SubOfficeExtendedTable;
