import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";

import { formatMoney } from "utils/money";
import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import useModal from "hooks/useModal";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { getNextMonths } from "./helpers";

function SalaryTable({ data, onUpdate, t }) {
  const months = getNextMonths(3);
  const salaryModal = useModal();

  const handleClose = (values) => {
    salaryModal.close();
    if (values) {
      onUpdate(values, salaryModal.data.index);
    }
  };

  return (
    <>
      <tr className="t-wrap t-header">
        <th>
          <div className="t-row">
            <div className="t-col">{t("common:employee")}</div>
            {months.map((month) => (
              <div key={month.name} className="t-col text-right">
                {month.name}
              </div>
            ))}
            <div className="t-col">
              <div className="td-addon-actions">
                <Button variant="secondary" size="xs" onClick={() => salaryModal.open({ item: null, index: null })}>
                  <i className="fas fa-plus" />
                </Button>
              </div>
            </div>
          </div>
        </th>
      </tr>
      {data.items.map((row, i) => (
        <tr key={`sal.${i}`} className="t-wrap">
          <th>
            <div className="t-row">
              <div className="t-col overflow-hidden" title={row.employee}>
                {row.employee}
              </div>
              <div className="t-col overflow-hidden text-right" title={formatMoney(row.amount1, 1, 1)}>
                {row.perm && <i className="fas fa-lock" />} {formatMoney(row.amount1, 1, 1)}
              </div>
              <div className="t-col overflow-hidden text-right" title={formatMoney(row.amount2, 1, 1)}>
                {formatMoney(row.amount2, 1, 1)}
              </div>
              <div className="t-col overflow-hidden text-right" title={formatMoney(row.amount3, 1, 1)}>
                {formatMoney(row.amount3, 1, 1)}
              </div>
              <div className="t-col">
                <div className="td-addon-actions">
                  <Button variant="secondary" size="xs" onClick={() => salaryModal.open({ item: row, index: i })}>
                    <i className="fas fa-pen" />
                  </Button>
                  {!row.perm && (
                    <Button variant="secondary" size="xs" onClick={() => onUpdate(null, i)}>
                      <i className="fas fa-trash" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </th>
        </tr>
      ))}
      <tr className="t-wrap">
        <th>
          <div className="t-row">
            <div className="t-col">{t("cash.totalGrossSalary")}</div>
            <div className="t-col text-right">{formatMoney(data.gross[0], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.gross[1], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.gross[2], 1, 1)}</div>
            <div className="t-col" />
          </div>
        </th>
      </tr>
      <tr className="t-wrap">
        <th>
          <div className="t-row">
            <div className="t-col">{t("cash.netSalary")}</div>
            <div className="t-col text-right">{formatMoney(data.net[0], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.net[1], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.net[2], 1, 1)}</div>
            <div className="t-col" />
          </div>
        </th>
        {data.weeksNet.map((amount, i) => (
          <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
        ))}
      </tr>
      <tr className="t-wrap">
        <th>
          <div className="t-row">
            <div className="t-col">{t("cash.personalTax")}</div>
            <div className="t-col text-right">{formatMoney(data.personal[0], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.personal[1], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.personal[2], 1, 1)}</div>
            <div className="t-col" />
          </div>
        </th>
        {data.weeksPersonal.map((amount, i) => (
          <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
        ))}
      </tr>
      <tr className="t-wrap">
        <th>
          <div className="t-row">
            <div className="t-col">{t("cash.socSec")}</div>
            <div className="t-col text-right">{formatMoney(data.socSec[0], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.socSec[1], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.socSec[2], 1, 1)}</div>
            <div className="t-col" />
          </div>
        </th>
        {data.weeksSocSec.map((amount, i) => (
          <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
        ))}
      </tr>
      <tr className="t-wrap">
        <th>
          <div className="t-row">
            <div className="t-col">{t("cash.totalSalaryCost")}</div>
            <div className="t-col text-right">{formatMoney(data.total[0], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.total[1], 1, 1)}</div>
            <div className="t-col text-right">{formatMoney(data.total[2], 1, 1)}</div>
            <div className="t-col" />
          </div>
        </th>
      </tr>
      {salaryModal.show && <SalaryModal data={salaryModal.data.item} handleClose={handleClose} />}
    </>
  );
}

function SalaryModal({ data, handleClose }) {
  const { t } = useTranslation("reports");
  const isPerm = data ? data.perm : false;
  const formikProps = {
    initialValues: data
      ? {
          ...data,
        }
      : {
          employee: "",
          amount1: null,
          amount2: null,
          amount3: null,
        },
    validationSchema: yup.object().shape({
      employee: yup.string().required(),
      amount1: yup.string().nullable().required(),
      amount2: yup.string().nullable().required(),
      amount3: yup.string().nullable().required(),
    }),
    onSubmit: async (values) => {
      if (!values) {
        handleClose(null);
      } else {
        handleClose({
          ...values,
        });
      }
    },
  };
  return (
    <Modal show onHide={handleClose} size="sm" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("cash.salariesSim")}</Modal.Title>
      </Modal.Header>
      <Formik {...formikProps}>
        {({ isSubmitting }) => (
          <Form>
            <Modal.Body>
              <FormGroup.Input type="text" required label={t("common:employee")} name="employee" />
              <FormGroup.MoneyInput
                required
                label={`1. ${t("common:money.amount")} (Ksek)`}
                name="amount1"
                decimalPlaces={1}
                disabled={isPerm}
              />
              <FormGroup.MoneyInput
                required
                label={`2. ${t("common:money.amount")} (Ksek)`}
                name="amount2"
                decimalPlaces={1}
              />
              <FormGroup.MoneyInput
                required
                label={`3. ${t("common:money.amount")} (Ksek)`}
                name="amount3"
                decimalPlaces={1}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" variant="secondary" onClick={() => handleClose(undefined)}>
                {t("common:actions.cancel")}
              </Button>
              <SubmitButton isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default SalaryTable;
