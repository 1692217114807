import React from "react";
import { Card, Table } from "react-bootstrap";

import { HintButton } from "components/ui/buttons";
import { formatMoney } from "utils/money";

function YearToDateTable({ data, t }) {
  return (
    <Card>
      <Card.Body className="p-0">
        <Table responsive>
          <thead>
            <tr>
              <th>{t("dashboard.yearToDate")}</th>
              <th className="text-right">SEK</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {t("dashboard.income")}
                <HintButton hint={t("dashboard.hintIncome")} />
              </td>
              <td className="text-right">{formatMoney(data.income.sum, 0, 0)}</td>
            </tr>
            <tr>
              <td>
                {t("dashboard.expenses")}
                <HintButton hint={t("dashboard.hintExpenses")} />
              </td>
              <td className="text-right">{formatMoney(data.expenses.sum, 0, 0)}</td>
            </tr>
            <tr>
              <td>
                {t("dashboard.result")}
                <HintButton hint={t("dashboard.hintResult")} />
              </td>
              <td className="text-right">{formatMoney(data.result, 0, 0)}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default YearToDateTable;
