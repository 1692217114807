import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as contractAPI from "api/contracts";
import { RocketLoader } from "components/ui/loaders";
import ContractInvoiceForm from "components/forms/ContractInvoiceForm";

function ContractInvoiceEditPage() {
  const company = useOutletContext();
  const { id: contractId } = useParams();
  const navigate = useNavigate();
  const { loading, item } = useInitialAsync(() => {
    return contractAPI.contracts.details(company.id, contractId);
  });

  const onUpdate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }
  const connectedProducts = item.connected_products || {};
  delete item.connected_products;
  return (
    <ContractInvoiceForm
      invoice={item}
      company={company}
      onSave={onUpdate}
      invoiceConnectedProducts={connectedProducts}
    />
  );
}

export default ContractInvoiceEditPage;
