import client from "./client";

export function approve(companyId, ids) {
  return client.patch(`/companies/${companyId}/company-time-logs/approve_many/`, { ids });
}

export const comment = {
  list(companyId, logId) {
    return client.get(`/companies/${companyId}/time-logs/${logId}/comments/`, {
      params: { page_size: 100 },
    });
  },
  remove(companyId, logId, commentId) {
    return client.delete(`/companies/${companyId}/time-logs/${logId}/comments/${commentId}/`);
  },
  create(companyId, logId, text) {
    return client.post(`/companies/${companyId}/time-logs/${logId}/comments/`, {
      text,
    });
  },
};
