import React from "react";
import { Card } from "react-bootstrap";
import { useParams, useOutletContext } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { DirectPaymentTable } from "components/tables";
import * as tableAPI from "api/table";

function DirectPaymentListPage({ initialMode, allowedModes, selectable = false, tableId = "dirtPayments" }) {
  const { mode } = useParams();
  const company = useOutletContext();
  const initialFilters = {
    mode: initialMode || mode || "",
  };

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => tableAPI.companyDirectPayments(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <DirectPaymentTable
            companyId={company.id}
            selectable={selectable}
            forStatus={initialFilters.mode}
            allowedModes={allowedModes}
          />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default DirectPaymentListPage;
