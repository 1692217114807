import React from "react";

function EventComponent({ event }) {
  return (
    <div className="fc-event-main-frame">
      {event.extendedProps.companyName && <div className="fc-event-time">{event.extendedProps.companyName}</div>}
      <div className="fc-event-title-container">
        <div className="fc-event-title">{event.title}</div>
      </div>
    </div>
  );
}

export default EventComponent;
