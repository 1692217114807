import React from "react";

import * as companyAPI from "api/company";
import useTable from "components/tables/btable/useTable";
import { withInitialAsync } from "hooks/useAsync";
import BankRuleForm from "./BankRuleForm";

function BankRuleEditWidget({ data: item, companyId, id, companyAccounts }) {
  const {
    dataActions: { reload, updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };
  // pull first item and set as main
  const firstRule = item.rules.shift();
  const bankRule = {
    ...item,
    main: firstRule || { percent: 100, account_ids: [], charge: "debit" },
    rules: item.rules,
  };

  return (
    <BankRuleForm
      companyId={companyId}
      bankRule={bankRule}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
      companyAccounts={companyAccounts}
    />
  );
}

const EnhancedBankRuleEditWidget = withInitialAsync(
  BankRuleEditWidget,
  ({ companyId, id }) =>
    React.useCallback((cancelToken) => companyAPI.bankRules.details(companyId, id, { cancelToken }), [companyId, id]),
  {},
  false
);

export default EnhancedBankRuleEditWidget;
