import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomerForm from "components/forms/CustomerForm";
import * as billectaAPI from "api/billecta";
import { withInitialAsync } from "hooks/useAsync";
import { DraggableModalDialog } from "../DraggableModalDialog";

function CustomerAddModal({ data: { next }, companyId, handleClose, onSuccess }) {
  const { t } = useTranslation("ci");

  const customer = {
    Description: "",
    Name: "",
    OrgNo: "",
    DebtorType: "Private",
    DebtorNo: `${next}`,
    IsActive: true,
    UnitPrice: null,
    Units: "",
    VAT: 0,
    ArticleNumber: "",
    Address: "",
    Address2: "",
    ZipCode: "",
    Email: [],
    ContactName: "",
    DefaultActionConfig: { OurReference: "", DeliveryMethod: "Email" },
    City: "",
    GLN: "",
    CountryCode: "SE",
    VatNumber: "",
  };

  return (
    <Modal show onHide={handleClose} size="xl" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.addCustomer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerForm companyId={companyId} customer={customer} successCallback={onSuccess} />
      </Modal.Body>
    </Modal>
  );
}

const EnhancedCustomerAddModal = withInitialAsync(
  CustomerAddModal,
  ({ companyId }) =>
    React.useCallback((cancelToken) => billectaAPI.getNextCustomerNumber(companyId, { cancelToken }), [companyId]),
  {},
  false
);

export default EnhancedCustomerAddModal;
