import client from "api2/client";
import { parseISO } from "date-fns";
import _ from "lodash";

export function companyOutlays(companyId, filters, config) {
  return client.get(`/outlays/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function receiptDetails(companyId, invoiceId, config) {
  return client
    .get(`/outlays/receipt/${invoiceId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      const outlay = {
        ...response.data,
        receipt_date: response.data.receipt_date ? parseISO(response.data.receipt_date) : null,
        booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
      };
      if (outlay.account === 6072) {
        if (_.isEmpty(outlay.representation)) {
          outlay.representation = {
            activity: 1,
            purpose: 1,
            participants: [],
          };
        }
      }
      return outlay;
    });
}

export function mileageDetails(companyId, invoiceId, config) {
  return client
    .get(`/outlays/mileage/${invoiceId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => ({
      ...response.data,
      booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
    }));
}

export function reInvoice(companyId, outlayId, values, config) {
  return client.post(`/outlays/${outlayId}/reinvoice/`, values, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function prelim(companyId, outlayId, config) {
  return client.patch(`/outlays/${outlayId}/prelim/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function dispute(companyId, outlayId, values, config) {
  return client.patch(`/outlays/${outlayId}/dispute/`, values, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function approve(companyId, outlayId, params, config) {
  return client.patch(`/outlays/${outlayId}/approve/`, null, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function approveUpdate(companyId, data, config) {
  return client.patch(`/outlays/receipt/${data.id}/update-prelim/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function rescanInvoice(companyId, outlayId, config) {
  return client.get(`/outlays/receipt/${outlayId}/ocr-scan/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function receiptSave(companyId, data, config) {
  if (data.id) {
    return client.put(`/outlays/receipt/${data.id}/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/outlays/receipt/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function mileageSave(companyId, data, config) {
  if (data.id) {
    return client.put(`/outlays/mileage/${data.id}/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/outlays/mileage/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function remove(companyId, outlayId, params, config) {
  return client.delete(`/outlays/${outlayId}/delete/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function pay(companyId, outlayId, bankIDToken, config) {
  return client.post(
    `/outlays/${outlayId}/payments/pay-bgc/`,
    {
      bank_id_token: bankIDToken,
    },
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function manualPay(companyId, outlayId, data, config) {
  return client.post(`/outlays/${outlayId}/payments/pay-manual/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function cancelPayment(companyId, outlayId, config) {
  return client.delete(`/outlays/${outlayId}/payments/cancel/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function moveDraft(companyId, outlayId, config) {
  return client.patch(`/outlays/${outlayId}/draft/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
