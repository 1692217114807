import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { SearchFilter } from "components/filters";

function OfferFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const path = useResolvedPath("").pathname;
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
      </section>
      <section className="table-filters-right">
        <Link to={`${path}/form`} className="btn btn-first">
          <i className="fas fa-plus" /> {t("common:actions.create")}
        </Link>
      </section>
    </>
  );
}

export default OfferFilters;
