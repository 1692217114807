import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import HistoryFilters from "./HistoryFilters";
import { formatDatetime } from "../../../utils/date";

function HistoryTable({ companyId }) {
  const { t } = useTranslation("history");

  const headers = useMemo(
    () => [
      {
        field: "created",
        label: t("common:dates.date"),
        render: (values) => formatDatetime(values.created),
      },
      {
        field: "user_name",
        label: t("common:user"),
      },
      {
        field: "section_name",
        label: t("history:partOfSystem"),
        sortField: "section",
      },
      {
        field: "full_log",
        label: t("history:log"),
        className: "div-rows-in-cell",
        canSort: false,
        render: (values) => values.full_log.join("\n"),
      },
    ],
    [t]
  );

  return <BTable headers={headers} FiltersComponent={<HistoryFilters companyId={companyId} />} />;
}

export default HistoryTable;
