import { useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";

export function IndeterminateCheckbox({
  name,
  label,
  onChange,
  parentShowMore,
  toggleSection,
  disabled = false,
  indeterminate = false,
  header = false,
  tdProps,
  ...props
}) {
  const [field, , helpers] = useField({ name, ...props });
  const checkboxRef = useRef(null);
  const [showMore, setShowMore] = useState(parentShowMore);
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  useEffect(() => {
    setShowMore(parentShowMore);
  }, [parentShowMore]);

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    helpers.setValue(isChecked);
    if (onChange) {
      onChange(event, isChecked);
    }
  };

  const renderIcon = () => {
    if (header) {
      return (
        <i
          onClick={toggleSection}
          className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
          style={{ paddingRight: "30px", marginLeft: "-25px" }}
        />
      );
    }
    return null;
  };

  return (
    <td {...tdProps} className={cx(tdProps?.className)}>
      <div className="custom-control custom-checkbox">
        {renderIcon()}
        <input
          ref={checkboxRef}
          type="checkbox"
          className="custom-control-input"
          id={`checkbox_${name}`}
          checked={field.value}
          onChange={handleCheckboxChange}
          disabled={disabled}
          style={{ marginRight: "55px" }}
        />
        <label className="custom-control-label" htmlFor={`checkbox_${name}`}>
          {label}
        </label>
      </div>
    </td>
  );
}
