import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuthState } from "hooks/useAuth";
import { startPages } from "api/options";
import BankIDLoginForm from "./BankIDLoginForm";

function BankIDLoginPage() {
  const { authenticated, user } = useAuthState();
  const location = useLocation();

  if (authenticated) {
    const isConsult = user.is_consult || user.is_superuser;
    let startPageCode;
    if (isConsult) {
      startPageCode = Object.keys(startPages.paths_by_code).includes(user.start_page) // includes used to just to be safe
        ? user.start_page
        : startPages.code_for.officeSupport; // if smt will go wrong the redirect to office support
    } else {
      // regular users should always be redirected to Accounting
      startPageCode = startPages.code_for.accounting;
    }
    const { from } = location.state || { from: { pathname: startPages.paths_by_code[startPageCode] } };
    return <Navigate to={from} />;
  }
  const initialSSN = localStorage.getItem("ssn") || "";
  return <BankIDLoginForm initialSSN={initialSSN} />;
}

export default BankIDLoginPage;
