import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { PreviewButton, TooltipActionButton } from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import * as silverAPI from "api/silverback";
import useTable from "components/tables/btable/useTable";
import { formatDate } from "utils/date";
import useModal from "hooks/useModal";

function SilverbackActions({ companyId, row }) {
  const { t } = useTranslation("common");
  const {
    dataActions: { reload },
  } = useTable();
  const fileModal = useModal();

  const openFileModal = () => {
    fileModal.open(`/trash/${row.id}/file/`);
  };

  const onDelete = async () => {
    const answer = await confirmRemove(
      t("others:confirm.removeSilverback", {
        date: formatDate(row.meeting_date),
      })
    );
    if (answer) {
      silverAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  return (
    <>
      <PreviewButton
        link={`/consult/silverback/${row.id}/change`}
        text={t("common:documents")}
        onClick={openFileModal}
        icon="fas fa-images"
      />
      <TooltipActionButton variant="danger" text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
    </>
  );
}

export default SilverbackActions;
