import React from "react";

import useInitialAsync from "hooks/useInitialAsync";
import * as consultAPI from "api/consult";
import { Loader } from "components/ui/loaders";
import useTable from "components/tables/btable/useTable";
import GlobalSupplierForm from "./GlobalSupplierForm";

function GlobalSupplierEditWidget({ id }) {
  const { loading, item } = useInitialAsync(() => consultAPI.getGlobalSupplierDetails(id));
  const {
    dataActions: { reload, updateRow },
  } = useTable();

  if (loading) {
    return <Loader />;
  }

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };

  return <GlobalSupplierForm supplier={item} successCallback={successCallback} deleteCallback={deleteCallback} />;
}

export default GlobalSupplierEditWidget;
