import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import EmployeeAbsenceListPage from "./EmployeeAbsenceListPage";

function ApproveEmployeeAbsenceListPage() {
  const canApprove = HasPermCode(codesFor.absences.approve);
  return <EmployeeAbsenceListPage tableId="prelimAbsences" initialMode="prelim" selectable={canApprove} />;
}

export default ApproveEmployeeAbsenceListPage;
