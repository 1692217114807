import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { ProjectExtendedTable } from "components/tables";
import * as tableAPI from "api/table";
import { ProjectAddWidget } from "components/forms/company-settings-forms/ProjectForm";
import { CodeRequiredInList } from "components/perms";
import { codesForCompanySettings } from "components/perms/PermCodes";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";

function ProjectListPage({ tableId = "projects" }) {
  const { companyId } = useParams();
  const initialFilters = {
    mode: "ongoing",
  };
  const { userPermCodes } = useContext(CompanySettingStateContext);

  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => tableAPI.companyProjects(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <ProjectExtendedTable companyId={companyId} />
        </Card.Body>
      </Card>
      <CodeRequiredInList code={codesForCompanySettings.edit} codeList={userPermCodes}>
        <ProjectAddWidget companyId={companyId} />
      </CodeRequiredInList>
    </TableProvider>
  );
}

export default ProjectListPage;
