import React from "react";

function PreviewInput({ label, value, defaultValue = "-" }) {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div>{value || defaultValue}</div>
    </div>
  );
}

function PreviewCheckbox({ checked, label }) {
  return (
    <div className="checkbox custom-control checkbox-primary">
      <input type="checkbox" className="d-none" defaultChecked={checked} />
      <label className="custom-control-label">{label}</label>
    </div>
  );
}

export { PreviewInput, PreviewCheckbox };
