import React from "react";

import * as officeSupportApi from "api/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportInternalForm from "./OfficeSupportInternalForm";

function OfficeSupportInternalWidget({ data: item, companyId, changeEditedForm }) {
  return <OfficeSupportInternalForm companyId={companyId} changeEditedForm={changeEditedForm} initialData={item} />;
}

const EnhancedOfficeSupportInternalWidget = withInitialAsync(
  OfficeSupportInternalWidget,
  ({ companyId }) =>
    React.useCallback(
      (cancelToken) => officeSupportApi.internalInformation.get(companyId, { cancelToken }),
      [companyId]
    ),
  [],
  false,
  true
);

export default EnhancedOfficeSupportInternalWidget;
