import React from "react";
import { NavLink, useResolvedPath } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HasPermCode } from "components/perms";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { useCompanyState } from "hooks/useCompany";

function InvoicingNavTabs() {
  const { t } = useTranslation("navigation");
  const path = useResolvedPath("").pathname;
  const {
    company: { offer_order_enabled, contract_invoice_enabled },
  } = useCompanyState();

  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      <li className="nav-item">
        <NavLink to={`${path}/customer-invoices`} className="nav-link">
          {t("customerInvoices")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/customers`} className="nav-link">
          {t("customers")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/products`} className="nav-link">
          {t("products")}
        </NavLink>
      </li>
      {HasPermCode(codesForCustomerInvoices.contractInvoices) && contract_invoice_enabled && (
        <>
          <li className="nav-item">
            <NavLink to={`${path}/contract-invoices`} className="nav-link">
              {t("contractInvoices")}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`${path}/contract-templates`} className="nav-link">
              {t("contractTemplates")}
            </NavLink>
          </li>
        </>
      )}
      {HasPermCode(codesForCustomerInvoices.offerAndOrder) && offer_order_enabled && (
        <li className="nav-item">
          <NavLink to={`${path}/offer-order`} className="nav-link">
            {t("offerAndOrder")}
          </NavLink>
        </li>
      )}
    </ul>
  );
}

export default InvoicingNavTabs;
