import React, { useState, useEffect } from "react";
import { useField } from "formik";
import FormGroup from "components/formik/FormGroup";
import { Row, Col } from "react-bootstrap";

function OtherInputSelect({ name, options, otherLabel, otherName, otherPlaceholder, showInline = true, ...props }) {
  const [field, , helpers] = useField({ name, ...props });
  const initialIsOther = field.value && field.value.value === "other";
  const [showOtherInput, setShowOtherInput] = useState(initialIsOther);

  const handleSelectChange = (selected) => {
    helpers.setValue(selected);
    setShowOtherInput(selected.value === "other");
    if (props.onChange) {
      props.onChange(selected);
    }
  };
  useEffect(() => {
    setShowOtherInput(field.value && field.value.value === "other");
  }, [field.value]);

  if (showInline) {
    return (
      <Row>
        <Col>
          <FormGroup.SimpleSelect name={name} options={options} onChange={handleSelectChange} {...props} />
        </Col>
        <Col>
          {showOtherInput && (
            <FormGroup.Input name={otherName} label={otherLabel} placeholder={otherPlaceholder} required />
          )}
        </Col>
      </Row>
    );
  }
  return (
    <div>
      <FormGroup.SimpleSelect name={name} options={options} onChange={handleSelectChange} {...props} />
      {showOtherInput && (
        <FormGroup.Input name={otherName} label={otherLabel} placeholder={otherPlaceholder} required />
      )}
    </div>
  );
}

export default OtherInputSelect;
