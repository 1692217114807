import React from "react";
import { Button } from "react-bootstrap";

function LedgerRowActions({ hasSpec, isChecked, handleAddVerToSpec, handleVerCheck }) {
  return (
    <>
      <Button variant={hasSpec ? "info" : "outline-info"} size="sm" onClick={handleAddVerToSpec}>
        <i className="fas fa-plus" />
      </Button>
      <Button size="sm" variant={isChecked ? "info" : "outline-info"} onClick={handleVerCheck}>
        <i className="fas fa-check" />
      </Button>
    </>
  );
}

export default LedgerRowActions;
