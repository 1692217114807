import React, { useEffect, useState } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as archiveAPI from "api/archive";
import { DocumentBox } from "components/ui/documents";
import { DraggableModalDialog } from "../DraggableModalDialog";
import "./FileArchiveDocumentModal.scss";
import FileArchiveSwitcher from "../../forms/FileArchiveForm/FileArchiveSwitcher";

function FileArchiveDocumentModal({ handleClose, handleOpen, companyId, archiveId, filters, connectConfig }) {
  const { t } = useTranslation("common");
  const [title, setTitle] = useState("");
  useEffect(() => {
    archiveAPI.archiveDocument.details(companyId, archiveId).then((response) => {
      setTitle(response.title);
    });
  }, [companyId, archiveId]);
  const customOpen = (newArchiveId) => {
    handleClose();
    handleOpen(newArchiveId);
  };
  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton className="custom-modal-header">
        <Modal.Title className="m-0">{t("documents")}</Modal.Title>
      </Modal.Header>
      <Modal.Header className="modal-header-control">
        <div className="fileName">{title}</div>
        <Row>
          <Col />
          <Col className="text-center">
            {connectConfig && (
              <Button
                variant={connectConfig.isDocChecked(archiveId) ? "outline-danger" : "outline-primary"}
                className="mt-1"
                onClick={() => {
                  connectConfig.onArchiveDocConnectSwitch(archiveId);
                }}
              >
                {connectConfig.isDocChecked(archiveId) ? t("common:actions.disconnect") : t("common:actions.connect")}
              </Button>
            )}
          </Col>
          <Col />
        </Row>
        <FileArchiveSwitcher
          companyId={companyId}
          archiveId={archiveId}
          customOpen={customOpen}
          passedFilters={filters}
        />
      </Modal.Header>
      <Modal.Body>
        {archiveId && (
          <DocumentBox partUrl="archive-documents" companyId={companyId} documentId={archiveId} removable={false} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default FileArchiveDocumentModal;
