import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import FileArchiveForm from "components/forms/FileArchiveForm";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";

function FileArchiveAddPage() {
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const company = useOutletContext();
  const navigate = useNavigate();
  const archive = {
    title: "",
    description: "",
  };

  const onCreate = (newArchive) => {
    navigate(-1);
    reloadMenuNumbers();
  };

  return <FileArchiveForm companyId={company.id} archive={archive} onSave={onCreate} />;
}

export default FileArchiveAddPage;
