import React from "react";

import * as officeSupportApi from "api/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportRolesTaxAgencyAttorneyForm from "./OfficeSupportRolesTaxAgencyAttorneyForm";

function OfficeSupportRolesTaxAgencyAttorneyWidget({ data: item, companyId, changeEditedForm }) {
  return (
    <OfficeSupportRolesTaxAgencyAttorneyForm
      companyId={companyId}
      changeEditedForm={changeEditedForm}
      initialData={item}
    />
  );
}

const EnhancedOfficeSupportRolesTaxAgencyAttorneyWidget = withInitialAsync(
  OfficeSupportRolesTaxAgencyAttorneyWidget,
  ({ companyId }) =>
    React.useCallback(
      (cancelToken) => officeSupportApi.rolesTaxAgencyAttorney.get(companyId, { cancelToken }),
      [companyId]
    ),
  [],
  false,
  true
);

export default EnhancedOfficeSupportRolesTaxAgencyAttorneyWidget;
