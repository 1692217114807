import React, { useEffect } from "react";
import cx from "classnames";

import { useCompanyDispatch } from "hooks/useCompany";
import NavBar from "components/ui/navbar";
import CompanyListMenu from "./CompanyListMenu";

function OnboardingWizardLayout({ companyId, companyName, children }) {
  const { reset } = useCompanyDispatch();

  useEffect(() => {
    reset();
  }, [reset]);

  return (
    <div className="company-list">
      <NavBar companyMenu={false} company={{ id: companyId, name: companyName }} forDashboard={false} />
      <CompanyListMenu />
      <div className={cx("content-page")}>
        <div className="content pt-3">
          <div className="container-fluid">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingWizardLayout;
