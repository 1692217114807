import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import Menu from "./Menu";

function LeftSidebar({ children, extraDeps }) {
  return (
    <div className="left-side-menu">
      <Scrollbars autoHide className="left-side-scroll">
        <div>
          <Menu extraDeps={extraDeps}>{children}</Menu>
        </div>
      </Scrollbars>
    </div>
  );
}

export default LeftSidebar;
