import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { SupplierExtendedTable } from "components/tables";
import * as tableAPI from "api/table";
import { SupplierAddWidget } from "components/forms/SupplierForm";
import { PermCodeRequired } from "components/perms";
import { useOutletContext } from "react-router-dom";
import { codesForSupplierInvoices } from "components/perms/PermCodes";

function SupplierListPage({ selectable = false, tableId = "suppliers" }) {
  const company = useOutletContext();
  const initialFilters = { is_active: true };

  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => tableAPI.companySuppliers(company.id, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <SupplierExtendedTable companyId={company.id} selectable={selectable} forStatus={initialFilters.mode} />
        </Card.Body>
      </Card>
      <PermCodeRequired code={codesForSupplierInvoices.manage}>
        <SupplierAddWidget companyId={company.id} />
      </PermCodeRequired>
    </TableProvider>
  );
}

export default SupplierListPage;
