import client from "./client";

// export function details(companyId, reportId) {
//   return client.get(`/companies/${companyId}/absence-reports/${reportId}/`).then((response) => ({
//     ...response.data,
//   }));
// }

// export function approve(companyId, reportId) {
//   return client.patch(`/companies/${companyId}/absence-reports/${reportId}/approve/`);
// }
//
// export function dispute(companyId, reportId, values) {
//   return client.patch(`/companies/${companyId}/absence-reports/${reportId}/dispute/`, values);
// }
//
// export const comment = {
//   list(companyId, reportId) {
//     return client.get(`/companies/${companyId}/absences/${reportId}/comments/`, { params: { page_size: 100 } });
//   },
//   remove(companyId, reportId, commentId) {
//     return client.delete(`/companies/${companyId}/absences/${reportId}/comments/${commentId}/`);
//   },
//   create(companyId, reportId, text) {
//     return client.post(`/companies/${companyId}/absences/${reportId}/comments/`, { text });
//   },
// };

export function logs(reportId) {
  return client.get(`/absence-history/${reportId}/`);
}
