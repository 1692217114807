import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useResolvedPath } from "react-router-dom";
import useTable from "components/tables/btable/useTable";
import { MonthDateFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import { FilterButton } from "components/ui/buttons";
import * as options from "api/options";
import { PermCodeRequired } from "components/perms";
import { codesForSalaries } from "components/perms/PermCodes";

function SalaryArchiveFilters({ companyId }) {
  const archiveCategories = options.salaryArchiveCategories.asList();
  const { filters, setFilters } = useTable();
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation("common");
  const filtersCount = !!filters.financial_year + !!filters.category + !!filters.month;
  const [more, setMore] = useState(!!filtersCount);
  return (
    <PermCodeRequired code={codesForSalaries.archive}>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />
      </section>
      <section className="table-filters-right">
        <Link to={`${url}/form`} className="btn btn-first">
          <i className="fas fa-plus" /> {t("common:actions.create")}
        </Link>
      </section>
      {more && (
        <section className="more-filters">
          <SelectSimpleFilter
            name="category"
            label={t("common:category")}
            options={archiveCategories}
            isClearable
            onFilter={setFilters}
            defaultValue={filters.category}
          />
          <MonthDateFilter
            name="month"
            label={t("common:dates.month")}
            defaultValue={filters.month}
            isClearable
            onChange={(d) => setFilters({ ...filters, month: d })}
          />
        </section>
      )}
    </PermCodeRequired>
  );
}

export default SalaryArchiveFilters;
