import React from "react";
import { truncateText } from "utils/text";
import { Button } from "react-bootstrap";
import cx from "classnames";

function NavBarButton({ icon, rightIcon, text, className, ...btnProps }) {
  return (
    <Button variant="link" onClick={() => {}} className={cx("nav-btn", className)} {...btnProps}>
      {icon && <i className={icon} />}
      <span>{truncateText(text, 25)}</span>
      {rightIcon && <i className={rightIcon} />}
    </Button>
  );
}

export default NavBarButton;
