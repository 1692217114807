import React from "react";
import { formatDatetime } from "utils/date";
import { useTranslation } from "react-i18next";

function CommentBox({ comments, userId, onRemove }) {
  const { t } = useTranslation("common");

  if (!comments.length) {
    return <NoResultsFound text={t("noResultsFound")} />;
  }

  return (
    <div className="comments">
      {comments.map((comment, i) => (
        <article key={`comment.${comment.id}`} className="comments-item">
          <h4 className="mt-0 mb-0 font-16">
            {comment.created_by_name}
            {comment.created_by === userId && !!onRemove && (
              <button
                type="button"
                className="btn btn-xs btn-remove btn-outline-danger"
                onClick={() => onRemove(i, comment)}
              >
                {t("actions.remove")}
              </button>
            )}
          </h4>
          <p className="text-muted">
            <small>{formatDatetime(comment.created)}</small>
          </p>
          <p className="mb-0">{comment.text}</p>
        </article>
      ))}
    </div>
  );
}

function NoResultsFound({ text }) {
  return (
    <div className="comments">
      <article className="comments-item">
        <p className="mb-0">{text}</p>
      </article>
    </div>
  );
}

export default CommentBox;
