import React, { useContext } from "react";

import useInitialAsync from "hooks/useInitialAsync";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import * as statsAPI from "api/stats";
import { RocketLoader } from "components/ui/loaders";
import { Navigate, useOutletContext } from "react-router-dom";
import { PermCodeRequired } from "components/perms";
import { codesForReports } from "components/perms/PermCodes";
import HomeContent from "./HomeContent";

function HomePage({ company }) {
  const { financialYear } = useContext(FinancialYearStateContext);
  const financialYearId = financialYear ? financialYear.id : null;
  const { loading, item: data } = useInitialAsync(
    ({ cancelToken }) => statsAPI.dashboardReport(company.id, { financial_year: financialYearId }, { cancelToken }),
    {},
    [financialYearId]
  );
  if (loading) {
    return <RocketLoader />;
  }

  return (
    <div style={{ paddingTop: 24 }}>
      <HomeContent data={data} financialYear={financialYear} />
    </div>
  );
}

function HomePageWrapper() {
  const company = useOutletContext();
  if (company.ttOnly) {
    return <Navigate to="archive/company-archive" />;
  }
  return (
    <PermCodeRequired code={codesForReports.enabled}>
      <HomePage company={company} />
    </PermCodeRequired>
  );
}

export default HomePageWrapper;
