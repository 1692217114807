import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import SupplierInvoiceListPage from "./SupplierInvoiceListPage";

function PaySupplierInvoiceListPage() {
  const canPay = HasPermCode(codesFor.supplierInvoices.pay);
  return <SupplierInvoiceListPage tableId="unpaidSupplierInvoices" initialMode="unpaid" selectable={canPay} />;
}

export default PaySupplierInvoiceListPage;
