import { NavLink } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

function ManagementDashboardNavTabs() {
  const { t } = useTranslation("navigation");
  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      <li className="nav-item">
        <NavLink to="/management/overview" className="nav-link">
          {t("ongoingAndOverview")}
        </NavLink>
      </li>
    </ul>
  );
}

export default ManagementDashboardNavTabs;
