import React from "react";

function PageNotFound() {
  return (
    <div className="page-not-found">
      <h1>404 - Page not found</h1>
    </div>
  );
}

export default PageNotFound;
