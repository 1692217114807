import React from "react";
import { Alert, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { SubmitButton } from "components/ui/buttons";
import { FormGroup } from "components/formik";
import { DraggableModalDialog } from "../DraggableModalDialog";

function DisputeModal({ handleClose, onSubmit }) {
  const { t } = useTranslation("common");
  const formikProps = {
    initialValues: {
      comment: "",
    },
    validationSchema: yup.object().shape({
      comment: yup.string().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      onSubmit(values).catch((error) => {
        setErrors(error.data);
      });
    },
  };
  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Formik {...formikProps}>
        {({ errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="m-0">{t("actions.dispute")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormGroup.Input as="textarea" rows={3} label={t("comment")} name="comment" required />
              {errors && errors.__all__ && <Alert variant="danger">{errors.__all__}</Alert>}
            </Modal.Body>
            <Modal.Footer>
              <SubmitButton title="actions.dispute" />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default DisputeModal;
