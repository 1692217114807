import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CompanyVATPeriodWrapper from "./CompanyVATPeriodWrapper";
import { DraggableModalDialog } from "../../../modals/DraggableModalDialog";

function VATPeriodModal({ companyId, handleClose }) {
  const { t } = useTranslation("company");

  return (
    <Modal
      show
      onHide={handleClose}
      scrollable
      backdrop="static"
      keyboard={false}
      dialogAs={DraggableModalDialog}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ width: "100%", textAlign: "center" }}>{t("modal.title.changeVatPeriod")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CompanyVATPeriodWrapper companyId={companyId} />
      </Modal.Body>
    </Modal>
  );
}

export default VATPeriodModal;
