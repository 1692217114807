import PropTypes from "prop-types";

export const PERMISSION_MODULE_CHILD_TYPE = PropTypes.shape({
  label: PropTypes.shape({
    en: PropTypes.string.isRequired,
    sv: PropTypes.string.isRequired,
  }),
  code: PropTypes.string.isRequired,
  roles: PropTypes.shape({
    employee: PropTypes.string.isRequired,
  }),
});

export const PERMISSION_MODULE_TYPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  label: PropTypes.shape({
    en: PropTypes.string.isRequired,
    sv: PropTypes.string.isRequired,
  }),
  children: PropTypes.arrayOf(PERMISSION_MODULE_CHILD_TYPE),
});
