import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { AllError, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import ConditionalOverlayTrigger from "components/ui/OverlayTriggers/ConditionalOverlayTrigger";
import * as companyAPI from "api/company";
import * as options from "api/options";

function GeneralForm({ company }) {
  const { t } = useTranslation("company");
  const euVATChoices = options.euVATChoices.asList();
  const reconciliationPeriods = options.reconciliationPeriods.asList();
  const vatReportOptions = options.vatReportOptions.asList();
  const companyTypes = options.companyTypes.asList();
  const ttOnly = company.status === "basic";
  const formikProps = {
    initialValues: {
      ...company,
      invoice_receive_email: ttOnly ? "" : company.invoice_receive_email,
    },
    validationSchema: yup.object(),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await companyAPI
        .saveCompanyGeneralData({
          ...values,
          id: company.id,
          company_type: values.company_type.value,
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, touched, errors }) => {
        return (
          <Form>
            <Card className="pt-2">
              <Card.Body>
                <Row xl={4} lg={2}>
                  <Col>
                    <ConditionalOverlayTrigger placement="bottom">
                      <FormGroup.Input label={t("common:companyName")} name="name" disabled />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col>
                    <ConditionalOverlayTrigger placement="bottom">
                      <FormGroup.Input label={t("common:orgNo")} name="org_no" disabled />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col>
                    <FormGroup.Input label={t("smsNotificationPhone")} name="sms_notification_phone" />
                  </Col>
                </Row>
                <Row className="mt-3" xl={4} lg={2}>
                  <Col>
                    <ConditionalOverlayTrigger placement="bottom" useSpan>
                      <FormGroup.LabeledCheckbox
                        title={t("generalTax")}
                        label={t("registeredGeneralTax")}
                        name="registered_aga"
                        disabled
                      />
                    </ConditionalOverlayTrigger>
                    <ConditionalOverlayTrigger placement="bottom" useSpan>
                      <FormGroup.LabeledCheckbox
                        title={t("companyTax")}
                        label={t("approvedCompanyTax")}
                        name="approved_company_tax"
                        disabled
                      />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col>
                    <ConditionalOverlayTrigger placement="bottom" useSpan>
                      <FormGroup.LabeledRadio
                        title={t("companyType")}
                        name="company_type"
                        options={companyTypes}
                        disabled
                      />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col>
                    <ConditionalOverlayTrigger placement="bottom" useSpan>
                      <FormGroup.LabeledRadio
                        title={t("vatReport")}
                        name="vat_report_period"
                        options={vatReportOptions}
                        disabled
                      />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col>
                    <FormGroup.LabeledRadio
                      title={t("reconciliationPeriod")}
                      name="reconciliation_period"
                      options={reconciliationPeriods}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col />
                  <Col />
                  <Col />
                  <Col>
                    <FormGroup.LabeledRadio title={t("euVAT")} name="eu_vat" options={euVATChoices} />
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default GeneralForm;
