import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import ReactDatePicker from "react-datepicker";

function BankMatchingMonthDateFilter({ label, defaultValue, onChange, highlightedMonths }) {
  const [selectedDate, setSelectedDate] = useState(defaultValue);

  const renderMonth = (month, shortMonth, longMonth) => {
    const data = highlightedMonths.find((item) => item.month === month + 1 && selectedDate.getFullYear() === item.year);
    const isSelected = selectedDate && selectedDate.getMonth() === month && selectedDate.getFullYear() === data?.year;
    const className = data && !isSelected ? "bank-matching" : "";
    return (
      <span className={className}>
        {shortMonth}
        {data && <span className="bank-matching-count">[{data.count}]</span>}
      </span>
    );
  };

  const handleYearChange = (date) => {
    const newDate = new Date(selectedDate);
    newDate.setFullYear(date.getFullYear());
    setSelectedDate(newDate);
    onChange(newDate);
  };

  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <ReactDatePicker
        className="form-control"
        selected={selectedDate}
        dateFormat={["MM/yyyy"]}
        onChange={(date) => {
          setSelectedDate(date);
          onChange(date);
        }}
        onYearChange={(date) => handleYearChange(date)}
        showMonthYearPicker
        renderMonthContent={(month, shortMonth, longMonth) => renderMonth(month, shortMonth, longMonth)}
        popperProps={{
          positionFixed: true, // use this to make the popper position: fixed
        }}
        popperClassName="bank-matching-react-datepicker-popper"
      />
    </Form.Group>
  );
}

export default BankMatchingMonthDateFilter;
