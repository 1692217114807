import React, { useState } from "react";
import { Button, ButtonGroup, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import useTable from "components/tables/btable/useTable";
import { scrollToSection } from "utils/others";
import { ConfirmButton } from "components/ui/buttons";
import * as companyAPI from "api/company";
import TTActivationInput from "./TTActivationInput";

function RepeatedTaskFilters({ companyId }) {
  const [loading, setLoading] = useState(false);
  const [isDateValid, setIsDateValid] = useState(true);
  const { t } = useTranslation(["common", "msg"]);

  const {
    filters,
    setFilters,
    dataActions: { reload },
  } = useTable();

  const autoInitialize = () => {
    setLoading(true);
    companyAPI
      .initialRepeatedTasks(companyId)
      .then(() => {
        toast.success(t("msg:generated"));
        reload();
      })
      .catch((error) => {
        toast.error(error.data.__all__ || t("msg:canNotExecuteAction"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid>
      <Row className="justify-content-between">
        <Col className="table-filters-left" as="section" xs={12} lg={4}>
          <ButtonGroup aria-label="Status filter" className="status-filter">
            <Button
              variant="outline-secondary"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="outline-success"
              active={filters.mode === "active"}
              onClick={() => setFilters({ mode: "active" })}
            >
              {t("common:statuses.active")}
            </Button>
            <Button
              variant="outline-warning"
              active={filters.mode === "inactive"}
              onClick={() => setFilters({ mode: "inactive" })}
            >
              {t("common:statuses.inactive")}
            </Button>
          </ButtonGroup>
        </Col>
        <Col className="table-filters-right" as="section" xs={12} lg="auto">
          <div>
            <TTActivationInput companyId={companyId} setIsDateValid={setIsDateValid} isDateValid={isDateValid} />
            {!isDateValid && <div className="text-danger">{t("common:errors.required")}</div>}
          </div>
          <ConfirmButton
            onClick={autoInitialize}
            disabled={loading}
            variant="second"
            confirmMessage={t("company:confirm.ttSchemaInitialize")}
            icon="fa-thin fa-check"
            label={t("common:actions.autoFill")}
            className="filter-button"
          />
          <Button
            onClick={() => scrollToSection("create-tt-config")}
            variant="outline-secondary"
            className="filter-button"
          >
            <i className="fas fa-plus fa-xs" /> {t("common:actions.create")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default RepeatedTaskFilters;
