import PropTypes from "prop-types";
import React from "react";

import { Pagination } from "react-bootstrap";

function paginationGenerator(current, last, width = 2) {
  const left = current - width;
  const right = current + width + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= last; i += 1) {
    if (i === 1 || i === last || (i >= left && i <= right)) {
      range.push(i);
    } else if (i < left) {
      i = left - 1;
    } else if (i > right) {
      range.push(last);
      break;
    }
  }

  range.forEach((i) => {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push("...");
      }
    }
    rangeWithDots.push(i);
    l = i;
  });

  return rangeWithDots;
}

function BPagination({ pagination: { page, hasPrevious, hasNext, setPage, count } }) {
  if (count === 0) {
    return null;
  }
  const pages = paginationGenerator(page, count);
  return (
    <Pagination style={{ float: "right" }}>
      <Pagination.Prev disabled={!hasPrevious} onClick={() => setPage(page - 1)}>
        ⟨
      </Pagination.Prev>
      {pages.map((p, i) =>
        Number.isInteger(p) ? (
          <Pagination.Item key={p} active={p === page} onClick={() => setPage(p)}>
            {p}
          </Pagination.Item>
        ) : (
          <Pagination.Ellipsis disabled key={`e-${i}`} />
        )
      )}
      <Pagination.Next disabled={!hasNext} onClick={() => setPage(page + 1)}>
        ⟩
      </Pagination.Next>
    </Pagination>
  );
}

BPagination.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    hasPrevious: PropTypes.bool.isRequired,
    hasNext: PropTypes.bool.isRequired,
    setPage: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
  }),
};

export default BPagination;
