import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import _ from "lodash";

import * as companyAPI from "api/company";
import AdminForm, { ExtraActions } from "components/forms/company-settings-forms/AdminForm";

import { withInitialAsync } from "hooks/useAsync";

function AdminFormPage({ data: item }) {
  const { companyId } = useParams();
  const ttOnly = item.info.status === "basic";

  const accounts = item.accounts.map((account) => ({
    id: account.number,
    value: account.number,
    label: `${account.number} - ${account.name}`,
  }));

  const bgcAccounts = {
    asList: accounts,
    byId: _.keyBy(accounts, (account) => account.id),
  };

  return (
    <>
      <Row>
        <Col>
          <AdminForm ttOnly={ttOnly} company={item.info} bgcAccounts={bgcAccounts} />
        </Col>
      </Row>
      {!ttOnly && <ExtraActions companyId={companyId} />}
    </>
  );
}

const EnhancedAdminFormPage = withInitialAsync(
  AdminFormPage,
  () => {
    const { companyId } = useParams();
    return React.useCallback(
      (cancelToken) => companyAPI.getCompanyConsultData(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedAdminFormPage;
