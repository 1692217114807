import React from "react";

import logoBlueSrc from "assets/images/bflow-logo-blue.png";
import { GetMainPageUrl } from "utils/layout";

function LogoBox() {
  return (
    <div className="logo-box">
      <a href={GetMainPageUrl()} className="logo">
        <span className="logo-lg">
          <img src={logoBlueSrc} alt="" height="40" />
        </span>
        <span className="logo-sm">
          <img src={logoBlueSrc} alt="" height="24" />
        </span>
      </a>
    </div>
  );
}

export default LogoBox;
