import { PERMISSION_ROLES } from "utils/permissions/permissions";
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { CHECKBOX_CLASSES } from "utils/permissions/constants";
import { handleParentCheckboxChange, mapCodesToSingleModule } from "utils/permissions/helpers";
import { PERMISSION_MODULE_TYPE } from "./models";

const { checked, partiallyChecked, allowed } = CHECKBOX_CLASSES;

function ParentCheckBoxes({ permissionModule, permsChecked, setPermsChecked, withExpandClass, expandParentRow }) {
  const {
    children: childPerms,
    label,
    id,
    enabled: { code: enabledCode },
  } = permissionModule;

  const {
    i18n: { language },
  } = useTranslation();

  return (
    <tr className={cx("parent", withExpandClass)} key={id}>
      <td onClick={expandParentRow}>
        <button className="arrow-btn" type="button">
          {withExpandClass ? <i className="fe-chevron-up" /> : <i className="fe-chevron-down" />}
        </button>
        <span>{label[language]}</span>
      </td>

      {PERMISSION_ROLES.map((role) => {
        const { codesMappedToRole, foundCodes } = mapCodesToSingleModule(childPerms, permsChecked, role);

        const mappedLength = codesMappedToRole?.length;
        const isAllowedToCheck = mappedLength > 0;

        const foundCodesLength = foundCodes?.length;
        const isSomethingChecked = foundCodesLength > 0;

        const forAllChecked = foundCodesLength === mappedLength && isSomethingChecked ? checked : "";
        const forPartiallyChecked = isSomethingChecked ? partiallyChecked : "";
        const forAllowed = isAllowedToCheck ? allowed : "";

        const styles = forAllChecked || forPartiallyChecked || forAllowed;

        return (
          <td key={role}>
            <div className={cx("checkbox-wrapper", styles)}>
              <button
                onClick={() =>
                  handleParentCheckboxChange(role, setPermsChecked, isSomethingChecked, codesMappedToRole, enabledCode)
                }
                type="button"
                className="checkbox"
              />
            </div>
          </td>
        );
      })}
    </tr>
  );
}

ParentCheckBoxes.propTypes = {
  permissionModule: PERMISSION_MODULE_TYPE,
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
  withExpandClass: PropTypes.string.isRequired,
  expandParentRow: PropTypes.func.isRequired,
};

export default React.memo(ParentCheckBoxes);
