import React from "react";

import * as officeSupportApi from "api/office-support";
import { withInitialAsync } from "hooks/useAsync";
import OfficeSupportRolesEssentialForm from "./OfficeSupportRolesEssentialForm";

function OfficeSupportRolesEssentialWidget({ data: item, companyId, changeEditedForm }) {
  return (
    <OfficeSupportRolesEssentialForm companyId={companyId} changeEditedForm={changeEditedForm} initialData={item} />
  );
}

const EnhancedOfficeSupportRolesEssentialWidget = withInitialAsync(
  OfficeSupportRolesEssentialWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => officeSupportApi.rolesEssential.get(companyId, { cancelToken }), [companyId]),
  [],
  false,
  true
);

export default EnhancedOfficeSupportRolesEssentialWidget;
