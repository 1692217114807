import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DraggableModalDialog } from "../DraggableModalDialog";

function ConfirmModal({
  description,
  giveAnswer,
  btnTitle = "actions.yesExecute",
  cancelBtnTitle = "actions.cancel",
  isSafeAction = false,
}) {
  const { t } = useTranslation("common");
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => giveAnswer(null)}
      dialogClassName="confirmation-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.areYouSure")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={() => giveAnswer(false)}>
          {t(cancelBtnTitle)}
        </Button>
        <Button type="button" variant={isSafeAction ? "primary" : "danger"} onClick={() => giveAnswer(true)}>
          <span>
            {!isSafeAction && <i className="fas fa-exclamation mr-1" />} {t(btnTitle)}
          </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
