import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import * as companyAPI from "api/company";
import { BankConnectionContext } from "state/providers/BankConnectionProvider";
import { Loader } from "components/ui/loaders";
import { useTranslation } from "react-i18next";
import BankAccountList from "./BankAccountList";
import "./ConnectionResults.scss";

function ConnectionResult({ handleClose }) {
  const { t } = useTranslation("company");
  const { companyId, consentId, showError } = useContext(BankConnectionContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ accounts: [] });
  const getAccounts = useCallback(() => {
    if (!companyId || !consentId) {
      showError("");
      return null;
    }
    return companyAPI.psd2
      .consentDetails(companyId, consentId)
      .then((response) => {
        if (!response.data.accounts.length) {
          showError(t("psd.connectedButNoAccounts", { bankName: t(`options:bank.${response.data.bic_fi}`) }));
        } else {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error.data && error.data.__all__) {
          showError(error.data.__all__);
        } else {
          showError("");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId, consentId, showError, t]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getAccounts();
    }, 1000);
    return () => clearTimeout(timeout);
  }, [getAccounts]);
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="connection-results">
      <div className="title">{t("msg:successfullyConnectedTo", { bank: t(`options:bank.${data.bic_fi}`) })}</div>
      <div className="subtitle">{t("common:actions.choosePaymentAccount")}:</div>
      <BankAccountList consentId={consentId} companyId={companyId} accounts={data.accounts} switchDisabled={false} />
      <Button type="button" onClick={handleClose}>
        {t("common:actions.finish")} <i className="fe-chevron-right" />
      </Button>
    </div>
  );
}

export default ConnectionResult;
