import React, { useCallback, useEffect, useRef, useState } from "react";
import { BankIDLoader, Loader } from "components/ui/loaders";
import * as companyAPI from "api/company";
import { formatDate } from "utils/date";

function DecoupledSigning({ payment, updatePayment, companyId }) {
  const statusRef = useRef(false);
  const [item, setItem] = useState({
    ...payment,
    paymentId: null,
    state: "",
    error: "",
    qrCode: "",
    info: "",
    loading: true,
  });
  const checkSignStatus = useCallback(
    (paymentId) => {
      return companyAPI.singlePayment.checkSignStatus(companyId, paymentId).then((response) => {
        if (response.data.status === -1) {
          updatePayment({ error: response.data.error, state: "fail" });
        } else if (response.data.status === 1) {
          companyAPI.singlePayment.checkPayment(companyId, paymentId).then((response2) => {
            updatePayment({ error: response2.data.error, state: response2.data.state });
          });
        }
        return response.data;
      });
    },
    [companyId, updatePayment]
  );

  useEffect(() => {
    companyAPI.singlePayment
      .create(companyId, {
        verification: payment.id,
        payment_date: formatDate(payment.payment_date),
      })
      .then((response) => {
        checkSignStatus(response.data.payment_id).then((data) => {
          if (data.stage !== "") {
            setItem((_item) => ({
              ..._item,
              info: data.info,
              paymentId: response.data.payment_id,
              qrCode: response.data.image,
              loading: false,
            }));
          }
        });
      })
      .catch((response) => {
        updatePayment({ error: response.data.error, state: "fail" });
      });
  }, [companyId, payment.id, payment.payment_date, checkSignStatus, updatePayment]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!statusRef.current && item.paymentId) {
        statusRef.current = true;
        checkSignStatus(item.paymentId)
          .then((data) => {
            if (data.status === 0) {
              setItem((_item) => ({
                ..._item,
                info: data.info,
                image: data.image ? data.image : _item.image,
              }));
            } else {
              clearInterval(interval);
            }
          })
          .finally(() => {
            statusRef.current = false;
          });
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [item.paymentId, checkSignStatus]);

  if (item.loading) {
    return <Loader />;
  }
  return (
    <div className="stage-decoupled">
      <div className="title">{item.info}</div>
      {item.info && !item.qrCode && <BankIDLoader bankIdLogo text={false} />}
      {item.qrCode && <img src={item.qrCode} alt="SCAN ME" />}
    </div>
  );
}

export default DecoupledSigning;
