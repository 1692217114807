import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";

import { formatMoney } from "utils/money";
import * as options from "api/options";
import useModal from "hooks/useModal";
import { SubmitButton } from "components/ui/buttons";
import { FormGroup } from "components/formik";
import { formatDate, parseDate } from "utils/date";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";

const cashflowFreqs = options.cashflowFreqs.asDict();
const freqList = options.cashflowFreqs.asList();

function AddonTable({ t, data, onUpdate }) {
  const addonModal = useModal();

  const handleClose = (values) => {
    addonModal.close();
    if (values) {
      onUpdate(values, addonModal.data.index);
    }
  };

  return (
    <>
      <tr className="t-wrap t-header">
        <th>
          <div className="t-row">
            <div className="t-col">{t("common:comment")}</div>
            <div className="t-col text-right">{t("common:money.amount")}</div>
            <div className="t-col text-center">{t("common:dates.dueDate")}</div>
            <div className="t-col text-center">{t("cash.freq")}</div>
            <div className="t-col">
              <div className="td-addon-actions">
                <Button variant="secondary" size="xs" onClick={() => addonModal.open({ item: null, index: null })}>
                  <i className="fas fa-plus" />
                </Button>
              </div>
            </div>
          </div>
        </th>
      </tr>
      {data.items.map((row, i) => (
        <tr key={`addon.in.${i}`} className="t-wrap">
          <th>
            <div className="t-row">
              <div className="t-col overflow-hidden" title={row.item.text}>
                {row.item.text}
              </div>
              <div className="t-col overflow-hidden text-right" title={formatMoney(row.item.amount, 1, 1)}>
                {formatMoney(row.item.amount, 1, 1)}
              </div>
              <div className="t-col text-center">{row.item.due_date}</div>
              <div className="t-col text-center overflow-hidden" title={cashflowFreqs[row.item.repeat]}>
                {cashflowFreqs[row.item.repeat]}
              </div>
              <div className="t-col">
                <div className="td-addon-actions">
                  <Button variant="secondary" size="xs" onClick={() => addonModal.open({ item: row.item, index: i })}>
                    <i className="fas fa-pen" />
                  </Button>
                  <Button variant="secondary" size="xs" onClick={() => onUpdate(null, i)}>
                    <i className="fas fa-trash" />
                  </Button>
                </div>
              </div>
            </div>
          </th>
          {row.weeks.map((amount, idx) => (
            <td key={idx}>{formatMoney(amount, 1, 1, "-")}</td>
          ))}
        </tr>
      ))}
      {!data.items.length && (
        <tr className="t-wrap">
          <th>{t("common:noResultsFound")}</th>
        </tr>
      )}
      {addonModal.show && <AddonModal data={addonModal.data.item} handleClose={handleClose} />}
    </>
  );
}

function AddonModal({ data, handleClose }) {
  const { t } = useTranslation("reports");
  const formikProps = {
    initialValues: data
      ? {
          ...data,
          due_date: parseDate(data.due_date),
          repeat: options.cashflowFreqs.getOption(data.repeat),
        }
      : {
          repeat: freqList[0],
          due_date: null,
          text: "",
          amount: null,
        },
    validationSchema: yup.object().shape({
      amount: yup.string().nullable().required(),
      due_date: yup.date().nullable().required(),
      text: yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (!values) {
        handleClose(null);
      } else {
        handleClose({
          ...values,
          due_date: formatDate(values.due_date),
          repeat: values.repeat.value,
        });
      }
    },
  };
  return (
    <Modal show onHide={handleClose} size="sm" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("cash.myAddons")}</Modal.Title>
      </Modal.Header>
      <Formik {...formikProps}>
        {({ isSubmitting }) => (
          <Form>
            <Modal.Body>
              <FormGroup.SimpleSelect label={t("cash.freq")} name="repeat" options={freqList} />
              <FormGroup.DatePicker required label={t("common:dates.dueDate")} name="due_date" />
              <FormGroup.MoneyInput
                required
                label={`${t("common:money.amount")} (Ksek)`}
                name="amount"
                decimalPlaces={1}
              />
              <FormGroup.Input type="text" required label={t("common:comment")} name="text" />
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" variant="secondary" onClick={() => handleClose(undefined)}>
                {t("common:actions.cancel")}
              </Button>
              <SubmitButton isSubmitting={isSubmitting} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddonTable;
