import { useContext } from "react";
import _ from "lodash";
import { TableContext } from "./provider";

function useTable() {
  const { state, dispatch, tableId } = useContext(TableContext);

  function setPage(pageNumber) {
    dispatch({
      type: "SET_PAGE",
      payload: pageNumber,
    });
  }

  function setFilters(newFilter) {
    return dispatch({
      type: "SET_FILTERS",
      payload: newFilter,
    });
  }

  function setOrdering(isSorted, field, desc) {
    if (_.isArray(field)) {
      field = field.map((f) => `${desc ? "-" : ""}${f}`);
      return setFilters({
        ordering: isSorted ? `${field}` : null,
      });
    }
    return setFilters({
      ordering: isSorted ? `${desc ? "-" : ""}${field}` : null,
    });
  }

  function reload() {
    return dispatch({ type: "RELOAD" });
  }

  function updateRow(newRow, idKey = "id") {
    return dispatch({ type: "UPDATE_ROW", payload: newRow, idKey });
  }

  function toggleExpand(index) {
    return dispatch({ type: "TOGGLE_EXPAND", index });
  }

  function toggleSelect(row) {
    return dispatch({ type: "TOGGLE_SELECT", row });
  }

  return {
    tableId,
    loading: state.loading,
    data: state.data,
    selected: state.selected,
    filters: state.filters,
    responseHeaders: state.responseHeaders,
    pagination: {
      ...state.pagination,
      setPage,
    },
    setFilters,
    setOrdering,
    toggleExpand,
    toggleSelect,
    dataActions: {
      reload,
      updateRow,
    },
  };
}

export default useTable;
