const calculateExpectedBudget = (budget) => {
  let sum = 0;
  Object.keys(budget).forEach((accountNumber) => {
    sum += budget[accountNumber].sum;
  });
  return sum;
};

const calculateExpectedBudgetMonthly = (budget, monthIndex) => {
  let sum = 0;
  Object.keys(budget).forEach((accountNumber) => {
    sum += budget[accountNumber].months[monthIndex] || 0;
  });
  return sum;
};

const calculateCategoryBudget = (budget, item) => {
  if (!item) {
    return 0;
  }
  let accounts;
  if (item.accounts) {
    accounts = item.accounts;
  } else if (item.child_categories) {
    accounts = item.child_categories.reduce((all, child) => [...all, ...(child.accounts || [])], []) || [];
  } else {
    return 0;
  }
  if (!accounts) {
    return 0;
  }
  return accounts.reduce((total, a) => (budget[a.number]?.sum || 0) + total, 0);
};

const calculateCategoryBudgetMonthly = (budget, item, monthIndex) => {
  if (!item) {
    return 0;
  }
  let accounts;
  if (item.accounts) {
    accounts = item.accounts;
  } else if (item.child_categories) {
    accounts = item.child_categories.reduce((all, child) => [...all, ...(child.accounts || [])], []) || [];
  } else {
    return 0;
  }
  if (!accounts) {
    return 0;
  }
  return accounts.reduce((total, a) => (budget[a.number]?.months[monthIndex] || 0) + total, 0);
};

const getExtraCatAccounts = (budget, categoryName) => {
  if (categoryName.includes("Brutt")) {
    return Object.keys(budget).filter((accountNumber) => accountNumber <= 4999);
  }
  if (categoryName.includes("Röre")) {
    return Object.keys(budget).filter((accountNumber) => accountNumber <= 7999);
  }
  if (categoryName.includes("Resultat e")) {
    return Object.keys(budget).filter((accountNumber) => accountNumber <= 8423);
  }
  return [];
};

const calculateExtraCatMonthly = (budget, category, monthIndex) => {
  const accountNumbers = getExtraCatAccounts(budget, category.extra_name || category.name);
  const amounts = accountNumbers.map((accountNumber) => budget[accountNumber].months[monthIndex]);
  return amounts.reduce((sum, next) => sum + next, 0);
};

const calculateExtraCatYearly = (budget, category) => {
  const accountNumbers = getExtraCatAccounts(budget, category.extra_name || category.name);
  const amounts = accountNumbers.map((accountNumber) => budget[accountNumber].sum);
  return amounts.reduce((sum, next) => sum + next, 0);
};

export {
  calculateExpectedBudget,
  calculateCategoryBudget,
  calculateCategoryBudgetMonthly,
  calculateExpectedBudgetMonthly,
  calculateExtraCatMonthly,
  calculateExtraCatYearly,
};
