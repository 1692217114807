import React, { useContext } from "react";
import * as tableAPI from "api/table";
import { Card } from "react-bootstrap";
import TableProvider from "components/tables/btable/provider";
import { ConsultTTReportTable } from "components/tables";
import { useAuthState } from "hooks/useAuth";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";

function TimeTrackerConsultReportPage({ tableId = "trackerConsultReport" }) {
  const { user } = useAuthState();
  const { selected: selectedOffice, options } = useContext(TimeTrackerStateContext);
  const initialFilters = {
    users: [user.id],
    companies: [],
    month_holder: new Date(),
  };

  return (
    <TableProvider
      tableId={tableId}
      initialFilters={initialFilters}
      dataSource={(filtersTable, config) =>
        tableAPI.trackerConsultReport({ ...filtersTable, office: selectedOffice.office }, config)
      }
    >
      <Card>
        <Card.Body>
          <ConsultTTReportTable options={options} />
        </Card.Body>
      </Card>
    </TableProvider>
  );
}

export default TimeTrackerConsultReportPage;
