import React from "react";
import useTable from "components/tables/btable/useTable";
import CustomerForm from "./CustomerForm";

function CustomerEditWidget({ companyId, customer }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(
      {
        ...newData,
        Email: newData.Email.join(";"),
        DebtorType: newData.DebtorType.value,
        Notes: {
          ...newData.Notes,
          PriceGroup: newData.Notes.PriceGroup.value,
        },
        DefaultActionConfig: {
          ...newData.DefaultActionConfig,
          DeliveryMethod: newData.DefaultActionConfig.DeliveryMethod.value,
          InvoicePaymentTermsInDays: newData.DefaultActionConfig.InvoicePaymentTermsInDays.value,
          CommunicationLanguage: newData.DefaultActionConfig.CommunicationLanguage.value,
        },
      },
      "DebtorPublicId"
    );
  };
  const customerData = {
    ...customer,
    OrgNo: customer.OrgNo || "",
    Address2: customer.Address2 || "",
    DebtorNo: customer.DebtorNo || "",
    Email: customer.Email ? customer.Email.replace(" ", "").split(";") : [],
    VatNumber: customer.VatNumber || "",
    GLN: customer.GLN || "",
    ContactName: customer.ContactName || "",
    Address: customer.Address || "",
    ZipCode: customer.ZipCode || "",
    City: customer.City || "",
    DefaultActionConfig: {
      ...customer.DefaultActionConfig,
      OurReference: customer.DefaultActionConfig.OurReference || "",
    },
  };
  return <CustomerForm companyId={companyId} customer={customerData} successCallback={successCallback} />;
}

export default CustomerEditWidget;
