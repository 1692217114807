import React, { useCallback, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import * as billectaAPI from "api/billecta";
import useTable from "components/tables/btable/useTable";
import { withInitialAsync } from "hooks/useAsync";
import CustomerForm from "./CustomerForm";

function CustomerAddWidget({ data: { next }, companyId }) {
  const { t } = useTranslation("ci");
  const nextCustomerNumber = useRef(next);
  const {
    dataActions: { reload },
  } = useTable();

  const onSuccess = useCallback(() => {
    return billectaAPI.getNextCustomerNumber(companyId).then((response) => {
      nextCustomerNumber.current = `${response.data.next}`;
      reload();
    });
  }, [companyId, reload]);

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("customer/removed", onSuccess);
    return () => document.body.removeEventListener("customer/removed", onSuccess);
  }, [onSuccess]);

  const customer = {
    Description: "",
    Name: "",
    OrgNo: "",
    DebtorType: "Private",
    DebtorNo: `${nextCustomerNumber.current || 1}`,
    IsActive: true,
    UnitPrice: null,
    Units: "",
    VAT: 0,
    ArticleNumber: "",
    Address: "",
    Address2: "",
    ZipCode: "",
    Email: [],
    ContactName: "",
    DefaultActionConfig: { OurReference: "", DeliveryMethod: "Email" },
    City: "",
    GLN: "",
    CountryCode: "SE",
    VatNumber: "",
  };

  return (
    <Row>
      <Col>
        <Card className="card-border" id="create-customer-section">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addCustomer")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <CustomerForm companyId={companyId} customer={customer} successCallback={onSuccess} resetOnSuccess />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

const EnhancedCustomerAddWidget = withInitialAsync(
  CustomerAddWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => billectaAPI.getNextCustomerNumber(companyId, { cancelToken }), [companyId]),
  {},
  false
);

export default EnhancedCustomerAddWidget;
