import React from "react";
import { PERMISSION_ROLES } from "utils/permissions/permissions";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { CHECKBOX_CLASSES } from "utils/permissions/constants";
import { handleGlobalCheckboxChange, mapCodesToAllModules } from "utils/permissions/helpers";
import { PERMISSION_MODULE_TYPE } from "./models";

const { checked, partiallyChecked, allowed } = CHECKBOX_CLASSES;

function GlobalCheckboxes({ permissionModules, permsChecked, setPermsChecked }) {
  const { t } = useTranslation("company");

  return PERMISSION_ROLES.map((role) => {
    const { codesMappedToRole, foundCodes, enabledCodes } = mapCodesToAllModules(permissionModules, permsChecked, role);

    const isSomethingChecked = foundCodes?.length > 0;

    const forAllChecked = foundCodes?.length === codesMappedToRole?.length && isSomethingChecked ? checked : "";
    const forPartiallyChecked = isSomethingChecked ? partiallyChecked : "";

    const styles = forAllChecked || forPartiallyChecked || allowed;

    return (
      <th key={role}>
        <div>
          <div className="label">{t(role)}</div>
          <div
            onClick={() =>
              handleGlobalCheckboxChange(setPermsChecked, isSomethingChecked, codesMappedToRole, enabledCodes)
            }
            className={cx("checkbox-wrapper", styles)}
          >
            <button type="button" className="checkbox" />
          </div>
        </div>
      </th>
    );
  });
}

GlobalCheckboxes.propTypes = {
  permissionModules: PropTypes.arrayOf(PERMISSION_MODULE_TYPE),
  permsChecked: PropTypes.arrayOf(PropTypes.string.isRequired),
  setPermsChecked: PropTypes.func.isRequired,
};

export default GlobalCheckboxes;
