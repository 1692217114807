import React from "react";
import { Button } from "react-bootstrap";

import { formatMoney } from "utils/money";
import * as options from "api/options";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { fillSI } from "./helpers";

const cashflowFreqs = options.cashflowFreqs.asDict();
const freqList = options.cashflowFreqs.asList();
const cashflowDelay = options.cashflowDelay.asDict();
const delayList = options.cashflowDelay.asList();

function Header({ title }) {
  const { t } = useTranslation("reports");
  return (
    <tr className="t-wrap t-header">
      <th>
        <div className="text-center pb-2">
          <strong>{t(title)}</strong>
        </div>
        <div className="t-row">
          <div className="t-col">{t("common:supplier")}</div>
          <div className="t-col text-right">{t("common:money.amount")}</div>
          <div className="t-col text-center">{t("common:dates.dueDate")}</div>
          <div className="t-col text-center">{t("cash.delay")}</div>
          <div className="t-col text-center">{t("cash.freq")}</div>
          <div className="t-col" />
        </div>
      </th>
    </tr>
  );
}

function SIRow({ t, row, onChange, isSimulation = false, index, addSim, remSim }) {
  return (
    <tr className={cx("t-wrap", row.type)}>
      <th>
        <div className="t-row t-si">
          <div className="t-col overflow-hidden" title={row.supplier}>
            {row.supplier}
          </div>
          <div className="t-col overflow-hidden text-right" title={formatMoney(row.amount, 1, 1)}>
            {formatMoney(row.amount, 1, 1)}
          </div>
          <div className="t-col text-center">{row.due_date}</div>
          <div className="t-col text-center overflow-hidden" title={cashflowDelay[row.delay]}>
            <select
              className="form-control"
              defaultValue={row.delay}
              onChange={(e) => onChange(e.target.value, "delay")}
            >
              {delayList.map((k) => (
                <option key={`d.${k.value}`} value={k.value}>
                  {k.label}
                </option>
              ))}
            </select>
          </div>
          <div className="t-col text-center overflow-hidden" title={cashflowFreqs[row.repeat]}>
            <select
              className="form-control"
              defaultValue={row.repeat}
              onChange={(e) => onChange(e.target.value, "repeat")}
            >
              {freqList.map((k) => (
                <option key={`f.${k.value}`} value={k.value}>
                  {k.label}
                </option>
              ))}
            </select>
          </div>
          <div className="t-col">
            <div className="td-addon-actions">
              {!isSimulation ? (
                <>
                  <Button
                    variant="success"
                    size="xs"
                    onClick={() => addSim(row, index, "sis")}
                    title="Single Invoice Simulation"
                  >
                    SIS
                  </Button>
                  <Button
                    variant="primary"
                    size="xs"
                    onClick={() => addSim(row, index, "lts")}
                    title="Long Term Simulation"
                  >
                    LTS
                  </Button>
                  <Button variant="secondary" size="xs" onClick={() => addSim(row, index, "nos")} title="No simulation">
                    NOS
                  </Button>
                </>
              ) : (
                <Button variant="secondary" size="xs" onClick={() => remSim(row, index)}>
                  {t("common:actions.remove")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </th>
      {row.weeks.map((amount, i) => (
        <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
      ))}
    </tr>
  );
}

function SITable({ t, data, weeksByNo, onUpdate }) {
  function onSimulationChange(newData, index, isSimulation) {
    const output = {
      untreated: [...data.untreated],
      simulation: [...data.simulation],
      weeks: data.weeks,
    };
    if (isSimulation) {
      output.simulation[index] = {
        ...newData,
        weeks: fillSI(newData, weeksByNo),
      };
    } else {
      output.untreated[index] = {
        ...newData,
        weeks: fillSI(newData, weeksByNo),
      };
    }
    onUpdate(output);
  }

  const addSim = (row, index, type) => {
    const output = {
      untreated: data.untreated.filter((item, i) => i !== index),
      simulation: [
        {
          ...data.untreated[index],
          type,
          weeks: type === "nos" ? data.weeks.map(() => 0) : row.weeks,
        },
        ...data.simulation,
      ],
      weeks: data.weeks,
    };
    onUpdate(output);
  };

  const remSim = (row, index) => {
    const output = {
      untreated: [{ ...data.simulation[index], type: "" }, ...data.untreated],
      simulation: data.simulation.filter((item, i) => i !== index),
      weeks: data.weeks,
    };
    onUpdate(output);
  };

  return (
    <>
      <Header title="cash.newUntreated" />
      {data.untreated.map((row, i) => (
        <SIRow
          t={t}
          row={row}
          key={`si.un.${row.id}`}
          index={i}
          addSim={addSim}
          remSim={remSim}
          onChange={(value, key) =>
            onSimulationChange(
              {
                ...row,
                [key]: value,
              },
              i,
              false
            )
          }
        />
      ))}
      {!data.untreated.length && (
        <tr className="t-wrap">
          <th>{t("common:noResultsFound")}</th>
        </tr>
      )}

      <Header title="cash.simulation" />
      {data.simulation.map((row, i) => (
        <SIRow
          row={row}
          key={`si.sim.${row.id}`}
          isSimulation
          index={i}
          remSim={remSim}
          t={t}
          onChange={(value, key) =>
            onSimulationChange(
              {
                ...row,
                [key]: value,
              },
              i,
              true
            )
          }
        />
      ))}
      {!data.simulation.length && (
        <tr className="t-wrap">
          <th>{t("common:noResultsFound")}</th>
        </tr>
      )}
    </>
  );
}

export default SITable;
