import React, { useEffect } from "react";

import NavBar from "components/ui/navbar";

function FilePreviewLayout({ children }) {
  useEffect(() => {
    document.body.classList.add("file-preview-page");
    return () => {
      document.body.classList.remove("file-preview-page");
    };
  });

  return (
    <div>
      <NavBar companyMenu={false} />
      <div className="content-page ml-0">
        <div className="content">
          <div className="container-fluid">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default FilePreviewLayout;
