import React from "react";

import useTable from "components/tables/btable/useTable";
import SubOfficeForm from "./SubOfficeForm";

function SubOfficeEditWidget({ officeId, subOffice }) {
  const {
    dataActions: { reload },
  } = useTable();

  return <SubOfficeForm officeId={officeId} subOffice={subOffice} onSuccess={reload} onDelete={reload} />;
}

export default SubOfficeEditWidget;
