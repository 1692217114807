import { saveAs } from "file-saver";

export function getFileNameDispositionContent(response, inline = false) {
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf(inline ? "inline" : "attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
  }
  return "";
}

export function saveBlobToComputer(response, defaultFileName, inline = false) {
  const fileName = getFileNameDispositionContent(response, inline) || defaultFileName;
  saveAs(response.data, fileName);
}
