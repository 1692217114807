import client from "./client";

export function getGlobalSupplierDetails(supplierId) {
  return client.get(`/suppliers/${supplierId}/`);
}

export function saveSupplier(data) {
  if (data.id) {
    return client.put(`/suppliers/${data.id}/`, {
      ...data,
    });
  }
  return client.post(`/suppliers/`, {
    ...data,
  });
}

export function getUser(userId, config) {
  return client.get(`/users/${userId}/`, config);
}

export function saveUser(data) {
  if (data.id) {
    return client.put(`/users/${data.id}/`, {
      ...data,
    });
  }
  return client.post(`/users/`, {
    ...data,
  });
}

export function saveOfficeUser(officeId, data) {
  if (data.id) {
    return client.put(`/offices/${officeId}/users/${data.id}/`, {
      ...data,
    });
  }
  return client.post(`/offices/${officeId}/users/`, {
    ...data,
  });
}

export function removeOfficeUser(officeId, userId) {
  return client.delete(`/offices/${officeId}/users/${userId}/`);
}

export function deleteUser(userId) {
  return client.delete(`/users/${userId}/`);
}

export function startUserResetPassword(userId, userEmail) {
  return client.post(`/users/${userId}/force_password_reset/`, {
    email: userEmail,
  });
}

export function getEventDetails(eventId) {
  return client.get(`/events/${eventId}/`);
}

export function saveEvent(data) {
  return client.post("/events/", data);
}

export function removeEvent(eventId) {
  return client.delete(`/events/${eventId}/`);
}

export function globalSearch(filters, config) {
  return client.get(`/search/`, { params: filters, ...config });
}

export function pullSupplierBGC(bgNumber) {
  return client.get("/suppliers/pull_from_bgc/", { params: { bgNumber } });
}
