import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import CompanyArchive from "components/forms/CompanyArchiveForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as archiveAPI from "api/archive";
import { RocketLoader } from "components/ui/loaders";

function CompanyArchiveEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: documentId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return archiveAPI.companyDocument.details(company.id, documentId);
  });

  const onUpdate = () => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <CompanyArchive companyId={company.id} archive={item} onSave={onUpdate} />;
}

export default CompanyArchiveEditPage;
