import React, { useCallback } from "react";
import _ from "lodash";

import * as billectaAPI from "api/billecta";
import useAsync from "hooks/useAsync";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EInvoiceReceiverModal from "components/modals/EInvoiceReceiverModal";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";
import { PermCodeRequired } from "components/perms";
import { codesForCustomerInvoices } from "components/perms/PermCodes";

function EInvoiceReceiverBox({ companyId, customerId, initialOrgNo, onConnect, onDisconnect }) {
  const { t } = useTranslation("common");
  const eInvoiceConnectModal = useModal();

  const dataSource = useCallback(
    (cancelToken) => billectaAPI.eInvoiceReceiverInfo(companyId, customerId, { cancelToken }),
    [customerId, companyId]
  );
  const [{ data: item, loading }, { updateData }] = useAsync(dataSource, {});

  const onSelected = (receiver) => {
    const data = {
      billecta_customer_id: customerId,
      inexchange_receiver_id: receiver.companyId,
      name: receiver.name,
      gln: receiver.gln,
      org_no: receiver.orgNo,
    };
    updateData(data);
    billectaAPI.eInvoiceReceiverSet(companyId, customerId, data).catch(() => {
      toast.error(t("msg:canNotExecuteAction"));
    });
    onConnect();
    eInvoiceConnectModal.close();
  };

  const clearReceiver = () => {
    updateData(null);
    billectaAPI.eInvoiceReceiverDestroy(companyId, customerId).catch(() => {
      toast.error(t("msg:canNotExecuteAction"));
    });
    onDisconnect();
  };

  return (
    <>
      <fieldset>
        <legend>E-Invoice receiver</legend>
        <div className="p-1 border-left">
          {!_.isEmpty(item) && (
            <div>
              <strong>{t("common:name")}:</strong> {item.name}
              <br />
              <strong>{t("common:orgNo")}:</strong> {item.org_no}
              <br />
              <strong>GLN/PEPPOL</strong> {item.gln}
              <br />
              <div className="text-success">{t("ci:eInvoiceReceiverAllowed")}</div>
              <Button size="sm" variant="second" className="mt-1" onClick={clearReceiver}>
                {t("common:actions.change")}
              </Button>
            </div>
          )}
          {!loading && _.isEmpty(item) && (
            <div>
              <div className="text-danger">{t("ci:eInvoiceReceiverMissing")}</div>
              <PermCodeRequired code={codesForCustomerInvoices.manage}>
                <Button
                  size="sm"
                  variant="primary"
                  className="mt-1"
                  onClick={() =>
                    eInvoiceConnectModal.open({
                      orgNo: initialOrgNo,
                    })
                  }
                >
                  {t("ci:eInvoiceReceiverConnect")}
                </Button>
              </PermCodeRequired>
            </div>
          )}
        </div>
      </fieldset>
      {eInvoiceConnectModal.show && (
        <EInvoiceReceiverModal
          companyId={companyId}
          handleClose={eInvoiceConnectModal.close}
          initialGln={eInvoiceConnectModal.data.gln}
          initialOrgNo={eInvoiceConnectModal.data.orgNo}
          onSelected={onSelected}
        />
      )}
    </>
  );
}

export default EInvoiceReceiverBox;
