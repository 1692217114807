import React from "react";
import { Alert, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import { formatMoney } from "utils/money";
import * as taxAPI from "api/taxes";
import { formatDate } from "utils/date";

function TaxDirectPaymentForm({ companyId, taxOCR, taxDirectPayment, onSave }) {
  const { t } = useTranslation("tax");
  const formikProps = {
    initialValues: {
      ...taxDirectPayment,
    },
    validationSchema: yup.object().shape({
      payment_date: yup.date().nullable().required(),
      amount: yup.number().positive().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      await taxAPI.taxDP
        .save(companyId, {
          booking_date: formatDate(values.booking_date),
          payment_date: formatDate(values.payment_date),
          amount: values.amount,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        const balanceAfter = values.balanceBefore + (values.amount || 0);
        return (
          <Form id="dpForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={3}>
                    <FormGroup.BookingDatePicker
                      name="payment_date"
                      label={t("common:dates.paymentDate")}
                      minDate={new Date()}
                    />
                  </Col>
                </Row>
                <Alert variant="info">
                  {t("common:money.balanceBefore")}: <strong>{formatMoney(values.balanceBefore)} SEK</strong>
                </Alert>
                <Row>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="amount" label={t("common:money.toBePaid")} required />
                  </Col>
                </Row>
                <Alert variant="info">
                  {t("common:money.balanceAfter")}: <strong>{formatMoney(balanceAfter)} SEK</strong>
                </Alert>
                <Alert variant="info">
                  OCR: <strong>{taxOCR}</strong>
                  <br />
                  BankGiro: <strong>5050-1055</strong>
                </Alert>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>{taxOCR && <SubmitButton isSubmitting={isSubmitting} />}</ButtonGroup>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default TaxDirectPaymentForm;
