import React, { useCallback } from "react";
import { Form } from "react-bootstrap";

import { BSelect } from "components/formik/pickers";
import { useCompanyState } from "hooks/useCompany";

function FinancialYearFilter({ label, name = "financial_year", defaultValue, onFilter, isClearable = true, ...props }) {
  const { financialYears } = useCompanyState();

  const Component = useCallback(() => {
    return (
      <BSelect
        defaultValue={financialYears.filter((option) => option.value === defaultValue)}
        options={financialYears}
        onChange={(selected) => onFilter({ [name]: selected ? selected.value : null })}
        isClearable={isClearable}
        {...props}
      />
    );
  }, [defaultValue, financialYears, isClearable, name, onFilter, props]);

  if (label) {
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        <Component />
      </Form.Group>
    );
  }
  return <Component />;
}

export default FinancialYearFilter;
