import React, { useMemo } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import useInitialAsync from "hooks/useInitialAsync";
import { Loader } from "components/ui/loaders";
import * as ciAPI from "api/customer-invoices";
import { Form, Formik } from "formik";
import { FormGroup, TableGroup } from "components/formik";
import { handleActionErrors } from "api/errors";
import { formatDate } from "utils/date";
import * as ReactDOM from "react-dom";
import { isFirstDayOfMonth, parseISO } from "date-fns";
import { SubmitButton } from "components/ui/buttons";
import cx from "classnames";
import * as yup from "yup";
import { formatMoney } from "../../../utils/money";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { periodisationAccounts } from "../../../utils/periodisation";
import { useCompanyState } from "../../../hooks/useCompany";

function CIPeriodisationModal({ companyId, invoiceId, handleClose, onSubmitSuccess, centerById, projectById }) {
  const { t } = useTranslation(["ci"]);
  const { loading, item: data } = useInitialAsync(() => ciAPI.getPeriodisation(companyId, invoiceId));

  return (
    <Modal show onHide={handleClose} size="xl" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("common:periodisation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && <Loader />}
        {!loading && data && (
          <>
            <PeriodisationRecordsForm
              companyId={companyId}
              invoiceId={invoiceId}
              data={data}
              handleClose={handleClose}
              onSubmitSuccess={onSubmitSuccess}
              t={t}
              centerById={centerById}
              projectById={projectById}
            />
            <br />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
function PeriodisationRecordsForm({
  companyId,
  invoiceId,
  data,
  handleClose,
  onSubmitSuccess,
  t,
  centerById,
  projectById,
}) {
  const {
    accounts: { byId: accountById, asOptions: accountOptions },
  } = useCompanyState();
  const periodisationAlreadySet = !!data.periodisation_date_start;
  const getToPeriodiseSum = (values) => {
    return values.records.reduce((sum, record) => {
      if (record.PeriodisationEnabled) {
        sum += record.ToPeriodiseAmount;
      }

      return sum;
    }, 0);
  };
  function filterPeriodisationAccounts(option) {
    return periodisationAccounts.includes(option.id);
  }
  const optionsPeriodisationAccount = useMemo(() => {
    return accountOptions.filter(filterPeriodisationAccounts);
  }, [accountOptions]);
  const formikProps = {
    initialValues: {
      ...data,
      periodisation_months: data.periodisation_months || "",
      periodisation_date_start: data.periodisation_date_start ? parseISO(data.periodisation_date_start) : null,
      periodisation_account: accountById[data.periodisation_account ? data.periodisation_account : 2991],
    },
    validationSchema: yup
      .object()
      .shape({
        periodisation_date_start: yup.date().nullable().required(),
        periodisation_months: yup.number().nullable().required(),
        periodisation_account: yup.object().nullable().required(),
      })
      .test("period_date_start", function (values) {
        if (values.periodisation_date_start) {
          const today = new Date();
          const earielstPeriodisationDateStartNew = new Date(today.getFullYear(), today.getMonth() - 1, 1);
          if (earielstPeriodisationDateStartNew > values.periodisation_date_start) {
            // earliest day is first day of last month
            return this.createError({
              path: "periodisation_date_start",
              message: t("ver:errors.periodisationStartOnToEarly"),
            });
          }
          if (!isFirstDayOfMonth(values.periodisation_date_start)) {
            // need to be a first day of the month
            return this.createError({
              path: "periodisation_date_start",
              message: t("ver:errors.periodisationStartOnNotFirstDay"),
            });
          }
        }
        return true;
      }),
    onSubmit: async (values, { setErrors, setFieldError, set }) => {
      return ciAPI
        .setPeriodisation(companyId, invoiceId, {
          ...values,
          periodisation_date_start: formatDate(values.periodisation_date_start),
          periodisation_account: values.periodisation_account.value,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          onSubmitSuccess();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form id="periodisation-rows">
            <Table bordered>
              <thead>
                <tr>
                  <th style={{ width: "10px" }} />
                  <th style={{ minWidth: "30%" }}>{t("common:description")}</th>
                  <th style={{ width: "12%" }}>{t("common:costCenter")}</th>
                  <th style={{ width: "12%" }}>{t("common:project")}</th>
                  <th style={{ width: "20%" }} className="text-right">
                    {t("recordNetAfterDiscountAmount")}
                  </th>
                  <th style={{ width: "15%" }} className="text-right">
                    {t("recordToPeriodiseAmount")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.records.map((record, index) => (
                  <tr
                    key={index}
                    className={cx([{ "text-muted": periodisationAlreadySet && !record.PeriodisationEnabled }])}
                  >
                    <TableGroup.Checkbox
                      name={`records[${index}].PeriodisationEnabled`}
                      disabled={periodisationAlreadySet}
                    />
                    <td>
                      <span>{record.ArticleDescription}</span>
                    </td>
                    <td>{centerById[record.BflowCostCenter]?.name || "-"}</td>
                    <td>{projectById[record.BflowProject]?.name || "-"}</td>
                    <td className="text-right">
                      {formatMoney(record.NetAmount)} {values.currency}
                    </td>
                    <TableGroup.MoneyInput
                      name={`records[${index}].ToPeriodiseAmount`}
                      maxvalue={record.NetAmount > 0 ? record.NetAmount : 0}
                      minvalue={record.NetAmount < 0 ? record.NetAmount : 0}
                      disabled={!values.records[index].PeriodisationEnabled || periodisationAlreadySet}
                    />
                  </tr>
                ))}
                <tr style={{ borderColor: "transparent" }}>
                  <td style={{ borderBottomColor: "transparent", borderLeftColor: "transparent" }} colSpan={4} />
                  <td className="text-right">
                    <b> {t("recordToPeriodiseAmount")}</b>
                  </td>
                  <td className="text-right">
                    {formatMoney(getToPeriodiseSum(values))} {values.currency}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Row>
              <Col md={3}>
                <FormGroup.DatePicker
                  label={t("ver:perStartOn")}
                  name="periodisation_date_start"
                  popperClassName="popper-in-modal"
                  required
                  disabled={periodisationAlreadySet}
                />
              </Col>
              <Col md={3}>
                <FormGroup.Input
                  type="number"
                  label={t("common:months")}
                  name="periodisation_months"
                  min={1}
                  required
                  disabled={periodisationAlreadySet}
                />{" "}
              </Col>
              <Col md={1} />
              <Col xl={3}>
                <FormGroup.SimpleSelect
                  name="periodisation_account"
                  label={`${t("common:periodisationAccount")}`}
                  options={optionsPeriodisationAccount}
                  required
                  disabled={periodisationAlreadySet}
                />
              </Col>
              <Col md={2} />
            </Row>
            {!periodisationAlreadySet && (
              <>
                <hr />
                <Row>
                  <Col md={4} />
                  <Col md={4} className="text-center">
                    <Button type="button" className="btn btn-white" onClick={() => handleClose()}>
                      {t("common:actions.cancel")}
                    </Button>
                    <SubmitButton isSubmitting={isSubmitting} />
                  </Col>
                  <Col md={4} />
                </Row>
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

const confirmRoot = document.createElement("div");

const confirmConfigurePeriodisation = (companyId, invoiceId, centerById, projectById) => {
  return new Promise((resolve, reject) => {
    const onFinish = (token) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      resolve(true);
    };
    ReactDOM.render(
      <CIPeriodisationModal
        companyId={companyId}
        invoiceId={invoiceId}
        handleClose={onFinish}
        onSubmitSuccess={onFinish}
        centerById={centerById}
        projectById={projectById}
      />,
      confirmRoot
    );
  });
};

export { confirmConfigurePeriodisation, CIPeriodisationModal };

export default CIPeriodisationModal;
