import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { GlobalSearchTable } from "components/tables";
import * as consultAPI from "api/consult";

function GlobalSearchPage({ tableId = "globalSearch" }) {
  const initialFilters = {
    status: 1,
  };

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => consultAPI.globalSearch(filters, config)}
          initialFilters={initialFilters}
        >
          <GlobalSearchTable />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default GlobalSearchPage;
