const codesForVerifications = {
  enabled: "V00",
  manage: "V01",
};

const codesForFileArchive = {
  enabled: "FA00",
  manage: "FA01",
};

const codesForCompanyArchive = {
  enabled: "CA00",
  manage: "CA01",
  delete: "CA03",
};

const codesForAbsences = {
  enabled: "EA00",
  approve: "EA01",
  download: "EA02",
};

const codesForTimeReport = {
  enabled: "TR00",
  approve: "TR01",
};

const codesForSupplierInvoices = {
  enabled: "S00",
  create: "S01",
  manage: "S02",
  approve: "S03",
  pay: "S04",
  bookPayment: "S05",
  creditInvoice: "S06",
  cancelPayment: "S07",
  localSuppliers: "S08",
};

const codesForOutlays = {
  enabled: "O00",
  manage: "O01",
  approve: "O02",
  pay: "O03",
  bookPayment: "O04",
  cancelPayment: "O05",
};

const codesForCompanySettings = {
  enabled: "CS00",
  edit: "CS01",
  accountPlan: "CS02",
  bankConnection: "CS03",
  tt: "CS04",
  bankMatchingRules: "CS05",
  sieFileImport: "CS06",
  dataExport: "CS07",
  history: "CS08",
};

const codesForEmployees = {
  enabled: "CS12",
  create: "CS13",
  edit: "CS14",
  delete: "CS15",
  editPermissions: "CS16",
  editSchedules: "CS17",
};

const codesForAuditors = {
  enabled: "CS18",
  manage: "CS19",
};

const codesForFinancialYears = {
  enabled: "FY00",
  manage: "FY01",
  exportSIE: "FY02",
};

const codesForOffice = {
  enabled: "OF00",
  userManage: "OF01",
  editConsultPerms: "OF02",
  scheduleTemplateManage: "OF03",
  companyRegister: "OF04",
  companyOnboarding: "OF05",
  officeSupervisior: "OF06",
};

const codesForConsultManage = {
  enabled: "X00",
};

const codesForTT = {
  enabled: "X00",
};

const codesForUpload = {
  enabled: "UP00",
};

const codesForCustomerInvoices = {
  enabled: "C00",
  manage: "C01",
  bookPayment: "C02",
  contractInvoices: "C03",
  offerAndOrder: "C04",
};

const codesForSalaries = {
  enabled: "SAL00",
  manage: "SAL01",
  approve: "SAL02",
  pay: "SAL03",
  bookPayment: "SAL04",
  cancelPayment: "SAL05",
  archive: "SAL06",
};

const codesForReports = {
  enabled: "R00",
  ledger: "R01",
  reconciliation: "R02",
};

const codesForDirectPayments = {
  enabled: "DP00",
  manage: "DP01",
  pay: "DP02",
  cancelPayment: "DP03",
  bookPayment: "DP04",
};

const codesForTaxes = {
  enabled: "T00",
  manage: "T01",
  pay: "T02",
  cancelPayment: "T03",
  bookPayment: "T04",
};

const codesForInventoryRegister = {
  enabled: "IR00",
  manage: "IR01",
};

const codesForOfficeSupport = {
  enabled: "OS00",
};

const codesFor = {
  auditors: codesForAuditors,
  employees: codesForEmployees,
  taxes: codesForTaxes,
  companyArchive: codesForCompanyArchive,
  fileArchive: codesForFileArchive,
  timeReports: codesForTimeReport,
  financialYears: codesForFinancialYears,
  reports: codesForReports,
  absences: codesForAbsences,
  salaries: codesForSalaries,
  outlays: codesForOutlays,
  customerInvoices: codesForCustomerInvoices,
  consultManage: codesForConsultManage,
  tt: codesForTT,
  upload: codesForUpload,
  office: codesForOffice,
  verifications: codesForVerifications,
  supplierInvoices: codesForSupplierInvoices,
  companySettings: codesForCompanySettings,
  directPayments: codesForDirectPayments,
  inventoryRegister: codesForInventoryRegister,
  officeSupport: codesForOfficeSupport,
};

export {
  codesFor,
  codesForTaxes,
  codesForDirectPayments,
  codesForFileArchive,
  codesForCompanyArchive,
  codesForTimeReport,
  codesForFinancialYears,
  codesForReports,
  codesForAbsences,
  codesForOutlays,
  codesForSalaries,
  codesForCustomerInvoices,
  codesForUpload,
  codesForTT,
  codesForConsultManage,
  codesForOffice,
  codesForAuditors,
  codesForEmployees,
  codesForCompanySettings,
  codesForVerifications,
  codesForSupplierInvoices,
  codesForInventoryRegister,
  codesForOfficeSupport,
};
