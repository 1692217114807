import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { UserExtendedTable } from "components/tables";
import * as tableAPI from "api/table";
import { UserAddWidget } from "components/forms/UserForm";
import { useParams } from "react-router-dom";

function OfficeUserListPage({ tableId = "office-users" }) {
  const { officeId } = useParams();
  const initialFilters = {
    is_active: true,
  };
  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => tableAPI.users({ ...filters, office: officeId }, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <UserExtendedTable officeId={officeId} />
        </Card.Body>
      </Card>
      <UserAddWidget officeId={officeId} />
    </TableProvider>
  );
}

export default OfficeUserListPage;
