import React, { useContext } from "react";
import { Card } from "react-bootstrap";

import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import CompanySettingProvider from "state/providers/CompanySettingProvider";
import * as tableAPI from "api/table";
import { EmployeeExtendedTable } from "components/tables";
import { EmployeeAddWidget } from "components/forms/company-settings-forms/EmployeeForm";
import TableProvider from "components/tables/btable/provider";
import { handleActionErrors } from "api/errors";
import { useTranslation } from "react-i18next";
import {
  OnboardingDispatchContext,
  OnboardingStateContext,
} from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";

function EmployeesStep() {
  const initialFilters = {};
  const { companyId } = useContext(OnboardingStateContext);
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const { t } = useTranslation("common");

  const onFinish = () => {
    return companyAPI.onboarding.employees
      .finish(companyId)
      .then(() => {
        fetchDetails(true);
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };
  return (
    <CompanySettingProvider>
      <Card className="employees-step">
        <Card.Body>
          <StepHeader
            rightComponent={<SaveButton onSave={onFinish} withIcon={false} buttonText={t("actions.goNext")} />}
          />
        </Card.Body>
        <TableProvider
          tableId="onboarding-employees"
          dataSource={(filters, config) => tableAPI.companyEmployees(companyId, filters, config)}
          initialFilters={initialFilters}
        >
          <Card.Body>
            <EmployeeExtendedTable companyId={companyId} forOnboarding />
          </Card.Body>
          <EmployeeAddWidget companyId={companyId} />
        </TableProvider>
      </Card>
    </CompanySettingProvider>
  );
}

const EnhancedEmployeesStep = withInitialAsync(
  EmployeesStep,
  ({ companyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboarding.employees.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedEmployeesStep;
