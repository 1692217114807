import React from "react";
import { useTranslation } from "react-i18next";
import * as options from "api/options";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { Col, Row } from "react-bootstrap";

import * as companyAPI from "api/company";
import { AllError, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";

function AccountPlanForm({ companyId, account, successCallback }) {
  const { t } = useTranslation("company");
  const selectOptions = options.accountPlanOptions.asList();
  const isDefaultName = account.name === account.default_name;
  const formikProps = {
    initialValues: {
      name: account.name,
      cost_center_option: options.accountPlanOptions.getOption(account.cost_center_option) || selectOptions[0],
      project_option: options.accountPlanOptions.getOption(account.project_option) || selectOptions[0],
    },
    onSubmit: async (values, { setErrors }) => {
      await companyAPI
        .saveCompanyAccountPlan(companyId, {
          ...values,
          account: account.number,
          cost_center_option: values.cost_center_option.value || values.cost_center_option,
          project_option: values.project_option.value || values.project_option,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.Input
                  label={t("common:name")}
                  name="name"
                  disabled={!account.is_active}
                  placeholder={account.default_name}
                  style={{ color: isDefaultName ? "#6c757d" : "hsl(0, 0%, 20%)" }}
                />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.SimpleSelect
                  name="cost_center_option"
                  label={t("common:costCenter")}
                  isDisabled={!account.is_active}
                  options={selectOptions}
                />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.SimpleSelect
                  name="project_option"
                  label={t("common:project")}
                  options={selectOptions}
                  isDisabled={!account.is_active}
                />
              </Col>
            </Row>
            <AllError errors={errors} />
            <hr />
            {account.is_active && <SubmitButton isSubmitting={isSubmitting} />}
          </Form>
        );
      }}
    </Formik>
  );
}

export default AccountPlanForm;
