import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Alert, Card, Col, Row, Table } from "react-bootstrap";

import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";
import { ItemNotFoundErrorPage } from "pages/errors";
import * as taxAPI from "api/taxes";
import { formatDate } from "utils/date";
import { formatMoney } from "utils/money";
import { useCompanyState } from "hooks/useCompany";

function TaxAuthorityPreviewPage() {
  const company = useOutletContext();
  const { t } = useTranslation("tax");
  const { id: taxId } = useParams();
  const {
    company: { ocr_tax },
  } = useCompanyState();
  const { loading, item } = useInitialAsync(() => taxAPI.taxAuthority.details(company.id, taxId), {});

  if (loading) {
    return <RocketLoader />;
  }

  if (!loading && _.isEmpty(item)) {
    return <ItemNotFoundErrorPage />;
  }
  const tax = {
    ...item,
    spec_per_date: item.created ? formatDate(item.created) : null,
    payment_date: item.payment_date ? formatDate(item.payment_date) : null,
    incoming_balance: `${formatMoney(item.incoming_balance)} ${item.amount_currency}`,
    personnel_tax: item.personnel_tax ? `${formatMoney(item.personnel_tax)} ${item.amount_currency}` : null,
    soc_sec_fees: item.soc_sec_fees ? `${formatMoney(item.soc_sec_fees)} ${item.amount_currency}` : null,
    interest: item.interest ? `${formatMoney(item.interest)} ${item.amount_currency}` : null,
    vat: item.vat ? `${formatMoney(item.vat)} ${item.amount_currency}` : null,
    prel_tax: item.prel_tax ? `${formatMoney(item.prel_tax)} ${item.amount_currency}` : null,
    fees: item.fees ? `${formatMoney(item.fees)} ${item.amount_currency}` : null,
    sick_pay_return: item.sick_pay_return ? `${formatMoney(item.sick_pay_return)} ${item.amount_currency}` : null,
    company_tax: item.company_tax ? `${formatMoney(item.company_tax)} ${item.amount_currency}` : null,
    refunded_debited_preliminary_tax: item.refunded_debited_preliminary_tax
      ? `${formatMoney(item.refunded_debited_preliminary_tax)} ${item.amount_currency}`
      : null,
    special_payroll_tax: item.special_payroll_tax
      ? `${formatMoney(item.special_payroll_tax)} ${item.amount_currency}`
      : null,
    other: item.other ? `${formatMoney(item.other)} ${item.amount_currency}` : null,
    amount: `${formatMoney(item.amount)} ${item.amount_currency}`,
    payAmount: `${formatMoney(item.outgoing_balance < 0 ? -item.outgoing_balance : 0)} ${item.amount_currency}`,
    outgoingTaxBalance: `${formatMoney(item.outgoing_balance)} ${item.amount_currency}`,
  };
  return (
    <Card>
      <Card.Body>
        <TaxContent company={company} tax={tax} t={t} ocr_tax={ocr_tax} />
      </Card.Body>
    </Card>
  );
}

function TaxContent({ tax, t, ocr_tax }) {
  return (
    <div style={{ fontSize: "1rem" }}>
      <Row>
        <Col lg={4} mg={6}>
          <h5>
            {t("specPer")} {tax.spec_per_date}
          </h5>
          <Table>
            <tbody>
              <tr>
                <th>{t("common:dates.dueDate")}</th>
                <td className="text-right">{tax.payment_date}</td>
              </tr>
              <tr>
                <th>{t("incomingBalance")}</th>
                <td className="text-right">{tax.incoming_balance}</td>
              </tr>
              {!!tax.personnel_tax && (
                <tr>
                  <th>{t("personalTax")}</th>
                  <td className="text-right">{tax.personnel_tax}</td>
                </tr>
              )}
              {!!tax.soc_sec_fees && (
                <tr>
                  <th>{t("socSecFees")}</th>
                  <td className="text-right">{tax.soc_sec_fees}</td>
                </tr>
              )}
              {!!tax.prel_tax && (
                <tr>
                  <th>{t("prelTax")}</th>
                  <td className="text-right">{tax.prel_tax}</td>
                </tr>
              )}
              {!!tax.vat && (
                <tr>
                  <th>{t("common:money.vat")}</th>
                  <td className="text-right">{tax.vat}</td>
                </tr>
              )}
              {!!tax.interest && (
                <tr>
                  <th>{t("interestFees")}</th>
                  <td className="text-right">{tax.interest}</td>
                </tr>
              )}
              {!!tax.fees && (
                <tr>
                  <th>{t("fees")}</th>
                  <td className="text-right">{tax.fees}</td>
                </tr>
              )}
              {!!tax.sick_pay_return && (
                <tr>
                  <th>{t("sickPayReturn")}</th>
                  <td className="text-right">{tax.sick_pay_return}</td>
                </tr>
              )}
              {!!tax.company_tax && (
                <tr>
                  <th>{t("companyTax")}</th>
                  <td className="text-right">{tax.company_tax}</td>
                </tr>
              )}
              {!!tax.refunded_debited_preliminary_tax && (
                <tr>
                  <th>{t("refundedDebitedPreliminaryTax")}</th>
                  <td className="text-right">{tax.refunded_debited_preliminary_tax}</td>
                </tr>
              )}
              {!!tax.special_payroll_tax && (
                <tr>
                  <th>{t("specialPayrollTax")}</th>
                  <td className="text-right">{tax.special_payroll_tax}</td>
                </tr>
              )}
              {!!tax.other && (
                <tr>
                  <th>{t("bread:Other")}</th>
                  <td className="text-right">{tax.other}</td>
                </tr>
              )}
              <tr>
                <th>{t("outgoingTaxBalance")}</th>
                <td className="text-right">{tax.outgoingTaxBalance}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert variant="warning">{t("toSkatt", { ocr: ocr_tax })}</Alert>
        </Col>
      </Row>
    </div>
  );
}

export default TaxAuthorityPreviewPage;
