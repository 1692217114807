import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { TrashTable } from "components/tables";
import * as tableAPI from "api/table";
import CompanyListLayout from "components/layouts/CompanyListLayout";

function TrashListPage({ selectable = false, tableId = "trashDocument" }) {
  const initialFilters = null;

  return (
    <CompanyListLayout>
      <Card>
        <Card.Body>
          <TableProvider
            tableId={tableId}
            dataSource={(filters, config) => tableAPI.trashDocuments(filters, config)}
            initialFilters={initialFilters}
          >
            <TrashTable selectable={selectable} />
          </TableProvider>
        </Card.Body>
      </Card>
    </CompanyListLayout>
  );
}

export default TrashListPage;
