import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { UserExtendedTable } from "components/tables";
import * as tableAPI from "api/table";
import { UserAddWidget } from "components/forms/UserForm";

function AdminUserListPage({ tableId = "admin-users" }) {
  const initialFilters = {
    is_active: true,
  };

  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => tableAPI.users(filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <UserExtendedTable />
        </Card.Body>
      </Card>
      <UserAddWidget />
    </TableProvider>
  );
}

export default AdminUserListPage;
