import React from "react";
import { Card } from "react-bootstrap";
import * as tableAPI from "api/table";
import TableProvider from "components/tables/btable/provider";
import useModal from "hooks/useModal";
import { RegisterOfficeModal } from "components/modals";
import { OfficeTable } from "components/tables";
import OfficeListLayout from "components/layouts/OfficeListLayout";

function OfficeListPage() {
  const registerModal = useModal();

  const initialFilters = {};

  return (
    <OfficeListLayout>
      <Card>
        <Card.Body>
          <TableProvider
            tableId="companies"
            dataSource={tableAPI.offices}
            initialFilters={initialFilters}
            keepTerm={false}
          >
            <OfficeTable />
          </TableProvider>
        </Card.Body>
      </Card>
      {registerModal.show && <RegisterOfficeModal handleClose={registerModal.close} />}
    </OfficeListLayout>
  );
}

export default OfficeListPage;
