import { NavLink, useResolvedPath } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

function CompanyCardNavTabs({ tabIndicators }) {
  const { t } = useTranslation("office-support");
  const canSeeClientDetails = true;
  const path = useResolvedPath("").pathname;
  const { client_details, business_profile, roles, documents, assessments, reassessments } = tabIndicators;

  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      {canSeeClientDetails && (
        <li className="nav-item">
          <NavLink to={`${path}/client-details`} className="nav-link">
            {t("company:clientDetails")}
            <span className={cx("status-dot", client_details?.up_to_date ? "hidden" : client_details?.color)} />
          </NavLink>
        </li>
      )}
      {canSeeClientDetails && (
        <li className="nav-item">
          <NavLink to={`${path}/business-profile`} className="nav-link">
            {t("company:businessProfile")}
            <span className={cx("status-dot", business_profile?.up_to_date ? "hidden" : business_profile?.color)} />
          </NavLink>
        </li>
      )}
      {canSeeClientDetails && (
        <li className="nav-item">
          <NavLink to={`${path}/roles`} className="nav-link">
            <span> {t("common:roles")}</span>
            <span className={cx("status-dot", roles?.up_to_date ? "hidden" : roles?.color)} />
          </NavLink>
        </li>
      )}
      {canSeeClientDetails && (
        <li className="nav-item">
          <NavLink to={`${path}/assessment`} className="nav-link">
            {t("company:assessment")}
            <span className={cx("status-dot", assessments?.up_to_date ? "hidden" : assessments?.color)} />
          </NavLink>
        </li>
      )}
      {canSeeClientDetails && (
        <li className="nav-item">
          <NavLink to={`${path}/reassessment`} className="nav-link">
            {t("company:reassessment")}
            <span className={cx("status-dot", reassessments?.up_to_date ? "hidden" : reassessments?.color)} />
          </NavLink>
        </li>
      )}
      {canSeeClientDetails && (
        <li className="nav-item">
          <NavLink to={`${path}/tt`} className="nav-link">
            <i className="fe-calendar" />
            <span> TT</span>
          </NavLink>
        </li>
      )}
      {canSeeClientDetails && (
        <li className="nav-item">
          <NavLink to={`${path}/documents`} className="nav-link">
            <span> {t("navigation:documents")}</span>
            <span className={cx("status-dot", documents?.up_to_date ? "hidden" : documents?.color)} />
          </NavLink>
        </li>
      )}
    </ul>
  );
}

export default CompanyCardNavTabs;
