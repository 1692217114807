import React, { useCallback, useContext, useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInitialAsync from "hooks/useInitialAsync";
import * as archiveAPI from "api/archive";
import { RocketLoader } from "components/ui/loaders";
import { FinancialYearFilter } from "components/filters";
import { FiltersContext } from "state/providers/FiltersProvider";

import CompanyArchiveCard from "./CompanyArchiveCard";
import { useAuthState } from "../../../../hooks/useAuth";

function CompanyArchiveAuditorPage({ companyId, data, filters, updateFilter, reloadData }) {
  const { t } = useTranslation("common");
  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  const haveItems =
    Object.values(data).reduce((sum, nextItem) => {
      return sum + nextItem.length;
    }, 0) !== 0;
  return (
    <Card id="company-archive">
      <Card.Body>
        <Row>
          <Col lg={3} sm={8}>
            <FinancialYearFilter
              label={t("common:financialYear")}
              name="financial_year"
              isClearable={false}
              defaultValue={filters.financial_year}
              onFilter={(selected) => {
                updateFilter({ financial_year: selected.financial_year });
              }}
            />
          </Col>
        </Row>
        <p className="mb-0">{t("company:auditorArchiveCategoryNote")}</p>
        {!haveItems && (
          <Alert variant="info" className="mt-1">
            {t("common:NoDocumentsUploaded")}
          </Alert>
        )}
        <div className="grid-2 mt-2">
          {Object.keys(data)
            .filter((cat) => (isConsult && !filters.projection ? true : data[cat].length))
            .map((cat) => (
              <CompanyArchiveCard
                key={cat}
                companyId={companyId}
                category={cat}
                items={data[cat]}
                projection={filters.projection}
                isConsult={isConsult}
                financialYearId={filters.financial_year}
                reloadData={reloadData}
              />
            ))}
        </div>
      </Card.Body>
    </Card>
  );
}

function CompanyArchiveAuditorWrapper() {
  const company = useOutletContext();
  const [counter, setCounter] = useState(1); // to refresh
  const { filters, updateFilter } = useContext(FiltersContext);
  const { loading, item } = useInitialAsync(
    () => {
      return archiveAPI.companyDocument.auditorList(company.id, filters);
    },
    {},
    [counter, filters.financial_year, filters.projection]
  );
  const reloadData = useCallback(() => {
    setCounter((c) => c + 1);
  }, []);

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <CompanyArchiveAuditorPage
      companyId={company.id}
      data={item}
      reloadData={reloadData}
      filters={filters}
      updateFilter={updateFilter}
    />
  );
}

export default CompanyArchiveAuditorWrapper;
