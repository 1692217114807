import React, { useCallback, useMemo, useState } from "react";

const PaymentAccountContext = React.createContext(undefined);

const initialState = {
  isPaymentAccount: false,
};

function PaymentAccountProvider({ children }) {
  const [data, setData] = useState(initialState);

  const setIsPaymentAccount = useCallback((isPaymentAccount) => {
    setData((prevState) => ({ ...prevState, isPaymentAccount }));
  }, []);

  const stateValue = useMemo(
    () => ({
      isPaymentAccount: data.isPaymentAccount,
      setIsPaymentAccount,
    }),
    [data.isPaymentAccount, setIsPaymentAccount]
  );

  return <PaymentAccountContext.Provider value={stateValue}>{children}</PaymentAccountContext.Provider>;
}

export { PaymentAccountContext };

export default PaymentAccountProvider;
