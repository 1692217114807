import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResolvedPath } from "react-router-dom";

import * as offerAPI from "api/offers";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import { OfferHistoryButton, TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import FileUrlModalOld from "components/modals/FileUrlModal/FileUrlModalOld";
import useModal from "hooks/useModal";

function OfferActions({ row, companyId }) {
  const { t } = useTranslation("ci");
  const path = useResolvedPath("").pathname;
  const fileModal = useModal();
  const {
    dataActions: { reload },
  } = useTable();

  const show = {
    acceptBtn: row.last_email_sent_date,
  };

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.removeOffer", { customer: row.customer_snapshot.Name }));
    if (answer) {
      offerAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch(() => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };

  const openPreviewModal = () => {
    fileModal.open(`/companies/${companyId}/offers/${row.id}/preview_direct/`);
  };

  const onSentToCustomer = async () => {
    const answer = await confirmExecute(
      t("confirm.sendOffer", {
        customer: row.customer_snapshot.Name,
        email: row.customer_snapshot.Email || `${t("common:contact.emailShort")} ${t("common:notSet")}`,
      })
    );

    if (answer) {
      offerAPI
        .sendToCustomer(companyId, row.id)
        .then(() => {
          toast.success(t("msg:sent"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onAccept = async () => {
    const answer = await confirmExecute(t("confirm.acceptOffer", { customer: row.customer_snapshot.Name }));
    if (answer) {
      offerAPI
        .approve(companyId, row.id)
        .then(() => {
          toast.success(t("msg:generated"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  return (
    <>
      <OfferHistoryButton offerId={row.id} />
      <TooltipActionButton text={t("common:actions.preview")} onClick={openPreviewModal} icon="fas fa-images" />
      {show.acceptBtn && (
        <TooltipActionButton text={t("actions.acceptOffer")} onClick={onAccept} icon="fas fa-thumbs-up" />
      )}
      <TooltipActionButton
        text={t("actions.sendOfferToCustomer")}
        onClick={onSentToCustomer}
        icon="fas fa-paper-plane"
      />
      <TooltipActionLink text={t("common:actions.change")} link={`${path}/${row.id}/change`} icon="fas fa-pen" />
      <TooltipActionLink text={t("common:actions.duplicate")} link={`${path}/${row.id}/duplicate`} icon="fas fa-copy" />
      <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      {fileModal.show && <FileUrlModalOld fileUrl={fileModal.data} handleClose={fileModal.close} />}
    </>
  );
}

export default OfferActions;
