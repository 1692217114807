import React, { useContext, useState } from "react";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import _ from "lodash";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";
import { NewDocuments } from "components/ui/documents/verification-documents";
import * as documentAPI from "api/document";
import * as salaryAPI from "api/salaries";
import { formatDate } from "utils/date";
import { AllError, FormGroup } from "components/formik";
import { toast } from "react-toastify";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import { useCompanyState } from "hooks/useCompany";
import { filterActiveCC, filterActiveProjects } from "utils/others";

function AgaPdfReductionForm({ companyId, item, onSave }) {
  const { t } = useTranslation("salary");
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const { checkYears } = useContext(FinancialYearDispatchContext);
  const {
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0;
  const showCostCenters = activeCenters.length !== 0;
  const formikProps = {
    initialValues: {
      ...item,
    },
    validationSchema: yup.object().shape({
      booking_date: yup.date().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      const executeAnyway = await checkYears(values.booking_date);
      if (!executeAnyway) {
        return;
      }

      const formData = new FormData();
      formData.append("booking_date", formatDate(values.booking_date));
      formData.append("project", values.project?.value ?? "");
      formData.append("cost_center", values.cost_center?.value ?? "");
      formData.append("reduction_soc_sec", values.reduction_soc_sec);
      if (newFiles[0] && newFiles[0].file) {
        formData.append("file", newFiles[0].file);
      }

      await salaryAPI
        .agaPdfReduction(companyId, formData)
        .then(async (response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors }) => {
        return (
          <Form id="holidayForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={5} className="verification-files">
                    <NewDocuments
                      documents={newFiles}
                      onChange={({ file, index }) =>
                        documentAPI.onNewFileChange({
                          file,
                          index,
                          allFiles: newFiles,
                          setFile: setNewFiles,
                          max: 1,
                        })
                      }
                      multiple={false}
                    />
                  </Col>
                  <Col xl={7}>
                    <Row>
                      <Col xl={4}>
                        <FormGroup.BookingDatePicker name="booking_date" label={t("common:dates.bookingDate")} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6}>
                        <FormGroup.MoneyInput name="reduction_soc_sec" label={t("reductionSocSec")} />
                      </Col>
                    </Row>
                    <Row>
                      {showProjects && (
                        <Col xl={5}>
                          <FormGroup.SimpleSelect
                            options={activeProjects}
                            isClearable
                            name="project"
                            label={t("common:project")}
                          />
                        </Col>
                      )}
                      {showCostCenters && (
                        <Col xl={5}>
                          <FormGroup.SimpleSelect
                            options={activeCenters}
                            isClearable
                            name="cost_center"
                            label={t("common:costCenter")}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <ButtonGroup>
                  <SubmitButton isSubmitting={isSubmitting} />
                </ButtonGroup>
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AgaPdfReductionForm;
