import qs from "qs";
import client from "./client";

export const clientDetails = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/client_details/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/client_details/`, data);
  },
};

export const accountingDetails = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/accounting/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/accounting/`, data);
  },
};

export const businessProfile = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/business_profile/`);
  },
  update: (companyId, data) => {
    return client.patch(`/office-support/${companyId}/business_profile/`, data);
  },
};

export const internalInformation = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/internal_information/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/internal_information/`, data);
  },
};

export const rolesAdditional = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/additional/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/additional/`, data);
  },
};

export const rolesEssential = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/essential/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/essential/`, data);
  },
};

export const rolesTaxAgencyAttorney = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/tax_agency_attorney/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/tax_agency_attorney/`, data);
  },
};

export const rolesOtherAttorney = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/other_attorney/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/other_attorney/`, data);
  },
};

export const rolesBankAttorney = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/bank_attorney/`);
  },
  update: (companyId, data) => {
    return client.put(`/office-support/${companyId}/bank_attorney/`, data);
  },
};

export const bflowAccess = {
  get: (companyId) => {
    return client.get(`/office-support/${companyId}/bflow_access/`);
  },
};

export const assessment = {
  get: (companyId, forReassessment = false) => {
    return client.get(`/office-support/${companyId}/assessment/?forReassessment=${forReassessment}`);
  },
  save: (companyId, data, forReassessment = false) => {
    return client.put(`/office-support/${companyId}/assessment/?forReassessment=${forReassessment}`, data);
  },
};

export const statusIndicator = {
  get_company_details_indicators: (companyId) => {
    return client.get(`/office-support/${companyId}/company_details_indicators/`);
  },
  update: (companyId, data) => {
    return client.patch(`/office-support/${companyId}/company_details_indicators/`, data);
  },
};

export const exportOfficeSupport = {
  get: (filters, config) => {
    return client.get(`/office-support/export`, {
      params: filters,
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    });
  },
  save: (data) => {
    return client.post(`/office-support/export`, data, {
      responseType: "blob",
    });
  },
};
