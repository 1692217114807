import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { ConsultPicker, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { toast } from "react-toastify";
import * as officeSupportApi from "api/office-support";
import * as yup from "yup";
import useModal from "hooks/useModal";
import BflowAccessModal from "components/modals/OfficeSupportModal";

function OfficeSupportRolesOtherAttorneyForm({ company, changeEditedForm, initialData }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);
  const modal = useModal();

  const handleModalClose = () => {
    modal.close();
  };

  const emptyOtherAttorney = {
    consult: null,
    power_of_attorney: "",
    id: "",
  };

  const formikProps = {
    initialValues: {
      other_attorneys:
        initialData.other_attorneys.length > 0
          ? initialData.other_attorneys.map((item) => ({
              consult: {
                value: item.value,
                label: item.label,
              },
              power_of_attorney: item.power_of_attorney,
              id: item.id,
            }))
          : [emptyOtherAttorney],
    },
    validationSchema: yup.object().shape({
      other_attorneys: yup.array().of(
        yup.object().shape({
          consult: yup.object().nullable().required(),
          power_of_attorney: yup.string().required(),
        })
      ),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await officeSupportApi.rolesOtherAttorney
        .update(company.id, {
          ...values,
          other_attorneys: values.other_attorneys
            .filter((item) => item.consult)
            .map((item) => ({
              value: item.consult.value,
              label: item.consult.label,
              power_of_attorney: item.power_of_attorney,
              id: item.id || null,
            })),
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data?.__all__) {
            toast.error(error.data.__all__, { autoClose: 5000 });
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "roles-other-attorney-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <>
      <Formik {...formikProps}>
        {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
          return (
            <Form>
              <FormUpdateChecker />
              <Card>
                <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                  <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                    {t("header.otherAttorney")}
                    <i
                      style={{ textAlign: "right", float: "right" }}
                      className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                    />
                  </Card.Title>
                </Card.Header>
                {showMore && (
                  <Card.Body className="pt-0">
                    <FieldArray
                      name="other_attorneys"
                      render={(arrayHelpers) => (
                        <>
                          {values.other_attorneys.map((attorney, index) => (
                            <React.Fragment key={index}>
                              <Row key={`other-button.${index}`}>
                                <Col>
                                  {values.other_attorneys.length > 1 && (
                                    <>
                                      {index + 1}.
                                      <Button style={{ color: "#FD6767" }} variant="text" size="lg" className="pl-1">
                                        <i className="fe-trash" onClick={() => arrayHelpers.remove(index)} />
                                      </Button>
                                    </>
                                  )}
                                  <input type="hidden" name={`other_attorneys.${index}.id`} value={attorney.id} />
                                </Col>
                              </Row>
                              <Row key={`other-consult.${index}`}>
                                <Col>
                                  <ConsultPicker
                                    name={`other_attorneys.${index}.consult`}
                                    label={t("title.consultant")}
                                    required
                                  />
                                </Col>
                                <Col>
                                  <FormGroup.Input
                                    label={t("title.powerOfAttorney")}
                                    name={`other_attorneys.${index}.power_of_attorney`}
                                    required
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          ))}
                          {values.other_attorneys.length < 10 && (
                            <Row>
                              <Col>
                                <Button
                                  style={{ color: "#6c757d" }}
                                  color=""
                                  variant="text"
                                  onClick={() => arrayHelpers.push(emptyOtherAttorney)}
                                  className="add-btn"
                                >
                                  <i style={{ color: "#348CD4" }} className="fas fa-plus mr-1" />
                                  {t("actions.addConsultant")}
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    />
                    <Row className="mt-4">
                      <Col>
                        <Button type="button" className="btn-second blue" onClick={modal.open}>
                          {t("bflowAccess")}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        {dirty && (
                          <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                            {t("common:actions.cancel")}
                          </Button>
                        )}
                        <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                      </Col>
                    </Row>
                  </Card.Body>
                )}
              </Card>
            </Form>
          );
        }}
      </Formik>
      {modal.show && <BflowAccessModal company={company} handleClose={handleModalClose} />}
    </>
  );
}

export default OfficeSupportRolesOtherAttorneyForm;
