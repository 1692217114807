import { Col, Modal, Row } from "react-bootstrap";
import React from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { isAfter, isSameMonth } from "date-fns";

import * as trackerAPI from "api/time-tracker";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import { parseDate } from "utils/date";
import { AllError, FormGroup } from "components/formik";
import { absenceReasons } from "api/options";
import { toast } from "react-toastify";
import { DraggableModalDialog } from "../DraggableModalDialog";
import { useModalScroll } from "../hooks/useModalScroll";

function TTAbsenceLogModal({ absenceLog, officeId, handleClose }) {
  const { t } = useTranslation("tt");
  const { shouldScroll } = useModalScroll();

  const absenceReasonsList = absenceReasons.asList();
  const formikProps = {
    initialValues: {
      ...absenceLog,
      date_from: parseDate(absenceLog.date_from),
      date_to: parseDate(absenceLog.date_to),
    },
    validationSchema: yup
      .object()
      .shape({
        date_from: yup.date().nullable().required(),
        date_to: yup.date().nullable().required(),
        reason: yup.object().nullable().required(),
        hours_per_day: yup.number().min(0.5).max(12),
        comment: yup.string().when("reason", function (_reason, schema) {
          return _reason && _reason.value === "other" ? schema.required() : schema;
        }),
      })
      .test("validDates", function (values) {
        if (values.date_from && values.date_to && isAfter(values.date_from, values.date_to)) {
          return this.createError({
            path: "date_to",
            message: t("common:errors.mustLaterStartDate"),
          });
        }
        if (values.date_from && values.date_to && !isSameMonth(values.date_from, values.date_to)) {
          return this.createError({
            path: "date_to",
            message: t("common:errors.mustBeWithinSameMonth"),
          });
        }
        return true;
      }),
    onSubmit: async (values, { setErrors }) => {
      await trackerAPI
        .createAbsenceTimeLogs(officeId, values)
        .then((response) => {
          toast.success(t("msg:generatedCount", { count: response.data.created || 0 }));
          handleClose(true);
        })
        .catch((error) => {
          if (error.status === 403) {
            toast.error(t("msg:canNotExecuteAction"));
          } else {
            setErrors(error.data);
          }
        });
    },
  };

  return (
    <Modal show onHide={handleClose} animation={false} dialogAs={DraggableModalDialog} scrollable={shouldScroll}>
      <Formik {...formikProps}>
        {({ values: { reason }, errors, isSubmitting }) => {
          return (
            <Form noValidate>
              <Modal.Header closeButton>
                <Modal.Title as="h4">{t("options:absence")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup.DatePicker label={t("common:dates.dateFrom")} name="date_from" required />
                <FormGroup.DatePicker label={t("common:dates.dateTo")} name="date_to" required />
                <Row>
                  <Col lg={4}>
                    <FormGroup.Input type="number" name="hours_per_day" label={t("hoursPerDay")} required />
                  </Col>
                  <Col lg={8}>
                    <FormGroup.SimpleSelect
                      name="reason"
                      label={t("common:reason")}
                      options={absenceReasonsList}
                      required
                    />
                  </Col>
                </Row>

                <FormGroup.Input
                  as="textarea"
                  rows={2}
                  name="comment"
                  label={t("common:comment")}
                  required={reason && reason.value === "other"}
                />
                <AllError errors={errors} />
              </Modal.Body>
              <Modal.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default TTAbsenceLogModal;
