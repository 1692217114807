import client from "./client";

export function save(companyId, data) {
  return client.post(`/companies/${companyId}/direct-payments/`, data);
}

export function remove(companyId, dpId) {
  return client.delete(`/companies/${companyId}/direct-payments/${dpId}/`);
}

export function cancelPayment(companyId, dpId) {
  return client.patch(`/companies/${companyId}/direct-payments/${dpId}/cancel_payment/`);
}

export function pay(companyId, dpId, bankIDToken) {
  return client.patch(`/companies/${companyId}/direct-payments/${dpId}/send_money_bank/`, {
    bank_id_token: bankIDToken,
  });
}

export function manualPay(companyId, dpId, data) {
  return client.patch(`/companies/${companyId}/direct-payments/${dpId}/add_manual_payment/`, data);
}
