import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as invoiceAPI from "api/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";
import _ from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function CustomerInvoiceCreditPage() {
  const { t } = useTranslation("ci");
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: verificationId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return invoiceAPI.details(company.id, verificationId);
  });

  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }
  if (item.periodisation_status && !["undone", "stopped_before_execution"].includes(item.periodisation_status)) {
    toast.warning(t("warning.creditedWithPeriodisation"), { autoClose: 5000 });
  }
  const draftCreditInvoice = {
    ...item,
    id: null,
    booking_date: new Date(),
    credit_for: item.id,
    invoice_no: "",
    status: 0,
    sub_status: "",
    delivery_method: item.delivery_method || "Manually",
    rot_rut: {
      Customers: [],
    },
    periodisation_config: null,
    periodisation_enabled: false,
    records: [
      {
        key: _.uniqueId("ln"),
        RecordType: "Message",
        ArticleDescription: `Kredit avser faktura ${item.invoice_no}`,
      },
      ...item.records.map((record) => {
        if (record.RecordType === "Standard") {
          record.UnitPrice *= -1;
          record.maxQtyForCredit = record.Quantity;
          record.Discount = record.DiscountType === "Percentage" ? Math.abs(record.Discount) : -1 * record.Discount;
          delete record.RotRutAmount;
          delete record.RotRutActivated;
          delete record.RotRutHours;
          delete record.RotRutMaterialCostAmount;
          delete record.RotRutType;
        }
        return record;
      }),
    ],
  };

  return (
    <CustomerInvoiceForm forCredit invoice={draftCreditInvoice} company={company} forCreditInvoice onSave={onCreate} />
  );
}

export default CustomerInvoiceCreditPage;
