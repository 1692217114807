import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import useTable from "components/tables/btable/useTable";
import { ConsultFilter, SearchFilter, SelectSimpleFilter } from "components/filters";
import * as options from "api/options";

function ManagementOverviewFilters() {
  const vatPeriodChoices = options.vatPeriodNumber.asList();
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  return (
    <section className="table-filters-left">
      <SearchFilter hiddenLabel onFilter={setFilters} defaultValue={filters.term} width={300} />
      <ConsultFilter onFilter={setFilters} label={t("common:consult")} defaultValue={filters.consult} />
      <SelectSimpleFilter
        onFilter={setFilters}
        options={vatPeriodChoices}
        label={t("manage.vatPeriod")}
        isClearable
        name="vat_report_period"
      />
      <div>
        <label className="d-block">&nbsp;</label>
        <Button
          variant="outline-secondary"
          onClick={() => setFilters({ favourites: filters.favourites ? null : true })}
          active={filters.favourites}
        >
          {t("favouritesOnly")}
        </Button>
      </div>
    </section>
  );
}

export default ManagementOverviewFilters;
