import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import UserForm from "components/forms/UserForm";
import { useTranslation } from "react-i18next";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import { DraggableModalDialog } from "../DraggableModalDialog";

function UserAddModal({ handleClose, onSuccess }) {
  const { t } = useTranslation("common");
  const user = {
    first_name: "",
    last_name: "",
    email: "",
    personal_number: "",
    office_role: "regular",
    is_active: true,
  };
  const {
    info: { office_id: officeId },
  } = useContext(CompanySettingStateContext);

  const successCallback = (newUser) => {
    if (onSuccess) {
      onSuccess(newUser);
    }
    handleClose();
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.addUser")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UserForm officeId={officeId} user={user} successCallback={successCallback} />
      </Modal.Body>
    </Modal>
  );
}

export default UserAddModal;
