import React, { useContext } from "react";
import { BalanceStateContext } from "state/providers/BalanceProvider";
import { formatMoney } from "utils/money";

function TransactionBalance({ account }) {
  const { balances } = useContext(BalanceStateContext);
  return <span>{formatMoney(balances[account] || 0)}</span>;
}

export default TransactionBalance;
