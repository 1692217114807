import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipActionButton } from "components/ui/buttons";
import useModal from "hooks/useModal";
import useTable from "components/tables/btable/useTable";
import SieRevertConfirmModal from "./SieRevertConfirmModal";

function SieFileActions({ row, companyId }) {
  const { t } = useTranslation(["others", "msg", "common"]);
  const {
    dataActions: { reload },
  } = useTable();
  const sieRevertModal = useModal();
  return (
    <>
      <TooltipActionButton
        variant="danger"
        text={t("common:actions.revertAndRemove")}
        onClick={sieRevertModal.open}
        icon="fas fa-trash"
      />
      {sieRevertModal.show && (
        <SieRevertConfirmModal companyId={companyId} sieFile={row} handleClose={sieRevertModal.close} reload={reload} />
      )}
    </>
  );
}

export default SieFileActions;
