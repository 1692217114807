import React from "react";
import LogoBox from "./LogoBox";
import ToggleMenu from "./ToggleMenu";
import Breadcrumbs from "./Breadcrumbs";

function NavBarLeftSide({ company, forDashboard }) {
  return (
    <>
      <LogoBox />
      <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
        {company && (
          <li className="menu-toggler">
            <ToggleMenu />
          </li>
        )}
        <Breadcrumbs />
        <li>
          {/* <ConsultWorkTimeTask /> */}
          {/* <ConsultActiveTask /> */}
        </li>
      </ul>
    </>
  );
}

export default NavBarLeftSide;
