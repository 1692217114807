import React, { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useCompanyState } from "hooks/useCompany";
import Select from "react-select";
import useModalOnPage from "components/modals/hooks/useModalOnPage";

const AccountSelect = forwardRef(
  ({ placeholder, options, defaultValue, onChange, isOptionDisabled, ...props }, ref) => {
    const isModal = useModalOnPage();
    return (
      <Select
        ref={ref}
        className="react-select"
        classNamePrefix="select"
        placeholder={placeholder}
        menuPlacement={isModal ? "bottom" : "auto"}
        onChange={onChange}
        isOptionDisabled={isOptionDisabled}
        styles={{
          menu: (provided, state) => ({
            ...provided,
            width: 300,
          }),
          dropdownIndicator: () => ({
            display: "none",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
        }}
        options={options}
        defaultValue={options.filter((option) => option.number === Number(defaultValue))}
        isClearable
        {...props}
      />
    );
  }
);

function AccountRangeFilter({ label, numberFrom, numberTo, min, max, onChange }) {
  const { t } = useTranslation("common");
  const {
    accounts: { asOptions: accountOptions },
  } = useCompanyState();
  const toRef = useRef(null);
  const fromRef = useRef(null);
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <div className="number-range account-range">
        <AccountSelect
          ref={fromRef}
          placeholder={t("from")}
          options={accountOptions}
          defaultValue={numberFrom}
          onChange={(selected) => {
            if (!selected) {
              onChange({ numberFrom: null, numberTo });
            } else {
              const targetNumberTo = selected.number > numberTo ? null : numberTo;
              if (targetNumberTo !== numberTo) {
                toRef.current.setValue(targetNumberTo);
              }
              onChange({ numberFrom: selected && selected.number, numberTo: targetNumberTo });
              toRef.current.focus();
            }
          }}
        />
        <AccountSelect
          ref={toRef}
          placeholder={t("to")}
          options={accountOptions}
          defaultValue={numberTo}
          openMenuOnFocus
          isOptionDisabled={(option) => {
            if (numberFrom) {
              return option.number < numberFrom;
            }
            return false;
          }}
          onChange={(selected) => {
            onChange({ numberFrom, numberTo: selected ? selected.number : null });
          }}
        />
      </div>
    </Form.Group>
  );
}

export { AccountSelect };
export default AccountRangeFilter;
