import React from "react";
import "./TTTask.scss";
import { truncateText } from "utils/text";
import { formatDuration } from "utils/date";
import cx from "classnames";
import DurationInterval from "./DurationInterval";

function TTTask({ title, company, duration, playing = true, children, taskClassName }) {
  return (
    <div className="active-tasks">
      <div className={cx("active-task", taskClassName)}>
        <div className="active-task__title">{truncateText(title, 29)}</div>
        <div className="active-task__company">{truncateText(company, 30)}</div>
        <div className="active-task__summary">
          <div className="summary-time">
            {playing ? <DurationInterval initialDuration={duration} /> : formatDuration(duration)}
          </div>
          <div className="summary-actions">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default TTTask;
