import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SieExportForm from "components/forms/SieForm/SieExportForm";
import { DraggableModalDialog } from "../DraggableModalDialog";

function SieFileExportModal({ handleClose, companyId, fyData }) {
  const { t } = useTranslation("company");

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("downloadSie")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SieExportForm companyId={companyId} fy={fyData} onFinish={handleClose} />
      </Modal.Body>
    </Modal>
  );
}

export default SieFileExportModal;
