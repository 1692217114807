import React from "react";
import * as selectAPI from "api/select";
import { useTranslation } from "react-i18next";
import { Button, InputGroup } from "react-bootstrap";
import { UserAddModal } from "components/modals";
import useModal from "hooks/useModal";
import FormGroup from "./FormGroup";

function UserPicker({
  name = "user",
  label,
  officeId,
  isDisabled = false,
  showAddButton = true,
  addUserCallback,
  setValue,
  ...otherProps
}) {
  const { t } = useTranslation("common");
  const userModal = useModal();

  const getUsers = (params, callback) => {
    return selectAPI.getUsers({ ...params, officeId }, callback);
  };

  const onAddUser = (newUser) => {
    if (setValue) {
      setValue(name, { value: newUser.id, label: newUser.name });
    }
  };

  return (
    <div className="user-picker">
      <InputGroup>
        <FormGroup.AsyncSelect
          wrapperStyle={{ width: "100%" }}
          name={name}
          label={label || t("common:user")}
          loadOptions={getUsers}
          minSearchLength={3}
          isDisabled={isDisabled}
          {...otherProps}
        />
        {showAddButton && (
          <InputGroup.Append>
            <Button variant="second" className="float-right" onClick={userModal.open}>
              <i className="fas fa-plus" />
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
      {userModal.show && <UserAddModal officeId={officeId} onSuccess={onAddUser} handleClose={userModal.close} />}
    </div>
  );
}

export default UserPicker;
