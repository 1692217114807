import React from "react";
import { codesFor, HasPermCode } from "components/perms";
import SalaryListPage from "./SalaryListPage";

function ApproveSalaryListPage() {
  const canApprove = HasPermCode(codesFor.salaries.approve);
  return <SalaryListPage tableId="prelimSalaries" initialMode="prelim" selectable={canApprove} />;
}

export default ApproveSalaryListPage;
