import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import * as verificationAPI from "api/verification";
import * as absenceAPI from "api/absence";
import * as documentAPI from "api/document";
import * as companyAPI from "api/company";
import * as offerAPI from "api/offers";
import { formatDatetime } from "utils/date";
import { ActionButton } from "./TooltipButton";

function _renderPopover({ logs, t, ...props }) {
  return (
    <Popover id="popover-logs" className="popover-logs" {...props}>
      <Popover.Title as="h3">{t("logs.title")}</Popover.Title>
      <Popover.Content>
        <ul>
          {logs.map((log) => (
            <li key={`log.${log.id}`}>
              {formatDatetime(log.created)} <strong>{t(`logs.${log.action}`).toLowerCase()}</strong>
              {log.extra_info ? ` ${log.extra_info}` : ` (${log.user_name})`}
            </li>
          ))}
        </ul>
      </Popover.Content>
    </Popover>
  );
}

function VerificationHistoryButton({ verificationId, verLogsOnly = false }) {
  const { t } = useTranslation("common");
  const [logs, setLogs] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const getLogs = React.useCallback(() => {
    verificationAPI.logs(verificationId, verLogsOnly).then((res) => setLogs(res.data));
  }, [verificationId, verLogsOnly]);
  return (
    <OverlayTrigger
      show={show}
      placement="left"
      onEnter={getLogs}
      overlay={(props) => _renderPopover({ logs, t, ...props })}
    >
      <ActionButton
        icon="fas fa-history"
        className="history-btn"
        onClick={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
    </OverlayTrigger>
  );
}

function AbsenceHistoryButton({ reportId }) {
  const { t } = useTranslation("common");
  const [logs, setLogs] = React.useState([]);
  const getLogs = React.useCallback(() => {
    absenceAPI.logs(reportId).then((res) => setLogs(res.data));
  }, [reportId]);
  return (
    <OverlayTrigger
      trigger="focus"
      placement="left"
      onEnter={getLogs}
      overlay={(props) => _renderPopover({ logs, t, ...props })}
    >
      <ActionButton icon="fas fa-history" />
    </OverlayTrigger>
  );
}

function DocumentHistoryButton({ documentId }) {
  const { t } = useTranslation("common");
  const [logs, setLogs] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const getLogs = React.useCallback(() => {
    documentAPI.logs(documentId).then((res) => setLogs(res.data));
  }, [documentId]);
  return (
    <OverlayTrigger
      show={show}
      placement="left"
      onEnter={getLogs}
      overlay={(props) => _renderPopover({ logs, t, ...props })}
    >
      <ActionButton icon="fas fa-history" onClick={() => setShow(true)} onMouseLeave={() => setShow(false)} />
    </OverlayTrigger>
  );
}

function EUVatHistoryButton({ vatId }) {
  const { t } = useTranslation("common");
  const [logs, setLogs] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const getLogs = React.useCallback(() => {
    companyAPI.euVat.logs(vatId).then((res) => setLogs(res.data));
  }, [vatId]);
  return (
    <OverlayTrigger
      show={show}
      placement="left"
      onEnter={getLogs}
      overlay={(props) => _renderPopover({ logs, t, ...props })}
    >
      <ActionButton
        disabled={!vatId}
        icon="fas fa-history"
        onClick={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
    </OverlayTrigger>
  );
}

function HistoryButtonCreated({ createdAt, createdBy, id }) {
  const { t } = useTranslation("common");
  const [logs] = React.useState([
    {
      id,
      created: createdAt,
      user_name: createdBy,
      action: "created",
    },
  ]);

  const [show, setShow] = React.useState(false);

  return (
    <OverlayTrigger show={show} placement="left" overlay={(props) => _renderPopover({ logs, t, ...props })}>
      <ActionButton icon="fas fa-history" onClick={() => setShow(true)} onMouseLeave={() => setShow(false)} />
    </OverlayTrigger>
  );
}

function OfferHistoryButton({ offerId }) {
  const { t } = useTranslation("common");
  const [logs, setLogs] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const getLogs = React.useCallback(() => {
    offerAPI.logs(offerId).then((res) => setLogs(res.data));
  }, [offerId]);
  return (
    <OverlayTrigger
      show={show}
      placement="left"
      onEnter={getLogs}
      overlay={(props) => _renderPopover({ logs, t, ...props })}
    >
      <ActionButton
        icon="fas fa-history"
        className="history-btn"
        onClick={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
    </OverlayTrigger>
  );
}

export {
  VerificationHistoryButton,
  AbsenceHistoryButton,
  HistoryButtonCreated,
  DocumentHistoryButton,
  EUVatHistoryButton,
  OfferHistoryButton,
};
